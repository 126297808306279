import React, {useState, useEffect} from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Table, Popover, message, Button } from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import coursesApiClient from 'utils/coursesApiClient';
import apiClient from 'utils/apiClient';

const mapStateToProps = (state: any) => {
  return {
    session: state.session
  }
};

const ManageTemplates: React.FC = ({history}:any) => {
  const [visibleTooltip, setVisibleTooltip] = useState<number|null>(null);
  const [phishingSimulationTemplates, setPhishingSimulationTemplates] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const intl = useIntl();

  useEffect(() => {
    const loadPhishingSimulationTemplates = async () => {
      setIsLoading(true);
      try {
        let response = await coursesApiClient.request('/api/v1/courses/phishing/phishing-simulation', [], 'GET');
        const phishingTemplates = response.phishingSimulations;

        phishingTemplates.forEach((template: any) => {
          template.isTemplate = true;
          if (template.phishingTemplateCombinations.length) {
            template.children = template.phishingTemplateCombinations;
          }
        });
          setPhishingSimulationTemplates(phishingTemplates);
      } catch (error) {
        message.error(intl.formatMessage({id: "error.server_error"}));
      } finally {
      setIsLoading(false);
      }

      };

      const loadLanguages = async () => {
        let languages = await apiClient.request(`/api/v1/language`, {}, 'GET');
        let languageListNameForSelect:any = {};
        Object.values(languages.languages).map((value:any) => {
          if (value['publish']) {
            languageListNameForSelect[value['code']] = value['name']
          }
          return languageListNameForSelect;
        });

        Object.values(languages.languages).map((value:any) => {
          if (!value['publish']) {
            languageListNameForSelect[value['code']] = value['name']
          }
          return languageListNameForSelect;
        });
      };
    loadLanguages();
    loadPhishingSimulationTemplates();
  }, [intl]);

  const columns = [
    {
      title: intl.formatMessage({id: 'phishing.simulation_id'}),
      dataIndex: 'simulation_id',
      render: (value: string, record: any) => record.isTemplate ? record.simulationId : null
    },
    {
      title: intl.formatMessage({id: 'general.language'}),
      dataIndex: 'language',
      render: (value: string, record: any) => !record.isTemplate ? record.language : null
    },
    {
      title: intl.formatMessage({id: 'phishing.table_template_id'}),
      dataIndex: 'template_id',
      render: (value: string, record: any) => !record.isTemplate ? record.template_id : null
    },
    {
      title: intl.formatMessage({id: 'phishing.simulation_template_name'}),
      key: 'simulation_template_name',
      render: (value: string, record: any) => {
        return (
          !record.isTemplate ?
        <div className='user-name-style' onClick={()=>{history.push(`/phishing/templates/${record.phishing_template}/edit/1/${record.language}`)}}>
          {record.simulation_template_name}
        </div>
        :
        <div className='user-name-style' onClick={()=>{history.push(`/phishing/templates/${record.id}/edit/0/`)}}>
          {record.simulationTemplateName}
        </div>
        )
      }
    },
    {
      title: intl.formatMessage({id: 'phishing.phishing_email'}),
      key: 'phishing_email',
      render: (value: string, record: any) => !record.isTemplate ? `${record.gophish_template_id} ${record.simulation_template_name}` : null,
    },
    {
      title: intl.formatMessage({id: 'phishing.phishing_landing_page'}),
      key: 'phishing_landing_page',
      render: (value: string, record: any) => !record.isTemplate ? `${record.gophish_landing_page_id} ${record.landing_page_name}` : null,
    },
    {
      title: intl.formatMessage({id: 'phishing.use_case'}),
      key: 'use_case',
      render: (value: string, record: any) =>{
        if (record.isTemplate) {
          if (record.allowSmartDephish === 1 && record.customPhishingCampaigns === 1 ) {
            return [intl.formatMessage({id: 'phishing.smart_dephish'}),', ' ,intl.formatMessage({id: 'phishing.custom_phishing_campaigns'})]
          } else if (record.allowSmartDephish === 1) {
            return [intl.formatMessage({id: 'phishing.smart_dephish'})]
          } else {
            return [intl.formatMessage({id: 'phishing.custom_phishing_campaigns'})]
          }
        }

      }
    },
    {
      key: 'actions',
      render: (text: string, record: any, index: number) => {
        let existingLanguages:any = [];
        if (record.isTemplate) {
          Object.values(record.phishingTemplateCombinations).map((element:any)=> {
            existingLanguages.push(element.language);
            return existingLanguages;
          })
        }

        const content = (
          <>
            <NavLink to={`/phishing/templates/${record.id}/edit`}>
              <div className="popover-item">
                <FormattedMessage id="general.edit"/>
              </div>
            </NavLink>
            <div onClick={()=>{history.push(`/phishing/templates/${record.id}/edit/1/NEW_LANGUAGE`)}}>
              <div className="popover-item">
                <FormattedMessage id="courses.add_new_language"/>
              </div>
            </div>

          </>
        );

        if (record.isTemplate) {
          return (
            <Popover
              visible={index === visibleTooltip}
              content={content}
              trigger="click"
              placement="bottomRight"
              arrowPointAtCenter
              onVisibleChange={(visible) => handleVisibleChange(visible, index)}
            >
              <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
                <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} />
              </div>
            </Popover>
          );
        }
      }
    }
  ];

  const handleVisibleChange = (visible: boolean, rowIndex: number) => {
    if (visible) {
      setVisibleTooltip(rowIndex);
    } else {
      setVisibleTooltip(null);
    }
  }

  const showTotal = (total: any, range: any) => {
    return `${range[0]}-${range[1]} of ${total}`
  };

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageHeader title={intl.formatMessage({id: 'phishing.manage_templates'})} />
      <DefaultLayout.PageContent>
        <NavLink to='/phishing/templates/add'>
          <Button type="primary">
            <FormattedMessage id="phishing.add_new_simulation_template" />
          </Button>
        </NavLink>
        <Table
          locale={{ emptyText: intl.formatMessage({ id: 'general.found_no_data' }) }}
          pagination={{
            showTotal: showTotal,
            pageSizeOptions: ['10', '20'],
            showSizeChanger: true,
            locale: { items_per_page: intl.formatMessage({ id: 'general.page' }), jump_to: intl.formatMessage({ id: 'general.go_to' }) },
            showQuickJumper: true,
          }}
          rowKey={(record) => record.id}
          style={{ margin: 0, marginTop: 15 }}
          columns={columns}
          dataSource={phishingSimulationTemplates}
          size="middle"
          loading={isLoading}
          scroll={{ x: 800 }}
        />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
}

export default connect(mapStateToProps)(withRouter(ManageTemplates));
