import React from 'react';
import { Spin } from 'antd';
import { defaults, Line } from 'react-chartjs-2';
import '../styles.scss';

interface LineChartProps {
  // Does anyone know how to type this?
  data: any,
  title?: string,
  loading?: boolean,
}

const LineChart: React.FC<LineChartProps> = ({ data, title, loading = false }) => {
  defaults.global.defaultFontFamily = 'Gilroy';

  const options = {
    maintainAspectRatio: false,
    legend: false,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero: true,
          fontColor: 'black',
          maxTicksLimit: 5,
          padding: 15,
          suggestedMax: (Math.max(...(data.datasets && data.datasets.length ? data.datasets[0].data : [])) || 1) * 1.25,
        },
        gridLines: {
          drawTicks: false,
          zeroLineWidth: 0,
        },
      }],
      xAxes: [{
        ticks: {
          callback: (tick: any) => tick.toString().substring(0, 3),
          fontColor: 'black',
          padding: 15,
        },
        gridLines: {
          drawTicks: false,
          zeroLineWidth: 0,
        },
      }],
    },
    plugins: {
      datalabels: false,
    },
  };

  const plugins = [{
    // This makes the gradient below the line
    afterLayout: (chart: any) => {
      if (chart.data.datasets[0]) {
        const chartArea = chart.chartArea;

        const fillGradient = chart.ctx.createLinearGradient(
          chartArea.left,
          /* chartArea.bottom could be NaN if the chart is made really small,
           which would throw an error */
          chartArea.bottom || 0,
          chartArea.right,
          chartArea.top
        );

        fillGradient.addColorStop(0, 'rgba(99, 205, 163, 0.2)');
        fillGradient.addColorStop(0.95, 'rgba(99, 205, 163, 0)');

        chart.data.datasets[0].backgroundColor = fillGradient;
      }
    },
  }];

  return (
    <>
      {title && <p className="chart-title">{title}</p>}
      <Spin spinning={loading}>
        <Line
          data={data}
          options={options}
          plugins={plugins}
        />
      </Spin>
    </>
  );
}

export default LineChart;