import React, {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { Button, Input, Popover, message, Tag, Modal, Form } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import debounce from "lodash/debounce";
import { paginatorQuery } from 'components/Table/methods';
import Table from 'components/Table';
import DefaultLayout from 'components/DefaultLayout';
import FlexRow from 'components/FlexRow';
import moment from 'moment';
import apiClient from 'utils/apiClient';
import config from 'config/config';
import './styles.scss';

const mapStateToProps = (state: any) => {
  return {
    session: state.session,
  };
};

const { Search } = Input;
const { confirm } = Modal;

const CustomerList: React.FC = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visibleTooltip, setVisibleTooltip] = useState<number|null>(null);
  const intl = useIntl();

  const pageSizeOptions = [10, 20];
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPageSize, setCurrentPageSize] = useState<number>(pageSizeOptions[0]);
  const [sortValues, setSortValues] = useState();

  const resourceUrl = config.api.routes.backend.customers;
  const [form] = Form.useForm();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  const loadData = async () => {
    try {
      setLoading(true);
      let query = paginatorQuery(1, currentPageSize);
      let customersResponse = await getRecords(resourceUrl, query);
      setCustomers(customersResponse.data);
      setTotalRecords(customersResponse.pagination.total);
      setCurrentPageSize(pageSizeOptions[0])
    } catch (err) {
      console.error(err);
      message.error(intl.formatMessage({id: "error.data_load"}));
    } finally {
      setLoading(false);
    }
  };

  const deleteCustomer = async (id: string) => {
    try {
      setLoading(true);
      setVisibleTooltip(null);

      let validationResponse = await apiClient.request(`/api/v1/customers/${id}/validate-deletion`, {}, 'POST');

      if (validationResponse.hasOwnProperty('result')) {
        if (validationResponse.result.activeLicences > 0) {
          Modal.warning({
            title: intl.formatMessage({id: "general.attention"}),
            content: intl.formatMessage({id: "organization.deletion.still_has_valid_licences"})
          });
        } else if (validationResponse.result.actualUsers > 0) {
          confirm({
            title: intl.formatMessage({id: "general.attention"}),
            content: intl.formatMessage({id: "organization.deletion.still_has_undeleted_users"}),
              icon: <ExclamationCircleOutlined />,
              okText: intl.formatMessage({id: 'general.yes'}),
              cancelText: intl.formatMessage({id: 'general.no'}),

              onOk() {
                queueDeletion(id);
              }
            });
        } else {
          await softDelete(id);

          message.success(intl.formatMessage({id: "organization.deletion.successful_deleted"}));

          loadData();
        }
      }
    } catch (err: any) {
      if (err.hasOwnProperty('message') && err.message === 'customer_already_added_to_deletion_queue') {
        message.error(intl.formatMessage({id: "error.customer_already_added_to_deletion_queue"}));
      } else {
        message.error(intl.formatMessage({id: "error.delete_item"}));
      }

      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const softDelete = async (id: string) => {
    await apiClient.request(`/api/v1/customers/${id}/soft-delete`, {}, 'POST');
  };

  const queueDeletion = async (id: string) => {
    try {
      setLoading(true);

      await apiClient.request(`/api/v1/customers/${id}/queue-deletion`, {}, 'POST');
    } catch (err) {
      message.error(intl.formatMessage({id: "error.delete_item"}));
      console.error(err);
    } finally {
      setLoading(false);
      message.success(intl.formatMessage({id: "organization.deletion.added_to_deletion_queue"}));
    }
  };

  const handleVisibleChange = (visible: boolean, recordId: number) => {
    if (visible) {
      setVisibleTooltip(recordId);
    } else {
      setVisibleTooltip(null);
    }
  };

  const getRecords = async (resourceUrl: string, pageQuery: string, data?:{column?: string, order?: string, q?: string}, searchString?: string) => {
    let query = (pageQuery !== '') ? '?' + pageQuery : '';

    if (!data) {
        data = sortValues
    }

    if (!data) {
        data = {}
    }

    if (searchString?.length) {
      data.q = searchString;
    } else if (form.getFieldValue('search')) {
      data.q = form.getFieldValue('search');
    }

    let response = await apiClient.request(resourceUrl + query, data, 'GET');

    return  {
      data: (response.customers) ? response.customers : {},
      pagination: (response.pagination) ? response.pagination : {}
    }
  };

  const columns = [
    {
      title: intl.formatMessage({id: 'general.customer'}),
      dataIndex: 'name',
      render: (_text: string, record: any) => (
        <NavLink to={`/customers/${record.customer.id}/edit`}>
          {record.customer.name}
        </NavLink>
      )
    },
    {
      title: intl.formatMessage({id: 'general.start_date'}),
      sorter: true,
      key: 'begin_date',
      render: (value: string, record: any) => record.agreement ? moment(record.agreement.beginDate).format('DD.MM.YYYY') : '-'
    },
    {
      title: intl.formatMessage({id: 'general.end_date'}),
      sorter: true,
      key: 'end_date',
      render: (value: string, record: any) => {
        let color;
        if (record.agreement && record.agreement.endDate){
         if (moment(record.agreement.endDate) < moment()) color='date-color';
          return (
            <div className={color}>
              {moment(record.agreement.endDate).format('DD.MM.YYYY')}
            </div>)
        } else {
            return'-'
        }
      }
    },
    {
      title: intl.formatMessage({id: 'organization.agreement_changes'}),
      key: 'agreement_changes',
      render: () => '-'
    },
    {
      title: intl.formatMessage({id: 'organization.agreement_status'}),
      key: 'agreement_status',
      sorter: true,
      render: (_text: string, record: any, key: number) => (
        record.agreement
          ? <Tag color={record.agreement.status === 'ACTIVE' ? 'green' : 'red'} key={key}>
              {intl.formatMessage({id: `agreement.status.${record.agreement.status}`})}
            </Tag>
          : '-'
      )
    },
    {
      title: intl.formatMessage({id: 'organization.active_students'}),
      key: 'activeStudents',
      sorter: true,
      render: (_text: string, record: any) => (
        <NavLink to={`/customers/${record.customer.id}/users/org`}>
          {record.customer.activeStudentCount}
        </NavLink>
      )
    },
    {
      key: 'actions',
      render: (text: string, record: any) => {
        const customerId = record.customer.id;

        const content = <>
          {
            record.agreement ?
              <NavLink to={`/customers/${customerId}/agreement`}>
                <div className="popover-item">
                  <FormattedMessage id="organization.edit_agreement"/>
                </div>
              </NavLink>
              :
              <NavLink to={`/customers/${customerId}/agreement`}>
                <div className="popover-item">
                  <FormattedMessage id="organization.add_agreement"/>
                </div>
              </NavLink>
          }
          <NavLink to={`/customers/${customerId}/edit`}>
            <div className="popover-item">
              <FormattedMessage id="organization.edit_customer"/>
            </div>
          </NavLink>
          <div className="popover-item" onClick={() => {deleteCustomer(customerId)}}>
            <FormattedMessage id="organization.delete_customer"/>
          </div>
        </>;

        return (
          <Popover
            visible={customerId === visibleTooltip}
            content={content}
            trigger="click"
            placement="bottomRight"
            arrowPointAtCenter
            onVisibleChange={(visible) => handleVisibleChange(visible, customerId)}
          >
            <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
              <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
            </div>
          </Popover>
        )
      },
    },
  ];

    const handleTableSortFilter = async (sorter: any, tablePage: number) => {
        if (sorter && Object.keys(sorter).length === 0 && sorter.constructor === Object) {
            return;
        }

        let parsedValues: any = {
            column: sorter.columnKey,
            order: sorter.order ? sorter.order : null
        };

        setSortValues(parsedValues);

        if (currentPage === tablePage && sorter.order) {
            let query = paginatorQuery(1, currentPageSize ? currentPageSize : 10);

            let organizationsResponse: any = await getRecords(resourceUrl, query, parsedValues);

            setCustomers(organizationsResponse.data);
            setTotalRecords(organizationsResponse.pagination.total);
        } else if (!sorter.order) {

            setLoading(true);

            let query = paginatorQuery(1, currentPageSize ? currentPageSize : 10);
            setCurrentPage(1);

            let organizationsResponse: any = await getRecords(resourceUrl, query, parsedValues);

            setCustomers(organizationsResponse.data);
            setTotalRecords(organizationsResponse.pagination.total);
            setLoading(false);

        }
    };

    const searchCustomerByName = async (searchValue: string) => {
        setLoading(true);

        let query = paginatorQuery(1, currentPageSize ? currentPageSize : 10);

        setCurrentPage(1);

        let organizationsResponse: any = await getRecords(resourceUrl, query, undefined, searchValue);

        setCustomers(organizationsResponse.data);
        setTotalRecords(organizationsResponse.pagination.total);
        setLoading(false);
    } ;

    const loadOptionsTimeOut = debounce(function (searchValue: string) {
        const searchString = searchValue.replace(/\s+/g, '');
        if (searchValue.length === 0 || searchString.length >= 3) {
          searchCustomerByName(searchValue);
        }
    }, 800);

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageHeader title={intl.formatMessage({id: 'general.customers'})} />
      <DefaultLayout.PageContent>
        <FlexRow 
          left={
            <NavLink to="/customers/add">
              <Button type="primary" icon={<PlusOutlined/>}>
                <FormattedMessage id="organization.add_customer" />
              </Button>
            </NavLink>
          }
          right={
            <Form form={form}>
              <Form.Item name='search' style={{marginBottom: 0}}>
                <Search
                    className="table-search-field"
                    allowClear
                    placeholder={intl.formatMessage({id: "general.search"})}
                    onChange={value => loadOptionsTimeOut(value.target.value)}
                />
              </Form.Item>
            </Form>
          }
        />
            <Table
              resourceUrl={resourceUrl}
              totalRecords={totalRecords}
              loadedData={customers}
              setLoadedData={setCustomers}
              setTotalRecords={setTotalRecords}
              setCurrentPageSize={setCurrentPageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setLoading={setLoading}
              loading={loading}
              columns={columns}
              pageSizeOptions={pageSizeOptions}
              getRecords={getRecords}
              onChange={(pagination:any, filters: any, sorter: any) => handleTableSortFilter(sorter, pagination.current)}
            />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
};

export default connect(mapStateToProps)(CustomerList);
