import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import FlexRow from 'components/FlexRow';
import '../PageFooter/styles.scss';

const mapStateToProps = (state: any) => {
  return {
    sidebarCollapsed: state.sidebarCollapsed.collapsed,
  };
};

interface PageFooterWithRowProps {
  sidebarCollapsed: boolean;
  noSidebar?: boolean;
  left?: ReactElement;
  right?: ReactElement;
  shrink?: boolean;
}

const PageFooterWithRow: React.FC<PageFooterWithRowProps> = ({ sidebarCollapsed, noSidebar, left, right, shrink = false }) => {
  return (
    <div  className={`page-footer ${shrink ? 'page-footer-size' : ''} ${(sidebarCollapsed || noSidebar) ? 'sidebar-collapsed': ''}`}>
      <FlexRow left={left} right={right}/>
    </div>
  );
};

export default connect(mapStateToProps)(PageFooterWithRow);
