import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag as AntDTag } from 'antd';
import { Status } from 'components/CampaignCard/types';

interface TagProps {
  status: Status,
}

const Tag: React.FC<TagProps> = ({ status }) => {
  const parameters = {
    'NOT_STARTED': {
      color: 'gray',
      messageId: 'student.new',
    },
    'IN_PROGRESS': {
      color: 'blue',
      messageId: 'student.process',
    },
    'COMPLETED': {
      color: 'green',
      messageId: 'student.finished',
    },
    'MISSED': {
      color: 'red',
      messageId: 'student.unfinished',
    },
    'CANCELLED': {
      color: 'red',
      messageId: 'student.unfinished',
    },
  }

  return (
    <AntDTag color={parameters[status].color}>
      <FormattedMessage id={parameters[status].messageId} />
    </AntDTag>
  );
};

export default Tag;