import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { message, Space, Spin } from 'antd';
import { useIntl } from 'react-intl';
import ActivityCard from 'components/ActivityCard';
import { ActivityData } from './types';
import coursesApiClient from 'utils/coursesApiClient';

const mapStateToProps = (state: any) => ({
  locale: state.locale.locale,
});

interface ActivityListProps {
  locale: string,
  userUuid: string,
  activityData: ActivityData,
  setResourceData?: any;
}

const ActivityList: React.FC<ActivityListProps> = ({ locale, userUuid, activityData, setResourceData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [activities, setActivities] = useState([]);

  const intl = useIntl();

  useEffect(() => {
    const loadActivities = async () => {
      try {
        setIsLoading(true);

        const url = `/api/v1/courses/student/${activityData.enrollmentId}/activity-list/${activityData.campaignId}/0`;

        const data = {
          activeUserTypeUuid: userUuid,
          activeLanguage: locale,
        };

        const response = await coursesApiClient.request(url, data, 'POST');

        setActivities(response.activities);
      } catch (error) {
        message.error(intl.formatMessage({ id: 'error.data_load' }));
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    loadActivities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderActivities = () => (
    activities.map((activity: any) => <ActivityCard key={activity.id} activity={activity} preview bordered campaignData={activityData} setResourceData={setResourceData} />)
  );

  return (
    <Spin spinning={isLoading}>
      <div style={{ minHeight: 50 }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          {renderActivities()}
        </Space>
      </div>
    </Spin>
  );
};

export default connect(mapStateToProps)(ActivityList);