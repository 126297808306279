import React from 'react';
import { NavLink } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import './styles.scss';

interface BreadcrumbObject {
  path?: string,
  name: string,
  onClick?: any;
  state?: any;
}

interface PageHeaderProps {
  breadcrumb?: BreadcrumbObject[],
  title: string,
  loading?: boolean
}

const PageHeader: React.FC<PageHeaderProps> = ({breadcrumb = [], title, loading}) => {

  let className = 'page-header';

  if (loading) {
    className += ' loading';
  }

  const backClick = (breadcrumbLastObject: any) => {
    if (breadcrumbLastObject.onClick) {
      breadcrumbLastObject.onClick()
    }
  }

  return (
    <div className={className}>
      <Breadcrumb>
        {
          breadcrumb.map((breadcrumb: BreadcrumbObject, index: number) => {
            return (
              <Breadcrumb.Item key={index}>
                {breadcrumb.path
                  ? (
                    <NavLink to={breadcrumb.path}>
                      {breadcrumb.name}
                    </NavLink>
                  )
                  : breadcrumb.name
                }
              </Breadcrumb.Item>
            )
          })
        }
        <Breadcrumb.Item>
          {title}
        </Breadcrumb.Item>
      </Breadcrumb>
      <h1>
        {
          breadcrumb.length > 0
            ? <NavLink onClick={()=> backClick(breadcrumb[breadcrumb.length - 1]) } to={{
              pathname: breadcrumb[breadcrumb.length - 1].path || '/',
              state: breadcrumb[breadcrumb.length - 1]?.state || null
            }}>
                <i className="fal fa-arrow-left" />
              </NavLink>
            : null
        }
        {title}
      </h1>
    </div>
  )
}

export default PageHeader;
