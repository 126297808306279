import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card } from 'antd';
import Badge from './components/Badge';
import Button from './components/Button';
import Certificate from './components/Certificate';
import Information from './components/Information';
import { Status, StatusWithoutCancelled } from './types';
import styles from './styles.module.scss';
import { connect } from 'react-redux';

interface CampaignCardBaseProps {
  campaign: any;
  status: Status;
  bordered?: boolean;
  hideButton?: boolean;
  preview?: boolean;
  reload?: any;
  session?: any;
}

interface CampaignCardWithLinkProps extends CampaignCardBaseProps {
  link: string;
  onClick?: undefined;
}

interface CampaignCardWithOnClickProps extends CampaignCardBaseProps {
  link?: undefined;
  onClick: () => void;
}

type CampaignCardProps = CampaignCardWithLinkProps | CampaignCardWithOnClickProps;

const mapStateToProps = (state: any) => {
  return {
    session: state.session
  }
}

const CampaignCard: React.FC<CampaignCardProps> = ({
  campaign,
  status,
  bordered = false,
  hideButton = false,
  preview = false,
  link,
  reload,
  session,
  onClick,
}) => {
  const hasCertificate = campaign.isCertificateGenerated;
  hideButton = hideButton || status === 'CANCELLED';

  const renderButton = () => {
    /* status will NEVER be 'CANCELLED' here since the button will not get rendered if it's that,
     TypeScript doesn't understand that, hence the ignore. If it reaches this function because of the
     'preview' prop 'designProps' will return 'preview' instead of 'status' prop so 'status' will not be used */
    // @ts-ignore
    const designProps: { preview: true } | { status: StatusWithoutCancelled } = preview ? { preview } : { status };

    const interactionProps: { link: string } | { onClick: (() => void) } = link ? { link } : { onClick: onClick! };

    return <Button {...designProps} {...interactionProps} />;
  };

  return (
    <Card className={styles.card} bordered={bordered}>

      <div className={
        `${styles.grid} ${hideButton ? styles.gridWithoutButton : null} ${hasCertificate ? styles.gridWithCertificate : null}`
      }>
        <Badge campaign={campaign} />

        <div className={`${styles.progressContainer}`}>
          <p className={styles.progress}>
            <FormattedMessage id="student.campaign_completed" values={{ amount: campaign.result }} />
          </p>
        </div>

        {hasCertificate && <Certificate campaign={campaign} />}

        <Information reload={reload} campaign={campaign} status={status} />
        {(!hideButton || preview) && renderButton()}
      </div>
    </Card>
  );
};

export default connect(mapStateToProps)(CampaignCard);
