import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import DoughnutChart from 'components/Charts/DoughnutChart';
import { DashboardChartProps } from '../types';
import coursesApiClient from 'utils/coursesApiClient';

const PhishingResultChart: React.FC<DashboardChartProps> = ({ customerUuid }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    data: {},
    inner: {},
  });

  const intl = useIntl();

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const url = `/api/v1/courses/statistic/dashboard/campaigns/customer/${customerUuid}/phishing-overall`;
        const response = await coursesApiClient.request(url);
        const responseData = response.statistic.overall;

        const data = {
          data: [
            responseData.totalSent - responseData.failed,
            responseData.failed,
          ],
          total: responseData.totalSent,
        };

        const parsedData = {
          data: {
            labels: [
              intl.formatMessage({ id: 'phishing.success' }),
              intl.formatMessage({ id: 'phishing.failed' }),
            ],
            datasets: [{
              data: data.data,
              backgroundColor: [
                'rgb(33, 208, 160)',
                'rgb(244, 98, 100)',
              ],
              hoverBackgroundColor: [
                'rgb(13, 188, 140)',
                'rgb(224 ,68, 80)',
              ],
            }],
          },
          inner: {
            text: `${intl.formatMessage({ id: 'phishing.emails_sent' })}*`,
            number: data.total,
            small: true,
          },
        };

        setData(parsedData);
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, [intl, customerUuid]);

  return (
    <DoughnutChart
      data={data.data}
      title={intl.formatMessage({ id: 'phishing.campaign_results' })}
      inner={data.inner}
      footer={`*${intl.formatMessage({ id: 'general.past_12_months' })}`}
      loading={isLoading}
    />
  );
}

export default PhishingResultChart;