import React from 'react';
import { Form } from 'antd';
import Spinner from 'components/Spinner';
import { Editor } from '@tinymce/tinymce-react';
import config from 'config/config';

const ContentEditor: React.FC<any> = ({htmlTemplate, loading, setHtmlTemplate, label, name }) => {

    return (
        <>
            <Spinner spinning={loading} >
                <Form.Item name={name} label={label}>
                    <Editor
                        apiKey={config.api.tinymceApiKey}
                        value={htmlTemplate}
                        onEditorChange={(el: any) => setHtmlTemplate(el)}
                        initialValue={'‎'} // initial value = Empty character. Needs to switch between language after loading template
                        init={{
                            branding: false,
                            mode: 'exact',
                            height: 350,
                            menubar: false,
                            resize: false,
                            relative_urls : true,
                            remove_script_host : true,
                            plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                            ],
                            toolbar:
                                'undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code | help'
                        }}
                    />
                </Form.Item>
            </Spinner>
        </>
    )
};

export default ContentEditor;
