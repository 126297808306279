import {
    SAVE_PUBLIC_APPEARANCE
} from 'store/actionTypes';

const defaultState = false;

const publicAppearanceReducer = (state = defaultState, action: any) => {
    switch (action.type) {
        case SAVE_PUBLIC_APPEARANCE:
            return { ...action.payload};
        default:
            return state;
    }
};

export default publicAppearanceReducer;
