import React, { useState, useEffect } from 'react';
import { Alert as AntDAlert } from 'antd';
import apiClient from 'utils/apiClient';

const Alerts = ({organization, activeUserType, envName, intl}: any) => {
    const [notifications, setNotifications] = useState<any>([]);
    const [licencesReportData, setLicencesReportData] = useState<any>({});

    useEffect(() => {
        const loadLicencesReport = async () => {
            try {
              let customerId = organization.organization_id
              let response = await apiClient.request('/api/v1/customers/' + customerId + '/licences-status-report', {}, 'GET');
      
              setLicencesReportData(response.report)
            } catch (error) {
              console.error(error)
            }
        }
      
        if (activeUserType === 'CUSTOMER_ADMIN' && Object.keys(licencesReportData).length === 0) {
            loadLicencesReport()
        }

        const loadLearningManagerNotifications = () => {
            let notify = []

            for (let key in licencesReportData) {
                if (licencesReportData[key].notify === true) {
                    notifications[key] = licencesReportData[key]

                    if (key === 'platformAccessExpire') {
                        notifications[key].message = intl.formatMessage({id: "general.attention"})
                        notifications[key].type = "warning"
                        notifications[key].description = intl.formatMessage({id: "dashboard.notifications.customer_account_will_ru_run_out_of_user_access_licences"}, {environmentName: envName, days: licencesReportData[key].expireAfter, endDate: licencesReportData[key].endDate})
                    }
                    if (key === 'lessPlatformAccess') {
                        notifications[key].message = intl.formatMessage({id: "general.attention"})
                        notifications[key].type = "warning"
                        notifications[key].description = intl.formatMessage({id: "dashboard.notifications.will_be_less_access_licences_than_students"}, {environmentName: envName, invalidDate: licencesReportData[key].startDate, days: licencesReportData[key].expireAfter})
                    }
                    if (key === 'elearningContentWillBeLess') {
                        notifications[key].message = intl.formatMessage({id: "general.attention"})
                        notifications[key].type = "warning"
                        notifications[key].description = intl.formatMessage({id: "dashboard.notifications.no_active_elearning_content_licences_while_still_have_active_student_access_licences"}, {environmentName: envName, endDate: licencesReportData[key].endDate})
                    }
                    if (key === 'studentsMoreThanAllowed') {
                        notifications[key].message = intl.formatMessage({id: "general.warning"})
                        notifications[key].type = "error"
                        notifications[key].description = intl.formatMessage({id: "dashboard.notifications.platform_access_expired_or_less_than_students"})
                    }
                    if (key === 'agreementWarning') {
                        notifications[key].message = intl.formatMessage({id: "general.attention"})
                        notifications[key].type = "warning"
                        notifications[key].description = intl.formatMessage({id: "dashboard.notifications.agreement_expiration_warning"}, {endDate: licencesReportData[key].endDate})
                    }
                    if (key === 'agreementExpired') {
                        notifications[key].message = intl.formatMessage({id: "general.warning"})
                        notifications[key].type = "error"
                        notifications[key].description = intl.formatMessage({id: "dashboard.notifications.agreement_expired"}, {endDate: licencesReportData[key].endDate})
                    }

                    notify.push(notifications[key])
                }
            }

            setNotifications(notify)
        }
        
        loadLearningManagerNotifications()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [licencesReportData]);

    return (<>
        {
            notifications.map((notification:any, index:any) => {
                return <AntDAlert 
                    message={notification.message}
                    description={notification.description}
                    type={notification.type}
                    showIcon
                    closable
                    style={{marginBottom: '10px'}}
                />
            })
        }
    </>);
  }
  
  export default Alerts;
