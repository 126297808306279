import React, { useState, useEffect } from 'react';
import coursesApiClient from 'utils/coursesApiClient';
import { Button, message } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useBeforeunload } from 'react-beforeunload';
import Plyr from 'plyr';
import './styles.scss';

type ServerRequestType = {
    learningActivityId: number;
    activityAttemptId?: number;
    type: 'learn' | 'test';
    userTypeUuid: string;
    language?: string;
    version?: string;
}

/**
 * 
 * @param state 
 * @returns 
 */
const mapStateToProps = (state: any) => ({ session: state.session });

/**
 * 
 * @param param0 
 * @returns 
 */
const VideoPlayer: React.FC<any> = ({ scormData, language, session, version }) => {
    const [video, setVideo] = useState({ provider: '', id: '' }),
        [material, setMaterial] = useState<any>(),
        [disableProgress, setDisableProgress] = useState(false),
        [pauseOnBlur, setPauseOnBlur] = useState(false),
        [iframe, setIframe] = useState(false),
        [endLoading, setEndLoading] = useState(false),
        intl = useIntl();

    useEffect(() => {
        loadPdfMaterialPlayer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl])

    useBeforeunload((event) => {
        endVideoSession(material.uuid);
        event.preventDefault();
    });

    const loadPdfMaterialPlayer = async () => {
        try {
            const parsedValues: ServerRequestType = {
                learningActivityId: scormData.learningActivityId,
                type: scormData.type,
                userTypeUuid: session.active_user_type_uuid,
                activityAttemptId: scormData.activityAttemptId
            };

            if (language) {
                parsedValues.language = language
            }

            if (version) {
                parsedValues.version = version
            }
            const response = await coursesApiClient.request(`/api/v1/courses/serve-request/start-video`, parsedValues, 'POST');

            const materialData = response.material;
            setMaterial(materialData);
            const settings = materialData.settings;
            setPauseOnBlur(settings.pause_on_blur);
            setDisableProgress(!settings.video_progress);
            setVideo({ provider: materialData.videoProvider, id: materialData.videoId })
            startActivity(materialData.uuid)

            if (!settings.view_all_to_complete) {
                finish(materialData.uuid);
            }
        } catch (error: any) {
            if (error.message === 'already_active_scorm') {
                message.error(intl.formatMessage({ id: 'error.already_active_activity' }));
            } else {
                console.error(error);
                message.error(intl.formatMessage({ id: 'error.data_load' }));
            }
        }
    }

    const player = new Plyr('#player', {
        controls: video.provider === 'vimeo' ? [] : ['progress', 'volume'],
        autoplay: true,
    });

    useEffect(() => {
        document.addEventListener("visibilitychange", function () {
            if (pauseOnBlur && player) {
                if (document.visibilityState === 'hidden') {
                    player.pause()
                } else {
                    player.play()
                }
            }
        });
    })

    useEffect(() => {
        if (!iframe && video.id) {
            setVideo({ provider: video.provider, id: video.id });
            setIframe(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [video]);

    /**
     * 
     * @param uuid 
     */
    const finish = async (uuid: string) => {
        try {
            await coursesApiClient.request(`/api/v1/courses/video-results/${uuid}/finish`, {}, 'POST');
        } catch (error) {
            message.error(intl.formatMessage({ id: 'error.data_load' }));
            console.error(error);
        }
    }

    /**
     * 
     * @param uuid 
     */
    const endVideoSession = async (uuid: string) => {
        setEndLoading(true)
        try {
            await coursesApiClient.request(`/api/v1/courses/video-results/${uuid}/end`, {}, 'POST');
            window.self.close();
        } catch (error) {
            message.error(intl.formatMessage({ id: 'error.data_load' }));
            console.error(error);
        } finally {
            setEndLoading(false)
        }
    }

    /**
     * 
     * @param uuid 
     */
    const startActivity = (uuid: string) => {
        coursesApiClient.request(`/api/v1/courses/video-results/${uuid}/initialize`, {}, 'POST');
    }

    /**
     * 
     * @param id 
     * @param provider 
     * @returns 
     */
    const constructVideoPlayer = (id: string, provider: string) => {
        player.on('ended', () => finish(material?.uuid));
        return (
            <div className={disableProgress ? 'container-disabled' : 'container'}>
                <div id="player" data-plyr-provider={provider} data-plyr-embed-id={id}></div>
                <div className='buttons-container'>
                    <div className='button-wrapper'>
                        <div className='rewind-button'>
                            <Button onClick={() => player.rewind(5)} >
                                <i className="fa-solid fa-backward"></i>
                                <FormattedMessage id='courses.rewind_video' />
                            </Button>
                        </div>
                        {provider !== 'vimeo' &&
                            <Button onClick={() => player.togglePlay()}>
                                <i className="fa-solid fa-play-pause"></i>
                            </Button>
                        }
                    </div>
                    <Button loading={endLoading} onClick={() => endVideoSession(material.uuid)}>
                        <FormattedMessage id='courses.finish' />
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <>
            {video.id &&
                constructVideoPlayer(video.id, video.provider)
            }
        </>
    );
};

export default connect(mapStateToProps)(VideoPlayer);
