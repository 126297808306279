import React, { useState, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Table, message, Popover, Form, Modal, Button, Radio } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { changeMailTemplateFilterOptions } from 'store/actions/saveMailTemplateFilterOptions';
import { changeMailTemplateOrganizationOptions } from 'store/actions/saveMailTemplateOrganizationOptions';
import DefaultLayout from 'components/DefaultLayout';
import { CustomerSelect } from 'components/Form';
import apiClient from 'utils/apiClient';
import config from 'config/config';

const mapStateToProps = (state: any) => {
  return {
    session: state.session,
    mailTemplateFilterOptions: state.mailTemplateFilterOptions,
    mailTemplateOrganizationsOptions: state.mailTemplateOrganizationOptions,
    locale: state.locale.locale
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  changeMailTemplateFilterOptions: (mailTemplateFilterOptions: any) => dispatch(changeMailTemplateFilterOptions(mailTemplateFilterOptions)),
  changeMailTemplateOrganizationOptions: (mailTemplateOrganizationsOptions: any) => dispatch(changeMailTemplateOrganizationOptions(mailTemplateOrganizationsOptions))
});

const OrganizationTemplates: React.FC<any> = ({
  history,
  session,
  mailTemplateOrganizationsOptions,
  changeMailTemplateOrganizationOptions,
  changeMailTemplateFilterOptions,
  setLanguageEditRecord,
  setSelecteTemplate,
  setSelectedLanguageKey,
  setShowEditModal,
  locale,
  setTemplateSubject
}:any) => {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleTooltip, setVisibleTooltip] = useState<number|null>(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [customerType, setCustomerType] = useState<any>();
  const [showTemplateOrganizations, setShowTemplateOrganizations] = useState(false);
  const [templatesOwner, setTemplatesOwner] = useState();

  const intl = useIntl();
  const [form] = Form.useForm();

  useEffect(() => {
    loadOrganizationTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  const handleVisibleChange = (visible: boolean, recordId: number) => {
    setVisibleTooltip(visible ? recordId : null);
  }

  const loadOrganizationTemplates = async () => {
      setIsLoading(true);

      try {
          if (session.active_user_type === 'SUPER_ADMIN' && mailTemplateOrganizationsOptions.owner) {
              if ( mailTemplateOrganizationsOptions.owner.customerType === "RESELLER" || mailTemplateOrganizationsOptions.owner.customerType === "DISTRIBUTOR") {
                  let templatesResponse = await apiClient.request(`/api/v1/organizations/${mailTemplateOrganizationsOptions.owner.ownerId}/mail-templates`, {}, 'GET');
                  setTemplatesOwner(mailTemplateOrganizationsOptions.owner.ownerId);
                  setTemplates(templatesResponse.mailTemplates);
              }
          } else if (session.organization)  {
              let templatesResponse = await apiClient.request(`/api/v1/organizations/${session.organization.organization_id}/mail-templates`, {}, 'GET');
              setTemplatesOwner(session.organization.organization_id);
              setTemplates(templatesResponse.mailTemplates);
          }
      } catch (error) {
          console.error(error);
          message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
          setIsLoading(false);
      }
  }

  const columns = [
    {
      title: intl.formatMessage({id: 'emailing.templates.template_name'}),
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => {
        return (
          <NavLink to={`/e-mailing/manage-templates/organization/${record.organization_id}/${record.uuid}/edit`}>{record.name_transaltions[locale] ? record.name_transaltions[locale] : record.name_transaltions['EN'] ? record.name_transaltions['EN'] : Object.values(record.name_transaltions)[0]}</NavLink>
        )
      }
    },
    {
      title: intl.formatMessage({id: 'emailing.templates.template_type'}),
      dataIndex: 'templateType',
      key: 'templateType',
      render: (text: string, record: any) => intl.formatMessage({id: `email_templates.template_type.${record.template_type}`})
    },
    {
      title: intl.formatMessage({id: 'emailing.templates.template_category'}),
      dataIndex: 'categoryCode',
      key: 'categoryCode',
      render: (text: string, record: any) => intl.formatMessage({id: `email_templates.categories.${record.category_code}`})
    },
    {
      title: intl.formatMessage({id: 'general.language'}),
      dataIndex: 'language',
      key: 'language',
      render: (text: string, record: any) => {
        let html;
        if (record.html_template) {
          html = record.html_template
        } else {
          html = record.html
        }
        return (
              <Radio.Group>
                { html ?
                  Object.entries(html).map((el:any, index:any)=>{
                    return (
                        <>
                          {el ?
                            <Radio.Button style={{ textTransform: 'uppercase'}} value={el[0]} onClick={() => {
                              if (record.html_template) {
                                setLanguageEditRecord(record.html_template[el[0]]);
                              } else {
                                setLanguageEditRecord(record.html[el[0]]);
                              }
                              setTemplateSubject(record.subject_translations[el[0]])
                              setSelecteTemplate(record);
                              setSelectedLanguageKey(el[0]);
                              setShowEditModal(true)}}
                            >
                              {el[0]}
                            </Radio.Button>
                            : null
                          }
                        </>
                      )
                  })
                  : null
                }
              </Radio.Group>

        )
      }
    },
    {
      key: 'actions',
      render: (text: string, record: any) => {

        const deleteButton = (
            <div onClick={() => {confirmTemplateDeletion(record.uuid); setVisibleTooltip(null)}} className="popover-item">
                <FormattedMessage id="general.delete"/>
            </div>
        );

        const content = <>
          <NavLink to={`/e-mailing/manage-templates/organization/${record.organization_id}/${record.uuid}/edit`}>
            <div className="popover-item" >
              <FormattedMessage id="general.edit"/>
            </div>
          </NavLink>
            {record.category_code !== 'system_events' && session.active_user_type === 'DISTRIBUTOR_ADMIN' &&
              deleteButton
            }
            {session.active_user_type === 'SUPER_ADMIN' &&
              deleteButton
            }
        </>;

        return (
          <Popover
            visible={record.id === visibleTooltip}
            content={content}
            trigger="click"
            placement="bottomRight"
            arrowPointAtCenter
            onVisibleChange={(visible) => handleVisibleChange(visible, record.id)}
          >
            <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
              <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
            </div>
          </Popover>
        );
      },
    }
  ];

  const confirmTemplateDeletion = (uuid: string) => {
      Modal.confirm({
          title: intl.formatMessage({id: 'general.attention'}),
          icon: <ExclamationCircleOutlined />,
          content: intl.formatMessage({id: 'emailing.templates.begin_deletion_org'}),
          okText: intl.formatMessage({id: 'general.delete'}),
          okType: 'danger',
          cancelText: intl.formatMessage({id: 'general.cancel'}),
          onOk() {
              deleteTemplate(uuid);
          }
      })
  };

  const deleteTemplate = async (uuid: string) => {
      try {
          await apiClient.request(`/api/v1/mail-templates/organizations/${uuid}/delete`, {}, 'DELETE');
      } catch (error: any) {
          message.error(error);
          console.error(error);
      }
  };

  const selectOwner = async (value: any) => {

    if (showTemplateOrganizations) {
      history.push(`/e-mailing/manage-templates/organization/${templatesOwner}/add`)
      setShowTemplateOrganizations(false);
    }

    try {
      setIsLoading(true);
      setShowFilterModal(false);

      const templatesResponse = await apiClient.request(`/api/v1/organizations/${value.organization}/mail-templates`, {}, 'GET');
      setTemplates(templatesResponse.mailTemplates);

      const ownerId = value.customer || value.organization;

      const selectedOrganization = {
        ownerId,
        customerType,
      };

      changeMailTemplateFilterOptions({ owner: selectedOrganization });
      changeMailTemplateOrganizationOptions({ owner: selectedOrganization });
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({ id: 'error.data_load' }));
    } finally {
      setShowFilterModal(false);

      setIsLoading(false);
    }
  }

  const checkOwner = () => {
    if (session.active_user_type === 'SUPER_ADMIN') {
      setShowFilterModal(true);
      setShowTemplateOrganizations(true)
    } else {
      history.push(`/e-mailing/manage-templates/organization/${templatesOwner}/add`)
    }
  }

  return (
    <DefaultLayout.PageContent style={{borderTopLeftRadius: 0}}>
      <Button loading={session.active_user_type === 'SUPER_ADMIN' ? false : !templatesOwner} type="primary" onClick={checkOwner}>
        <PlusOutlined/> <FormattedMessage id='general.add' />
      </Button>
      <Button
        type="primary"
        style={{ marginLeft: 10, display: session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'CUSTOMER_ADMIN' ? 'none' : '' }}
        onClick={() => { setShowFilterModal(true) }}
      >
        <FormattedMessage id="general.filter" />
      </Button>
      <Table
        locale={{ emptyText: intl.formatMessage({ id: 'general.found_no_data' }) }}
        style={{ margin: 0, marginTop: 15 }}
        columns={columns}
        loading={isLoading}
        dataSource={templates}
        size="middle"
        scroll={{ x: 800 }}
      />
      <Modal
        className="user-list-modal"
        visible={showFilterModal}
        title={intl.formatMessage({ id: 'emailing.templates.select_organization' })}
        onOk={() => form.submit()}
        onCancel={() => setShowFilterModal(false)}
        okText={intl.formatMessage({ id: 'general.select' })}
        cancelText={intl.formatMessage({ id: 'general.cancel' })}
      >
        <Form form={form} onFinish={selectOwner}>
          <CustomerSelect
            showSearch
            name="organization"
            label={intl.formatMessage({ id: 'general.reseller' })}
            url={config.api.routes.backend.organizationsOptions}
            integerKey={true}
            onChange={(value: any, option: any) => {setTemplatesOwner(value); setCustomerType(option.customerType); form.setFieldsValue({organizationalUnitId: []});}}
            isResponseArray={true}
            dataKey="organizations"
            mapDataEntries={(el: any) => ({ value: el.id, customerType: el.type, label: el.name })}
            valueKey="value"
            labelKey="label"
            customLayout={true}
            filterOption={(input: string, option: any) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          />
        </Form>
      </Modal>
    </DefaultLayout.PageContent>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OrganizationTemplates));
