import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {Alert, Empty, message, Space, Spin} from 'antd';
import CampaignCard from 'components/CampaignCard';
import { ActivityData } from './types';
import coursesApiClient from 'utils/coursesApiClient';

interface CampaignListProps {
  userUuid: string,
  setActivityData: React.Dispatch<React.SetStateAction<ActivityData>>,
  activeUserIsMe?: boolean,
}

const CampaignList: React.FC<CampaignListProps> = ({ userUuid, setActivityData, activeUserIsMe }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [campaignList, setCampaignList] = useState<any>([]);
  const [campaignFinishedList, setCampaignFinishedList] = useState<any>([]);
  const [campaignUnfinishedList, setCampaignUnfinishedList] = useState<any>([]);
  const [canceledList, setCanceledList] = useState<any>([]);
  const [hasNoCampaigns, setHasNoCampaigns] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (userUuid) {
      loadCampaigns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, userUuid]);

  const loadCampaigns = async () => {
    try {
      setIsLoading(true);

      const response = await coursesApiClient.request(`/api/v1/courses/student/campaign-list/${userUuid}/0`, {}, 'GET');
      const campaignList = response.campaigns;

      if (!response.campaigns.success) {
          setHasNoCampaigns(true);
          return;
      }

      setCampaignFinishedList(campaignList.campaigns.finished ? campaignList.campaigns.finished : []);
      setCampaignUnfinishedList( campaignList.campaigns.unFinished ? campaignList.campaigns.unFinished : []);
      setCampaignList(campaignList.campaigns.inProgress ? campaignList.campaigns.inProgress : [] );
      setCanceledList(campaignList.campaigns.canceled ? campaignList.campaigns.canceled : []);

    } catch (error: any) {
      if (error.message !== 'student_has_no_campaigns') {
        message.error(intl.formatMessage({ id: 'error.data_load' }));
        console.error(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCampaignClick = (campaign: any) => {
    setActivityData({
      dataIsSet: true,
      campaignId: campaign.id,
      enrollmentId: campaign.enrollmentId,
    });
  };

  const renderCampaigns = (campaigns:any) => {
    if (campaigns.length === 0) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={intl.formatMessage({ id: 'general.found_no_data' })}
        />
      );
    }

    return campaigns.map((campaign: any) =>
      {

        return (
          <CampaignCard
            key={campaign.id}
            campaign={campaign}
            reload={loadCampaigns}
            status={campaign.campaignStatus}
            onClick={() => handleCampaignClick(campaign)}
            preview
            bordered
          />
        )
      }

    );
  };

  return (
    <Spin spinning={isLoading}>
      {hasNoCampaigns &&
        <Alert className='activities-alert' message={intl.formatMessage({id: "general.info"})}
               description={
                   activeUserIsMe
                       ?
                       intl.formatMessage({id: "student.not_have_activities"}) :
                       intl.formatMessage({id: "student.has_no_campaigns"})

               }
               type="info" showIcon />
      }
      {
        campaignList.length ?
          <>
            <div style={{ minHeight: 50 }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                {!isLoading && renderCampaigns(campaignList)}
              </Space>
            </div>
          </>
        : null
      }
      {
        campaignFinishedList.length ?
          <>
            <h3 className='finished-unfinished'><FormattedMessage id="student.finished_learning" /></h3>
            <div style={{ minHeight: 50 }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                {!isLoading && renderCampaigns(campaignFinishedList)}
              </Space>
            </div>
          </>
        : null
      }
      {
        campaignUnfinishedList.length ?
          <>
            <h3 className='finished-unfinished'><FormattedMessage id="student.unfinished-learning" /></h3>
            <div style={{ minHeight: 50 }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                {!isLoading && renderCampaigns(campaignUnfinishedList)}
              </Space>
            </div>
          </>
        : null
      }
      {
        canceledList.length ?
          <>
            <h3 className='finished-unfinished'><FormattedMessage id="student.canceled_learning" /></h3>
            <div style={{ minHeight: 50 }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                {!isLoading && renderCampaigns(canceledList)}
              </Space>
            </div>
          </>
        : null
      }
    </Spin>
  );
};

export default CampaignList;
