import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Alert, message, Tabs, Table, Tooltip, Modal } from 'antd';
import FlexRow from '../../FlexRow';
import InstructionButton from 'components/VideoInstructions/InstructionButton';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { CampaignPhishingInterface } from '../types';
import PhishingCampaignResultModal from 'components/FormCampaign/PhishingCampaignResultModal';
import PhishingSettingsTabs from './PhishingSettingsTabs';
import PhishingOverview from 'components/FormCampaign/PhishingOverview';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import Button from 'components/Button';
import './styles.scss';
const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        userRole: state.session.active_user_type
    }
};

const CampaignPhishing: React.FC<CampaignPhishingInterface & RouteComponentProps> = ({
    campaignId,
    owner,
    previous,
    status,
    campaign,
    phishingSettings,
    phishingCampaign,
    setPhishingSettings,
    newCampaignStatus,
    validation,
    launchButton,
    currentStep,
    sendRequest,
    launching,
    userRole,
    submitTab,
    smartDephishSwitch,
    setSmartDephishSwitch
}) => {
    const [showResultModal, setShowResultModal] = useState(false);
    const [enrollmentId, setEnrollmentId] = useState();
    const [targetUsers, setTargetUsers] = useState([]);
    const { TabPane } = Tabs;

    const intl = useIntl();

    useEffect(() => {
        if (owner) {
            loadUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [owner]);

    const loadUsers = async () => {
        try {
            let usersResults = await apiClient.request(`/api/v1/auth/phishing-campaign/${campaignId}/users-results/${owner?.id}`, {}, 'GET');
            setTargetUsers(usersResults.userData);
        } catch (error) {
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }
    };

    const renderPhishingSettingsTab = () => {
        return (
            <PhishingSettingsTabs
                campaignId={campaignId}
                owner={owner}
                status={status}
                campaign={campaign}
                phishingSettings={phishingSettings}
                phishingCampaign={phishingCampaign}
                setPhishingSettings={setPhishingSettings}
                previous={previous}
                validation={validation}
                launchButton={launchButton}
                newCampaignStatus={newCampaignStatus}
                currentStep={currentStep}
                sendRequest={sendRequest}
                launching={launching}
                submitTab={submitTab}
                smartDephishSwitch={smartDephishSwitch}
                setSmartDephishSwitch={setSmartDephishSwitch}
            />
        )
    };

    const checkIfUserAvailable = async (record: any) => {
        let parsedValues = {
            userUuid: record.userUuid,
            customerId: owner?.id
        };

        try {

            let response = await apiClient.request('/api/v1/manager/user', parsedValues, 'POST');

            if (response.allow) {
                setShowResultModal(true);
                setEnrollmentId(record.enrollmentId);
            } else {
                message.error(intl.formatMessage({ id: 'error.not_allowed' }));
            }
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }
    };

    const displayResultModal = (record: any) => {

        if (userRole === 'SUPERVISION') {
            checkIfUserAvailable(record);
        } else {
            setShowResultModal(true);
            setEnrollmentId(record.enrollmentId);
        }
    };

    const columns = [
        {
            title: intl.formatMessage({ id: 'phishing.name' }),
            dataIndex: 'name',
        },
        {
            title: intl.formatMessage({ id: 'phishing.last_name' }),
            dataIndex: 'lastName',
        },
        {
            title: intl.formatMessage({ id: 'phishing.email' }),
            dataIndex: 'email',
        },
        {
            title: intl.formatMessage({ id: 'phishing.organizational_unit' }),
            dataIndex: 'organizationalUnit',
        },
        {
            title: intl.formatMessage({ id: 'phishing.phishes_received' }),
            dataIndex: 'phishes_received',
            key: 'phishes_received',
            render: (_text: string, record: any) => {
                if (record.planedActivities !== 0) {
                    return (
                        <div className='phishes-recieved' onClick={() => { displayResultModal(record); }}>
                            <span>{record.planedActivities}</span>
                        </div>
                    )
                } else {
                    return '-';
                }
            }
        },
        {
            title: (
                <span>
                    {intl.formatMessage({ id: 'phishing.result' })}
                    <Tooltip title={intl.formatMessage({ id: 'phishing.results_hint' })}>
                        <i className='fal fa-question-circle header-item' />
                    </Tooltip>
                </span>),
            dataIndex: 'result',
            key: 'result',
            render: (_text: string, record: any) => {
                return (
                    <div>
                        {record.phishesReceived !== null ?
                            <div>
                                <span>{record.phishesFailed}/</span>
                                <span>{record.phishesReceived} </span>
                                {record.failRate !== null ?
                                    <>
                                        <span>( {record.failRate.toString()} %)</span>
                                    </>
                                    : null
                                }
                            </div>
                            : '-'
                        }
                    </div>
                )
            }
        }
    ];


    const getPhishingResults = async () => {
        Modal.info({
            title: intl.formatMessage({ id: 'general.info' }),
            content: intl.formatMessage({ id: 'phishing.phishing_results_message' }),
        });
        await coursesApiClient.request(`api/v1/courses/campaign-phishing/${campaignId}/results`, [], 'GET');
    };

    const getPhishingResultsButton = () => {
        if (status === 'DRAFT') {
            return;
        }

        if (userRole === 'SUPERVISION') {
            return;
        }

        return (
            <Button type='primary' onClick={getPhishingResults}><FormattedMessage id='phishing.get_results' /></Button>
        )
    };

    return (
        <>
            <FlexRow
                left={
                    <h1 className='form-group-header' style={{ marginBottom: 24, fontSize: 20 }} >{intl.formatMessage({ id: 'phishing.review_smart_dephish_settings' })}</h1>
                }
                right={
                    <InstructionButton position='SMART_DEPHISH' />
                }
            />
            {status !== 'DRAFT' ?
                <Tabs tabBarExtraContent={getPhishingResultsButton()}>
                    <TabPane tab={intl.formatMessage({ id: 'phishing.campaign_overview' })} key='overview'>
                        {phishingSettings && phishingSettings.summary ?
                            <PhishingOverview
                                campaignId={campaignId}
                                phishingCampaign={phishingSettings}
                            />
                            :
                            <Alert className='smartdephish-alert' message={intl.formatMessage({ id: 'general.found_no_data' })} type='info' showIcon style={{ marginBottom: 24 }} />
                        }
                    </TabPane>
                    <TabPane tab={intl.formatMessage({ id: 'phishing.target_users' })} key='target-users'>
                        {targetUsers ?
                            <Table
                                locale={{ emptyText: intl.formatMessage({ id: 'general.found_no_data' }) }}
                                style={{ marginTop: 16 }}
                                columns={columns}
                                dataSource={targetUsers}
                                size='middle'
                                scroll={{ x: 800 }}
                            />
                            :
                            <div className='phishing-not-started-alert'>
                                <Alert
                                    message={intl.formatMessage({ id: 'phishing.phishing_not_started' })}
                                    type='info'
                                    showIcon
                                />
                            </div>
                        }

                    </TabPane>
                    <TabPane tab={intl.formatMessage({ id: 'phishing.delivery_settings' })} key='delivery-settings'>
                        {renderPhishingSettingsTab()}
                    </TabPane>
                </Tabs>
                :
                <>{renderPhishingSettingsTab()}</>
            }

            <PhishingCampaignResultModal
                visible={showResultModal}
                onCancel={() => setShowResultModal(false)}
                enrollmentId={enrollmentId}
                setEnrollmentId={setEnrollmentId}
                campaignType={'STANDARD'}
            />
        </>
    )
};
export default connect(mapStateToProps)(withRouter(CampaignPhishing));
