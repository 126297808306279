import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from 'store/rootReducer';
import storage from 'redux-persist/lib/storage'
import config from "../config/config";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'locale',
    'environment',
    'campaignData',
    'scormData',
    'campaignActivityList'
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = config.api.environment === 'development' ? applyMiddleware(thunk, logger) : applyMiddleware(thunk);

export const store = createStore(
  persistedReducer,
  middleware
);

export const persistor = persistStore(store);

const exportedObject = {
  store,
  persistor
};

export default exportedObject;