import React from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

const PageUnderConstruction: React.FC = () => {
  return (
    <div className="page-under-construction-container">
      <h1><FormattedMessage id="general.page_under_construction"/></h1>
      <i className="fal fa-wrench"/>
    </div>
  )
}

export default PageUnderConstruction;
