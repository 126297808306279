import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import jwt from 'utils/jwt';

const LoginRoute = (props: any) => {
  return (
    !!jwt.get() ? <Redirect to="/" /> : <Route {...props} />
  )
}

export default LoginRoute;
