import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin, Form, message, Button } from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import { Input, Select, Switch } from 'components/Form';
import config from 'config/config';
import apiClient from 'utils/apiClient';
import methods from './methods';
import './styles.scss';

const mapStateToProps = (state: any) => {
  return {
    session: state.session
  }
}

const OrganizationForm: React.FC = ({history, match, session}: any) => {
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [organizationLoading, setOrganizationLoading] = useState(true);
  const [organizationTypeOptions, setOrganizationTypeOptions] = useState<any>([]);
  const [organizationType, setOrganizationType] = useState<string>();

  const intl = useIntl();
  const [form] = Form.useForm();
  const organizationId = match.params.id;

  useEffect(() => {
    if (!organizationId) {
      setOrganizationLoading(false);
    }
  }, [organizationId]);

  useEffect(() => {
    const loadOrganization = async () => {
      try {
        setOrganizationLoading(true);
        let organizationResponse = await apiClient.request(`/api/v1/organizations/${organizationId}`, {}, 'GET');
        let organization = organizationResponse.organization;
        form.setFieldsValue({
          name: organization.name,
          legalName: organization.legalName,
          country: organization.country,
          legalAddress: organization.legalAddress,
          officeAddress: organization.officeAddress,
          contactEmail: organization.contactEmail,
          itSupportEmail: organization.itSupportEmail,
          contactTelephone: organization.contactTelephone,
          status: organization.status,
          type: organization.type,
          canEditGlobalEnvironment: organization.canEditGlobalEnvironment
        });
        setOrganizationType(organization.type);
      } catch (err) {
        console.error(err);
        message.error(intl.formatMessage({id: "error.data_load"}));
      } finally {
        setOrganizationLoading(false);
      }
    };

    if (organizationId) {
      loadOrganization();
    }
    setTypeOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, form, organizationId]);

  const submitForm = async (values: any) => {
    try {
      setSubmitInProgress(true);
      if (match.params.id) {
        await apiClient.request(`/api/v1/organizations/${match.params.id}`, values, 'PUT');
      } else {
        await apiClient.request('/api/v1/organizations', values, 'POST');
      }
      setSubmitInProgress(false);
      message.success(intl.formatMessage({id:match.params.id ? "organization.reseller_successfully_updated" :  "organization.reseller_successfully_created"}));
      history.push('/organizations');
    } catch (error: any) {
      console.error(error);
      if (error.status === 422) {
        let errors = JSON.parse(error.message);
        form.setFields(methods.mapSubmitErrorsToForm(errors));
        message.error({
          title: 'Error',
          content: intl.formatMessage({id: "validation.organization_email_already_exists"})
        });
      } else {
        message.error(intl.formatMessage({id: "general.submit_error"}));    // TODO: Izmantot notification
      }
      setSubmitInProgress(false);
    }
  }

  const setTypeOptions = async () => {
    let options = []

    let types = await apiClient.request(`/api/v1/enum/partner-organization-types`, {}, 'GET');

    if (session.active_user_type === 'DISTRIBUTOR_ADMIN') {
      options = [{label: types['RESELLER'], value: 'RESELLER'}]
    } else {
      options = Object.keys(types).map(function(key) {
        return {label: types[key], value: key}
      })
    }

    setOrganizationTypeOptions(options)
  }

  return <DefaultLayout.PageLayout withStickyFooter>
    <DefaultLayout.PageHeader
      breadcrumb={[{name: intl.formatMessage({id: 'general.resellers'}), path: '/organizations'}]}
      title={intl.formatMessage({id: match.params.id ? 'organization.edit_reseller' : 'organization.add_reseller'})}
    />
    <Spin spinning={submitInProgress || organizationLoading} wrapperClassName={organizationLoading ? "spinner-with-white-background" : ""}>
      <DefaultLayout.PageContent withTopPadding >
        <Form onFinish={submitForm} form={form}>
          <Input name="name" label={intl.formatMessage({id: "organization.organization_name"})} validation={{required: true}}/>
          <Input name="legalName" label={intl.formatMessage({id: "organization.legal_name"})} validation={{required: true}}/>
          <Select
            showSearch
            filterOption={(input: any, option:any) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 }
            sortOptions={(a: any, b: any) => a[1].localeCompare(b[1])}
            classifierIsObject={true}
            name='country'
            label={intl.formatMessage({id: 'general.country'})}
            customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
            url={config.api.routes.enums.countries}
          />
          <Input name="legalAddress" label={intl.formatMessage({id: "organization.legal_address"})} validation={{required: true}}/>
          <Input name="officeAddress" label={intl.formatMessage({id: "organization.office_address"})} validation={{required: true}}/>
          <Input name="contactEmail" label={intl.formatMessage({id: "organization.business_contact_email"})} validation={{required: true, email: true}}/>
          <Input name="itSupportEmail" label={intl.formatMessage({id: "organization.it_support_email"})} validation={{required: true, email: true}}/>
          <Input name="contactTelephone" label={intl.formatMessage({id: "organization.contact_telephone"})} validation={{required: true}}/>
          <Select
            name='status'
            label={intl.formatMessage({id: 'organization.organization_status'})}
            customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
            url={config.api.routes.enums.organizationStatuses}
          />
          <Select
            name='type'
            label={intl.formatMessage({id: 'organization.partner_organization_types'})}
            customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
            options={organizationTypeOptions}
            onChange={(type:string)=> setOrganizationType(type)}
          />
          {organizationType === 'RESELLER' &&
            <Switch isFormItem hasDefaultLayout name='canEditGlobalEnvironment' label={intl.formatMessage({id: 'organizations.can_edit_environment_settings'})}/>
          }
        </Form>
      </DefaultLayout.PageContent>
      <DefaultLayout.PageFooter className='justify-content-end'>
        <div className="form-buttons">
          <NavLink to="/organizations">
            <Button style={{marginRight: 8}}>
              <FormattedMessage id="general.back" />
            </Button>
          </NavLink>
          <Button type="primary" htmlType="submit" loading={submitInProgress} onClick={() => form.submit()}>
            <FormattedMessage id="general.submit" />
          </Button>
        </div>
      </DefaultLayout.PageFooter>
    </Spin>
  </DefaultLayout.PageLayout>
}

export default connect(mapStateToProps)(withRouter(OrganizationForm));
