import React from 'react';
import { useIntl } from 'react-intl';
import DefaultLayout from 'components/DefaultLayout';
import StudentCampaignListDivision from 'components/StudentCampaignListDivision';
import './styles.scss';


const StudentCampaignList: React.FC = () => {
  const intl = useIntl();

  return (
      <DefaultLayout.PageLayout withoutPageHeader className="student-layout">
        <DefaultLayout.PageHeader title={intl.formatMessage({id: 'student.my_courses'})} breadcrumb={undefined}/>
        <StudentCampaignListDivision />
      </DefaultLayout.PageLayout>
  )
}
export default StudentCampaignList;