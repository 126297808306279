import React, {useState, useEffect} from 'react';
import { Select } from 'antd';
import apiClient from 'utils/apiClient';

const TimeZoneInput: React.FC<any> = (props) => {
  const [timeZones, setTimeZones] = useState<any>([]);
  const [isTimeZonesLoading, setIsTimeZonesLoading] = useState(false);

  const parseTimeZones = (timeZoneResponseArray: any) => {
    let timeZonesParsed = Object.keys(timeZoneResponseArray).map(el => {
      let label = el;
      let numericValue = parseInt(el);

      if (numericValue > 0) {
        label = `+${el}:00`
      } else if (numericValue < 0) {
        label = `${el}:00`
      }

      return ({
        value: el,
        label: label
      })
    })

    return timeZonesParsed
  }

  useEffect(() => {
    const loadTimeZones = async () => {
      setIsTimeZonesLoading(true);
      let timeZonesResponse = await apiClient.request('/api/v1/enum/time-zone-types', {}, 'GET');
      setTimeZones(parseTimeZones(timeZonesResponse));
      setIsTimeZonesLoading(false);
    }

    loadTimeZones();
  }, []);

  return (
    <Select loading={isTimeZonesLoading} {...props}>
      {timeZones.map((el: any) => 
        <Select.Option key={el.value} value={el.value}>{el.label}</Select.Option>
      )}
    </Select>
  )
}

export default TimeZoneInput;
