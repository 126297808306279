import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Empty, message, Spin } from 'antd';
import apiClient from 'utils/coursesApiClient';
import { AppearanceSettingsData } from 'interfaces/redux';

interface NoSettingsFoundProps {
  currentSettingsAreSameAsSaved: () => boolean;
  getAppearanceSettingsData: () => ({ resellerId: number }|{ customerId: number } | {});
  loadAppearanceSettings: (data: AppearanceSettingsData) => void;
  getUrl: () => string
  loadSettings: () => Promise<void>;
}

const NoSettingsFound: React.FC<NoSettingsFoundProps> = ({
  currentSettingsAreSameAsSaved,
  getAppearanceSettingsData,
  loadAppearanceSettings,
  getUrl,
  loadSettings,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const intl = useIntl();

  const handleButtonClick = async () => {
    try {
      setIsSubmitting(true);

      await apiClient.request(getUrl(), {}, 'POST');

      message.success(`${intl.formatMessage({ id: 'system.environment.settings_created' })}!`);

      if (currentSettingsAreSameAsSaved()) {
        loadAppearanceSettings(getAppearanceSettingsData());
      }

      await loadSettings();
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({ id: 'error.data_load' }));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Spin spinning={isSubmitting}>
      <Empty
        description={intl.formatMessage({ id: 'system.environment.no_settings_found' })}
        style={{ marginBottom: 24 }}
      >
        <Button type="primary" onClick={handleButtonClick}>
          <FormattedMessage id="system.environment.create_settings" />
        </Button>
      </Empty>
    </Spin>
  );
};

export default NoSettingsFound;