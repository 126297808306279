import React, {useState, useEffect} from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Layout, message, Spin } from 'antd';
import { changeLocale } from 'store/actions/locale';
import { changeSidebarCollapsed } from 'store/actions/sidebarCollapsed';
import { clearSession, setSession, toggleSessionLoading, setSessionSelectedOrganization} from 'store/actions/session';
import { switchToLearningEnvironment, switchToAdministrationEnvironment } from 'store/actions/environment';
import { NavLink } from 'react-router-dom';
import Spinner from 'components/Spinner';
import Router from 'components/Router';
import Sidebar from 'components/Sidebar';
import PageLayout from './PageLayout';
import PageContent from './PageContent';
import PageHeader from './PageHeader';
import PageFooter from './PageFooter';
import PageFooterWithRow from './PageFooterWithRow';
import jwt from 'utils/jwt';
import apiClient from 'utils/apiClient';
import config from 'config/config';
import RoleSwitch from 'components/SessionSwitches/RoleSwitch';
import OrganizationSwitch from 'components/SessionSwitches/OrganizationSwitch';
import VideoInstruction from "../VideoInstructions/VideoInstruction";
import LanguageSwitch from 'components/LanguageSwitch';
import { loadAppearanceSettings } from 'store/actions/appearanceSettingsActions';
import { AppearanceSettings, AppearanceSettingsData } from 'interfaces/redux';
import Helmet from 'react-helmet';
import ThemeSwitcher from 'react-css-vars';
import Cookies from 'js-cookie';
import './styles.scss';

const { Header, Content } = Layout;

const mapStateToProps = (state: any) => ({
  environment: state.environment.environment,
  locale: state.locale.locale,
  session: state.session,
  languageDictionary : state.languageDictionary,
  appearanceSettings: state.appearanceSettings,
  appearanceSettingsLoading : state.appearanceSettings.loading,
  logoPath: state.appearanceSettings.logoPath,
  title: state.appearanceSettings.title,
});

const mapDispatchToProps = (dispatch: any) => ({
  changeLocale: (locale: string) => dispatch(changeLocale(locale)),
  changeSidebarCollapsed: (sidebarCollapsed: boolean) => dispatch(changeSidebarCollapsed(sidebarCollapsed)),
  setSession: (session: any) => dispatch(setSession(session)),
  setSessionSelectedOrganization: (organization:any) => dispatch(setSessionSelectedOrganization(organization)),
  clearSession: () => dispatch(clearSession()),
  toggleSessionLoading: () => dispatch(toggleSessionLoading()),
  switchToLearningEnvironment: () => dispatch(switchToLearningEnvironment()),
  switchToAdministrationEnvironment: () => dispatch(switchToAdministrationEnvironment()),
  loadAppearanceSettings: (data: AppearanceSettingsData) => dispatch(loadAppearanceSettings(data)),
});

interface DefaultLayoutProps {
  locale: string;
  session: any;
  changeLocale: Function;
  changeSidebarCollapsed: Function;
  setSession: Function;
  setSessionSelectedOrganization: Function;
  clearSession: Function;
  toggleSessionLoading: Function;
  environment: string;
  switchToLearningEnvironment: Function;
  switchToAdministrationEnvironment: Function;
  languageDictionary: any;
  loadAppearanceSettings: Function;
  appearanceSettings: AppearanceSettings;
  appearanceSettingsLoading: boolean;
  logoPath: any;
  title: string;
  history:any
}

const DefaultLayout = ({ locale, session, changeLocale, changeSidebarCollapsed, setSession, setSessionSelectedOrganization, clearSession, toggleSessionLoading, environment, switchToLearningEnvironment, switchToAdministrationEnvironment, languageDictionary, loadAppearanceSettings, appearanceSettings, appearanceSettingsLoading, logoPath, title, history}: DefaultLayoutProps) => {
  const [collapsed, setCollapsed] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<any>(null);
  const [selectedOrganizationType, setSelectedOrganizationType] = useState<any>(null);
  const [selectedUserTypeId, setSelectedUserTypeId] = useState<any>(null);
  const [roles, setRoles] = useState<any>([]);
  const [favicon, setFavicon] = useState();
  const [logo, setLogo] = useState();
  const [logoIsLoading, setLogoIsLoading] = useState(false);
  const [code, setCode] = useState();
  const [showVideoInstructionModal, setShowVideoInstructionModal] = useState(false);


  useEffect(() => {
    const authMe = async () => {
      try {
        loadAppearanceSettings([]);
        const response = await apiClient.request(config.api.routes.auth.me);

        if (!!response.data.firstLogin && response.data.active_user_type === 'CUSTOMER_ADMIN') {
          displayIntroVideo();
        }
        await setSession(response.data);
          if (response.data.active_user_type === 'SUPERVISION') {
              if (window.location.pathname === '/' || window.location.pathname === '/403') {
                  history.push('/courses/manage-campaigns');
              } else {
                  history.push(window.location.pathname);
              }
          }
      } catch (err: any) {
        if (err.status_code === 401 && err.message === "The token has been blacklisted") {
          if (Cookies.get('jwt')) {
            Cookies.remove('jwt');
            history.push('/')
          }
        }
        toggleSessionLoading();
      }
    };

    authMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const displayIntroVideo = async () => {
        const response = await apiClient.request(`/api/v1/instruction/get/INTRO_INSTRUCTION/${locale}`, {}, 'GET');

        if (response.code.length > 0) {
            setCode(response.code[0].code);

            setShowVideoInstructionModal(true);

            await apiClient.request('/api/v1/auth/intro-log', [], 'POST');
        }
    };

  useEffect(() => {
    changeSidebarCollapsed(collapsed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapsed]);

  useEffect(() => {
    const loadFavicon = async () => {
      try {
        const response = await apiClient.request(`api/v1${appearanceSettings.faviconPath}`, {}, 'GET', true, true);

        setFavicon(response);
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      }
    };

    if (appearanceSettings.faviconPath) {
      loadFavicon();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appearanceSettings.faviconPath]);

  useEffect(() => {
    if (session.active_user_type !== null) {
      if (session.active_user_type === 'STUDENT') {
        switchToLearningEnvironment();
      } else {
        switchToAdministrationEnvironment();
      }
    }
    let roles: any = [];

    let activeCustomerObj = Object.entries(session.user_types.customers || {})
      .map((el: any) => ({value: el[0], roles: el[1].roles}))
      .find((el: any) => el.roles[session.active_user_type] && el.roles[session.active_user_type][0].userTypeId === parseInt(session.active_user_type_id));
    let activeOrganizationObj = Object.entries(session.user_types.organizations || {})
      .map((el: any) => ({value: el[0], roles: el[1].roles}))
      .find((el: any) => el.roles[session.active_user_type] && el.roles[session.active_user_type][0].userTypeId === parseInt(session.active_user_type_id));

    if (activeCustomerObj) {
      roles = Object.entries(activeCustomerObj.roles).map((el: any) => ({userTypeId: el[1][0] ? el[1][0].userTypeId : null, role: el[0]}));
      setSelectedOrganization(`c${activeCustomerObj.value}`);
      setSessionSelectedOrganization({organization: 'CUSTOMER', organization_type:'CUSTOMER', organization_id: activeCustomerObj.value, organization_uuid: session.user_types.customers[activeCustomerObj.value].uuid});
    } else if (activeOrganizationObj) {
      roles = Object.entries(activeOrganizationObj.roles).map((el: any) => ({userTypeId: el[1][0] ? el[1][0].userTypeId : null, role: el[0]}));
      setSelectedOrganization(`o${activeOrganizationObj.value}`);

      let organizationType

      switch (session.active_user_type) {
        case 'RESELLER_ADMIN':
          organizationType = 'RESELLER'
          break;
        case 'DISTRIBUTOR_ADMIN':
          organizationType = 'DISTRIBUTOR'
          break;
      }

      setSessionSelectedOrganization({organization: organizationType, organization_type:"ORGANIZATION", organization_id: activeOrganizationObj.value, organization_uuid: session.user_types.organizations[activeOrganizationObj.value].uuid});
    }

    if (session.user_types.hasOwnProperty('superAdmin')) {
      roles.push({userTypeId: session.user_types.superAdmin, role: 'SUPER_ADMIN'});
    }

    setRoles(roles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session && session.active_user_type_id])

  useEffect(() => {
    if (selectedOrganization && selectedOrganizationType) {
      let userTypes = session.user_types || {};
      sessionStorage.removeItem('current');

      let selectedOrganizationObject = userTypes[selectedOrganizationType][selectedOrganization.substr(1)];
      toggleSessionLoading();

      if (selectedOrganizationObject) {
        if (selectedOrganizationObject.roles['RESELLER_ADMIN']) {
          jwt.switchUserRole(selectedOrganizationObject.roles['RESELLER_ADMIN'][0].userTypeId);
        } else if (selectedOrganizationObject.roles['DISTRIBUTOR_ADMIN']) {
          jwt.switchUserRole(selectedOrganizationObject.roles['DISTRIBUTOR_ADMIN'][0].userTypeId);
        } else if (selectedOrganizationObject.roles['CUSTOMER_ADMIN']) {
          jwt.switchUserRole(selectedOrganizationObject.roles['CUSTOMER_ADMIN'][0].userTypeId);
        } else if (selectedOrganizationObject.roles['SUPERVISION']) {
          jwt.switchUserRole(selectedOrganizationObject.roles['SUPERVISION'][0].userTypeId);
        } else if (selectedOrganizationObject.roles['STUDENT']) {
          jwt.switchUserRole(selectedOrganizationObject.roles['STUDENT'][0].userTypeId);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganization, selectedOrganizationType])

  useEffect(() => {
    if (selectedUserTypeId && selectedUserTypeId !== session.active_user_type_id) {
      toggleSessionLoading();
      jwt.switchUserRole(selectedUserTypeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUserTypeId])

  useEffect(() => {
    const loadLogo = async () => {
      try {
        setLogoIsLoading(true);

        const response = await apiClient.request(`api/v1${logoPath}`, {}, 'GET', true, true);

        setLogo(response);
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
        setLogoIsLoading(false);
      }
    };

    if (logoPath) {
      loadLogo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoPath]);

  const intl = useIntl();

  const customerOptions = Object.entries(session.user_types.customers || {}).map((el: any) => ({value: el[0], label: el[1].name, roles: el[1].roles}));
  const organizationOptions = Object.entries(session.user_types.organizations || {}).map((el: any) => ({value: el[0], label: el[1].name, roles: el[1].roles}));

  const switchToStudentRole = () => {
    return roles.map((element: any) => {
      if (element.role === 'STUDENT') {
        setSelectedUserTypeId(element.userTypeId)
      }
      return null;
    })
  }

  return (
    <Spinner spinning={session.loading || languageDictionary.loading || appearanceSettingsLoading} opaque={true}>
      <Helmet>
        {appearanceSettings.faviconPath === null
          ? <link rel="icon" type="image/png" href="/favicon.ico" sizes="16x16" />
          : <link rel="icon" type="image/png" href={favicon ? URL.createObjectURL(favicon) : undefined} sizes="16x16" />
        }
        <title>{config.api.appAppearance.title}</title>
      </Helmet>
      <ThemeSwitcher theme={appearanceSettings.theme} />
      <Layout style={{ minHeight: '100vh' }}>
        <Header className='site-header'>
          {environment === 'LEARNING'
            ?
              <div className="brand-logo">
                <NavLink to='/'>
                <Spin spinning={appearanceSettingsLoading || logoIsLoading} size="small">
                  {logoPath === null
                    ? <img src="/cs-logo.svg" alt={title} />
                    : <img src={logo ? URL.createObjectURL(logo) : undefined} alt={title} />
                  }
                </Spin>
                </NavLink>
              </div>
            : null
          }
          <div className='header-menu' >
            <OrganizationSwitch
              selectedOrganization={selectedOrganization}
              customers={customerOptions}
              organizations={organizationOptions}
              onClick={(value: number, type: any) => {setSelectedOrganizationType(type); setSelectedOrganization(value)}}
            />
            <RoleSwitch
              roles={roles}
              onClick={(value: number) => setSelectedUserTypeId(value)}
              selectedUserTypeId={session.active_user_type_id}
              usersName={`${session.name} ${session.surname}`}
            />
            <LanguageSwitch/>
            {environment === 'ADMINISTRATION'
              ? <NavLink to='/'><i className='fal fa-book header-item' onClick={() => switchToStudentRole()} /></NavLink>
              : (session.active_user_type !== 'STUDENT' ? <NavLink to='/'><i className='fal fa-user-cog header-item' onClick={() => switchToAdministrationEnvironment()} /></NavLink> : null)
            }
            {/* TODO */}
            {/* Kamēr nav funkcionalitāte tikmēr nerādīt */}
            {/* <i className='fal fa-bell header-item' />
            <i className='fal fa-cog header-item' /> */}
            {
              session.active_user_type !== 'STUDENT'
              ?
                <NavLink to='/support'>
                    <i className='fal fa-question-circle header-item' />
                </NavLink>
              : null
            }

            <i className='fal fa-sign-out header-item' onClick={() => {jwt.logout(); clearSession()}} />
          </div>
        </Header>
        {environment === 'ADMINISTRATION'
          ? <Sidebar collapsed={collapsed} setCollapsed={setCollapsed}/>
          : null
        }
        <Content>
          <Router/>
        </Content>
      </Layout>
        <VideoInstruction
            visible={showVideoInstructionModal}
            onCancel={() => setShowVideoInstructionModal(false)}
            videoCode={code && code}
            setCode={setCode}
        />
    </Spinner>
  );
}

DefaultLayout.PageLayout = PageLayout;
DefaultLayout.PageContent = PageContent;
DefaultLayout.PageHeader = PageHeader;
DefaultLayout.PageFooter = PageFooter;
DefaultLayout.PageFooterWithRow = PageFooterWithRow;

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
