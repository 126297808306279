import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import moment from 'moment';
import LineChart from 'components/Charts/LineChart';
import { DashboardChartProps } from './types';
import coursesApiClient from 'utils/coursesApiClient';

const ActivityLineChart: React.FC<DashboardChartProps> = ({ customerUuid }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  const intl = useIntl();

  const rotateArrayToEndAtCurrentMonth = (array: any[]) => {
    const currentMonth = moment().month();

    for (let i = 0; i < 11 - currentMonth; i++) {
      array.unshift(array.pop());
    }

    return array;
  }

  const monthsLabelsEndingWithCurrent = () => {
    const monthLabels = [
      intl.formatMessage({ id: 'general.january' }),
      intl.formatMessage({ id: 'general.february' }),
      intl.formatMessage({ id: 'general.march' }),
      intl.formatMessage({ id: 'general.april' }),
      intl.formatMessage({ id: 'general.may' }),
      intl.formatMessage({ id: 'general.june' }),
      intl.formatMessage({ id: 'general.july' }),
      intl.formatMessage({ id: 'general.august' }),
      intl.formatMessage({ id: 'general.september' }),
      intl.formatMessage({ id: 'general.october' }),
      intl.formatMessage({ id: 'general.november' }),
      intl.formatMessage({ id: 'general.december' }),
    ];

    return rotateArrayToEndAtCurrentMonth(monthLabels);
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const url = `/api/v1/courses/statistic/dashboard/campaigns/customer/${customerUuid}/completed-activities-over-time`;
        const response = await coursesApiClient.request(url);
        const responseData = response.statistic;

        const data = [...Object.values(responseData)];

        const parsedData = {
          labels: [
            ...monthsLabelsEndingWithCurrent(),
            '',
          ],
          datasets: [{
            backgroundColor: 'red',
            borderColor: '#63CDA3',
            pointBackgroundColor: '#63CDA3',
            pointBorderWidth: 0,
            pointHitRadius: 8,
            pointRadius: [...Array(11).fill(0), 6],
            data: [
              ...rotateArrayToEndAtCurrentMonth(data),
              null,
            ],
          }],
        };

        setData(parsedData);
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  return (
    <LineChart
      data={data}
      title={intl.formatMessage({ id: 'campaign.completed_activities' })}
      loading={isLoading}
    />
  );
}

export default ActivityLineChart;