import React, { useState, useEffect, createRef } from 'react';
import { Spin } from 'antd';
import { defaults, Doughnut } from 'react-chartjs-2';
import '../styles.scss';

// EXAMPLE DATA
// const data = {
//   labels: [
//     'Success',
//     'Failure'
//   ],
//   datasets: [{
//     data: [65, 59],
//     backgroundColor: [
//       'rgb(33, 208, 160)',
//       'rgb(244, 98, 100)'
//     ],
//     hoverBackgroundColor: [
//       'rgb(13, 188, 140)',
//       'rgb(224 ,68, 80)',
//     ]
//   }]
// }

interface DoughnutChartProps {
  data: any,
  title?: string,
  showLabelValues?: boolean,
  corner?: {
    text?: string,
    number?: number,
  },
  inner?: {
    text?: string,
    number?: number,
    small?: boolean,
  },
  footer?: string,
  cutoutPercentage?: number,
  loading?: boolean,
}

const DoughnutChart: React.FC<DoughnutChartProps> = ({
  data, title, showLabelValues = true, corner,
  inner, footer, cutoutPercentage = 85,
  loading = false
}) => {
  defaults.global.defaultFontFamily = 'Gilroy'

  const [chartWidth, setChartWidth] = useState(0);
  
  const chartRef = createRef<Doughnut>();

  /* Why height instead of width? Because width (of the canvas) is actually wider than the chart itself,
    however, height-wise the chart takes up the entire canvas, and since the doughnut chart has the same
    width and height, this height measurement can be used as the chart "width". */
  useEffect(() => setChartWidth(chartRef.current?.chartInstance.height), 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  const options = {
    cutoutPercentage: cutoutPercentage,
    onResize: (_chart: any, size: any) => {
      /* Why height instead of width? Because width (of the canvas) is actually wider than the chart itself,
       however, height-wise the chart takes up the entire canvas, and since the doughnut chart has the same
       width and height, this height measurement can be used as the chart "width". */
      setChartWidth(size.height);
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: (tooltip: any, data: any) => {
          if (data.datasets.length > 1) {
            const dataset = data.datasets[tooltip.datasetIndex];
            const index = tooltip.index;

            return ` ${parseInt(dataset.data[index]).toFixed(1)}%`;
          }

          const value = data.datasets[0].data[tooltip.index];
          const percentage = getPercentage(value, data.datasets[0].data);

          return ` ${value} (${percentage}%)`;
        },
        title: (tooltip: any, data: any) => {
          if (data.datasets.length > 1) {
            if (!tooltip[0]) {
              return null;
            }

            const tooltipIndex = tooltip[0].datasetIndex;
            const index = tooltipIndex > 1 ? tooltipIndex - 1 : tooltipIndex;

            return data.labels[index];
          }

          return data.labels[tooltip[0].index];
        }
      },
      filter: (tooltip: any) => data.datasets.length > 1 ? tooltip.index !== 1 : true,
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  }

  const getPercentage = (value: number, data: [number]) => {
    const sumOfDataValues = data.reduce((sum: number, value: number) => sum + value, 0);
    // Rounds to 1 decimal place
    return Math.round((value / sumOfDataValues * 100) * 10) / 10;
  }

  const renderLegend = (data: any) => (
    <div className="chart-legend">
      {data.labels?.map((label: string, index: number) => {
        return (
          <div key={label} className="chart-legend-item">
            {data.datasets.length > 1
              ? <div
                  className="chart-legend-badge"
                  style={{ backgroundColor: data.datasets[index >= 1 ? index + 1 : index].backgroundColor[0] }}
                />
              : <div
                  className="chart-legend-badge"
                  style={{ backgroundColor: data.datasets[0].backgroundColor[index] }}
                />
            }
            <div className="chart-legend-text">
              {showLabelValues && (
                data.datasets.length > 1
                  ? <p>
                      <strong>{parseInt(data.datasets[index >= 1 ? index + 1 : index].data[0]).toFixed(1)}%</strong>
                    </p>
                  : <p>
                      <strong>{getPercentage(data.datasets[0].data[index], data.datasets[0].data)}%</strong>
                    </p>
              )}
              <p className="chart-legend-label">{label.toLowerCase()}</p>
            </div>
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      {title && <p className="chart-title">{title}</p>}
      <Spin spinning={loading}>
        <div className="doughnut-wrapper">
          <Doughnut
            ref={chartRef}
            data={data}
            options={options}
          />
          {corner && (
            <div
              className="doughnut-corner"
              style={{ maxWidth: '30%' }}
            >
              {corner.text && <p className="doughnut-text">{corner.text}</p>}
              {corner.number !== undefined && (
                <p className="doughnut-number">
                  <strong>{corner.number}</strong>
                </p>
              )}
            </div>
          )}
          {inner && (
            <div
              className="doughnut-inner"
              style={{ width: chartWidth }}
            >
              {inner.text && <p className={`doughnut-text ${inner.small && 'small'}`}>{inner.text}</p>}
              {inner.number !== undefined && (
                <p className="doughnut-number">
                  <strong>{inner.number}</strong>
                </p>
              )}
            </div>
          )}
        </div>
        {renderLegend(data)}
        {footer && <p className="chart-footer">{footer}</p>}
      </Spin>
    </>
  );
}

export default DoughnutChart;
