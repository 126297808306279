import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Tag, Button } from 'antd';
import Table from 'components/Table';
import { connect } from 'react-redux';
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        userTableFilter: state.userTableFilter,
        session: state.session
    }
};

const ScenarioUsersTable: React.FC<any> = ({
    resourceUrl,
    users,
    setUsers,
    visible,
    onCancel,
    pageSizeOptions,
    setCurrentPageSize,
    totalRecords,
    setTotalRecords,
    currentPage,
    getRecords,
    setCurrentPage
}) => {

    const [isUsersLoading, setIsUsersLoading] = useState(false);

    const intl = useIntl();

    const columns = [
        {
            title: intl.formatMessage({ id: 'general.name' }),
            dataIndex: 'name',
        },
        {
            title: intl.formatMessage({ id: 'general.surname' }),
            dataIndex: 'surname',
        },
        {
            title: intl.formatMessage({ id: 'general.email' }),
            dataIndex: 'email',
        },
        {
            title: intl.formatMessage({ id: 'users.user_status' }),
            dataIndex: 'accountStatus',
            render: (_text: string, record: any, tag: any) => {
                let color;

                if (record.accountStatus) {
                    if (record.accountStatus === 'ACTIVE') color = 'green';
                    if (record.accountStatus === 'BLOCKED') color = 'red';
                    return (
                        <Tag color={color} key={tag}>
                            {intl.formatMessage({ id: `user.status.${record.accountStatus}` })}
                        </Tag>
                    )
                } else {
                    return '-';
                }
            }
        }
    ];



    return (
        <Modal
            className="user-list-modal"
            visible={visible}
            onCancel={onCancel}
            title={intl.formatMessage({ id: 'general.users' })}
            footer={<Button><FormattedMessage id='general.cancel' /></Button>}
            width={1000}
        >
            <Table
                resourceUrl={resourceUrl}
                setTotalRecords={setTotalRecords}
                totalRecords={totalRecords}
                loadedData={users}
                setLoadedData={setUsers}
                setCurrentPageSize={setCurrentPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setLoading={setIsUsersLoading}
                loading={isUsersLoading}
                columns={columns}
                pageSizeOptions={pageSizeOptions}
                getRecords={getRecords}
                hasSelection={false}
                size='small'
            />
        </Modal>
    )
};

export default connect(mapStateToProps)(ScenarioUsersTable);
