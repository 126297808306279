import React, {useEffect, useState} from 'react';
import {Button, Popover, Dropdown, Menu} from 'antd';
import VideoInstruction from "../VideoInstruction";
import {FormattedMessage, useIntl} from "react-intl";
import {connect} from "react-redux";
import apiClient from "utils/apiClient";
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        session: state.session
    }
};

const InstructionButton: React.FC<any> = ({position, locale, session}) => {
    const [showVideoInstructionModal, setShowVideoInstructionModal] = useState(false);
    const [codeList, setCodeList] = useState<any>([]);
    const [code, setCode] = useState();
    const [visiblePopover, setVisiblePopover] = useState(false);

    const userRole = session.active_user_type;

    const intl = useIntl();

    useEffect(() => {
        const loadInstruction = async () => {
            try {
                let url = '';
                if (position === 'LOGIN') {
                    url = `/api/v1/login-help/${locale}`
                } else {
                    url = `/api/v1/instruction/get/${position}/${locale}`;
                }

                const response = await apiClient.request(url, {}, 'GET');
                setCodeList(response.code);
            } catch (error) {
                console.error(error);
            }
        };

        if (userRole !== 'SUPERVISION') {
            loadInstruction();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const content = (
        <div className='instruction-position-error'>
            <p>
                <FormattedMessage
                    id={'system.instruction.position'}/> {intl.formatMessage({id: `system.positions_${position}`})}
            </p>
            <Button className='instruction-close-button' size='small' onClick={() => setVisiblePopover(false)}>
                <FormattedMessage id={'general.close'}/>
            </Button>
        </div>
    );

    const menu = (
        <Menu>
            {codeList &&
                Object.values(codeList).map((el: any) => {
                    return (
                        <Menu.Item>
                            <div onClick={() => {
                                setShowVideoInstructionModal(true);
                                setCode(el.code);
                            }}>
                                {el.name}
                            </div>
                        </Menu.Item>
                    );
                })
            }
        </Menu>
    );

    return (
        <>
            {codeList.length ?
                <>
                    {codeList.length ?
                        <>
                            {codeList.length === 1 ?
                                <Button
                                    className='help-button'
                                    onClick={() => {
                                        setCode(codeList[0].code);
                                        setShowVideoInstructionModal(true);
                                    }}
                                >
                                    <div className='icon-style'>
                                        <i className="fas fa-play"></i>
                                    </div>
                                </Button>
                                :
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <Button
                                        className='help-button'
                                        onClick={() => setShowVideoInstructionModal(true)}
                                    >
                                        <div className='icon-style'>
                                            <i className="fas fa-play"></i>
                                        </div>
                                    </Button>
                                </Dropdown>

                            }
                        </>
                        :
                        <div>
                            {userRole === 'SUPER_ADMIN' || userRole === 'DISTRIBUTOR_ADMIN' || userRole === 'RESELLER_ADMIN' ?
                                <Button
                                    className='help-button'
                                    onClick={() => setVisiblePopover(true)}
                                >
                                    <div className='icon-style'>
                                        <i className="fas fa-play"></i>
                                    </div>
                                </Button>
                                : null
                            }
                        </div>
                    }
                    {code ?
                        <VideoInstruction
                            visible={showVideoInstructionModal}
                            onCancel={() => setShowVideoInstructionModal(false)}
                            videoCode={code}
                            setCode={setCode}
                        />
                        :
                        <div className='instruction-popover'>
                            <Popover
                                placement='left'
                                visible={visiblePopover}
                                content={content}
                                title={intl.formatMessage({id: 'system.instruction_video_not_found'})}
                            />
                        </div>
                    }
                </>
                : null
            }
        </>
    )
};

export default connect(mapStateToProps)(InstructionButton);
