import React, { useState, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import {Table, message, Popover, Radio, Button, Modal} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import { connect } from 'react-redux';
import config from 'config/config';

const mapStateToProps = (state: any) => {
  return {
    session: state.session,
    locale: state.locale.locale
  }
}

const StandardTemplates: React.FC<any> = ({
  props,
  session,
  locale,
  setLanguageEditRecord,
  setSelecteTemplate,
  setSelectedLanguageKey,
  setShowEditModal,
  setTemplateSubject
  }:any) => {
  const [isLoading, setIsLoading] = useState(true);
  const [templates, setTemplates] = useState([]);
  const [visibleTooltip, setVisibleTooltip] = useState<number|null>(null);

  const intl = useIntl();

  useEffect(() => {
    loadStandardTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  const loadStandardTemplates = async () => {
      try {
          setIsLoading(true);
          const templatesResponse = await apiClient.request(config.api.routes.backend.standardTemplates, {}, 'GET');
          setTemplates(templatesResponse.emailTemplates);
      } catch (error) {
          console.error(error);
          message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
          setIsLoading(false);
      }
  };

  const handleVisibleChange = (visible: boolean, recordId: number) => {
    setVisibleTooltip(visible ? recordId : null);
  };

  const columns = [
    {
      title: intl.formatMessage({ id: 'emailing.templates.template_name' }),
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => {
        return (
          <NavLink to={`/e-mailing/manage-templates/${record.uuid}/edit`}>{record.name[locale] ? record.name[locale] : record.name['EN'] ? record.name['EN'] : Object.values(record.name)[0]}</NavLink>
        )
      }
    },
    {
      title: intl.formatMessage({ id: 'emailing.templates.template_type' }),
      dataIndex: 'templateType',
      key: 'templateType',
      render: (text: string, record: any) => intl.formatMessage({id: `email_templates.template_type.${record.template_type}`})
    },
    {
      title: intl.formatMessage({ id: 'emailing.templates.template_category' }),
      dataIndex: 'categoryCode',
      key: 'categoryCode',
      render: (text: string, record: any) => intl.formatMessage({id: `email_templates.categories.${record.category_code}`})
    },
    {
      title: intl.formatMessage({ id: 'general.language' }),
      dataIndex: 'language',
      key: 'language',
      render: (text: string, record: any) => {
        return (
          <div>
            {
              record.html_template ?
              <Radio.Group>
                {
                  Object.entries(record.html_template).map((el:any, index:any)=>{
                    return (
                        <>
                          {el ?
                            <Radio.Button style={{ textTransform: 'uppercase'}} value={el[0]} onClick={() => {
                              if (record.html_template) {
                                setLanguageEditRecord(record.html_template[el[0]]);
                              } else {
                                let htmlString = JSON.parse(record.html);
                                setLanguageEditRecord(htmlString[el[0]]);
                              }
                              setTemplateSubject(record.subject[el[0]])
                              setSelecteTemplate(record);
                              setSelectedLanguageKey(el[0]);
                              setShowEditModal(true)}}
                            >
                              {el[0]}
                            </Radio.Button>
                            : null
                          }
                        </>
                      )
                  })
                }
              </Radio.Group>
              :null
            }
          </div>
        )
      }
    },
    {
      key: 'actions',
      render: (record: any) => {
        const content = (
            <>
                <NavLink to={`/e-mailing/manage-templates/${record.uuid}/edit`}>
                    <div className="popover-item">
                        <FormattedMessage id="general.edit"/>
                    </div>
                </NavLink>
                <div onClick={() => {confirmTemplateDeletion(record.uuid); setVisibleTooltip(null)}} className="popover-item">
                    <FormattedMessage id="general.delete"/>
                </div>
            </>
        );

        return (
          <Popover
            visible={record.id === visibleTooltip}
            content={content}
            trigger="click"
            placement="bottomRight"
            arrowPointAtCenter
            onVisibleChange={(visible) => handleVisibleChange(visible, record.id)}
          >
            <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
              <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} />
            </div>
          </Popover>
        );
      },
    },
  ];

  const confirmTemplateDeletion = (uuid: string) => {
      Modal.confirm({
          title: intl.formatMessage({id: 'general.attention'}),
          icon: <ExclamationCircleOutlined />,
          content: intl.formatMessage({id: 'emailing.templates.begin_deletion_org'}),
          okText: intl.formatMessage({id: 'general.delete'}),
          okType: 'danger',
          cancelText:  intl.formatMessage({id: 'general.cancel'}),
          onOk() {
              deleteTemplate(uuid);
          }
      })
  };

  const deleteTemplate = async (uuid: string) => {
      try {
          await apiClient.request(`/api/v1/mail-templates/${uuid}/delete`, {}, 'DELETE');
      } catch (error: any) {
          message.error(error);
          console.error(error);
      }
  };

  return (
    <DefaultLayout.PageContent style={{ borderTopLeftRadius: 0 }}>
      <NavLink to="/e-mailing/manage-templates/add">
        <Button type="primary">
          <PlusOutlined/> <FormattedMessage id="general.add" />
        </Button>
      </NavLink>
      <Table
        locale={{ emptyText: intl.formatMessage({ id: 'general.found_no_data' }) }}
        style={{ margin: 0, marginTop: 15 }}
        columns={columns}
        loading={isLoading}
        dataSource={templates}
        size="middle"
        scroll={{ x: 800 }}
        rowKey="id"
      />
    </DefaultLayout.PageContent>
  );
}

export default connect(mapStateToProps)(withRouter(StandardTemplates));
