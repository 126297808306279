
import React from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import config from "config/config";
import { Modal, Form } from 'antd';
import { Select } from 'components/Form';
import { changeCampaignData } from "store/actions/campaignData";

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        locale: state.locale
    }
};

interface CampaignFilter {
    visible: boolean;
    onCancel: any;
    session: any;
    getRecords: any;
    currentPageSize: number;
    changeCampaignData: any;
    setPhishingCampaigns: any
    setTotalRecords: React.Dispatch<React.SetStateAction<number>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>
    resourceUrl: string;
}

const mapDispatchToProps = (dispatch: any) => ({
    changeCampaignData: (formData: any) => dispatch(changeCampaignData(formData)),
});

const PhishingCampaignFilterModal = ({ visible, onCancel, getRecords, currentPageSize, session, changeCampaignData, setPhishingCampaigns, setTotalRecords, setLoading, resourceUrl }: CampaignFilter) => {

    const intl = useIntl();
    const [form] = Form.useForm();

    const submitForm = async (values: any) => {
        let pageQuery = 'page[number]=' + 1 + '&page[size]=' + currentPageSize;

        let customer
        if (values.customer) {
            customer = values.customer.split(',');
        }

        let customerId = session.active_user_type === 'CUSTOMER_ADMIN' ? session.organization.organization_id : customer[1];

        let customerUuid = session.active_user_type === 'CUSTOMER_ADMIN' ? session.organization.organization_uuid : customer[0];

        setLoading(true);
        onCancel();

        let parsedValues = {
            customerUuid: customerUuid,
            status: values.status
        };

        let response = await getRecords(resourceUrl, pageQuery, parsedValues, customerUuid, customerId);

        setPhishingCampaigns(response.data);
        setTotalRecords(response.pagination.total);
        setLoading(false);

        changeCampaignData({ customerId: customerId, customerUuid: customerUuid });
    };

    return (
        <Modal
            className="filter-phishing"
            visible={visible}
            onCancel={() => onCancel()}
            title={intl.formatMessage({id: 'phishing.filter_campaign'})}
            okText={intl.formatMessage({id: 'general.filter'})}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
            onOk={form.submit}
        >
            <Form form={form} onFinish={submitForm}>
                {session.active_user_type === 'SUPER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN' || session.active_user_type === 'RESELLER_ADMIN' ?
                    <Select
                        showSearch
                        name='customer'
                        label={intl.formatMessage({id: "general.select_customer"})}
                        url={config.api.routes.backend.organizationsCustomers}
                        integerKey={true}
                        customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
                        isResponseArray={true}
                        dataKey='customers'
                        mapDataEntries={(el: any) => ({value: el.uuid + ',' + el.id, label: el.name})}
                        valueKey='value'
                        labelKey='label'
                        customLayout={true}
                        filterOption={(input: string, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                    />
                    : null
                }
                <Select
                    allowClear
                    name='status'
                    customLayout={true}
                    label={intl.formatMessage({ id: 'general.status' })}
                    url={config.api.routes.enums.campaignStatusTypes}
                />
            </Form>
        </Modal>
    )
};
export default connect(mapStateToProps, mapDispatchToProps)(PhishingCampaignFilterModal);
