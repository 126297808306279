import * as ActionTypes from 'store/actionTypes';

export const changeCampaignActivityAction = ({ campaignId, type }: any) => ({
  type: ActionTypes.SAVE_CUSTOM_CAMPAIGN_ACTIVITY,
  payload: {
    campaignId: campaignId,
    type: type
  }
});

export const clearCampaignActivityData = () => ({
  type: ActionTypes.CLEAR_CUSTOM_CAMPAIGN_ACTIVITY,
});
