import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import OrganizationModal from './OrganizationModal';
import DefaultLayout from 'components/DefaultLayout';
import Spinner from 'components/Spinner';
import FlexRow from 'components/FlexRow';
import ConnectionForm from './ConnectionForm';
import { connect } from 'react-redux';
import { Button, message, Tabs, Form } from 'antd';
import UserSyncForm from './UserSyncForm';
import apiClient from 'utils/apiClient';

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        activeRole: state.session.active_user_type
    }
};

const ConnectionSettings: React.FC<any> = ({ session, activeRole }) => {

    const [submitInProgress, setSubmitInProgress] = useState(false),
        [connection, setConnection] = useState<any>(),
        [connectionType, setConnectionType] = useState<string>(''),
        [CRONExpression, setCRONExpression] = useState<string | null>(null),
        [form] = Form.useForm(),
        [isLoading, setIsLoading] = useState<boolean>(false),
        [showOrganizationModal, setShowOrganizationModal] = useState(false),
        [organization, setOrganization] = useState<any>(),
        adminRoles = ['RESELLER_ADMIN', 'SUPER_ADMIN', 'DISTRIBUTOR_ADMIN'],
        { TabPane } = Tabs,
        intl = useIntl();


    useEffect(() => {
        if (session.active_user_type === 'CUSTOMER_ADMIN') {
            setOrganization({
                type: 'CUSTOMER',
                id: session.organization.organization_id,
                uuid: session.organization.organization_uuid
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl])

    useEffect(() => {
        if (organization) {
            loadConnection();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organization]);


    const loadConnection = async () => {
        setIsLoading(true);
        try {
            const response = await apiClient.request(`/api/v1/system/connection/${organization.id}/${organization.type}`, {}, 'GET');

            if (!Array.isArray(response.connection)) {
                setConnection(response.connection);
            }

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsLoading(false);
        }
    }

    /**
     * 
     * @param values 
     */
    const submitForm = async (values: any) => {
        setSubmitInProgress(true);
        const parsedValues = {
            settings: {
                mapping: {
                    ...values.mapping,
                },
                auth: {
                    ...values.auth,
                },
                userSync: {
                    ...values.userSync,
                }
            },
            connectionSettings: {
                ...values.connection
            },
            ownerId: organization.id,
            ownerType: organization.type,
            cronExpression: CRONExpression ? CRONExpression : connection?.cron_expression ? connection?.cron_expression : null
        }

        try {
            if (connection && connection?.id) {
                await apiClient.request(`/api/v1/system/connection/${connection?.id}`, parsedValues, 'PUT');
            } else {
                await apiClient.request('/api/v1/system/connection', parsedValues, 'POST');
            }
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));

        } finally {
            setSubmitInProgress(false);
        }
    }

    return <DefaultLayout.PageLayout withStickyFooter>
        <DefaultLayout.PageHeader title={intl.formatMessage({ id: 'system.connection_settings' })} />
        <Spinner spinning={isLoading || submitInProgress} opaqueH={isLoading}>
            <DefaultLayout.PageContent>
                <FlexRow
                    left={
                        <>
                            {adminRoles.includes(activeRole) &&
                                <Button type="primary"
                                    onClick={() => setShowOrganizationModal(true)}>
                                    <FormattedMessage id="general.filter" />
                                </Button>
                            }
                        </>
                    }
                />
                {organization &&
                    <Form
                        form={form}
                        onFinish={submitForm}
                        autoComplete="off"
                    >
                        <Tabs>
                            <TabPane tab={intl.formatMessage({ id: 'system.connection' })} key='connection'>
                                <ConnectionForm
                                    form={form}
                                    connection={connection}
                                    isLoading={isLoading}
                                    connectionType={connectionType}
                                    setConnectionType={setConnectionType}
                                />
                            </TabPane>
                            <TabPane tab={intl.formatMessage({ id: 'general.user_sync' })} key='userSync'>
                                <UserSyncForm
                                    connectionType={connectionType}
                                    form={form}
                                    connection={connection}
                                    organization={organization}
                                    setCRONExpression={setCRONExpression}
                                />
                            </TabPane>
                        </Tabs>
                    </Form>
                }

            </DefaultLayout.PageContent>
        </Spinner>



        <OrganizationModal
            visible={showOrganizationModal}
            onCancel={() => setShowOrganizationModal(false)}
            setOrganization={setOrganization}
        />

        <DefaultLayout.PageFooter className='justify-content-end'>
            <div className="form-buttons">
                <Button
                    type="primary"
                    onClick={() => form.submit()}
                    loading={false}
                >
                    <FormattedMessage id="general.submit" />
                </Button>
            </div>
        </DefaultLayout.PageFooter>
    </DefaultLayout.PageLayout>
};

export default connect(mapStateToProps)(ConnectionSettings);
