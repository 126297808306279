
const getEditorContent = (editorObject: any) => {

    let editorContent;
    if (typeof editorObject === 'string') {
        editorContent = editorObject;
    } else if (editorObject && typeof editorObject !== 'string') {
        editorContent = editorObject.level.content;
    } else {
        editorContent = '';
    }

    return editorContent;
};


const exportedObject = {
    getEditorContent
};

export default exportedObject;