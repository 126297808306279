import * as ActionTypes from 'store/actionTypes';

const defaultState = {}

const CampaignCustomerFilter = (state = defaultState, action: any) => {
  switch(action.type) {
    case ActionTypes.SAVE_CAMPAIGN_CUSTOMER_FILTER:
      return {...action.payload};
    default:
      return state;
  }
}

export default CampaignCustomerFilter;
