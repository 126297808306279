import * as ActionTypes from 'store/actionTypes';

const defaultState = {
  campaignId: null,
  type: null
}

const customCampaignActivity = (state = defaultState, action: any) => {
  switch (action.type) {
    case ActionTypes.SAVE_CUSTOM_CAMPAIGN_ACTIVITY:
      let type = state;
      type = action.payload;
      return type;
    case ActionTypes.CLEAR_CUSTOM_CAMPAIGN_ACTIVITY:
      return (defaultState);
    default:
      return state;
  }
}

export default customCampaignActivity;