import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form, DatePicker, InputNumber, Typography, Transfer, Tooltip, message, Checkbox } from 'antd';
import { Input, Select } from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import './styles.scss';

const ELearningContent = ({
  formItemLayout, servicesLoading,
  services, serviceList, activitiesService, serviceId,
  setServiceId, disabledDates, setValidFromDate,
  setValidToDate, targetKeys, setTargetKeys,
  activitiesHasErrors, edit
}: any) => {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [transferData, setTransferData] = useState([]);

  const intl = useIntl();

  const { Title } = Typography;

  useEffect(() => {
    const loadActivities = async (service: any) => {
      const formContent = JSON.parse(service.formContent);
      const activitiesIds = formContent.course.activitiesIds;

      if (!edit) {
        setTargetKeys(activitiesIds);
      }

      try {
        const urlSuffix = edit ? '' : '/active';

        const response = await coursesApiClient.request(`/api/v1/courses/learning-activities/${activitiesIds.join()}${urlSuffix}`, {}, 'GET');

        const activityData: any = [];

        response.learningActivities.forEach((activity: any) => {
          if (targetKeys.includes(activity.id) || activity.status === 'ONLINE') {
            const title = JSON.parse(activity.title);
            activityData.push({
              key: activity.id,
              name: title[Object.keys(title)[0]],
            });
          }
        });

        setTransferData(activityData);
      } catch(error) {
        console.error(error);
        message.error(intl.formatMessage({id: 'error.data_load'}));
      }
    }

    const service = activitiesService.find((service: any) => service.id === parseInt(serviceId));

    if (service) {
      loadActivities(service);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [services, serviceId]);

	return (
		<>
      <Title level={4} style={{ fontSize: '1rem', marginBottom: '1rem' }}>{intl.formatMessage({id: 'licensing.licence_terms'})}</Title>
      {
        !edit
          ? <Select
              name="service"
              label={intl.formatMessage({id: 'licensing.service'})}
              validation={{required: true}}
              loading={servicesLoading}
              manualOptions={serviceList}
              onChange={(value: any) => setServiceId(parseInt(value))}
              customLayout={formItemLayout}
            />
          : <Input
              name="service"
              label={intl.formatMessage({id: 'licensing.service'})}
              readOnly
              customLayout={formItemLayout}
            />
      }

      <Form.Item
        name="validFrom"
        label={intl.formatMessage({id: 'licensing.valid_from'})}
        rules={[{ required: true, message: intl.formatMessage({id: 'validation.field_required'}) }]}
        {...formItemLayout}
      >
        <DatePicker
          showToday={false}
          format="DD/MM/YYYY"
          disabled={!(!!serviceId)}
          disabledDate={(current: any) => disabledDates(current)}
          placeholder=""
          onChange={(value: any) => setValidFromDate(value)}
        />
      </Form.Item>
      <Form.Item
        name="validTo"
        label={
          <span>
            {intl.formatMessage({id: 'licensing.valid_to'})}
            <Tooltip title={intl.formatMessage({id: 'e_learning_valid_to_max_length'})}>
              <i className='fal fa-question-circle header-item' />
            </Tooltip>
          </span>
        }
        rules={[{ required: true, message: intl.formatMessage({id: 'validation.field_required'}) }]}
        {...formItemLayout}
      >
        <DatePicker
          showToday={false}
          format="DD/MM/YYYY"
          disabled={!(!!serviceId) || services.length === 0}
          disabledDate={(current: any) => disabledDates(current, true)}
          placeholder=""
          onChange={(value: any) => setValidToDate(value)}
        />
      </Form.Item>
      <Form.Item
        className={activitiesHasErrors ? 'ant-form-item-with-help ant-form-item-has-error' : ''}
      >
        <Transfer
          className='transferer-style'
          locale={{notFoundContent: intl.formatMessage({id: "general.found_no_data"})}}
          dataSource={transferData}
          targetKeys={targetKeys}
          selectedKeys={selectedKeys}
          onSelectChange={(value: any) => { setSelectedKeys(value) }}
          onChange={(value: any) => { setTargetKeys(value) }}
          render={(item: any) => item.name}
          selectAllLabels={[intl.formatMessage({id: 'campaign.available'}, {amount: transferData.length > 0 ? transferData.length - targetKeys.length : 0}),intl.formatMessage({id: 'campaign.added'}, {amount: targetKeys.length})]}
          oneWay
          showSelectAll={false}
          disabled={!(!!serviceId)}
        />
        {
        activitiesHasErrors
          ? <div className="ant-form-item-explain">
              <div>{intl.formatMessage({id: 'select_at_least_1_activity'})}</div>
            </div>
          : null
        }
      </Form.Item>
      <Form.Item
        name="maxAccess"
        label={intl.formatMessage({id: 'licensing.max_access'})}
        rules={[{ required: true, message: intl.formatMessage({id: 'validation.field_required'}) }]}
        {...formItemLayout}
      >
        <InputNumber min={1} style={{ width: '100%' }}/>
      </Form.Item>
      {!edit &&
        <Form.Item name="splitLicenseServices" label={
          <span>
            {intl.formatMessage({id: 'licensing.license_split'})}
            <Tooltip title={intl.formatMessage({id: 'licensing.license_split_hint'})}>
              <i className='fal fa-question-circle header-item' />
            </Tooltip>
          </span>
          }
          {...formItemLayout}>
          <Checkbox.Group>
            <Checkbox value={true} />
          </Checkbox.Group>
        </Form.Item>
      }
      {/* This field is not needed for now */}
      {/*<Select*/}
      {/*  name="actionAfterLicenceEndTerm"*/}
      {/*  label={intl.formatMessage({id: 'licensing.action_after_licence_end_term'})}*/}
      {/*  validation={{required: true}}*/}
      {/*  url={config.api.routes.enums.actionAfterLicenceEndTermTypes}*/}
      {/*  customLayout={formItemLayout}*/}
      {/*/>*/}
    </>
	);
}

export default ELearningContent;
