import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {withRouter } from 'react-router-dom';
import { message, Form, Table, Popover, Modal, Button, Radio} from 'antd';
import Switch from 'components/Form/Switch';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import config from 'config/config';
import { connect } from 'react-redux';
import { Input, Select } from 'components/Form';
import InstructionButton from "components/VideoInstructions/InstructionButton";
import FlexRow from "components/FlexRow";
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {session: state.session}
  };

const ManageFilters: React.FC<any> = ({session}) => {
    const [customerFiltetrs, setCustomerFilters] = useState<any>([]);
    const [visibleTooltip, setVisibleTooltip] = useState<number|null>(null);
    const [isFiltersLoading, setIsFiltersLoading] = useState(false);
    const [showCreateFilterCreateModal, setShowFilterCreateModal] = useState(false);
    const [selectedCustomerModal, setSelectedCustomerModal] = useState(false);
    const [selectedCustomerId, setSelectedCustomerId] = useState();
    const [filterId, setFilterId] = useState<any>();
    const [showFilterButton, setShowFilterButton] = useState(false);
    const [disableAddNewFilterButton, setDisableAddNewFilterButton] = useState(true);
    const [editSwitch, setEditSwitch] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [customFilterDeleteId, setCustomFilterDeleteId] = useState<any>();
    const [customFilterEdit, setCustomFilterEdit] = useState(false);

    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(()=> {
        form.setFieldsValue({
            customFilterStatus: 'ACTIVE',
          });
    })

    useEffect(() => {
        const loadCustomer = async () => {
          if (session.active_user_type === "SUPER_ADMIN") {
              setSelectedCustomerModal(true);
              setShowFilterButton(true);
          } else if (session.active_user_type === "DISTRIBUTOR_ADMIN") {
              setSelectedCustomerModal(true);
              setShowFilterButton(true);
          } else if (session.active_user_type === "RESELLER_ADMIN") {
              setSelectedCustomerModal(true);
              setShowFilterButton(true);
          } else if (session.organization.organization_type === "CUSTOMER") {
            setSelectedCustomerId(session.organization.organization_id);
            let customeFilter = await apiClient.request(`/api/v1/customer-users/${session.organization.organization_id}/customer-custom-fields`, [] , 'GET');

            setCustomerFilters(customeFilter.customFields);
            setDisableAddNewFilterButton(false);
          }

        };
        loadCustomer();

        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

      const handleVisibleChange = (visible: boolean, rowIndex: number) => {
        if (visible) {
          setVisibleTooltip(rowIndex);
        } else {
          setVisibleTooltip(null);
        }
      }

      const createFilter = async (values: any) => {
        let slug = values.filterName.replace(/\s+/g, '_').toLowerCase();
        let parsedValues =
            {
              name: values.filterName,
              slug: slug,
              default: values.defaultValue,
              isRequired: editSwitch,
              status: values.customFilterStatus,
              customerId: selectedCustomerId
            }
        try {
            if (!filterId) {
                await apiClient.request('/api/v1/custom-fields', parsedValues, 'POST');
            } else{
                await apiClient.request(`/api/v1/custom-fields/${filterId}`, parsedValues, 'PUT');
            }
        } catch (err) {
          console.error(err);
          message.error(intl.formatMessage({id: "error.data_load"}));
        } finally {
            setShowFilterCreateModal(false);
            selectCustomer();
            setCustomFilterEdit(false);
            setFilterId(null);
        }
      }

    async function changeStatus(record:any) {

      let requestUrl = `/api/v1/custom-fields/${record.id}`;
      let newStatus = record.status === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE';

      let parsedValues =
      {
          name: record.name,
          slug: record.slug,
          default: record.default,
          isRequired: record.is_required,
          status: newStatus,
          customerId: selectedCustomerId
      }

      try {
        await apiClient.request( requestUrl, parsedValues, 'PUT');
      } catch (err) {
        console.error(err);
        message.error(intl.formatMessage({id: "error.data_load"}));
      }
    }

async function changeRequired(record:any) {

  let requestUrl = `/api/v1/custom-fields/${record.id}`;

  let newIsRequired = record.is_required === 1 ? 0 : 1;

  let parsedValues =
  {
      name: record.name,
      slug: record.slug,
      default: record.default,
      isRequired: newIsRequired,
      status: record.status,
      customerId: selectedCustomerId
  }
  try {

      await apiClient.request( requestUrl, parsedValues, 'PUT');

  } catch (err) {
    console.error(err);
    message.error(intl.formatMessage({id: "error.data_load"}));
  }
}

      const columns = [
        {
          title: intl.formatMessage({id: 'system.filter.name'}),
          dataIndex: 'name',
          key: 'name',
        },
        {
          title:
            <div>
              {intl.formatMessage({id: 'general.status'})}
            </div>,
          dataIndex: 'status',
            render: (value: string, record:any) => {
              let switchStatus;
              if (record.status === 'ACTIVE') switchStatus = true;
              if (record.status === 'INACTIVE') switchStatus = false;
              return (<Switch  onChange={() => changeStatus(record)} defaultChecked={switchStatus} />)
            }
        },
        {
          title:
          <div>
            {intl.formatMessage({id: 'general.mandatory'})}
            <Popover
              content={intl.formatMessage({id: 'campaign.mandatory_hint'})} >
                <i style={{marginLeft:"3px"}} className="fal fa-question-circle"></i>
            </Popover>
          </div>,
          dataIndex: 'is_required',
          key: 'is_required',

          render: (value: string, record:any) => {
            let switchStatus;
            if (record.is_required === 1) switchStatus = true;
            if (record.is_required === 0) switchStatus = false;
            return (<Switch  onChange={() => {changeRequired(record)}} defaultChecked={switchStatus} />)
          }
        },

        {
            title: intl.formatMessage({id: 'system.filter.default'}),
            dataIndex: 'default',
            key: 'default',
            render: (text: string, record: any) => record.default
        },
        {
            key: 'actions',
            render: (text: string, record: any, index: number) => {
              const content = <>
                  <div className="popover-item" onClick={() =>  {setCustomFilterEdit(true); setShowFilterCreateModal(true); setFilterId(record.id); handleVisibleChange(false, index);
                  setEditSwitch(record.is_required)
                    form.setFieldsValue({
                      filterName: record.name,
                      requirement:record.is_required,
                      defaultValue:record.default,
                      customFilterStatus:record.status
                    });
                  }}>

                    <FormattedMessage id="general.edit"/>
                  </div>
                  <div className="popover-item" onClick={() => { deleteCustomerFilter(record.id)}}>
                    <FormattedMessage id="system.filter.delete_filter"/>
                  </div>
              </>;

              return (
                <Popover
                  visible={index === visibleTooltip}
                  content={content}
                  trigger="click"
                  placement="bottomRight"
                  arrowPointAtCenter
                  onVisibleChange={(visible) => handleVisibleChange(visible, index)}
                >
                  <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                    <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
                  </div>
                </Popover>
              )
            },
          },
      ];
      const showTotal = (total: any, range: any) => {
        return `${range[0]}-${range[1]} of ${total}`
      };

    const selectCustomer = async () => {
      try {
        setSelectedCustomerModal(false);
        setIsFiltersLoading(true);

        const response = await apiClient.request(`/api/v1/customer-users/${selectedCustomerId}/customer-custom-fields`);

        setCustomerFilters(response.customFields);
      } catch (err) {
        console.error(err);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
        setDisableAddNewFilterButton(false);
        setIsFiltersLoading(false);
      }
    }

    const deleteCustomerFilter = async (id: string) => {
      try {
        setVisibleTooltip(null);  // hide tooltips

        await apiClient.request(`/api/v1/custom-fields/${id}/used-filter`, {}, 'DELETE');

        let customeFilter = await apiClient.request(`/api/v1/customer-users/${selectedCustomerId}/customer-custom-fields`, [] , 'GET');

        setCustomerFilters(customeFilter.customFields);

      } catch (err: any) {

        if (err.message === 'custom_filter_in_use') {
          setCustomFilterDeleteId(id);
          setShowDeleteModal(true);
        } else {
          message.error(intl.formatMessage({id: "error.delete_item"}));
          console.error(err);
        }
      }
    }

    const deleteFilterConfirm = async () => {
      try {
          await apiClient.request(`/api/v1/custom-fields/${customFilterDeleteId}`, {}, 'DELETE');

        let customeFilter = await apiClient.request(`/api/v1/customer-users/${selectedCustomerId}/customer-custom-fields`, [] , 'GET');

        setCustomerFilters(customeFilter.customFields);

      } catch (err) {
        message.error(intl.formatMessage({id: "error.delete_item"}));
        console.error(err);
      } finally {
        setShowDeleteModal(false);
        setCustomFilterDeleteId([]);
      }
    }

  return (
  <>
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageHeader title={intl.formatMessage({id: 'system.filter.filter_manager'})} />
      <DefaultLayout.PageContent>
          <FlexRow
            left={
                <>
                    <Button type="primary" disabled={disableAddNewFilterButton} onClick={() => {
                        setShowFilterCreateModal(true);
                        form.setFieldsValue({
                            filterName: null,
                            defaultValue: null
                        });
                    }}
                    >
                        <FormattedMessage id="system.filter.add_new_filter"/>
                    </Button>
                    {
                        showFilterButton ?
                            <Button style={{marginLeft: '5px'}} type="primary"
                                    onClick={() => setSelectedCustomerModal(true)}>
                                <FormattedMessage id="general.filter"/>
                            </Button>
                            : null
                    }
                </>
            }
            right={
                <>
                    <InstructionButton position='OTHER_ADMIN_FUNCTIONS' />
                </>
            }
          />

          <Table
          locale={{ emptyText: intl.formatMessage({id: "general.found_no_data"}) }}
          pagination={{
            showTotal: showTotal,
            pageSizeOptions: ['10', '20'],
            showSizeChanger: true,
            locale: { items_per_page: intl.formatMessage({id: "general.page"}), jump_to: intl.formatMessage({id: "general.go_to"}) },
            showQuickJumper: true
          }}
          loading={isFiltersLoading}
          rowKey={(record) => record}
          style={{marginTop: 16}}
          columns={columns}
          dataSource={customerFiltetrs}
          size="middle"
          scroll={{ x: 800 }}>
        </Table>
      </DefaultLayout.PageContent>
      <DefaultLayout.PageFooter className='justify-content-end sticky-footer-buttons' style={{border:'1px', borderColor:'#D9D9D9'}}>
      </DefaultLayout.PageFooter>
    </DefaultLayout.PageLayout>
    <Modal
      className="user-list-modal"
      visible={selectedCustomerModal}
      title={intl.formatMessage({id: 'system.filter.customer_custom_filter'})}
      onOk={()=>form.submit()}
      onCancel={() => setSelectedCustomerModal(false)}
      okText={intl.formatMessage({id: 'general.filter'})}
      cancelText={intl.formatMessage({id: 'general.cancel'})}
    >
      <Form form={form} onFinish={selectCustomer}>
        <Select
          showSearch
          name='customer'
          label={intl.formatMessage({id: "general.customer"})}
          url={config.api.routes.backend.organizationsCustomers}
          integerKey={true}
          customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
          onChange={(id: any) => {setSelectedCustomerId(id);}}
          isResponseArray={true}
          dataKey='customers'
          mapDataEntries={(el: any) => ({value: el.id, label: el.name})}
          valueKey='value'
          labelKey='label'
          customLayout={true}
          filterOption={(input: string, option: any) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
      </Form>
    </Modal>

    <Modal
      className="custom-filter-modal"
      visible={showCreateFilterCreateModal}
      onCancel={() =>{ setShowFilterCreateModal(false); setCustomFilterEdit(false)}}
      title={customFilterEdit ? intl.formatMessage({id: 'system.filter.edit_custom_filter'}) : intl.formatMessage({id: 'system.filter.create_custom_filter'})}
      onOk={()=>form.submit()}
      okText={customFilterEdit ? intl.formatMessage({id: 'general.update'}) : intl.formatMessage({id: 'general.create'})}
      cancelText={intl.formatMessage({id: 'general.cancel'})}
    >
      <Form form={form} onFinish={createFilter}>
        <Form.Item
          className='input-custom-filter'
          name="filterName"
          label={intl.formatMessage({id: "system.filter.name"})}
          rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="customFilterStatus"
          label={intl.formatMessage({id: 'general.status'})}
        >
          <Radio.Group
            onChange={(el: any) => {
              if(el.target.value === 'ACTIVE'){
                form.setFieldsValue({
                  customFilterStatus: 'ACTIVE',
                });
              }else{
                form.setFieldsValue({
                  customFilterStatus: 'INACTIVE',
                });
              }
            }}
          >
            <Radio value="ACTIVE"><FormattedMessage id='general.active'/></Radio>
            <Radio value="INACTIVE"><FormattedMessage id='general.inactive'/></Radio>
          </Radio.Group>
        </Form.Item>

        <Switch isFormItem name='requirement' label={intl.formatMessage({id: 'general.mandatory'})} defaultChecked={editSwitch} onChange={(e:boolean) => setEditSwitch(e)}/>

        <Form.Item
          name="defaultValue"
          className='input-custom-filter'
          label={intl.formatMessage({id: "system.filter.default_value"})}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>

    <Modal
      className="delete-custom-filter-modal"
      visible={showDeleteModal}
      onOk={() => deleteFilterConfirm()}
      onCancel={() => setShowDeleteModal(false)}
      title={intl.formatMessage({id: 'system.filter.delete_custom_filter'})}
      okText={intl.formatMessage({id: 'general.delete'})}
      cancelText={intl.formatMessage({id: 'general.cancel'})}
    >
      {intl.formatMessage({id: 'campaign.filter_delete_confirm'})}
    </Modal>
  </>

  )
}

export default connect(mapStateToProps)(withRouter(ManageFilters));
