import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import { Form, message, Row, Col, Modal, Button } from 'antd';
import moment from 'moment';
import DefaultLayout from 'components/DefaultLayout';
import Spinner from 'components/Spinner';
import { Input, Switch, DatePicker, Checkbox, Select } from 'components/Form';
import apiClient from 'utils/apiClient';
import config from 'config/config';
import methods from './methods';
import './styles.scss';

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 6 },
};

const CustomerAgreementForm: React.FC = ({history, match}: any) => {
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [agreementLoading, setAgreementLoading] = useState(true);
  const [agreement, setAgreement] = useState<any>({});
  const [customer, setCustomer] = useState<any>({})
  const [hasEndDate, setHasEndDate] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [onAgreementEndEnum, setOnAgreementEndEnum] = useState(false);

  const [form] = Form.useForm();
  const intl = useIntl();
  const customerId = match.params.customerId;

  useEffect(() => {
    const loadAgreement = async () => {
      try {
        setAgreementLoading(true);
        let customerResponse = await apiClient.request(`${config.api.routes.backend.customers}/${customerId}`);
        setCustomer(customerResponse.customer);
        setAgreement(customerResponse.agreement || {});

        let actionOnAgreementEndResponse = await apiClient.request(config.api.routes.enums.actionOnAgreementEnd, {}, 'GET');
        let actionOnAgreementEnd:any = [];
        Object.entries(actionOnAgreementEndResponse).map((value:any, index:any) => {
          return actionOnAgreementEnd[value[0]] = intl.formatMessage({id: `organization.${value[0]}`})
        })
        setOnAgreementEndEnum(actionOnAgreementEnd);
      } catch (err) {
        console.error(err);
        message.error(intl.formatMessage({id: "error.data_load"}));
      } finally {
        setAgreementLoading(false);
      }
    };

    loadAgreement();
  }, [intl, customerId]);

  useEffect(() => {
    if (agreement.id) {
      form.setFieldsValue({
        beginDate: moment(agreement.beginDate),
        endDate: agreement.endDate ? moment(agreement.endDate) : moment(agreement.beginDate).add(1, 'days'),
        referenceId: agreement.referenceId,
        isTrialAgreement: !!agreement.isTrialAgreement,
        canApplyForNewCourses: !!agreement.canApplyForNewCourses,
        canAddOwnContent: !!agreement.canAddOwnContent,
        hasCustomDesign: !!agreement.hasCustomDesign,
        onAgreementEnd: agreement.onAgreementEnd,
        hasEndDate: agreement.hasEndDate
      });

      setHasEndDate(agreement.hasEndDate);
    } else {
      // Default form values
      form.setFieldsValue({
        beginDate: moment(),
        endDate: moment().add('1', 'day'),
        isTrialAgreement: false,
        canApplyForNewCourses: false,
        canAddOwnContent: false,
        hasCustomDesign: false,
        onAgreementEnd: 'BLOCK_USER_ACCOUNT_ACCESS'
      });
    }
  }, [agreement, form]);

  const submitForm = async (values: any) => {
    try {
      let parsedValues = {
        ...values,
        beginDate: values['beginDate'].format("YYYY-MM-DD"),
        endDate: values['endDate'] ? values['endDate'].format("YYYY-MM-DD") : null,
        status: agreement.status || 'ACTIVE',
        customerId: match.params.customerId,
        hasEndDate: hasEndDate
      }

      setSubmitInProgress(true);
      if (agreement.id) {
        await apiClient.request(`${config.api.routes.backend.customerAgreements}/${agreement.id}`, parsedValues, 'PUT');
        setSubmitInProgress(false);
        history.push('/customers');
      } else {
        await apiClient.request(config.api.routes.backend.customerAgreements, parsedValues, 'POST');
        setSubmitInProgress(false);
        history.push(`/customers/${customerId}/users`);
      }
      message.success(intl.formatMessage({id: "organization.agreement_successfully_updated"}));
    } catch (error: any) {
      console.error(error);
      if (error.status === 422) {
        let errors = JSON.parse(error.message);
        form.setFields(methods.mapSubmitErrorsToForm(errors));
      }
      message.error(intl.formatMessage({id: "general.submit_error"}));
      setSubmitInProgress(false);
    }
  }

  const activateAgreement = async () => {
    try {
      setSubmitInProgress(true);
      await apiClient.request(`${config.api.routes.backend.customerAgreements}/${agreement.id}/reactivate`, [], 'POST');
      let customerResponse = await apiClient.request(`${config.api.routes.backend.customers}/${customerId}`);
      setAgreement(customerResponse.agreement || {});
      message.success(intl.formatMessage({id: "organization.agreement_activated"}));
    } catch (error: any) {
      console.error(error);
      if (error.status === 422) {
        let errors = JSON.parse(error.message);
        form.setFields(methods.mapSubmitErrorsToForm(errors));
      }
      message.error(intl.formatMessage({id: "error.server_error"}));
    } finally {
      setSubmitInProgress(false);
    }
  }

  const deactivateAgreement = async () => {
    try {
      setSubmitInProgress(true);
      await apiClient.request(`${config.api.routes.backend.customerAgreements}/${agreement.id}/cancel`, [], 'POST');
      let customerResponse = await apiClient.request(`${config.api.routes.backend.customers}/${customerId}`);
      setAgreement(customerResponse.agreement || {});
      setShowConfirmationModal(false);
      message.success(intl.formatMessage({id: "organization.agreement_deactivated"}));
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({id: "error.server_error"}));
    } finally {
      setSubmitInProgress(false);
    }
  }

  return <DefaultLayout.PageLayout withStickyFooter>
    <DefaultLayout.PageHeader
      breadcrumb={[
        {name: intl.formatMessage({id: 'general.customers'}), path: '/customers'}
      ]}
      loading={agreementLoading}
      title={
        agreement.id
          ? intl.formatMessage({id: 'form.edit_agreement_title'}, { name: customer.name})
          : intl.formatMessage({id: 'form.add_agreement_title'}, { name: customer.name})
        }
    />
    <Spinner spinning={submitInProgress || agreementLoading} opaque={agreementLoading} >
      <DefaultLayout.PageContent withTopPadding>
        <Form form={form} onFinish={submitForm}>
          <DatePicker
            name='beginDate'
            validateTrigger='onChange'
            label={intl.formatMessage({id: "organization.agreement_begin_date"})}
            customRules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
            validation={{required: true}}
          />
          {
            hasEndDate ?
              <DatePicker
                name="endDate"
                className={moment() > moment(agreement.endDate) ? 'expired-end-date' : ''}
                validateTrigger="onChange"
                label={intl.formatMessage({id: "organization.agreement_end_date"})}
                defaultValue={agreement.beginDate ? moment(agreement.beginDate) : moment()}
                customRules={[
                  { required: true, message: intl.formatMessage({id: "validation.field_required"}) },
                  ({ getFieldValue }: any) => ({
                    validator(rule: any, value: any) {
                      if (moment(getFieldValue('beginDate')).isBefore(value, 'day')) {
                        return Promise.resolve();
                      }

                      return Promise.reject(intl.formatMessage({id: "validation.date_must_be_after_begin_date"}));
                    }
                  })
                ]}
              /> : null
          }
          <Checkbox
            name="hasEndDate"
            label={intl.formatMessage({id: "general.indefinite"})}
            checked={!hasEndDate}
            onChange={() => setHasEndDate(!hasEndDate)}
          />
          {
            agreement.id ?
              <Form.Item
                className="agreement-status"
                {...formItemLayout}
                label={intl.formatMessage({id: "Agreement status:"})}
              >
                  <div>
                  { agreement.status === 'ENDED' ? <label className="inactive-status"><FormattedMessage id="agreement.status.INACTIVE"/></label> : <label className="active-status"><FormattedMessage id="agreement.status.ACTIVE"/></label> }
                  {
                    agreement.id ?
                      agreement.status === 'ACTIVE' ?
                        <Button type="primary" onClick={() => setShowConfirmationModal(true)} loading={submitInProgress} style={{height: 32, padding: 8, lineHeight: 1, backgroundColor: '#F5222D', borderRadius: 8, marginRight: 8, border: 0}}>
                          <FormattedMessage id="form.actions.deactivate_agreement" />
                        </Button>
                        :
                        <Button type="primary" onClick={() => activateAgreement()} loading={submitInProgress} style={{height: 32, padding: 8, lineHeight: 1, backgroundColor: '#52C419', borderRadius: 8, marginRight: 8, border: 0}}>
                          <FormattedMessage id="form.actions.activate_agreement" />
                        </Button>
                      :
                      null
                  }
                  </div>
              </Form.Item> : null
          }
          <Input name="referenceId" label={intl.formatMessage({id: "organization.reference_id"})} validation={{required: true}} />

          <hr className="form-group-separator" />
          <Row>
            <Col span={14}>
              <h1 className="form-group-header">{intl.formatMessage({id: "organization.special_rights"})}</h1>
            </Col>
          </Row>

          <Switch isFormItem hasDefaultLayout name="isTrialAgreement" label={intl.formatMessage({id: "organization.is_trial_agreement"})} />
          <Switch isFormItem hasDefaultLayout name="canApplyForNewCourses" label={intl.formatMessage({id: "organization.can_apply_for_new_courses"})} />
          <Switch isFormItem hasDefaultLayout name="canAddOwnContent" label={intl.formatMessage({id: "organization.can_add_own_content"})} />
          <Switch isFormItem hasDefaultLayout name="hasCustomDesign" label={intl.formatMessage({id: "organization.has_custom_design"})} />
          <Select
            name='onAgreementEnd'
            label={intl.formatMessage({id: "organization.action_on_agreement_end"})}
            customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
            manualOptions={onAgreementEndEnum}
          />
        </Form>
      </DefaultLayout.PageContent>
      <DefaultLayout.PageFooter className='justify-content-end'>
        <div className="form-buttons">
          <NavLink to="/customers">
            <Button>
              <FormattedMessage id="general.back" />
            </Button>
          </NavLink>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 10 }}
            loading={submitInProgress}
            onClick={() => form.submit()}
          >
            <FormattedMessage id="general.submit" />
          </Button>
        </div>
      </DefaultLayout.PageFooter>
      <Modal
        title={intl.formatMessage({id: 'form.actions.deactivate_agreement'})}
        visible={showConfirmationModal}
        onOk={() => deactivateAgreement()}
        onCancel={() => setShowConfirmationModal(false)}
        okText={intl.formatMessage({id: 'form.actions.deactivate_agreement'})}
        cancelText={intl.formatMessage({id: 'general.cancel'})}
      >
        <FormattedMessage id="confirmation.are_you_sure" />
      </Modal>
    </Spinner>
  </DefaultLayout.PageLayout>
}

export default withRouter(CustomerAgreementForm);
