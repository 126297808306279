import React, { useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import { message, Table, Input, Tooltip, Popover, Button, Alert, Modal } from 'antd';
import { Switch } from 'components/Form';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import FlexRow from 'components/FlexRow';
import ActivityModal from './ActivityModal';
import CourseAccessModal from '../CourseEdit/CourseAccessModal';
import ActivityCreationModal from '../CourseEdit/ActivityCreationModal';
import ScormPreviewModal from './ScormPreviewModal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        locale: state.locale.locale
    };
}

interface ActivitiesListInterface {
    course: any;
    customActivityCampaign: {campaignId: number | null, type: string | null};
    match?: any;
    locale?: any;
    session?: any;
    history?: any;
}

const ActivitiesList: React.FC<ActivitiesListInterface & RouteComponentProps> = ({ course, customActivityCampaign, match, locale, session, history }) => {
    const [isActivitiesLoading, setIsActivitiesLoading] = useState(true);
    const [isSwitchLoading, setIsSwitchLoading] = useState(false);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [title, setTitle] = useState<any>('');
    const [selectLanguageListName, setSelectLanguageListName] = useState<any>({});

    const [activities, setActivities] = useState<any>();
    const [defaultActivities, setDefaultActivities] = useState();
    const [scormPreviewModal, setScormPreviewModal] = useState(false);

    const [showTopicCreationModal, setShowTopicCreationModal] = useState(false);

    const [showActivityModal, setShowActivityModal] = useState(false);
    const [showAccessModal, setShowAccessModal] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState('');
    const [hasEmptyActivity, setHasEmptyActivity] = useState(false);

    const [previewType, setPreviewType] = useState<"learn" | "test">('learn');

    const [selectActivity, setSelectActivity] = useState({
        activityId: 0,
        language: '',
        selected: false,
        preview: false
    });

    const intl = useIntl();
    const { Search } = Input;
    const { confirm } = Modal;
    const courseId = match.params.courseId;

    useEffect(() => {

        const loadLanguages = async () => {
            let languages = await apiClient.request(`/api/v1/language`, {}, 'GET');
            let languageListForSelect: any = {};
            let languageListNameForSelect: any = {};
            Object.values(languages.languages).map((value: any) => {
                if (value['publish']) {
                    languageListForSelect[value['code']] = value['code']
                    languageListNameForSelect[value['code']] = value['name']
                }
                return languageListNameForSelect;
            });
            setSelectLanguageListName(languageListNameForSelect);
        };

        loadActivities();
        loadLanguages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    const loadActivities = async () => {
        try {
            let courseList = await coursesApiClient.request(`/api/v1/courses/activities/${courseId}/list`, {}, 'GET');
            setActivities(courseList.learningActivities);
            setDefaultActivities(courseList.learningActivities);

            if (courseList.learningActivities.length === 0) {
                setHasEmptyActivity(false)
            }
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: "error.data_load" }));
        } finally {
            setIsActivitiesLoading(false);
        }
    };

    const columns = [
        {
            title: intl.formatMessage({ id: 'courses.activity_name' }),
            render: (record: any) => {
                if (record.title) {
                    const title = JSON.parse(record.title);
                    return title[locale] ? title[locale] : title[Object.keys(title)[0]];
                };
            }
        },
        {
            title: intl.formatMessage({ id: 'courses.date_created' }),
            render: (record: any) => {
                return record.createdAt ? moment(record.createdAt).format('DD.MM.YYYY') : '-'
            }
        },
        {
            title: intl.formatMessage({ id: 'courses.last_update' }),
            render: (record: any) => record.lastUpdated ? moment(record.lastUpdated).format('DD.MM.YYYY') : '-'
        },
        {
            title: intl.formatMessage({ id: 'general.languages' }),
            render: (record: any) => {

                if (record.title) {
                    const title = JSON.parse(record.title);
                    return (
                        Object.keys(title).map((value: any) => {
                            return (
                                <div className="language-link-blue"
                                    onClick={() => setSelectActivity({ ...selectActivity, activityId: record.id, language: value, selected: true })}
                                >
                                    {value}
                                </div>
                            )
                        })
                    )
                }

            }
        },
        {
            title:
                <div>
                    {intl.formatMessage({ id: 'general.online' })}
                    <Tooltip title={intl.formatMessage({ id: 'courses.activity_status_explanation' })}>
                        <i className='fal fa-question-circle header-item' />
                    </Tooltip>
                </div>,
            render: (_text: string, record: any) => {
                const switchStatus = !!(record.status === 'ONLINE');
                return (
                    <Switch
                        key={record.id}
                        onChange={() => onSwitchChange(record)}
                        disabled={isSwitchLoading}
                        defaultChecked={switchStatus}
                    />
                );
            }
        },
        {
            title: intl.formatMessage({ id: 'courses.test' }),
            render: (_text: string, record: any) => {
                return (record.hasTest ? intl.formatMessage({ id: 'courses.included' }) : intl.formatMessage({ id: 'courses.not_included' }))
            }
        },
        {
            title: intl.formatMessage({ id: 'courses.sequence_priority' }),
            dataIndex: 'sequencePriority',
        },
        {
            title: intl.formatMessage({ id: 'general.actions' }),
            render: (_text: string, record: any, index: number) => {
                if (!record.hasLearningFile && !record.hasMaterials && !record.hasTest) {
                    setHasEmptyActivity(true)
                }

                const content = <>
                    {record.type === 'SCORM' ?
                        <div className="popover-item" onClick={() => { setVisibleTooltip(null) }}>
                            <FormattedMessage id="courses.add_new_language" />
                        </div>
                        :
                        <div className="popover-item" onClick={() => history.push(`/courses/${courseId}/material/${record.id}`)}>
                            <FormattedMessage id="general.edit" />
                        </div>
                    }
                    {record.hasLearningFile != null ?
                        <div className="popover-item" onClick={() => {
                            setTitle(intl.formatMessage({ id: "courses.preview_learning_file" }));
                            setPreviewType('learn');
                            setScormPreviewModal(true);
                            setSelectActivity({ ...selectActivity, activityId: record.id, language: '', preview: true });
                            setVisibleTooltip(null)
                            setSelectedType(record.lastLearningActivity);
                        }}
                        >
                            <FormattedMessage id="courses.preview_learning_file" />
                        </div> : null}

                    {record.hasTest != null ?
                        <div className="popover-item" onClick={() => {
                            setTitle(intl.formatMessage({ id: "courses.preview_test_file" }));
                            setScormPreviewModal(true);
                            setPreviewType('test');
                            setSelectActivity({ ...selectActivity, activityId: record.id, language: '', preview: true });
                            setVisibleTooltip(null)
                            setSelectedType(record.lastLearningActivity);
                        }}
                        >
                            <FormattedMessage id="courses.preview_test_file" />
                        </div> : null}
                    {(!record.hasLearningFile && !record.hasMaterials && !record.hasTest) || (course && course.campaignCourse) ?
                        <div className="popover-item" onClick={() => {
                            warnDeletion(record.id);

                            setVisibleTooltip(null);
                        }}
                        >
                            <FormattedMessage id="general.delete" />
                        </div>
                        : null
                    }
                </>;

                return (
                    <Popover
                        visible={index === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onVisibleChange={(visible) => handleVisibleChange(visible, index)}
                    >
                        <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
                            <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} />
                        </div>
                    </Popover>
                )
            },
        }
    ];

    const warnDeletion = async (id: number) => {
        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: intl.formatMessage({ id: 'courses.deletion_resource' }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                deleteEmptyActivity(id);
            }
        });
    }

    const deleteEmptyActivity = async (id: number) => {
        setIsActivitiesLoading(true);

        try {
            let response = await coursesApiClient.request(`/api/v1/courses/learning-activity/${id}/delete`, {}, 'DELETE');
            if (response.deleted) {
                message.success(intl.formatMessage({ id: 'general.deletion_successful' }));
                loadActivities();
            } else {
                message.error(intl.formatMessage({ id: 'courses.activity_has_learning_materials' }));
                setIsActivitiesLoading(false);
            }
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: 'error.action_failed' }));
        }
    }

    const getWarningDescription = (activity: any) => {

        let warning = <></>

        if (!activity.hasLearningFile && !activity.hasMaterials && !activity.hasTest) {

            const title = JSON.parse(activity.title);

            warning = <div className='warning-container'>
                <span><ExclamationCircleOutlined style={{ color: '#fab11f' }} /> <FormattedMessage id='courses.empty_activity_warning' values={{
                    title: <span className='activity-title'>{title[locale] ? title[locale] : title[Object.keys(title)[0]]}</span>
                }} /></span>

                <Button onClick={() => history.push(`/courses/${courseId}/material/${activity.id}`)}>
                    <i className="fa-solid fa-plus"></i><FormattedMessage id="courses.add_learning_content" />
                </Button>
            </div>
        }
        return warning;
    }

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    }

    const onSwitchChange = async (record: any) => {
        try {
            setIsSwitchLoading(true);

            const data = {
                ...record,
                testFile: undefined,
                learningFile: undefined
            }

            const newSwitchStatus = data.status === 'ONLINE' ? 'OFFLINE' : 'ONLINE';

            data.status = newSwitchStatus;

            await coursesApiClient.request(`/api/v1/courses/learning-activity/${data.id}`, data, 'POST');

            const messageId = newSwitchStatus === 'ONLINE' ? 'courses.learning_activity_can_now_be_included' : 'courses.learning_activity_now_can_not_be_included';

            message.success(intl.formatMessage({ id: messageId }), 7);
            loadActivities();

        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: 'error.action_failed' }));
        } finally {
            setIsSwitchLoading(false);
        }
    }

    const filterBySearch = (searchText: string) => {
        const filteredActivities = activities.filter((data: any) => data.name.includes(searchText));

        if (searchText) {
            setActivities(filteredActivities);
        } else {
            setActivities(defaultActivities);
        }
    };

    return (
        <>
            <FlexRow
                left={
                    <>
                        <Button type="primary" onClick={() => setShowTopicCreationModal(true)}>
                            <FormattedMessage id="courses.add_new_activity" />
                        </Button>
                        {session.active_user_type !== 'CUSTOMER_ADMIN' && session.active_user_type !== 'RESELLER_ADMIN' &&
                            <Button type="primary" onClick={() => setShowAccessModal(true)}>
                                <FormattedMessage id="courses.access" />
                            </Button>
                        }
                    </>
                }
                right={
                    <Search
                        className="table-search-field"
                        placeholder={intl.formatMessage({ id: "general.search" })}
                        onChange={event => filterBySearch(event.target.value)}
                    />
                }
            />
            {activities && hasEmptyActivity &&
                <div className='warning-wrapper'>
                    <Alert
                        description={Object.values(activities).map((activity: any) => getWarningDescription(activity))}
                        type="warning"
                    />
                </div>
            }
            <Table
                locale={{ emptyText: intl.formatMessage({ id: "general.found_no_data" }) }}
                loading={isActivitiesLoading}
                rowKey={(record) => record}
                columns={columns}
                size="middle"
                scroll={{ x: 800 }}
                dataSource={activities}
            />
            <ActivityModal
                visible={showActivityModal}
                setVisible={() => setShowActivityModal(true)}
                onCancel={() => setShowActivityModal(false)}
                courseId={courseId}
                reload={loadActivities}
                activity={selectActivity.selected ? selectActivity : null}
                setSelectActivity={setSelectActivity}
            />
            <ActivityCreationModal
                visible={showTopicCreationModal}
                onCancel={() => setShowTopicCreationModal(false)}
                courseId={courseId}
                languageList={selectLanguageListName}
                customActivityCampaign={customActivityCampaign}
            />
            <CourseAccessModal
                visible={showAccessModal}
                onCancel={() => setShowAccessModal(false)}
                course={course}
            />
            <ScormPreviewModal
                title={title}
                visible={scormPreviewModal}
                onCancel={() => setScormPreviewModal(false)}
                activityId={selectActivity.preview ? selectActivity.activityId : 0}
                previewType={previewType}
                type={selectedType}
            />
        </>
    )
}
export default connect(mapStateToProps)(withRouter(ActivitiesList));
