import * as ActionTypes from 'store/actionTypes';

const defaultState = {
    templateLanguage:null
}

const phishingSimulationTemplateView = (state = defaultState, action: any) => {
  switch(action.type) {
    case ActionTypes.SAVE_PHISHING_SIMULATION_LANGUAGE:
      var ManagePhishingView = action.payload;
      return ({...state, ...ManagePhishingView});

    default:
      return state;
  }
}

export default phishingSimulationTemplateView;