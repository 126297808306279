import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Input, Popover, message, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import './styles.scss';
import Table from 'components/Table';
import { paginatorQuery } from 'components/Table/methods';

const { Search } = Input;

const mapStateToProps = (state: any) => ({ session: state.session });

const OrganizationList: React.FC = ({ session }: any) => {
  const [organizations, setOrganizations] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [visibleTooltip, setVisibleTooltip] = useState<number|null>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPageSize, setCurrentPageSize] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortValues, setSortValues] = useState();

  const pageSizeOptions = [10, 20]
  const resourceUrl = '/api/v1/organizations'

  const intl = useIntl();

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoading(true);

        setCurrentPageSize(pageSizeOptions[0])

        let query = paginatorQuery(currentPage, currentPageSize)

        let organizationsResponse = await getRecords(resourceUrl, query);

        setOrganizations(organizationsResponse.data);
        setTotalRecords(organizationsResponse.pagination.total);
      } catch (err) {
        message.error(intl.formatMessage({id: 'error.data_load'}));
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  const deleteReseller = async (id: string) => {
    try {
      setLoading(true);
      setVisibleTooltip(null);  // hide tooltips
      await apiClient.request('/api/v1/organizations/' + id, {}, 'DELETE');
      let organizationsResponse = await apiClient.request('/api/v1/organizations', {}, 'GET');
      setOrganizations(organizationsResponse.organizations);
    } catch (err) {
      message.error(intl.formatMessage({id: 'error.delete_item'}));
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const handleVisibleChange = (visible: boolean, recordId: number) => {
    if (visible) {
      setVisibleTooltip(recordId);
    } else {
      setVisibleTooltip(null);
    }
  }

  const getRecords = async (resourceUrl: string, pageQuery: string, data?:{}) => {
    let query = (pageQuery !== '') ? '?' + pageQuery : '';

    if (!data) {
        data = sortValues
    }

    if (session.active_user_type === 'RESELLER_ADMIN') {
      let response = await apiClient.request(`/api/v1/organizations/${session.organization.organization_id}`, data, 'GET');

      return  {
        data: [response],
        pagination: {}
      }
    } else {
      let response = await apiClient.request(resourceUrl + query, data, 'GET');

      return  {
        data: (response.organizations) ? response.organizations : {},
        pagination: (response.pagination) ? response.pagination : {}
      }
    }
  };

  const showAddNewResellers = () => {
    if (session.active_user_type === 'SUPER_ADMIN') {
      return true
    } else if (session.active_user_type === 'DISTRIBUTOR_ADMIN') {
      if (session.special_rights.includes('can_add_new_resellers')) {
        return true
      }
    }

    return false
  };

  const handleTableSortFilter = async (sorter: any, tablePage: number) => {
      if (sorter && Object.keys(sorter).length === 0 && sorter.constructor === Object) {
        return;
      }

      let parsedValues: any = {
          column: sorter.columnKey,
          order: sorter.order ? sorter.order : null
      };

      setSortValues(parsedValues);

      if (currentPage === tablePage && sorter.order) {
          let query = paginatorQuery(1, currentPageSize ? currentPageSize : 10);

          let organizationsResponse: any = await getRecords(resourceUrl, query, parsedValues);

          setOrganizations(organizationsResponse.data);
          setTotalRecords(organizationsResponse.pagination.total);
      } else if (!sorter.order) {

          setLoading(true);

          let query = paginatorQuery(1, currentPageSize ? currentPageSize : 10);
          setCurrentPage(1);

          let organizationsResponse: any = await getRecords(resourceUrl, query, parsedValues);

          setOrganizations(organizationsResponse.data);
          setTotalRecords(organizationsResponse.pagination.total);
          setLoading(false);

      }
  };

  let columns = [
    {
      title: intl.formatMessage({id: "general.company"}),
      dataIndex: 'name',
      render: (text: string, record: any) => (
        <NavLink to={`/organizations/${record.organization.id}/admins`}>
          {record.organization.name}
        </NavLink>
      )
    },
    {
      title: intl.formatMessage({id: "general.start_date"}),
      key: 'begin_date',
      sorter: true,
      render: (value: string, record: any) =>
        record.agreement ? moment(record.agreement.beginDate).format('DD.MM.YYYY') : '-'
    },
    {
      title: intl.formatMessage({id: "general.end_date"}),
      key: 'end_date',
      sorter: true,
      render: (value: string, record: any) => {
        let color;
        if (record.agreement && record.agreement.endDate){
         if (moment(record.agreement.endDate) < moment()) color='date-color';
          return (
            <div className={color}>
              {moment(record.agreement.endDate).format('DD.MM.YYYY')}
            </div>)
        } else {
            return'-'
        }
      }
    },
    {
      title: intl.formatMessage({id: "general.customers"}),
      sorter: true,
      key: 'customers',
      render: (text: string, record: any) => record.organization.totalCustomers
    },
    {
      title: intl.formatMessage({id: "general.resellers"}),
      key: 'resellers',
      render: () => '-'
    },
    {
      title: intl.formatMessage({id: "general.admins"}),
      key: 'admins',
      render: (text: string, record: any) => record.organization.totalAdmins

    },
    {
      title: intl.formatMessage({id: "organization.agreement_changes"}),
      key: 'agreement_changes',
      render: () => '-'
    },
    {
      title: intl.formatMessage({id: "organization.agreement_status"}),
      key: 'agreement_status',
      render: (text: string, record: any, tag: any) => {
        let color;
        if (record.agreement) {
          if (record.agreement.status === 'ACTIVE') color = 'green';
          if (record.agreement.status === 'INACTIVE') color = 'red';
            // ENDED
          return (
            <Tag color={color} key={tag}>
              {intl.formatMessage({id: `agreement.status.${record.agreement.status}`})}
            </Tag>
          )
        } else {
            return '-';
        }
      }
    },
    {
      key: 'actions',
      render: (text: string, record: any) => {
        const organizationId = record.organization.id;
        const content = <>
          {
            record.agreement ?
              <NavLink to={`/organizations/${organizationId}/agreement`}>
                <div className="popover-item">
                  <FormattedMessage id="organization.edit_agreement"/>
                </div>
              </NavLink>
              :
              <NavLink to={`/organizations/${organizationId}/agreement`}>
                <div className="popover-item">
                  <FormattedMessage id="organization.add_agreement"/>
                </div>
              </NavLink>
          }
          <NavLink to={`/organizations/${organizationId}/edit`}>
            <div className="popover-item">
              <FormattedMessage id="organization.edit_reseller"/>
            </div>
          </NavLink>
          <div className="popover-item delete-item" onClick={() => {deleteReseller(organizationId)}}>
            <FormattedMessage id="organization.delete_reseller"/>
          </div>
        </>;

        return (
          <Popover
            visible={organizationId === visibleTooltip}
            content={content}
            trigger="click"
            placement="bottomRight"
            arrowPointAtCenter
            onVisibleChange={(visible) => handleVisibleChange(visible, organizationId)}
          >
            <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
              <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
            </div>
          </Popover>
        )
      },
    }
  ];

  if (session.active_user_type === 'RESELLER_ADMIN') {
    columns = columns.filter((column: any) => column.key !== 'actions');
  }

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageHeader title={intl.formatMessage({id:'general.resellers'})}/>
      <DefaultLayout.PageContent>
        <div style={{ display: 'flex', marginBottom: 16 }}>
            { (showAddNewResellers()) ?
              <NavLink to="/organizations/add">
                <Button type="primary" icon={<PlusOutlined/>}>
                  <FormattedMessage id="organization.add_resellers"/>
                </Button>
              </NavLink>
              : null
            }
              <Search
                placeholder={intl.formatMessage({id: 'general.search'})}
                style={{ marginLeft: 'auto' }}
              />
        </div>
        <Table
          resourceUrl = {resourceUrl}
          totalRecords = {totalRecords}
          loadedData = {organizations}
          setLoadedData = {setOrganizations}
          setTotalRecords = {setTotalRecords}
          currentPage = {currentPage}
          setCurrentPageSize = {setCurrentPageSize}
          setCurrentPage = {setCurrentPage}
          setLoading = {setLoading}
          loading = {loading}
          columns = {columns}
          pageSizeOptions = {pageSizeOptions}
          getRecords = {getRecords}
          hasSelection={false}
          onChange={(pagination:any, filters: any, sorter: any) => handleTableSortFilter(sorter, pagination.current)}
        />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default connect(mapStateToProps)(OrganizationList);
