import React, { useEffect, useState, useCallback } from 'react';
import { useIntl } from 'react-intl';
import DefaultLayout from 'components/DefaultLayout';
import coursesApiClient from 'utils/coursesApiClient';
import { connect } from 'react-redux';
import { Tag, message } from 'antd';
import Buttons from 'components/ActivityCard/components/Buttons';
import Materials from './Materials';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import FormattedMessage from 'react-intl/lib/components/message';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Spinner from 'components/Spinner';
import styles from './styles.module.scss';
import './styles.scss';

interface ActivityResourcesInterface {
    match?: any;
    session?: any;
    resourceData?: any;
    clear: Function;
    locale?: string;
}
const mapStateToProps = (state: any) => ({
    session: state.session,
    locale: state.locale.locale
});

const ActivityResources: React.FC<ActivityResourcesInterface & RouteComponentProps> = ({ match, session, resourceData, clear, locale }) => {
    const [activityFormData, setActivityFormData] = useState<any>();
    const [loading, setLoading] = useState(false);

    const adminRoles = ['RESELLER_ADMIN', 'SUPER_ADMIN', 'DISTRIBUTOR_ADMIN', 'CUSTOMER_ADMIN', 'SUPERVISION'];
    const activityId = adminRoles.includes(session.active_user_type) ? resourceData.activityId : match.params.activityId;
    const campaignId = adminRoles.includes(session.active_user_type) ? resourceData.campaignId : match.params.id;
    const enrollmentId = adminRoles.includes(session.active_user_type) ? resourceData.enrollmentId : match.params.enrollmentId;
    const [refreshKey, setRefreshKey] = useState(0);
    const [reload, setReload] = useState(false);

    const intl = useIntl();

    function onFocus() {
        document.body.className = 'focused';
        setRefreshKey(oldKey => oldKey + 1)
    };

    const reloadActivities = useCallback(() => {
        if (document.visibilityState === 'visible') {
            if (reload) {
                onFocus();
                setReload(false);
                document.removeEventListener("visibilitychange", reloadActivities);
            }
        } else {
            setReload(true);
        }
    }, [reload])

    useEffect(() => {
        document.addEventListener("visibilitychange", reloadActivities);
    })

    useEffect(() => {
        loadCampaignData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshKey, locale]);


    const loadCampaignData = async () => {
        setLoading(true);
        try {
            let response = await coursesApiClient.request(`/api/v1/courses/student/${enrollmentId}/${activityId}/get-activity`, {}, 'GET');
            setActivityFormData(response.activity);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setLoading(false);
        }
    }

    const getTimeSpentContent = () => {
        if (!activityFormData.scormSettings.timeSpent) {
            return '‎';
        }

        const timeDate = new Date(activityFormData.scormSettings.timeSpent * 1000);

        const timeSpent = {
            hours: timeDate.getUTCHours(),
            minutes: timeDate.getUTCMinutes(),
            seconds: timeDate.getUTCSeconds(),
        };

        if (timeSpent.hours > 0 || timeSpent.minutes > 0) {
            if (timeSpent.hours > 0) {
                return intl.formatMessage({ id: 'student.time_spent_with_hours' }, { hours: timeSpent.hours, minutes: timeSpent.minutes });
            } else {
                return intl.formatMessage({ id: 'student.time_spent_without_hours' }, { minutes: timeSpent.minutes });
            }
        } else {
            return `${timeSpent.seconds}s`;
        }
    }

    const getResultContent = () => {
        if ((activityFormData.activity.testStatus === 'PASSED' || activityFormData.activity.testStatus === 'FAILED') && activityFormData.scormSettings.result) {
            return `${activityFormData.scormSettings.result}%`;
        }

        return '‎'; //empty char for container
    }

    const getMandatoryContent = () => {
        return activityFormData.scormSettings.mandatory
            ? <CheckOutlined style={{ marginLeft: '15%', color: 'green' }} />
            : <CloseOutlined style={{ marginLeft: '15%', color: 'red' }} />
    };

    /**
     * 
     * @param status 
     * @returns 
     */
    const getStatusContent = (activity: any) => {
        let activityStatus: 'NOT_STARTED' | 'IN_PROGRESS' | 'COMPLETED' = 'NOT_STARTED';

        if (activity.hasTest && activity.hasLearningFile) {
            if (activity.learningStatus === activity.testStatus) {
                activityStatus = activity.learningStatus;
            }
    
            if (activity.learningStatus !== activity.testStatus) {
                activityStatus = 'IN_PROGRESS';
            }
        }

        if (activity.hasTest) {
            activityStatus = activity.testStatus;
        }

        if (activity.hasLearningFile) {
            activityStatus = activity.learningStatus;
        }

        const parameters = {
            'NOT_STARTED': { color: 'gold', message: 'student.not_started' },
            'IN_PROGRESS': { color: 'blue', message: 'student.in_progress' },
            'COMPLETED': { color: 'green', message: 'student.completed' }
        };

        return (
            <Tag color={parameters[activityStatus].color}>
                <FormattedMessage id={parameters[activityStatus].message} />
            </Tag>
        );
    };

    const getTile = () => {
        const title = JSON.parse(activityFormData.activity.title);

        if (locale) {
            return title[locale] ? title[locale] : title[Object.keys(title)[0]]
        } else {
            return title[Object.keys(title)[0]];
        }
    }

    const content = () => {
        return (
            <Spinner spinning={loading}>
                {!adminRoles.includes(session.active_user_type) &&
                    <DefaultLayout.PageHeader title={activityFormData && getTile()}
                        breadcrumb={
                            [
                                { name: intl.formatMessage({ id: 'student.learning.list' }), path: `/` },
                                { name: intl.formatMessage({ id: 'student.activity_list' }), path: `/student/${enrollmentId}/activity-list/${campaignId}` }
                            ]
                        }
                    />
                }
                {activityFormData && (activityFormData.activity.hasLearningFile || activityFormData.activity.hasTest || activityFormData.activity.hasPdfMaterial) &&
                    <>
                        <div className={styles.ActivityCard}>
                            <div className={styles.ActivityCardHeader}>
                                <i className={`${styles.ActivityCardHeaderIcon} fa-solid fa-book`} /> <FormattedMessage id='courses.export.learning_activity' />
                            </div>
                            <div className={styles.ActivityWrapper}>
                                <div className={styles.Activity} >
                                    <div className={styles.ActivityItemLabel}>
                                        <FormattedMessage id='general.activity' />
                                    </div>
                                    <div className={styles.ActivityItemContent}>
                                        {getTile()}
                                    </div>
                                    <div className={styles.ActivityItemLabel}>
                                        <FormattedMessage id='student.time_spent' />
                                    </div>
                                    <div className={styles.ActivityItemContent}>
                                        {activityFormData.scormSettings && getTimeSpentContent()}
                                    </div>

                                    <div className={styles.ActivityItemLabel}>
                                        <FormattedMessage id='general.pass_rate' />
                                    </div>
                                    <div className={styles.ActivityItemContent}>
                                        {activityFormData.scormSettings && activityFormData.scormSettings.passRate ? activityFormData.scormSettings.passRate + '%' : '-' }
                                    </div>

                                    <div className={styles.ActivityItemLabel}>
                                        <FormattedMessage id='student.result' />
                                    </div>
                                    <div className={styles.ActivityItemContent}>
                                        {activityFormData.scormSettings && getResultContent()}
                                    </div>

                                    <div className={styles.ActivityItemLabel}>
                                        <FormattedMessage id='general.mandatory' />
                                    </div>
                                    <div className={styles.ActivityItemContent}>
                                        {activityFormData.scormSettings && getMandatoryContent()}
                                    </div>

                                    <div className={styles.ActivityItemLabel}>
                                        <FormattedMessage id='general.status' />
                                    </div>
                                    <div className={styles.ActivityItemContent}>
                                        {activityFormData.scormSettings && getStatusContent(activityFormData.activity)}
                                    </div>
                                </div>
                                {!adminRoles.includes(session.active_user_type) &&
                                    <>
                                        {activityFormData.activity && <Buttons activity={activityFormData.activity} campaignData={activityFormData.campaign} isMaterials={true} />}
                                    </>
                                }
                            </div>

                        </div>
                    </>
                }
                {activityFormData &&
                    <Materials
                        activityFormData={activityFormData}
                        clear={clear}
                        setActivityFormData={setActivityFormData}
                    />
                }
            </Spinner>
        )
    }

    return (
        <>
            {adminRoles.includes(session.active_user_type) ?
                <>
                    {content()}
                </>
                :
                <DefaultLayout.PageLayout withoutPageHeader className='student-responsive-view'>
                    {content()}
                </DefaultLayout.PageLayout>
            }
        </>
    )
}
export default connect(mapStateToProps)(withRouter(ActivityResources));