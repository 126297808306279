import React from 'react';
import { Button as AntDButton } from 'antd';
import './styles.scss';

const Button: React.FC<any> = ({className, type, disabled, ...props}) => {
  let buttonClassName = '';

  if (type === 'primary') {
    buttonClassName = 'primary-button ';
  } else if (type === 'outline') {
    buttonClassName = 'outlined-button ';
  }

  return (
    <AntDButton {...props} className={buttonClassName + (className || '')} disabled={disabled}/>
  )
}

export default Button;
