import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button, Alert, Input, message } from 'antd';
import { RequestSelect } from 'components/Form';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import FlexRow from 'components/FlexRow';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { CampaignStudentsInterface } from '../types';
import AppendStudentsModal from './AppendStudentsModal';
import StudentTable from './StudentTable';
import StudentFilterModal from './StudentFilterModal';
import coursesApiClient from 'utils/coursesApiClient';
import Spinner from 'components/Spinner';
import { withRouter, RouteComponentProps } from 'react-router';
import CampaignFilterBar from './CampaignFilterBar';

const mapStateToProps = (state: any) => {
    return {
        activeUserRole: state.session.active_user_type
    }
};

const CampaignStudents: React.FC<CampaignStudentsInterface & RouteComponentProps> = ({
    campaignId,
    owner,
    next,
    previous,
    newCampaignStatus,
    campaign,
    history,
    status,
    courses,
    activeUserRole,
    launchButton,
    currentStep,
    sendRequest,
    launching,
    submitTab,
    validation
}) => {
    const [showAppendStudentsModal, setShowAppendStudentsModal] = useState(false);
    const [showStudentsFilterModal, setShowStudentsFilterModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState<any>([]);
    const [customFilterValues, setCustomFilterValues] = useState<any[]>();
    const [loading, setIsLoading] = useState(false);

    const [rowSelection, setRowSelection] = useState<any>({
        filter: {},
        selectedAllUsers: false,
        selectedUsers: [],
        deSelectedUsers: [],
    });


    const [excludedUsers, setExcludedUsers] = useState<string[]>([]);
    const resourceUrl = `/api/v1/auth/campaign/${campaignId}/students`;

    const [totalRecords, setTotalRecords] = useState<number>(0);

    const pageSizeOptions = [30, 50];
    const [currentPageSize, setCurrentPageSize] = useState<number>(pageSizeOptions[0]);

    const [students, setStudents] = useState<any[]>([]);
    const [addedUsers, setAddedUsers] = useState<string[]>([]);

    const [searchedUsers, setSearchedUsers] = useState([]);

    const [usersFilter, setUsersFilter] = useState<any>();

    const intl = useIntl();
    const { Search } = Input;

    const [searchForm] = Form.useForm();
    const [form] = Form.useForm();
    const [filterModal] = Form.useForm();

    useEffect(() => {
        if (launching && currentStep === 2) {
            sendRequest(submitForm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [launching]);

    useEffect(() => {
        if (submitTab === 'students') {
            submitForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitTab]);

    useEffect(() => {
        if (campaignId && owner) {
            loadCampaignStudents();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignId, owner]);


    useEffect(() => {
        if (students && students.length > 0) {
            validation(true);
        } else if (students && students.length === 0) {
            validation(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [students]);

    const loadCampaignStudents = async () => {
        let pageQuery = 'page[number]=' + 1 + '&page[size]=' + currentPageSize;

        let students = await getRecords(resourceUrl, pageQuery);

        setStudents(students.data);
        setTotalRecords(students.pagination.total);
    };

    const submitForm = async (direction: string = '') => {
        setIsLoading(true);

        if (totalRecords) {
            if (courses && courses.length) {
                let parsedValues: any = {
                    campaignId: campaignId
                };
                if (usersFilter) {
                    parsedValues.filter = usersFilter;
                }
                if (addedUsers.length > 0) {
                    parsedValues.addedUsers = addedUsers;
                }

                if (excludedUsers.length > 0) {
                    parsedValues.excludedUsers = excludedUsers;
                }
                if (usersFilter || addedUsers.length > 0) {
                    await apiClient.request(`/api/v1/customers/${owner?.id}/add-enrollments `, parsedValues, 'POST');
                    validation(true);
                }
            } else {
                message.error(intl.formatMessage({ id: 'campaign.add_course_before_users' }));
            }
        }

        if (direction === 'next') {
            next(campaignId);
        } else if (direction === 'previous') {
            previous();
        } else if (direction === 'save') {
            history.push('/courses/manage-campaigns');
        }

        setIsLoading(false);
    };


    const getRecords = async (requestUrl: string, pageQuery: string, data: any = {}, set: boolean = false) => {
        setIsLoading(true);
        let query = (pageQuery !== '') ? '?' + pageQuery : '';

        let url = requestUrl + query;

        if (usersFilter && data && Object.keys(data).length === 0 && Object.getPrototypeOf(data) === Object.prototype) {
            data.filter = usersFilter;
            data.append = false;
        }

        data.customerId = owner?.id;

        let response = await apiClient.request(url, data, 'POST');
        setIsLoading(false);

        if (set) {
            setStudents(response.users);
            setTotalRecords(response.pagination.total);
        }

        return {
            data: (response.users) ? response.users : {},
            pagination: (response.pagination) ? response.pagination : {}
        }
    };

    const addUser = async () => {
        if (selectedUser === undefined || selectedUser.length === 0) {
            return;
        }

        let optionsResponse = await apiClient.request(`/api/v1/auth/customers/${owner?.id}/get-students`, selectedUser, 'POST');

        optionsResponse.forEach((element: any) => {
            setStudents((users: any) => [...users, element]);
        });

        selectedUser.map((element: any) => {
            setAddedUsers((addedUsers: any) => [...addedUsers, element]);
            return null;
        });

        setSearchedUsers(selectedUser);
        form.setFieldsValue({
            addUser: undefined,
        });
        setSearchedUsers([]);
        setTotalRecords(totalRecords + optionsResponse.length);
    };

    const searchStudent = async (searchText: string = '') => {

        let pageQuery = 'page[number]=' + 1 + '&page[size]=' + currentPageSize;

        let data: any = {};

        if (searchText) {
            data.q = searchText;
        }
        data.append = false;

        const students = await getRecords(resourceUrl, pageQuery, data);

        setStudents(students.data);
        setTotalRecords(students.pagination.total);
    };

    const loadOptionsTimeOut = debounce(function (text: string) {

        const searchText = text.replace(/\s+/g, '');
        if (text.length === 0 || searchText.length >= 3) {
            searchStudent(searchText);
        }
    }, 800);

    const cancelStudentsEnrollment = async () => {

        const enrollmentIdList = rowSelection.selectedUsers;

        if (rowSelection.selectedUsers.length) {
            await coursesApiClient.request(`/api/v1/courses/campaigns/${campaignId}/cancel-continue-enrollment-list`, enrollmentIdList, 'POST');
        }

        loadCampaignStudents();
    };

    const removeStudentsFormCampaign = async () => {
        const enrollmentIdList = rowSelection.selectedUsers;

        if (rowSelection.selectedUsers.length) {
            await coursesApiClient.request(`/api/v1/courses/campaigns/${campaignId}/enrollment/remove-list`, enrollmentIdList, 'POST');
        }

        loadCampaignStudents();
    };

    return (
        <>
            {activeUserRole !== 'SUPERVISION' &&
                <div className='campaign-header-text'>
                    <h1 className='text-30' >{intl.formatMessage({ id: 'campaign.please_add_students_to_your_campaign' })}</h1>
                </div>
            }
            <Alert
                type='info'
                message={intl.formatMessage({ id: 'campaign.you_will_be_able_to_add_remove_users' })}
                style={{ marginBottom: 24 }}
                showIcon
                closable
            />
            <FlexRow
                left={
                    <>
                        {activeUserRole !== 'SUPERVISION' &&
                            <>
                                <Button type='primary' onClick={() => setShowAppendStudentsModal(true)}>
                                    <i className='fal fa-plus header-item' />
                                    <span><FormattedMessage id='campaign.add_user_groups' /></span>
                                </Button>

                                <Form form={form} className='add-user'>
                                    <RequestSelect
                                        url={`/api/v1/auth/customers/${owner?.id}/select-users`}
                                        campaignId={campaignId}
                                        className='add-user-select'
                                        placeholder='Search'
                                        name='addUser'
                                        label={intl.formatMessage({ id: 'users.add_user' })}
                                        style={{ width: 250, marginBottom: 0 }}
                                        onChange={(e: any) => setSelectedUser(e)}
                                        mode='multiple'
                                        valueKey='userTypeUuid'
                                        removeParam={searchedUsers}
                                        customLayout
                                        showSearch
                                        optionLabelProp='label'
                                    />
                                </Form>

                                <Button onClick={addUser}>
                                    <FormattedMessage id='general.add' />
                                </Button>
                            </>
                        }
                    </>
                }
                right={
                    <>
                        {!newCampaignStatus &&
                            <Button type='primary' onClick={() => setShowStudentsFilterModal(true)}>
                                <FormattedMessage id='general.filter' />
                            </Button>
                        }
                        <Form form={searchForm}>
                            <Form.Item name='searchInput' style={{ marginBottom: 0 }}>
                                <Search
                                    className='table-search-field'
                                    allowClear
                                    placeholder={intl.formatMessage({ id: 'general.search' })}
                                    onChange={(event: any) => loadOptionsTimeOut(event.target.value)}
                                />
                            </Form.Item>
                        </Form>
                    </>
                }
            />
            <CampaignFilterBar
                filter={usersFilter}
                owner={owner}
                customFilterValues={customFilterValues}
                form={filterModal}
                getRecords={getRecords}
                resourceUrl={resourceUrl}
                currentPageSize={currentPageSize}
                campaignId={campaignId}
            />
            <Spinner spinning={loading}>
                <StudentTable
                    status={status}
                    campaignId={campaignId}
                    campaign={campaign}
                    owner={owner}
                    customFilterValues={customFilterValues}
                    pageSizeOptions={pageSizeOptions}
                    setCurrentPageSize={setCurrentPageSize}
                    history={history}
                    getRecords={getRecords}
                    students={students}
                    setStudents={setStudents}
                    resourceUrl={resourceUrl}
                    totalRecords={totalRecords}
                    setTotalRecords={setTotalRecords}
                    setExcludedUsers={setExcludedUsers}
                    reloadTable={loadCampaignStudents}
                    rowSelection={rowSelection}
                    setRowSelection={setRowSelection}
                />
            </Spinner>

            <DefaultLayout.PageFooterWithRow
                left={
                    <>
                        <div>
                            {rowSelection.selectedUsers.length}
                            <FormattedMessage id='users.rows_selected'/>
                        </div>
                        {newCampaignStatus &&
                            <>
                                <Button loading={loading} onClick={() => submitForm('previous')}>
                                    <FormattedMessage id='campaign.previous' />
                                </Button>
                                <Button loading={loading} onClick={() => submitForm('next')}>
                                    <FormattedMessage id='general.next' />
                                </Button>
                            </>
                        }
                        {activeUserRole !== 'SUPERVISION' &&
                        <>
                            <Button
                                className='campaign-buttons'
                                onClick={() => {
                                    rowSelection
                                        ? removeStudentsFormCampaign()
                                        : message.error(intl.formatMessage({ id: 'error.select_users' }))
                                }}
                            >
                                <i style={{ color: '#F5222D' }} className='fal fa-times-circle' />
                                <span><FormattedMessage id='campaign.mass_delete' /></span>
                            </Button>
                            {!newCampaignStatus &&
                                <Button
                                    className='campaign-buttons'
                                    onClick={() => {
                                        rowSelection
                                            ? cancelStudentsEnrollment()
                                            : message.error(intl.formatMessage({ id: 'error.select_users' }))
                                    }}
                                >
                                    <i style={{ color: '#F5222D' }} className='fal fa-times-circle' />
                                    <span><FormattedMessage id='campaign.mass_cancel' /></span>
                                </Button>
                            }
                        </>
                        }

                    </>
                }
                right={
                    <>
                        {!newCampaignStatus &&
                            <Button onClick={() => { history.push('/courses/manage-campaigns'); }}>
                                <FormattedMessage id='general.back' />
                            </Button>
                        }
                        {activeUserRole !== 'SUPERVISION' &&
                        <Button loading={loading} onClick={() => { submitForm('save'); }} className='save-button'>
                            <i className='fal fa-save header-item' />
                            <FormattedMessage id={status === 'DRAFT' ? 'campaign.save_as_draft' : 'general.save'} />
                        </Button>
                        }
                        {!newCampaignStatus &&
                            launchButton(submitForm)
                        }
                    </>
                }
            />
            <AppendStudentsModal
                visible={showAppendStudentsModal}
                onCancel={() => setShowAppendStudentsModal(false)}
                owner={owner}
                getRecords={getRecords}
                pageSize={currentPageSize}
                resourceUrl={resourceUrl}
                setStudents={setStudents}
                setCustomFilterValues={setCustomFilterValues}
                setTotalRecords={setTotalRecords}
                setUsersFilter={setUsersFilter}
            />
            <StudentFilterModal
                visible={showStudentsFilterModal}
                onCancel={() => setShowStudentsFilterModal(false)}
                owner={owner}
                campaignId={campaignId}
                getRecords={getRecords}
                pageSize={currentPageSize}
                resourceUrl={resourceUrl}
                setStudents={setStudents}
                setCustomFilterValues={setCustomFilterValues}
                setTotalRecords={setTotalRecords}
                setUsersFilter={setUsersFilter}
                form={filterModal}
            />
        </>
    )
};
export default connect(mapStateToProps)(withRouter(CampaignStudents));
