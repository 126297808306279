import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DefaultLayout from 'components/DefaultLayout';
import LearningActivity from '../../../components/LearningActivity';
import PdfMaterialPlayer from 'components/StudentComponents/PdfMaterialPlayer';
import VideoMaterialPlayer from 'components/StudentComponents/VideoMaterialPlayer';

const mapStateToProps = (state: any) => ({ scormData: state.scormData, language: state.locale.locale });

const ShowScorm: React.FC<any> = ({ scormData, language }) => {

  const getLearningView = () => {
    switch (scormData.materialType) {
      case 'SCORM_LEARNING':
      case 'SCORM_TEST':
        return (
          <DefaultLayout.PageLayout withoutPageHeader>
            <DefaultLayout.PageContent>
              <LearningActivity
                learningActivityId={scormData.learningActivityId}
                activityAttemptId={scormData.activityAttemptId}
                type={scormData.type}
                isFirstAttempt={scormData.isFirstAttempt}
                parentActivityId={scormData.parentActivityId}
                campaignId={scormData.campaignId}
                language={language}
              />
            </DefaultLayout.PageContent>
          </DefaultLayout.PageLayout>
        )
      case 'PDF_MATERIAL':
        return (
          <div className='activity-wrapper'>
            <div className='activity-content'>
              <PdfMaterialPlayer
                learningActivityId={scormData.learningActivityId}
                activityAttemptId={scormData.activityAttemptId}
                type={scormData.type}
                isFirstAttempt={scormData.isFirstAttempt}
                parentActivityId={scormData.parentActivityId}
                campaignId={scormData.campaignId}
                language={language}
              />
            </div>
          </div>
        )
      case 'VIDEO_MATERIAL':
        return (
          <div className='activity-wrapper'>
            <div className='activity-content'/* style={{maxWidth: window.screen.width > 1550 ? 1200 : 895 }}*/>
              <VideoMaterialPlayer
                scormData={scormData}
                language={language}

              />
            </div>
          </div>
        )
      default:
        break;
    }
  }
  return (
    <>
      {getLearningView()}
    </>
  )
}

export default connect(mapStateToProps)(withRouter(ShowScorm));
