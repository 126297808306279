import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { message, Table } from 'antd';
import coursesApiClient from 'utils/coursesApiClient';

interface TopStudentTableProps {
  campaignId: number,
}

const TopStudentTable: React.FC<TopStudentTableProps> = ({ campaignId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  const intl = useIntl();

  const columns = [
    {
      title: '#',
      key: 'nr',
      render: (_text: any, _row: any, index: number) => String(index + 1).padStart(2, '0'),
    },
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'fullName',
    },
    {
      title: intl.formatMessage({ id: 'campaign.score' }),
      dataIndex: 'averageScore',
    },
  ];

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const response = await coursesApiClient.request(`/api/v1/courses/statistic/campaign/${campaignId}/tests-top-students`);

        setData(response.list);
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, [intl, campaignId]);

  return (
    <>
      <p className="chart-title">{intl.formatMessage({ id: 'campaign.top_students_by_score'})}</p>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{ y: '20vw' }}
        size="small"
        loading={isLoading}
      />
    </>
  );
}

export default TopStudentTable;