import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';
import { Modal, Form, message, DatePicker } from 'antd';
import { Select } from 'components/Form';
import config from "config/config";
import CustomerSelect from "../../Form/CustomerSelect";
import apiClient from 'utils/apiClient';
import FileDownload from "js-file-download";
import {connect} from "react-redux";
import moment from 'moment';

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    }
};

const LicenceExportModal = ({
                                showExportLicence, setExportLicence, customers, customersLoading, session
                            }: any) => {
    const intl = useIntl();
    const [serviceList, setServiceList] = useState<[]>([]);
    const [servicesLoading, setServicesLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    useEffect(() => {
        const loadServices = async () => {
            try {
                setServicesLoading(true);

                const response = await apiClient.request(`/api/v1/services/type/E_LEARNING_CONTENT/active`, {}, 'GET');

                let services = response.services;

                const servicesObject: any = {};

                Object.values(services).map((service: any) => {
                    return servicesObject[service['id']] = service['name'];
                });

                setServiceList(servicesObject);
            } catch (error) {
                message.error(intl.formatMessage({id: 'error.data_load'}));
            } finally {
                setServicesLoading(false);
            }
        };
        if (session.active_user_type !== 'CUSTOMER_ADMIN') {
            loadServices()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[intl]);

    const submitForm = async (values: any) => {
        let created = [];
        let validFrom = [];
        let validUntil = [];

        if (values.created) {
            created.push(values.created[0].format('YYYY-MM-DD 00:00:00'));
            created.push(values.created[1].format('YYYY-MM-DD 23:59:00'));
        }
        if (values.validFrom) {
            validFrom.push(values.validFrom[0].format('YYYY-MM-DD 00:00:00'));
            validFrom.push(values.validFrom[1].format('YYYY-MM-DD 23:59:00'));
        }
        if (values.validUntil) {
            validUntil.push(values.validUntil[0].format('YYYY-MM-DD 00:00:00'));
            validUntil.push(values.validUntil[1].format('YYYY-MM-DD 23:59:00'));
        }

        const parsedValues: any = {
            organizationId: values.organizationId && 'eq:' + values.organizationId,
            customerId: values.customerId && 'eq:' + values.customerId,
            serviceCategory: values.serviceCategory && values.serviceCategory,
            serviceId: values.serviceId && 'in:' + values.serviceId,
            created: values.created ? 'btwn_date:' + created  : undefined,
            validFrom : values.validFrom ? 'btwn_date:' + validFrom : undefined,
            validUntil: values.validUntil ? 'btwn_date:' + validUntil : undefined
        };

        const bodyFormData = new FormData();
        Object.entries(parsedValues).map((el: any) => {
            if (el[1]) {
                bodyFormData.append(el[0], el[1]);
            }
            return bodyFormData;
        });

        try {
            const response = await apiClient.request('/api/v1/licences/export', bodyFormData, 'POST', true, true);
            if (response) {
                FileDownload(response, 'Licence_report_' + moment().format('d.m.Y') + '.xlsx');
            } else {
                message.error(intl.formatMessage({ id: 'error.data_load' }));
            }

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        } finally {
            setExportLicence(false);
        }
    };

    return (
        <Modal
            visible={showExportLicence}
            title={intl.formatMessage({id: 'licensing.export_licence_report'})}
            okText={intl.formatMessage({id: 'general.submit'})}
            cancelText={intl.formatMessage({id: 'general.back'})}
            onOk={form.submit}
            onCancel={() => {
                setExportLicence(false);
            }}
        >
            <Form form={form} onFinish={submitForm}>
                <CustomerSelect
                    showSearch
                    allowClear
                    name='organizationId'
                    customLayout={formItemLayout}
                    label={intl.formatMessage({id: "general.reseller"})}
                    url={config.api.routes.backend.organizationsOptions}
                    integerKey={true}
                    customRules={[{ required: false, message: intl.formatMessage({id: "validation.field_required"})}]}
                    isResponseArray={true}
                    dataKey='organizations'
                    mapDataEntries={(el: any) => ({value: el.id, customerType: el.type, label: el.name})}
                    valueKey='value'
                    labelKey='label'
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                />
                {customers &&
                    <Select
                        showSearch
                        allowClear
                        customLayout={formItemLayout}
                        name="customerId"
                        label={intl.formatMessage({id: 'general.customer'})}
                        customRules={[{ required: false, message: intl.formatMessage({id: 'validation.field_required'}) }]}
                        manualOptions={customers}
                        filterOption={(input: string, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                        loading={customersLoading}
                    />
                }
                <Select
                    allowSearch
                    allowClear
                    showArrow
                    name='serviceCategory'
                    mode='multiple'
                    label={intl.formatMessage({ id: 'licensing.service_category' })}
                    url={config.api.routes.enums.licenceServiceTypes}
                    customLayout={formItemLayout}
                />
                <Select
                    name="serviceId"
                    allowClear
                    label={intl.formatMessage({id: 'licensing.service'})}
                    validation={{required: false}}
                    loading={servicesLoading}
                    manualOptions={serviceList}
                    customLayout={formItemLayout}
                />
                <span>
                    <Form.Item {...formItemLayout} name='created' label={intl.formatMessage({id: 'licensing.filter_created'})} rules={[{ required: false, message: intl.formatMessage({id: "validation.field_required"}) }]} >
                        <DatePicker.RangePicker
                            format="YYYY-MM-DD"
                        />
                    </Form.Item>
                    <Form.Item {...formItemLayout} name='validFrom' label={intl.formatMessage({id: 'licensing.valid_from'})} rules={[{ required: false, message: intl.formatMessage({id: "validation.field_required"}) }]} >
                        <DatePicker.RangePicker
                            format="YYYY-MM-DD"
                        />
                    </Form.Item>
                    <Form.Item {...formItemLayout} name='validUntil' label={intl.formatMessage({id: 'licensing.valid_until'})} rules={[{ required: false, message: intl.formatMessage({id: "validation.field_required"}) }]} >
                        <DatePicker.RangePicker
                            format="YYYY-MM-DD"
                        />
                    </Form.Item>
                </span>
            </Form>
        </Modal>
    );
};

export default connect(mapStateToProps)(LicenceExportModal);
