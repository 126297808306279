import * as ActionTypes from 'store/actionTypes';

const defaultState = {}

const CampaignActivityList = (state = defaultState, action: any) => {
  switch(action.type) {
    case ActionTypes.SAVE_CAMPAIGN_ACTIVITY_LIST:
      return {...action.payload};
    default:
      return state;
  }
}

export default CampaignActivityList;
