import React, { useState } from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {Button, Layout, Row, Col} from 'antd';
import {changeLocale} from 'store/actions/locale';
import Spinner from 'components/Spinner';
import AppearanceStyler from 'components/NoAuthHelper/AppearanceStyler';

const {Header, Footer, Content} = Layout;

const mapStateToProps = (state: any) => {
    return {
        title: state.appearanceSettings.title,
        locale: state.locale.locale,
        languageDictionary: state.languageDictionary,
        globalTheme: state.publicAppearanceReducer
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    changeLocale: (locale: string) => dispatch(changeLocale(locale)),
});

const FailPage: React.FC = ({languageDictionary, locale, changeLocale, globalTheme}: any) => {
    const [appearanceIsLoading, setAppearanceIsLoading] = useState(!!!globalTheme);
    
    const changeLanguage = (language: string) => {
        changeLocale(language.toUpperCase());
    };

    return (
        <Spinner spinning={appearanceIsLoading} opaque>
            <Layout style={{minHeight: '100vh'}}>
                <AppearanceStyler setAppearanceIsLoading={setAppearanceIsLoading}/>
                <Header style={{ backgroundColor: globalTheme && globalTheme.theme.themeBackground ? globalTheme.theme.themeBackground : '#463368' , height: 90}}>
                {globalTheme && globalTheme.logo 
                    ? <img src={globalTheme.logo ? URL.createObjectURL(globalTheme.logo) : undefined} alt={globalTheme.title} />
                    : <img src="/cs-logo.svg" alt={'CloudStudy'} />
                }
                </Header>
                <Content style={{ backgroundColor: globalTheme && globalTheme.theme.themeBackground ? globalTheme.theme.themeBackground : '#463368' , display: 'flex', flexDirection: 'column'}}>
                    <Row justify="start" align="middle" style={{marginLeft: 126, marginTop: 126, marginRight: 126}}>
                        <Col span={12} style={{zIndex: 1}}>
                            <div style={{textAlign: 'left'}}>
                                <div style={{fontSize: 64, color: globalTheme ? globalTheme.theme.navActiveTextColor : '#FFFFFF', minWidth: 700}}>
                                    <FormattedMessage id="general.success_registration_title"/>
                                </div>
                                <div style={{fontSize: 24, color: globalTheme ? globalTheme.theme.navTextColor : '#A094C2', width: 500}}>
                                    <FormattedMessage id="general.success_registration_text"/>
                                </div>
                            </div>
                        </Col>
                        <Col span={12} style={{display: 'flex', justifyContent: 'flex-end'}}>

                        </Col>
                    </Row>
                    <img alt='' style={{maxWidth: 800, display: 'flex', position: 'absolute', right: '15%', top: '10%', maxHeight: '80%'}}
                         src="/registration-complete.png"/>
                    <div className='language-switch-rc'>
                        {
                            Object.entries(languageDictionary).map((entries: any) => {
                                if (entries[0] !== 'loading' && entries[0] !== 'failed') {
                                    return (
                                        <Button className={"locale-switch" + (locale === entries[0] ? " active" : "")}
                                                onClick={() => changeLanguage(entries[0])}>{entries[0]}</Button>
                                    )
                                }
                                return null;
                            })
                        }
                    </div>
                </Content>
                <Footer style={{ backgroundColor: globalTheme && globalTheme.theme.themeBackground ? globalTheme.theme.themeBackground : '#463368' , textAlign: 'center'}}>
                    <ul className="footer-list">
                        <li>© CloudStudy Ltd</li>
                        <li><FormattedMessage id="login.terms"/></li>
                        <li><FormattedMessage id="login.terms_and_conditions"/></li>
                        <li><FormattedMessage id="login.cookies"/></li>
                    </ul>
                </Footer>
            </Layout>
        </Spinner>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(FailPage);

