import * as ActionTypes from 'store/actionTypes';

export const changeCampaignActivityList = ({enrollmentId, campaignId, campaignAttemptId, campaignDeadline, resertificationIndex}:any) => ({
  type: ActionTypes.SAVE_CAMPAIGN_ACTIVITY_LIST,

  payload: {
    enrollmentId: enrollmentId,
    campaignId: campaignId,
    campaignAttemptId: campaignAttemptId,
    campaignDeadline: campaignDeadline,
    resertificationIndex: resertificationIndex
  }
});
