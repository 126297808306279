import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Input as AntDInput, Radio, Tooltip, DatePicker, InputNumber, Button, message, Select as AntdSelect } from 'antd';
import { Input, Select } from 'components/Form';
import moment from 'moment';
import CampaignIconSelect from './CampaignIconSelect';
import DefaultLayout from 'components/DefaultLayout';
import coursesApiClient from 'utils/coursesApiClient';
import apiClient from 'utils/apiClient';
import { CampaignSettingsInterface, CampaignType } from '../types';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Spinner from 'components/Spinner';
import './styles.scss';
import { connect } from 'react-redux';

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 6 },
};

const mapStateToProps = (state: any) => {
    return {
        userRole: state.session.active_user_type
    }
};

const CampaignSettings: React.FC<CampaignSettingsInterface & RouteComponentProps> = ({
    campaignTitle,
    campaignLanguages,
    setSelectedLanguage,
    selectedLanguage,
    languageTranslations,
    setCampaignType,
    next,
    courses,
    owner,
    setCampaign,
    setCampaignId,
    campaignId,
    setCampaignTitle,
    campaign,
    validation,
    newCampaignStatus,
    launchButton,
    phishingCampaign,
    currentStep,
    sendRequest,
    launching,
    submitTab,
    status,
    history,
    userRole
}) => {
    const [campaignTitleTranslations, setCampaignTitleTranslations] = useState<any>({});
    const [campaignIcon, setCampaignIcon] = useState<string>('');
    const [courseScenario, setCourseScenario] = useState<CampaignType>();
    const [loading, setIsLoading] = useState(false);
    const [direction, setDirection] = useState('');
    const [electronicJournal, setElectronicJournal] = useState<boolean>(false);
    const [learningManagers, setLearningManagers] = useState();
    const [disableFields, setDisableFields] = useState({
        campaignTitle: false,
        deadline: false,
        default: false
    });

    const { TextArea } = AntDInput;


    const [form] = Form.useForm();
    const intl = useIntl();

    useEffect(() => {
        if (launching && currentStep === 0) {
            sendRequest(submitForm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [launching]);

    useEffect(() => {
        if (status === 'ONGOING') {
            setDisableFields({
                campaignTitle: false,
                deadline: false,
                default: true
            });
        }

        if (status === 'FINISHED') {
            setDisableFields({
                campaignTitle: true,
                deadline: true,
                default: true
            });
        }

    }, [status]);


    useEffect(() => {
        if (submitTab === 'settings') {
            submitForm();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitTab]);

    useEffect(() => {
        const setDefaultFieldValues = () => {
            const defaultJournalText = intl.formatMessage({ id: 'campaign.default_journal_text' }, { campaign: campaignTitle });
            form.setFieldsValue({
                courseScenario: 'TIMED_EVENT',
                signatureJournal: false,
                campaignJournalText: defaultJournalText,
                accessToCompletedActivityMaterials: true,
                includePretestAsOfSecondRecertification: true,
                avoidCertificateInvalidityPeriod: true,
                allowRepeatedAfterTests: 0,
            });
        };
        if (!campaignId) {
            setDefaultFieldValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);
    
    useEffect(() => {
        if (owner?.id) {
            loadCustomerLearningManagers();
        }
    },[owner])

    useEffect(() => {
        if (campaign) {
            setCampaignValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign]);

    const setCampaignValues = () => {

        let campaignName = campaign?.nameTranslations[selectedLanguage] ? campaign?.nameTranslations[selectedLanguage] : campaign?.name;

        form.setFieldsValue({
            campaignTitle: campaignName,
            supportedLanguages: campaign?.supportedLanguages,
            courseScenario: campaign?.courseScenario,
            campaignThumbnail: campaign?.campaignThumbnail,
            deadline: moment(campaign?.deadline),
            signatureJournal: !!campaign?.signatureJournal,
            certificationValidityTerm: campaign?.certificationValidityTerm,
            minimumCompletionTermAllowed: campaign?.minimumCompletionTermAllowed,
            maxTerm: campaign?.maxTerm,
            avoidCertificateInvalidityPeriod: !!campaign?.avoidCertificateInvalidityPeriod,
            allowRepeatedAfterTests: campaign?.allowRepeatedAftertests,
            includePretestAsOfSecondRecertification: !!campaign?.includePretestAsOfSecondRecertification,
            accessToCompletedActivityMaterials: !!campaign?.accessToCompletedActivityMaterials,
        });

        if (campaign?.settings && typeof campaign?.settings === 'string') {
            let settings = JSON.parse(campaign.settings)
            form.setFieldsValue({
                notificationManager: settings.notification_manager
            });
        }

        setCampaignIcon(campaign ? campaign.campaignThumbnail : '');
        setCourseScenario(campaign?.courseScenario);

        setElectronicJournal(!!campaign?.signatureJournal);
        setCampaignTitleTranslations(campaign?.nameTranslations);
        setCampaignTitle(campaignName);

        if (!!campaign?.signatureJournal) {
            loadSignatureSettings();
        }
    };

    const loadSignatureSettings = async () => {
        let campaignName = campaign?.nameTranslations[selectedLanguage] ? campaign?.nameTranslations[selectedLanguage] : campaign?.name;

        let response = await coursesApiClient.request(`/api/v1/courses/signature/${campaignId}/settings`, [], 'GET');

        const defaultJournalText = intl.formatMessage({ id: 'campaign.default_journal_text' }, { campaign: campaignName });

        let fieldValues = {};

        if (!!response.settings) {
            fieldValues = {
                campaignJournalText: response.settings.campaignJournalText ? response.settings.campaignJournalText : defaultJournalText,
            }
        } else {
            fieldValues = {
                campaignJournalText: defaultJournalText,
            }
        }

        form.setFieldsValue(fieldValues);
    }

    useEffect(() => {
        if (!campaignId) {
            setUserPassedCampaignData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignTitle]);

    const setUserPassedCampaignData = () => {
        form.setFieldsValue({
            campaignTitle: campaignTitle,
        });
    };

    useEffect(() => {
        form.setFieldsValue({
            supportedLanguages: campaignLanguages.length ? campaignLanguages : campaign?.supportedLanguages
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignLanguages]);

    const saveNameTranslation = async (language: string) => {

        const campaignTitles = campaignTitleTranslations;

        campaignTitles[selectedLanguage] = form.getFieldValue('campaignTitle');

        setCampaignTitleTranslations(campaignTitles);

        setSelectedLanguage(language);

        form.setFieldsValue({
            campaignTitle: campaignTitles[language]
        });
    };

    const disabledDates = (current: moment.Moment, to: boolean = false) => {
        const validDate = to ? moment() : moment().add('3', 'M');

        if (validDate === null) {
            return current < moment().subtract(1, 'day');
        }

        return (
            (to ? validDate > current : current > validDate) ||
            validDate.isSame(current, 'day') ||
            current < moment().subtract(1, 'day')
        );
    };

    const submitForm = async (values: any = null) => {

        if (!values) {
            values = form.getFieldsValue();
        }
        setIsLoading(true);
        let campaignTitles: any = {};

        if (
            campaignTitleTranslations &&
            Object.keys(campaignTitleTranslations).length === 0 &&
            Object.getPrototypeOf(campaignTitleTranslations) === Object.prototype
        ) {
            campaignTitles[selectedLanguage] = values.campaignTitle;
        } else {
            campaignTitles = campaignTitleTranslations;
            campaignTitles[selectedLanguage] = values.campaignTitle;
        }

        const parsedValues = {
            accessToCompletedActivityMaterials: !!values.accessToCompletedActivityMaterials,
            allowRepeatedAftertests: values.allowRepeatedAfterTests,
            avoidCertificateInvalidityPeriod: !!values.avoidCertificateInvalidityPeriod,
            campaignJournalText: values.campaignJournalText,
            campaignThumbnail: values.campaignThumbnail,
            courseScenario: values.courseScenario,
            courses: courses,
            customerUuid: owner?.uuid,
            certificationValidityTerm: values.certificationValidityTerm,
            deadline: values.deadline.format('DD.MM.YYYY'),
            signatureJournal: values.signatureJournal,
            includePretestAsOfSecondRecertification: !!values.includePretestAsOfSecondRecertification,
            maxTerm: values.maxTerm,
            minimumCompletionTermAllowed: values.minimumCompletionTermAllowed,
            name: values.campaignTitle,
            nameTranslations: campaignTitles,
            phishingEnabled: phishingCampaign,
            supportedLanguages: values.supportedLanguages,
            settings: {
                notification_manager: values.notificationManager
            }
        };

        let response;

        try {
            if (!campaignId) {
                response = await coursesApiClient.request('/api/v1/courses/campaigns', parsedValues, 'POST');
                setCampaignId(response.campaign.id)
            } else if (campaignId) {
                response = await coursesApiClient.request('/api/v1/courses/campaigns/' + campaignId, parsedValues, 'PUT');
            }
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsLoading(false);
        }

        const campaignResponse = response.campaign;
        setCampaignTitle(campaignResponse.name);

        setCampaign(campaignResponse);

        if (direction === 'next') {
            next(response.campaign.id);
        } else if (direction === 'save') {
            history.push('/courses/manage-campaigns');
        }

        if (response.campaign.id) {
            validation(true);
        }

        return setIsLoading(false);
    };

    const loadCustomerLearningManagers = async () => {
        // setUsersLoading(true);
        try {
            let url = '/api/v1/auth/customer/customer-admins';

            if (userRole === 'SUPER_ADMIN' || userRole === 'RESELLER_ADMIN' || userRole === 'DISTRIBUTOR_ADMIN') {
                url = url + '?customerId=' + owner?.id;
            }

            let response = await apiClient.request(url, [], 'GET');

            setLearningManagers(response.learningManagers);

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            // setUsersLoading(false);
        }   
    };

    return (
        <Spinner spinning={loading}>
            <Form form={form} onFinish={submitForm}>
                <div className='campaign-header-text'>
                    <h1 className='text-30' >{intl.formatMessage({ id: 'campaign.please_set_up_your_campaign_settings' })}</h1>
                    <h1 className='text-18'>{intl.formatMessage({ id: 'campaign.campaign_name_and_identity' })}</h1>
                </div>

                <Input
                    disabled={disableFields.campaignTitle}
                    name='campaignTitle'
                    label={intl.formatMessage({ id: 'campaign.campaign_title' })}
                    validation={{ required: true }}
                />

                {campaignLanguages &&
                    <Form.Item {...formItemLayout} label=' ' className='noColon'>
                        <Radio.Group >
                            {
                                campaignLanguages.map((languageKey: string) => {
                                    return (
                                        <Radio.Button value={languageKey} onClick={() => saveNameTranslation(languageKey)}>{languageKey}</Radio.Button>
                                    )
                                })
                            }
                        </Radio.Group>
                    </Form.Item>
                }
                <CampaignIconSelect setCampaignIcon={setCampaignIcon} />
                <Form.Item {...formItemLayout} label=' ' className='noColon'>
                    {campaignIcon &&
                        <div className='selected-campaign-thumbnail'>
                            <i className={`fal ${campaignIcon} header-item`} />
                        </div>
                    }
                </Form.Item>
                <Select
                    disabled={disableFields.default}
                    showArrow
                    placeholder='Search'
                    name='supportedLanguages'
                    label={'campaign.supperted_languages_within_campaign'}
                    valueKey='value'
                    labelKey='label'
                    manualOptions={languageTranslations}
                    showSearch
                    allowClear
                    style={{ width: 190, marginRight: 6, marginBottom: 0 }}
                    mode='multiple'
                    customRules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required_for_language' }) }]}
                    hint={'campaign.supported_language_hint'}
                />
                <div className='campaign-header-text'>
                    <h1 className='text-18'>{intl.formatMessage({ id: 'campaign.campaign_settings' })}</h1>
                </div>
                <Form.Item {...formItemLayout} name='courseScenario' label={intl.formatMessage({ id: 'campaign.course_scenario' })}>
                    <Radio.Group
                        disabled={disableFields.default}
                        onChange={(event: any) => {
                            setCourseScenario(event.target.value);
                            setCampaignType(event.target.value);
                        }}
                    >
                        <Radio checked value='TIMED_EVENT'><FormattedMessage id='campaign.timed_event' />
                            <Tooltip title={intl.formatMessage({ id: 'campaign.timed_event_hint' })}>
                                <i className='fal fa-question-circle header-item' />
                            </Tooltip>
                        </Radio>
                        <Radio value='CERTIFICATION'><FormattedMessage id='campaign.certification_course' />
                            <Tooltip title={intl.formatMessage({ id: 'campaign.certification_course_hint' })}>
                                <i className='fal fa-question-circle header-item' />
                            </Tooltip>
                        </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item {...formItemLayout}
                    label={
                        <span>
                            {intl.formatMessage({ id: 'campaign.finish_campaign_on' })}
                            <Tooltip title={intl.formatMessage({ id: 'campaign.finish_campaign_on_hint' })}>
                                <i className='fal fa-question-circle header-item' />
                            </Tooltip>
                        </span>
                    }
                    name='deadline'
                    rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                >
                    <DatePicker
                        disabled={disableFields.deadline}
                        style={{ width: 200 }}
                        format='YYYY-MM-DD'
                        disabledDate={(current: any) => disabledDates(current, true)}
                        placeholder=''
                    />
                </Form.Item>
                {courseScenario === 'CERTIFICATION' &&
                    <Form.Item {...formItemLayout}
                        label={
                            <span>
                                {intl.formatMessage({ id: 'campaign.certification_validity_term' })}
                                <Tooltip title={intl.formatMessage({ id: 'campaign.certification_validity_term_hint' })}>
                                    <i className='fal fa-question-circle header-item' />
                                </Tooltip>
                            </span>
                        }
                        name='certificationValidityTerm'
                        rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                    >
                        <InputNumber
                            disabled={disableFields.default}
                            min={0}
                        />
                    </Form.Item>
                }
                <Form.Item className='required-form-item-container' {...formItemLayout}
                    label={
                        <span>
                            <span className='required-form-item'>
                                *
                            </span>
                            {intl.formatMessage({ id: 'campaign.minimum_completion_term_allowed' })}
                            <Tooltip title={intl.formatMessage({ id: 'campaign.minimum_completion_term_allowed_hint' })}>
                                <i className='fal fa-question-circle header-item' />
                            </Tooltip>
                        </span>
                    }
                    name='minimumCompletionTermAllowed'
                    required={true}
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (value > getFieldValue('maxTerm') && getFieldValue('maxTerm') !== 0) {
                                    return Promise.reject(intl.formatMessage({ id: 'campaign.invalid_min_term' }));
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <InputNumber
                        disabled={disableFields.default}
                        min={0}
                    />
                </Form.Item>
                <Form.Item className='required-form-item-container' {...formItemLayout}
                    label={
                        <span>
                            <span className='required-form-item'>
                                *
                            </span>
                            {intl.formatMessage({ id: 'campaign.maximum_completion_term_allowed' })}
                            <Tooltip title={intl.formatMessage({ id: 'campaign.maximum_completion_term_allowed_hint' })}>
                                <i className='fal fa-question-circle header-item' />
                            </Tooltip>
                        </span>
                    }
                    name='maxTerm'
                    required={true}
                    rules={[
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (value < getFieldValue('minimumCompletionTermAllowed') && value !== 0) {
                                    return Promise.reject(intl.formatMessage({ id: 'campaign.invalid_max_term' }));
                                }
                                return Promise.resolve();
                            },
                        }),
                    ]}
                >
                    <InputNumber
                        disabled={disableFields.default}
                        min={0}
                    />
                </Form.Item>
                {courseScenario === 'CERTIFICATION' &&
                    <Form.Item {...formItemLayout}
                        label={
                            <span>
                                {intl.formatMessage({ id: 'campaign.avoid_certificate_invalidity_period' })}
                                <Tooltip title={intl.formatMessage({ id: 'campaign.avoid_certificate_invalidity_period' })}>
                                    <i className='fal fa-question-circle header-item' />
                                </Tooltip>
                            </span>
                        }
                        name='avoidCertificateInvalidityPeriod'
                    >
                        <Radio.Group
                            disabled={disableFields.default}
                        >
                            <Radio value={true}><FormattedMessage id='general.yes' /></Radio>
                            <Radio value={false}><FormattedMessage id='general.no' /></Radio>
                        </Radio.Group>
                    </Form.Item>
                }

                <Form.Item {...formItemLayout}
                    label={
                        <span>
                            {intl.formatMessage({ id: 'campaign.allow_repeated_after_tests' })}
                            <Tooltip title={intl.formatMessage({ id: 'campaign.allow_repeated_after_tests_hint' })}>
                                <i className='fal fa-question-circle header-item' />
                            </Tooltip>
                        </span>
                    }
                    name='allowRepeatedAfterTests'
                >
                    <InputNumber
                        disabled={disableFields.default}
                        min={0}
                    />
                </Form.Item>
                {courseScenario === 'CERTIFICATION' &&
                    <Form.Item {...formItemLayout}
                        label={
                            <span>
                                {intl.formatMessage({ id: 'campaign.include_pre_test_as_of_2nd_recertification' })}
                                <Tooltip title={intl.formatMessage({ id: 'campaign.include_pre_test_as_of_2nd_recertification_hint' })}>
                                    <i className='fal fa-question-circle header-item' />
                                </Tooltip>
                            </span>
                        }
                        name='includePretestAsOfSecondRecertification'
                    >
                        <Radio.Group disabled={disableFields.default}>
                            <Radio value={true}><FormattedMessage id='general.yes' /></Radio>
                            <Radio value={false}><FormattedMessage id='general.no' /></Radio>
                        </Radio.Group>
                    </Form.Item>
                }
                <Form.Item {...formItemLayout}
                    label={
                        <span>
                            {intl.formatMessage({ id: 'campaign.allow_access_to_completed_activity_materials' })}
                            <Tooltip title={intl.formatMessage({ id: 'campaign.allow_access_to_completed_activity_materials_hint' })}>
                                <i className='fal fa-question-circle header-item' />
                            </Tooltip>
                        </span>
                    }
                    name='accessToCompletedActivityMaterials'
                >
                    <Radio.Group disabled={disableFields.default}>
                        <Radio value={true} ><FormattedMessage id='general.yes' /></Radio>
                        <Radio value={false} ><FormattedMessage id='general.no' /></Radio>
                    </Radio.Group>
                </Form.Item>
                <div className='assignment-notification-settings'>
                    <Form.Item
                        {...{ labelCol: { span: 8 }, wrapperCol: { span: 6 } }}
                        name='notificationManager'
                        rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                        label={intl.formatMessage({ id: 'campaign.responsible_customer_admin' })}
                    >
                        <AntdSelect
                            allowClear
                            showSearch
                            notFoundContent='No customer users found'
                            style={{ height: '100%', width: '100%', borderRadius: 8 }}
                            loading={false}
                            dropdownStyle={{ minWidth: '400px' }}
                        >
                            {learningManagers &&
                                Object.values(learningManagers).map((el: any) => {
                                    return <AntdSelect.Option key={el.userTypeUuid} value={el.userTypeUuid}>{`${el.name} ${el.surname} (${el.email})`}</AntdSelect.Option>
                                })
                            }
                        </AntdSelect>
                    </Form.Item>
                </div>
                <div className='electronic-journal-container'>
                    <hr className='form-group-separator' />
                    <div className='campaign-header-text'>
                        <h1 className='text-18'><FormattedMessage id='campaign.journal' /></h1>
                    </div>

                    <Form.Item {...formItemLayout} label={
                        <span>
                            <FormattedMessage id='campaign.enable_electronic_journal' />
                            <Tooltip title={intl.formatMessage({ id: 'campaign.enable_electronic_journal_hint' })}>
                                <i className='fal fa-question-circle header-item' />
                            </Tooltip>
                        </span>
                    } name='signatureJournal'>
                        <Radio.Group onChange={(event: any) => setElectronicJournal(event.target.value)} defaultValue={false} disabled={disableFields.default}>
                            <Radio value={true}><FormattedMessage id='general.yes' /></Radio>
                            <Radio value={false}><FormattedMessage id='general.no' /></Radio>
                        </Radio.Group>
                    </Form.Item>
                    {electronicJournal &&
                        <Form.Item {...formItemLayout}
                            label={
                                <span>
                                    <FormattedMessage id='campaign.electronic_journal_text' />
                                    <Tooltip title={intl.formatMessage({ id: 'campaign.electronic_journal_text_hint' })}>
                                        <i className='fal fa-question-circle header-item' />
                                    </Tooltip>
                                </span>
                            }
                            name='campaignJournalText'
                        >
                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }} maxLength={256} />
                        </Form.Item>
                    }
                </div>
            </Form>
            <DefaultLayout.PageFooterWithRow
                left={
                    <>
                        {newCampaignStatus &&
                            <Button loading={loading} onClick={() => { setDirection('next'); form.submit(); }}>
                                <FormattedMessage id='general.next' />
                            </Button>
                        }
                    </>
                }
                right={
                    <>
                        {!newCampaignStatus &&
                            < Button onClick={() => { history.push('/courses/manage-campaigns'); }}>
                                <FormattedMessage id='general.back' />
                            </Button>
                        }
                        <Button loading={loading} onClick={() => { form.submit(); setDirection('save'); }} className='save-button'>
                            <i className='fal fa-save header-item' />
                            <FormattedMessage id={status === 'DRAFT' ? 'campaign.save_as_draft' : 'general.save'} />
                        </Button>
                        {!newCampaignStatus && launchButton(submitForm)}
                    </>

                }
            />
        </Spinner>
    )
};

export default connect(mapStateToProps)(withRouter(CampaignSettings));
