import React, { useState, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, message, Button } from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import Spinner from 'components/Spinner';
import apiClient from 'utils/apiClient';
import htmlTemplateParser from 'utils/htmlTemplateParser';
import EmailTemplateForm from '../EmailTemplateForm';
import { CloseCircleFilled } from '@ant-design/icons';
import config from 'config/config';
import htmlTemplateOrganizationParser from 'utils/htmlTemplateOrganizationParser';

const mapStateToProps = (state: any) => {
  return {
    locale: state.locale.locale,
    campaignData: state.campaignData,
    session: state.session
  };
}

const EmailTemplateFormOrganization: React.FC = ({ match, locale, session, history }: any) => {
  const [defaultTemplateId, setDefaultTemplateId] = useState();
  const [allVariables, setAllVariables] = useState();
  const [emailCategories, setEmailCategories] = useState();
  const [templateType, setTemplateType] = useState<string>('');
  const [category, setCategory] = useState<string>('');
  const [mailables, setMailables] = useState([]);
  const [selectedMailable, setSelectedMailable] = useState<any>();
  const [mailable, setMailable] = useState<any>();
  const [disableCampaignNotification, setDisableCampaignNotification] = useState(false);
  const [fillAllFielsError, setFillAllFielsError] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('');
  const [supportedLanguages, setSupportedLanguages] = useState<any>([]);
  const [mailTemplateName, setMailTemplateName] = useState<any>({});
  const [mailTemplateSubject, setMailTemplateSubject] = useState<any>({});
  const [mailTemplateHtml, setMailTemplateHtml] = useState<any>({});
  const [htmlTemplate, setHtmlTemplate] = useState(''); //TinyMCE Editor
  const [mailTemplateSelectedName, setMailTemplateSelectedName] = useState<string>();
  const [mailTemplateSelectedSubject, setMailTemplateSelectedSubject] = useState<string>();
  const [mailTemplateSelectedHtml, setMailTemplateSelectedHtml] = useState<string>();
  const [isTemplateLoading, setIsTemplateLoading] = useState<boolean>(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [disableMailable, setDisableMailable] = useState(false);
  const [disableCategory, setDisableCategory] = useState(false);

  const [selectedSmsTemplate, setSelectedSmsTemplate] = useState<string>(),
  [smsMailTemplate, setSmsMailTemplate] = useState<any>({});

  const organizationId = match.params.id;
  const templateUuid = match.params.uuid;
  const activeUserType = session.active_user_type;

  const [form] = Form.useForm();
  const intl = useIntl();

  useEffect(() => {
    if (!templateUuid) {
      setIsTemplateLoading(true);
    }
    const loadEmailCategories = async (templateType: string = '', templateCategoryId: number = 0) => {
      const categoriesResponse = await apiClient.request(`/api/v1/mail-templates/mail-categories`, {}, 'GET');

      if (templateCategoryId !== 0) {
        setCategory(categoriesResponse[templateCategoryId])
      }

      if (activeUserType !== 'SUPER_ADMIN') {
        if (templateType === 'CUSTOM' || templateType === '') {
          Object.entries(categoriesResponse).map((el: any) => {
            if (el[1] === 'system_events') {
              delete categoriesResponse[el[0]]
            }
            return categoriesResponse;
          })
        }
      }
      if (!templateUuid) {
        setIsTemplateLoading(false);
      }
      return setEmailCategories(categoriesResponse);
    }

    const loadVariables = async (templateId: number = 0) => {
      if (templateId) {
        const allVariables = await apiClient.request(`/api/v1/organization-mail-templates/${templateId}/variables`, {}, 'GET');
        return setAllVariables(allVariables);
      }
    }

    const loadMailables = async () => {
      let mailablesResponse = await apiClient.request(config.api.routes.backend.standardMailables, {}, 'GET');
      setMailables(mailablesResponse);
    }

    const selectLanguage = (templateResponse: any) => {
      if (templateResponse.name[locale]) {
        return locale;
      } else if (templateResponse.name['EN']) {
        return 'EN';
      } else {
        return Object.keys(templateResponse.name)[0];
      }
    }

    const loadTemplate = async () => {
      setIsTemplateLoading(true);

      const templateResponse = await apiClient.request(`/api/v1/organization-mail-templates/${templateUuid}/template`, {}, 'GET');

      const mailTemplate = templateResponse.mailTemplates;

      const language = await selectLanguage(mailTemplate);

      setSelectedLanguage(language);
      loadEmailCategories(mailTemplate.templateType, mailTemplate.categoryId);

      await loadVariables(mailTemplate.id);

      if (mailTemplate.templateType === 'STANDARD') {
        loadMailables();
        setDisableMailable(true);
        setDisableCategory(true);
        setDisableCampaignNotification(true);
      }

      setSupportedLanguages(mailTemplate.supportedLanguages);
      setDefaultTemplateId(mailTemplate.defaultTemplateId);
      setTemplateType(mailTemplate.templateType);
      setMailTemplateSubject(mailTemplate.subject);
      setMailTemplateName(mailTemplate.name);
      setMailTemplateSelectedName(mailTemplate.name[language]);
      setMailTemplateSelectedSubject(mailTemplate.subject[language]);

      if (mailTemplate.html) {
        setMailTemplateHtml(mailTemplate.html);

        let html = '';
        if (mailTemplate.html[language]) {
          html = htmlTemplateOrganizationParser.htmlTemplateOrganizationParser(mailTemplate.html[language], intl);
        }

        setMailTemplateSelectedHtml(html);
        setHtmlTemplate(html);
        form.setFieldsValue({
          htmlTemplate: html,
        });
      } else {
        setMailTemplateHtml([]);
      }

      if (mailTemplate.smsTemplate) {
        let sms = '';
        setSmsMailTemplate(mailTemplate.smsTemplate);

        if (mailTemplate.smsTemplate && mailTemplate.smsTemplate[language]) {
          sms = mailTemplate.smsTemplate[language]
        }

        setSelectedSmsTemplate(sms)

        form.setFieldsValue({
          smsTemplate: sms,
        });

      } else {
        setSmsMailTemplate([]);
      }

      const mailableArray = mailTemplate.mailable.split('\\');

      setMailable(mailableArray[mailableArray.length - 1]);

      let mailableSnakeCase = mailableArray[mailableArray.length - 1].replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`);

      const templateMailable = mailableSnakeCase.substring(1).toUpperCase();

      form.setFieldsValue({
        name: mailTemplate.name[language],
        mailable: mailableArray[mailableArray.length - 1],
        learningCampaignNotificationType: templateMailable,
        templateType: mailTemplate.templateType,
        subject: mailTemplate.subject[language],
        language: mailTemplate.language,
        categoryId: mailTemplate.categoryId,
        supportedLanguages: mailTemplate.supportedLanguages
      });

      setIsTemplateLoading(false);
    }

    const loadFrom = async (organizationId: number) => {
      const response = await apiClient.request(`/api/v1/customize-environment/template-email-settings/RESELLER/${organizationId}`, [], 'GET')
      form.setFieldsValue({
        from: response.from
      });
    }

    loadVariables();

    if (organizationId) {
      loadFrom(organizationId);
    }

    if (templateUuid) {
      loadTemplate();
    } else {
      loadEmailCategories();
      setMailable('UserDefinedTemplateMail');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  const setFieldValues = (languageCode: string) => {
    setMailTemplateSelectedName(mailTemplateName[languageCode]);
    setMailTemplateSelectedSubject(mailTemplateSubject[languageCode]);

    form.setFieldsValue({
      name: mailTemplateName[languageCode],
      subject: mailTemplateSubject[languageCode],
    });

    if (mailTemplateHtml && languageCode in mailTemplateHtml && mailTemplateHtml[languageCode]) {
      const html = htmlTemplateOrganizationParser.htmlTemplateOrganizationParser(mailTemplateHtml[languageCode], intl);
      setMailTemplateSelectedHtml(html);
      setHtmlTemplate(html);

      form.setFieldsValue({
        htmlTemplate: html,
      });

    } else {
      setMailTemplateSelectedHtml('');
      setHtmlTemplate('');
      form.setFieldsValue({
        htmlTemplate: '',
      });
    }

    if (smsMailTemplate && languageCode in smsMailTemplate && smsMailTemplate[languageCode]) {
      const sms = smsMailTemplate[languageCode];
      setSelectedSmsTemplate(sms);

      form.setFieldsValue({
        smsTemplate: sms,
      });

    } else {
      setSelectedSmsTemplate('');
      form.setFieldsValue({
        smsTemplate: '',
      });
    }
  }

  const setTemplateTranslations = (language: string, name: string, subject: string, html: string, sms: string) => {
    let templateLanguage = language;

    let templateName = mailTemplateName;
    let templateSubject = mailTemplateSubject;
    let templateHtml = mailTemplateHtml;
    let smsTemplate = smsMailTemplate;

    if (templateLanguage === '') {
      templateLanguage = supportedLanguages[0];
    }

    templateName[templateLanguage] = name;
    templateSubject[templateLanguage] = subject;
    templateHtml[templateLanguage] = templateHtml ? htmlTemplateParser.htmlTemplateParse(html) : '';
    smsTemplate[templateLanguage] = sms;

    setMailTemplateName(templateName);
    setMailTemplateSubject(templateSubject);
    setMailTemplateHtml(templateHtml);
    setSmsMailTemplate(smsTemplate);
  }

  const languageChange = (languageCode: string) => {
    const values = form.getFieldsValue();

    const newMailTemplateName = values.name;
    const newMailTemplateSubject = values.subject;
    const newMailTemplateHtml = values.htmlTemplate;
    const newSmsTemplate = values.smsTemplate;

    if (
      newMailTemplateName === mailTemplateSelectedName &&
      newMailTemplateSubject === mailTemplateSelectedSubject &&
      newMailTemplateHtml === mailTemplateSelectedHtml &&
      newSmsTemplate === selectedSmsTemplate &&
      templateUuid
    ) {
      setFieldValues(languageCode);
    } else {
      setTemplateTranslations(
        selectedLanguage,
        newMailTemplateName,
        newMailTemplateSubject,
        htmlTemplate,
        newSmsTemplate
      );

      setFieldValues(languageCode);
    }
    setSelectedLanguage(languageCode);
  }

  const deleteLangugae = async (language: string) => {
    const newSupportedLanguages = supportedLanguages.filter((element: string) => { return element !== language });
    setSupportedLanguages(newSupportedLanguages);
  }

  const submitForm = async (values: any) => {
    setIsTemplateLoading(true);
    setSubmitInProgress(true);
    let filledFieldsCount = 0;

    const currentParsedHtml = htmlTemplateParser.htmlTemplateParse(htmlTemplate);

    let language;
    if (selectedLanguage) {
      language = selectedLanguage;
    } else if (selectedLanguage === '' && supportedLanguages.length > 0) {
      language = supportedLanguages[0];
    } else {
      return message.error('language_not_selected');
    }

    let templateName = mailTemplateName;
    let templateSubject = mailTemplateSubject;
    let templateHtml = mailTemplateHtml;

    templateName[language] = values.name;
    templateSubject[language] = values.subject;
    templateHtml = {
      ...templateHtml,
      [language]: currentParsedHtml
    };

    let smsContent = {
      ...smsMailTemplate,
      [language]: values.smsTemplate
    }

    let parsedValues: any = {
      templateType: templateType ? templateType : 'CUSTOM',
      categoryId: values.categoryId,
      language: language,
      from: values.from,
      organizationId: organizationId,
      supportedLanguages: supportedLanguages,

      name: templateName,
      subject: templateSubject,
      htmlTemplate: templateHtml,
      smsTemplate: smsContent
    }

    if (defaultTemplateId) {
      parsedValues['defaultTemplateId'] = defaultTemplateId;
    }
    let submitResponse;

    supportedLanguages.map((el: any) => {
      if (templateName.hasOwnProperty(el) &&
        templateName[el] !== '' &&
        templateName[el] !== undefined &&
        templateSubject.hasOwnProperty(el) &&
        templateSubject[el] !== '' &&
        templateSubject[el] !== undefined
      ) {
        filledFieldsCount = filledFieldsCount + 1;
      }
      return filledFieldsCount;
    })

    if (filledFieldsCount === supportedLanguages.length) {
      try {
        if (templateUuid) {
          submitResponse = await apiClient.request(`/api/v1/organization-mail-templates/${templateUuid}`, parsedValues, 'PUT');
        } else {
          submitResponse = await apiClient.request(`/api/v1/organization-mail-templates`, parsedValues, 'POST');
        }
        if (submitResponse.status_code > 299) throw submitResponse.message;

        setSubmitInProgress(false);
        setIsTemplateLoading(false);

        message.success(templateUuid ? intl.formatMessage({ id: "emailing.templates.updated_successfully" }) : intl.formatMessage({ id: "emailing.template_created" }));
        history.push('/e-mailing/manage-templates');

      } catch (error) {

        console.error(error);
        message.error(intl.formatMessage({ id: "general.submit_error" }));
        setSubmitInProgress(false);
        setIsTemplateLoading(false);
      }
    } else if (filledFieldsCount !== supportedLanguages.length) {
      setSubmitInProgress(false);
      setIsTemplateLoading(false);
      return setFillAllFielsError(true);
    }
  }

  return (
    <DefaultLayout.PageLayout withStickyFooter>
      <DefaultLayout.PageHeader
        breadcrumb={[{ name: intl.formatMessage({ id: 'emailing.templates.manage_templates' }), path: '/e-mailing/manage-templates' }]}
        title={intl.formatMessage({ id: templateUuid ? 'emailing.templates.edit_template' : 'emailing.templates.add_template' })}
      />
      <Spinner spinning={submitInProgress || isTemplateLoading} opaque={isTemplateLoading}>
        <DefaultLayout.PageContent withTopPadding>
          <Form form={form} onFinish={submitForm}>
            <EmailTemplateForm
              form={form}
              intl={intl}
              emailCategories={emailCategories}
              category={category}
              setCategory={setCategory}
              setSelectedMailable={setSelectedMailable}
              selectedMailable={selectedMailable}
              setHtmlTemplate={setHtmlTemplate}
              htmlTemplate={htmlTemplate}
              languageChange={languageChange}
              templateId={templateUuid}
              ownerVariables={allVariables}
              mailables={mailables}
              mailable={mailable}
              loading={isTemplateLoading}
              deleteLangugae={deleteLangugae}
              supportedLanguages={supportedLanguages}
              setSupportedLanguages={setSupportedLanguages}
              disableMailable={disableMailable}
              disableCategory={disableCategory}
              disableCampaignNotification={disableCampaignNotification}
              templateType={templateType}
            />
          </Form>
        </DefaultLayout.PageContent>
      </Spinner>
      <DefaultLayout.PageFooter className='justify-content-end'>
        {fillAllFielsError &&
          <div>
            <span style={{ marginRight: 5 }}>
              <CloseCircleFilled style={{ color: 'red' }} />
            </span>
            <span style={{ marginRight: 5 }}>
              <FormattedMessage id="error.fill_all_mandatory_fields_languages" />
            </span>
          </div>
        }
        <NavLink to="/e-mailing/manage-templates">
          <Button>
            <FormattedMessage id="general.back" />
          </Button>
        </NavLink>
        <Button type="primary" htmlType="submit" style={{ marginLeft: 10 }} loading={submitInProgress} onClick={() => form.submit()}>
          <FormattedMessage id="general.submit" />
        </Button>
      </DefaultLayout.PageFooter>
    </DefaultLayout.PageLayout>
  )
}

export default connect(mapStateToProps)(withRouter(EmailTemplateFormOrganization));
