import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import { message, Popover, Tag, Modal, Checkbox, Input, Form, Button} from 'antd';
import { PlusOutlined, ExclamationCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { paginatorQuery } from 'components/Table/methods';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import { Select } from 'components/Form';
import FilterBar from 'components/FilterBar';
import SendEmailModal from 'components/SendEmailModal';
import { UserRequestPropertiesType } from 'components/UsersComponents/types';
import Table from 'components/Table';
import './styles.scss';

const AdminList: React.FC<any> = ({match}) => {
  const [admins, setAdmins] = useState([]);
  const [isAdminsLoading, setIsAdminsLoading] = useState(false);
  const [visibleTooltip, setVisibleTooltip] = useState<number|null>(null);
  const [organization, setOrganization] = useState<any>({});
  const [isOrganizationLoading, setIsOrganizationLoading] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [searchText,setSearchText ] = useState('');
  const [selectedRowsLength, setSelectedRowsLength] = useState<number>(0);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [selectAllFiltered, setSelectAllFiltered] = useState(false);
  const [showScenariosModal, setShowScenariosModal] = useState(false);
  const [filter, setFilter] = useState();
  const [organizationalUnitLabels, setOrganizationalUnitLabels] = useState([]);
  const [organizationalUnitIds, setOrganizationalUnitIds] = useState<any>({});
  const [filterBarValues, setFilterBarValues] = useState<any>();
  const [refreshKey, setRefreshKey] = useState(0);

  const pageSizeOptions = [10, 20];
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageSize, setCurrentPageSize] = useState<number>(pageSizeOptions[0]);
  const [organizationType, setOrganizationType] = useState();

  const [rowSelection, setRowSelection] = useState<any>({
    filter: {},
    selectedAllUsers: false,
    selectedUsers: [],
    deSelectedUsers: [],
  });

  const { confirm, info } = Modal;
  const { Search } = Input;
  const [form] = Form.useForm();

  const intl = useIntl();
  const organizationId = match.params.organizationId;
  const resourceUrl = '';

  useEffect(() => {
    const loadOrganization = async () => {
      try {
        setIsOrganizationLoading(true);
        let organizationResponse = await apiClient.request(`/api/v1/organizations/${organizationId}`, {}, 'GET');
        setOrganizationType(organizationResponse.organization.type);
        setOrganization(organizationResponse.organization);
      } catch (err) {
        console.error(err);
        message.error(intl.formatMessage({id: "error.data_load"}));
      } finally {
        setIsOrganizationLoading(false);
      }
    };

    const loadAdmins = async () => {
      try {
        setIsAdminsLoading(true);

        let query = paginatorQuery(currentPage, currentPageSize);

        let adminsResponse = await getRecords(resourceUrl, query);

        setAdmins(adminsResponse.data);
        setTotalRecords(adminsResponse.pagination.total);
      } catch (err) {
        console.error(err);
        message.error(intl.formatMessage({id: "error.data_load"}));
      } finally {
        setIsAdminsLoading(false);
      }
    };
    form.setFieldsValue({
      accountStatus: ['ACTIVE']
    });

    loadOrganization();
    loadAdmins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, refreshKey]);

    useEffect(() => {
        if (filterBarValues) {
            filterBarUsersFilter(filterBarValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterBarValues]);

  const  filterBySearchString = (searchText: string, users: never[], includeColumn: string[]) => {
    return !searchText ? users : users.filter((user: any) => { return Object.keys(user).some(key => includeColumn.includes(key) ? user[key].toString().toLowerCase().includes(searchText.toLocaleLowerCase()) : false); });
  };

  const handleVisibleChange = (visible: boolean, recordId: number) => {
    if (visible) {
      setVisibleTooltip(recordId);
    } else {
      setVisibleTooltip(null);
    }
  };

  const filterBarUsersFilter = async (values:any) => {
    let query = 'page[number]=1&page[size]=' + currentPageSize;

    let requestUrl =  '/api/v1/auth/organizations/' + organizationId + '/users?' + query;

    setFilter(values.filter);

    let usersResponse = await apiClient.request(requestUrl, values, 'POST');
    let filteredUsers = usersResponse.users;

    setAdmins(filteredUsers);
    setTotalRecords(usersResponse.pagination.total);
    setCurrentPage(1)
  };

  const clearFilter = () => {
    form.setFieldsValue({
      organizationalUnitId: []
    });
    setAdmins([]);
  };

  const clearAllFilter = () => {
    form.setFieldsValue({
      customer:[],
      organization: [],
      organizationalUnitId: []
    });
    setAdmins([]);
    setRefreshKey(oldKey => oldKey +1)
  };

  const columns = [
    {
      title: intl.formatMessage({id: 'general.name'}),
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => {
        return (
          <NavLink to={`/organizations/${organizationId}/admins/${record.userUuid}/edit`}>
            {record.name}
          </NavLink>
        )
      }
    },
    {
      title: intl.formatMessage({id: 'general.surname'}),
      dataIndex: 'surname',
      key: 'surname',
      render: (text: string, record: any) => record.surname
    },
    {
      title: intl.formatMessage({id: 'general.email'}),
      dataIndex: 'email',
      key: 'email',
      render: (text: string, record: any) => record.email
    },
    {
      title: intl.formatMessage({id: 'users.organizational_unit'}),
      dataIndex: 'organizationalUnit',
      key: 'organizationalUnit',
      render: (text: string, record: any) => record.organizationalUnitName
    },
    {
      title: intl.formatMessage({id: 'users.user_status'}),
      dataIndex: 'accountStatus',
      render: (text: string, record: any, tag: any) => {
        let color;
        if (record.accountStatus) {
          if (record.accountStatus === 'ACTIVE') color = 'green';
          if (record.accountStatus === 'BLOCKED') color = 'red';
          return (
            <>
            <Tag color={color} key={tag}>
              {intl.formatMessage({id: `user.status.${record.accountStatus}`})}
            </Tag>
            {record.deletionInitiatedAt != null ?
            <Tag color={'red'}>
              {intl.formatMessage({id: `user.status.DELETION`})}
            </Tag> : ''}
            </>
          )
        } else {
          return '-';
        }
      }
    },
    {
      title: intl.formatMessage({id: 'users.user_type'}),
      dataIndex: 'userType',
      render: (text: string, record: any, tag: any) => {
        let adminValue;
        if (record.roles) {
          let eachRole = record.roles.split(',');
          for (const [value] of eachRole.entries()) {
            if (intl.formatMessage({id: `user.type.${value}`}) === 'Reseller admin') adminValue = value;
            if (intl.formatMessage({id: `user.type.${value}`}) === 'Distributor admin') adminValue = value;
          }
        }

          return (
              <Tag color={'gold'} key={tag} visible={adminValue}>
                {intl.formatMessage({id: `user.type.${adminValue}`})}
              </Tag>
          )
      }
    },
    {
      key: 'actions',
      render: (text: string, record: any) => {
        const content = <>
          <NavLink to={`/organizations/${organizationId}/admins/${record.userUuid}/edit`}>
            <div className="popover-item">
              <FormattedMessage id="general.edit"/>
            </div>
          </NavLink>
        </>;

        return (
          <Popover
            visible={record.id === visibleTooltip}
            content={content}
            trigger="click"
            placement="bottomRight"
            arrowPointAtCenter
            onVisibleChange={(visible) => handleVisibleChange(visible, record.id)}
          >
            <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
              <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
            </div>
          </Popover>
        )
      },
    }
  ];

  const filterUsers = async (values: any) => {
    let parsedValues:any = {
      filter: {
        userTypes: ["ADMIN"],
        options: {
          accountStatus: `in:${values.accountStatus}`,

        }
      }
    };

    if (values.organizationalUnitId && values.organizationalUnitId.length > 0){
      let organizationalUnitId = `in:${values.organizationalUnitId}`;
      parsedValues.filter = {
        ...parsedValues.filter,
        options:{
          ...parsedValues.filter.options,
          organizationalUnitId
        }
      }
    }

    setShowFilterModal(false);

    try {
      setIsAdminsLoading(true);
      setFilter(parsedValues.filter);

      let query = 'page[number]=1&page[size]=' + pageSizeOptions[0];

      let usersResponse = await apiClient.request(`/api/v1/auth/organizations/${organizationId}/users?` + query, parsedValues, 'POST');
      let filteredUsers = usersResponse.users;

      setAdmins(filteredUsers);
      setTotalRecords(usersResponse.pagination.total);
      setCurrentPage(1)
    } catch (err) {
      console.error(err);
      message.error(intl.formatMessage({id: "error.data_load"}));
    } finally {
      setSelectedUsers([]);
      setSelectedRowsLength(0);
      setIsAdminsLoading(false);
    }
  };

  const includeColumn = ['name', 'surname', 'email'];
  let filteredUsers = filterBySearchString(searchText, admins, includeColumn);

const blockUsers = async () => {

  let parsedValues: UserRequestPropertiesType = rowSelection;

  parsedValues.organizationType = 'ORGANIZATION';
  parsedValues.organizationId = organizationId;

  if (parsedValues.filter && parsedValues.filter.length === 0) {
      parsedValues.filter = null;
  }

  try {
      await apiClient.request('/api/v1/users/mass-block', parsedValues, 'POST');

      info({
          content: intl.formatMessage({id: 'users.block_users_info'}, {amount: selectedRowsLength}),
          icon: <InfoCircleOutlined />,
          cancelText: intl.formatMessage({id: 'general.close'})
      });

      message.success(intl.formatMessage({id: "users.users_blocked_sucessfully"}));
  } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({id: "general.submit_error"}));    // TODO: Izmantot notification
  }
};

const activateUsers = async () => {
  let parsedValues : UserRequestPropertiesType = rowSelection;

  parsedValues.organizationType = 'ORGANIZATION';
  parsedValues.organizationId = organizationId;

  if (parsedValues.filter && parsedValues.filter.length === 0) {
      parsedValues.filter = null;
  }

  try {

      let submitResponse = await apiClient.request('/api/v1/users/mass-activate', parsedValues, 'POST');

      info({
          content: intl.formatMessage({id: 'users.activate_users_info'}, {amount: selectedRowsLength}),
          icon: <InfoCircleOutlined />,
          cancelText: intl.formatMessage({id: 'general.close'})
      });

      if (!submitResponse) {
          return "max_users_limit_exceeded"
      }

      if (submitResponse.status_code > 299) return "submit error";
      message.success(intl.formatMessage({id: "users.users_activated_sucessfully"}));
  } catch (error) {
      let errorMessage = "general.submit_error";

      if (error === 'max_users_limit_exceeded') {
          errorMessage = 'error.' + error
      }

      console.error(error);
      message.error(intl.formatMessage({id: errorMessage}));
  }
};

const showConfirm = (value:boolean) => {
  if (value) {
    confirm({
      content:`Do you want to Activate ${selectedRowsLength} users? `,
      icon: <ExclamationCircleOutlined />,
      okText: intl.formatMessage({id: 'general.continue'}),
      cancelText: intl.formatMessage({id: 'general.cancel'}),
      onOk() {
        activateUsers();

      },
    });
  } else {
    confirm({
      content:`Do you want to Block ${selectedRowsLength} users? `,
      icon: <ExclamationCircleOutlined />,
      okText: intl.formatMessage({id: 'general.continue'}),
      cancelText: intl.formatMessage({id: 'general.cancel'}),
      onOk() {
        blockUsers();
      },
    });
  }
};

const showConfirmDelete = async () => {
  let parsedValues = {};
  let selectedForDelete = {};

  if (selectAllFiltered) {
    let response = await getRecords(resourceUrl, '');
    let users = response.data;
    selectedForDelete = users;

    parsedValues = users.map((e:any)=> [e.id]);
  } else {
    selectedForDelete = selectedUsers;
    parsedValues = selectedUsers.map((e:any)=> [e.id]);
  }

  if (Object.keys(parsedValues).length === 0) {
    Modal.warning({
      title: intl.formatMessage({id: "general.attention"}),
      content: intl.formatMessage({id: 'users.no_users_selected'})
    });
  } else {
    if (!validDeletionUsersSelection(selectedForDelete)) {
      return
    }

    confirm({
        title: intl.formatMessage({id: "general.attention"}),
        content: intl.formatMessage({id: "users.confirm_delete"}),
        icon: <ExclamationCircleOutlined />,
        okText: intl.formatMessage({id: 'general.continue'}),
        cancelText: intl.formatMessage({id: 'general.cancel'}),

        onOk() {
          initiateUsersDelete(parsedValues);
        },
      });
  }
};

const initiateUsersDelete = async (parsedValues:any) => {
  setIsAdminsLoading(true);

  try {
      let submitResponse = await apiClient.request('/api/v1/admins/mass-delete', parsedValues, 'POST');

      let query = paginatorQuery(currentPage, currentPageSize);
      let usersResponse = await getRecords(resourceUrl, query);
      let filteredUsers = usersResponse.data;

      setAdmins(filteredUsers);

      if(submitResponse.status_code > 299)  message.error(intl.formatMessage({id: "general.submit_error"}));

      let info1 = intl.formatMessage({id: "users.after_initiate_delete_info_1"});
      let info2 = intl.formatMessage({id: "users.after_initiate_delete_info_2"});

      Modal.success({
        content:
        <div className="delete-success-modal-content">
          <p>{info1}</p>
          <p>{info2}</p>
        </div>
      });
  } catch (error) {
    console.error(error);
    message.error(intl.formatMessage({id: "general.submit_error"}));    // TODO: Izmantot notification
  } finally {
    setSelectedUsers([]);
    setSelectedRowsLength(0);
    setIsAdminsLoading(false);
    setSelectAllFiltered(false);
  }
};

const showConfirmUndelete = async () => {
  let parsedValues = {};
  let selectedForUndelete = {};

  if (selectAllFiltered) {
    let response = await getRecords(resourceUrl, '');
    let users = response.data;
    selectedForUndelete = users;

    parsedValues = users.map((e:any)=> [e.id])
  } else {
    selectedForUndelete = selectedUsers;
    parsedValues = selectedUsers.map((e:any)=> [e.id])
  }

  if (Object.keys(parsedValues).length === 0) {
    Modal.warning({
      title: intl.formatMessage({id: "general.attention"}),
      content: intl.formatMessage({id: 'users.no_users_selected'})
    });
  } else {
    if (!validUndeletionUsersSelection(selectedForUndelete)) {
      return
    }

    undeleteUsers(parsedValues);
  }
};

const undeleteUsers = async (parsedValues:any) => {
  setIsAdminsLoading(true);

  try {
      let submitResponse = await apiClient.request('/api/v1/admins/mass-undelete', parsedValues, 'POST');

      let query = paginatorQuery(currentPage, currentPageSize);
      let usersResponse = await getRecords(resourceUrl, query);
      let filteredUsers = usersResponse.data;

      setAdmins(filteredUsers);

      if(submitResponse.status_code > 299)  message.error(intl.formatMessage({id: "general.submit_error"}));

      message.success(intl.formatMessage({id: "users.undelete_success"}))
  } catch (error) {
    console.error(error);
    message.error(intl.formatMessage({id: "general.submit_error"}));    // TODO: Izmantot notification
  } finally {
    setSelectedUsers([]);
    setSelectedRowsLength(0);
    setIsAdminsLoading(false);
    setSelectAllFiltered(false);
  }
};

const validUndeletionUsersSelection = (users:any) => {
  let result = true;

  Object(users).forEach(function(user:any) {
    if (user.deletionInitiatedAt === null) {
      result = false
    }
  });

  if (!result) {
    Modal.warning({
      title: intl.formatMessage({id: "general.attention"}),
      content: intl.formatMessage({id: "users.no_correct_selection_for_undeletion"})
    });
  }

  return result;
};

const validDeletionUsersSelection = (users:any) => {
  let result = true;

  Object(users).forEach(function(user:any) {
    if (user.deletionInitiatedAt !== null) {
      result = false
    }
  });

  if (!result) {
    Modal.warning({
      title: intl.formatMessage({id: "general.attention"}),
      content: intl.formatMessage({id: "users.no_correct_selection_for_deletion"})
    });
  }

  return result;
};

const slelectAllButton = (users:any, boolean:boolean) => {
  if (boolean) {
    setSelectedUsers(users);
    setSelectAllFiltered(boolean);
    setSelectedRowsLength(totalRecords);
  } else {
    users =[];
    setSelectedUsers(users);
    setSelectAllFiltered(boolean);
    setSelectedRowsLength(users.length);
  }
};

const getRecords = async (resourceUrl: string, pageQuery: string) => {
  let data = {};
  let query = (pageQuery !== '') ? '?' + pageQuery : '';
  let usersResponse = {admins:[], users:[], pagination:{total:0}};
  let admins = [];

  if (filter) {
    data = {
      filter: filter
    };

    usersResponse = await apiClient.request(`/api/v1/auth/organizations/${organizationId}/users` + query, data, 'POST');
    admins = usersResponse.users;
  } else {
    usersResponse = await apiClient.request(`/api/v1/auth/organizations/${organizationId}/admins` + query, data, 'GET');
    admins = usersResponse.admins;
  }

  return  {
    data: (admins) ? admins : [],
    pagination: (usersResponse.pagination) ? usersResponse.pagination : {total:0}
  }
};

return (
    <>
      <DefaultLayout.PageLayout>
        <DefaultLayout.PageHeader
          loading={isOrganizationLoading}
          breadcrumb={[{name: intl.formatMessage({id: 'general.organizations'}), path: '/organizations'}]}
          title={(organization.name + ' ' + intl.formatMessage({id: 'general.admins'}))}
        />
        <DefaultLayout.PageContent>
          <NavLink to={`/organizations/${organizationId}/admins/add`}>
            <Button
              type="primary"
            >
              <PlusOutlined/> <FormattedMessage id="users.add_admin" />
            </Button>
          </NavLink>
          <Button type="primary" style={{marginLeft: 10}} onClick={() => setShowFilterModal(true)}><FormattedMessage id="general.filter"/></Button>
          <Button type='primary' onClick={()=>slelectAllButton(filteredUsers, !selectAllFiltered)} style={{marginLeft:'10px'}}>
            {selectAllFiltered ? <FormattedMessage id="users.deselect_all" /> : <FormattedMessage id="users.select_all" />}
          </Button>
          <Search
            className="table-search-field"
            placeholder={intl.formatMessage({id: "general.search"})}
            onChange={value => setSearchText(value.target.value)}
          />
        <FilterBar
          filter={filter}
          ownerId={organizationId}
          organizationalUnitLabels={organizationalUnitLabels}
          customerLabel={organization.name}
          organizationalUnit={organizationalUnitIds}
          setFilterBarValues={setFilterBarValues}
          clearFilter={clearFilter}
          clearAllFilter={clearAllFilter}
        />
          <Table
            setLoading = {setIsAdminsLoading}
            loading = {isAdminsLoading}
            columns = {columns}
            size='small'
            resourceUrl = {resourceUrl}
            totalRecords={totalRecords}


            loadedData = {admins}
            setLoadedData = {setAdmins}
  
            setTotalRecords = {setTotalRecords}
            setCurrentPageSize = {setCurrentPageSize}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageSizeOptions={pageSizeOptions}
            getRecords={getRecords}

            hasSelection={true}


            selectAll={selectAllFiltered}
            setSelectAll={setSelectAllFiltered}
            selectedRowsLength={selectedRowsLength}
            setSelectedRowsLength={setSelectedRowsLength}

            
            selectedData={selectedUsers}
            setSelectedData={setSelectedUsers}
            customRowSelection={rowSelection}
            setCustomRowSelection={setRowSelection}
          />

        </DefaultLayout.PageContent>

        <Modal
          className="user-list-modal"
          visible={showFilterModal}
          title={intl.formatMessage({id: 'users.user_filter'})}
          onOk={()=>form.submit()}
          onCancel={() => setShowFilterModal(false)}
          okText={intl.formatMessage({id: 'general.filter'})}
          cancelText={intl.formatMessage({id: 'general.cancel'})}
        >
          <Form form={form} onFinish={filterUsers}>
            <Select
              mode="multiple"
              allowClear
              showSearch
              name='organizationalUnitId'
              dataKey='organizationalUnits'
              label={intl.formatMessage({id: "general.unit"})}
              url={`/api/v1/organizations/${organizationId}/organizational-unit-types`}
              onChange={(val: any, option: any) => {setOrganizationalUnitLabels(option.map((el:any)=> el.children)); setOrganizationalUnitIds(option.map((el:any)=>[el.children, el.value])); }}
              integerKey={true}
              customLayout={true}
              filterOption={(input: string, option: any) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              dropdownStyle={{ minWidth: "385px" }}
            />
            <Form.Item name="accountStatus" label={intl.formatMessage({id: 'users.user_status'})}>
              <Checkbox.Group>
                <Checkbox value="ACTIVE" style={{ lineHeight: '32px' }}><FormattedMessage id='user.status.ACTIVE'/></Checkbox>
                <Checkbox value="BLOCKED" style={{ lineHeight: '32px' }}><FormattedMessage id='user.status.BLOCKED'/></Checkbox>
              </Checkbox.Group>
            </Form.Item>
          </Form>
        </Modal>
        <DefaultLayout.PageFooter className='sticky-footer-buttons' style={{border:'1px', borderColor:'#D9D9D9'}}>
              <div >
                {selectedRowsLength}
                <FormattedMessage id='users.rows_selected' />
              </div>
              <Button onClick={() => selectedUsers.length !== 0 ? setShowScenariosModal(true) : message.error(intl.formatMessage({id: "error.select_users"}))}>
                <i className="fal fa-envelope" style={{ paddingRight:'8px' }}/>
                <FormattedMessage id='users.send_email_users'/>
              </Button>
              <Button onClick={() => showConfirm(true)}>
                <i style={{color:'#52C419', paddingRight:'8px'}} className="fal fa-check-circle" />
                <FormattedMessage id='general.activate'/>
              </Button>
              <Button onClick={() => showConfirm(false)}>
                <i style={{color:'#F5222D', paddingRight:'8px'}} className="fal fa-times-circle" />
                <FormattedMessage id='general.block'/>
              </Button>
              <Button onClick={() => showConfirmDelete()}>
                <i style={{color:'#F5222D', paddingRight:'8px'}} className="fal fa-trash" />
                <FormattedMessage id='users.delete'/>
              </Button>
              <Button onClick={() => showConfirmUndelete()}>
                <i style={{color:'#F5222D', paddingRight:'8px'}} className="fal fa-trash-restore" />
                <FormattedMessage id='users.undelete'/>
              </Button>
        </DefaultLayout.PageFooter>
      </DefaultLayout.PageLayout>
      <SendEmailModal
        ownerId={organizationId}
        ownerType={organizationType}
        users={selectedUsers}
        isUserModal={true}
        visible={showScenariosModal}
        onCancel={() => setShowScenariosModal(false)}
        afterSubmit={() => setShowScenariosModal(false)}
        selectAll = {selectAllFiltered}
        getRecords = {getRecords}
        resourceUrl = {resourceUrl}
      />
    </>
  )
};

export default withRouter(AdminList);
