import React from 'react';
import { connect } from 'react-redux';
import './styles.scss';

const mapStateToProps = (state: any) => {
  return {
    sidebarCollapsed: state.sidebarCollapsed.collapsed
  }
}

const PageFooter: React.FC<any> = ({sidebarCollapsed, dispatch, ...props}) => {
  let className = '';

  if (sidebarCollapsed || props.noSidebar) {
    className = `${props.className || ''} page-footer sidebar-collapsed`;
  } else {
    className = `${props.className || ''} page-footer`;
  }

  return <div {...props} className={className} />;
}

export default connect(mapStateToProps)(PageFooter);
