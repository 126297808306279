import * as ActionTypes from 'store/actionTypes';

const defaultState = {
    isEmailSent: false
}

const IsEmailSent = (state = defaultState, action: any) => {
  switch(action.type) {
    case ActionTypes.CHANGE_RESET_PASSWORD_EMAIL_IS_SENT:
      var isEmailSent = action.payload;
      return {...state, isEmailSent: isEmailSent};

    default:
      return state;
  }
}

export default IsEmailSent;
