const htmlTemplateOrganizationParser = (html: any, intl: any) => {
    const VARIABLE_NODE_CLASS_NAME = 'email_template.variable';

    let htmlTemplateWithTranslatedVariables = html;

    if (htmlTemplateWithTranslatedVariables.includes('href="{{password_reset_url}}"')) {
        htmlTemplateWithTranslatedVariables = htmlTemplateWithTranslatedVariables.replace('href="{{password_reset_url}}"', 'href="[{password_reset_url}]"');
    }

    let allUsedVariables = htmlTemplateWithTranslatedVariables.match(/{{\w*}}/g) || [];
    allUsedVariables.forEach((el: any) => {
        el = el.replace('{{', '');
        el = el.replace('}}', '');
        switch (el) {
            case ('managing_organization_support_email'):
                htmlTemplateWithTranslatedVariables = htmlTemplateWithTranslatedVariables.replace(
                    /{{[^}]*}}/,
                    `<span data-variable="${el}" class="${VARIABLE_NODE_CLASS_NAME}">{_{${intl.formatMessage({ id: "email_template.variable.managing_organization_support_email" }, { reseller: intl.formatMessage({ id: 'emailing.reseller' }) })}}_}</span>`
                )
                break;
            case ('managing_organization_support_phone'):
                htmlTemplateWithTranslatedVariables = htmlTemplateWithTranslatedVariables.replace(
                    /{{[^}]*}}/,
                    `<span data-variable="${el}" class="${VARIABLE_NODE_CLASS_NAME}">{_{${intl.formatMessage({ id: "email_template.variable.managing_organization_support_phone" }, { reseller: intl.formatMessage({ id: 'emailing.reseller' }) })}}_}</span>`
                )
                break;
            case ('distributor_support_phone'):
                htmlTemplateWithTranslatedVariables = htmlTemplateWithTranslatedVariables.replace(
                    /{{[^}]*}}/,
                    `<span data-variable="${el}" class="${VARIABLE_NODE_CLASS_NAME}">{_{${intl.formatMessage({ id: "email_template.variable.distributor_support_phone" }, { distributor: intl.formatMessage({ id: 'emailing.distributor' }) })}}_}</span>`
                )
                break;
            default:
                htmlTemplateWithTranslatedVariables = htmlTemplateWithTranslatedVariables.replace(
                    /{{[^}]*}}/,
                    `<span data-variable="${el}" class="${VARIABLE_NODE_CLASS_NAME}">{_{${intl.formatMessage({ id: "email_template.variable." + el })}}_}</span>`
                )
                break;
        }
    })
    htmlTemplateWithTranslatedVariables = htmlTemplateWithTranslatedVariables.replace(/{_{/g, '{{');
    htmlTemplateWithTranslatedVariables = htmlTemplateWithTranslatedVariables.replace(/}_}/g, '}}');

    return htmlTemplateWithTranslatedVariables;
}
const exportedObject = {
    htmlTemplateOrganizationParser
};

export default exportedObject;

