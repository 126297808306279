import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import BarChart from 'components/Charts/BarChart';
import { DashboardChartProps } from './types';
import coursesApiClient from 'utils/coursesApiClient';

const TotalTimeSpentChart: React.FC<DashboardChartProps> = ({ customerUuid }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  const intl = useIntl();

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const url = `/api/v1/courses/statistic/dashboard/campaigns/customer/${customerUuid}/time-spent`;
        const response = await coursesApiClient.request(url);
        const responseData = response.statistic;

        const secondsToHours = (number: number) => number / 60 / 60;

        const data = [
          secondsToHours(responseData.total),
          secondsToHours(responseData.ytd),
          secondsToHours(responseData['12m']),
        ];

        const parsedData = {
          labels: [
            intl.formatMessage({ id: 'general.total' }),
            intl.formatMessage({ id: 'general.ytd' }),
            intl.formatMessage({ id: 'general.12m' }),
          ],
          datasets: [
            {
              data: data,
              backgroundColor: Array(3).fill('rgb(30, 209, 161)'),
              hoverBackgroundColor: Array(3).fill('rgb(10, 189, 141)'),
            },
          ],
        };

        setData(parsedData);
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, [intl, customerUuid]);

  return (
    <BarChart
      data={data}
      title={intl.formatMessage({ id: 'campaign.total_time_spent_in_elearning' })}
      barPercentage={0.9}
      loading={isLoading}
    />
  );
}

export default TotalTimeSpentChart;