import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Modal, Upload, Form, Button } from 'antd';
import apiClient from 'utils/apiClient';
import Spinner from 'components/Spinner';
import { UploadOutlined } from '@ant-design/icons';
import { Select } from 'components/Form';

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

const LanguageImportModal: React.FC<any> = ({ visible, onCancel, languageList }) => {
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [uploadLanguage, setUploadLanguage] = useState<string>();

    const intl = useIntl();
    const [importForm] = Form.useForm();

    const submitForm = async (values: any) => {
        setSubmitInProgress(true);
        try {
            const uploadLanguages: { [key: string]: any } = {
                translationLanguage: uploadLanguage,
                file: values.languageImport ? values.languageImport.fileList[0].originFileObj : null,
            };

            if (!values.languageImport) {
                setSubmitInProgress(false);
                return message.error(intl.formatMessage({ id: "system.language.file_not_uploaded" }));
            }

            const formData = new FormData();
            Object.keys(uploadLanguages).forEach((key) => {
                const value = uploadLanguages[key];
                if (value !== null) {
                    formData.append(key, value);
                }
            });

            const response = await apiClient.request('/api/v1/language/import', formData, 'POST');

            if (response.language.newTranslations !== 0 && response.language.updatedTranslations !== 0) {
                message.success(intl.formatMessage({ id: "system.language.import_successful_newTranslations_updatedTranslations"} , {newTranslations: response.language.newTranslations, updatedTranslations: response.language.updatedTranslations}));
                   
            } else if (response.language.newTranslations === 0) {
                message.success(intl.formatMessage({ id: "system.language.import_successful_updatedTranslations"}, {updatedTranslations: response.language.updatedTranslations}));
            } else if (response.language.updatedTranslations === 0) {
                message.success(intl.formatMessage({ id: "system.language.import_successful_newTranslations" }, {newTranslations: response.language.newTranslations}));
            }
            setSubmitInProgress(false);
            onCancel();
            window.location.reload();

        } catch (error) {
            message.error(intl.formatMessage({ id: "system.language.import_unsuccessful" }));
            console.error(error);
            setSubmitInProgress(false);
        }
    }

    return (
        <Form form={importForm} onFinish={submitForm} {...formItemLayout}>
            <Modal
                className="import-modal"
                title={intl.formatMessage({ id: 'system.language_import_modal' })}
                visible={visible}
                onOk={() => { importForm.submit() }}
                onCancel={onCancel}
                okText={intl.formatMessage({ id: 'system.language.import' })}
                cancelText={intl.formatMessage({ id: 'general.cancel' })}
            >
                <Spinner spinning={submitInProgress}>
                    <Select
                        name='uploadLanguage'
                        label={intl.formatMessage({ id: "system.language.language_import" })}
                        customLayout={formItemLayout}
                        manualOptions={languageList}
                        onChange={(language: string) => setUploadLanguage(language)}
                    />

                    <Form.Item
                        name='languageImport'
                        label={intl.formatMessage({ id: 'system.language.import_file' })}
                    >
                        <Upload
                            beforeUpload={() => false}
                            accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                        >
                            <Button icon={<UploadOutlined />}>
                                <span><FormattedMessage id="general.upload_no_colon" /></span>
                            </Button>
                        </Upload>
                    </Form.Item>
                </Spinner>
            </Modal>
        </Form>
    )
}

export default LanguageImportModal;
