import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { message, Form, Steps, Button} from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import Spinner from 'components/Spinner';
import PhishingSimulationOptions from 'components/Phishing/PhishingSimulationOptions';
import PhishingSimulationGophishTemplates from 'components/Phishing/PhishingSimulationGophishTemplates';
import './styles.scss';

const mapStateToProps = (state: any) => {
  return {
    session: state.session,
    phishingSimulationTemplateView: state.phishingSimulationTemplateView.templateLanguage
  }
};

const ManageTemplates: React.FC = ({session, match, history}: any) => {
    const [currentStep, setCurrentStep] = useState<any>(0);
    const [phishingSimulationTemplate, setPhishingSimulationTemplate] = useState<any>();
    const [simulationResponse, setSimulationResponse] = useState<any>();
    const [simulationTemplateName, setSimulationTemplateName] = useState();
    const [gophishTemplateName, setGophishTemplateName] = useState();
    const [gophishTemplateId, setGophishTemplateId] = useState();
    const [gophishLandingPageName, setGophishLandingPageName] = useState();
    const [gophishLandingPageId, setGophishLandingPageId] = useState();
    const [templates, setTemplates] = useState<any>();
    const [isLoadningTemplates, setIsLoadningTemplates] = useState(false);
    const [gophishSendingProfileId, setGophishSendingProfileId] = useState();
    const [gophishSendingProfileName, setGophishSendingProfileName] = useState();
    const [selectedIndustryTypes, setSelectedIndustryTypes] = useState<any>();
    const [industryTypesEnum, setIndustryTypesEnum] = useState<any>();

    const intl = useIntl();
    const [form] = Form.useForm();
    const [templateForm] = Form.useForm();
    const { Step } = Steps;
    const simulationId = match.params.id;
    const page = match.params.page;

    useEffect(() => {
      switch (page) {
          case '0':
              setCurrentStep(0);
              break;
          case '1':
              setCurrentStep(1);
              break;

      }
      const loadPhishingTemplateSimulation = async () => {
        let industryResponese = await apiClient.request('/api/v1/enum/industry-types', [], 'GET');
        let industryTypes:any = [];
        Object.keys(industryResponese).map((el:any)=> {
          return industryTypes.push(el);
        });
        setIndustryTypesEnum(industryTypes);

        setIsLoadningTemplates(true);
        try {
          let responese = await coursesApiClient.request(`/api/v1/courses/phishing/phishing-simulation/${simulationId}`, [], 'GET');
          let industry:any = [];

          if (responese.phishingSimulation.industry) {
          let industryArray = responese.phishingSimulation.industry.split(',');
            Object.entries(industryTypes).map((el:any)=>{
              if(industryArray.includes(el[1])) {
                industry.push(el[0])
              }
              return industry
            })
          }
          setSelectedIndustryTypes(industry);

          form.setFieldsValue({
            simulationTemplateUniversalName: responese.phishingSimulation.simulation_template_name,
            allowSmartDephish: !!responese.phishingSimulation.allow_smart_dephish,
            customPhishingCampaigns: !!responese.phishingSimulation.custom_phishing_campaigns,
            difficulty: responese.phishingSimulation.difficulty,
            industryTypes: industry
          });

          setTemplates(responese.phishingSimulation.phishing_template_combinations);
          setPhishingSimulationTemplate(responese.phishingSimulation);
        } catch (error) {
        message.error(intl.formatMessage({id: "error.server_error"}));

        } finally {
          setIsLoadningTemplates(false);
        }
      };

      const loadTemplateCombonations = async () => {
        let responese = await coursesApiClient.request(`/api/v1/courses/phishing/phishing-simulation/templates/${simulationId}`, [], 'GET');
        setTemplates(responese.phishingSimulationTemplates);
      };

      const loadIndustryTypesEnum = async () => {
        let responese = await apiClient.request('/api/v1/enum/industry-types', [], 'GET');
        let industryTypes:any = [];
        Object.keys(responese).map((el:any)=> {
          return industryTypes.push(el);
        });
        setIndustryTypesEnum(industryTypes);
      };

      if (simulationId) {
        loadPhishingTemplateSimulation();
        loadTemplateCombonations();
      } else {
        loadIndustryTypesEnum();
        form.setFieldsValue({
          allowSmartDephish: true,
          customPhishingCampaigns: true,
          difficulty: 'LOW'
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    const steps = [
        {
          title: 'First',
          content: 'First-content',
        },
        {
          title: 'Second',
          content: 'Second-content',
        }
      ];

    const submitForm = async () => {
      let values = form.getFieldsValue();
      let industryTypes:any = [];

      Object.values(selectedIndustryTypes).map((el:any)=>{
        if (industryTypesEnum[el]) {
          industryTypes.push(industryTypesEnum[el])
        }
        return industryTypes
    });

      let parsedValues = {
        simulationTemplateName: values.simulationTemplateUniversalName,
        allowSmartDephish: values.allowSmartDephish,
        customPhishingCampaigns: values.customPhishingCampaigns,
        difficulty: values.difficulty,
        industry: industryTypes
      };
      let responese = await coursesApiClient.request('/api/v1/courses/phishing/phishing-simulation', parsedValues, 'POST');

      history.push(`/phishing/templates/${responese.phishingSimulation.id}/edit/1`);

      setSimulationResponse(responese.phishingSimulation);
    };

    const submitTemplateForm = async () => {
      let simulationValues = form.getFieldsValue();
      let industryTypes:any = [];
      Object.values(selectedIndustryTypes).map((el:any)=>{
          if (industryTypesEnum[el]) {
            industryTypes.push(industryTypesEnum[el])
          }
          return industryTypes;
      });

      let simulationParsedValues = {
        simulationTemplateName: simulationValues.simulationTemplateUniversalName,
        allowSmartDephish: simulationValues.allowSmartDephish,
        customPhishingCampaigns: simulationValues.customPhishingCampaigns,
        difficulty: simulationValues.difficulty,
        industry: industryTypes
      };

      let values = templateForm.getFieldsValue();

      let templateId;
      if (phishingSimulationTemplate) {
        templateId = `${phishingSimulationTemplate.simulation_id}.${values.language}`
      } else if (simulationResponse) {
        templateId =`${simulationResponse.simulationId}.${values.language}`
      }

      let parsedValues = {
        language: values.language,
        simulationTemplateName:values.templateNameInChosenLanguage,
        gophishTemplateId: gophishTemplateId,
        templateName:gophishTemplateName,
        gophishLandingPageId: gophishLandingPageId,
        landingPageName: gophishLandingPageName,
        templateId:templateId,
        phishingTemplate: simulationId || simulationResponse.id,
        gophishSendingProfile: gophishSendingProfileName,
        gophishSendingProfileId: gophishSendingProfileId,
        url: values.listeningUrl
      };

      let langArray:any = [];
      let combinationId:any;
      let inArray = false;

      if (templates) {
        Object.values(templates).map((el:any)=> {
          langArray.push(el.language);
          if (el.language === values.language) {
            combinationId = el.id;
            inArray = true
          }
          return combinationId;
        })
      }

      try {
        if (templates) {
          let responese = await coursesApiClient.request(`/api/v1/courses/phishing/phishing-simulation/${simulationId}`, simulationParsedValues, 'PUT');
          setSimulationResponse(responese.phishingSimulation);
        }

      if (templates && templates.length > 0 && inArray && combinationId) {
        await coursesApiClient.request(`/api/v1/courses/phishing/template-combinations/${combinationId}`, parsedValues, 'PUT');

      } else {
        await coursesApiClient.request('/api/v1/courses/phishing/template-combinations', parsedValues, 'POST');
      }

      } catch (error) {
        message.error(intl.formatMessage({id: "error.server_error"}));
      } finally {
        history.push('/phishing/templates')
      }
    };

    const next = async () => {
      if (simulationId) {
        setCurrentStep(currentStep + 1);
        history.push(`/phishing/templates/${simulationId}/edit/1`);

      } else if (simulationTemplateName) {
        submitForm();
        setCurrentStep(currentStep + 1);
      } else {
        setCurrentStep(currentStep + 1);
        history.push(`/phishing/templates/${simulationResponse.id}/edit/1`);
      }
    };

    const prev = () => {
      if (simulationId) {
        setCurrentStep(currentStep - 1);
        history.push(`/phishing/templates/${simulationId}/edit/0`);
      } else if (simulationResponse) {
        history.push(`/phishing/templates/${simulationResponse.id}/edit/0`);
        setCurrentStep(currentStep - 1);
      } else {
        setCurrentStep(currentStep - 1);
        history.push(`/phishing/templates/${simulationResponse.id}/edit/0`);
      }
    };

  return (
    <>
      <DefaultLayout.PageLayout>
        <DefaultLayout.PageHeader
          breadcrumb={
            [{name: intl.formatMessage({id: 'phishing.phishing'}), path: '/phishing/templates'}]
          }
          title={intl.formatMessage({id: 'phishing.phishing_tempalte_manager'})}
        />
          <div style={{backgroundColor: 'white', padding: 16, borderRadius: 8}}>
          <Spinner spinning={isLoadningTemplates}>
            <Steps className='phishing-template-steps' style={{display:'none'}} current={currentStep}>
            <Step
                key={0}
            />
            <Step
                key={1}
            />
            </Steps>
            <Form form={form} onFinish={submitForm}>
                <div style={currentStep === 0 ? { display: ''} : {display: 'none'}} className='phishing-simulations-options'>
                    <PhishingSimulationOptions
                      phishingSimulationTemplate={phishingSimulationTemplate}
                      form={form}
                      setSimulationTemplateName={setSimulationTemplateName}
                      setSelectedIndustryTypes={setSelectedIndustryTypes}
                      industryTypes={industryTypesEnum}
                    />
                </div>
              </Form>
              <Form form={templateForm} onFinish={submitTemplateForm}>

                <div style={parseInt(currentStep) === 1 ? { display: ''} : {display: 'none'}}>
                    <PhishingSimulationGophishTemplates
                      setGophishTemplateName={setGophishTemplateName}
                      setGophishTemplateId={setGophishTemplateId}
                      setGophishLandingPageName={setGophishLandingPageName}
                      setGophishLandingPageId={setGophishLandingPageId}
                      setGophishSendingProfileName={setGophishSendingProfileName}
                      setGophishSendingProfileId={setGophishSendingProfileId}
                      templates={templates}
                      simulationId={simulationId}
                      form={templateForm}
                    />
                </div>
              </Form>
          </Spinner>
        </div>

        <DefaultLayout.PageFooter className='campaign-footer'>
            {parseInt(currentStep) !== 0 && (
              <Button onClick={() => prev()}  style={{ margin: '0 8px', float: 'left', marginTop: '12px' }}>
                <FormattedMessage id="campaign.previous" />
              </Button>
            )}
            {parseInt(currentStep) < steps.length - 1 && (
              <Button onClick={() => next()}  style={{float: 'left', marginTop: '12px' }}>
                <FormattedMessage id="general.next" />
              </Button>
            )}
            { parseInt(currentStep) === 1?
              <Button onClick={() => {submitTemplateForm();}} className='save-button' style={{ margin: '0 8px', float: 'right', marginTop: '12px' }}>
                  <FormattedMessage id="general.submit" />
              </Button>
              : null
            }
          </DefaultLayout.PageFooter>
      </DefaultLayout.PageLayout>

    </>
  )
};

export default connect(mapStateToProps)(withRouter(ManageTemplates));
