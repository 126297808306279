import * as ActionTypes from 'store/actionTypes';

const defaultState = {
    owner: null,
    type: null
  }
  
  const MailTemplateFilterOptions = (state = defaultState, action: any) => {
    switch(action.type) {
      case ActionTypes.SAVE_MAIL_TEMPLATE_FILTER_OPTIONS:
          let currentState = state;
          currentState.owner = action.payload;
        return currentState;
      default:
        return state;
    }
  }
  

export default MailTemplateFilterOptions;
