import * as ActionTypes from 'store/actionTypes';

const defaultState = {
  loading: true,
  failed: false,
}

const LanguageDictionary = (state = defaultState, action: any) => {
  switch(action.type) {
    case ActionTypes.LANGUAGE_DICTIONARY_GET_IN_PROGRESS:
      return { loading: true, failed: false };
    case ActionTypes.LANGUAGE_DICTIONARY_GET_SUCCESS:
      return { ...action.payload, loading: false, failed: false };
    case ActionTypes.LANGUAGE_DICTIONARY_GET_FAILED:
      return { loading: false, failed: true };
    default:
      return state;
  }
}

export default LanguageDictionary;
