import React from 'react';
import { useIntl } from 'react-intl';
import { Select } from 'components/Form';

interface ScormResultSelectInterface {
    name: string;
    label: string;
    layout?: {
        labelCol: {
            span: number
        }
        wrapperCol: {
            span: number
        }
    }
    disabled?: boolean;
    customRules?: any;
    hasDefaultLayout?: boolean;
}

const ScormResultSelect: React.FC<ScormResultSelectInterface> = ({ name, label, layout, disabled = false, customRules = [], hasDefaultLayout }) => {
    const expectedResult = {
        'Passed/Incomplete': 'Passed/Incomplete',
        'Passed/Failed': 'Passed/Failed',
        'Completed/Incomplete': 'Completed/Incomplete',
        'Completed/Failed': 'Completed/Failed'
    };
    const intl = useIntl();

    let formItemLayout = layout || {}

    if (hasDefaultLayout) {
        formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 }
        }
    }

    return (
        <Select
            customLayout={formItemLayout}
            name={name}
            label={intl.formatMessage({ id: label })}
            manualOptions={expectedResult}
            disabled={disabled}
            customRules={customRules}
        />
    )
}
export default ScormResultSelect;
