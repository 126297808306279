import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { message, Modal } from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import Button from 'components/Button';
import moment from 'moment';
import FormattedMessage from 'react-intl/lib/components/message';
import Spinner from 'components/Spinner';
import './styles.scss';

interface AdminSignatureModalInterface {
    visible: boolean;
    onCancel: any;
    campaignId: number;
    enrollmentId: number;
    reload: any;
    campaign: any;
}

const AdminSignatureModal: React.FC<AdminSignatureModalInterface> = ({ visible, onCancel, campaignId, enrollmentId, reload, campaign }) => {
    const [signatureSettings, setSignatureSettings] = useState<string>('');
    const [signatureIsLoading, setSignatureIsLoading] = useState(false);

    const intl = useIntl();

    useEffect(() => {
        if (visible) {
            loadCampaignSignatureSettings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

    const loadCampaignSignatureSettings = async () => {
        setSignatureIsLoading(true);
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/signature/${campaignId}/settings`, [], 'GET');
            setSignatureSettings(response.settings.description);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        } finally {
            setSignatureIsLoading(false);
        }
    }

    return (
        <Modal
            className='signature-modal'
            visible={visible}
            onCancel={onCancel}
            title={<span>
                <span style={{ fontSize: 25 }}>
                    <i style={{ fontWeight: 600, color: '#C1E6FF' }} className='fal fa-user-edit'></i>
                </span>
                <FormattedMessage id='student.signature' />
            </span>}
            footer={
                !campaign.signature ?
                    <Button onClick={() => onCancel()}>
                        <FormattedMessage id='general.cancel' />
                    </Button>
                    : <span>
                        <i style={{ color: '#D9D9D9' }} className="fal fa-user-edit"></i>
                        {moment(campaign.signature.created_at).format('HH:mm DD.MM.YYYY')}
                    </span>
            }
        >
            <Spinner spinning={signatureIsLoading}>
                {signatureSettings}
            </Spinner>
        </Modal >
    );
}

export default AdminSignatureModal;
