import React from 'react';
import { useIntl } from 'react-intl';
import { Form, DatePicker as AntDDatePicker } from 'antd';
import { constructValidationRules } from './validations';
import moment from 'moment';
import './styles.scss';

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 6 },
};

const DatePicker: React.FC<any> = ({name, label, validation = {}, defaultValue, className, customRules, validateTrigger, ...props}) => {
  const intl = useIntl();

  return (
    <Form.Item
      {...formItemLayout}
      name={name}
      label={label}
      rules={customRules ? customRules : constructValidationRules(validation, intl)}
      validateTrigger={validateTrigger}
    >
      <AntDDatePicker
        suffixIcon={<i className='fal fa-calendar-alt' />}
        defaultValue={defaultValue || moment()}
        {...props}
        className={'default-input-field ' + (className || '')}
      />
    </Form.Item>
  )
}

export default DatePicker;
