import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DefaultLayout from 'components/DefaultLayout';
import { connect } from 'react-redux';
import { Alert, InputNumber, Form, Radio, Button, Tooltip } from 'antd';
import { Select, Input } from 'components/Form';

import { withRouter, RouteComponentProps } from 'react-router-dom';
import { CampaignCertificatesInterface, CertificateResponseType, CertificateSubmitType } from '../types';
import coursesApiClient from 'utils/coursesApiClient';
import CertificateEditModal from './CertificateEditModal';
import PreviewCertificateModal from './PreviewCertificateModal';
import Spinner from 'components/Spinner';
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    }
};

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 6 },
};


const CampaignCertificates: React.FC<CampaignCertificatesInterface & RouteComponentProps> = ({
    campaignId,
    owner,
    next,
    previous,
    campaign,
    status,
    phishing,
    campaignLanguages,
    selectedLanguage,
    certificateTemplate,
    certificateGeneration,
    setCertificateGeneration,
    validation,
    launchButton,
    newCampaignStatus,
    currentStep,
    sendRequest,
    launching,
    submitTab,
    history
}) => {
    const [backgroundImage1, setBackgroundImage1] = useState<any>();
    const [backgroundImage2, setBackgroundImage2] = useState<any>();
    const [showEmailTemplateModal, setShowEmailTemplateModal] = useState(false);
    const [selectedBackground, setSelectedBackground] = useState<number>(1);
    const [certificate, setCertificate] = useState<CertificateResponseType | undefined>();
    const [titleList, setTitleList] = useState([]);
    const [newCertificateTitle, setNewCertificateTitle] = useState<string[]>([]);
    const [direction, setDirection] = useState('');
    const [certificatePreviewData, setCertificatePreviewData] = useState({});
    const [loading, setIsLoading] = useState(false);

    const [validationName, setValidationName] = useState(0);
    const [validationTextBefore, setValidationTextBefore] = useState('');
    const [validationTextAfter, setValidationTextAfter] = useState('');

    const [showCertificatePreviewModal, setShowCertificatePreviewModal] = useState(false);
    const [disableFields, setDisableFields] = useState({
        generateCert: false,
        default: false,
        buttons: false
    });

    const [form] = Form.useForm();
    const intl = useIntl();

    useEffect(() => {
        if (!certificateGeneration) {
            if (status === 'DRAFT') {
                setDisableFields({
                    generateCert: false,
                    default: true,
                    buttons: true
                });
            } else {
                setDisableFields({
                    generateCert: true,
                    default: true,
                    buttons: true
                });
            }
        } else {
            if (status === 'DRAFT') {
                setDisableFields({
                    generateCert: false,
                    default: false,
                    buttons: false
                });
            } else {
                setDisableFields({
                    generateCert: true,
                    default: true,
                    buttons: false
                });
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status, certificateGeneration]);

    useEffect(() => {
        if (launching && currentStep === 4) {
            sendRequest(submitForm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [launching]);

    useEffect(() => {
        if (submitTab === 'certificates') {
            submitForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitTab]);

    useEffect(() => {
        loadImage();

        if (campaignId) {
            loadCampaignCertificate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    useEffect(() => {
        if (!certificateGeneration) {
            validation(true);
        } else if (certificateGeneration) {
            validation(false);
        } else if (certificate) {
            validation(true);
        } else {
            validation(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [certificateGeneration]);

    useEffect(() => {
        if (certificateGeneration) {
            let name = form.getFieldValue('name');
            if (name && validationTextBefore && validationTextAfter) {
                validation(true);
            } else {
                validation(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validationName, validationTextBefore, validationTextAfter]);

    const loadCampaignCertificate = async () => {
        setIsLoading(true);
        const response = await coursesApiClient.request(`/api/v1/courses/campaigns/${campaignId}/certificates`, {}, 'GET');
        if (response.certificates !== []) {
            setCertificate(response.certificates);
        }

        setFormValues(response.certificates);
        setIsLoading(false);
    };

    useEffect(() => {
        if (campaign) {
            form.setFieldsValue({
                certificateGeneration: !!campaign?.generateCertificates,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaign]);

    const setFormValues = (certificate: any) => {
        form.setFieldsValue({
            language: certificate.language ? certificate.language : selectedLanguage,
            name: certificate.name,
            textBefore: certificate.textBefore,
            textAfter: certificate.textAfter,
            validUntil: certificate.validUntil,
        });

        const certificateBackground = certificate?.name ? certificate.templateId : 1;

        setSelectedBackground(certificateBackground);
    };

    const submitForm = async (values: any = null) => {
        if (status !== 'DRAFT') {
            return;
        }

        if (!values) {
            values = form.getFieldsValue();
        }

        if (!certificateGeneration) {
            navigate();
            await coursesApiClient.request(`/api/v1/courses/campaigns/${campaignId}/certificates-status`, { certificateGeneration: certificateGeneration }, 'POST');

            validation(true);
            return;
        }

        setIsLoading(true);
        const parsedValues: CertificateSubmitType = {
            templateId: selectedBackground,
            textBefore: values.textBefore,
            textAfter: values.textAfter,
            validUntil: values.validUntil,
            language: values.language,
        };

        if (titleList[values.name]) {
            parsedValues.titleId = values.name;
        } else {
            parsedValues.newTitle = values.name;
        }

        await coursesApiClient.request(`/api/v1/courses/campaigns/${campaignId}/certificates`, parsedValues, 'POST');

        validation(true);

        navigate();

        setIsLoading(false);
    };

    const navigate = () => {
        if (direction === 'next') {
            next(campaignId);
        } else if (direction === 'previous') {
            previous();
        } else if (direction === 'save') {
            history.push('/courses/manage-campaigns')
        }
    };

    const disableCertificate = () => {
        setCertificateGeneration(false);
        form.setFieldsValue({
            name: '',
            textBefore: '',
            textAfter: ''
        });
    };

    useEffect(() => {

        if (owner) {
            loadTitles()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [owner]);

    const loadTitles = async () => {
        let titleList = await coursesApiClient.request(`/api/v1/courses/campaigns/certificates/titles/${owner?.uuid}/${selectedLanguage}`, {}, 'GET');
        let titleListData: any = {};
        Object.entries(titleList.certificateTitles).map((value: any) => {
            return titleListData[value[1]['id']] = value[1]['name'];
        });
        setTitleList(titleListData);
    };

    const loadImage = async () => {
        let certificate1 = await coursesApiClient.request(`/api/v1/courses/campaigns/templates/1/background`, {}, 'GET', true, true, {}, 'arraybuffer');
        let certificate2 = await coursesApiClient.request(`/api/v1/courses/campaigns/templates/2/background`, {}, 'GET', true, true, {}, 'arraybuffer');
        let base64 = btoa(
            new Uint8Array(certificate1)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        setBackgroundImage1(base64);
        let base64_2 = btoa(
            new Uint8Array(certificate2)
                .reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        setBackgroundImage2(base64_2);
    };

    const previewCertificate = async () => {
        const values = form.getFieldsValue();

        const parsedValues: any = {
            data: values,
            previewLink: 'data:image/png;base64,' + (selectedBackground === 1 ? backgroundImage1 : backgroundImage2),
        };

        if (titleList[values.name]) {
            parsedValues.certificateName = values.name;
        } else {
            parsedValues.certificateName = values.name;
        }

        setCertificatePreviewData(parsedValues);

        setShowCertificatePreviewModal(true);
    };

    return (
        <>
            <Spinner spinning={loading}>
                <div className='campaign-header-text'>
                    <h1 className='text-30' >{intl.formatMessage({ id: 'campaign.please_set_up_award_certificate_for_your_campaign' })}</h1>
                </div>
                {
                    <Alert closable message={intl.formatMessage({ id: 'campaign.you_may_choose_diffrente_certificate_layout_alert' })} type='info' showIcon style={{ marginBottom: 24 }} />
                }
                <Form form={form} onFinish={submitForm} {...formItemLayout}>
                    <Form.Item label={intl.formatMessage({ id: 'campaign.enable_certificate_generation' })} name='certificateGeneration'>
                        <Radio.Group disabled={disableFields.generateCert}>
                            <Radio value={false} onClick={() => disableCertificate()}><FormattedMessage id='general.yes' /></Radio>
                            <Radio value={true} onClick={() => setCertificateGeneration(true)}><FormattedMessage id='general.no' /></Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label={intl.formatMessage({ id: 'general.language' })} name='language'>
                        <Radio.Group disabled={!certificateGeneration}>
                            {campaignLanguages.length ?
                                Object.entries(campaignLanguages).map((el: any) => {
                                    return (
                                        <>
                                            {el ?
                                                <Radio.Button disabled={disableFields.default} value={el[1]}>{el[1]}</Radio.Button>
                                                : null
                                            }
                                        </>
                                    )
                                })
                                : null
                            }
                        </Radio.Group>
                    </Form.Item>
                    {certificateGeneration &&
                        <Form.Item label={intl.formatMessage({ id: 'campaign.background_and_style' })}>
                            <div style={{ width: '600px' }}>
                                <div className={selectedBackground === 1 ? 'background-and-style-active' : 'background-and-style'} onClick={() => { setSelectedBackground(1); }}>
                                    <div style={{ width: '110px', height: '80px', backgroundImage: "url('data:image/png;base64," + backgroundImage1 + "')", backgroundSize: 'contain', margin: '10px', borderRadius: '8px' }} />
                                </div>
                                <div className={selectedBackground === 2 ? 'background-and-style-active' : 'background-and-style'} onClick={() => { setSelectedBackground(2); }}>
                                    <div style={{ width: '110px', height: '80px', backgroundImage: "url('data:image/png;base64," + backgroundImage2 + "')", backgroundSize: 'contain', margin: '10px', borderRadius: '8px' }} />
                                </div>
                            </div>
                        </Form.Item>
                    }
                    <Select
                        allowClear
                        showSearch
                        name='name'
                        disabled={disableFields.default}
                        customRules={[{ required: !!certificateGeneration, message: intl.formatMessage({ id: 'validation.field_required_for_language' }) }]}
                        label={intl.formatMessage({ id: 'campaign.certificate_title_name' })}
                        manualOptions={titleList}
                        integerKey={true}
                        onSearch={(element: any) => { setValidationName(element); setNewCertificateTitle(() => [...newCertificateTitle, element]); form.setFieldsValue({ name: newCertificateTitle[newCertificateTitle.length - 1] }); }}
                        onChange={(event: any) => { setValidationName(validationName + 1); setNewCertificateTitle([]); form.setFieldsValue({ name: event }); }}
                        filterOption={(input: string, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                    />
                    <Input label={intl.formatMessage({ id: 'campaign.text_before_the_students_name' })} name={'textBefore'} onChange={(event: any) => setValidationTextBefore(event.target.value)}
                        disabled={disableFields.default}
                        customRules={[{ required: !!certificateGeneration, message: intl.formatMessage({ id: 'validation.field_required' }) }]} />
                    <Input label={intl.formatMessage({ id: 'campaign.text_after_the_students_name' })} name={'textAfter'}
                        disabled={disableFields.default}
                        onChange={(event: any) => setValidationTextAfter(event.target.value)}
                        customRules={[{ required: !!certificateGeneration, message: intl.formatMessage({ id: 'validation.field_required' }) }]} />
                    <Form.Item label={intl.formatMessage({ id: 'campaign.validity_term_days' })} name={'validUntil'}>
                        <InputNumber min={1}
                            disabled={!certificateGeneration || campaign?.courseScenario === 'CERTIFICATION'}
                        />
                    </Form.Item>
                    <Form.Item label={<span>
                        {intl.formatMessage({ id: 'campaign.course_completion_e-mail_template' })}
                        <Tooltip title={intl.formatMessage({ id: 'campaign.certificate_template_edit_hint' })}>
                            <i className='fal fa-question-circle header-item' />
                        </Tooltip>
                    </span>}>
                        <Button
                            disabled={disableFields.buttons}
                            onClick={() => setShowEmailTemplateModal(true)}
                        >
                            <FormattedMessage id='general.edit' />
                        </Button>
                    </Form.Item>
                    <Form.Item label={' '} className='noColon'>
                        <Button
                            disabled={disableFields.buttons}
                            onClick={() => previewCertificate()}
                            className='preview-button'>
                            <i className='fal fa-save header-item' />
                            <FormattedMessage id='campaign.preview' />
                        </Button>
                    </Form.Item>
                </Form>
            </Spinner>
            <DefaultLayout.PageFooterWithRow
                left={
                    <>
                        {newCampaignStatus &&
                            <>
                                <Button loading={loading} onClick={() => { setDirection('previous'); form.submit(); }}>
                                    <FormattedMessage id='campaign.previous' />
                                </Button>
                                {phishing ?
                                    <Button loading={loading} onClick={() => { setDirection('next'); form.submit(); }}>
                                        <FormattedMessage id='general.next' />
                                    </Button>
                                    :
                                    null
                                }
                            </>
                        }
                    </>
                }
                right={
                    <>
                        {!newCampaignStatus &&
                            <Button onClick={() => { history.push('/courses/manage-campaigns'); }}>
                                <FormattedMessage id='general.back' />
                            </Button>
                        }
                        <Button loading={loading} onClick={() => { setDirection('save'); form.submit(); }} className='save-button'>
                            <i className='fal fa-save header-item' />
                            <FormattedMessage id={status === 'DRAFT' ? 'campaign.save_as_draft' : 'general.save'} />
                        </Button>
                        {!newCampaignStatus && launchButton(submitForm)}
                    </>
                }
            />

            <CertificateEditModal
                visible={showEmailTemplateModal}
                onCancel={() => setShowEmailTemplateModal(false)}
                owner={owner}
                templateUuid={certificate?.emailTemplateUuid}
            />

            <PreviewCertificateModal
                visible={showCertificatePreviewModal}
                onCancel={() => setShowCertificatePreviewModal(false)}
                certificatePreviewData={certificatePreviewData}
            />
        </>
    )
};
export default connect(mapStateToProps)(withRouter(CampaignCertificates));
