import React, { useState, useEffect } from 'react';

interface DayProps {
  form: any; // FormInstance
  buildCronExpression: VoidFunction;
  pickerValue: any;
  setPickerValue: any;
  dayValue: number;
}

const Day: React.FC<DayProps> = ({ form, buildCronExpression, pickerValue, setPickerValue, dayValue }) => {
  const [selected, setSelected] = useState(false);

  // If value gets cleared, selected state gets reset
  useEffect(() => {
    if (!pickerValue.length) {
      setSelected(false);
    }
  }, [pickerValue])

  const handleClick = () => {
    const _selected = !selected;
    setSelected(_selected);

    const value = _selected ? [...pickerValue, dayValue].sort((a, b) => a - b) : pickerValue.filter((day: number) => (day !== dayValue));
    setPickerValue(value);
    form.setFieldsValue({ everyMonthOn: value });

    buildCronExpression();
  }

  return (
    <td
      title={dayValue.toString()}
      className={`ant-picker-cell ant-picker-cell-in-view ${selected ? 'ant-picker-cell-selected' : ''}`}
      onClick={handleClick}
    >
      <div className="ant-picker-cell-inner">{dayValue}</div>
    </td>
  );
}

export default Day;