import React from 'react';
import FlagIcon from '../FlagIconFactory/index'
import './styles.scss';

interface FlagProps {
    languageCode: string;
}

const Flag: React.FC<FlagProps> = ({ languageCode }) => {
    const getFlag = (code: string) => {
        switch (code) {
            case 'LV':
                return <FlagIcon className='language-flags' code="lv" size="lg" />;
            case 'EN':
                return <FlagIcon className='language-flags' code="gb" size="lg" />;
            case 'LT':
                return <FlagIcon className='language-flags' code="lt" size="lg" />;
            case 'RU':
                return <FlagIcon className='language-flags' code="ru" size="lg" />;
            case 'SR':
            case 'BH':
            case 'RS':
                return <FlagIcon className='language-flags' code="rs" size="lg" />;
            case 'SQ':
                return <FlagIcon className='language-flags' code="al" size="lg" />;
            case 'BE':
                return <FlagIcon className='language-flags' code="by" size="lg" />;
            case 'BG':
                return <FlagIcon className='language-flags' code="bg" size="lg" />;
            case 'ZH':
                return <FlagIcon className='language-flags' code="cn" size="lg" />;
            case 'HR':
                return <FlagIcon className='language-flags' code="hr" size="lg" />;
            case 'CS':
                return <FlagIcon className='language-flags' code="cz" size="lg" />;
            case 'DA':
                return <FlagIcon className='language-flags' code="dk" size="lg" />;
            case 'NL':
                return <FlagIcon className='language-flags' code="nl" size="lg" />;
            case 'ET':
                return <FlagIcon className='language-flags' code="ee" size="lg" />;
            case 'FR':
                return <FlagIcon className='language-flags' code="fr" size="lg" />;
            case 'DE':
                return <FlagIcon className='language-flags' code="de" size="lg" />;
            case 'EL':
                return <FlagIcon className='language-flags' code="gr" size="lg" />;
            case 'HU':
                return <FlagIcon className='language-flags' code="hu" size="lg" />;
            case 'FI':
                return <FlagIcon className='language-flags' code="fi" size="lg" />;
            case 'IS':
                return <FlagIcon className='language-flags' code="is" size="lg" />;
            case 'ID':
                return <FlagIcon className='language-flags' code="id" size="lg" />;
            case 'JA':
                return <FlagIcon className='language-flags' code="jp" size="lg" />;
            case 'KO':
                return <FlagIcon className='language-flags' code="kr" size="lg" />;
            case 'MK':
                return <FlagIcon className='language-flags' code="mk" size="lg" />;
            case 'MS':
                return <FlagIcon className='language-flags' code="my" size="lg" />;
            case 'MT':
                return <FlagIcon className='language-flags' code="mt" size="lg" />;
            case 'NO':
                return <FlagIcon className='language-flags' code="no" size="lg" />;
            case 'PL':
                return <FlagIcon className='language-flags' code="pl" size="lg" />;
            case 'PT':
                return <FlagIcon className='language-flags' code="pt" size="lg" />;
            case 'RO':
                return <FlagIcon className='language-flags' code="ro" size="lg" />;
            case 'SK':
                return <FlagIcon className='language-flags' code="sk" size="lg" />;
            case 'SL':
                return <FlagIcon className='language-flags' code="si" size="lg" />;
            case 'ES':
                return <FlagIcon className='language-flags' code="es" size="lg" />;
            case 'SV':
                return <FlagIcon className='language-flags' code="se" size="lg" />;
            case 'TR':
                return <FlagIcon className='language-flags' code="tr" size="lg" />;
            case 'UK':
                return <FlagIcon className='language-flags' code="ua" size="lg" />;
            default:
                return <p>{code}</p>
        }
    };

    return getFlag(languageCode);
};

export default Flag;
