import React from 'react';
import { withRouter } from 'react-router-dom';
import ScenariosForm from 'components/ScenariosForm';
import DefaultLayout from 'components/DefaultLayout';
import { useIntl } from 'react-intl';



const EmailScenarioForm: React.FC<any> = (props) => {

  const intl = useIntl();
  const scenarioId = props.match.params.id;
  
  return (
    <>
      <DefaultLayout.PageLayout withStickyFooter>
        <DefaultLayout.PageHeader
          breadcrumb={[{name: intl.formatMessage({id: 'emailing.scenarios.e_mailing_center'}), path: '/e-mailing/email-scenarios'}]}
          title={intl.formatMessage({id: scenarioId ? 'emailing.scenarios.edit_mail_scenario' : 'emailing.scenarios.send_new_e_mail'})}
        />
        <ScenariosForm/>
      </DefaultLayout.PageLayout>
     
    </>
    )
}

export default withRouter(EmailScenarioForm);
