import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';
import {Form, Modal, message} from 'antd';
import { Select, CustomerSelect} from 'components/Form';
import {changeCampaignCustomerFilter} from 'store/actions/campaignCustomerFilter';
import {connect} from 'react-redux';
import coursesApiClient from 'utils/coursesApiClient';
import config from 'config/config';
import { changeCampaignData } from 'store/actions/campaignData';

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        campaignCustomerFilter: state.campaignCustomerFilter,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    changeCampaignCustomerFilter: (formData: any) => dispatch(changeCampaignCustomerFilter(formData)),
    changeCampaignData: (formData: any) => dispatch(changeCampaignData(formData))

});

const CampaignFilterModal = ({visible, onCancel, session, setCampaignList, setCampaignOriginalList, setLoading, campaignCustomerFilter, changeCampaignCustomerFilter, finished, changeCampaignData}: any) => {
    const [isCourseListLoading, setIsCourseListLoading] = useState(false);
    const [customerId, setCustomerId] = useState();
    const [customerUuid, setCustomerUuid] = useState();
    const [courseList, setCourseList] = useState([]);
    const isCustomerAdmin = session.active_user_type === 'CUSTOMER_ADMIN';

    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
        if (isCustomerAdmin) {
            loadCourses();
        }

        if (campaignCustomerFilter.customerUuid && !isCustomerAdmin) {
            form.setFieldsValue({
                customer: campaignCustomerFilter.customerId
            });
            setCustomerUuid(campaignCustomerFilter.customerUuid);
            setCustomerId(campaignCustomerFilter.customerId);
            submitForm({
                customer: campaignCustomerFilter.customerId,
                status: undefined,
                includedCourses: []
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    useEffect(() => {
        if (session.active_user_type !== 'SUPERVISION' && session.active_user_type !== 'CUSTOMER_ADMIN' && customerId) {
            loadCourses()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[customerId]);

    useEffect(() => {
        if ((session?.organization?.organization_type !== 'ORGANIZATION' && session?.organization?.organization_uuid) || customerUuid) {
            submitForm([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[finished])

    const loadCourses = async () => {
        setCourseList([]);
        form.setFieldsValue({ includedCourses: [] });
        try {
            setIsCourseListLoading(true);

            const data = isCustomerAdmin ? {} : { customerId };
            const response = await coursesApiClient.request('/api/v1/courses/courses/valid', data, 'POST');
            setCourseList(response.courses);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        } finally {
            setIsCourseListLoading(false);
        }
    };

    const submitForm = async (values: any) => {
        setLoading(true);
        try {
            const parsedValues = {
                status: values.status,
                includedCourses: values.includedCourses
            };

            if (session.active_user_type === 'SUPERVISION') {
                if (!values.status && finished) {
                    parsedValues.status = 'in:ONGOING'
                } else if (!values.status && !finished) {
                    parsedValues.status = 'in:ONGOING,FINISHED'
                }
            } else {
                if (!values.status && finished) {
                    parsedValues.status = 'in:DRAFT,ONGOING'
                } else if (!values.status && !finished) {
                    parsedValues.status = 'in:DRAFT,ONGOING,FINISHED'
                }
            }

            let uuid;
            if (session.active_user_type === 'CUSTOMER_ADMIN' || session.active_user_type === 'SUPERVISION' ) {
                uuid = session.organization.organization_uuid
            } else {
                uuid = customerUuid ? customerUuid : campaignCustomerFilter.customerUuid ? campaignCustomerFilter.customerUuid : false;
            }

            if (!uuid) {
                return message.error(intl.formatMessage({id: 'error.data_load'}));
            }
            onCancel();
            const response = await coursesApiClient.request(`/api/v1/courses/customer/${uuid}/filter-campaigns`, parsedValues, 'POST');

            changeCampaignData({customerId: customerId, customerUuid: customerUuid, cameFromCampaign: false});

            changeCampaignCustomerFilter({
                customerId: customerId,
                customerUuid: uuid
            });

            setCampaignList(response.campaigns);
            setCampaignOriginalList(response.campaigns);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title={intl.formatMessage({id: 'courses.campaign_filter'})}
            visible={visible}
            onCancel={() => onCancel(true)}
            okText={intl.formatMessage({id: 'general.filter'})}
            cancelText={intl.formatMessage({id: 'general.cancel'})}
            onOk={form.submit}
            forceRender={true}
        >
            <Form   form={form} onFinish={submitForm}>
                {session.active_user_type !== 'CUSTOMER_ADMIN' && session.active_user_type !== 'SUPERVISION' &&
                    <CustomerSelect
                        customLayout={formItemLayout}
                        showSearch
                        name='customer'
                        label={intl.formatMessage({id: 'general.customer'})}
                        url={config.api.routes.backend.organizationsCustomers}
                        integerKey={true}
                        customRules={[{ required: true, message: intl.formatMessage({id: 'validation.field_required'}) }]}
                        onChange={(value: any, option:any) => {
                            setCustomerId(value);
                            setCustomerUuid(option.uuid);
                        }}
                        isResponseArray={true}
                        dataKey='customers'
                        mapDataEntries={(el: any) => ({value: el.id, uuid:el.uuid, label: el.name })}
                        valueKey='value'
                        labelKey='label'
                        filterOption={(input: string, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                    />
                }
                <Select
                    allowClear
                    name='status'
                    customLayout={formItemLayout}
                    label={intl.formatMessage({ id: 'general.status' })}
                    url={config.api.routes.enums.campaignStatusTypes}
                />
                <Select
                    showArrow
                    showSearch
                    allowClear
                    isResponseArray={true}
                    integerKey={true}
                    name='includedCourses'
                    label={intl.formatMessage({id: 'campaign.included_courses'})}
                    valueKey='id'
                    labelKey='name'
                    manualOptions= {courseList}
                    loading={isCourseListLoading}
                    customLayout={formItemLayout}
                    mode='multiple'
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                />
            </Form>
        </Modal>
    )
};
export default connect(mapStateToProps, mapDispatchToProps)(CampaignFilterModal);
