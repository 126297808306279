import * as ActionTypes from 'store/actionTypes';

const defaultState = {
    owner: null
  }
  
  const FilterdOrganizationOptions = (state = defaultState, action: any) => {
    switch(action.type) {
      case ActionTypes.STORE_FILTERED_ORGANIZATION_OPTIONS:
          let currentState = state;
          currentState.owner = action.payload;
        return currentState;
      default:
        return state;
    }
  }
  

export default FilterdOrganizationOptions;
