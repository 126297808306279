import React from 'react';
import { Form, Upload, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

interface FileUploadInterface {
    name: string;
    layout?: {
        labelCol: {
            span: number
        }
        wrapperCol: {
            span: number
        }
    }
    label: string;
    buttonLabel: string;
    onUpload?: any
    multiple?: boolean;
}

const FileUpload: React.FC<FileUploadInterface> = ({ name, layout, label, buttonLabel, onUpload, multiple = false, ...props }) => {

    const intl = useIntl();

    const normFile = (e: any) => {
        if (onUpload) {
            onUpload();
        }
        let fileList = [...e.fileList];

        if (!multiple) {
            fileList = fileList.slice(-1);
        }

        if (Array.isArray(e)) {
            return e;
        }
        return e && fileList;
    };

    //default upload request prevention
    const customerDraggerRequest = ({ onSuccess }: any) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    }

    return (
        <Form.Item {...layout} name={name} label={intl.formatMessage({ id: label })} valuePropName="fileList" getValueFromEvent={normFile}>
            <Upload multiple={multiple} customRequest={customerDraggerRequest} {...props}>
                <Button>
                    <i className="fa fa-upload upload-scorm-icon" />  <FormattedMessage id={buttonLabel} />
                </Button>
            </Upload>
        </Form.Item>
    )
}
export default FileUpload;
