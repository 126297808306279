import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Space} from 'antd';
import {RemovingValuesType, UsersFilterBarInterface} from '../types';
import './styles.scss';

const UsersFilterBar: React.FC<UsersFilterBarInterface> = ({
                                           userRole,
                                           filter,
                                           setFilter,
                                           activeOrganizationType,
                                           activeOrganizationId,
                                           organizationResponse,
                                           customFilterResponse,
                                           organizationalUnitResponse,
                                           setUpdateFilterBar,
                                           campaign = false,
                                           form
                                       }) => {
    const [filterTags, setFilterTags] = useState<any>([]);
    const intl = useIntl();

    useEffect(() => {
        tagGenerator();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, activeOrganizationId]);

    const tagGenerator = async () => {

        let filterOptions: any = [];
        let organizationName;

        if (activeOrganizationId !== 0) {
            let customer = [];

            if (userRole !== 'CUSTOMER_ADMIN' && userRole !== 'SUPERVISION' && !campaign) {
                if (filter && filter.filter && filter.filter.options && organizationResponse.length > 0) {
                    organizationName = organizationResponse.filter((el: any) => el.value === activeOrganizationId);

                    customer.push([activeOrganizationType, organizationName[0].label, activeOrganizationId, 'customer']);

                    filterOptions.customer = customer;
                }
            }
        }

        if (filter?.filter?.options?.organizationalUnitId && Object.keys(organizationalUnitResponse).length > 0 && organizationalUnitResponse.constructor === Object) {
            let organizationalUnitArray: any = [];

            let orgUnitString = filter.filter.options.organizationalUnitId.split(':');

            if (orgUnitString[1].length > 0) {
                let orgUnitIdArray = orgUnitString[1].split(',');

                orgUnitIdArray.map((el: any) => {
                    return organizationalUnitArray.push(['OrgUnit', organizationalUnitResponse[el], parseInt(el), 'organizationalUnits']);
                });

                filterOptions.organizationalUnits = organizationalUnitArray;
            }
        }

        if (filter && filter.filter && filter.filter.customFields) {
            let customFieldsArray: any = [];
            Object.entries(filter.filter.customFields).map((customField: any)  => {
                if (customField[1] && customField[1].length) {
                    let customFieldName = customFilterResponse.filter((el: any) => el.id.toString() === customField[0]);

                    customField[1].map((customFieldValue: any) => {
                        return customFieldsArray.push([customFieldName[0].name, customFieldValue, customFieldName[0].id, 'customFields', customFieldName[0].slug])
                    })
                }

                return customFieldsArray;
            });

            filterOptions.customFields = customFieldsArray;
        }

        setFilterTags(filterOptions);
    };

    const filterClose = (elementId: number, elementTag: string, value: string = '') => {
        let filterValues = filterTags[elementTag];
        let removingValues: RemovingValuesType;
        let newFilter: any;

        let usersFilter: any = filter;

        if (value) {
            removingValues = filterValues.filter((el: any) => (el[3] === elementTag && el[2] === elementId && el[1] === value));
        } else {
            removingValues = filterValues.filter((el: any) => (el[3] === elementTag && el[2] === elementId));
        }

        if (elementTag === 'customFields') {
            let filterCustomFieldValues = usersFilter.filter.customFields[elementId.toString()];

            let customFilterValues = filterCustomFieldValues.filter((el: string) => el !== removingValues[0][1]);

            newFilter = filter.filter;

            form.setFieldsValue({
                [`customFilter_${removingValues[0][4]}_${removingValues[0][2]}`]: customFilterValues
            });

            newFilter.customFields[elementId.toString()] = customFilterValues;

            setFilter({filter: newFilter});
            setUpdateFilterBar(true);
        }

        if (elementTag === 'organizationalUnits') {
            let filterCustomFieldValues: any = usersFilter.filter.options.organizationalUnitId;

            let orgUnitString = filterCustomFieldValues.split(':');

            let orgUnitIdArray = orgUnitString[1].split(',');

            let customFilterValues: any = orgUnitIdArray.filter((el: string) => el !== removingValues[0][2].toString());

            newFilter = filter.filter;

            if (customFilterValues.length) {

                for (let i = 0; i < customFilterValues.length; i++) customFilterValues[i] =+ customFilterValues[i];

                form.setFieldsValue({
                    organizationalUnitId: customFilterValues
                });

                newFilter.options.organizationalUnitId = 'in:' + customFilterValues.toString();
            } else {
                form.setFieldsValue({
                    organizationalUnitId: []
                });
                delete newFilter.options['organizationalUnitId']
            }


            setFilter({filter: newFilter});
            setUpdateFilterBar(true);

        }

        if (elementTag === 'customer') {
            clearFilterBar();
        }
    };

    const clearFilterBar = () => {
        setFilter({});

        form.setFieldsValue({
            customer: [],
            organization: [],
            organizationalUnitId: []
        });

        customFilterResponse.map((el: any) => {
            form.setFieldsValue({
                [`customFilter_${el.slug}_${el.id}`]: []
            });

            return customFilterResponse;
        });

        setUpdateFilterBar('clear');
        setUpdateFilterBar(true);
    };

    return (
        <div className='filter-tag-container'>
            <Space>
            <span className='user-filter-bar-naming'>
                <FormattedMessage id='general.filtered_by'/>:
            </span>
                <div className='filter-tags'>
                    {filterTags.customer ?
                        <div className='customer-filter-tag'>
                            {filterTags.customer[0][1] ? `${intl.formatMessage({id: 'general.customer'})}: ${filterTags.customer[0][1]}` : null}
                            <i onClick={() => {
                                filterClose(filterTags.customer[0][2], filterTags.customer[0][3])
                            }} style={{paddingLeft: '5px', paddingRight: '3px', cursor: 'pointer'}}
                               className="fal fa-times">

                            </i>
                        </div>
                        : null
                    }
                    {filterTags.organizationalUnits ?
                        Object.values(filterTags.organizationalUnits).map((organizationalUnit: any) => {
                            let filterName = organizationalUnit[0];
                            let filterValue = organizationalUnit[1];
                            let filterId = organizationalUnit[2];
                            let filterTag = organizationalUnit[3];

                            return (
                                <div className='customer-filter-tag'>
                                    {filterName ? `${intl.formatMessage({id: 'users.filter_bar_unit'})}: ${filterValue}` : null}
                                    <i onClick={() => {
                                        filterClose(filterId, filterTag)
                                    }} style={{paddingLeft: '5px', paddingRight: '3px', cursor: 'pointer'}}
                                       className="fal fa-times">

                                    </i>
                                </div>
                            );
                        })
                        : null
                    }
                    {filterTags.customFields ?
                        Object.values(filterTags.customFields).map((customField: any) => {
                            let filterName = customField[0];
                            let filterValue = customField[1];
                            let filterId = customField[2];
                            let filterTag = customField[3];

                            return (
                                <div className='customer-filter-tag'>
                                    {filterName ? `${filterName}: ${filterValue}` : null}
                                    <i onClick={() => {
                                        filterClose(filterId, filterTag, filterValue)
                                    }} style={{paddingLeft: '5px', paddingRight: '3px', cursor: 'pointer'}}
                                       className="fal fa-times">

                                    </i>
                                </div>
                            );
                        })
                        : null
                    }
                </div>
                <button
                    className='clear-all-button'
                    onClick={() => {
                        clearFilterBar()
                    }}
                    style={{whiteSpace: 'nowrap'}}
                >
                    <FormattedMessage id="users.clear_all"/>
                </button>
            </Space>
        </div>
    )
};

export default UsersFilterBar;
