import React, { useState, useEffect } from 'react';
import DefaultLayout from 'components/DefaultLayout';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import {Table, message, Popover, Modal, Form, Radio, Button} from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import config from 'config/config';
import apiClient from 'utils/apiClient';
import { CustomerSelect } from 'components/Form';
import { changeMailTemplateFilterOptions } from 'store/actions/saveMailTemplateFilterOptions';
import { changeMailTemplateCustomerOptions } from 'store/actions/saveMailTemplateCustomerOptions';

const mapStateToProps = (state: any) => {
  return {
    session: state.session,
    mailTemplateFilterOptions: state.mailTemplateFilterOptions,
    mailTemplateCustomerOptions: state.mailTemplateCustomerOptions,
    locale: state.locale.locale
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  changeMailTemplateFilterOptions: (mailTemplateFilterOptions: any) => dispatch(changeMailTemplateFilterOptions(mailTemplateFilterOptions)),
  changeMailTemplateCustomerOptions: (mailTemplateCustomerOptions: any) => dispatch(changeMailTemplateCustomerOptions(mailTemplateCustomerOptions))
});

const CustomerTemplates: React.FC<any> = ({
  history,
  session,
  mailTemplateCustomerOptions,
  changeMailTemplateCustomerOptions,
  changeMailTemplateFilterOptions,
  setLanguageEditRecord,
  setSelecteTemplate,
  setSelectedLanguageKey,
  setShowEditModal,
  locale,
  setTemplateSubject
}:any) => {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [visibleTooltip, setVisibleTooltip] = useState<number|null>(null);
  const [showOrganizationModal, setShowOrganizationModal] = useState(false);
  const [showTemplateOrganizations, setShowTemplateOrganizations] = useState(false);
  const [templatesOwner, setTemplatesOwner] = useState<number|null>(null);

  const intl = useIntl();
  const [form] = Form.useForm();

  useEffect(() => {
    loadStandardTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  const loadStandardTemplates = async () => {
        setIsLoading(true);
        try {
            if (session.active_user_type === 'SUPER_ADMIN') {
                if (mailTemplateCustomerOptions.owner && mailTemplateCustomerOptions.owner.customerType === "CUSTOMER") {
                    let templatesResponse = await apiClient.request(`/api/v1/customers/${mailTemplateCustomerOptions.owner.ownerId}/mail-templates`, {}, 'GET');
                    setTemplatesOwner(mailTemplateCustomerOptions.owner.ownerId);
                    setTemplates(templatesResponse.mailTemplates);
                }
            } else {
                switch (session.organization.organization_type) {
                    case 'ORGANIZATION':
                        if (!templatesOwner) {
                            setShowOrganizationModal(true);
                        } else {
                            let templatesResponse = await apiClient.request(`/api/v1/customers/${templatesOwner}/mail-templates`, {}, 'GET');
                            setTemplates(templatesResponse.mailTemplates);
                        }
                        break;
                    case 'CUSTOMER':
                        let templatesResponse = await apiClient.request(`/api/v1/customers/${session.organization.organization_id}/mail-templates`, {}, 'GET');
                        setTemplatesOwner(session.organization.organization_id);
                        setTemplates(templatesResponse.mailTemplates);
                        break;
                }
            }
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({id: "error.data_load"}));
        } finally {
            setIsLoading(false);
        }
    };

  const handleVisibleChange = (visible: boolean, recordId: number) => {
    setVisibleTooltip(visible ? recordId : null);
  };

  const columns = [
    {
      title: intl.formatMessage({id: 'emailing.templates.template_name'}),
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => {
        return (
          <NavLink to={`/e-mailing/manage-templates/customer/${record.customer_id}/${record.uuid}/edit`}>
            {record.name[locale] ? record.name[locale] : record.name['EN'] ? record.name['EN'] : Object.values(record.name)[0]}</NavLink>
        )
      }
    },
    {
      title: intl.formatMessage({id: 'emailing.templates.template_type'}),
      dataIndex: 'templateType',
      key: 'templateType',
      render: (text: string, record: any) => intl.formatMessage({id: `email_templates.template_type.${record.template_type}`})
    },
    {
      title: intl.formatMessage({id: 'emailing.templates.template_category'}),
      dataIndex: 'categoryCode',
      key: 'categoryCode',
      render: (text: string, record: any) => intl.formatMessage({id: `email_templates.categories.${record.category_code}`})
    },
    {
      title: intl.formatMessage({id: 'general.language'}),
      dataIndex: 'language',
      key: 'language',
      render: (text: string, record: any) => {

        let html;
        if (record.html_template) {
          html = record.html_template
        } else {
          html = record.html
        }

        return (
          <div>
            {html ?
              <Radio.Group>
              {
                Object.entries(html).map((el:any, index:any)=>{
                  return (
                    <>
                      {el ?
                        <Radio.Button style={{ textTransform: 'uppercase'}} value={el[0]} onClick={() => {
                          if (record.html_template) {
                            setLanguageEditRecord(record.html_template[el[0]]);
                          } else {
                            setLanguageEditRecord(record.html[el[0]]);
                          }
                          setTemplateSubject(record.subject[el[0]])
                          setSelecteTemplate(record);
                          setSelectedLanguageKey(el[0]);
                          setShowEditModal(true)}}
                        >
                          {el[0]}
                        </Radio.Button>
                        : null
                      }
                    </>
                )
              })
            }
            </Radio.Group>
            : null
            }
          </div>

        )
      }

    },
    {
      key: 'actions',
      render: (text: string, record: any) => {

        const deleteButton = (
          <div onClick={() => {confirmTemplateDeletion(record.uuid); setVisibleTooltip(null)}} className="popover-item">
              <FormattedMessage id="general.delete"/>
          </div>
        );

        const content = <>
          <NavLink to={`/e-mailing/manage-templates/customer/${record.customer_id}/${record.uuid}/edit`}>
            <div className="popover-item">
              <FormattedMessage id="general.edit"/>
            </div>
          </NavLink>
          {record.template_type === 'CUSTOM' && session.active_user_type === 'CUSTOMER_ADMIN' &&
            deleteButton
          }
          {record.category_code !== 'system_events' && session.active_user_type === 'DISTRIBUTOR_ADMIN' &&
            deleteButton
          }
          {session.active_user_type === 'SUPER_ADMIN' &&
            deleteButton
          }
        </>;

        return (
            <Popover
              visible={record.id === visibleTooltip}
              content={content}
              trigger="click"
              placement="bottomRight"
              arrowPointAtCenter
              onVisibleChange={(visible) => handleVisibleChange(visible, record.id)}
            >
              <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
              </div>
            </Popover>
        )
      },
    }
  ];

  const confirmTemplateDeletion = (uuid: string) => {
      Modal.confirm({
          title: intl.formatMessage({id: 'general.attention'}),
          icon: <ExclamationCircleOutlined />,
          content: intl.formatMessage({id: 'emailing.templates.begin_deletion'}),
          okText: intl.formatMessage({id: 'general.delete'}),
          okType: 'danger',
          cancelText: intl.formatMessage({id: 'general.cancel'}),
          onOk() {
              deleteTemplate(uuid);
          }
      })
  };

  const deleteTemplate = async (uuid: string) => {
      try {
          await apiClient.request(`/api/v1/mail-templates/customers/${uuid}/delete`, {}, 'DELETE');
          loadStandardTemplates();
      } catch (error: any) {
          message.error(error);
          console.error(error);
      }
  };


  const selectOwner = async (value: any) => {
    if (showTemplateOrganizations) {
      history.push(`/e-mailing/manage-templates/customer/${templatesOwner}/add`)
      setShowTemplateOrganizations(false);
    }
    try {
      setShowOrganizationModal(false);
      setIsLoading(true);

      const templatesResponse = await apiClient.request(`/api/v1/customers/${value.customer}/mail-templates`, {}, 'GET');
      setTemplates(templatesResponse.mailTemplates);

      const ownerId = value.customer;

      const selectedOrganization = {
        ownerId,
        customerType: 'CUSTOMER'
      };

      changeMailTemplateFilterOptions({owner:selectedOrganization});
      changeMailTemplateCustomerOptions({owner:selectedOrganization});
    } catch (error: any) {
      message.error(error);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const checkOwner = () => {
    if (session.active_user_type !== 'CUSTOMER_ADMIN' ) {
      setShowOrganizationModal(true);
      setShowTemplateOrganizations(true)
    } else {
      history.push(`/e-mailing/manage-templates/customer/${templatesOwner}/add`)
    }
  }

  return (
    <DefaultLayout.PageContent style={{borderTopLeftRadius: 0}}>
      <Button loading={session.active_user_type !== 'CUSTOMER_ADMIN' ? false : !templatesOwner} type="primary" onClick={checkOwner}>
        <PlusOutlined/> <FormattedMessage id="general.add" />
      </Button>
      <Button
        type="primary"
        style={{ marginLeft: 10, display: session.active_user_type === 'CUSTOMER_ADMIN' ? 'none' : '' }}
        onClick={() => {setShowOrganizationModal(true)}}
      >
        <FormattedMessage id="general.filter" />
      </Button>
      <Table
        locale={{ emptyText: intl.formatMessage({id: "general.found_no_data"}) }}
        style={{ margin: 0, marginTop: 15 }}
        columns={columns}
        loading={isLoading}
        dataSource={templates}
        size="middle"
        scroll={{ x: 800 }}
      />
      <Modal
        className="user-list-modal"
        visible={showOrganizationModal}
        title={intl.formatMessage({id: 'emailing.templates.select_organization'})}
        onOk={()=>  form.submit()}
        onCancel={() => setShowOrganizationModal(false)}
        okText={intl.formatMessage({id: 'general.select'})}
        cancelText={intl.formatMessage({id: 'general.cancel'})}
      >
        <Form form={form} onFinish={selectOwner}>
          <CustomerSelect
            showSearch
            name="customer"
            label={intl.formatMessage({id: "organization.organization_name"})}
            url={config.api.routes.backend.organizationsCustomers}
            integerKey={true}
            customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
            onChange={(value: any) => {setTemplatesOwner(value);}}
            isResponseArray={true}
            dataKey="customers"
            mapDataEntries={(el: any) => ({value: el.id, label: el.name})}
            valueKey="value"
            labelKey="label"
            customLayout={true}
            filterOption={(input: string, option: any) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          />
        </Form>
      </Modal>
    </DefaultLayout.PageContent>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(CustomerTemplates));

