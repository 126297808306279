import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { message, Modal, Transfer, Form } from 'antd';
import { Select } from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import { OwnerType } from '../types';
import { connect } from 'react-redux';

import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    }
}

interface CampaignActivitiesModalInterface {
    visible: boolean;
    onCancel: any;
    owner: OwnerType | undefined;
    setCampaignActivities: React.Dispatch<React.SetStateAction<any>>;
    locale?: string;
    setMinutes: React.Dispatch<React.SetStateAction<number>>;
    campaignId: number;
    campaignCourses: any;
    setCustomCourse: React.Dispatch<React.SetStateAction<any>>;
    setPhishingCampaign: React.Dispatch<React.SetStateAction<boolean>>;
    campaignType: string;
    setLoadCourses: React.Dispatch<React.SetStateAction<boolean>>;
    campaign: any
}

const CampaignActivitiesModal: React.FC<CampaignActivitiesModalInterface> = ({
    visible, onCancel, owner,
    setCampaignActivities,
    locale,
    setMinutes,
    campaignId,
    campaignCourses,
    setCustomCourse,
    setPhishingCampaign,
    campaignType,
    setLoadCourses,
    campaign
}) => {
    const [customerCourses, setCustomerCourses] = useState([]);
    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [courses, setCourses] = useState<any>([]);
    const [allCourses, setAllCourses] = useState<any>();


    const [activities, setActivities] = useState([]);

    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
        if (owner) {
            loadCourses();
            loadCampaignActivities();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [owner]);

    const loadCourses = async () => {
        const customerId = owner?.id;

        const data = { customerId, campaignId };

        const response = await coursesApiClient.request('/api/v1/courses/courses/valid', data, 'POST');
        const customerCourses = response.courses;
        setAllCourses(customerCourses);
        let customCourse = customerCourses.filter((course: any) => course.campaign_course === campaignId);

        setCustomCourse(customCourse)
        let selectCourseValues: any = {};

        Object.values(customerCourses).map((course: any) => {
            return selectCourseValues[course.id] = course.name;
        });

        setCustomerCourses(selectCourseValues);
        onCancel(false);
    };

    useEffect(() => {

        if (allCourses && courses) {
            setPhishingStep();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courses, allCourses]);

    const setPhishingStep = () => {
        if (campaign && campaign.status === 'DRAFT') {
            let courseArray = courses.map(String);
            let phishing = false;
            if (campaignType !== 'CERTIFICATION') {
                return Object.values(allCourses).map((course: any) => {
                    if (courseArray.includes((course.id).toString())) {
                        if (!phishing && course.smart_dephish_enabled) {
                            phishing = true;
                        }
                    }
                    return null;
                });
            }

            setPhishingCampaign(phishing);
        }
    }

    const handleChange = (keys: any) => {
        setTargetKeys(keys);
    }

    const selectChange = (sourceSelectedKeys: any) => {
        setSelectedKeys(sourceSelectedKeys);
    }

    const parseActivities = (activities: any) => {
        let collectedActivities: any = [];

        if (!activities) return [];

        Object.values(activities).map((course: any) => {
            Object.values(course).map((activity: any) => {
                if (activity.status === 'ONLINE') {
                    const title = JSON.parse(activity.title);
                    collectedActivities.push({
                        key: activity.id,
                        titles: title[locale ?? 'EN'] ? title[locale ?? 'EN'] : title[Object.keys(title)[0]],
                    });
                }
                return collectedActivities;
            })
            return collectedActivities;
        })

        return collectedActivities;
    }

    const loadCourseLearningActivities = (courseId: string) => {
        let ids: string[] = courses;

        if (courseId) {
            ids = ids.concat(courseId);
            setCourses((courses: any) => [...courses, courseId])
        }

        const url = `/api/v1/courses/campaigns/${ids.join()}/learning-activities/valid`;

        if (ids.length) {
            loadActivities(url);
        } else {
            setActivities([]);
        }
    };

    const loadActivities = async (url: string) => {
        setLoadCourses(true);
        try {
            const response = await coursesApiClient.request(url, { customerId: owner?.id, campaignId }, 'POST');
            setActivities(response.activities);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: "launch_error" }));
        } finally {
            setLoadCourses(false);
        }
    }

    const handleDeselect = (courseId: string) => {
        const newCourses = courses.filter((id: string) => id !== courseId);
        setCourses(newCourses);

        if (newCourses.length) {
            const url = `/api/v1/courses/campaigns/${newCourses.join()}/learning-activities/valid`;
            loadActivities(url);
        } else {
            setActivities([]);
        }
    }

    const loadCampaignActivities = async () => {
        let response = await coursesApiClient.request(`/api/v1/courses/campaigns/${campaignId}/activities`, [], 'GET');
        const responseActivities = response.activities;

        let collectedActivities: any = [];

        Object.values(responseActivities.activities).map((activity: any) => {
            collectedActivities.push(activity.id)
            return collectedActivities;
        })
        setTargetKeys(collectedActivities);
        setMinutes(responseActivities.totalMinutes);
        setCampaignActivities(responseActivities.activities);
    };

    const onlyUnique = (value: any, index: any, self: any) => {
        return self.indexOf(value) === index;
    }

    useEffect(() => {
        if (campaignCourses?.length && owner) {

            let array = campaignCourses?.map(String);

            let unique = array.filter(onlyUnique);

            setCourses(unique);
            const url = `/api/v1/courses/campaigns/${unique.join()}/learning-activities/valid`;
            loadActivities(url);

            form.setFieldsValue({ includedCourses: unique });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignCourses, owner]);

    const submitForm = () => {
        onCancel();
        let campaignActivities: any = [];
        let minutes = 0;

        if (targetKeys.length) {
            let i = 0;

            Object.values(activities).map((course: any) => {
                Object.values(course).map((activity: any) => {
                    if (targetKeys.includes(activity.id)) {
                        activity['availableLanguages'] = Object.keys(activity.name_translations);
                        activity['index'] = i;
                        activity['mandatory'] = true;
                        activity['forcePretesting'] = false;

                        campaignActivities.push(activity);
                        minutes += activity.minutes;

                        i += 1;
                    }
                    return campaignActivities;
                })
                return campaignActivities;
            })
        }

        setCampaignActivities(campaignActivities);
        setMinutes(minutes);
    }

    return (
        <Modal
            width='650px'
            visible={visible}
            onCancel={onCancel}
            onOk={form.submit}
            title={intl.formatMessage({ id: 'courses.select_activity' })}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
            okText={intl.formatMessage({ id: 'general.add' })}
            className='campaign-activities'
        >
            <Form form={form} onFinish={submitForm}>
                <Select
                    showArrow
                    showSearch
                    name='includedCourses'
                    label={intl.formatMessage({ id: 'campaign.included_courses' })}
                    valueKey='value'
                    labelKey='label'
                    manualOptions={customerCourses}
                    onSelect={(courseId: string) => loadCourseLearningActivities(courseId)}
                    onDeselect={(courseId: string) => handleDeselect(courseId)}
                    customLayout={true}
                    mode='multiple'
                />
                <Transfer
                    locale={{ notFoundContent: intl.formatMessage({ id: 'general.found_no_data' }), selectInvert: intl.formatMessage({ id: 'campaign.invert_values' }), selectAll: intl.formatMessage({ id: 'campaign.select_all_values' }), removeAll: intl.formatMessage({ id: 'campaign.remove_all_values' }) }}
                    dataSource={parseActivities(activities)}
                    targetKeys={targetKeys}
                    selectedKeys={selectedKeys}
                    onSelectChange={(e) => selectChange(e)}
                    onChange={(e) => handleChange(e)}
                    render={(item: any) => item.titles}
                    oneWay
                    showSelectAll={false}
                />
            </Form>
        </Modal >
    );
}

export default connect(mapStateToProps)(CampaignActivitiesModal);
