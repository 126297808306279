import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import {HorizontalStackedBarChart} from 'components/Charts/HorizontalStackedBarChart';
import coursesApiClient from 'utils/coursesApiClient';

interface OrganizationalUnitChartProps {
  campaignId: number,
}

const OrganizationalUnitChart: React.FC<OrganizationalUnitChartProps> = ({ campaignId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  const intl = useIntl();

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const response = await coursesApiClient.request(`/api/v1/courses/statistic/campaign/${campaignId}/progress-by-org-unit`);

        const data = response.orgUnits;

        const parsedData = {
          labels: Object.keys(data),
          datasets: [
            {
              label: intl.formatMessage({ id: 'general.not_started' }),
              backgroundColor: 'rgb(247, 216, 0)',
              hoverBackgroundColor: 'rgb(227, 196, 0)',
              data: Object.values(data).map((value: any) => value.notStarted),
            },
            {
              label: intl.formatMessage({ id: 'general.in_progress' }),
              backgroundColor: 'rgb(126, 162, 223)',
              hoverBackgroundColor: 'rgb(106, 142, 203)',
              data: Object.values(data).map((value: any) => value.inProgress),
            },
            {
              label: intl.formatMessage({ id: 'general.completed' }),
              backgroundColor: 'rgb(33, 208, 160)',
              hoverBackgroundColor: 'rgb(13, 188, 140)',
              data: Object.values(data).map((value: any) => value.completed),
            },
          ],
        };

        setData(parsedData);
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, [intl, campaignId]);

  return (
    <HorizontalStackedBarChart
      data={data}
      title={intl.formatMessage({ id: 'campaign.learning_progress_by_org_unit' })}
      loading={isLoading}
    />
  );
}

export default OrganizationalUnitChart;