import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { Row, Col, Card, Alert } from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import TotalTimeSpentChart from './Charts/TotalTimeSpentChart';
import ActiveStudentsChart from './Charts/ActiveStudentsChart';
import ObjectiveProgressChart from './Charts/ObjectiveProgressChart';
import CampaignTable from './Charts/CampaignTable';
import ActivityLineChart from './Charts/ActivityLineChart';
import PhishingSummaryChart from './Charts/Phishing/PhishingSummaryChart';
import PhishingResultChart from './Charts/Phishing/PhishingResultChart';
import DashboardStatisticsExport from 'components/Dashboard/DashboardStatisticsExport';
import Alerts from 'components/Dashboard/Alerts';

const mapStateToProps = (state: any) => ({
  organization: state.session.organization,
  session: state.session,
  envName: state.appearanceSettings.title
});

interface DashboardProps {
  organization: undefined | {
    organization_type: string;
    organization_uuid: string;
    organization_id: string;
  };
}

const Dashboard: React.FC<DashboardProps> = ({ organization, session, envName }: any) => {
  const intl = useIntl();
  const [customerUuid, setCustomerUuid] = useState<any>();

  useEffect(() => {
    if (session.active_user_type === 'CUSTOMER_ADMIN') {
        setCustomerUuid(organization!.organization_uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[customerUuid]);

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageHeader title={intl.formatMessage({ id: 'dashboard.dashboard' })} />
        <>
        {session.active_user_type !== 'STUDENT' &&
        <DefaultLayout.PageContent>
        <Alerts
          organization={organization}
          activeUserType={session.active_user_type}
          envName={envName}
          intl={intl}
        />
        <DashboardStatisticsExport setCustomerUuid={setCustomerUuid} customerUuid={customerUuid} />
        {customerUuid ?
          <>
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Card size="small">
                <TotalTimeSpentChart customerUuid={customerUuid} />
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small">
                <ActiveStudentsChart customerUuid={customerUuid} />
              </Card>
            </Col>
            <Col span={8}>
              <Card size="small">
                <ObjectiveProgressChart customerUuid={customerUuid} />
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card size="small">
                <CampaignTable customerUuid={customerUuid} />
              </Card>
            </Col>
            <Col span={12}>
              <Card size="small">
                <ActivityLineChart customerUuid={customerUuid} />
              </Card>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Card size="small">
                <PhishingSummaryChart customerUuid={customerUuid} />
              </Card>
            </Col>
            <Col span={12}>
              <Card size="small">
                <PhishingResultChart customerUuid={customerUuid} />
              </Card>
            </Col>
          </Row>
          </>
            :
            <Alert style={{marginTop: 8}} message={intl.formatMessage({id: "dashboard.select_customer_alert"})} type="info"/>
        }
        </DefaultLayout.PageContent>
        }
        </>
    </DefaultLayout.PageLayout>
  );
};

export default connect(mapStateToProps)(Dashboard);
