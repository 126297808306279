import React from 'react';
import { useIntl } from 'react-intl';
import { Button, message, Space, Tag, Form } from 'antd';
import FormattedMessage from 'react-intl/lib/components/message';
import coursesApiClient from 'utils/coursesApiClient';
import moment from 'moment';
import { connect } from 'react-redux';
import DefaultLayout from 'components/DefaultLayout';
import AssignmentForm from './AssignmentForm';
import './styles.scss';

interface MaterialsInterface {
    activityFormData: any;
    session?: any;
    clear: Function;
    setActivityFormData: React.Dispatch<React.SetStateAction<any>>;
}

const mapStateToProps = (state: any) => ({
    session: state.session,
});

const Materials: React.FC<MaterialsInterface> = ({ session, clear, activityFormData, setActivityFormData }) => {
    const adminRoles = ['RESELLER_ADMIN', 'SUPER_ADMIN', 'DISTRIBUTOR_ADMIN', 'CUSTOMER_ADMIN', 'SUPERVISION'];

    const assignmentSettings = JSON.parse(activityFormData.materials.assignments.settings);
    const assignment = activityFormData.materials.assignments;
    const enrollment = activityFormData.enrollment;
    const attempt = activityFormData.activityAttempt;
    const attemptAssignmentProperties = activityFormData.activityAttempt.assignment_properties ? JSON.parse(activityFormData.activityAttempt.assignment_properties) : false;

    const intl = useIntl();
    const [form] = Form.useForm();

    /**
     * 
     * @param submit 
     */
    const submitForm = async (submit: boolean) => {
        const values = form.getFieldsValue();
        const parsedValues = {
            submit: submit,
            comment: values.comment,
            examination: values.examination,
            rating: values.rating,
            assignmentId: assignment.id,
        };

        try {
            const response = await coursesApiClient.request(`api/v1/courses/student/${activityFormData.enrollment.id}/submit-evaluation`, parsedValues, 'POST');
            setActivityFormData({ ...activityFormData, activityAttempt: response.activityAttempt })

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }
    }

    /**
     * 
     * @param format 
     * @returns 
     */
    const getAssignmentDeadline = (format: 'element' | 'date' = 'element') => {
        if (enrollment && assignmentSettings) {

            let date: any

            if (assignmentSettings?.deadline) {
                date = moment(enrollment.enroll_date);
                date.add(assignmentSettings.deadline, 'days').toString();
            } else {
                date = moment(enrollment.enroll_date);
            }

            let deadline: any = moment(date).format('YYYY-MM-DD 00:00:00')

            if (format === 'element') {
                return formDate(deadline);
            } else if (format === 'date') {
                return deadline
            }
        }
    }

    /**
    * 
    * @param dateString 
    * @returns 
    */
    const formDate = (dateString: string) => {
        const date = moment(dateString).format('DD ');
        const time = moment(dateString).format(' YYYY HH:mm:ss');
        return <span>{date}{getMonth(parseInt(moment(dateString).format('M')))}{time}</span>
    }

    /**
     * 
     * @param monthNumber 
     * @returns 
     */
    const getMonth = (monthNumber: number) => {
        const month = [
            <FormattedMessage id='general.january' />,
            <FormattedMessage id='general.february' />,
            <FormattedMessage id='general.march' />,
            <FormattedMessage id='general.april' />,
            <FormattedMessage id='general.may' />,
            <FormattedMessage id='general.june' />,
            <FormattedMessage id='general.july' />,
            <FormattedMessage id='general.august' />,
            <FormattedMessage id='general.september' />,
            <FormattedMessage id='general.october' />,
            <FormattedMessage id='general.november' />,
            <FormattedMessage id='general.december' />
        ];

        return month[monthNumber];
    }

    /**
     * 
     * @param statusType 
     * @returns 
     */
    const getStatusTag = (statusType: 'VALUE' | 'TAG' = 'TAG') => {
        let status: string = 'NOT_STARTED'
        let color = ''
        let messageId = ''
        let assignmentSubmission;
        let assignmentEvaluation;

        if (attemptAssignmentProperties && attemptAssignmentProperties[assignment.id]) {

            assignmentSubmission = attemptAssignmentProperties[assignment.id].submission;

            if (attemptAssignmentProperties[assignment.id].evaluation) {
                assignmentEvaluation = attemptAssignmentProperties[assignment.id].evaluation;
            }
        }

        if (attempt.status === 'NOT_STARTED' || attempt.status === 'IN_PROGRESS') {
            status = 'NOT_SUBMITTED'
        }

        if (attempt.status === 'IN_PROGRESS' && assignmentSubmission) {
            status = 'SUBMITTED'
        }

        if (attempt.status === 'IN_PROGRESS' && assignmentEvaluation && !assignmentEvaluation['submitted']) {
            status = 'EVALUATION'
        } else if (attempt.status === 'IN_PROGRESS' && assignmentSubmission && assignmentSubmission['submitted']) {
            status = 'EVALUATION'
        }

        if (attempt.status === 'IN_PROGRESS' && assignmentEvaluation && assignmentEvaluation['submitted']) {
            status = 'VALUED'
        }

        if (moment().startOf('day') >= moment(getAssignmentDeadline('date')) && assignmentSubmission && !assignmentSubmission['submitted']) {
            status = 'MISSED_DEADLINE'
        }

        if (attempt.status === 'COMPLETED') {
            status = 'VALUED'
        }

        if (assignmentSubmission && assignmentSettings.evaluation_type === 'NO_EVALUATION') {
            status = 'SUBMITTED'
        }

        switch (status) {
            case 'NOT_SUBMITTED':
                color = 'gold'
                messageId = 'courses.topic_status.not_submitted'
                break;
            case 'SUBMITTED':
                color = 'blue'
                messageId = 'courses.topic_status.submitted'
                break;
            case 'EVALUATION':
                color = 'purple'
                messageId = 'courses.topic_status.evaluation'
                break;
            case 'VALUED':
                color = 'green'
                messageId = 'courses.topic_status.valued'
                break;
            case 'MISSED_DEADLINE':
                color = 'red'
                messageId = 'courses.topic_status.missed_deadline'
                break;
        }

        if (statusType === 'TAG') {
            return (
                <Tag color={color}>
                    <FormattedMessage id={messageId} />
                </Tag>
            )
        } else if (statusType === 'VALUE') {
            return status;
        }
    }

    return (
        <>
            {assignmentSettings &&
                <AssignmentForm
                    activityFormData={activityFormData}
                    form={form}
                    getStatusTag={getStatusTag}
                    getAssignmentDeadline={getAssignmentDeadline}
                    formDate={formDate}
                    setActivityFormData={setActivityFormData}
                />
            }
            {adminRoles.includes(session.active_user_type) &&
                <DefaultLayout.PageFooterWithRow
                    right={
                        <>
                            {adminRoles.includes(session.active_user_type) &&
                                <>
                                    <Button onClick={() => clear()}>
                                        <FormattedMessage id='general.cancel' />
                                    </Button>
                                    {assignment && attempt && !(getStatusTag('VALUE') === 'NOT_SUBMITTED' || getStatusTag('VALUE') === 'MISSED_DEADLINE') ?
                                        <Space>
                                            <Button style={{ width: 160 }} className='save-button' onClick={() => submitForm(false)}>
                                                <FormattedMessage id='general.save' />
                                            </Button>
                                            {getStatusTag('VALUE') === 'SUBMITTED' || getStatusTag('VALUE') === 'EVALUATION' ?
                                                <Button style={{ width: 160 }} type='primary' onClick={() => submitForm(true)}>
                                                    <FormattedMessage id='courses.submit_evaluation' />
                                                </Button>
                                                : null
                                            }
                                        </Space>
                                        : null
                                    }
                                </>
                            }
                        </>
                    }
                />
            }
        </>
    )
}
export default connect(mapStateToProps)(Materials);