import * as ActionTypes from 'store/actionTypes';

export const setSession = (session: any) => ({
  type: ActionTypes.SET_SESSION,
  payload: session
});

export const clearSession = () => ({
  type: ActionTypes.CLEAR_SESSION,
  payload: {}
});

export const toggleSessionLoading = () => ({
  type: ActionTypes.TOGGLE_SESSION_LOADING,
  payload: {}
});


export const setSessionSelectedOrganization = (organization:any) => ({
  type: ActionTypes.SET_SESSION_SELECTED_ORGANIZATION,
  payload: organization
});