import React from 'react';
import FlagIcon from 'components/Flags/FlagIconFactory/index'

interface FlagProps {
    languageCode: string;
}

const Flag: React.FC<FlagProps> = ({ languageCode }) => {
    const getFlag = (code: string) => {
        return <FlagIcon className='language-flags' code={code.toLowerCase()} size="lg" />;
    };

    return getFlag(languageCode);
};

export default Flag;
