import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import apiClient from 'utils/apiClient';
import { Select } from 'components/Form';
import { message } from 'antd';

const LanguageSelect: React.FC<any> = ({ name, label, published = true, disabled = false, ...props}) => {
  const [selectLanguageListName, setSelectLanguageListName] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const intl = useIntl();
  useEffect(() => {
    loadLanguages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);


  const loadLanguages = async () => {
    setIsLoading(true);

    try {
      let languages = await apiClient.request('/api/v1/language', {}, 'GET');
      let languageListNameForSelect: any = {};
      Object.values(languages.languages).map((value: any) => {

        if (!published) {
          languageListNameForSelect[value['code']] = value['name']
        }

        if (value['publish'] && published) {
          languageListNameForSelect[value['code']] = value['name']
        }
        return languageListNameForSelect;
      });
      setSelectLanguageListName(languageListNameForSelect);
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({ id: "error.data_load" }));
    } finally {
      setIsLoading(false);
    }

  };

  return (
    <Select
      name={name}
      label={label}
      loading={isLoading}
      manualOptions={selectLanguageListName}
      customRules={[{ required: true, message: intl.formatMessage({ id: "validation.field_required" }) }]}
      disabled={disabled}
      {...props}
    />
  )
};
export default LanguageSelect;
