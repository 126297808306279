import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, message } from 'antd';
import FileDownload from "js-file-download";
import coursesApiClient from 'utils/coursesApiClient';
import styles from '../styles.module.scss';

interface CertificateProps {
  campaign: any;
}

const Certificate: React.FC<CertificateProps> = ({ campaign }) => {
  const intl = useIntl();

  const downloadCertificate = async () => {
    try {
      const url = `/api/v1/courses/student/campaigns/certificate-file/${campaign.campaignAttemt}`;
      const response = await coursesApiClient.request(url, [], 'GET', true, true);

      if (response.fileName) {
        FileDownload(response, response.fileName);
      } else {
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      }
    } catch (error) {
      message.error(intl.formatMessage({ id: 'error.data_load' }));
    }
  };

  return (
    <div className={styles.certificateContainer}>
      <h3 className={styles.certificateTitle}>
        <i className={`${styles.certificateIcon} fal fa-file-certificate`} />
        {campaign.certificateTitle
          ? campaign.certificateTitle
          : intl.formatMessage({ id: 'student.certificate' }, { amount: campaign.totalMinutes })}
      </h3>
      <Button type="link" onClick={downloadCertificate}>
        <FormattedMessage id="student.download"/>
      </Button>
    </div>
  );
};

export default Certificate;