const onlyUnique = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
}

const pluck = (arr: any, key: string) => arr.map((i: any) => i[key]);


const getFileType = (fileName: string) => {
    const fileNameArray = fileName.split('.');

    return fileNameArray[fileNameArray.length - 1].toUpperCase();
}

const getFileSize = (size: number) => {
    var units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    var i = 0;
    while (size >= 1024) {
        size /= 1024;
        ++i;
    }
    return size.toFixed(1) + ' ' + units[i];
};

const isEmpty = (obj: any) => Object.keys(obj).length === 0;


const exportedObject = {
    onlyUnique, pluck, getFileType, getFileSize, isEmpty
};



export default exportedObject;