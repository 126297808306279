import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import moment from 'moment';
import BarLineChart from 'components/Charts/BarLineChart';
import { DashboardChartProps } from '../types';
import coursesApiClient from 'utils/coursesApiClient';

const PhishingSummaryChart: React.FC<DashboardChartProps> = ({ customerUuid }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  const intl = useIntl();

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const url = `/api/v1/courses/statistic/dashboard/campaigns/customer/${customerUuid}/phishing-overall`;
        const response = await coursesApiClient.request(url);
        const responseData = response.statistic.months;

        const rotateArrayToEndAtCurrentMonth = (array: any[]) => {
          const newArray = [...array];
          const currentMonth = moment().month();

          for (let i = 0; i < 11 - currentMonth; i++) {
            newArray.unshift(newArray.pop());
          }

          return newArray;
        }

        const monthsLabelsEndingWithCurrent = () => {
          const monthLabels = [
            intl.formatMessage({ id: 'general.january' }),
            intl.formatMessage({ id: 'general.february' }),
            intl.formatMessage({ id: 'general.march' }),
            intl.formatMessage({ id: 'general.april' }),
            intl.formatMessage({ id: 'general.may' }),
            intl.formatMessage({ id: 'general.june' }),
            intl.formatMessage({ id: 'general.july' }),
            intl.formatMessage({ id: 'general.august' }),
            intl.formatMessage({ id: 'general.september' }),
            intl.formatMessage({ id: 'general.october' }),
            intl.formatMessage({ id: 'general.november' }),
            intl.formatMessage({ id: 'general.december' }),
          ];

          return rotateArrayToEndAtCurrentMonth(monthLabels);
        }

        const orderedData: { sent: number, failed: number }[] = [];

        Object.keys(responseData)
          .sort((a, b) => parseInt(a, 10) - parseInt(b, 10))
          .forEach((key, index) => {
            orderedData[index] = responseData[key];
          });

        const rotatedData = rotateArrayToEndAtCurrentMonth(orderedData);

        const parsedData = {
          labels: monthsLabelsEndingWithCurrent(),
          datasets: [
            {
              type: 'bar',
              label: intl.formatMessage({ id: 'phishing.emails_sent' }),
              data: Object.values(rotatedData).map((month: { sent: number, failed: number }) => month.sent),
              backgroundColor: 'rgb(102, 148, 224)',
              hoverBackgroundColor: 'rgb(82, 128, 204)',
              yAxisID: 'y-axis-absolute',
              order: 1,
            },
            {
              type:'line',
              label: `${intl.formatMessage({ id: 'phishing.opening_percentage' })} (%)`,
              data: Object.values(rotatedData).map((month: { sent: number, failed: number }) => (month.failed / month.sent * 100).toFixed(1)),
              backgroundColor: 'rgb(244, 98, 100)',
              borderColor: 'rgb(244, 98, 100)',
              hoverBorderColor: 'rgb(224 ,68, 80)',
              pointBackgroundColor: 'rgb(224 ,68, 80)',
              yAxisID: 'y-axis-percentage',
              order: 0,
            },
          ],
        };

        setData(parsedData);
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, [intl, customerUuid]);

  return (
    <BarLineChart
      data={data}
      title={intl.formatMessage({ id: 'phishing.campaign_summary' })}
      loading={isLoading}
    />
  );
};

export default PhishingSummaryChart;