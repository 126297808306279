import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Progress, Spin, Table, Tooltip } from 'antd';
import moment from 'moment';
import FileDownload from 'js-file-download';
import { CampaignStatistics, DashboardChartProps, Progress as ProgressType } from './types';
import coursesApiClient from 'utils/coursesApiClient';
import {connect} from "react-redux";

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        role: state.session.active_user_type
    }
};

const CampaignTable: React.FC<DashboardChartProps> = ({ customerUuid, locale, role }) => {
  const [data, setData] = useState<CampaignStatistics[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  const intl = useIntl();

  const columns = [
    {
      title: intl.formatMessage({ id: 'campaign.campaign_title' }),
      dataIndex: 'campaignName',
      render: (campaignName: string, record: CampaignStatistics) => (
        <NavLink to={`/courses/campaign/${record.campaignId}/edit`}>{campaignName}</NavLink>
      ),
    },
    {
      title: intl.formatMessage({ id: 'campaign.users_enrolled' }),
      dataIndex: 'studentsEnrolled',
      width: '15%',
    },
    {
      title: intl.formatMessage({ id: 'campaign.campaign_progress' }),
      dataIndex: 'progress',
      render: (progress: ProgressType) => {
        const completed = progress.completed % 1 !== 0 ? progress.completed.toFixed(1) : progress.completed;

        return (
          <Tooltip title={`${completed}% ${intl.formatMessage({ id: 'general.completed' }).toLowerCase()}`}>
            <Progress
              percent={progress.inProgress + progress.completed}
              success={{
                percent: progress.completed,
                strokeColor: 'rgb(33, 208, 160)',
              }}
              showInfo={false}
              size="small"
              strokeColor="rgb(247, 216, 0)"
              trailColor="rgb(232, 232, 232)"
            />
          </Tooltip>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'campaign.finish_campaign_on' }),
      dataIndex: 'deadline',
      width: '20%',
      render: (deadline: moment.Moment) => {
        deadline = moment(deadline, 'YYYY-MM-DD');

        let color = '';

        if (deadline.isBefore()) {
          color = 'red';
        } else if (deadline.isBefore(moment().add(1, 'month'))) {
          color = 'orange';
        }

        return (
          <span style={{ color: color }}>
            {deadline.format('DD.MM.YYYY')}
          </span>
        );
      },
    },
  ];

  const getProgressValuesInPercent = (progress: ProgressType): ProgressType => ({
    total: progress.total,
    notStarted: progress.notStarted / progress.total * 100,
    inProgress: progress.inProgress / progress.total * 100,
    completed: progress.completed / progress.total * 100,
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const url = `/api/v1/courses/statistic/dashboard/campaigns/customer/${customerUuid}/campaigns-list`;
        const response = await coursesApiClient.request(url);
        const responseData = response.statistic;

        const data = responseData.map((campaign: CampaignStatistics) => {
          campaign.progress = getProgressValuesInPercent(campaign.progress);

          return campaign;
        });

        setData(data);
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, [intl, customerUuid]);

  const handleDownloadClick = async () => {
    try {
      setIsDownloading(true);

      const url = `/api/v1/courses/statistic/dashboard/campaigns/customer/${customerUuid}/export-campaigns-details`;

      const response = await coursesApiClient.request(url, {language: locale}, 'GET', true, true);

      if (response.fileName) {
        FileDownload(response, response.fileName);
      } else {
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      }
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({ id: 'error.data_load' }));
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <>
      <div className="chart-header">
        <p className="chart-title small-margin">
          <FormattedMessage id="campaign.campaigns" />
        </p>
        {role === 'CUSTOMER_ADMIN' &&
            <button className="chart-download-button" onClick={handleDownloadClick}>
                <Spin spinning={isDownloading} size="small">
                    <i className="fal fa-file-download" />
                </Spin>
            </button>
        }
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        pagination={false}
        rowKey="campaignId"
        scroll={{ y: '18vw' }}
        size="small"
      />
    </>
  );
}

export default connect(mapStateToProps)(CampaignTable);
