// THIS HAS TO BE ON TOP BECAUSE REASONS, just don't touch it, okay
import 'assets/scss/styles.scss';

import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { loadLanguageDictionary } from 'store/actions/languageDictionary';
import Login from 'pages/Login/Login';
import ResetConfirmPassword from 'pages/Login/ResetConfirmPassword';
import ResetPassword from 'pages/Login/ResetPassword';
import DefaultLayout from 'components/DefaultLayout';
import LoginRoute from 'components/LoginRoute';
import ShortRoute from 'components/ShortRoute';


import ProtectedRoute from 'components/ProtectedRoute';
import NoAuthCustomerForm from 'pages/Customers/NoAuthCustomerForm';
import RegistrationCompletion from 'pages/HelperPages/RegistrationCompletion';
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import Spinner from 'components/Spinner';

const mapStateToProps = (state: any) => ({
  locale: state.locale.locale,
  languageDictionary: state.languageDictionary,
  languageDictionaryLoading: state.languageDictionary.loading,
});

const mapDispatchToProps = (dispatch: any) => ({
  loadLanguageDictionary: () => dispatch(loadLanguageDictionary()),
});

interface AppProps {
  locale: string;
  languageDictionary: any;
  languageDictionaryLoading: boolean;
  loadLanguageDictionary: () => void;
}

const App: React.FC<AppProps> = ({
  locale,
  languageDictionary,
  loadLanguageDictionary,
}) => {
  useEffect(() => {
    loadLanguageDictionary();
  }, [loadLanguageDictionary]);

  return (
  <Spinner spinning={languageDictionary.loading} opaque>
    <IntlProvider locale={locale} messages={languageDictionary[locale]}>
      <BrowserRouter>
        <Switch>
          <ShortRoute exact path="/short/:code" component={ResetConfirmPassword}/>
          <LoginRoute exact path="/login/:uuid?" component={Login} />
          <LoginRoute exact path="/forgot-password" component={ResetPassword} />
          <LoginRoute path="/reset-confirm-password" component={ResetConfirmPassword} />
          <Route exact path="/register-customer" component={NoAuthCustomerForm} />
          <Route exact path="/registration-complete" component={RegistrationCompletion} />
          <Route exact path="/terms-and-conditions" component={TermsAndConditions}/>
          <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
          <ProtectedRoute path="/" component={DefaultLayout} />
        </Switch>
      </BrowserRouter>
    </IntlProvider>
  </Spinner>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
