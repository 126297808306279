export const navigation = [
  {key: "1", home: true, icon: "fal fa-home-alt", title: "dashboard.dashboard", permissionSlug: "view_dashboard"},
  {key: "sub1", icon: "fal fa-book", title: "courses.e_learning", permissionSlug: "view_courses,view_campaigns", subNav: [
    {key: "2", url: "/courses/manage-content", title: "courses.manage_content", permissionSlug: "view_courses"},
    {key: "3", url: "/courses/manage-campaigns", title: "campaigns.manage_campaigns", permissionSlug: "view_campaigns"},
  ]},
  {key: "5", icon: "fal fa-book", url: "/courses/manage-campaigns", title: "campaigns.manage_campaigns", permissionSlug: "view_manage_campaigns"},
  {key: "sub2", icon: "fal fa-building", title: "general.organizations", permissionSlug: "view_organizations,view_customers", subNav: [
    {key: "6", url: "/organizations", permissionSlug: "view_organizations", title: "general.resellers"},
    {key: "7", url: "/customers", permissionSlug: "view_customers", title: "general.customers"}
  ]},
    {key: "28", url: "/user/organizations", icon: "fal fa-users", title: "general.my_organizations", specRights: 'can_manage_customers'},
    {key: "8", url: "/users", icon: "fal fa-users", title: "general.users", permissionSlug: "view_users"},
    {key: "sub3", icon: "fal fa-fish-cooked", title: "phishing.phishing", permissionSlug: "view_phishing_campaign", subNav: [
    {key: "9", url: "/phishing/manage-campaigns", title: "campaigns.manage_campaigns", permissionSlug: 'view_phishing'},
    {key: "10", url: "/phishing/templates", title: "emailing.templates_and_scenarios", permissionSlug: 'create_phishing_templates'},
      ]},
  {key: "sub4", icon: "fal fa-envelope-open-text", title: "general.e_mailing", permissionSlug: "view_mail_scenarios", subNav: [
    {key: "11", url: "/e-mailing/email-scenarios", title: "general.send_emails", permissionSlug: "view_mail_scenarios"},
    {key: "12", url: "/e-mailing/manage-templates", title: "emailing.templates.manage_templates", permissionSlug: "view_mail_templates"},
    {key: "13", url: "/e-mailing/history", title: "emailing.history.e_mailing_history", permissionSlug: "view_mail_history"}
  ]},
  {key: "sub5", icon: "fal fa-analytics", title: "analytics.analytics", permissionSlug: 'view_analytics' , subNav: [
    {key: "14", url: "/analytics/e-learning", title: "courses.e_learning"},
    {key: "15", url: "/analytics/phishing", title: "phishing.phishing"},
    {key: "16", url: "/analytics/gamification", title: "analytics.gamification"},
    {key: "17", url: "/analytics/reports", title: "analytics.reports"}
  ]},
  {key: "sub6", icon: "fal fa-file-certificate", title: "licensing.licensing", permissionSlug: "view_services,view_licences", subNav: [
    {key: "18", url: "/licensing/manage-services", title: "licensing.manage_services", permissionSlug: "view_services"},
    {key: "19", url: "/licensing/manage-licences", title: "licensing.manage_licences", permissionSlug: "view_licences"}
  ]},
  {key: "sub7", icon: "fal fa-check-double", title: "subscriptions.subscriptions", permissionSlug: 'view_subscriptions', subNav: [
    {key: "27", url: "/content", title: "subscriptions.content.apply_for_content", permissionSlug: 'view_content'},
    {key: "20", url: "/subscriptions/assign-licences", title: "subscriptions.assign_licences",  permissionSlug: 'view_assigned_licences'},
    {key: "21", url: "/subscriptions/my-subscription-licences", title: "subscriptions.my_subscription_licences",  permissionSlug: 'view_customer_subscriptions'},
    {key: "4", url: "/e-learning/apply-for-new-content", title: "new_content.apply_for_new_content", permissionSlug: "view_apply_for_new_content"}
  ]},
  {key: "sub8", icon: "fal fa-cloud", title: "system.system", permissionSlug: "view_permissions,create_users_filters,view_managa_languages,can_edit_environment,view_global_logs,view_organization_logs,view_customer_logs,edit_connection", subNav: [
    {key: "22", url: "/system/manage-languages", title: "system.manage_languages", permissionSlug: "view_managa_languages"},
    {key: "23", url: "/system/auditing", title: "system.auditing", permissionSlug: "view_global_logs,view_organization_logs,view_customer_logs"},
    {key: "24", url: "/system/permissions", title: "system.permissions.permissions", permissionSlug: "view_permissions"},
    {key: "25", url: "/system/manage-filters", title: "system.filter.manage_filters", permissionSlug: "create_users_filters"},
    {key: "26", url: "/system/customize-environment", title: "system.customize_environment", permissionSlug: "can_edit_environment"},
    {key: "806", url: "/system/connection", title: "system.connection", permissionSlug: "edit_connection", specRights: 'can_manage_customer_connection'},
  ]},
];

const menuItems = navigation.map(menuItem => {
  if (menuItem.subNav) {
    return menuItem.subNav;
  }

  return menuItem;
}).flat();

export const openedMenuItem = menuItems.find(el => el.url && window.location.pathname.includes(el.url)) || null;

export const openedSubMenu = openedMenuItem ?
  navigation.find(navItem =>
    navItem.subNav && navItem.subNav.find(subNavItem => subNavItem.key === openedMenuItem.key)
  ) : null;

const exportedObject = {
  navigation,
  openedMenuItem,
  openedSubMenu
};

export default exportedObject;