
import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Modal, Select } from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import apiClient from 'utils/apiClient';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale
    }
}

const PhishingTestEmailModal = ({ customerId, selectedTestSimulation, visible, onCancel, afterSubmit }: any) => {
    const [selectedUser, setSelectedUser] = useState();
    const [customerUsers, setCustomerUsers] = useState<any>();
    const [users, setUsers] = useState<any>();
    const [usersLoading, setUsersLoading] = useState(false);

    const intl = useIntl();
    const includeColumn = ['name', 'surname', 'email'];

    useEffect(() => {
        const loadCustomerUsers = async () => {
            setUsersLoading(true);
            let responese = await apiClient.request(`/api/v1/auth/customers/${customerId}/users-options`, [], 'GET');

            let users = responese.usersOptions.filter((user: any) => user.userTypeUuid)

            setCustomerUsers(users);

            const items = users.slice(0, 5);

            setUsers(items);
            setUsersLoading(false);
        }

        if (customerId) {
            loadCustomerUsers();
        }
    }, [customerId])

    const sendTestPhishingEmail = async () => {
        try {
            await coursesApiClient.request(`/api/v1/courses/phishing/phishing-simulation-test/${selectedTestSimulation}/${selectedUser}`, [], 'POST');
            message.success(intl.formatMessage({ id: 'users.email_sent' }));
        } catch (error) {
            message.error(intl.formatMessage({ id: "error.server_error" }));
        }
    }

    const filterBySearchString = (searchText: string) => {
        let filtered: any = !searchText ? customerUsers : customerUsers.filter((user: any) => { return Object.keys(user).some(key => includeColumn.includes(key) ? user[key].toString().toLowerCase().includes(searchText.toLocaleLowerCase()) : false); });

        const items = filtered.slice(0, 5);

        setUsers(items);
    }

    return (
        <>
            <Modal
                title={intl.formatMessage({ id: 'phishing.send_test_phishing_email' })}
                visible={visible}
                onCancel={onCancel}
                onOk={() => { sendTestPhishingEmail(); afterSubmit() }}
                cancelText={intl.formatMessage({ id: 'general.cancel' })}
                okText={intl.formatMessage({ id: 'general.send' })}
            >
                <div>
                    <FormattedMessage id="phishing.please_provide_email" />:
        </div>
                <Select
                    allowClear
                    showSearch
                    loading={usersLoading}
                    style={{ width: '100%' }}
                    filterOption={(input: string, option: any) => {
                        return option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    value={selectedUser}
                    onSearch={(value: any) => filterBySearchString(value)}
                    onChange={(value: any) => setSelectedUser(value)}
                >
                    {
                        users && users.map((el: any) => <Select.Option value={el.userTypeUuid}>{el.name} {el.surname} ({el.email})</Select.Option>)
                    }
                </Select>

            </Modal>
        </>
    )
}
export default connect(mapStateToProps)(withRouter(PhishingTestEmailModal));