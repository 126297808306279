import React, { useState, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment/moment.js';
import { message, Tag, Modal, Form, Popover, Radio, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { changeOrganizationOptions } from 'store/actions/saveOrganizationOptions';
import InstructionButton from 'components/VideoInstructions/InstructionButton';
import DefaultLayout from 'components/DefaultLayout';
import FlexRow from 'components/FlexRow';
import { Select, CustomerSelect, Switch } from 'components/Form';
import apiClient from 'utils/apiClient';
import config from 'config/config';
import Table from 'components/Table';

import './styles.scss';

const mapStateToProps = (state: any) => {
  return {session: state.session,
    filterdOrganizationOptions: state.filterdOrganizationOptions,
    locale: state.locale.locale
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  changeOrganizationOptions: (filterdOrganizationOptions: any) => dispatch(changeOrganizationOptions(filterdOrganizationOptions))
});

const SendEmailScenarios: React.FC = ({session,filterdOrganizationOptions, changeOrganizationOptions, history, locale}: any) => {
  const [scenarios, setScenarios] = useState([]);
  const [selected, setSelected] = useState({});
  const [scenariosLoading, setScenariosLoading] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterBy, setFilterBy] = useState('customer');
  const [visibleTooltip, setVisibleTooltip] = useState<number|null>(null);
  const [ownerId, setOwnerId] = useState();
  const [filteredOptions, setFilteredOptions] = useState<any>([]);
  const [mailTemplateUrl, setMailTemplateUrl] = useState<any>();
  const [emailCategories, setEmailCategories] = useState();
  const [showDeactivationConfirmationModal, setShowDeactivationConfirmationModal] = useState(false);
  const [queuedEmails, setQueuedEmails] = useState<any>(null);
  const [mailDeliveryTerm, setMailDeliveryTerm] = useState<any>(null);

  const [showOnlyNotFinishedScenarios, setShowOnlyNotFinishedScenarios] = useState(true);
  const [templateOwnerType, setTemplateOwnerType] = useState('customer');
  const [filter, setFilter] = useState();

  const [templateOwnerLabel, setTemplateOwnerLabel] = useState(null);
  const [mailTemplateLabel, setMailTemplateLabel] = useState(null);
  const [mailCategoryLabel, setMailCategoryLabel] = useState(null);

  const pageSizeOptions = [10, 20];
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPageSize, setCurrentPageSize] = useState<number>(pageSizeOptions[0]);

  const [showOrganizationModal, setShowOrganizationModal] = useState(false);

  const resourceUrl = '/api/v1/get-mail-scenarios';


  const intl = useIntl();
  const [form] = Form.useForm();
  const [modal] = Form.useForm();

  useEffect(() => {
    if (session.active_user_type === 'SUPER_ADMIN') {
      setScenariosLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
      changeOrganizationOptions({});
      const loadEmailCategories = async () => {
      let categoriesResponse = await apiClient.request(`/api/v1/mail-templates/mail-categories`, {}, 'GET');
      if (session.active_user_type !== 'SUPER_ADMIN') {
          Object.entries(categoriesResponse).map((el:any)=> {
            if (el[1] === 'system_events') {
              delete categoriesResponse[el[0]]
            }
            return categoriesResponse;
          })
      }
      setEmailCategories(categoriesResponse);
    };

    if (!filterdOrganizationOptions.owner) {
      form.setFieldsValue({
        filterBy: 'customer',
      });
    }
    modal.setFieldsValue({
      filterBy: 'customer',
    });

    if (session.active_user_type === 'DISTRIBUTOR_ADMIN') {
      setTemplateOwnerType('organization');
        setOwnerId(session.organization.organization_id);
    } else if (session.active_user_type === 'RESELLER_ADMIN') {
        setTemplateOwnerType('organization');
        setOwnerId(session.organization.organization_id);
    } else if (session.active_user_type ==='CUSTOMER_ADMIN') {
        setTemplateOwnerType('customer');
        setOwnerId(session.organization.organization_id);
    }

    loadEmailCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);


  useEffect(() => {
      loadScenarios();
      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showOnlyNotFinishedScenarios]);

const filterScenarios = async (values: any) => {

  let parsedValues = {
      filterBy: values.filterBy,
      ownerReferenceId: values.organization || values.customer,
      categoryId: values.templateCategory,
      templateId: values.templateName
  };

    setScenariosLoading(true);
    setShowFilterModal(false);
    let response:any = await getRecords(resourceUrl, 'page[number]=1&page[size]=' + currentPageSize, parsedValues);
    setScenarios(response.data);

    let pagination = response.pagination;

    setTotalRecords(pagination.total);
    setScenariosLoading(false);
};

    const loadScenarios = async () => {
        setScenariosLoading(true);

        if (session.active_user_type === 'SUPER_ADMIN') {
            if (!filterdOrganizationOptions.owner) {
                setScenariosLoading(false);
                return;
            }
        }

        let response:any = await getRecords(resourceUrl, 'page[number]=1&page[size]=' + currentPageSize, filter);

        setScenarios(response.data);

        let pagination = response.pagination;

        setTotalRecords(pagination.total);
        setScenariosLoading(false);

    };

  const handleVisibleChange = (visible: boolean, recordId: number) => {
    if (visible) {
      setVisibleTooltip(recordId);
    } else {
      setVisibleTooltip(null);
    }
  }

  async function onChange(record:any) {
    let errorMessageCode = 'error.data_load';
    let force = 0;

    setSelected(record)

    try {
      if (record.status === 'ACTIVE') {
        await apiClient.request(`/api/v1/mail-scenario/${record.id}/deactivate/${force}`, record, 'POST');
        record.status = 'INACTIVE'
      } else if (record.status === 'INACTIVE') {
        await apiClient.request(`/api/v1/mail-scenario/${record.id}/activate`, record , 'POST');
        record.status = 'ACTIVE'
      }
    } catch (err: any) {
      if (err.message === 'unsend_mail_scenario_queue') {
        errorMessageCode = err.message

        let keys = Object.keys(record.queued);
        let last = keys[keys.length-1];

        setQueuedEmails(record.queued[last])

        let hours = calculateMailDeliveryTime(record.queued[last], record.emailLimit)

        var term = moment().add(hours, 'hours').format('Y-MM-DD');

        setMailDeliveryTerm(term)
        setShowDeactivationConfirmationModal(true)
      } else {
        message.error(intl.formatMessage({id: 'error.' + errorMessageCode}));
      }
    }
  }

  const calculateMailDeliveryTime = (recipients: any, limit: any) => {
    let hours = 0;

    if (limit > 0 && recipients > 0) {
      let add = (recipients % limit === 0) ? 0 : 1;

      hours = (Math.trunc(recipients / limit) + add) - 1;
    }

    return hours
  };

  const columns = [
    {
      title: intl.formatMessage({id: 'emailing.scenarios.e_mailing_event'}),
      dataIndex: 'description',
      key: 'description',
      render: (value: string, record: any) => {

        let description = record.description.split(':');
        let templateName = record.templateName;
        let templateDescription = '';

        if (templateName && templateName[locale]) {
          templateDescription = templateName[locale];
        } else if (templateName) {
          templateDescription = templateName[Object.keys(templateName)[0]];
        } else {
          templateDescription = description[1];
        }

        if (templateDescription.length) {
          return <>
            <Tag>
              <FormattedMessage id={`email_templates.categories.${description[0]}`} />
            </Tag>
            {templateDescription}
          </>
        }
        return record.description;
      }
    },
    {
      title: intl.formatMessage({id: 'emailing.campaign'}),
      dataIndex: 'campaignName',
      render: (value: string, record: any) =>
          record.campaignName ? record.campaignName : '-'
    },
    {
      title: intl.formatMessage({id: 'general.schedule'}),
      dataIndex: 'cron_expression',
      key:'cronExpression',
      render: (value: string, record: any) =>{
        if (record.cronExpression !== null) {
          let result = {everyMinutesOn: '',everyHourOn: '', everyMonthOn: '',  everyWeekOn: []};
          let expressionElements = record.cronExpression.split(' ');
          if(expressionElements[0] !== '*'){
            if(expressionElements[0] < 10){

              result.everyMinutesOn = '0' + expressionElements[0].split(',').map((el: string) => parseInt(el));
            }else result.everyMinutesOn = expressionElements[0].split(',').map((el: string) => parseInt(el));
          }
          if(expressionElements[1] !== '*'){
            if(expressionElements[1] < 10){

              result.everyHourOn = '0' + expressionElements[1].split(',').map((el: string) => parseInt(el));
            }else result.everyHourOn = expressionElements[1].split(',').map((el: string) => parseInt(el));
          }

          if(expressionElements[2] !== '*'){

            result.everyMonthOn = expressionElements[2].split(',').map((el: string) => parseInt(el));
            if(result.everyMonthOn.length === 1){
              return ` ${result.everyHourOn}:${result.everyMinutesOn} ${intl.formatMessage({id: 'emailing.scenarios.every_month_on'})}  ${result.everyMonthOn} ${intl.formatMessage({id: 'day'})}  `
            }else{
              return ` ${result.everyHourOn}:${result.everyMinutesOn} ${intl.formatMessage({id: 'emailing.scenarios.every_month_on'})} ${result.everyMonthOn} ${intl.formatMessage({id: 'day'})}`
            }
          }else if(expressionElements[4] !== '*') {

            result.everyWeekOn = expressionElements[4].split(',').map((el: string) => parseInt(el));

            if (result.everyWeekOn.length > 1) {
              let text = ` ${result.everyHourOn}:${result.everyMinutesOn} ${intl.formatMessage({id: 'emailing.scenarios.every_week_on'})}`;
              result.everyWeekOn.forEach((el: number) => {
                text += ` ${intl.formatMessage({id: `emailing.scenarios.day.${el}`})},`;
              })
              text = text.substring(0, text.length - 1);
              return text;
            } else {
              return ` ${result.everyHourOn}:${result.everyMinutesOn} ${intl.formatMessage({id: 'emailing.scenarios.every_week_on'})} ${intl.formatMessage({id: `emailing.scenarios.day.${result.everyWeekOn}`})} `
            }


          } else if(result.everyMinutesOn !== ''  && result.everyHourOn !== '' ) {
            return ` ${result.everyHourOn}:${result.everyMinutesOn} ${intl.formatMessage({id: 'emailing.scenarios.every_day'})} `
          }else return ` ${intl.formatMessage({id: 'error.invalid_cron_expression'})} `

        } else if (record.scheduleDate) {
          return `${record.scheduleDate}`;
        } else {
          return '-'
        }
      }
    },
    {
      title: intl.formatMessage({id: 'emailing.scenarios.last_occurance'}),
      dataIndex: 'lastExecution',
      render: (value: string, record: any) =>
          record.lastExecution ? record.lastExecution : '-'
    },
    {
      title: intl.formatMessage({id: 'emailing.scenarios.next_occurance'}),
      dataIndex: 'nextExecution',
      render: (value: string, record: any) =>
        record.nextExecution ? (record.isScenarioEnded ? '-' : record.nextExecution) : '-'
    },
    {
      title:
        <div>
          {intl.formatMessage({id: 'general.status'})}
          <Popover
            content={intl.formatMessage({id: 'emailing.scenarios.status.hint'})} >
            <i style={{marginLeft:'3px'}} className='fal fa-question-circle'></i>
          </Popover>
        </div>,
      dataIndex: 'status',
      render: (value: string, record:any) =>{
        let switchStatus;
        if (record.status === 'ACTIVE') switchStatus = true;
        if (record.status === 'INACTIVE') switchStatus = false;
        return (<Switch  onChange={() => onChange(record)} defaultChecked={switchStatus} disabled={record.isScenarioEnded} />)
      }
    },
    {
      key: 'actions',
      render: (text: string, record: any) => {
        const organizationId = record.id;
        const content = <>
        {record.scenarioType === 'CUSTOM' ?
          <NavLink to={`/e-mailing/email-scenarios/${record.id}/edit`}>
          <div className='popover-item'>
              <FormattedMessage id='general.edit' />
          </div>
          </NavLink>
          : null}
          <NavLink to={`/e-mailing/${record.id}/history/`}>
              <div className='popover-item'>
                <FormattedMessage id='emailing.scenarios.view_sending_history'/>
              </div>
          </NavLink>
        </>;

        return (
          <Popover
            visible={organizationId === visibleTooltip}
            content={content}
            trigger='click'
            placement='bottomRight'
            arrowPointAtCenter
            onVisibleChange={(visible) => handleVisibleChange(visible, organizationId)}
          >
            <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
              <i className='fal fa-ellipsis-v' style={{fontSize: '16px'}} />
            </div>
          </Popover>
        )
      },
    }
  ];

  const clearFilter = async () => {
    setFilteredOptions([]);
    setScenarios([]);

    setTemplateOwnerLabel(null);
    setMailCategoryLabel(null);
    setMailTemplateLabel(null);

    form.setFieldsValue({
        templateCategory: [],
        templateName: []
    });

    setFilter(undefined);
    setTotalRecords(0);
  };


  const tagFilter = async(el:any, index:any) => {
      let newFilter: any = filter;

      let parsedValues;

      if (el.customer) {
          form.setFieldsValue({
              customer: [],
              organization: [],
              templateCategory: [],
              templateName: []
          });

          setTemplateOwnerLabel(null);
          newFilter = [];
          setFilteredOptions([]);
          setScenarios([]);
      }

      if (el.category) {
          form.setFieldsValue({
              templateCategory: [],
          });
          setMailCategoryLabel(null);
          newFilter.categoryId = [];
      }

      if (el.template) {
          form.setFieldsValue({
              templateName: []
          });
          setMailTemplateLabel(null);
          newFilter.templateId = [];
      }

      if (el.category || el.template) {
          parsedValues = newFilter;

          setScenariosLoading(true);
          setShowFilterModal(false);
          let response:any = await getRecords(resourceUrl, 'page[number]=1&page[size]=' + currentPageSize, parsedValues);

          setScenarios(response.data);

          let pagination = response.pagination;

          setTotalRecords(pagination.total);
          setScenariosLoading(false);
      }
  };

  const forceDeactivateMailScenario = async (selected:any) => {
    await apiClient.request(`/api/v1/mail-scenario/${selected.id}/deactivate/1`, {}, 'POST');
    selected.status = 'INACTIVE'

    setShowDeactivationConfirmationModal(false)
  };

  const getRecords = async (resourceUrl: string, pageQuery: string, filterValues: any = {}) => {
      let query = (pageQuery !== '') ? '?' + pageQuery : '';

      let parsedValues:any;

      if (filterValues) {
          parsedValues = filterValues;
      } else {
          parsedValues = filter;
      }

      parsedValues.isScenarioEnded = !showOnlyNotFinishedScenarios;

      let response = await apiClient.request(resourceUrl + query, parsedValues, 'POST');
      setFilteredOptions([]);

      if (parsedValues && parsedValues.ownerReferenceId && parsedValues.ownerReferenceId.length !== 0) {
          setFilteredOptions((filteredOptions:any)=> [...filteredOptions, {customer:templateOwnerLabel}]);
      }

      if (parsedValues && parsedValues.categoryId && parsedValues.categoryId.length !== 0 ) {
          setFilteredOptions((filteredOptions:any)=> [...filteredOptions, {category:mailCategoryLabel}]);
      }

      if (parsedValues && parsedValues.templateId && parsedValues.templateId.length !== 0 ) {
          setFilteredOptions((filteredOptions:any)=> [...filteredOptions, {template:mailTemplateLabel}]);
      }

      setFilter(parsedValues);
      changeOrganizationOptions({owner: parsedValues});

      return  {
          data: (response.mailScenarios.data) ? response.mailScenarios.data : {},
          pagination: (response.mailScenarios.pagination) ? response.mailScenarios.pagination : {}
      }
  };

  const addNewScenario = async (value: any) => {
      changeOrganizationOptions({});
      try {
          changeOrganizationOptions({
              owner: {
                  ownerId: value.customer || value.organization,
                  owner: value.customer ? 'CUSTOMER' : 'ORGANIZATION',
                  templateOwnerLabel
              }
          });
      } catch (error: any) {
          message.error(error);
          console.error(error);
      } finally {
          setShowOrganizationModal(false);
          history.push('/e-mailing/email-scenarios/add');
      }
  };

  return (
    <>
      <DefaultLayout.PageLayout>
        <DefaultLayout.PageHeader title={intl.formatMessage({id: 'emailing.scenarios.e_mailing_center'})} />

        <DefaultLayout.PageContent>
        <FlexRow
          left={
            <>
              <Button type='primary' onClick={() => session.active_user_type === 'SUPER_ADMIN' ? setShowOrganizationModal(true) : history.push('/e-mailing/email-scenarios/add')}>
                <PlusOutlined/> <FormattedMessage id='emailing.scenarios.send_new_e_mail' />
              </Button>
              <Button
                type='primary'
                style={{ marginLeft: 10 }}
                onClick={() => setShowFilterModal(true)}
              >
                <FormattedMessage id='general.filter' />
              </Button>
            </>
          }
          right={
            <>
              <FormattedMessage id='emailing.dont_show_finished' />
              <Switch
                name='showUnfinishedScenarios'
                label={intl.formatMessage({id: 'emailing.dont_show_finished'})}
                onChange={(e:any) => setShowOnlyNotFinishedScenarios(e)}
                defaultChecked={showOnlyNotFinishedScenarios}
                styles={{ marginBottom: 0}}
                hasDefaultLayout
              />
              <InstructionButton position='MAIL_NOTIFICATIONS'/>
            </>
          }
        />

          <Form.Item
            name='filterOptions'
            label={intl.formatMessage({id: 'general.filtered_by'})}
            style={{ marginTop: 5, marginBottom: 0 }}
          >
            {Object.values(filteredOptions).map((el: any, index: any) => {
              return (
                <span>
                  <Tag closable onClose={()=>{ tagFilter(el, index)}}  className='tag-filter' closeIcon={<i className='fal fa-times'></i>}>
                      {el.customer ? `${intl.formatMessage({id: 'general.customer'})}:${el.customer}` : null}

                      {el.category ? `${intl.formatMessage({id: 'general.category'})}:${el.category}` : null}

                      {el.template ? `${intl.formatMessage({id: 'template'})}:${el.template}` : null}
                  </Tag>
                </span>
              );
            })}
            <button className='clear-all-button' onClick={()=>clearFilter()}>
              <FormattedMessage id='users.clear_all' />
            </button>
          </Form.Item>
            <Table
                resourceUrl = {resourceUrl}
                totalRecords = {totalRecords}
                loadedData = {scenarios}
                setLoadedData = {setScenarios}
                setTotalRecords = {setTotalRecords}
                setCurrentPageSize = {setCurrentPageSize}
                currentPage = {currentPage}
                setCurrentPage = {setCurrentPage}
                setLoading = {setScenariosLoading}
                loading = {scenariosLoading}
                columns = {columns}
                pageSizeOptions = {pageSizeOptions}
                getRecords = {getRecords}
                hasSelection = {false}
            />
        </DefaultLayout.PageContent>
      </DefaultLayout.PageLayout>

      <Modal
        className='history-list-modal'
        visible={showFilterModal}
        title={intl.formatMessage({id: 'emailing.scenarios.filter_e_mailing_events'})}
        onOk={() => form.submit()}
        onCancel={() => setShowFilterModal(false)}
        okText={intl.formatMessage({id: 'general.filter'})}
        cancelText={intl.formatMessage({id: 'general.cancel'})}
      >
        <Form form={form} onFinish={filterScenarios}>
            {session.active_user_type === 'SUPER_ADMIN' &&
            <>
                <Form.Item name='filterBy' label='Filter by'>
                    <Radio.Group
                      onChange={(el: any) => {
                        setFilterBy(el.target.value);
                        if (el.target.value === 'customer') {
                            form.setFieldsValue({filterBy: 'customer'});
                        } else {
                            form.setFieldsValue({filterBy: 'reseller'});
                        }
                        form.setFieldsValue({
                            customer: [],
                            organization: [],
                        });
                      }}
                    >
                    <Radio value='customer' onClick={() => {
                      setTemplateOwnerType('customer');
                      form.setFieldsValue({customer: [], templateCategory: [], templateName: []})
                      }}
                    >
                      <FormattedMessage id='general.customer'/></Radio>
                    <Radio value='reseller' onClick={() => {
                      setTemplateOwnerType('organization');
                      form.setFieldsValue({organization: [], templateCategory: [], templateName: []})
                      }}
                    >
                      <FormattedMessage id='general.reseller'/></Radio>
                    </Radio.Group>
                </Form.Item>
                {filterBy === 'customer'
                    ? <CustomerSelect
                        showSearch
                        name='customer'
                            label={intl.formatMessage({id: 'organization.organization_name'})}
                            url={config.api.routes.backend.organizationsCustomers}
                            integerKey={true}
                            customRules={[{ required: true, message: intl.formatMessage({id: 'validation.field_required'}) }]}
                            onChange={(value: any, option:any) => {
                                form.setFieldsValue({templateCategory: []});
                                form.setFieldsValue({templateName: []});
                                setOwnerId(value);
                                setTemplateOwnerLabel(option.children);
                            }}
                            isResponseArray={true}
                            dataKey='customers'
                            mapDataEntries={(el: any) => ({value: el.id, customerType: el.type, label: el.name})}
                            valueKey='value'
                            labelKey='label'
                            customLayout={true}
                            filterOption={(input: string, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                        />
                        : null}
                    {filterBy === 'reseller'
                        ? <CustomerSelect
                            showSearch
                            name='organization'
                            label={intl.formatMessage({id: 'organization.organization_name'})}
                            url={config.api.routes.backend.organizationsOptions}
                            integerKey={true}
                            customRules={[{ required: true, message: intl.formatMessage({id: 'validation.field_required'}) }]}
                            onChange={(value: any, option:any) => {
                                form.setFieldsValue({templateCategory: []});
                                form.setFieldsValue({templateName: []});
                                setOwnerId(value);
                                setTemplateOwnerLabel(option.children);
                            }}
                            isResponseArray={true}
                            dataKey='organizations'
                            mapDataEntries={(el: any) => ({value: el.id, customerType: el.type, label: el.name})}
                            valueKey='value'
                            labelKey='label'
                            customLayout={true}
                            filterOption={(input: string, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                        />
                        : null
                    }
                </>
            }
        <Select
          showSearch
          name='templateCategory'
          label={intl.formatMessage({id: 'emailing.scenarios.e_mail_category'})}
          manualOptions={emailCategories}
          integerKey={true}
          customLayout={true}
          customObjLabel={(el: string) => intl.formatMessage({id: `email_templates.categories.${el}`})}
          onChange={(value: any, option:any) => {setMailTemplateUrl(`/api/v1/${templateOwnerType}-mail-templates/template-name/${value}/${ownerId}`); form.setFieldsValue({templateName: []}); setMailCategoryLabel(option ? option.children : null)}}
          filterOption={(input: string, option: any) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
        <Select
            allowClear
            showSearch
            name='templateName'
            label={intl.formatMessage({id: 'emailing.scenarios.e_mail_template'})}
            url={mailTemplateUrl}
            integerKey={true}
            isResponseArray={true}
            dataKey='organizationEmailTemplate'
            onChange={(value: any, option: any)=> setMailTemplateLabel(option ? option.children : null)}
            mapDataEntries={(el: any) => ({value: el.id,
              label: el.name[locale] ? el.name[locale] : el.name[Object.keys(el.name)[0]]
            })}
            valueKey='value'
            labelKey='label'
            customLayout={true}
            filterOption={(input: string, option: any) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          />
        </Form>
      </Modal>

      <Modal
        className="user-list-modal"
        visible={showOrganizationModal}
        title={intl.formatMessage({ id: 'emailing.templates.select_organization' })}
        onOk={()=>  modal.submit()}
        onCancel={() => setShowOrganizationModal(false)}
        okText={intl.formatMessage({ id: 'general.select' })}
        cancelText={intl.formatMessage({ id: 'general.cancel' })}
      >
        <Form form={modal} onFinish={addNewScenario}>
          <Form.Item name="filterBy" label="Filter by">
            <Radio.Group
              onChange={(el: any) => {
                setFilterBy(el.target.value);

                if (el.target.value === 'customer') {
                  form.setFieldsValue({ filterBy: 'customer' });
                } else {
                  form.setFieldsValue({ filterBy: 'reseller' });
                }

                form.setFieldsValue({
                  customer: [],
                  organization: [],
                });
              }}
            >
              <Radio value="customer" onClick={() => { modal.setFieldsValue({ customer: [] }) }}>
                <FormattedMessage id='general.customer'/>
              </Radio>
              <Radio value="reseller" onClick={() => { modal.setFieldsValue({ organization: [] }) }}>
                <FormattedMessage id='general.reseller'/>
              </Radio>
            </Radio.Group>
          </Form.Item>
          {filterBy === 'customer'
            ? <CustomerSelect
              showSearch
              name='customer'
              label={intl.formatMessage({id: "general.customer"})}
              url={config.api.routes.backend.organizationsCustomers}
              integerKey={true}
              isResponseArray={true}
              dataKey='customers'
              mapDataEntries={(el: any) => ({value: el.id, label: el.name})}
              valueKey='value'
              labelKey='label'
              customLayout={true}
              filterOption={(input: string, option: any) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            />
            : null}
          {filterBy === 'reseller'
            ? <CustomerSelect
                showSearch
                name='organization'
                label={intl.formatMessage({id: "general.reseller"})}
                url={config.api.routes.backend.organizationsOptions}
                integerKey={true}
                onChange={() => {form.setFieldsValue({organizationalUnitId: []});}}
                isResponseArray={true}
                dataKey='organizations'
                mapDataEntries={(el: any) => ({value: el.id, customerType: el.type, label: el.name})}
                valueKey='value'
                labelKey='label'
                customLayout={true}
                filterOption={(input: string, option: any) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              />
            : null}
        </Form>
      </Modal>

      <Modal
        title={intl.formatMessage({id: 'emailing.scenarios.deactivate_mail_scenario'})}
        visible={showDeactivationConfirmationModal}
        onOk={() => forceDeactivateMailScenario(selected)}
        onCancel={() => setShowDeactivationConfirmationModal(false)}
        okText={intl.formatMessage({id: 'general.yes'})}
        cancelText={intl.formatMessage({id: 'general.no'})}
        >
        {intl.formatMessage({id: 'general.warning'})}!
        <br></br>
        <br></br>
        {intl.formatMessage({id: 'emailing.scenarios.mail_scenario_deactivate_confirmation'}, {queuedEmails: queuedEmails, mailDeliveryTerm: mailDeliveryTerm})}
        <br></br>
        <br></br>
        {intl.formatMessage({id: 'emailing.scenarios.are_you_sure_you_want_to_disable'})}?
      </Modal>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SendEmailScenarios));
