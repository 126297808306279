import React, { useEffect, useState } from 'react';
import { Empty, message, Space, Spin, Alert } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import CampaignCard from 'components/CampaignCard';
import { changeCampaignActivityList } from 'store/actions/saveCampaignActivityList';
import coursesApiClient from 'utils/coursesApiClient';
import { connect } from 'react-redux';
import './styles.scss';

const mapStateToProps = (state: any) => {
  return {
    session: state.session
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  changeCampaignActivityList: (formData: any) => dispatch(changeCampaignActivityList(formData))
});

const StudentCampaignListDivision = ({ session, history, changeCampaignActivityList }: any) => {

  const [isLoading, setIsLoading] = useState(true);
  const [campaignList, setCampaignList] = useState<any>([]);
  const [campaignFinishedList, setCampaignFinishedList] = useState<any>([]);
  const [campaignUnfinishedList, setCampaignUnfinishedList] = useState<any>([]);
  const [canceledList, setCanceledList] = useState<any>([]);
  const [hasNoCampaigns, setHasNoCampaigns] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    if (session.active_user_type_uuid && session.active_user_type === 'STUDENT') {
      loadCampaigns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, session.active_user_type_uuid]);

  const loadCampaigns = async () => {
    try {
      setIsLoading(true);

      const response = await coursesApiClient.request(`/api/v1/courses/student/campaign-list/${session.active_user_type_uuid}/1`, {}, 'GET');
      const campaignList = response.campaigns;

      if (!response.campaigns.success) {
          setHasNoCampaigns(true);
          return;
      }

      setCampaignFinishedList(campaignList.campaigns.finished ? campaignList.campaigns.finished : []);
      setCampaignUnfinishedList( campaignList.campaigns.unFinished ? campaignList.campaigns.unFinished : []);
      setCampaignList(campaignList.campaigns.inProgress ? campaignList.campaigns.inProgress : [] );
      setCanceledList(campaignList.campaigns.canceled ? campaignList.campaigns.canceled : []);

    } catch (error: any) {
      if (error.message !== 'student_has_no_campaigns') {
        message.error(intl.formatMessage({ id: 'error.data_load' }));
        console.error(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const renderCampaigns = (campaigns:any) => {
    if (campaigns.length === 0) {
      return (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={intl.formatMessage({ id: 'general.found_no_data' })}
        />
      );
    }

    return campaigns.map((campaign: any) =>
      {
        return (
          <CampaignCard
            key={campaign.id}
            campaign={campaign}
            status={campaign.campaignStatus}
            onClick={() => {changeCampaignActivityList({
              campaignId: campaign.id,
              campaignAttemptId: campaign.campaignAttemt,
              enrollmentId: campaign.enrollmentId,
              campaignDeadline: campaign.deadline,
              resertificationIndex: campaign.resertificationIndex
            });
             history.push(`/student/${campaign.enrollmentId}/activity-list/${campaign.id}`);}}
            bordered
          />
        )
      }
    );
  };

  return (
    <Spin spinning={isLoading}>
      {hasNoCampaigns &&
        <Alert className='activities-alert' message={intl.formatMessage({id: "general.info"})} description={intl.formatMessage({id: "student.not_have_activities"})} type="info" showIcon />
      }
      {
        campaignList.length ?
          <>
            <div style={{ minHeight: 50 }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                {!isLoading && renderCampaigns(campaignList)}
              </Space>
            </div>
          </>
        : null
      }
      {
        campaignFinishedList.length ?
          <>
            <h3 className='finished-unfinished'><FormattedMessage id="student.finished_learning" /></h3>
            <div style={{ minHeight: 50 }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                {!isLoading && renderCampaigns(campaignFinishedList)}
              </Space>
            </div>
          </>
        : null
      }
      {
        campaignUnfinishedList.length ?
          <>
            <h3 className='finished-unfinished'><FormattedMessage id="student.unfinished-learning" /></h3>
            <div style={{ minHeight: 50 }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                {!isLoading && renderCampaigns(campaignUnfinishedList)}
              </Space>
            </div>
          </>
        : null
      }
      {
        canceledList.length ?
          <>
            <h3 className='finished-unfinished'><FormattedMessage id="student.canceled_learning" /></h3>
            <div style={{ minHeight: 50 }}>
              <Space direction="vertical" style={{ width: '100%' }}>
                {!isLoading && renderCampaigns(canceledList)}
              </Space>
            </div>
          </>
        : null
      }
    </Spin>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StudentCampaignListDivision));
