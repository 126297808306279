import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form, Select as AntDSelect, Tooltip } from 'antd';
import apiClient from 'utils/apiClient';
import { constructValidationRules } from './validations';
import './styles.scss';

const Select: React.FC<any> = ({
  url,
  integerKey,
  dataKey,
  isResponseArray,
  valueKey,
  labelKey,
  mapDataEntries,
  name,
  label,
  validation = {},
  customRules,
  infoText,
  sortOptions,
  classifierIsObject,
  customLayout,
  disabled,
  customObjLabel,
  filter,
  help,
  withSystemName,
  distinct,
  visible = true,
  manualOptions,
  className,
  hint,
  translationModule,
  saveLoadedData = false,
  setLoadedData,
  ...props
}) => {
  const [options, setOptions] = useState<any>(isResponseArray ? [] : {});
  const [isOptionsLoading, setIsOptionsLoading] = useState(false);

  const intl = useIntl();

  // Fix for google autoFill popping up in select input
  // https://github.com/ant-design/ant-design/issues/7659
  useEffect(() => {
    const fixAutoComplete = () => {
      document.querySelectorAll(".ant-select-selector input").forEach((e) => {
        e.setAttribute("autoComplete", "noAutoComplete");
        //you can put any value but NOT "off" or "false" because they DO NOT work
      })
    };

    fixAutoComplete();
  }, []);

  useEffect(() => {
    const loadOptions = async () => {
      setIsOptionsLoading(true);
      if (manualOptions) {
        setOptions(manualOptions);
      } else {
        if (url) {
            setOptions([]);
            let optionsResponse = await apiClient.request(url, {}, 'GET');
          if (optionsResponse) {
            let parsedOptions = parseOptions(dataKey ? optionsResponse[dataKey] : optionsResponse);
            setOptions(parsedOptions);
            if (saveLoadedData) {
              setLoadedData(parsedOptions);
            }

          } else {
            setOptions(isResponseArray ? [] : {});
          }
        }
      }

      setIsOptionsLoading(false);
    };

    const parseOptions = (options: any) => {
      let parsedOptions = options;

      if (!options && isResponseArray) {
        parsedOptions = [];
      } else if (!options) {
        parsedOptions = {};
      }

      if (filter) {
        parsedOptions = parsedOptions.filter(filter);
      }

      if (mapDataEntries) {
        parsedOptions = parsedOptions.map(mapDataEntries);
      }

      if (distinct && isResponseArray) {
        let distinctOptions = [];
        const map = new Map();
        for (const item of parsedOptions) {
          if (!map.has(item.value)) {
            map.set(item.value, true);
            distinctOptions.push({
              value: item.value,
              label: item.label
            });
          }
        }
        parsedOptions = distinctOptions;
      }

      if (sortOptions) {
        if (classifierIsObject) {
          parsedOptions = Object.entries(parsedOptions).sort(sortOptions);
        } else {
          parsedOptions = parsedOptions.sort(sortOptions);
        }
      }

      if (translationModule) {
        Object.keys(parsedOptions).forEach((key) => {
          parsedOptions[key] = intl.formatMessage({ id: `${translationModule}.${key.toLowerCase()}` });
        });
      }

      return parsedOptions;
    };

    if (options) {
      loadOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, manualOptions]);

  const parsedKey = (key: string) => {
    if (integerKey) {
      return parseInt(key);
    }

    return key;
  };

  let labelCol: any = { span: 8 };
  let wrapperCol: any = { span: 6 };

  if (customLayout) {
    if (typeof customLayout === 'object') {
      labelCol = customLayout.labelCol || undefined;
      wrapperCol = customLayout.wrapperCol || undefined;
    } else {
      labelCol = undefined;
      wrapperCol = undefined;
    }
  }

  return (
    <Form.Item
      className={className}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      name={name}
      label={
        hint
          ? <span>
            {intl.formatMessage({ id: label })}
            <Tooltip title={intl.formatMessage({ id: hint })}>
              <i className='fal fa-question-circle header-item' />
            </Tooltip>
          </span>
          : label
      }
      rules={customRules || constructValidationRules(validation, intl)}
      help={help}
      style={{ display: visible ? '' : 'none' }}
    >
      <AntDSelect loading={isOptionsLoading} className={`default-select ${props.className || ''}`} disabled={disabled} {...props}>
        {
          isResponseArray
            ? options.map((option: any) => {
              return (
                <AntDSelect.Option key={option[valueKey]} value={option[valueKey]} >
                  {option[labelKey]}
                </AntDSelect.Option>
              );
            })
            : Object.keys(options).map((key: any, index: number) => {
              if (sortOptions && classifierIsObject) {
                return (
                  <AntDSelect.Option
                    key={parsedKey(key)}
                    value={options[key][0]}
                  >
                    {options[key][1]}
                  </AntDSelect.Option>
                );
              }
              return (
                <AntDSelect.Option
                  key={parsedKey(key)}
                  value={parsedKey(key)}
                >
                  {customObjLabel ? customObjLabel(options[key]) : options[key]}
                </AntDSelect.Option>
              );
            })
        }
      </AntDSelect>
    </Form.Item>
  )
};

export default Select;
