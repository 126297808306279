import React from 'react';
import { useIntl } from 'react-intl';
import { Select } from 'components/Form';

interface ScormVersionSelectInterface {
    name: string;
    label: string;
    layout?: {
        labelCol: {
            span: number
        }
        wrapperCol: {
            span: number
        }
    }
    disabled?: boolean;
    customRules?: any;
    hasDefaultLayout?: boolean;
}

const ScormVersionSelect: React.FC<ScormVersionSelectInterface> = ({ name, label, layout, disabled = false, customRules = [], hasDefaultLayout }) => {
    const scormVersions = {
        '1.0': 'SCORM 1.0',
        '1.1' : 'SCORM 1.1',
        '1.2' : 'SCORM 1.2',
        '1.3' : 'SCORM 2004'
      };
      
    const intl = useIntl();

    let formItemLayout = layout || {}

    if (hasDefaultLayout) {
        formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 }
        }
    }

    return (
        <Select
            customLayout={formItemLayout}
            name={name}
            label={intl.formatMessage({ id: label })}
            manualOptions={scormVersions}
            disabled={disabled}
            customRules={customRules}
        />
    )
}
export default ScormVersionSelect;
