import React, {useState, useEffect} from 'react';
import DefaultLayout from 'components/DefaultLayout';
import { useIntl, FormattedMessage } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import { Form, Select as AntDSelect, Radio, DatePicker, Checkbox, Modal, message, Row, Col, Button } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Select, Input } from 'components/Form';
import CRONBuilder from 'components/CRONBuilder';
import config from 'config/config';
import apiClient from 'utils/apiClient';
import moment from 'moment';
import coursesApiClient from 'utils/coursesApiClient';
import Spinner from 'components/Spinner';
import { connect } from 'react-redux';
import datepickerTranslations from 'shared/datepickerTranslations';
import './styles.scss';
import ScenarioUsersTable from './ScenarioUsersTable';
import ScenarioUserFilter from './ScenarioUserFilter';

const formItemPeriodLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 4 },
};


const mapStateToProps = (state: any) => {
  return {
    session: state.session,
    filterdOrganizationOptions: state.filterdOrganizationOptions,
    locale: state.locale,
    userRole: state.session.active_user_type
  }
}

const ScenariosForm: React.FC<any> = (props) => {
  const [CRONExpression, setCRONExpression] = useState<string|null>(null);
  const [initialCRONExpression, setInitialCRONExpression] = useState<string|null>(null);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [emailTemplateCategory, setEmailTemplateCategory] = useState<number|null>(null);
  const [emailTemplates, setEmailTemplates] = useState<any>([]);
  const [isEmailTemplatesLoading, setIsEmailTemplatesLoading] = useState(false);
  const [whenToSend, setWhenToSend] = useState(0);
  const [showModalSecond, setShowModalSecond] = useState(false);
  const [selectedUsersSecond, setSelectedUsersSecond] = useState<any>([]);
  const [selectedUserSecond, setSelectedUserSecond] = useState<any>(null);
  const [invalidFields, setInvalidFields] = useState<any>([]);
  const [isScenarioLoading, setIsScenarioLoading] = useState(false);
  const [templateName, setTemplateName] = useState<any>(null);
  const [categoryName, setCategoryName] = useState<any>(null);
  const [CCValue, setCCValue] = useState(false);
  const [iniPasswordReset, setIniPasswordReset] = useState(false);
  const [ownerReferenceId, setOwnerReferenceId] = useState();
  const [ownerType, setOwnerType] = useState('');
  const [ownerUsers, setOwnerUsers] = useState<any>([]);
  const [campaignList, setCampaignList] = useState([]);

  const [emailCategories, setEmailCategories] = useState();
  const [scenarioDescription, setScenarioDescription] = useState();
  const [selectedCampaign, setSelectedCampaign] = useState();

  const [resourceUrl, setResourceUrl] = useState<string>('');
  const [activeOrganizationId, setActiveOrganizationId] = useState<number>(0);
  const [activeOrganizationType, setActiveOrganizationType] = useState<any>(); // any replace
  const [users, setUsers] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [showUsersTable, setShowUsersTable] = useState(false);
  const [showUsersFilter, setShowUsersFilter] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [userFilter, setUserFilter] = useState<any>();

  const pageSizeOptions = [30, 50];

  const [currentPageSize, setCurrentPageSize] = useState<number>(pageSizeOptions[0]);

  const intl = useIntl();
  const [form] = Form.useForm();
  const [modal] = Form.useForm();
  const scenarioId = !props.isCampaign ? props.match.params.id : null;
  const isModal = props.isUserModal;

  useEffect(() => {
      if (!props.filterdOrganizationOptions.owner && props.session.active_user_type === 'SUPER_ADMIN') {
          props.history.push('/e-mailing/email-scenarios');
      }
    const loadEmailCategories = async () => {
      form.setFieldsValue({
        progress: ['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED']
      });
      let categoriesResponse = await apiClient.request(`/api/v1/mail-templates/mail-categories`, {}, 'GET');
      if (props.session.active_user_type !== 'SUPER_ADMIN') {
          Object.entries(categoriesResponse).map((el:any)=> {
            if (el[1] === 'system_events') {
              delete categoriesResponse[el[0]]
            }
            return categoriesResponse;
          })
      }
      setEmailCategories(categoriesResponse);
    }



    const loadEmailScenario = async () => {
      setIsScenarioLoading(true);
      let categoriesResponse = await apiClient.request(config.api.routes.backend.mailCategories);
      let mailScenarioResponse = await apiClient.request(config.api.routes.backend.mailScenarios + `/${scenarioId}`);
      let mailScenario = mailScenarioResponse ? mailScenarioResponse['mailScenario'] : {};
      let categoryName = mailScenario.description.split(':')[0];
      let mailDescription = mailScenario.description;
      let mailCategory = Object.entries(categoriesResponse).find((el: any) => el[1] === categoryName);  // TODO: Category should be in database
      let categoryId = Array.isArray(mailCategory) && mailCategory.length > 0 ? parseInt(mailCategory[0]) : null;
      let category = Array.isArray(mailCategory) && mailCategory.length > 0 ? mailCategory[1] : null;
      let activeRadio = 0;
      let filter = mailScenarioResponse.mailScenario.to
      let mailScenarioTo = {filter}
      let specificUsers = mailScenarioResponse.mailScenario.toSpecificUsers

      setActiveOrganizationId(mailScenario.ownerReferenceId);
      setActiveOrganizationType(mailScenario.ownerType);

      setScenarioDescription(mailDescription);
      setOwnerType(mailScenarioResponse.mailScenario.ownerType);

      if (filter || specificUsers) {
        let pageQuery = 'page[number]=' + 1 + '&page[size]=' + currentPageSize;

        getRecords(getResourceUrl(), pageQuery, mailScenarioTo);
        setUserFilter(mailScenarioTo);
      }

      if (mailScenario.cronExpression) {
        activeRadio = 2;
        setInitialCRONExpression(mailScenario.cronExpression);
        setCRONExpression(mailScenario.cronExpression);
      } else if (mailScenario.scheduleDate) {
        activeRadio = 1;
      }

      setEmailTemplateCategory(categoryId);
      setWhenToSend(activeRadio);
      setCCValue(mailScenario.cc ? true : false);
      setIniPasswordReset(mailScenario.iniPasswordReset ? true : false)
      setCategoryName(category);
      setSelectedCampaign(mailScenario.campaignId);
      form.setFieldsValue({
        name: mailScenario.name,
        category: categoryId,
        templateId: mailScenario.templateId,
        scheduleDate: moment(mailScenario.scheduleDate),
        period: [moment(mailScenario.startDate), moment(mailScenario.endDate)],
        emailLimit: mailScenario.emailLimit,
        campaignList: mailScenario.campaignId,
      });

      setIsScenarioLoading(false);
    };

    const loadCampaignList = async () => {
      let customer;
      if (props.filterdOrganizationOptions.owner && props.filterdOrganizationOptions.owner.ownerId) {
        customer = await apiClient.request(`/api/v1/customers/${props.filterdOrganizationOptions.owner.ownerId}`, {}, 'GET');
      }

      let campaignList = await coursesApiClient.request(`/api/v1/courses/customer/${customer ? customer.customer.uuid : props.session.organization.organization_uuid}/campaigns`, {}, 'GET');
      let campaignListValues:any = [];
      Object.values(campaignList.campaigns).map((value:any, index:any) => {
        campaignListValues[value['id']] = value['name'];
        return campaignListValues;
      })
      setCampaignList(campaignListValues)
    }


    if (props.filterdOrganizationOptions.owner) {
        setOwnerType(props.filterdOrganizationOptions.owner.owner);
    } else if (props.session.active_user_type !== 'SUPER_ADMIN') {
        setOwnerType(props.session.organization.organization_type);


        if (props.session.organization.organization_type === 'CUSTOMER') {
            modal.setFieldsValue({
                userTypes: ['CUSTOMER_ADMIN', 'STUDENT'],
                accountStatus: ['ACTIVE']
            });
        } else {
            modal.setFieldsValue({
                userTypes: ['ADMIN'],
                accountStatus: ['ACTIVE']
            });
        }
    }

      loadEmailCategories();
      loadCampaignList();

    if (scenarioId) {
      loadEmailScenario();
    }

    if (!scenarioId) {
      setActiveOrganizationId(props.session.organization.organization_id);
      setActiveOrganizationType(props.session.organization.organization_type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getResourceUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [activeOrganizationId, activeOrganizationType]);

const getResourceUrl = () => {
  let url = '';
  if (props.userRole === 'CUSTOMER_ADMIN' || props.userRole === 'SUPERVISION') {
    url = '/api/v1/auth/customer/users'
    setResourceUrl(url);
  } else {
    if (activeOrganizationType === 'CUSTOMER') {
        url = '/api/v1/auth/customer/users'
        setResourceUrl('/api/v1/auth/customer/users');
    } else {
        url = `/api/v1/auth/organizations/${activeOrganizationId}/users`
        setResourceUrl(`/api/v1/auth/organizations/${activeOrganizationId}/users`);
    }
  }

  return url;
}

const getRecords = async (resourceUrl: string, pageQuery: string, data: any = null) => {
  let query = (pageQuery !== '') ? '?' + pageQuery : '';

  let url = resourceUrl + query;

  if (!data) {
    data = {};
    if (userFilter) {
      data.filter = userFilter
    }
  }

  let response = await apiClient.request(url, data, 'POST');

  setUsers(response.users);
  setTotalRecords(response.pagination.total);

  return {
    data: (response.users) ? response.users : {},
    pagination: (response.pagination) ? response.pagination : {}
  }
};

  useEffect(() => {
    const loadEmailTemplates = async () => {
      setIsEmailTemplatesLoading(true);
      try {
        if (props.session.active_user_type === "SUPER_ADMIN"  ) {
        let optionsResponse;

        switch (props.filterdOrganizationOptions.owner.owner) {
          case 'DISTRIBUTOR':
          case 'RESELLER':
          case 'ORGANIZATION':
            modal.setFieldsValue({
              userTypes: ['ADMIN'],
              accountStatus: ['ACTIVE']
            });
            optionsResponse = await apiClient.request(`/api/v1/organizations/${props.filterdOrganizationOptions.owner.ownerId}/mail-templates/${emailTemplateCategory}/options`, 'GET');
            setEmailTemplates(optionsResponse.mailTemplates);


            setOwnerType(props.filterdOrganizationOptions.owner.owner);
            setOwnerReferenceId(props.filterdOrganizationOptions.owner.ownerId);
            break;

          case 'CUSTOMER':
            modal.setFieldsValue({
              userTypes: ['CUSTOMER_ADMIN', 'STUDENT'],
              accountStatus: ['ACTIVE']
            });
            optionsResponse = await apiClient.request(`/api/v1/customers/${props.filterdOrganizationOptions.owner.ownerId}/mail-templates/${emailTemplateCategory}/options`, 'GET');
            setEmailTemplates(optionsResponse.mailTemplates);

            setOwnerType(props.filterdOrganizationOptions.owner.owner);
            setOwnerReferenceId(props.filterdOrganizationOptions.owner.ownerId);

            break;

          default:
            break;
        }

        } else if (props.session.organization.organization_type === "ORGANIZATION") {
          modal.setFieldsValue({
            userTypes: ['ADMIN'],
            accountStatus: ['ACTIVE']
          });

          switch (props.session.active_user_type) {
            case 'DISTRIBUTOR_ADMIN':
              setOwnerType('DISTRIBUTOR')
              break;
            case 'RESELLER_ADMIN':
              setOwnerType('RESELLER')
              break;
            default:
              break;
          }

          let optionsResponse = await apiClient.request( `/api/v1/organizations/${props.session.organization.organization_id}/mail-templates/${emailTemplateCategory}/options`, 'GET');
          setEmailTemplates(optionsResponse.mailTemplates);

          setOwnerReferenceId(props.session.organization.organization_id);

        } else if (props.session.organization.organization_type === "CUSTOMER") {
          modal.setFieldsValue({
            userTypes: ['CUSTOMER_ADMIN', 'STUDENT'],
            accountStatus: ['ACTIVE']
          });

          let optionsResponse = await apiClient.request( `/api/v1/customers/${props.session.organization.organization_id}/mail-templates/${emailTemplateCategory}/options`, 'GET');
          setEmailTemplates(optionsResponse.mailTemplates);

          setOwnerReferenceId(props.session.organization.organization_id);
          setOwnerType('CUSTOMER')

        }
      } catch (error) {
        if (props.filterdOrganizationOptions.owner.owner === null && props.session.active_user_type === "SUPER_ADMIN"){
          message.error(intl.formatMessage({id: "error.owner_not_selected"}));
          console.error(error);
          props.history.push('/e-mailing/email-scenarios');
        } else {
          message.error(intl.formatMessage({id: "error.data_load"}));
        }
          console.error(error);
      } finally {
        setIsEmailTemplatesLoading(false);
      }
    };
    if (emailTemplateCategory) {
      loadEmailTemplates();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailTemplateCategory]);

  useEffect(() => {
    setInvalidFields([]);
  }, [CRONExpression, selectedUsersSecond]);

  const removeUserFromListSecond = (id: number) => {
    setSelectedUsersSecond((selectedUsersSecond: any) => selectedUsersSecond.filter((el: number) => el !== id));
  };

  const addUserToListSecond = () => {
    if (selectedUserSecond) {
      setSelectedUsersSecond((selectedUsersSecond: any) => [...selectedUsersSecond, selectedUserSecond]);
    }
    setSelectedUserSecond(null);
  }

  const loadUserOptions = async () => {

    if (!ownerUsers.length) {
      let organizationId;
      if (props.filterdOrganizationOptions.owner.owner) {
        organizationId = props.filterdOrganizationOptions.owner.ownerId;
        let usersResponse = await apiClient.request(`/api/v1/auth/organizations/${organizationId}/users-options`, 'GET');
        setOwnerUsers(usersResponse.usersOptions);
      } else {
        organizationId = props.session.organization.organization_id;
        let usersResponse = await apiClient.request(`/api/v1/auth/customers/${organizationId}/users-options`, 'GET');
        setOwnerUsers(usersResponse.usersOptions);
      }
    }
  }

  const submitForm = async (values: any) => {
    const formValid = validateForm();
    let description;
    if (templateName) {
      description = `${categoryName}: ${templateName}`
    } else {
      description = scenarioDescription
    }
    let campaignFilter;
    if (selectedCampaign) {
      campaignFilter = {
        userTypes: ['STUDENT'],
        options: {
          accountStatus: 'ACTIVE',
          campaign: {
            campaignId: values.campaignList,
            progress: values.progress
          }
        },
        campaign: {
          campaignId: values.campaignList,
          progress: values.progress
        }
      }
    }

    if (formValid) {
      try {
        setSubmitInProgress(true);
        let parsedValues:any

          parsedValues = {
            ...parsedValues,
            category: values.category,
            templateId: values.templateId,
            description: description,
            to: !selectedCampaign ? userFilter : campaignFilter,
            cc: CCValue ? 1 : 0,
            iniPasswordReset: iniPasswordReset ? 1 : 0,
            ownerType: ownerType,              // TODO: use value from session when its developed
            ownerReferenceId: ownerReferenceId,
            status: selectedUsersSecond.length > 0 ? 'INACTIVE': 'ACTIVE' ,
            scenarioType: 'CUSTOM',
            toRecipients: selectedUsersSecond,
            emailLimit: values.emailLimit,
            campaignId: values.campaignList,
            learningCampaign: !selectedCampaign ? false : true
        }

        switch(whenToSend) {
          case 0:
            parsedValues.sendEmailNow = true;
            parsedValues.startDate = moment().format('YYYY-MM-DD');
            parsedValues.endDate = moment().format('YYYY-MM-DD');

            break;
          case 1:
            parsedValues.sendEmailNow = false;
            parsedValues.startDate = moment().format('YYYY-MM-DD');
            parsedValues.endDate = moment().format('YYYY-MM-DD');
            parsedValues.scheduleDate = values.scheduleDate.format('YYYY-MM-DD HH:mm');
            break;
          case 2:
            parsedValues.sendEmailNow = false;
            parsedValues.startDate = values.period[0].format('YYYY-MM-DD');
            parsedValues.endDate = values.period[1].format('YYYY-MM-DD');
            parsedValues.cronExpression = CRONExpression;
            break;
        }

        let submitResponse

        if (scenarioId) {
          submitResponse = await apiClient.request(`/api/v1/mail-scenarios/${scenarioId}`, parsedValues, 'PUT');

        } else {
          submitResponse = await apiClient.request('/api/v1/mail-scenarios', parsedValues, 'POST');
        }

        if (submitResponse.status_code > 299) throw submitResponse.message;
        setSubmitInProgress(false);
        if (props.setModalVisible) {
          props.setModalVisible(false);
          props.setRefreshKey((oldKey:any) => oldKey +1);
        }
        message.success(scenarioId ? 'Email scenario updated successfully' : 'Email scenario successfully created');

        props.history.push('/e-mailing/email-scenarios');

      } catch (error) {
        message.error('Email scenario failed');

        props.history.push('/e-mailing/email-scenarios');

        if (props.setModalVisible) {
          props.setModalVisible(false);
          props.setRefreshKey((oldKey:any) => oldKey +1)
        }
        setSubmitInProgress(false);
      }
    }
  }

  const validateForm = () => {
    let formValid = true;

    if (whenToSend === 2 && !CRONExpression) {
      setInvalidFields((invalidFields: any) => [...invalidFields, 'CRONExpression'])
      formValid = false;
    }
    if (totalRecords === 0 && selectedUsersSecond.length === 0 && !selectedCampaign) {
      setInvalidFields((invalidFields: any) => [...invalidFields, 'whomToSend'])
      setInvalidFields((invalidFields: any) => [...invalidFields, 'createAsInactive'])
      formValid = false;
    }

    return formValid;
  }

  return (
    <>
        <Spinner spinning={submitInProgress || isScenarioLoading} opaque={isScenarioLoading}>
          <DefaultLayout.PageContent>
            <Form form={form} onFinish={submitForm} labelCol={{span: 8}} wrapperCol={{span: 6}}>
              <Select
                className = 'scenario-email-required'
                name='category'
                label={intl.formatMessage({id: "emailing.scenarios.e_mail_category"})}
                manualOptions={emailCategories}
                integerKey={true}
                customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
                customObjLabel={(el: string) => intl.formatMessage({id: `email_templates.categories.${el}`})}
                onChange={(value: number, {systemName}: any) => {setEmailTemplateCategory(value); setCategoryName(systemName); form.setFieldsValue({templateId: null})}}
                withSystemName={true}
              />
              <Form.Item
                className = 'scenario-email-required'
                name='templateId'
                label={intl.formatMessage({id: 'emailing.scenarios.email_template'})}
                help={emailTemplateCategory ? undefined : intl.formatMessage({id: 'emailing.scenarios.select_email_category'})}
                rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
              >
                <AntDSelect loading={isEmailTemplatesLoading} className={'default-select'} disabled={!emailTemplateCategory} onChange={(value: any, props: any) => {setTemplateName(props.children); }}>
                    {emailTemplates.filter((el: any) => el.category_id === emailTemplateCategory).map((el: any) =>
                      <AntDSelect.Option value={parseInt(el.id)}>
                        {el.name_transaltions ?
                         (el.name_transaltions[props.locale.locale] ? el.name_transaltions[props.locale.locale] : Object.values(el.name_transaltions)[0]) :
                          el.name[props.locale.locale] ? el.name[props.locale.locale] : Object.values(el.name)[0]}
                        </AntDSelect.Option>
                    )}
                </AntDSelect>
              </Form.Item>
              {
                campaignList.length ?
                  <Select
                    name="campaignList"
                    label={intl.formatMessage({id: 'campaign.email_campaign'})}
                    onChange={(value: any) => {form.setFieldsValue({value}); setSelectedCampaign(value);}}
                    manualOptions= {campaignList}
                    allowClear
                    integerKey={true}
                  />
                : null
              }

              {
                selectedCampaign ?
                  <Form.Item name="progress" label={intl.formatMessage({id: 'general.progress'})}>
                    <Checkbox.Group onChange={() => invalidFields.includes('whomToSend') ? false : modal.submit()} className='campaign-progress-checkbox'>
                      <Checkbox defaultChecked={true} value="NOT_STARTED" style={{ lineHeight: '32px' }}><FormattedMessage id='general.not_started'/></Checkbox>
                      <Checkbox defaultChecked={true} value="IN_PROGRESS" style={{ lineHeight: '32px' }}><FormattedMessage id='general.in_progress'/></Checkbox>
                      <Checkbox defaultChecked={true} value="COMPLETED" style={{ lineHeight: '32px' }}><FormattedMessage id='general.completed'/></Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                : null
              }
              <Form.Item label={intl.formatMessage({id: 'emailing.scenarios.when_to_send'})}>
                <Radio.Group onChange={(el) => setWhenToSend(el.target.value)} value={whenToSend}>
                  <Radio value={0} className='when-to-send'><FormattedMessage id='emailing.scenarios.now' /></Radio>
                  <Radio value={1} className='when-to-send'><FormattedMessage id='emailing.scenarios.on_a_specific_time' /></Radio>
                  <Radio value={2} className='when-to-send'><FormattedMessage id='emailing.scenarios.recurring' /></Radio>
                </Radio.Group>
              </Form.Item>
              {
                whenToSend === 1
                  ? <Form.Item label={intl.formatMessage({id: 'emailing.scenarios.on_a_specific_time'})} name='scheduleDate' rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}>
                      <DatePicker
                        format="YYYY-MM-DD HH:mm"
                        disabledDate={(current: any) => current && current < moment().subtract(1, 'day').endOf('day')}
                        showTime={{ defaultValue: moment('00:00:00', 'HH:mm') }}
                        minuteStep={60}
                      />
                    </Form.Item>
                  : null
              }
              {
                whenToSend === 2
                  ?
                    <>
                      <Form.Item
                        label={intl.formatMessage({id: 'emailing.scenarios.recurring'})}
                        validateStatus={invalidFields.includes('CRONExpression') ? 'error' : undefined}
                        help={invalidFields.includes('CRONExpression') ? intl.formatMessage({id: 'please_fill_all_reccurrance_fields'}): undefined}
                      >
                        <CRONBuilder
                          onChange={(value: string|null) => setCRONExpression(value)}
                          initialValue={initialCRONExpression}
                        />
                      </Form.Item>
                      <Form.Item {...formItemPeriodLayout} name='period' label={intl.formatMessage({id: 'emailing.scenarios.period'})} rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]} >
                        <DatePicker.RangePicker
                          format="YYYY-MM-DD"
                          disabledDate={(current: any) => current && current < moment().subtract(1, 'day').endOf('day')}
                          locale={datepickerTranslations(intl)}
                        />
                      </Form.Item>
                    </>
                  : null
              }
              {
                !selectedCampaign ?
                <>
                  <Form.Item
                    name='whomToSend'
                    label={intl.formatMessage({id: 'emailing.scenarios.whom_to_send'})}
                    validateStatus={invalidFields.includes('whomToSend') ? 'error' : undefined}
                    help={invalidFields.includes('whomToSend') ? intl.formatMessage({id: 'select_at_least_1_user'}): undefined}
                    style={{marginBottom:3}}
                  >
                    <Button type="primary"onClick={() => setShowUsersFilter(true)}> <FormattedMessage id={'emailing.scenarios.select_users'} /> </Button>
                  </Form.Item>
                  <div style={{marginLeft:'33%', maxWidth:'25%', marginBottom:10, textDecoration:'underline'}} onClick={()=> setShowUsersTable(true)}>
                    {totalRecords} <FormattedMessage id='emailing.scenarios.users_selected'/>
                  </div>
                </>
                : null
              }
              <Form.Item
                name='createAsInactive'
                label={intl.formatMessage({id: 'emailing.scenarios.create_as_inactive_scenario_and_send_test_email_to'})}
                validateStatus={invalidFields.includes('createAsInactive') ? 'error' : undefined}
                help={invalidFields.includes('createAsInactive') ? intl.formatMessage({id: 'select_at_least_1_user'}): undefined}
                style={isModal !== true ? {display: ''} : { display: 'none'}}
              >
                <Button type="primary" onClick={() => {loadUserOptions(); setShowModalSecond(true)}}><FormattedMessage id={'emailing.scenarios.select_users'} /></Button>
              </Form.Item>
              <Form.Item name='cc' label={intl.formatMessage({id: 'emailing.scenarios.copy_cc_direct_manager'})}>
                <Checkbox checked={CCValue} onChange={() => setCCValue(!CCValue)} />
              </Form.Item>
              <Form.Item name='iniPasswordReset' label={intl.formatMessage({id: 'emailing.scenarios.initialize_password_reset'})}>
                <Checkbox checked={iniPasswordReset} onChange={() => setIniPasswordReset(!iniPasswordReset)} />
              </Form.Item>
              <Input
                type='text'
                name='emailLimit'
                label={intl.formatMessage({id: 'emailing.scenarios.maximum_number_of_emails_to_be_sent_in_one_hour'})}
                style={{marginBottom:'10px'}}
              >
              </Input>
              <div
                style={{marginLeft:'33%',marginBottom:10}}
              >
              </div>
              <div className="form-buttons" style={{textAlign: "right"}} >
                <NavLink to={'/e-mailing/email-scenarios'}>
                  <Button className='outlined-button' style={{margin: 8}}  >
                    <FormattedMessage id="general.back" />
                  </Button>
                </NavLink>
                <Button type="primary" loading={submitInProgress} onClick={() => form.submit()}>
                  <FormattedMessage id="general.submit" />
                </Button>
              </div>
            </Form>
          </DefaultLayout.PageContent>
        </Spinner>
      <Modal
        title={intl.formatMessage({id: 'emailing.scenarios.select_users'})}
        visible={showModalSecond}
        onCancel={() => setShowModalSecond(false)}
        onOk={() => setShowModalSecond(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: {backgroundColor: '#19A9CE', border: 0, borderRadius: 8} }}
      >
        <Row>
          <Col span={20}>
            <AntDSelect
              showSearch
              showArrow
              style={{width: '100%'}}
              filterOption={(input: string, option: any) => {
                return option.children.join('').toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              value={selectedUserSecond}
              onChange={(value) => setSelectedUserSecond(value)}
            >
              {ownerUsers ? ownerUsers.filter((el: any) => !selectedUsersSecond.includes(el.userTypeId)).map((el: any) =>
                <AntDSelect.Option value={el.userTypeId}>{el.name} {el.surname} ({el.email})</AntDSelect.Option>
              ) :null}
            </AntDSelect>
          </Col>
          <Col span={4} style={{textAlign: 'right'}}>
            <Button
              type="primary"
              onClick={() => addUserToListSecond()}
            >
              <PlusOutlined/> <FormattedMessage id="general.add" />
            </Button>
          </Col>
        </Row>
        <Row style={{marginTop: 15}}>
          <FormattedMessage id={'emailing.scenarios.selected_users'}/>:
          <div style={{width: '100%', height: 150, overflowY: 'scroll', border: '1px solid #e6e6e6'}}>
            {ownerUsers ? ownerUsers.filter((el: any) => selectedUsersSecond.includes(el.userTypeId)).map((el: any) =>
              <Row>
                <Col span={21} offset={1}>
                  {el.name} {el.surname} ({el.email})
                </Col>
                <Col span={2}>
                  <DeleteOutlined onClick={() => removeUserFromListSecond(el.userTypeId)}/>
                </Col>
              </Row>
            ) : null}
          </div>
        </Row>
      </Modal>
      <ScenarioUsersTable 
        resourceUrl={resourceUrl}
        users={users}
        setUsers={setUsers}
        visible={showUsersTable}
        onCancel={() => setShowUsersTable(false)}
        pageSizeOptions={pageSizeOptions}
        setCurrentPageSize={setCurrentPageSize}
        currentPageSize={currentPageSize}
        totalRecords={totalRecords}
        setTotalRecords={setTotalRecords}
        setCurrentPage={setCurrentPage}
        getRecords={getRecords}
        currentPage={currentPage}
        userFilter={userFilter}
      />
      <ScenarioUserFilter 
        activeOrganizationId={activeOrganizationId}
        activeOrganizationType={activeOrganizationType}
        setUsers={setUsers}
        visible={showUsersFilter}
        onCancel={() => setShowUsersFilter(false)}
        currentPageSize={currentPageSize}
        setCurrentPage={setCurrentPage}
        setTotalRecords={setTotalRecords}
        setUserFilter={setUserFilter}
        userFilter={userFilter}
      />
    </>
  )
}

export default connect(mapStateToProps)(withRouter(ScenariosForm));
