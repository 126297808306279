import React, {useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Modal, message, Space, Button} from 'antd';
import {ExclamationCircleOutlined, InfoCircleOutlined} from '@ant-design/icons';
import apiClient from "utils/apiClient";
import UsersMailScenarioModal from "../UsersMailScenarioModal";
import {UserRequestPropertiesType, UsersListFooterInterface} from '../types';
import './styles.scss';
import EnrollUsersIntoCampaignModal from '../EnrollUsersIntoCampaignModal';

const UsersListFooter: React.FC<UsersListFooterInterface> = ({
                                            selectedRowsLength,
                                            rowSelectedUsers,
                                            activeOrganizationType,
                                            activeOrganizationId,
                                            activeOrganizationUuid,
                                            rowSelection,
                                            setReloadUsers
                                        }) => {
    const [showMailModal, setShowMailModal] = useState(false);
    const [showCampaignAppendModal, setShowCampaignAppendModal] = useState(false);

    const intl = useIntl();
    const {confirm, info} = Modal;

    const showConfirm = (value: boolean) => {
        if (value) {
            confirm({
                content: intl.formatMessage({id: 'users.activate_users'}, {amount: selectedRowsLength}),
                icon: <ExclamationCircleOutlined/>,
                okText: intl.formatMessage({id: 'general.continue'}),
                cancelText: intl.formatMessage({id: 'general.cancel'}),
                onOk() {
                    activateUsers();
                },
            });
        } else {
            confirm({
                content: intl.formatMessage({id: 'users.block_users'}, {amount: selectedRowsLength}),
                icon: <ExclamationCircleOutlined/>,
                okText: intl.formatMessage({id: 'general.continue'}),
                cancelText: intl.formatMessage({id: 'general.cancel'}),
                onOk() {
                    blockUsers();
                },
            });
        }
    };

    const activateUsers = async () => {
        let parsedValues : UserRequestPropertiesType = rowSelection;

        parsedValues.organizationType = activeOrganizationType;
        parsedValues.organizationId = activeOrganizationId;

        if (parsedValues.filter && parsedValues.filter.length === 0) {
            parsedValues.filter = null;
        }

        try {

            let submitResponse = await apiClient.request('/api/v1/users/mass-activate', parsedValues, 'POST');

            info({
                content: intl.formatMessage({id: 'users.activate_users_info'}, {amount: selectedRowsLength}),
                icon: <InfoCircleOutlined />,
                cancelText: intl.formatMessage({id: 'general.close'})
            });

            if (!submitResponse) {
                return "max_users_limit_exceeded"
            }

            if (submitResponse.status_code > 299) return "submit error";
            message.success(intl.formatMessage({id: "users.users_activated_sucessfully"}));
        } catch (error) {
            let errorMessage = "general.submit_error";

            if (error === 'max_users_limit_exceeded') {
                errorMessage = 'error.' + error
            }

            console.error(error);
            message.error(intl.formatMessage({id: errorMessage}));
        }
    };

    const blockUsers = async () => {

        let parsedValues: UserRequestPropertiesType = rowSelection;

        parsedValues.organizationType = activeOrganizationType;
        parsedValues.organizationId = activeOrganizationId;

        if (parsedValues.filter && parsedValues.filter.length === 0) {
            parsedValues.filter = null;
        }

        try {
            await apiClient.request('/api/v1/users/mass-block', parsedValues, 'POST');

            info({
                content: intl.formatMessage({id: 'users.block_users_info'}, {amount: selectedRowsLength}),
                icon: <InfoCircleOutlined />,
                cancelText: intl.formatMessage({id: 'general.close'})
            });

            message.success(intl.formatMessage({id: "users.users_blocked_sucessfully"}));
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: "general.submit_error"}));    // TODO: Izmantot notification
        }
    };

    const showConfirmDelete = () => {

        if (!((rowSelection.selectedAllUsers && rowSelection.filter) || (!rowSelection.selectedAllUsers && rowSelection.selectedUsers.length))) {

            Modal.warning({
                title: intl.formatMessage({id: 'general.attention'}),
                content: intl.formatMessage({id: 'users.no_users_selected'})
            });

        } else {
            if (!validDeletionUsersSelection(rowSelectedUsers)) {
                return;
            }

            confirm({
                title: intl.formatMessage({id: 'general.attention'}),
                content: intl.formatMessage({id: 'users.confirm_delete'}),
                icon: <ExclamationCircleOutlined/>,
                okText: intl.formatMessage({id: 'general.continue'}),
                cancelText: intl.formatMessage({id: 'general.cancel'}),

                onOk() {
                    initiateUsersDelete();
                },
            });
        }
    };

    const initiateUsersDelete = async () => {
        let parsedValues: UserRequestPropertiesType = rowSelection;

        parsedValues.organizationType = activeOrganizationType;
        parsedValues.organizationId = activeOrganizationId;

        if (parsedValues?.filter.length === 0) {
            parsedValues.filter = null;
        }

        try {
            let massDeleteRequestUrl = activeOrganizationType === 'CUSTOMER'
                ? '/api/v1/customer-users/mass-delete'
                : '/api/v1/admins/mass-delete';

            let submitResponse = await apiClient.request(massDeleteRequestUrl, parsedValues, 'POST');

            if (submitResponse.status_code > 299) return 'submit error';

            let info1 = intl.formatMessage({id: 'users.after_initiate_delete_info_1'});
            let info2 = intl.formatMessage({id: 'users.after_initiate_delete_info_2'});

            Modal.success({
                content: <>
                    <p>{info1}</p>
                    <p>{info2}</p>
                </>
            });
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'general.submit_error'}));
        } finally {
            setReloadUsers(true);
        }
    };

    const validDeletionUsersSelection = (users: any) => {
        let result = true;

        Object(users).forEach(function (user: any) {
            if (user.deletionInitiatedAt !== null) {
                result = false
            }
        });

        if (!result) {
            Modal.warning({
                title: intl.formatMessage({id: "general.attention"}),
                content: intl.formatMessage({id: "users.no_correct_selection_for_deletion"})
            });
        }

        return result;
    };

    const showConfirmUndelete = () => {

        if (!((rowSelection.selectedAllUsers && rowSelection.filter) || (!rowSelection.selectedAllUsers && rowSelection.selectedUsers.length))) {

            Modal.warning({
                title: intl.formatMessage({id: "general.attention"}),
                content: intl.formatMessage({id: 'users.no_users_selected'})
            });
        } else {
            if (!validUndeletionUsersSelection(rowSelectedUsers)) {
                return
            }

            undeleteUsers();
        }
    };

    const undeleteUsers = async () => {
        let parsedValues: UserRequestPropertiesType = rowSelection;

        parsedValues.organizationType = activeOrganizationType;
        parsedValues.organizationId = activeOrganizationId;

        if (parsedValues.filter && parsedValues.filter.length === 0) {
            parsedValues.filter = null;
        }

        try {
            let massUndeleteRequestUrl = activeOrganizationType === 'CUSTOMER'
                ? `/api/v1/customer-users/mass-undelete`
                : `/api/v1/admins/mass-undelete`;

            let submitResponse = await apiClient.request(massUndeleteRequestUrl, parsedValues, 'POST');

            if (submitResponse.status_code > 299) return "submit error";

            message.success(intl.formatMessage({id: "users.undelete_success"}))
        } catch (error) {
            let errorMessage = "general.submit_error";
            console.error(error);
            message.error(intl.formatMessage({id: errorMessage}));
        } finally {
            setReloadUsers(true);
        }
    };

    const validUndeletionUsersSelection = (users: any) => {
        let result = true;

        Object(users).forEach(function (user: any) {
            if (user.deletionInitiatedAt === null) {
                result = false
            }
        });

        if (!result) {
            Modal.warning({
                title: intl.formatMessage({id: "general.attention"}),
                content: intl.formatMessage({id: "users.no_correct_selection_for_undeletion"})
            });
        }

        return result;
    };

    const checkSelectedOrganization = () => {
        if (rowSelectedUsers.length > 0) {
            setShowMailModal(true);
        } else {
            message.error(intl.formatMessage({id: "error.select_users"}));
        }
    };

    return (
        <>
            <Space size='small' className='users-list-footer'>
                <div>
                    {selectedRowsLength}
                    <FormattedMessage id='users.rows_selected'/>
                </div>
                <Button onClick={() => checkSelectedOrganization()}>
                    <i className="fal fa-envelope"/>
                    <FormattedMessage id='users.send_email_users'/>
                </Button>
                <Button onClick={() => showConfirm(true)}>
                    <i style={{color: '#52C419'}} className="fal fa-check-circle"/>
                    <FormattedMessage id='general.activate'/>
                </Button>
                <Button onClick={() => showConfirm(false)}>
                    <i style={{color: '#F5222D'}} className="fal fa-times-circle"/>
                    <FormattedMessage id='general.block'/>
                </Button>
                <Button onClick={() => showConfirmDelete()}>
                    <i style={{color: '#F5222D'}} className="fal fa-trash"/>
                    <FormattedMessage id='users.delete'/>
                </Button>
                <Button onClick={() => showConfirmUndelete()}>
                    <i style={{color: '#F5222D'}} className="fal fa-trash-restore"/>
                    <FormattedMessage id='users.undelete'/>
                </Button>
                <Button onClick={() => setShowCampaignAppendModal(true)}>
                    <FormattedMessage id='users.add_users_to_campaigns'/>
                </Button>
            </Space>

            <UsersMailScenarioModal
                activeOrganizationId={activeOrganizationId}
                activeOrganizationUuid={activeOrganizationUuid}
                activeOrganizationType={activeOrganizationType}
                rowSelection={rowSelection}
                visible={showMailModal}
                onCancel={() => setShowMailModal(false)}
                afterSubmit={() => setShowMailModal(false)}
            />

            <EnrollUsersIntoCampaignModal 
                visible={showCampaignAppendModal}
                onCancel={() => setShowCampaignAppendModal(false)}
                rowSelection={rowSelection}
                organizationType={activeOrganizationType}
                uuid={activeOrganizationUuid}
                id={activeOrganizationId}
            />
        </>
    );
};

export default UsersListFooter;
