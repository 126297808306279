import React from 'react';
import { Card, Col, Row } from 'antd';
import StatisticsDownload from './StatisticsDownload';
import EnrolledStatisticsChart from './Charts/EnrolledStatisticsChart';
import TotalTimeSpentChart from './Charts/TotalTimeSpentChart';
import OrganizationalUnitChart from './Charts/OrganizationalUnitChart';
import CompletionOverTimeChart from './Charts/CompletionOverTimeChart';
import TestScoreOverviewChart from './Charts/TestScoreOverviewChart';
import TopStudentTable from './Charts/TopStudentTable';
import {UserRoleType}from 'components/types';
import './style.scss'

interface CampaignOverviewProps {
  campaignId: number;
  role: UserRoleType;
}

const CampaignOverview: React.FC<CampaignOverviewProps> = ({ campaignId, role }) => (
  <div>
      {role !== 'SUPERVISION' &&
          <StatisticsDownload campaignId={campaignId} />
      }

    <Row gutter={[16, 16]}>
      <Col span={12}>

        <Card size="small" className="chart-card">
          <EnrolledStatisticsChart campaignId={campaignId} />
        </Card>

        <Card size="small" className="chart-card adaptive">
          <OrganizationalUnitChart campaignId={campaignId} />
        </Card>

        <Card size="small" className="chart-card">
          <TestScoreOverviewChart campaignId={campaignId} />
        </Card>
      </Col>
      <Col span={12}>

        <Card size="small" className="chart-card">
          <TotalTimeSpentChart campaignId={campaignId} />
        </Card>

        <Card size="small" className="chart-card">
          <CompletionOverTimeChart campaignId={campaignId} />
        </Card>

        <Card size="small" className="chart-card">
          <TopStudentTable campaignId={campaignId} />
        </Card>
      </Col>
    </Row>
  </div>
);

export default CampaignOverview;
