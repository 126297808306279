import React from 'react';
import { Spin } from 'antd';
import { defaults, Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import '../styles.scss';

// EXAMPLE DATA
// const data = {
//   labels: ['Estimated time to complete', 'Actual logged time'],
//   datasets: [
//     {
//       data: [1342, 753],
//       backgroundColor: ['rgb(102, 148, 225)', 'rgb(30, 209, 161)'],
//       hoverBackgroundColor: ['rgb(82, 128, 205)', 'rgb(10, 189, 141)'],
//     }
//   ]
// }

interface BarChartProps {
  data: any,
  title?: string,
  barPercentage?: number,
  dataLabelAppend?: string,
  loading?: boolean,
}

const BarChart: React.FC<BarChartProps> = ({
  data, title, barPercentage = 0.5, dataLabelAppend, loading = false
}) => {
  defaults.global.defaultFontFamily = 'Gilroy';

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: (tooltip: any) => (Math.round(tooltip.yLabel * 10) / 10),
      },
    },
    scales: {
      xAxes: [{
        barPercentage: barPercentage,
        gridLines: {
          display: false,
        },
        ticks: {
          fontColor: 'black',
        },
      }],
      yAxes: [{
        gridLines: {
          lineWidth: 0,
          zeroLineColor: 'rgba(0, 0, 0, .15)',
          zeroLineWidth: 1,
        },
        ticks: {
          display: false,
          suggestedMax: (Math.max(...((data.datasets && data.datasets[0]) ? data.datasets[0].data : [])) || 1) * 1.25,
          beginAtZero: true,
        },
      }]
    },
    plugins: {
      datalabels: {
        color: 'black',
        anchor: 'end',
        align: 'end',
        font: {
          weight: 'bold',
          size: 16,
        },
        formatter: (value: number) => `${Math.round(value * 10) / 10}${dataLabelAppend || ''}`
      }
    }
  }

  return (
    <>
      {title && <p className="chart-title">{title}</p>}
      <Spin spinning={loading || false}>
        <Bar
          data={data}
          options={options}
        />
      </Spin>
    </>
  );
}

export default BarChart;
