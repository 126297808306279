import React from 'react';
import { Card } from 'antd';
import Buttons from './components/Buttons';
import List from './components/List';
import styles from './styles.module.scss';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import FormattedMessage from 'react-intl/lib/components/message';

interface ActivityCardProps {
  activity: any,
  bordered?: boolean,
  preview?: boolean,
  campaignData: any,
  setResourceData?: any;
  history?: any;
  session?: any;
  resource?: { campaignId: number, enrollmentId: number };
  attempt?: any;
}

const mapStateToProps = (state: any) => {
  return {
    session: state.session,
  };
};

const ActivityCard: React.FC<ActivityCardProps & RouteComponentProps> = ({ activity, bordered = false, preview = false, campaignData = [], setResourceData, history, session, resource, attempt }) => {

  const getColor = () => {
    switch (activity.assignmentStatus) {
      case 'COMPLETED':
      case 'FAILED':
        return '#379C37'
      case 'SUBMITTED':
        return '#428bdf'
      case 'NOT_SUBMITTED':
        return ''
    }
  }

  return (
    <Card className={styles.card} bordered={bordered}>
      <div className={styles.container}>
        <List activity={activity} attempt={attempt} />
        <Buttons activity={activity} campaignData={campaignData} preview={preview} setResourceData={setResourceData} />
      </div>
      {(activity.hasLearningFile || activity.hasTest) && activity.hasAssignment &&
        <div className={styles.materialsWrapper}>
          {(activity.hasLearningFile || activity.hasTest) &&
            <div className={styles.materialDisplay} style={{ display: activity.hasMaterials ? '' : 'none' }} >
              <i style={{ color: activity.learningActivity ? '#379C37' : '' }} className="fa-solid fa-check"></i><FormattedMessage id='courses.learning_activity' />
            </div>
          }
          <div className={styles.materialDisplay} style={{ display: activity.hasMaterials ? '' : 'none' }}  >
            <i style={{ color: getColor() }} className="fa-solid fa-check"></i><FormattedMessage id='courses.individual_work' />
          </div>
        </div>
      }
    </Card>
  );
};

export default connect(mapStateToProps)(withRouter(ActivityCard));