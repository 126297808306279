import * as ActionTypes from 'store/actionTypes';

export const changeCampaignData = ({campaignTitle, includedCourses, includedActivities, customerId, campaignId, customerUuid, cameFromCampaign, cameFromTemplate, cameFromCampaignEdit, highestTab, currentStep, courseScenario, certificationValidityTerm}:any) => ({
  type: ActionTypes.SAVE_CAMPAIGN_FORM,

  payload: {
    campaignTitle: campaignTitle,
    includedCourses: includedCourses,
    includedActivities:includedActivities,
    customerId: customerId,
    campaignId: campaignId,
    customerUuid: customerUuid,
    cameFromCampaign: cameFromCampaign,
    cameFromTemplate: cameFromTemplate,
    cameFromCampaignEdit: cameFromCampaignEdit,
    highestTab: highestTab,
    currentStep: currentStep,
    courseScenario: courseScenario,
    certificationValidityTerm: certificationValidityTerm
  }
});

export const clearCampaignData = () => ({
  type: ActionTypes.CLEAR_CAMPAIGN_DATA,
});
