import * as ActionTypes from 'store/actionTypes';

export const changeCampaignCustomerFilter = ({ customerId, customerUuid }:any) => ({
  type: ActionTypes.SAVE_CAMPAIGN_CUSTOMER_FILTER,

  payload: {
    customerId: customerId,
    customerUuid: customerUuid
  }
});
