import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import VideoInstructionsCreateModal from '../VideoInstructionsCreateModal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {connect} from 'react-redux';
import apiClient from "utils/apiClient";
import {message, Modal} from 'antd';
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    }
};

const VideoInstruction = ({visible, onCancel, videoCode, id, session, positions, reloadInstructions, setCode, reset = true}: any) => {
    const [showCreateInstructionModal, setShowCreateInstructionModal] = useState(false);

    const userRole = session.active_user_type;
    const { confirm } = Modal;
    const intl = useIntl();

    const showConfirmDelete = () => {
        confirm({
            title: intl.formatMessage({id: 'general.attention'}),
            content: intl.formatMessage({id: 'system.confirm_delete_instruction'}),
            icon: <ExclamationCircleOutlined/>,
            okText: intl.formatMessage({id: 'general.continue'}),
            cancelText: intl.formatMessage({id: 'general.cancel'}),

            onOk() {
                deleteInstruction(id);
            },
        });
    };


    const deleteInstruction = async (id: number) => {
        try {
            onCancel();
            await apiClient.request(`/api/v1/instruction/${id}/delete`, [], 'DELETE');
            reloadInstructions();
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        }
    };

    return (
        <>
            <Modal
                wrapClassName='video-modal-wrapper'
                className='video-instruction-modal'
                visible={visible}
                onCancel={onCancel}
                afterClose={() => reset ? setCode(null) : null}
                footer={null}
            >
                {videoCode &&
                    <div className='videoWrapper'>
                        <iframe
                            className='video-instruction-iframe'
                            src={`https://www.youtube.com/embed/${videoCode}`}
                            title='YouTube video player' frameBorder='0'
                            allow='accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                        />
                    </div>
                }
                {(userRole === 'DISTRIBUTOR_ADMIN' || userRole === 'SUPER_ADMIN') && id ?
                    <>
                        <i onClick={() => setShowCreateInstructionModal(true)} style={{cursor: 'pointer'}}
                           className='far fa-ellipsis-v'></i>
                        <i onClick={()=> showConfirmDelete()} style={{cursor: 'pointer'}} className='fal fa-trash'></i>
                    </>
                    :
                    null
                }

            </Modal>
            {id &&
                <VideoInstructionsCreateModal
                    visible={showCreateInstructionModal}
                    onCancel={() => setShowCreateInstructionModal(false)}
                    afterSubmit={() => setShowCreateInstructionModal(false)}
                    instructionId={id}
                    positions={positions}
                    reloadInstructions={reloadInstructions}
                />
            }
        </>
    )
};

export default connect(mapStateToProps)(VideoInstruction);
