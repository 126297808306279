import React from 'react';
import { Form } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import config from 'config/config';

interface RichTextEditorInterface {
    label: string;
    name: string;
    layout?: { labelCol?: { span: number }, wrapperCol?: { span: number } };
    hasDefaultLayout?: boolean;
}

const RichTextEditor: React.FC<RichTextEditorInterface> = ({ layout, label, name, hasDefaultLayout }) => {

    let formItemLayout = layout || {}

    if (hasDefaultLayout) {
        formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 }
        }
    }

    return (
        <Form.Item {...formItemLayout} name={name} label={label}>
            <Editor
                apiKey={config.api.tinymceApiKey}
                init={{
                    branding: false,
                    height: 320,
                    menubar: false,
                    resize: false,
                    relative_urls : true,
                    remove_script_host : true,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code | help'
                }}
            />
        </Form.Item>
    )
};

export default RichTextEditor;
