import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Menu, Layout, Spin, message } from 'antd';
import { navigation, openedMenuItem, openedSubMenu } from 'shared/navigation';
import { connect } from 'react-redux';
import apiClient from 'utils/apiClient';

const { Sider } = Layout;
const { SubMenu } = Menu;

interface subItemProps {
  key: string;
  url: string;
  title: string;
  permissionSlug?: string;
}

const mapStateToProps = (state: any) => {
  return {
    session: state.session,
    appearanceSettingsLoading: state.appearanceSettings.loading,
    title: state.appearanceSettings.title,
    logoPath: state.appearanceSettings.logoPath,
  }
}

const Sidebar: React.FC<any> = ({ collapsed, setCollapsed, session, appearanceSettingsLoading, title, logoPath }) => {
  const [logo, setLogo] = useState();
  const [logoIsLoading, setLogoIsLoading] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    const loadLogo = async () => {
      try {
        setLogoIsLoading(true);

        const response = await apiClient.request(`api/v1${logoPath}`, {}, 'GET', true, true);

        setLogo(response);
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
        setLogoIsLoading(false);
      }
    };

    if (logoPath) {
      loadLogo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoPath]);

  let navigationItems = navigation;

  if (!session.loading) {
    // Filter main navigation items
    navigationItems = navigationItems.filter((el: any) => {
      if (el.permissionSlug) {
        var permissionSlugs = el.permissionSlug.split(',');

        for (const permission of permissionSlugs) {
          if (el.key === 'sub1') {
            if (session.permissions.includes('view_manage_campaigns')) {
              return false;
            }
          }
          if (session.permissions && session.permissions.includes(permission)) {
            return true;
          }
        }

        return false;
      } else if (el.specRights) {
          let specialSlugs = el.specRights.split(',');

          for (const permission of specialSlugs) {
              if (session.special_rights && session.special_rights.includes(permission)) {
                  return true;
              }
          }
          return false;

      } else {
          return true;
      }

    });

    // Filter subNav items
    navigationItems.forEach((el: any, index: number) => {
      if (el.subNav) {
        navigationItems[index].subNav = el.subNav.filter((subItem: any) => {
          if (subItem.permissionSlug) {
            var permissionSlugs = subItem.permissionSlug.split(',');

            for (const permission of permissionSlugs) {

              if (subItem.specRights) {
                let specialSlugs = subItem.specRights.split(',');

                for (const specPermission of specialSlugs) {
                  if (((session.permissions && session.permissions.includes(permission))) && (session.special_rights && session.special_rights.includes(specPermission))) {
                    return true;
                  }
                }

              } else {
                if (session.permissions && session.permissions.includes(permission)) {
                  return true;
                }
              }
            }

            return false;
          } else {
            return true;
          }
        })
      } else {
        return true;
      }
    })
  }

  const MenuHeader = () => {
    if (collapsed) {
      return <i className="fal fa-bars" onClick={() => setCollapsed(!collapsed)} />
    }

    return <>
      <div style={{flex: 11}}>
        <NavLink to='/'>
          <Spin spinning={appearanceSettingsLoading || logoIsLoading} size="small">
            {logoPath === null
              ? <img src="/cs-logo.svg" alt={title} />
              : <img src={logo ? URL.createObjectURL(logo) : undefined} alt={title} />
            }
          </Spin>
        </NavLink>
      </div>
      <i className="fal fa-bars expanded" onClick={() => setCollapsed(!collapsed)} />
    </>
  }

  return (
    <Sider collapsible collapsed={collapsed} trigger={null} width={220} className="app-sidebar">
      <div className={`menu-header ${collapsed ? 'collapsed' : ''}`}>
        <MenuHeader />
      </div>
      <Menu theme="dark" defaultSelectedKeys={openedMenuItem ? [openedMenuItem.key] : ["1"]} defaultOpenKeys={openedSubMenu ? [openedSubMenu.key] : []} mode="inline">
        {
          navigationItems.map((item: any) => {
            if (item.subNav) {
              return (
                <SubMenu
                  key={item.key}
                  title={
                    <span>
                      <span className="anticon">
                        <i className={item.icon} />
                      </span>
                      <span>
                        <FormattedMessage id={item.title} />
                      </span>
                    </span>
                  }
                >
                  {
                    item.subNav.map((subItem: subItemProps) => {
                      return (
                        <Menu.Item key={subItem.key}>
                          <NavLink to={subItem.url}>
                            <FormattedMessage id={subItem.title} />
                          </NavLink>
                        </Menu.Item>
                      )
                    })
                  }
                </SubMenu>
              )
            }

            return <Menu.Item key={item.key}>
              <NavLink to={item.url || "/"}>
                <span className="anticon">
                  <i className={item.icon} />
                </span>
                <span>
                  <FormattedMessage id={item.title} />
                </span>
              </NavLink>
            </Menu.Item>
          })
        }
      </Menu>
      <div className="menu-version">
        {process.env.REACT_APP_VERSION}
      </div>
    </Sider>
   )
}

export default connect(mapStateToProps)(Sidebar);
