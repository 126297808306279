import * as ActionTypes from 'store/actionTypes';

const defaultState = {}

const UserTableFilter = (state = defaultState, action: any) => {
  switch(action.type) {
    case ActionTypes.SAVE_USER_TABLE_FILTER:
      var userTableFilter = action.payload;
      return ({...state, ...userTableFilter});

    default:
      return state;
  }
}

export default UserTableFilter;
