import * as ActionTypes from 'store/actionTypes';

export const switchToLearningEnvironment = () => ({
  type: ActionTypes.SWITCH_ACTIVE_ENVIRONMENT_TO_LEARNING,
  payload: {}
});

export const switchToAdministrationEnvironment = () => ({
  type: ActionTypes.SWITCH_ACTIVE_ENVIRONMENT_TO_ADMINISTRATION,
  payload: {}
});
