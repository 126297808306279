import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Space } from 'antd';
import { CampaignFilterBarInterface } from '../types';
import { connect } from 'react-redux';
import apiClient from 'utils/apiClient';

const mapStateToProps = (state: any) => {
    return {
        activeUserRole: state.session.active_user_type
    }
};

const CampaignFilterBar: React.FC<CampaignFilterBarInterface> = ({
    activeUserRole,
    filter,
    owner,
    customFilterValues,
    form,
    getRecords,
    resourceUrl,
    currentPageSize,
    campaignId
}) => {
    const [filterTags, setFilterTags] = useState<any>([]);
    const [organizationalUnitUrl, setOrganizationalUnitUrl] = useState('');
    const [organizationalUnits, setOrganizationalUnits] = useState<any>();
    const pageQuery = 'page[number]=' + 1 + '&page[size]=' + currentPageSize;

    const intl = useIntl();

    useEffect(() => {
        if (owner) {
            let url = '/api/v1/customer/organizational-unit-types';
            if (activeUserRole === 'SUPER_ADMIN' || activeUserRole === 'RESELLER_ADMIN' || activeUserRole === 'DISTRIBUTOR_ADMIN') {
                url = url + '?customerId=' + owner.id;
            }

            setOrganizationalUnitUrl(url);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [owner])

    useEffect(() => {
        if (organizationalUnitUrl) {
            loadOrganizationalUnits();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[organizationalUnitUrl]);

    const loadOrganizationalUnits = async () => {
        let response = await apiClient.request(organizationalUnitUrl, [], 'GET');
        setOrganizationalUnits(response.organizationalUnits);
    }

    useEffect(() => {
        if (organizationalUnits && filter) {
            tagGenerator();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, organizationalUnits]);

    const tagGenerator = async (updatedFilter: any = null) => {

        let filterValues;
        if (updatedFilter) {
            filterValues = updatedFilter
        } else {
            filterValues = filter

        }

        let filterOptions: any = [];

        if (filterValues?.options?.organizationalUnitId) {
            let organizationalUnitArray: any = [];

            let orgUnitString = filterValues.options.organizationalUnitId.split(':');

            if (orgUnitString[1].length > 0) {
                let orgUnitIdArray = orgUnitString[1].split(',');

                orgUnitIdArray.map((el: any) => {
                    return organizationalUnitArray.push(['OrgUnit', organizationalUnits[el], parseInt(el), 'organizationalUnits']);
                });

                filterOptions.organizationalUnits = organizationalUnitArray;
            }
        }

        if (filterValues?.customFields) {
            let customFieldsArray: any = [];
            Object.entries(filterValues.customFields).map((customField: any) => {
                if (customField[1] && customField[1].length) {
                    let customFieldName = customFilterValues.filter((el: any) => el.id.toString() === customField[0]);
                    customField[1].map((customFieldValue: any) => {
                        customFieldsArray.push([customFieldName[0].name, customFieldValue, customFieldName[0].id, 'customFields', customFieldName[0].slug])
                        return customFieldsArray;
                    })
                }
                return customFieldsArray;
            });

            filterOptions.customFields = customFieldsArray;
        }

        setFilterTags(filterOptions);
    };

    const filterClose = (elementId: number, elementTag: string, value: string = '') => {

        let filterValues = filterTags[elementTag];
        let removingValues: any;
        let newFilter: any;

        if (value) {
            removingValues = filterValues.filter((el: any) => (el[3] === elementTag && el[2] === elementId && el[1] === value));
        } else {
            removingValues = filterValues.filter((el: any) => (el[3] === elementTag && el[2] === elementId));
        }

        if (elementTag === 'customFields') {
            let filterCustomFieldValues = filter.customFields[elementId.toString()];

            let customFilterValues = filterCustomFieldValues.filter((el: string) => el !== removingValues[0][1]);

            newFilter = filter;

            form.setFieldsValue({
                [`customFilter_${removingValues[0][4]}_${removingValues[0][2]}`]: customFilterValues
            });

            newFilter.customFields[elementId.toString()] = customFilterValues;

            newFilter.append = false;
            newFilter.campaignId = campaignId;

            tagGenerator(newFilter);
            getRecords(resourceUrl, pageQuery, newFilter, true);
        }

        if (elementTag === 'organizationalUnits') {
            let filterCustomFieldValues: any = filter.options.organizationalUnitId;

            let orgUnitString = filterCustomFieldValues.split(':');

            let orgUnitIdArray = orgUnitString[1].split(',');

            let customFilterValues: any = orgUnitIdArray.filter((el: string) => el !== removingValues[0][2].toString());

            newFilter = filter;

            if (customFilterValues.length) {

                for (let i = 0; i < customFilterValues.length; i++) customFilterValues[i] = + customFilterValues[i];

                form.setFieldsValue({
                    organizationalUnitId: customFilterValues
                });

                newFilter.options.organizationalUnitId = 'in:' + customFilterValues.toString();
            } else {
                form.setFieldsValue({
                    organizationalUnitId: []
                });
                delete newFilter.options['organizationalUnitId']
            }

            newFilter.append = false;
            newFilter.campaignId = campaignId;

            tagGenerator(newFilter);
            getRecords(resourceUrl, pageQuery, newFilter, true);
        }
    };

    const clearFilterBar = () => {

        form.setFieldsValue({
            organizationalUnitId: []
        });

        customFilterValues.map((el: any) => {
            form.setFieldsValue({
                [`customFilter_${el.slug}_${el.id}`]: []
            });
            return null;
        });

        tagGenerator({});
        getRecords(resourceUrl, pageQuery, {}, true);
    };

    return (
        <div className='filter-tag-container'>
            <Space>
                <span className='user-filter-bar-naming'>
                    <FormattedMessage id='general.filtered_by' />:
                </span>
                <div className='filter-tags'>
                    {filterTags.organizationalUnits ?
                        Object.values(filterTags.organizationalUnits).map((organizationalUnit: any) => {
                            let filterName = organizationalUnit[0];
                            let filterValue = organizationalUnit[1];
                            let filterId = organizationalUnit[2];
                            let filterTag = organizationalUnit[3];

                            return (
                                <div className='customer-filter-tag'>
                                    {filterName ? `${intl.formatMessage({ id: 'users.filter_bar_unit' })}: ${filterValue}` : null}
                                    <i onClick={() => {
                                        filterClose(filterId, filterTag)
                                    }} style={{ paddingLeft: '5px', paddingRight: '3px', cursor: 'pointer' }}
                                        className="fal fa-times">

                                    </i>
                                </div>
                            );
                        })
                        : null
                    }
                    {filterTags.customFields ?
                        Object.values(filterTags.customFields).map((customField: any) => {
                            let filterName = customField[0];
                            let filterValue = customField[1];
                            let filterId = customField[2];
                            let filterTag = customField[3];

                            return (
                                <div className='customer-filter-tag'>
                                    {filterName ? `${filterName}: ${filterValue}` : null}
                                    <i onClick={() => {
                                        filterClose(filterId, filterTag, filterValue)
                                    }} style={{ paddingLeft: '5px', paddingRight: '3px', cursor: 'pointer' }}
                                        className="fal fa-times">

                                    </i>
                                </div>
                            );
                        })
                        : null
                    }
                </div>
                <button
                    className='clear-all-button'
                    onClick={() => {
                        clearFilterBar()
                    }}
                    style={{ whiteSpace: 'nowrap' }}
                >
                    <FormattedMessage id="users.clear_all" />
                </button>
            </Space>
        </div>
    )
};

export default connect(mapStateToProps)(CampaignFilterBar);
