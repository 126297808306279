import * as ActionTypes from 'store/actionTypes';

export const changeMailTemplateFilterOptions = ({owner}:any) => ({
  type: ActionTypes.SAVE_MAIL_TEMPLATE_FILTER_OPTIONS,
  payload: {
    owner: owner
  }
});



