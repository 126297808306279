import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import DoughnutChart from 'components/Charts/DoughnutChart';
import coursesApiClient from 'utils/coursesApiClient';

interface EnrolledStatisticsChartProps {
  campaignId: number,
}

const EnrolledStatisticsChart: React.FC<EnrolledStatisticsChartProps> = ({ campaignId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    data: {},
    inner: {},
  });

  const intl = useIntl();

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const response = await coursesApiClient.request(`/api/v1/courses/statistic/campaign/${campaignId}/enrolled-students`);

        const data = response.studentStatuses;

        const parsedData = {
          data: {
            labels: [
              intl.formatMessage({ id: 'general.not_started' }),
              intl.formatMessage({ id: 'general.in_progress' }),
              intl.formatMessage({ id: 'general.completed' }),
              intl.formatMessage({ id: 'campaign.missed_deadline' }),
            ],
            datasets: [{
              data: [
                data.notStarted,
                data.inProgress,
                data.completed,
                data.missedDeadline,
              ],
              backgroundColor: [
                'rgb(247, 216, 0)',
                'rgb(126, 162, 223)',
                'rgb(33, 208, 160)',
                'rgb(244, 99, 101)',
              ],
              hoverBackgroundColor: [
                'rgb(227, 196, 0)',
                'rgb(106, 142, 203)',
                'rgb(13, 188, 140)',
                'rgb(224, 79, 81)',
              ],
            }],
          },
          inner: {
            text: intl.formatMessage({ id: 'campaign.enrolled_students' }),
            number: data.total,
          },
        };

        setData(parsedData);
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, [intl, campaignId]);

  return (
    <DoughnutChart
      data={data.data}
      title={intl.formatMessage({ id: 'general.statistics' })}
      inner={data.inner}
      loading={isLoading}
    />
  );
}

export default EnrolledStatisticsChart;