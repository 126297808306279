import React from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { Button as AntDButton } from 'antd';
import { ButtonType } from 'antd/es/button';
import { StatusWithoutCancelled } from 'components/CampaignCard/types';
import styles from '../styles.module.scss';

interface ButtonWrapperProps {
  link?: string,
}

const ButtonWrapper: React.FC<ButtonWrapperProps> = ({ link, children }) => (
  <>{link ? <NavLink to={link}>{children}</NavLink> : children}</>
);

interface ButtonWhenNotPreviewProps  {
  status: StatusWithoutCancelled,
  preview?: never,
}

interface ButtonWhenPreviewProps {
  status?: never,
  preview: true,
}

interface ButtonWithLinkWithoutPreview extends ButtonWhenNotPreviewProps {
  link: string,
  onClick?: never,
}

interface ButtonWithLinkWithPreview extends ButtonWhenPreviewProps {
  link: string,
  onClick?: never,
}

interface ButtonWithOnClickPropsWithoutPreview extends ButtonWhenNotPreviewProps {
  link?: never,
  onClick: () => void,
}

interface ButtonWithOnClickPropsWithPreview extends ButtonWhenPreviewProps {
  link?: never,
  onClick: () => void,
}

type ButtonProps = ButtonWithLinkWithoutPreview | ButtonWithLinkWithPreview | ButtonWithOnClickPropsWithoutPreview | ButtonWithOnClickPropsWithPreview;

const Button: React.FC<ButtonProps> = ({ status, link, onClick }) => {
  interface Parameters {
    type: ButtonType,
    messageId: string,
  }

  interface ParameterStatuses {
    NOT_STARTED: Parameters,
    IN_PROGRESS: Parameters,
    COMPLETED: Parameters,
    MISSED: Parameters,
  }

  const parameters: ParameterStatuses = {
    'NOT_STARTED': {
      type: 'default',
      messageId: 'student.start',
    },
    'IN_PROGRESS': {
      type: 'primary',
      messageId: 'student.continue',
    },
    'COMPLETED': {
      type: 'primary',
      messageId: 'student.view_content',
    },
    'MISSED': {
      type: 'primary',
      messageId: 'student.view_content',
    },
  }

  return (
    <div className={styles.buttonContainer}>
      <ButtonWrapper link={link}>
        <AntDButton
          className={`ant-btn-student ${styles.button}`}
          type={status ? parameters[status].type : 'primary'}
          size="large"
          onClick={onClick}
        >
          <FormattedMessage id={status ? parameters[status].messageId : 'student.view_content'} />
        </AntDButton>
      </ButtonWrapper>
    </div>
  );
};

export default Button;