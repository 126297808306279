import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AdminSignatureModal from 'components/StudentCampaignListDivision/AdminSignatureModal';
import moment from 'moment';
import Tag from './Tag';
import { Status } from '../../types';
import styles from '../../styles.module.scss';
import { connect } from 'react-redux';

interface InformationProps {
  reload?: any;
  campaign: any;
  status: Status;
  session?: any;
}

const mapStateToProps = (state: any) => {
  return {
    session: state.session
  }
}

const Index: React.FC<InformationProps> = ({ campaign, status, reload, session }) => {
  const [showAdminSignatureModal, setShowAdminSignatureModal] = useState(false);

  const handleSignatureClick = () => {
    if (session.active_user_type !== 'STUDENT') {
      setShowAdminSignatureModal(true)
    }
}

return (
  <div className={styles.informationContainer}>
    <div className={styles.informationTitleWrapper}>
      <h3 className={styles.informationTitle}>{campaign.name}</h3>
      {!!campaign.hasJournal &&
        <span onClick={() => handleSignatureClick()} className={`${styles.signatureContainer} `  + (session.active_user_type !== 'STUDENT' ? 'sign-button' : '')}>
            <span className={(campaign.signature ? 'signature-style sign-green' : 'signature-style sign-red')}>
              <i className='fal fa-user-edit'></i>
              <FormattedMessage id='student.signature' />
            </span>
        </span>
      }
      <Tag status={status} />
    </div>
    {campaign.resertificationIndex ? <h3 className={styles.informationTitle}>({campaign.resertificationIndex + 1}/{campaign.resertificationStartedAt})</h3> : null}
    <div className={styles.informationList}>
      <p className={styles.informationItem}>
        <i className='fal fa-bars' />
        <FormattedMessage id="student.total_activites" values={{ amount: campaign.totalActivities }} />
      </p>
      <p className={styles.informationItem}>
        <i className='fal fa-clock' />
        <FormattedMessage id="student.activity_minutes" values={{ amount: campaign.totalMinutes }} />
      </p>
      <p className={`${styles.informationItem} ${status === 'MISSED' ? styles.informationItemMissed : null}`}>
        <i className='fal fa-flag-checkered' />
        <FormattedMessage id="student.deadline_in" />
        {moment(campaign.deadline).format('DD.MM.YYYY.')}
      </p>
    </div>

    <AdminSignatureModal
          visible={showAdminSignatureModal}
          onCancel={()=> setShowAdminSignatureModal(false)}
          campaignId={campaign.id}
          enrollmentId={campaign.enrollmentId}
          reload={reload}
          campaign={campaign}
      />
  </div>
)};

export default  connect(mapStateToProps)(Index);