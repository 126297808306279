const mapItemToValues = (item: any) => {
    return ({
        ...item,
        waitForPayment: !!item.waitForPayment
    })
};

const mapValuesForSubmit = (values: any) => {
    return ({
        ...values,
        waitForPayment: !!values.waitForPayment
    })
};

const mapSubmitErrorsToForm = (submitErrors: any) => {
    let errorKeys = Object.keys(submitErrors);

    if (errorKeys.length === 0) {
        return [];
    }

    return errorKeys.map((key: any) => ({
        name: key,
        errors: submitErrors[key]
    }));
};

const exportedObject = { mapItemToValues, mapSubmitErrorsToForm, mapValuesForSubmit };

export default exportedObject;