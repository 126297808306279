import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';
import { Modal, Form, message } from 'antd';
import { Select } from 'components/Form';

import apiClient from 'utils/apiClient';
import config from "../../config/config";
import {connect} from "react-redux";

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    }
};

interface DashboardModalProps {
    showCustomerSelect: boolean;
    setShowCustomerSelect: React.Dispatch<React.SetStateAction<boolean>>;
    setCustomerUuid: React.Dispatch<React.SetStateAction<string>>;
    setCustomerName: React.Dispatch<React.SetStateAction<string>>;
    session: any;
}

const DashboardModal: React.FC<DashboardModalProps> = ({showCustomerSelect, setShowCustomerSelect, setCustomerUuid, setCustomerName, session}) => {
    const [customersLoading, setCustomersLoading] = useState<boolean>(false);
    const [customers, setCustomers] = useState<[]|null>(null);

    const userRole = session.active_user_type;
    const [form] = Form.useForm();
    const intl = useIntl();

    useEffect(() => {
        const loadCustomers = async () => {
            let customerUrl = config.api.routes.backend.organizationsCustomers;

            try {
                setCustomersLoading(true);

                const response = await apiClient.request(customerUrl, {}, 'GET');
                const customerArray: any = [];

                Object.values(response.customers).map((customer: any) => {
                    return customerArray[customer.uuid] = customer.name;
                });

                setCustomers(customerArray);
            } catch(error) {
                message.error(intl.formatMessage({id: 'error.data_load'}));
                console.error(error);
            } finally {
                setCustomersLoading(false);
            }
        };

        if (userRole === 'SUPER_ADMIN' || userRole === 'DISTRIBUTOR_ADMIN' || userRole === 'RESELLER_ADMIN') {
            loadCustomers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userRole]);

    const submitForm = async (values: any) => {

        try {
            setCustomerUuid(values.customer);

            const customerName: string = customers ? customers[values.customer] : '';

            setCustomerName(customerName);
            setShowCustomerSelect(false);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({id: 'error.data_load'}));
        }
    };

    return (
        <Modal
            visible={showCustomerSelect}
            title={intl.formatMessage({id: 'general.customer'})}
            okText={intl.formatMessage({id: 'general.submit'})}
            cancelText={intl.formatMessage({id: 'general.back'})}
            onOk={form.submit}
            onCancel={() => {setShowCustomerSelect(false);}}
        >
            <Form form={form} onFinish={submitForm}>
                {customers ? <Select
                    showSearch
                    customLayout
                    name="customer"
                    label={intl.formatMessage({id: 'general.customer'})}
                    customRules={[{ required: true, message: intl.formatMessage({id: 'validation.field_required'}) }]}
                    manualOptions={customers}
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                    loading={customersLoading}
                />: null}
            </Form>
        </Modal>
    );
};

export default connect(mapStateToProps)(DashboardModal);
