import React from 'react';
import { useIntl } from 'react-intl';
import { Modal } from 'antd';
import EmailTemplateFormFromCustomer from 'pages/Emailing/EmailTemplateFormCustomer';
import { CertificateEditModalInterface } from '../types';

const CertificateEditModal: React.FC<CertificateEditModalInterface> = ({
    visible,
    onCancel,
    templateUuid,
    owner
}) => {

    const intl = useIntl();

    return (
        <Modal
            className="campaign-email-template-modal"
            visible={visible}
            onCancel={onCancel}
            width={800}
            footer={null}
            title={intl.formatMessage({ id: 'emailing.templates.edit_template' })}
        >
            <EmailTemplateFormFromCustomer isModal={true} completionsTemplate={{ templateOwner: owner?.id, templateUuid: templateUuid }} onCancel={onCancel} />
        </Modal>
    )
}
export default CertificateEditModal;
