import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, message, Space, Upload, Modal, Form, FormInstance } from 'antd';
import FlexRow from 'components/FlexRow';
import FileDownload from 'js-file-download';
import { DownloadOutlined, ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import FormattedMessage from 'react-intl/lib/components/message';
import coursesApiClient from 'utils/coursesApiClient';
import { connect } from 'react-redux';
import ActivityRating from './ActivityRating';
import { FileTable } from 'components/GlobalComponents';
import './styles.scss';
import Spinner from 'components/Spinner';

interface AssignmentFormInterface {
    activityFormData: any;
    session?: any;
    form: FormInstance;
    getStatusTag: Function;
    getAssignmentDeadline: Function;
    formDate: Function;
    setActivityFormData: React.Dispatch<React.SetStateAction<any>>;
}

const mapStateToProps = (state: any) => ({
    session: state.session,
});

const AssignmentForm: React.FC<AssignmentFormInterface> = ({ activityFormData, session, form, getStatusTag, getAssignmentDeadline, formDate, setActivityFormData }) => {
    const adminRoles = ['RESELLER_ADMIN', 'SUPER_ADMIN', 'DISTRIBUTOR_ADMIN', 'CUSTOMER_ADMIN', 'SUPERVISION'];
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [isFilesLoading, setIsFilesLoading] = useState(false);
    const [isAssignmentLoading, setIsAssignmentLoading] = useState(false);

    const intl = useIntl();

    const files = activityFormData.materials.assignments.file_settings ? JSON.parse(activityFormData.materials.assignments.file_settings) : [];
    const assignmentValues = JSON.parse(activityFormData.materials.assignments.settings);
    const submittedProperties = activityFormData.activityAttempt.assignment_properties ? JSON.parse(activityFormData.activityAttempt.assignment_properties)[activityFormData.materials.assignments.id] : null

    useEffect(() => {
        if (activityFormData) {
            if (submittedProperties && !submittedProperties?.submission?.submitted && submittedProperties?.submitted_files && submittedProperties?.submitted_files.length) {
                setDisableSubmit(false);
            } else if (submittedProperties && submittedProperties?.submission?.submitted) {
                setDisableSubmit(true);
            } else {
                setDisableSubmit(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submittedProperties])

    /**
     * 
     * @param file 
     */
    const uploadStudentFile = async (file: any) => {
        setIsFilesLoading(true);
        try {
            if (activityFormData) {
                let bodyFormData = new FormData();
                bodyFormData.append('file', file.file);
                const response = await coursesApiClient.request(`/api/v1/courses/student/${activityFormData.enrollment.id}/resource-upload/${activityFormData.materials.assignments.id}`, bodyFormData, 'POST');
                setActivityFormData({ ...activityFormData, activityAttempt: response.activityAttempt })
            }
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsFilesLoading(false);
        }
    }

    const downloadAttachment = async () => {
        try {
            let response = await coursesApiClient.request(`api/v1/courses/student/${activityFormData.enrollment.id}/download-attachment/${activityFormData.materials.assignments.id}`, {}, 'GET', true, true);

            const settings = JSON.parse(activityFormData.materials.assignments.settings);

            if (activityFormData) {
                FileDownload(response, settings.title + '.zip');
            } else {
                FileDownload(response, 'archive.zip');
            }
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }
    }

    const submitToCheck = async () => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            icon: <ExclamationCircleOutlined />,
            content: intl.formatMessage({ id: 'courses.student_submit_work' }),
            okText: intl.formatMessage({ id: 'general.submit' }),
            cancelText: intl.formatMessage({ id: 'general.cancel' }),
            onOk() {
                submit();
            }
        })
    }

    const submit = async () => {
        setIsAssignmentLoading(true);
        try {
            const response = await coursesApiClient.request(`api/v1/courses/student/${activityFormData.enrollment.id}/submit-work/${activityFormData.materials.assignments.id}`, {}, 'POST');
            setActivityFormData({ ...activityFormData, activityAttempt: response.activityAttempt })
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsAssignmentLoading(false);
        }
    }

    return (
        <div className='assignment-card'>
            <div className='assignment-header'>
                <i className="fa-solid fa-file-signature" />
                <FormattedMessage id='courses.individual_work' />
            </div>

            <div className='status-bar'>
                <div className='activity-deadline'>
                    <span>
                        <i className="fa-solid fa-flag-checkered" />
                    </span>
                    <span className='status-bar-value'>
                        <FormattedMessage id='courses.topic_submit_deadline' />:
                    </span>
                    <span className='status-bar-value'>
                        {getAssignmentDeadline()}
                    </span>
                </div>

                <div className='activity-status'>
                    <span className='status-bar-value'>
                        <FormattedMessage id='courses.assignment_status' />
                    </span>
                    <span className='status-bar-value'>
                        {activityFormData && getStatusTag()}
                    </span>
                </div>
            </div>

            <div className='assignment-form'>
                <span style={{ marginBottom: 16 }}>
                    {assignmentValues.title &&
                        <div className='resource-headers'>
                            {assignmentValues.title}
                        </div>
                    }
                </span>
                {activityFormData &&
                    <div style={{ fontSize: 16, overflowWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: assignmentValues.description }}></div>
                }


                {files && files.length ?
                    <>
                        <hr className='form-group-separator' />
                        <div className='resource-headers'>

                            <FormattedMessage id='courses.individual_learning_materials' />
                        </div>
                    </>
                    : null
                }
                {files && files.length ?
                    <div className='resource-container'>
                        <FileTable key={'assignment_materials'} fileArray={files} isAssignment={true} allowAction={false} />
                    </div>
                    : null
                }
                <>
                    <hr className='form-group-separator' />
                    <div className='resource-headers'>
                        <FormattedMessage id='courses.assignment_status' /> {activityFormData && getStatusTag()}
                    </div>
                </>
                <Form form={form}>
                    {activityFormData &&
                        <div className='resource-container'>
                            <ActivityRating activityFormData={activityFormData} formDate={formDate} getAssignmentDeadline={getAssignmentDeadline} form={form} getStatusTag={getStatusTag} />
                        </div>
                    }
                </Form>
                {!!assignmentValues.file_upload && !adminRoles.includes(session.active_user_type) &&
                    <>
                        <hr className='form-group-separator' />
                        <div className='resource-headers'>
                            <FormattedMessage id='courses.file_submission' />
                        </div>
                    </>
                }
                {!!submittedProperties?.submitted_files && !!submittedProperties?.submitted_files.length && adminRoles.includes(session.active_user_type) ?
                    <>
                        <hr className='form-group-separator' />
                        <div className='resource-headers'>
                            <FormattedMessage id='courses.file_submission' />
                        </div>
                    </>
                    :
                    null
                }
                {!submittedProperties?.submission?.submitted &&
                    <div className='assignment-upload-button'>
                        {assignmentValues && !!assignmentValues.file_upload && !adminRoles.includes(session.active_user_type) &&
                            <Upload
                                customRequest={(file: any) => uploadStudentFile(file)}
                                multiple
                                fileList={[]} >
                                <Button type='link'><Space size={8}><UploadOutlined /><FormattedMessage id='general.upload_files' /></Space></Button>
                            </Upload>
                        }
                    </div>
                }
                <Spinner spinning={isFilesLoading}>
                    <div className='resource-container'>
                        <FileTable setActivityFormData={setActivityFormData} activityFormData={activityFormData} allowDelete={!submittedProperties?.submission?.submitted} key={'student_upload'} fileArray={submittedProperties?.submitted_files} isAssignment={true} allowAction={true} date={true} dateFormatter={formDate} student={activityFormData.enrollment.id} />
                    </div>
                </Spinner>
                {!!submittedProperties?.submission && adminRoles.includes(session.active_user_type) ?
                    <Button onClick={() => downloadAttachment()} type='link' >
                        <Space size={3}>
                            <DownloadOutlined />
                            <FormattedMessage id='general.download_attachment' />
                        </Space>
                    </Button>
                    :
                    null
                }
                {!!assignmentValues.file_upload &&
                    <>
                        {!submittedProperties?.submission?.submitted &&
                            <div>
                                {!adminRoles.includes(session.active_user_type) &&
                                    <div>
                                        <hr className='form-group-separator' />
                                        <FlexRow
                                            right={
                                                <Button loading={isAssignmentLoading} className='submit-student-assignment' type='primary' disabled={disableSubmit} onClick={() => !disableSubmit ? submitToCheck() : null}>
                                                    <FormattedMessage id={assignmentValues && !!assignmentValues.file_upload ? 'courses.submit_assignment' : 'courses.confirm_read'} />
                                                </Button>
                                            }
                                        />
                                    </div>
                                }
                            </div>
                        }
                    </>
                }
            </div>
        </div >
    )
}
export default connect(mapStateToProps)(AssignmentForm);