import * as ActionTypes from 'store/actionTypes';

export const changeUserTableFilter = ({filter, filterLabels, organizationId, organizationType, organizationUuid}:any) => ({
  type: ActionTypes.SAVE_USER_TABLE_FILTER,

  payload: {
    filter: filter,
    filterLabels: filterLabels,
    organizationId: organizationId,
    organizationType: organizationType,
    organizationUuid: organizationUuid
  }
});
