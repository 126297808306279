import React from 'react';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Route from 'components/PermissionRoute';

/** ADMINISTRATION ROUTES */
import Dashboard from 'pages/Dashboard';
import OrganizationList from 'pages/Organizations/OrganizationList';
import OrganizationForm from 'pages/Organizations/OrganizationForm';
import OrganizationAgreementForm from 'pages/Organizations/AgreementForm';
import OrganizationAdminList from 'pages/Organizations/AdminList';
import OrganizationAdminForm from 'pages/Organizations/AdminForm';
import CustomerList from 'pages/Customers/CustomerList';
import CustomerForm from 'pages/Customers/CustomerForm';
import CustomerAgreementForm from 'pages/Customers/AgreementForm';
import UserListTest from 'pages/Users/UsersList_test';
import UserForm from 'pages/Users/UserForm';
import EmailScenarioForm from 'pages/Emailing/EmailScenarioForm';
import EmailTemplateFormStandard from 'pages/Emailing/EmailTemplateFormStandard';
import EmailTemplateFormCustomer from 'pages/Emailing/EmailTemplateFormCustomer';
import EmailTemplateFormOrganization from 'pages/Emailing/EmailTemplateFormOrganization';
import EmailTemplateList from 'pages/Emailing/EmailTemplateList';
import EmailHistoryList from 'pages/Emailing/EmailHistoryList';
import SendEmailScenarios from 'pages/Emailing/SendEmailScenarios';
import PageUnderConstruction from 'pages/PageUnderConstruction';
import ScenaroHistoryList from 'pages/Emailing/ScenaroHistoryList';
import PermissionsList from 'pages/System/PermissionsList';
import ManageContent from 'pages/Courses/ManageContent';
import CourseEdit from 'pages/Courses/CourseEdit';
import ManageELearning from 'pages/Courses/ManageELearning';
import NotAllowed from 'pages/ErrorPages/NotAllowed';
import ServiceList from 'pages/Licences/ServiceList';
import ServiceForm from 'pages/Licences/ServiceForm';
import LicenceList from 'pages/Licences/LicenceList';
import LicenceEdit from 'pages/Licences/LicenceEdit';
import ManagePhishingCampaigns from 'pages/Phishing/ManageCampaigns';
import ManageTemplates from 'pages/Phishing/ManageTemplates';
import PhishingTemplateForm from 'pages/Phishing/PhishingTemplateForm';
import CustomPhishingCampaignForm from 'pages/Phishing/CustomPhishingCampaignForm';
import ManageLanguages from 'pages/System/ManageLanguages';
import CustomizeEnvironment from '../../pages/System/CustomizeEnvironment';
import Audit from '../../pages/System/Audit';
import ContentForm from 'pages/Content/ContentForm';
import ContentList from 'pages/Content/ContentList';
import VideoInstructions from "pages/System/VideoInstructions";
import TermsAndConditions from 'pages/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from 'pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditionsEdit from 'pages/TermsAndConditions/TermsAndConditionsEdit';
import UserOrganizations from 'pages/Customers/UserOrganizations';
import LearningMaterial from 'pages/LearningMaterial';
import CampaignForm from 'pages/Campaign';

/** LEARNING ROUTES */
import ManageFilters from 'pages/System/ManageFilters';
import StudentCampaignActivityList from 'learningPages/Campaign/StudentCampaignActivityList';
import StudentCampaignList from 'learningPages/Campaign/StudentCampaignList';
import ShowScorm from 'learningPages/Campaign/ShowScorm';
import ContentView from 'pages/Content/ContentView';
import ActivityResources from 'learningPages/Campaign/ActivityResources';
import ConnectionSettings from 'pages/System/ConnectionSettings';

const mapStateToProps = (state: any) => {
  return {
    environment: state.environment.environment,
  }
};

const Router: React.FC<any> = ({environment}) => {
  if (environment === 'ADMINISTRATION') {
    return (
      <Switch>
        <Route exact path="/" component={Dashboard} permissionSlug='view_dashboard'/>
        <Route exact path="/users" component={UserListTest} permissionSlug='view_users' />
        <Route exact path="/organizations" component={OrganizationList} permissionSlug='view_organizations,view_customers' />
        <Route exact path="/organizations/add" component={OrganizationForm} permissionSlug='create_organizations' specialRight='can_add_new_resellers'/>
        <Route path="/organizations/:id/edit" component={OrganizationForm} permissionSlug='edit_organizations'/>
        <Route path="/organizations/:organizationId/agreement" component={OrganizationAgreementForm} permissionSlug='view_agreements' specialRight='can_manage_agreements'/>
        <Route path="/organizations/:organizationId/admins/add" component={OrganizationAdminForm} permissionSlug='create_admins' specialRight='can_manage_admins'/>
        <Route path="/organizations/:organizationId/admins/:uuid/edit" component={OrganizationAdminForm} permissionSlug='edit_admins' specialRight='can_manage_admins'/>
        <Route path="/organizations/:organizationId/admins" component={OrganizationAdminList} permissionSlug='view_admins' specialRight='can_manage_admins'/>
        <Route exact path="/customers" component={CustomerList} permissionSlug='view_customers' />
        <Route exact path="/customers/add" component={CustomerForm} permissionSlug='create_customers' specialRight='can_add_new_customers'/>
        <Route path="/customers/:id/edit" component={CustomerForm} permissionSlug='edit_customers' specialRight='can_manage_customers'/>
        <Route path="/customers/:customerId/agreement" component={CustomerAgreementForm} permissionSlug='view_customer-agreements' specialRight='can_manage_agreements'/>
        

        {/* campaign routes */}
        <Route exact path="/courses/manage-campaigns" component={ManageELearning} permissionSlug='view_campaigns'/>
        <Route exact path="/campaign/create/:step" component={CampaignForm} permissionSlug='create_campaigns'/>
        <Route exact path="/campaign/create/:id/:step?" component={CampaignForm} permissionSlug='create_campaigns'/>
        <Route exact path="/campaign/:id/:step?" component={CampaignForm} permissionSlug='view_campaigns'/>

        {/* courses routes */}
        <Route exact path="/courses/manage-content" component={ManageContent} permissionSlug='view_courses' specialRight='can_manage_courses,can_add_own_content' />
        <Route exact path="/courses/:courseId/edit" component={CourseEdit} permissionSlug='edit_courses' specialRight='can_manage_courses,can_add_own_content' />
        <Route exact path="/courses/:courseId/material/:activityId" component={LearningMaterial} permissionSlug='edit_courses' specialRight='can_manage_courses,can_add_own_content' />

        {/*user create/view components*/}
        <Route path="/customer/:customerId/user/:uuid/view/:backPath?" component={UserForm} permissionSlug='view_users'/>
        <Route path="/customer/:customerId/user/add/:role" component={UserForm} permissionSlug='create_users'/>

        {/*DIST/RES ADMIN user components*/}
        <Route path="/customers/:customerId/users/:backPath" component={UserListTest} permissionSlug='view_customers_users'/>

        {/*phishing routes campaign components*/}
        <Route exact path="/phishing/manage-campaigns" component={ManagePhishingCampaigns} permissionSlug='view_phishing_campaign'/>
        <Route exact path="/customer/:customerId/phishing/campaign" component={CustomPhishingCampaignForm} permissionSlug='create_phishing_campaign'/>
        <Route exact path="/customer/:customerId/phishing/campaign/:id/edit" component={CustomPhishingCampaignForm} permissionSlug='edit_phishing_campaign'/>

        <Route path="/admins/add" component={OrganizationAdminForm} permissionSlug='create_admins' specialRight='can_manage_admins'/>
        <Route exact path="/e-learning/add-content" component={PageUnderConstruction} permissionSlug='create_courses' specialRight='can_manage_courses'/>
        <Route exact path="/e-learning/apply-for-new-content" component={PageUnderConstruction} />
        <Route exact path="/phishing/templates" component={ManageTemplates} permissionSlug='create_phishing_templates'/>
        <Route exact path="/phishing/templates/add" component={PhishingTemplateForm} permissionSlug='create_phishing_templates'/>
        <Route exact path="/phishing/templates/:id/edit/:page/:language?" component={PhishingTemplateForm} permissionSlug='create_phishing_templates'/>
        <Route exact path="/phishing/campaign" component={CustomPhishingCampaignForm} permissionSlug='create_phishing_campaign'/>
        <Route exact path="/phishing/campaign/:id/edit" component={CustomPhishingCampaignForm} permissionSlug='edit_phishing_campaign'/>

        <Route exact path="/e-mailing/email-scenarios/add" component={EmailScenarioForm} permissionSlug='create_mail_scenarios'/>
        <Route path="/e-mailing/email-scenarios/:id/edit" component={EmailScenarioForm} permissionSlug='edit_mail_scenarios'/>
        <Route exact path="/e-mailing/email-scenarios" component={SendEmailScenarios} permissionSlug='view_mail_scenarios'/>
        <Route exact path="/e-mailing/manage-templates" component={EmailTemplateList} permissionSlug='view_mail_templates'/>
        <Route exact path="/e-mailing/manage-templates/add" component={EmailTemplateFormStandard} permissionSlug='create_mail_templates'/>
        <Route exact path="/e-mailing/manage-templates/:uuid/edit" component={EmailTemplateFormStandard} permissionSlug='edit_mail_templates'/>
        <Route exact path="/e-mailing/manage-templates/customer/:id/add" component={EmailTemplateFormCustomer} permissionSlug='create_mail_templates' specialRight='can_edit_email_templates'/>
        <Route exact path="/e-mailing/manage-templates/customer/:id/:uuid/edit" component={EmailTemplateFormCustomer} permissionSlug='edit_mail_templates' specialRight='can_edit_email_templates'/>
        <Route exact path="/e-mailing/manage-templates/organization/:id/add" component={EmailTemplateFormOrganization} permissionSlug='create_mail_templates' specialRight='can_edit_email_templates'/>
        <Route exact path="/e-mailing/manage-templates/organization/:id/:uuid/edit" component={EmailTemplateFormOrganization} permissionSlug='edit_mail_templates' specialRight='can_edit_email_templates'/>
        <Route exact path="/e-mailing/history" component={EmailHistoryList} permissionSlug='view_mail_scenarios'/>
        <Route exact path="/e-mailing/:id/history" component={ScenaroHistoryList} permissionSlug='view_mail_scenarios'/>
        <Route exact path="/analytics/e-learning" component={PageUnderConstruction} />
        <Route exact path="/analytics/phishing" component={PageUnderConstruction} />
        <Route exact path="/analytics/gamification" component={PageUnderConstruction} />
        <Route exact path="/analytics/reports" component={PageUnderConstruction} />
        <Route exact path="/licensing/manage-services" component={ServiceList} permissionSlug='view_services' specialRight='can_manage_services'/>
        <Route exact path="/licensing/manage-services/add" component={ServiceForm} permissionSlug='create_services' specialRight='can_manage_services'/>
        <Route path="/licensing/manage-services/:id/edit" component={ServiceForm} permissionSlug='edit_services' specialRight='can_manage_services'/>
        <Route exact path="/licensing/manage-licences" component={LicenceList} permissionSlug='view_licences'/>
        <Route path="/licensing/manage-licences/:id/edit" component={LicenceEdit} permissionSlug='view_licences'/>
        <Route exact path="/subscriptions/assign-licences" component={PageUnderConstruction} />
        <Route exact path="/subscriptions/my-subscription-licences" component={PageUnderConstruction} />
        <Route exact path="/system/manage-languages" component={ManageLanguages} />
        <Route exact path="/system/auditing" component={Audit} permissionSlug='view_global_logs,view_organization_logs,view_customer_logs'/>
        <Route exact path="/system/permissions" component={PermissionsList} permissionSlug='view_permissions'/>


        <Route exact path="/system/manage-filters" component={ManageFilters} permissionSlug='create_users_filters'/>
        <Route path="/system/connection" component={ConnectionSettings} permissionSlug='edit_connection' specialRight='can_manage_customer_connection' />

        <Route exact path="/system/customize-environment" component={CustomizeEnvironment} permissionSlug="can_edit_environment" />
        <Route exact path="/content" component={ContentList} permissionSlug="view_content" />
        <Route exact path="/content/add/:type" component={ContentForm} permissionSlug="create_content" />
        <Route exact path="/content/:id/edit" component={ContentForm} permissionSlug="create_content" />
        <Route exact path="/content/:id/view" component={ContentView} permissionSlug="view_content" />
        <Route exact path="/support" component={VideoInstructions} />
        <Route exact path="/user/organizations" component={UserOrganizations}  specialRight='can_manage_customers' />
        <Route exact path="/terms-and-conditions" component={TermsAndConditions}/>
        <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
        <Route exact path="/terms-and-conditions/edit" component={TermsAndConditionsEdit}/>
        <Route exact path="/privacy-policy/edit" component={TermsAndConditionsEdit}/>
        <Route exact path="/403" component={NotAllowed} />
        <Redirect to="/" />
      </Switch>
    )
  }

  return (
    <Switch>
      <Route exact path="/student/:enrollmentId/activity-list/:id" component={StudentCampaignActivityList} />
      <Route exact path="/student/:enrollmentId/activity-list/:id/resources/:activityId" component={ActivityResources} />
      <Route exact path="/student/show-activity" component={ShowScorm} />
      <Route exact path="/terms-and-conditions" component={TermsAndConditions}/>
      <Route exact path="/privacy-policy" component={PrivacyPolicy}/>
      <Route exact path="/" component={StudentCampaignList} />
      <Redirect to="/" />
    </Switch>
  )
};

export default connect(mapStateToProps)(withRouter(Router));
