import * as ActionTypes from 'store/actionTypes';

const defaultState = {
  locale: 'EN'
}

const Locale = (state = defaultState, action: any) => {
  switch(action.type) {
    case ActionTypes.CHANGE_LOCALE:
      var locale = action.payload;
      return {...state, locale: locale};

    default:
      return state;
  }
}

export default Locale;
