import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import FormattedMessage from 'react-intl/lib/components/message';
import { Form, Input, Radio, InputNumber, FormInstance, message, Tooltip } from 'antd';
import { Switch } from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import { FileTable, FileUpload, RichTextEditor } from 'components/GlobalComponents';
import Spinner from 'components/Spinner';

const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
};

type AssignmentFormType = {
    type: 'ASSIGNMENT',
    language: string,
    settings: {
        title: string;
        description: string;
        fileUpload: boolean;
        evaluationType: 'EXAMINATION' | 'RANGE' | 'COMMENT' | 'NO_EVALUATION';
        deadline: number;
        passRate?: number;
    }
}

interface AssignmentFormInterface {
    language: string;
    form: FormInstance;
    activityId: number;
    viewMaterial?: any;
    onCancel: any;
    reload: Function;
}

const AssignmentForm: React.FC<AssignmentFormInterface> = ({
    language,
    form,
    activityId,
    viewMaterial,
    onCancel,
    reload
}) => {
    const [evaluationType, setEvaluationType] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const intl = useIntl();

    useEffect(() => {
        if (viewMaterial) {

            const materialSettings = JSON.parse(viewMaterial.settings);

            if (viewMaterial.settings) {
                form.setFieldsValue({
                    title: materialSettings.title,
                    description: materialSettings.description,
                    evaluationType: materialSettings.evaluation_type,
                    deadline: materialSettings.deadline,
                    passRate: materialSettings.pass_rate,
                    fileUpload: !!materialSettings.file_upload
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [viewMaterial]);

    const uploadMaterial = async (materialId: number) => {

        const values = form.getFieldsValue();

        if (!values.materialsFiles) {
            return;
        }

        try {
            let bodyFormData = new FormData();

            for (let i = 0; i < values.materialsFiles.length; i++) {
                if (values.materialsFiles[i].originFileObj) {
                    bodyFormData.append('file_' + i, values.materialsFiles[i].originFileObj);
                } else {
                    bodyFormData.append('file_' + i, values.materialsFiles[i]);
                }
            }

            await coursesApiClient.request(`/api/v1/courses/material/upload-assignment-file/${materialId}`, bodyFormData, 'POST', true, true);

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }
    }

    const submitForm = async (values: any) => {
        setIsLoading(true);
        try {
            let description = values.description?.level?.content ? values.description.level.content : values.description?.level?.fragments ? values.description.level.fragments : values.description;

            if (Array.isArray(description)) {
                description = values.description?.level?.fragments.join(' ');
            }

            const parsedValues: AssignmentFormType = {
                type: 'ASSIGNMENT',
                language: language,
                settings: {
                    title: values.title,
                    description: description,
                    fileUpload: !!values.fileUpload,
                    evaluationType: values.evaluationType,
                    deadline: values.deadline
                }
            }

            if (values.evaluationType === 'RANGE') {
                parsedValues.settings.passRate = values.passRate
            }
            let response;

            if (viewMaterial) {
                response = await coursesApiClient.request(`/api/v1/courses/material/update/${viewMaterial.id}`, parsedValues, 'PUT');
                await uploadMaterial(response.material.settings.id);
            } else {
                response = await coursesApiClient.request(`/api/v1/courses/material/${activityId}/add-material`, parsedValues, 'POST');
                await uploadMaterial(response.material.settings.id);
            }

            onCancel();
            reload(activityId);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Form form={form} onFinish={submitForm} >
            <Spinner spinning={isLoading}>
                <Form.Item {...formItemLayout} name='title' label={intl.formatMessage({ id: 'general.title' })} >
                    <Input />
                </Form.Item>
                <RichTextEditor layout={formItemLayout} name='description' label={intl.formatMessage({ id: 'general.description' })} />
                <Form.Item {...formItemLayout} name='evaluationType' label={intl.formatMessage({ id: 'courses.evaluation_type' })} >
                    <Radio.Group onChange={(event: any) => setEvaluationType(event.target.value)}>
                        <Radio value='EXAMINATION'>
                            <FormattedMessage id='courses.examination' />
                        </Radio>
                        <Radio value='RANGE'>
                            <FormattedMessage id='courses.rating_range' />
                        </Radio>
                        <Radio value='NO_EVALUATION'>
                            <FormattedMessage id='courses.no_evaluation' />
                        </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item {...formItemLayout} name='deadline'
                    label={<span>
                        {intl.formatMessage({ id: 'courses.deadline_in_days' })}
                        <Tooltip title={intl.formatMessage({ id: 'courses.deadline_in_days_hint' })}>
                            <i className='fal fa-question-circle header-item' />
                        </Tooltip>
                    </span>}>
                    <InputNumber min={1} />
                </Form.Item>
                {evaluationType === 'RANGE' &&
                    <Form.Item {...formItemLayout} name='passRate' label={intl.formatMessage({ id: 'courses.pass_rate' })} >
                        <InputNumber min={1} max={100} />
                    </Form.Item>
                }
                <Switch
                    customLayout={formItemLayout}
                    defaultChecked={false}
                    hasDefaultLayout={false}
                    isFormItem
                    name='fileUpload'
                    label={<span>
                        {intl.formatMessage({ id: 'courses.assignment_upload' })}
                        <Tooltip title={intl.formatMessage({ id: 'courses.assignment_upload_hint' })}>
                            <i className='fal fa-question-circle header-item' />
                        </Tooltip>
                    </span>}
                />
                <FileUpload multiple={true} layout={formItemLayout} name="materialsFiles" label="courses.assignment_materials" buttonLabel='general.upload_files' />
                {viewMaterial && viewMaterial.fileSettings &&
                    <FileTable fileArray={JSON.parse(viewMaterial.fileSettings)} isAssignment={true} assignmentId={viewMaterial.id} allowAction={true} />
                }
            </Spinner>
        </Form>
    )
}
export default AssignmentForm;
