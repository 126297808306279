import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import {Button} from 'antd';
import apiClient from 'utils/apiClient';
import './styles.scss';

const FilterBar: React.FC<any> = ({
  filter,
  ownerId,
  customerLabel,
  customFilters,
  setFilterBarValues,
  clearAllFilter
}) => {
    const [filteredOptions, setFilteredOptions] = useState<any>([]);
    const [orgUnit, setOrgUnit] = useState();
    const intl = useIntl();

    useEffect(()=>{
      const loadCustomerOrganizationalUnits = async () => {

        let url = '/api/v1/customer/organizational-unit-types'

        if (filter === 'ORGANIZATION') {
          url = `/api/v1/organizations/${ownerId}/organizational-unit-types`
        }

          let orgUnitResponse = await apiClient.request(url, {}, 'GET');

          setOrgUnit(orgUnitResponse.organizationalUnits)
      };

      if (ownerId) {
        loadCustomerOrganizationalUnits();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ownerId]);


  useEffect(()=>{
      setFilteredOptions([]);
      if(filter) {
        if (customerLabel) {
          setFilteredOptions((filteredOptions:any)=> [...filteredOptions, {customer:customerLabel}]);
        }

        if (filter.options && filter.options.organizationalUnitId && orgUnit) {
            let organizationalUnitString:any;
            if (typeof filter.options.organizationalUnitId === 'string') {
              organizationalUnitString = filter.options.organizationalUnitId.substring(3);
              organizationalUnitString = organizationalUnitString.split(',');
              Object.entries(orgUnit).map((el:any)=>{
                if (organizationalUnitString.includes(el[0].toString())) {
                  setFilteredOptions((filteredOptions:any)=> [...filteredOptions, {unit:el[1], id:el[0]}]);
                }
                return null;
              });
            } else {
              Object.entries(orgUnit).map((el:any)=> {
                if (filter.options.organizationalUnitId.includes(el[1])) {
                  setFilteredOptions((filteredOptions:any)=> [...filteredOptions, {unit:el[1], id:el[0]}]);
                }
                return null;
              })
            }
        }

      if(filter.customFields) {
        Object.entries(filter.customFields).map((el:any)=> {
            uniqueFilters(el[1], el[0]);
            return null;
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[filter, ownerId, orgUnit]);

  const uniqueFilters = (customFiltersArray:any, customFiltersId:any) => {
    let uniqueFilters = customFiltersArray.filter((val:any, id:any, array:any) => array.indexOf(val) === id);
    Object.values(customFilters).map((element:any)=> {
      if (parseInt(customFiltersId) === element.id) {
        uniqueFilters.map((el:any)=> {
          setFilteredOptions((filteredOptions:any)=> [...filteredOptions, {filter:el, id:customFiltersId, name:element.name}]);
          return null;
        })
      }
      return null;
    })
  };

  const tagFilter = async(el:any) => {
    let parsedValues = filter;

    if (el['customer']) {
      clearAllFilter();
      setFilteredOptions([]);
    }

    if (el.unit) {
      if (typeof filter.options.organizationalUnitId === 'string') {
        let organizationalUnitString = parsedValues.options.organizationalUnitId.substring(3);

        organizationalUnitString = organizationalUnitString.split(',');

        organizationalUnitString.splice(organizationalUnitString.indexOf((el.id).toString()), 1);

        organizationalUnitString = organizationalUnitString.join(',');

        organizationalUnitString = `in:${organizationalUnitString}`;

        if (organizationalUnitString === 'in:') {
          let accountStatus = parsedValues.options.accountStatus;
          parsedValues = {
            filter: {
              ...parsedValues,
              options: {
                accountStatus,
              }
            }
          }
        } else {
          parsedValues = {
            filter: {
              ...parsedValues,
              options: {
                ...parsedValues.options,
                organizationalUnitId : organizationalUnitString
              }
            }
          }
        }
      } else {
        let organizationalUnitIdList = filter.options.organizationalUnitId;
        organizationalUnitIdList.splice(organizationalUnitIdList.indexOf((el.id).toString()), 1);

        parsedValues = {
          filter: {
            ...parsedValues,
            options: {
              organizationalUnitId : organizationalUnitIdList
            }
          }
        }
      }
      setFilterBarValues(parsedValues);

    }

    if (el.filter) {

      let customField:any = parsedValues.customFields[`${el.id}`];
      let customFields:any = {};

      customField.splice(customField.indexOf(el.filter), 1);

      customFields[el.id] = customField;

      parsedValues = {
        filter: {
          ...parsedValues,
          customFields: {
            ...parsedValues.customFields,
            [el.id]: customField
          }
        }
      };
      setFilterBarValues(parsedValues);
    }
  };

  const clearFilterBar = () =>{
    clearAllFilter();
    setFilteredOptions([]);
  };

  return (
    <div className='filter-by-label'>
      <FormattedMessage id='general.filtered_by' />:
        {
          Object.values(filteredOptions).map((el:any)=>{
              return (
                <div  className='custom-filter-tags'>
                  {el.customer ?
                    <div>
                      {el.customer ? `${intl.formatMessage({id: 'general.customer'})}: ${el.customer}` : null}
                      <i onClick={()=>{tagFilter(el)}} style={{paddingLeft:'5px', paddingRight:'3px', cursor:'pointer'}} className="fal fa-times"></i>
                    </div>
                    : null
                  }
                  {el.unit ?
                    <div >
                      {el.unit ? `${intl.formatMessage({id: 'users.filter_bar_unit'})}: ${el.unit}` : null}
                        <i onClick={()=>{tagFilter(el)}} style={{paddingLeft:'5px', paddingRight:'3px', cursor:'pointer'}} className="fal fa-times"></i>

                    </div>
                    : null
                  }
                  {el.name ?
                    <div>
                      {el.name ? `${el.name}: ${el.filter}` : null}
                        <i onClick={()=>{tagFilter(el)}} style={{paddingLeft:'5px', paddingRight:'3px', cursor:'pointer'}} className="fal fa-times"></i>
                    </div>
                    : null
                  }
                </div>
              )
          })
        }
      <Button
        type='link'
        className='clear-all-button'
        onClick={()=>{clearFilterBar()}}
        style={{ whiteSpace: 'nowrap' }}
      >
        <FormattedMessage id="users.clear_all" />
      </Button>
    </div>
  )
};

export default (withRouter(FilterBar));
