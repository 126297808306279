import React, { ReactElement } from 'react';
import { Space } from 'antd';
import styles from './styles.module.scss';

interface FlexRowProps {
  left?: ReactElement;
  right?: ReactElement;
  size?: 'small' | 'middle' | 'large' | number;
  flex?: 'center' | 'end';
  alignItems?: boolean;
}

// I don't quite like the name of this component, but I can't think of a better alternative
const FlexRow: React.FC<FlexRowProps> = ({ left, right, size = 'small', flex = 'center', alignItems = false }) => {

  const getClassName = () => {

    let className = '' 

    if (flex === 'center') {
      className += styles.flexRow;
    } else {
      className += styles.flexRowEnd;
    }

    if (alignItems) {
      className = styles.flexRowAlignCenter;
    }

    return className;
  }

  return (
  <div className={getClassName()}>
    {left && (
      <div>
        <Space size={size}>{left}</Space>
      </div>
    )}

    {/* If gap for flexbox was more widely supported this wouldn't be necessary, unfortunately, Safari doesn't support it */}
    {left && right && <div className={styles.flexRowSpacer} />}

    {right && (
      <div className={styles.flexRowRight}>
        <Space size={size}>{right}</Space>
      </div>
    )}
  </div>
)};

export default FlexRow;
