import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { RotateLeftOutlined, RotateRightOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { Button, message, Progress, Space } from 'antd';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import Spinner from 'components/Spinner';
import FormattedMessage from 'react-intl/lib/components/message';
import { useBeforeunload } from 'react-beforeunload';
import moment from 'moment';
import './styles.scss';

const mapStateToProps = (state: any) => ({ session: state.session });

interface PdfMaterialPlayerProps extends RouteComponentProps<any> {
    learningActivityId: number;
    activityAttemptId?: number;
    type: 'learn' | 'test';
    isFirstAttempt?: boolean;
    session?: any;
    parentActivityId?: number;
    campaignId?: string;
    language?: string;
    version?: string;
}

type ServerRequestType = {
    learningActivityId: number;
    activityAttemptId?: number;
    type: 'learn' | 'test';
    userTypeUuid: string;
    language?: string;
    version?: string;
}

const PdfMaterialPlayer: React.FC<PdfMaterialPlayerProps> = ({
    learningActivityId, activityAttemptId, type,
    isFirstAttempt, session, history, parentActivityId, campaignId, language, version
}) => {
    const [pdfMaterialData, setPdfMaterialData] = useState<any>();
    const [imgSrc, setImgSrc] = useState<any>();
    const [currentPage, setCurrentPage] = useState(0);
    const [isMaterialLoading, setIsMaterialLoading] = useState(false);
    const [imgArray, setImgArray] = useState<any>({});
    const [isNextButtonLoading, setIsNextButtonLoading] = useState(false);
    const [rotation, setRotation] = useState(0);
    const [percent, setPercent] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [timerStart, seTimerStart] = useState<any>();
    const [imageStyle, setImageStyle] = useState<any>({
        transform: `rotate(0deg)`,
        width: '100%'
    });
    const [timer, setTimer] = useState<any>();
    const [timeoutId, setTimeoutId] = useState<any>(0);

    const intl = useIntl();

    useEffect(() => {
        loadPdfMaterialPlayer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl, learningActivityId, activityAttemptId, type, isFirstAttempt, session]);

    document.addEventListener("visibilitychange", (event) => {
        if (document.visibilityState == "visible") {
            if (pdfMaterialData && pdfMaterialData.settings.seconds_on_slide && timer) {
                setIsNextButtonLoading(true);
                const pause = pdfMaterialData.settings.seconds_on_slide - timer
                if (pause > 0) {
                    setTimeout(function () { setIsNextButtonLoading(false) }, pause * 1000);
                }
            }
        } else {
            if (timerStart) {
                clearTimeout(timeoutId);
                setTimer(~~(moment().diff(timerStart) / 1000));
            }
        }
    });

    const loadPdfMaterialPlayer = async () => {
        setIsMaterialLoading(true);

        try {

            if (!learningActivityId || !type) {
                history.goBack();
            }

            const parsedValues: ServerRequestType = {
                learningActivityId,
                activityAttemptId,
                type,
                userTypeUuid: session.active_user_type_uuid
            };

            if (language) {
                parsedValues.language = language
            }

            if (version) {
                parsedValues.version = version
            }

            const response = await coursesApiClient.request(`/api/v1/courses/serve-request/get-pdf-material`, parsedValues, 'POST');

            setPdfMaterialData(response.material);
            startActivity(response.material.uuid);
            let lastPage = response.material.lastPage ? parseInt(response.material.lastPage) : 0;

            loadImage(response.material, lastPage, response.material.pages)
            if (isFirstAttempt) {
                consumeLicenceAccess();
            }
        } catch (error: any) {
            if (error.message === 'already_active_scorm') {
                message.error(intl.formatMessage({ id: 'error.already_active_activity' }));
            } else {
                console.error(error);
                message.error(intl.formatMessage({ id: 'error.data_load' }));
            }
        }
    }

    const consumeLicenceAccess = async () => {
        try {
            const values = {
                activityId: learningActivityId,
                userTypeId: session.active_user_type_id,
                customerId: session.organization.organization_id,
                parentActivityId: parentActivityId,
                campaignId: campaignId
            }

            return await apiClient.request('/api/v1/licences/consume', values, 'POST');
        } catch (error) {
            message.error(intl.formatMessage({ id: 'error.data_load' }));
            console.error(error);
        }
    }

    /**
     * 
     * @param data 
     * @param page 
     * @param lastPage 
     */
    const loadImage = async (data: { pages: number, path: string, title: string }, page: number = 0, lastPage: number = 0) => {
        setPercent((page / (lastPage ? lastPage - 1 : pdfMaterialData.pages - 1)) * 100)
        try {
            setCurrentPage(page);

            if (maxPage <= page) {
                setIsNextButtonLoading(true);
                setMaxPage(page)
                seTimerStart(moment());
            } else {
                seTimerStart(null);
            }

            if (imgArray && imgArray[page]) {
                setImgSrc(imgArray[page])
            } else {
                setIsMaterialLoading(true);
                let path

                let totalPages = pdfMaterialData ? pdfMaterialData.pages : data.pages;

                if (totalPages !== 1) {
                    path = data.path + '/' + data.title + '-' + page + '.jpg';
                } else {
                    path = data.path + '/' + data.title + '.jpg';
                }

                const response = await apiClient.request(`/api/v1/courses/student/pdf/screen/${path}`, {}, 'GET', true, true);

                let imgBlob = URL.createObjectURL(response)

                setImgArray((imgArray: any) => ({ ...imgArray, [page]: imgBlob }));
                setImgSrc(imgBlob);
            }

            buttonLoadingTimer(data);
        } catch (error) {
            message.error(intl.formatMessage({ id: 'error.data_load' }));
            console.error(error);
        } finally {
            setIsMaterialLoading(false)
            if (imgArray && !imgArray[page + 1]) {
                loadNext(data, page + 1);
            }
        }
    }

    /**
     * 
     * @param data 
     * @param page 
     */
    const loadNext = async (data: { pages: number, path: string, title: string }, page: number) => {
        const path = data.path + '/' + data.title + '-' + page + '.jpg';
        const response = await apiClient.request(`/api/v1/courses/student/pdf/screen/${path}`, {}, 'GET', true, true);
        let imgBlob = URL.createObjectURL(response)
        setImgArray((imgArray: any) => ({ ...imgArray, [page]: imgBlob }));
    }

    /**
     * 
     * @param uuid 
     */
    const startActivity = (uuid: string) => {
        coursesApiClient.request(`/api/v1/courses/pdf-results/${uuid}/initialize`, {}, 'POST');
    }

    const endActivity = () => {
        coursesApiClient.request(`/api/v1/courses/pdf-results/${pdfMaterialData.uuid}/finish`, { currentPage }, 'POST');
    }

    useBeforeunload((event) => {
        if (pdfMaterialData) {
            endActivity();

            if (currentPage !== pdfMaterialData.pages - 1) {
                event.preventDefault();
            }
        }
    });

    const finish = async () => {
        try {
            await coursesApiClient.request(`/api/v1/courses/pdf-results/${pdfMaterialData.uuid}/finish`, { currentPage }, 'POST');
            window.close();
        } catch (error) {
            message.error(intl.formatMessage({ id: 'error.data_load' }));
            console.error(error);
        }
    }

    const buttonLoadingTimer = (data: any) => {
        const timeout = setTimeout(function () { setIsNextButtonLoading(false) }, data.settings.seconds_on_slide * 1000);
        setTimeoutId(timeout)
    }

    const changeWidthWithHeight = (direction: 'right' | 'left') => {
        let degree = rotation;

        if (direction === 'right') {
            degree += 90
        } else if (direction === 'left') {
            degree -= 90
        }

        let newImageStyle = {};

        if (imageStyle.marginTop) {
            newImageStyle = {
                transform: `rotate(${degree}deg)`,
                width: '100%'
            }
        } else {
            newImageStyle = {
                transform: `rotate(${degree}deg)`,
                marginTop: '35%',
                width: '100%',
                marginBottom: '35%'
            }
        }

        setRotation(degree);
        setImageStyle(newImageStyle);
    }

    return (
        <>
            <Spinner spinning={isMaterialLoading}>
                <div className="image-wrapper">
                    {imgSrc &&
                        <img
                            alt=''
                            style={imageStyle}
                            className='pdf-image'
                            src={imgSrc}
                        />
                    }

                </div>
            </Spinner >
            <div className='pdf-progress-bar'>
                {pdfMaterialData &&
                    <Progress showInfo={false} percent={percent} />
                }
            </div>
            <div className='pdf-material-toolbar'>
                <Space>
                    {pdfMaterialData &&
                        <>
                            <Button onClick={() => { changeWidthWithHeight('left') }}>
                                <RotateLeftOutlined />
                            </Button>
                            <Button onClick={() => { changeWidthWithHeight('right') }}>
                                <RotateRightOutlined />
                            </Button>
                        </>
                    }
                </Space>
                {pdfMaterialData &&
                    <div>
                        {currentPage + 1} / {pdfMaterialData.pages}
                    </div>
                }
                <Space>
                    {currentPage !== 0 &&
                        < Button type='primary' onClick={() => loadImage(pdfMaterialData, currentPage - 1)}>
                            <FormattedMessage id='campaign.previous' />
                        </Button>
                    }
                    {pdfMaterialData && currentPage !== pdfMaterialData.pages - 1 &&
                        <Button disabled={isNextButtonLoading} type='primary' onClick={() => { loadImage(pdfMaterialData, currentPage + 1); }}>
                            <FormattedMessage id='general.next' />
                        </Button>
                    }
                    {pdfMaterialData && currentPage === pdfMaterialData.pages - 1 &&
                        <Button type='primary' onClick={() => finish()}>
                            <FormattedMessage id='general.finish' />
                        </Button>
                    }
                </Space>
            </div>
        </>

    );
}

export default connect(mapStateToProps)(withRouter(PdfMaterialPlayer));
