import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Modal, Select as AntDSelect, Form, Radio, DatePicker, Checkbox, Tooltip } from 'antd';
import apiClient from 'utils/apiClient';
import { withRouter } from 'react-router-dom';
import { Select, Input } from 'components/Form';
import { connect } from 'react-redux';
import moment from 'moment';
import CRONBuilder from 'components/CRONBuilder';
import datepickerTranslations from 'shared/datepickerTranslations';
import './styles.scss';

const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 15 },
};

const mapStateToProps = (state: any) => {
    return {
      session: state.session,
      locale: state.locale.locale
    }
  }

  const SendEmailModal: React.FC<any> = ({session,
    ownerId,
    ownerType,
    users,
    visible,
    onCancel,
    afterSubmit,
    cameFromCampaign,
    campaignId,
    selectAll,
    getRecords,
    resourceUrl,
    locale,
    campaignStatus
  }) => {
    const [CRONExpression, setCRONExpression] = useState<string|null>(null);
    const [emailTemplateCategory, setEmailTemplateCategory] = useState<number|null>(null);
    const [emailTemplates, setEmailTemplates] = useState<any>([]);
    const [isEmailTemplatesLoading, setIsEmailTemplatesLoading] = useState(false);
    const [whenToSend, setWhenToSend] = useState(0);
    const [templateName, setTemplateName] = useState<any>(null);
    const [categoryName, setCategoryName] = useState<any>(null);
    const [CCValue, setCCValue] = useState(false);
    const [iniPasswordReset, setIniPasswordReset] = useState(false);
    const [emailCategories, setEmailCategories] = useState();
    const [mailLimitInfoMessage, setMailLimitInfoMessage] = useState('');

    const [form] = Form.useForm();
    const intl = useIntl();

    useEffect(()=> {
      const loadEmailCategories = async () => {
        form.setFieldsValue({
          progress: ['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED']
        });

        let categoriesResponse = await apiClient.request(`/api/v1/mail-templates/mail-categories`, {}, 'GET');
        Object.entries(categoriesResponse).map((el:any)=> {
          if (el[1] === 'system_events') {
            delete categoriesResponse[el[0]]
          }
          return categoriesResponse;
        })
        setEmailCategories(categoriesResponse);
      }
      form.setFieldsValue({
        category: [],
      });
        loadEmailCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownerType])

    useEffect(()=> {
      const loadEmailTemplates = async () => {
        setIsEmailTemplatesLoading(true);
        try {
          if (session.active_user_type === "SUPER_ADMIN"  ) {
          let optionsResponse;
          switch (ownerType) {
            case 'RESELLER':
              optionsResponse = await apiClient.request(`/api/v1/organizations/${ownerId}/mail-templates/${emailTemplateCategory}/options`, 'GET');
              setEmailTemplates(optionsResponse.mailTemplates);
              break;
            case 'CUSTOMER':
              optionsResponse = await apiClient.request(`/api/v1/customers/${ownerId}/mail-templates/${emailTemplateCategory}/options`, 'GET');
              setEmailTemplates(optionsResponse.mailTemplates);
              break;
          }
          } else if (session.organization.organization_type === "ORGANIZATION") {
            let optionsResponse;
            switch (ownerType) {
                case 'RESELLER':
                  optionsResponse = await apiClient.request(`/api/v1/organizations/${ownerId}/mail-templates/${emailTemplateCategory}/options`, 'GET');
                  setEmailTemplates(optionsResponse.mailTemplates);
                  break;
                case 'CUSTOMER':
                  optionsResponse = await apiClient.request(`/api/v1/customers/${ownerId}/mail-templates/${emailTemplateCategory}/options`, 'GET');
                  setEmailTemplates(optionsResponse.mailTemplates);
                  break;
              }
          } else if (session.organization.organization_type === "CUSTOMER") {

            let optionsResponse = await apiClient.request( `/api/v1/customers/${session.organization.organization_id}/mail-templates/${emailTemplateCategory}/options`, 'GET');
            setEmailTemplates(optionsResponse.mailTemplates);
          } else {

          }
        } catch (error) {
            message.error(intl.formatMessage({id: "error.data_load"}));
            console.error(error);
        } finally {
          setIsEmailTemplatesLoading(false);
        }
      };

    form.setFieldsValue({
      templateId: [],
    });
    if (emailTemplateCategory) {
      loadEmailTemplates();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ownerType, emailTemplateCategory])

    const submitForm = async (values:any) => {
      let recipientsData = {}

      if (selectAll) {
        let usersResponse = await getRecords(resourceUrl, '')
        recipientsData = usersResponse.data
      } else {
        recipientsData = users
      }

      let usersTypeId:any =[]

      Object.values(recipientsData).map((el:any)=> {
        if (el.customerAdminUserTypeId) {
         usersTypeId.push(el.customerAdminUserTypeId)
        } else if (el.studentUserTypeId) {
         usersTypeId.push(el.studentUserTypeId)
        } else if (el.userTypeId) {
         usersTypeId.push(el.userTypeId)
        }
        return usersTypeId;
      })

      let description = `${categoryName}: ${templateName}`

      let parsedValues:any
      parsedValues = {
        ...parsedValues,
        category: values.category,
        templateId: values.templateId,
        description: description,
        cc: CCValue ? 1 : 0,
        iniPasswordReset: iniPasswordReset ? 1 : 0,
        ownerType: ownerType,
        ownerReferenceId: ownerId,
        status: 'ACTIVE',
        scenarioType: 'CUSTOM',
        emailLimit: values.emailLimit,
      }

    if (cameFromCampaign) {
      parsedValues = {
        ...parsedValues,
        status: campaignStatus === 'DRAFT' ? 'INACTIVE' : 'ACTIVE',
        campaignId: campaignId,
        learningCampaign: 1,
        to: {
          userTypes: ['STUDENT'],
          options: {
            accountStatus: 'ACTIVE'
          },
          customFields:{},
          campaign: {
            campaignId: campaignId,
            progress: values.progress
          }
        }
      }
    } else {
      parsedValues = {
        ...parsedValues,
        toSpecificUsers: usersTypeId
      }
    }
    switch(whenToSend) {
      case 0:
        parsedValues.sendEmailNow = true;
        parsedValues.startDate = moment().format('YYYY-MM-DD');
        parsedValues.endDate = moment().format('YYYY-MM-DD');

        break;
      case 1:
        parsedValues.sendEmailNow = false;
        parsedValues.startDate = moment().format('YYYY-MM-DD');
        parsedValues.endDate = moment().format('YYYY-MM-DD');
        parsedValues.scheduleDate = values.scheduleDate.format('YYYY-MM-DD HH:mm');
        break;
      case 2:
        parsedValues.sendEmailNow = false;
        parsedValues.startDate = values.period[0].format('YYYY-MM-DD');
        parsedValues.endDate = values.period[1].format('YYYY-MM-DD');
        parsedValues.cronExpression = CRONExpression;
        break;
    }

      try {
        await apiClient.request('/api/v1/mail-scenarios', parsedValues, 'POST');
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({id: "error.data_load"}));
      } finally {
        message.success(intl.formatMessage({id: 'sucess.email_scenario_successfully_created'}));
        form.resetFields();
        form.setFieldsValue({
          progress: ['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED']
        });
        if(afterSubmit){
          afterSubmit();
        }
      }
    }

    const calculateMailDeliveryTime = (recipients: any, limit: any) => {

      if (limit > 0 && recipients > 0) {
        let add = (recipients % limit === 0) ? 0 : 1;

        let hours = (Math.trunc(recipients / limit) + add) - 1;

        let message = intl.formatMessage({id: "email_delivery_intensity_info"}, {hours: hours});
        setMailLimitInfoMessage(message)
      } else {
        setMailLimitInfoMessage('')
      }
    }

  return (
  <Form form={form} onFinish={submitForm}>
    <Modal
      className="scenarios-modal"
      visible={visible}
      onCancel={onCancel}
      onOk={() => {
        form.submit();
      }}
      okText={intl.formatMessage({id: 'general.send'})}
      title={intl.formatMessage({id: 'general.send_emails'})}
    >
      <Select
          className='send-scenario-modal-select'
          style={{width:'100%'}}
          name='category'
          showArrow
          label={intl.formatMessage({id: "emailing.scenarios.e_mail_category"})}
          manualOptions={emailCategories}
          integerKey={true}
          customObjLabel={(el: string) => intl.formatMessage({id: `email_templates.categories.${el}`})}
          onChange={(value: number, {systemName}: any) => {setEmailTemplateCategory(value); setCategoryName(systemName);form.setFieldsValue({templateId: null})}}
          withSystemName={true}
          rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
          customLayout
      />
      <Form.Item
          className = 'scenario-email-required'
          name='templateId'
          label={intl.formatMessage({id: 'emailing.scenarios.email_template'})}
          help={emailTemplateCategory ? undefined : intl.formatMessage({id: 'emailing.scenarios.select_email_category'})}
          rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
      >
        <AntDSelect showArrow loading={isEmailTemplatesLoading} className={'default-select'} disabled={!emailTemplateCategory} onChange={(value: any, props: any) => {setTemplateName(props.children); }}>
            {emailTemplates.filter((el: any) => el.category_id === emailTemplateCategory).map((el: any) =>
              <AntDSelect.Option value={parseInt(el.id)}>{el.name[locale] ? el.name[locale] : Object.values(el.name)[0]}</AntDSelect.Option>
           )}
        </AntDSelect>
      </Form.Item>
        {
          cameFromCampaign ?
            <Form.Item
              name="progress"
              label={
                <span>
                  {intl.formatMessage({id: 'general.progress'})}
                  <Tooltip title={intl.formatMessage({id: 'general.progress.hint'})}>
                    <i className='fal fa-question-circle header-item' />
                  </Tooltip>
                </span>
              }
            >
              <Checkbox.Group>
                <Checkbox value="NOT_STARTED" style={{ lineHeight: '32px' }}><FormattedMessage id='general.not_started'/></Checkbox>
                <Checkbox value="IN_PROGRESS" style={{ lineHeight: '32px' }}><FormattedMessage id='general.in_progress'/></Checkbox>
                <Checkbox value="COMPLETED" style={{ lineHeight: '32px' }}><FormattedMessage id='general.completed'/></Checkbox>
              </Checkbox.Group>
            </Form.Item>
          : null
        }
        <Form.Item label={intl.formatMessage({id: 'emailing.scenarios.when_to_send'})}>
            <Radio.Group onChange={(el) => setWhenToSend(el.target.value)} value={whenToSend}>
                <Radio value={0}><FormattedMessage id='emailing.scenarios.now' /></Radio>
                <Radio value={1}><FormattedMessage id='emailing.scenarios.on_a_specific_time' /></Radio>
                <Radio value={2}><FormattedMessage id='emailing.scenarios.recurring' /></Radio>
            </Radio.Group>
        </Form.Item>
        {
            whenToSend === 1
            ? <Form.Item label={intl.formatMessage({id: 'emailing.scenarios.on_a_specific_time'})} name='scheduleDate' rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}>
            <DatePicker
            className='scroll-bar-scenario-modal'
            format="YYYY-MM-DD HH:mm"
            disabledDate={(current: any) => current && current < moment().subtract(1, 'day').endOf('day')}
            showTime={{ defaultValue: moment('00:00:00', 'HH:mm') }}
            placeholder={intl.formatMessage({id: 'users.select_date'})}
            />
            </Form.Item>
            : null
        }
        {
            whenToSend === 2
            ?
            <>
            <Form.Item
                label={intl.formatMessage({id: 'emailing.scenarios.recurring'})}
            >
                <CRONBuilder
                    className='scroll-bar-scenario-modal'
                    onChange={(value: string|null) => setCRONExpression(value)}
                    initialValue={null}
                />
            </Form.Item>
            <Form.Item {...formItemLayout} name='period' label={intl.formatMessage({id: 'emailing.scenarios.period'})} rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]} >
            <DatePicker.RangePicker
              format="YYYY-MM-DD"
              disabledDate={(current: any) => current && current < moment().subtract(1, 'day').endOf('day')}
              locale={datepickerTranslations(intl)}
            />
            </Form.Item>
            </>
            : null
        }
        <Form.Item name='cc' label={intl.formatMessage({id: 'emailing.scenarios.copy_cc_direct_manager'})}>
          <Checkbox checked={CCValue} onChange={() => setCCValue(!CCValue)} />
        </Form.Item>
        <Form.Item name='iniPasswordReset' label={intl.formatMessage({id: 'emailing.scenarios.initialize_password_reset'})}>
          <Checkbox checked={iniPasswordReset} onChange={() => setIniPasswordReset(!iniPasswordReset)} />
        </Form.Item>
        <Input
          type='text'
          name='emailLimit'
          onChange={(e:any) => calculateMailDeliveryTime(users.length, e.target.value)}  label={intl.formatMessage({id: 'emailing.scenarios.maximum_number_of_emails_to_be_sent_in_one_hour'})}
          style={{marginBottom:'10px'}}
          customLayout
        >
        </Input>
        <div
          style={{marginLeft:'33%',marginBottom:10}}
        >
          {mailLimitInfoMessage}
        </div>
      </Modal>
    </Form>
  )
}

export default connect(mapStateToProps )(withRouter(SendEmailModal));
