import {PickerLocale} from 'antd/lib/date-picker/generatePicker';
import { IntlShape } from 'react-intl';

const DatepickerTranslations = (intl: IntlShape) => {
    let translations : PickerLocale = {
        timePickerLocale:{}, 
        lang: {
            placeholder: '', 
            locale: '', 
            yearFormat: '', 
            today: '',
            now: '',
            backToToday: '',
            ok: '',
            timeSelect: '',
            dateSelect: '',
            clear: '',
            month: '',
            year: '',
            previousMonth: '',
            nextMonth: '',
            monthSelect: '',
            yearSelect: '',
            decadeSelect: '',
            dayFormat: '',
            dateFormat: '',
            dateTimeFormat: '',
            previousYear: '',
            nextYear: '',
            previousDecade: '',
            nextDecade: '',
            previousCentury: '',
            nextCentury: '',
            rangePlaceholder: [intl.formatMessage({id: "general.start_date"}), intl.formatMessage({id: "general.end_date"})]
        }
    }

    return translations;
}

export default DatepickerTranslations;
