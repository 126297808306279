import * as ActionTypes from 'store/actionTypes';

const defaultState = {
    enrollmentId: null,
    campaignId: null,
    customerId: null,
    userUuid: null,
    campaignTitle: null
}

const studentViewData = (state = defaultState, action: any) => {
    switch (action.type) {
        case ActionTypes.SAVE_STUDENT_VIEW_DATA:
            var studentViewData = action.payload;
            return ({ ...state, ...studentViewData });
        case ActionTypes.CLEAR_STUDENT_VIEW_DATA:
            return { ...defaultState };
        default:
            return state;
    }
}

export default studentViewData;