import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Modal, Tooltip, Checkbox, } from 'antd';
import { Select } from 'components/Form';
import CustomValuesSelect from "../../Form/CustomValuesSelect";
import Spinner from "../../Spinner";
import { StudentFilterModalInterface } from '../types';

const mapStateToProps = (state: any) => {
    return {
        activeUserRole: state.session.active_user_type
    }
}

const formLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};

const StudentFilterModal: React.FC <StudentFilterModalInterface> = ({
    visible,
    onCancel,
    owner,
    getRecords,
    pageSize,
    setStudents,
    resourceUrl,
    setCustomFilterValues,
    setTotalRecords,
    campaignId,
    setUsersFilter,
    form,
    activeUserRole
}) => {

    const intl = useIntl();

    const [organizationalUnitUrl, setOrganizationalUnitUrl] = useState('');
    const [isCustomFieldsLoading, setIsCustomFieldsLoading] = useState(false);

    useEffect(() => {
        form.setFieldsValue({
            progress: ['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED']
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl])

    const submitForm = async (values: any) => {
        const existingFields = ['progress', 'organizationalUnitId'];

        let pageQuery = 'page[number]=' + 1 + '&page[size]=' + pageSize;

        let parsedValues: any = {
            campaignId: campaignId,
            append: false,
            filter: {
                userTypes: ["STUDENT"],
                options: {
                    accountStatus: `in:ACTIVE`,
                    customerId: `in:${owner?.id}`
                }
            }
        }

        if (values.progress) {
            parsedValues.filter.progress = values.progress;
        }
        
        if (values.organizationalUnitId?.length) {
            parsedValues.filter.options.organizationalUnitId = 'in:' + values.organizationalUnitId.join()
        }

        let customFields: any = {};
        let customFiltersExist = false;

        Object.entries(values).map((el: any) => {
            if (!existingFields.includes(el[0]) && el[1] && el[1].length) {
                let customFilterId = el[0].split('_');
                customFields[customFilterId[customFilterId.length - 1]] = el[1];
                customFiltersExist = true;
            }
            return customFields;
        });

        if (parsedValues.filter && customFiltersExist) {
            parsedValues.filter.customFields = customFields;
        }

        setUsersFilter(parsedValues.filter)


        onCancel();
        const students = await getRecords(resourceUrl, pageQuery, parsedValues);

        setStudents(students.data);
        setTotalRecords(students.pagination.total);
    }

    useEffect(() => {
        if (owner) {
            let url = '/api/v1/customer/organizational-unit-types';
            if (activeUserRole === 'SUPER_ADMIN' || activeUserRole === 'RESELLER_ADMIN' || activeUserRole === 'DISTRIBUTOR_ADMIN') {
                url = url + '?customerId=' + owner.id;
            }

            setOrganizationalUnitUrl(url);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [owner])

    return (
        <Modal
            className="user-list-modal"
            visible={visible}
            title={intl.formatMessage({ id: 'users.user_filter' })}
            onOk={() => form.submit()}
            onCancel={onCancel}
            okText={intl.formatMessage({ id: 'general.filter' })}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
            forceRender={true}
        >
            <Form form={form} onFinish={submitForm}>
                <Form.Item
                    {...formLayout}
                    name="progress"
                    label={
                        <span>
                            {intl.formatMessage({ id: 'general.progress' })}
                            <Tooltip title={intl.formatMessage({ id: 'general.progress.hint' })}>
                                <i className='fal fa-question-circle header-item' />
                            </Tooltip>
                        </span>
                    }
                >
                    <Checkbox.Group>
                        <Checkbox value="NOT_STARTED" style={{ lineHeight: '32px' }}><FormattedMessage id='general.not_started' /></Checkbox>
                        <Checkbox value="IN_PROGRESS" style={{ lineHeight: '32px' }}><FormattedMessage id='general.in_progress' /></Checkbox>
                        <Checkbox value="COMPLETED" style={{ lineHeight: '32px' }}><FormattedMessage id='general.completed' /></Checkbox>
                    </Checkbox.Group>
                </Form.Item>

                <Select
                    mode="multiple"
                    allowClear
                    showSearch
                    name='organizationalUnitId'
                    dataKey='organizationalUnits'
                    maxTagCount={1}
                    showArrow
                    label={intl.formatMessage({ id: "general.unit" })}
                    saveLoadedData={false}
                    url={organizationalUnitUrl}
                    sortOptions={function (a: any, b: any) {
                        let textA = a[1].toUpperCase();
                        let textB = b[1].toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }}
                    classifierIsObject={true}
                    integerKey={true}
                    customLayout={formLayout}
                    filterOption={(input: string, option: any) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                />
                <Spinner spinning={isCustomFieldsLoading}>
                    <span className="customer-custom-values">
                        <CustomValuesSelect
                            customerId={owner?.id}
                            allowClear
                            showArrow
                            setLoading={setIsCustomFieldsLoading}
                            saveLoadedData={true}
                            setCustomFilterValues
                            customLayout={formLayout}
                            setLoadedData={setCustomFilterValues}
                            role={activeUserRole}
                            filterOption={(input: string, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                        />
                    </span>
                </Spinner>
            </Form>
        </Modal>
    )
}

export default connect(mapStateToProps)(StudentFilterModal);
