import React from 'react';
import {Form, Checkbox} from 'antd';
import {FormattedMessage, useIntl} from 'react-intl';
import {UserFilterCheckboxInterface} from '../types';
import {Switch} from 'components/Form';

const formItemCheckboxLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};

const UserFilterCheckBoxes: React.FC<UserFilterCheckboxInterface> = ({modalFormItemVisible, filterBy}) => {
    const intl = useIntl();

    return (
        <>
            <Form.Item {...formItemCheckboxLayout}
                       name="accountStatus"
                       label={intl.formatMessage({id: 'users.user_status'})}
                       style={modalFormItemVisible !== true ? {display: 'none'} : {display: ''}}
            >
                <Checkbox.Group>
                    <Checkbox checked={true} value="ACTIVE" style={{lineHeight: '32px'}}><FormattedMessage
                        id='user.status.ACTIVE'/>
                    </Checkbox>
                    <Checkbox checked={true} value="BLOCKED" style={{lineHeight: '32px'}}><FormattedMessage
                        id='user.status.BLOCKED'/>
                    </Checkbox>
                </Checkbox.Group>
            </Form.Item>
            <Form.Item
                {...formItemCheckboxLayout}
                name="active"
                label={intl.formatMessage({id: 'users.registration_status'})}
            >
                <Checkbox.Group>
                    <Checkbox checked={true} value={true} style={{lineHeight: '32px'}}><FormattedMessage
                        id='user.status.ACTIVE'/>
                    </Checkbox>
                    <Checkbox checked={true} value={false} style={{lineHeight: '32px'}}><FormattedMessage
                        id='user.status.INACTIVE'/>
                    </Checkbox>
                </Checkbox.Group>
            </Form.Item>
            <Form.Item
                {...formItemCheckboxLayout}
                name="userTypes"
                label={intl.formatMessage({id: 'users.user_type'})}
            >
                <Checkbox.Group>
                    {filterBy === 'ORGANIZATION' ?
                        <Checkbox value="ADMIN" style={{lineHeight: '32px'}}>
                            <FormattedMessage id='user.type.ADMIN'/>
                        </Checkbox>
                        : null
                    }
                    {filterBy === 'CUSTOMER' ?
                        <Checkbox value="CUSTOMER_ADMIN" style={{lineHeight: '32px'}}>
                            <FormattedMessage id='user.type.CUSTOMER_ADMIN'/>
                        </Checkbox>
                        : null
                    }
                    {filterBy === 'CUSTOMER' ?
                        <Checkbox value="STUDENT" style={{lineHeight: '32px'}}>
                            <FormattedMessage id='user.type.STUDENT'/>
                        </Checkbox>
                        : null
                    }
                </Checkbox.Group>
            </Form.Item>
            <Switch isFormItem customLayout={formItemCheckboxLayout} name='newUsers' label={intl.formatMessage({id: 'users.new_users'})}/>
        </>
    );
};

export default UserFilterCheckBoxes;
