import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {NavLink} from 'react-router-dom';
import {Menu, Input, Form, Button, Dropdown} from 'antd';
import {PlusOutlined, DownOutlined} from '@ant-design/icons';
import InstructionButton from "components/VideoInstructions/InstructionButton";
import FlexRow from "components/FlexRow";
import apiClient from "utils/apiClient";
import debounce from "lodash/debounce";
import UsersFilterModal from "../UsersFilterModal";
import UsersFilterBar from "../UsersFilterBar";
import ImportModal from "../../ImportModal";
import UsersExportModal from "../UsersExportModal";
import {UpdateUsersFilterType, UsersListToolbarProps} from '../types';

const UsersListToolbar: React.FC<UsersListToolbarProps> = ({
                                                               canAddLearningManagers,
                                                               activeOrganizationId,
                                                               activeOrganizationType,
                                                               activeOrganizationUuid,
                                                               showFilter, userRole,
                                                               setActiveOrganizationId,
                                                               setActiveOrganizationType,
                                                               setActiveOrganizationUuid,
                                                               setUsers,
                                                               currentPageSize,
                                                               setCurrentPage,
                                                               setTotalRecords,
                                                               usersFilter,
                                                               setUsersFilter,
                                                               setSelectedRowsLength,
                                                               selectedRowsLength,
                                                               users,
                                                               totalRecords,
                                                               setRowSelectedUsers,
                                                               selectAllUsers,
                                                               setSelectAllUsers,
                                                               setRowSelection,
                                                               rowSelection,
                                                               reloadUsers,
                                                               setReloadUsers,
                                                               setIsUsersLoading,
                                                               customerId,
                                                               filterNewUsers,
                                                               setFilterNewUsers
                                                           }) => {

    // Modals
    const [showFilterModal, setShowFilterModal] = useState<boolean>(false);
    const [showImportModal, setShowImportModal] = useState<boolean>(false);
    const [showExportModal, setShowExportModal] = useState<boolean>(false);

    const [searchText, setSearchText] = useState('');

    // Platform Access
    const [visiblePlatformAccessInfo, setVisiblePlatformAccessInfo] = useState<boolean>(false);
    const [validPlatformAccess, setValidPlatformAccess] = useState<boolean>(false);
    const [platformAccessInfo, setPlatformAccessInfo] = useState('');

    const [organizationResponse, setOrganizationResponse] = useState([]);
    const [customFilterResponse, setCustomFilterResponse] = useState([]);
    const [organizationalUnitResponse, setOrganizationalUnitResponse] = useState([]);

    const [isFilterLoading, setIsFilterLoading] = useState(false);
    const [updateFilterBar, setUpdateFilterBar] = useState<UpdateUsersFilterType>(false);

    const {Search} = Input;
    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
        if (userRole !== 'SUPERVISION' && activeOrganizationType === 'CUSTOMER') {
            setPlatformAccessInfoMessage(activeOrganizationId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeOrganizationId]);

    useEffect(() => {
        if (showFilter) {
            setShowFilterModal(true);
        }
    }, [showFilter]);

    const setPlatformAccessInfoMessage = async (selectedCustomer: any) => {
        if (selectedCustomer) {
            let platformAccessData = await apiClient.request(`/api/v1/customers/${selectedCustomer}/platform-access-data`, {}, 'GET');

            let available = platformAccessData.platformAccess.max_students - platformAccessData.platformAccess.current_students_count;

            if (available < 0) available = 0;

            if (available === 0) {
                setValidPlatformAccess(false)
            } else {
                setValidPlatformAccess(true)
            }

            setPlatformAccessInfo(available + '/' + platformAccessData.platformAccess.max_students);
            setVisiblePlatformAccessInfo(true);
        } else {
            setVisiblePlatformAccessInfo(false);
        }
    };

    const selectAllButton = (boolean: boolean) => {

        setSelectAllUsers(boolean);

        if (boolean) {
            setRowSelectedUsers(users);

            setRowSelection({
                filter: usersFilter.filter || {},
                selectedAllUsers: true,
                selectedUsers: [],
                deSelectedUsers: [],
                searchText: searchText
            });
            setSelectedRowsLength(totalRecords);

        } else {

            setRowSelectedUsers([]);
            setRowSelection({
                filter: {},
                selectedAllUsers: false,
                selectedUsers: [],
                deSelectedUsers: [],
                searchText: ''
            });

            setSelectedRowsLength(0);
        }
    };

    const loadOptionsTimeOut = debounce(function (text: string) {
        const searchText = text.replace(/\s+/g, '');
        if (text.length === 0 || searchText.length >= 3) {
            setSearchText(searchText);

            setRowSelection({
                filter: rowSelection.filter,
                selectedAllUsers: rowSelection.selectedAllUsers,
                selectedUsers: rowSelection.selectedUsers,
                deSelectedUsers: rowSelection.deSelectedUsers,
                searchText: searchText
            });
        }
    }, 800);

    const menu = (
        <Menu>

            {activeOrganizationId && activeOrganizationType === 'ORGANIZATION' ?
                <Menu.Item>
                    <NavLink
                        to={!activeOrganizationId ? `/admins/add` : `/organizations/${activeOrganizationId}/admins/add`}>
                        <FormattedMessage id='general.admin'/>
                    </NavLink>
                </Menu.Item>
                : null
            }
            {
                activeOrganizationId && activeOrganizationType === 'CUSTOMER' ?
                    <Menu.Item>
                        <NavLink
                            to={`/customer/${activeOrganizationId}/user/add/learning-managers`}>
                            <FormattedMessage id='general.learning_manager'/>
                        </NavLink>
                    </Menu.Item>
                    : null
            }
            {
                activeOrganizationId && activeOrganizationType === 'CUSTOMER' ?
                    <Menu.Item>

                        <NavLink to={`/customer/${activeOrganizationId}/user/add/students`}>
                            <FormattedMessage id='general.student'/>
                        </NavLink>
                    </Menu.Item>
                    : null
            }
        </Menu>
    );

    return (
        <div>
            <FlexRow
                left={
                    <>
                        {userRole !== 'SUPERVISION' &&
                        <>
                            {userRole === 'SUPER_ADMIN' || userRole === 'DISTRIBUTOR_ADMIN' || userRole === 'RESELLER_ADMIN' || canAddLearningManagers
                                ? (
                                    <Dropdown overlay={menu} placement="bottomLeft">
                                        <Button type="primary">
                                            <PlusOutlined/>
                                            <span><FormattedMessage id="users.add_user"/></span>
                                            <DownOutlined/>
                                        </Button>
                                    </Dropdown>
                                ) : (
                                    <NavLink to={`/customer/${activeOrganizationId}/user/add/students`}>
                                        <Button type="primary" icon={<PlusOutlined/>}>
                                            <FormattedMessage id="users.add_student"/>
                                        </Button>
                                    </NavLink>
                                )
                            }
                        </>
                        }
                        <Button
                            type="primary"
                            onClick={() => {
                                setShowFilterModal(true)
                            }}
                            loading={isFilterLoading}
                        >
                            <FormattedMessage id="general.filter"/>
                        </Button>
                        {userRole !== 'SUPERVISION' &&
                            <>
                                <Button type='primary' onClick={() => selectAllButton(!selectAllUsers)}>
                                    <FormattedMessage
                                        id={selectAllUsers ? 'users.deselect_all' : 'users.select_all'}/>
                                </Button>
                                <Button icon={<i className="fal fa-file-import"/>} onClick={() => setShowImportModal(true)}>
                                    <span><FormattedMessage id={intl.formatMessage({id: 'users.import.import'})}/></span>
                                </Button>
                                <Button icon={<i className="fal fa-file-export"/>} onClick={() => setShowExportModal(true)}>
                                    <span><FormattedMessage id={intl.formatMessage({id: 'users.export.export'})}/></span>
                                </Button>
                                {visiblePlatformAccessInfo && activeOrganizationType === 'CUSTOMER' && (
                                    <label>
                                        <FormattedMessage id={intl.formatMessage({id: 'users.available_student_access_licences'})}/>:{' '}
                                        <span style={{color: validPlatformAccess ? '#52c419' : 'red'}}>
                                            {platformAccessInfo}
                                        </span>
                                    </label>
                                )}
                        </>
                        }
                    </>
                }
                right={
                    <>
                        <Search
                            className="table-search-field"
                            allowClear
                            placeholder={intl.formatMessage({id: "general.search"})}
                            onChange={value => loadOptionsTimeOut(value.target.value)}
                        />
                        <InstructionButton position='USER_MANAGEMENT'/>
                    </>
                }
            />
            <br/>
            <UsersFilterBar
                userRole={userRole}
                filter={usersFilter}
                setFilter={setUsersFilter}
                activeOrganizationId={activeOrganizationId}
                activeOrganizationType={activeOrganizationType}
                organizationResponse={organizationResponse}
                customFilterResponse={customFilterResponse}
                organizationalUnitResponse={organizationalUnitResponse}
                setUpdateFilterBar={setUpdateFilterBar}
                form={form}
            />

            <UsersFilterModal
                showFilterModal={showFilterModal}
                setShowFilterModal={setShowFilterModal}
                activeOrganizationId={activeOrganizationId}
                activeOrganizationType={activeOrganizationType}
                activeOrganizationUuid={activeOrganizationUuid}
                userRole={userRole}
                setActiveOrganizationId={setActiveOrganizationId}
                setActiveOrganizationType={setActiveOrganizationType}
                setActiveOrganizationUuid={setActiveOrganizationUuid}
                setIsFilterLoading={setIsFilterLoading}
                setUsers={setUsers}
                currentPageSize={currentPageSize}
                setCurrentPage={setCurrentPage}
                setTotalRecords={setTotalRecords}
                usersFilter={usersFilter}
                setUsersFilter={setUsersFilter}
                setRowSelection={setRowSelection}
                setRowSelectedUsers={setRowSelectedUsers}
                setSelectedRowsLength={setSelectedRowsLength}
                setSelectAllUsers={setSelectAllUsers}
                searchText={searchText}
                setOrganizationResponse={setOrganizationResponse}
                organizationResponse={organizationResponse}
                setCustomFilterResponse={setCustomFilterResponse}
                setOrganizationalUnitResponse={setOrganizationalUnitResponse}
                updateFilterBar={updateFilterBar}
                setUpdateFilterBar={setUpdateFilterBar}
                form={form}
                reloadUsers={reloadUsers}
                setReloadUsers={setReloadUsers}
                setIsUsersLoading={setIsUsersLoading}
                customerId={customerId}
                filterNewUsers={filterNewUsers}
                setFilterNewUsers={setFilterNewUsers}
            />
            {userRole !== 'SUPERVISION' &&
            <>
                <ImportModal
                    visible={showImportModal}
                    onCancel={() => setShowImportModal(false)}
                    afterSubmit={() => setShowImportModal(false)}
                    customerId={activeOrganizationType === "CUSTOMER" ? activeOrganizationId : null}
                    setActiveOrganizationId={setActiveOrganizationId}
                    userRole={userRole}
                />
                <UsersExportModal
                    visible={showExportModal}
                    onCancel={() => setShowExportModal(false)}
                    afterSubmit={() => setShowExportModal(false)}
                    customerId={activeOrganizationId}
                    userRole={userRole}
                    selectAllUsers={selectAllUsers}
                    selectedRowsLength={selectedRowsLength}
                    rowSelection={rowSelection}
                />
            </>
            }
        </div>
    );
};

export default UsersListToolbar;
