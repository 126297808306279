import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import Button from 'components/Button';
import moment from 'moment';
import FormattedMessage from 'react-intl/lib/components/message';
import Spinner from 'components/Spinner';
import './styles.scss';

type SettingsType = {
    description: string;
    managerSign: boolean;
    managerSignDescription: string;
}

interface SignatureModalInterface {
    visible: boolean;
    onCancel: any;
    campaignId: number;
    enrollmentId: number;
    completed: boolean;
    reload: any;
    signature: boolean;
    date: string;
}

const SignatureModal: React.FC<SignatureModalInterface> = ({ visible, onCancel, campaignId, enrollmentId, completed, reload, signature, date }) => {
    const [signatureSettings, setSignatureSettings] = useState<SettingsType>({
        description: '',
        managerSign: false,
        managerSignDescription: ''
    });
    const [signatureIsLoading, setSignatureIsLoading] = useState(false);
    const [signatureSettingsIsLoading, setSignatureSettingsIsLoading] = useState(false);

    useEffect(() => {
        if (completed && campaignId) {
            loadCampaignSignSettings();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignId, completed]);

    const loadCampaignSignSettings = async () => {
        setSignatureSettingsIsLoading(true);
        let response = await coursesApiClient.request(`/api/v1/courses/student/${campaignId}/sign-settings`, [], 'GET');

        if (response.settings) {
            setSignatureSettings(response.settings);
        }
        setSignatureSettingsIsLoading(false);
    }

    const submit = async () => {
        setSignatureIsLoading(true);
        const parsedValues = {
            campaignId: campaignId,
            enrollmentId: enrollmentId
        }

        await coursesApiClient.request('/api/v1/courses/student/sign', parsedValues, 'POST');
        setSignatureIsLoading(false);
        reload();

        onCancel();
    }

    return (
        <Modal
            className='signature-modal'
            visible={visible}
            onCancel={onCancel}
            title={<span>
                <span style={{fontSize: 25}}>
                    <i style={{fontWeight: 600, color: '#C1E6FF'}} className='fal fa-user-edit'></i>
                </span>
                <FormattedMessage id='student.signature' />
            </span>}
            footer={
                !signature ?
                    <span>
                        <Button onClick={() => onCancel()}>
                            {!!signatureSettings.managerSign ?
                                <FormattedMessage id='general.accept' />
                                :
                                <FormattedMessage id='general.cancel' />
                            }
                        </Button>
                        {!signatureSettingsIsLoading && !signatureSettings.managerSign ?
                            < Button type='primary' loading={signatureIsLoading} onClick={() => submit()}>
                                <FormattedMessage id='general.sign' />
                            </Button>
                            :
                            null
                        }
                    </span >
                    : <span>
                        <i style={{ color: '#D9D9D9'}} className="fal fa-user-edit"></i>
                        {moment(date).format('HH:mm DD.MM.YYYY')}
                    </span>
            }
        >
            <Spinner spinning={signatureSettingsIsLoading} >
                {signature ? signatureSettings.description : !!signatureSettings.managerSign ? signatureSettings.managerSignDescription : signatureSettings.description}
            </Spinner>
        </Modal >
    );
}

export default SignatureModal;
