import React, { useState, useEffect } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, message, Tooltip, Radio, Input, Button } from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import Spinner from 'components/Spinner';
import { clearCampaignActivityData } from 'store/actions/customCampaignActivityAction';
import { Switch } from 'components/Form';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import ActivitiesList from '../ActivitiesList';
import './styles.scss';

const mapStateToProps = (state: any) => {
  return {
    session: state.session,
    locale: state.locale.locale,
    campaignActivity: state.customCampaignActivityReducer
  };
}

const mapDispatchToProps = (dispatch: any) => ({
  clearCampaignActivityData: () => dispatch(clearCampaignActivityData()),
});

interface CourseInterface {
  id: number;
  name: string;
  firstLanguage: string;
  nameTranslations: string;
  smartDephishEnabled: boolean;
  active: boolean;
  owner: 'CUSTOMER' | 'DISTRIBUTOR' | 'RESELLER';
  public: boolean;
}

const CourseEdit: React.FC = ({ match, session, campaignActivity, clearCampaignActivityData }: any) => {
    const [isActivitiesLoading, setIsActivitiesLoading] = useState(true);
    const [course, setCourse] = useState<CourseInterface | undefined>();
    const [isSwitchLoading, setIsSwitchLoading] = useState(false);
    const [selectLanguageList, setSelectLanguageList ] = useState({});
    const [customActivityCampaign, setCustomActivityCampaign] = useState({campaignId: null, type: null});

    const [form] = Form.useForm();
    const intl = useIntl();
    const courseId = match.params.courseId;

    useEffect(()=> {
      if (campaignActivity && campaignActivity.campaignId) {
        setCustomActivityCampaign(campaignActivity);
        clearCampaignActivityData();
      }
    },[campaignActivity])

    useEffect(() => {
        const loadLanguages = async () => {
          let languages = await apiClient.request(`/api/v1/language`, {}, 'GET');
          let languageListForSelect:any = {};
          let languageListNameForSelect:any = {};
          Object.values(languages.languages).map((value:any) => {
            if (value['publish']) {
              languageListForSelect[value['code']] = value['code']
              languageListNameForSelect[value['code']] = value['name']
            }
            return languageListNameForSelect;
          });
          setSelectLanguageList(languageListForSelect);
        };

        loadCourses();
        loadLanguages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [intl]);

    const loadCourses = async () => {
        try {
            setIsActivitiesLoading(true);
            let response = await coursesApiClient.request(`/api/v1/courses/courses/${courseId}`, {}, 'GET');
            const course = response.course;
            setCourse(course);

            form.setFieldsValue({
                courseName: course.nameTranslations ? course.nameTranslations[course.firstLanguage] : course.name ,
                courseLanguage: course.firstLanguage
            });

        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({id: "error.data_load"}));
        } finally {
            setIsActivitiesLoading(false);
        }
    };

    const submitCourseForm = async (courseName: string) => {
        if (!course) {
          return;
        }

        if (!courseName) {
          return;
        }

        let selectedLanguage = form.getFieldValue('courseLanguage');
        let translations: any = course.nameTranslations ? course.nameTranslations : {};

        if (translations && translations[selectedLanguage] === courseName) {
            return;
        }

        translations[selectedLanguage] = courseName;

        let parsedValues = {
            ...course,
            nameTranslations: translations
        };

        if (selectedLanguage === course.firstLanguage && course.name !== courseName) {
            parsedValues.name = courseName;
        }

        let response = await coursesApiClient.request(`/api/v1/courses/courses/${course.id}`, parsedValues, 'PUT');

        if (response.course === 'not_allowed_to_update') {
          return;
        } else {
          setCourse(response.course);
        }
    };

    const smartDePhish = async (smartDePhish: boolean) => {
        setIsSwitchLoading(true);
        try {
            const parsedValues = {
                ...course,
                smartDephishEnabled: smartDePhish
            };

            let response = await coursesApiClient.request(`/api/v1/courses/courses/${course?.id}`, parsedValues, 'PUT');

            if (response.course === 'not_allowed_to_update') {
              return;
            } else {
              message.success(intl.formatMessage({id:  smartDePhish ? 'phishing.smart_de_phish_activated' : 'phishing.smart_de_phish_deactivated'}));
              setCourse(response.course);
            }

        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({id: "error.server_error"}));
        } finally {
          setIsSwitchLoading(false);
        }
    };

    const onSwitchChangeOnline = async (status: boolean) => {
        setIsSwitchLoading(true);
        try {
            const parsedValues = {
                ...course,
                active: status
            };

            let response = await coursesApiClient.request(`/api/v1/courses/courses/${course?.id}`, parsedValues, 'PUT');

            if (response.course === 'not_allowed_to_update') {
              return;
            } else {
              message.success(intl.formatMessage({id: status ? 'courses.course_can_now_be_included' : 'courses.course_now_can_not_be_included'}), 7);
              setCourse(response.course);
            }


        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({id: 'error.server_error'}));
        } finally {
          setIsSwitchLoading(false);
        }
    };

    const setPublic = async (coursePrivacy: boolean) => {
      setIsSwitchLoading(true);
      try {
          const parsedValues = {
              ...course,
              public: coursePrivacy
          };

          let response = await coursesApiClient.request(`/api/v1/courses/courses/${course?.id}`, parsedValues, 'PUT');

          message.success(intl.formatMessage({id:  coursePrivacy ? 'courses.set_to_private' : 'courses.set_to_not_private'}));

          setCourse(response.course);
      } catch (err) {
          console.error(err);
          message.error(intl.formatMessage({id: "error.server_error"}));
      } finally{
        setIsSwitchLoading(false);
      }
  };


    return(
      <Spinner spinning={isActivitiesLoading}>
        <DefaultLayout.PageLayout>
          {course &&
            <DefaultLayout.PageHeader title={intl.formatMessage({id: 'courses.edit_course'}, {courseName: `${course?.name}`})} breadcrumb={[{name: intl.formatMessage({id: 'courses.manage_content'}), path: '/courses/manage-content'},]} />
          }
          <Form form={form}>
            <>
              {course ?
                <Form.Item name='courseName' label={intl.formatMessage({id: 'courses.course_title'})} >
                  <Input onBlur={(el:any) => {submitCourseForm(el.target.value)}}/>
                </Form.Item>
                : null
              }
            </>
            <Form.Item label=' ' className='noColon' name='courseLanguage'>
            {course && selectLanguageList ?
              <Radio.Group defaultValue={course.firstLanguage}>
                {
                  Object.entries(selectLanguageList).map((el:any) => {
                      return (
                        <>
                            <Radio.Button
                                value={el[1]}
                                onClick={() =>
                                    form.setFieldsValue({
                                        courseName:
                                            course.nameTranslations ? course.nameTranslations[el[1]]
                                            :
                                            el[1] === course.firstLanguage ? course.name : null
                                })}
                            >
                                {el[1]}
                            </Radio.Button>
                        </>
                      )
                  })
                }
              </Radio.Group>
              :null
            }
            </Form.Item>
            {session.active_user_type !== 'CUSTOMER_ADMIN' && course &&
              <Switch
                isFormItem
                label={intl.formatMessage({id: 'phishing.smart_dephish'})}
                onChange={(el: boolean) => smartDePhish(el)}
                checked={!!course.smartDephishEnabled}
                loading={isSwitchLoading}
              />
            }
            <Switch
              isFormItem
              label={
                  <>
                    {intl.formatMessage({id: 'general.online'})}
                    <Tooltip title={intl.formatMessage({id: 'courses.course_status_explanation'})}>
                      <i className='fal fa-question-circle header-item' />
                    </Tooltip>
                  </>
              }
              onChange={(status: boolean) => onSwitchChangeOnline(status)}
              checked={!!course?.active}
              loading={isSwitchLoading}
            />
            {course && course.owner !== 'DISTRIBUTOR' &&
              <Switch
              isFormItem
              label={
                <>
                    {intl.formatMessage({id: 'general.public'})}
                    <Tooltip title={intl.formatMessage({id: 'courses.public_hint'})}>
                        <i className='fal fa-question-circle header-item' />
                    </Tooltip>
                </>
              }
              onChange={(el: boolean) => setPublic(el)}
              checked={!!course.public}
              loading={isSwitchLoading}
              />
            }

          </Form>
          <ActivitiesList course={course} customActivityCampaign={customActivityCampaign} />
          {customActivityCampaign.campaignId &&
          <DefaultLayout.PageFooterWithRow
            right={
              <NavLink to={customActivityCampaign.type ? `/campaign/create/${customActivityCampaign.campaignId}/activities` : `/campaign/${customActivityCampaign.campaignId}/activities`}>
                <Button>
                  <FormattedMessage id='general.cancel' />
                </Button>
              </NavLink>
            }
          />
        }
        </DefaultLayout.PageLayout>
      </Spinner>
    )
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CourseEdit));
