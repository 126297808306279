import * as ActionTypes from 'store/actionTypes';

const defaultState = {
  environment: 'ADMINISTRATION'
}

const Environment = (state = defaultState, action: any) => {
  switch(action.type) {
    case ActionTypes.SWITCH_ACTIVE_ENVIRONMENT_TO_ADMINISTRATION:
      return {environment: 'ADMINISTRATION'};
    case ActionTypes.SWITCH_ACTIVE_ENVIRONMENT_TO_LEARNING:
      return {environment: 'LEARNING'};
    default:
      return state;
  }
}

export default Environment;
