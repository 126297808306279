import React, { useState} from 'react';
import { useIntl } from 'react-intl';
import { message, Modal, Form } from 'antd';
import apiClient from 'utils/apiClient';
import Spinner from 'components/Spinner';
import { Select } from 'components/Form';
import FileDownload from 'js-file-download';

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
};

const LanguageExportModal: React.FC<any> = ({ visible, onCancel, languageList }) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const intl = useIntl();
    const [importForm] = Form.useForm();

    const exportLanguage = async (values:any) => {
        const bodyFormData = new FormData();
        bodyFormData.append('language', "EN");
        bodyFormData.append('translationLanguage', values.exportLanguage);

        try {
            setIsDownloading(true);

            const response = await apiClient.request('/api/v1/language/export', bodyFormData, 'POST', true, true);

            if (response) {
                FileDownload(response, 'Translations.xlsx');
            } else {
                message.error(intl.formatMessage({ id: 'error.data_load' }));
            }
            onCancel();
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsDownloading(false);
        }
    }

    return (
        <Form form={importForm} onFinish={exportLanguage} {...formItemLayout}>
            <Modal
                className="import-modal"
                title={intl.formatMessage({ id: 'system.language_export_modal' })}
                visible={visible}
                onOk={() => { importForm.submit() }}
                onCancel={onCancel}
                okText={intl.formatMessage({ id: 'system.language.export' })}
                cancelText={intl.formatMessage({ id: 'general.cancel' })}
            >
                <Spinner spinning={isDownloading}>
                    <Select
                        name='exportLanguage'
                        label={intl.formatMessage({ id: "system.language.export_language" })}
                        customLayout={formItemLayout}
                        manualOptions={languageList}
                    />
                </Spinner>
            </Modal>
        </Form>
    )
}

export default LanguageExportModal;
