import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Table, Button, Input, Popover, message, Switch, Space } from 'antd';
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import moment from 'moment';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import ServicesFilterModal from './ServicesFilterModal';
import { connect } from 'react-redux';
import './styles.scss';

const mapStateToProps = (state: any) => {
  return {
    session: state.session
  }
}

const { Search } = Input;

const ServiceList: React.FC = ({ session }: any) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visibleTooltip, setVisibleTooltip] = useState<number|null>(null);
  const [showServiceFilterModal, setShowServiceFilterModal] = useState(false);
  
  const intl = useIntl();

  const loadData = async () => {
    try {
      setLoading(true);
      const response = await apiClient.request('/api/v1/services', {}, 'GET');
      setServices(response.services);
    } catch (error) {
      message.error(intl.formatMessage({ id: 'error.data_load' }));
      console.error(error);
    } finally {
      setLoading(false);
    }
  }
  
  useEffect(() => {
    if (session.active_user_type !== 'SUPER_ADMIN') {
      loadData();
    } else {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  const handleVisibleChange = (visible: boolean, recordId: number) => {
    setVisibleTooltip(visible ? recordId : null);
  }
  
  const handleSwitchChange = async (checked: boolean, id: number) => {
    try {
      await apiClient.request(`/api/v1/services/${id}/set-validity`, { active: checked }, 'PUT');
      loadData();
      message.success(intl.formatMessage({ id: 'services.service_successfully_updated' }))

    } catch (error) {
      message.error(intl.formatMessage({ id: 'error.data_load' }));
      console.error(error);
    }
  }
  
  const showTotal = (total: number, range: number[]) => `${range[0]}-${range[1]} of ${total}`;

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'licensing.service_category' }),
      dataIndex: 'type',
      render: (type: string) => intl.formatMessage({ id: `services.${type.toLowerCase()}` }),
    },
    {
      title: intl.formatMessage({ id: 'licensing.service_name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({id: 'licensing.validity'}),
      dataIndex: 'active',
      render: (active: boolean, record: any) => {
        if (active === null) {
          return '-';
        }

        return (
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            defaultChecked={active}
            onChange={(e) => handleSwitchChange(e, record.id)}
          />
        );
      },
    },
    {
      title: intl.formatMessage({id: 'licensing.valid_until'}),
      dataIndex: 'validTo',
      render: (validTo: string) => {
        return (
          <div className={moment(validTo) < moment() ? 'date-color' : ''}>
            {validTo ? moment(validTo).format('DD.MM.YYYY') : '-'}
          </div>
        );
      },
    },
    {
      dataIndex: 'id',
      key: 'actions',
      render: (serviceId: number) => {
        const content = (
          <NavLink to={`/licensing/manage-services/${serviceId}/edit`}>
            <div className="popover-item">
              <FormattedMessage id="licensing.edit_service"/>
            </div>
          </NavLink>
        );

        return (
          <Popover
            visible={serviceId === visibleTooltip} 
            content={content} 
            trigger="click" 
            placement="bottomRight" 
            arrowPointAtCenter
            onVisibleChange={(visible) => handleVisibleChange(visible, serviceId)}
          >
            <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
              <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} />
            </div>
          </Popover>
        );
      },
    },
  ];

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageHeader title={ intl.formatMessage({id: 'licensing.manage_services'}) }/>
      <DefaultLayout.PageContent>
        <Space>
        <NavLink to="/licensing/manage-services/add">
          <Button className="primary-button" type="primary">
            <PlusOutlined/> <FormattedMessage id="licensing.add_new_service" />
          </Button>
        </NavLink>
        <Button type="primary" icon={<i className="fal fa-filter"></i>} onClick={() => setShowServiceFilterModal(true)}>
          <FormattedMessage id="general.filter" />
        </Button>
        </Space>

        <Search
          className="table-search-field"
          placeholder={intl.formatMessage({ id: 'general.search' })}
          onSearch={value => console.log(value)}
        />
        <Table
          locale={{ emptyText: intl.formatMessage({ id: 'general.found_no_data' }) }}
          pagination={{
            showTotal: showTotal,
            pageSizeOptions: ['10', '20'],
            showSizeChanger: true,
            locale: { items_per_page: intl.formatMessage({ id: 'general.page' }), jump_to: intl.formatMessage({ id: 'general.go_to' }) },
            showQuickJumper: true,
          }}
          rowKey={(record) => record.id}
          style={{ margin: 0, marginTop: 15 }}
          columns={columns}
          dataSource={services}
          size="middle"
          loading={loading}
          scroll={{ x: 800 }}
        />
      </DefaultLayout.PageContent>

      <ServicesFilterModal 
        visible={showServiceFilterModal}
        onCancel={() => setShowServiceFilterModal(false)}
        setServices={setServices}
        setIsLoading={setLoading}
      
      />
    </DefaultLayout.PageLayout>
  );
}

export default connect(mapStateToProps)(ServiceList);
