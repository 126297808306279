import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { message, Table, Tag } from 'antd';
import { StudentActivityQuickViewInterface } from '../types';
import coursesApiClient from 'utils/coursesApiClient';
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    }
}

const StudentActivityQuickView: React.FC<StudentActivityQuickViewInterface> = ({
    record,
    locale
}) => {
    const [activities, setActivities] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const intl = useIntl();

    useEffect(() => {
        loadActivity();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl])

    const loadActivity = async () => {
        setIsLoading(true)
        try {
            const response = await coursesApiClient.request(`/api/v1/courses/campaign/${record.enrollmentId}/quick-view`, {}, 'GET');

            setActivities(response.activities)
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: "error.data_load" }));
        } finally {
            setIsLoading(false);
        }
    };

    const getTimeSpentContent = (time: number) => {
        if (!time) {
            return null;
        }

        const timeDate = new Date(time * 1000);

        const timeSpent = {
            hours: timeDate.getUTCHours(),
            minutes: timeDate.getUTCMinutes(),
            seconds: timeDate.getUTCSeconds(),
        };

        if (timeSpent.hours > 0 || timeSpent.minutes > 0) {
            if (timeSpent.hours > 0) {
                return intl.formatMessage({ id: 'student.time_spent_with_hours' }, { hours: timeSpent.hours, minutes: timeSpent.minutes });
            } else {
                return intl.formatMessage({ id: 'student.time_spent_without_hours' }, { minutes: timeSpent.minutes });
            }
        } else {
            return `${timeSpent.seconds}s`;
        }
    }

    const columns = [
        {
            title: intl.formatMessage({ id: 'general.activity' }),
            dataIndex: 'name',
            render: (_text: string, record: any) => {
                if (record.title) {
                    let language: any = locale
                    const title = JSON.parse(record.title);
                    return title[language] ? title[language] : title[Object.keys(title)[0]];
                }
            }
        },
        {
            title: intl.formatMessage({ id: 'student.time_spent' }),
            dataIndex: 'timeSpent',
            render: (_text: string, record: any) => {
                return getTimeSpentContent(record.timeSpent)
            },
        },
        {
            title: intl.formatMessage({ id: 'student.result' }),
            dataIndex: 'result',
        },
        {
            title: intl.formatMessage({ id: 'general.status' }),
            dataIndex: 'status',
            key: 'status',
            render: (_text: string, record: any) => {

                const getParams = (): any => {
                    switch (record.status) {
                        case ('NOT_STARTED'):
                            return { color: 'gold', message: 'student.not_started', className: 'student-campaign-tag-not-started' };
                        case ('IN_PROGRESS'):
                            return { color: 'blue', message: 'student.in_progress', className: 'student-campaign-tag-process' };
                        case ('COMPLETED'):
                            return { color: 'green', message: 'student.completed', className: 'student-campaign-tag-finished' };
                    }
                }

                const getMaterialParams = (): any => {
                    switch (record.status) {
                        case 'NOT_SUBMITTED':
                            return { color: 'gold', message: 'courses.topic_status.not_submitted', className: 'student-campaign-tag-default' };
                        case 'SUBMITTED':
                            return { color: 'blue', message: 'courses.topic_status.submitted', className: 'student-campaign-tag-default' };
                        case 'EVALUATION':
                            return { color: 'purple', message: 'courses.topic_status.evaluation', className: 'student-campaign-tag-default' };
                        case 'VALUED':
                            return { color: 'green', message: 'courses.topic_status.valued', className: 'student-campaign-tag-default' };
                        case 'MISSED_DEADLINE':
                            return { color: 'red', message: 'courses.topic_status.missed_deadline', className: 'student-campaign-tag-default' };
                    }
                }

                return (
                    <Tag color={record.type === 'ACTIVITY' ? getParams().color : getMaterialParams().color} key={record.id}
                        className={record.type === 'ACTIVITY' ? getParams().className : getMaterialParams().className}>
                        {intl.formatMessage({ id: record.type === 'ACTIVITY' ? getParams().message : getMaterialParams().message })}
                    </Tag>
                );
            },
        }
    ];

    return (
        <Table
            locale={{ emptyText: intl.formatMessage({ id: "general.found_no_data" }) }}
            columns={columns}
            loading={isLoading}
            dataSource={activities}
            size="small"
            pagination={false}
        />
    )
}

export default connect(mapStateToProps)(StudentActivityQuickView);
