import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {Table, Alert, Tooltip, message} from 'antd';
import { withRouter } from 'react-router-dom';
import Spinner from 'components/Spinner';
import PhishingCampaignResultModal from 'components/FormCampaign/PhishingCampaignResultModal';
import apiClient from "utils/apiClient";
import {connect} from "react-redux";
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    }
};

const CustomPhishingUsersResults = ({
  usersPhishingResults,
  isPhishTemplateLoading,
  tableSpinner,
  session
   }: any) => {
    const [showResultModal, setShowResultModal] = useState(false);
    const [enrollmentId, setEnrollmentId] = useState();

    const intl = useIntl();


    const checkIfUserAvailable = async (record: any) => {
        let parsedValues = {
            userUuid: record.userUuid,
            customerId: 2
        };

        try {

            let response = await apiClient.request('/api/v1/manager/user', parsedValues, 'POST');

            if (response.allow) {
                setShowResultModal(true);
                setEnrollmentId(record.enrollmentId);
            } else {
                message.error(intl.formatMessage({id: 'error.not_allowed'}));
            }
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({id: "error.data_load"}));
        }
    };

    const displayResultModal = (record: any) => {

        if (session.active_user_type === 'SUPERVISION') {
            checkIfUserAvailable(record);
        } else {
            setShowResultModal(true);
            setEnrollmentId(record.enrollmentId);
        }

    };

  const columnsResults = [
    {
      title: intl.formatMessage({id: 'phishing.name'}),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({id: 'phishing.last_name'}),
      dataIndex: 'lastName',
    },
    {
      title: intl.formatMessage({id: 'phishing.email'}),
      dataIndex: 'email',
    },
    {
      title: intl.formatMessage({id: 'phishing.organizational_unit'}),
      dataIndex: 'organizationalUnit',
    },
    {
      title:intl.formatMessage({id: 'phishing.phishes_received'}),
      dataIndex: 'phishes_received',
      key: 'phishes_received',
      render: (_text: string, record: any) => {
        return(
          <div className='phishes-recieved' onClick={() => {displayResultModal(record)}}>
            <span>{record.planedActivities}</span>
          </div>
        )
      }
    },
    {
      title:(
        <span>
          {intl.formatMessage({id: 'phishing.result'})}
          <Tooltip title={intl.formatMessage({id: 'phishing.results_hint'})}>
              <i className='fal fa-question-circle header-item' />
          </Tooltip>
        </span>),
      dataIndex: 'result',
      key: 'result',
      render: (_text: string, record: any) => {
        return(
          <div>
            { record.phishesReceived !== null ?
            <div>
              <span>{record.phishesFailed}/</span>
                <span>{record.phishesReceived} </span>
                { record.failRate !== null ?
                  <>
                    <span>( {record.failRate.toString()} %)</span>
                  </>
                : null
                }
            </div>
              : '-'
            }
          </div>
        )
      }
    }
  ];

  return (
  <>
    <Spinner spinning={tableSpinner}>
        {usersPhishingResults && usersPhishingResults.length > 0 ?
        <Table
            locale={{ emptyText: intl.formatMessage({id: "general.found_no_data"}) }}
            className="table-body"
            loading={isPhishTemplateLoading}
            style={{marginTop: 16}}
            columns={columnsResults}
            dataSource={usersPhishingResults}
            size="middle"
            scroll={{ x: 800 }}
        />
        :
        <div className='phishing-not-started-alert'>
            <Alert
            message={intl.formatMessage({id: "phishing.phishing_not_started"})}
            type="info"
            showIcon
            />
        </div>
        }
    </Spinner>
    <PhishingCampaignResultModal
        visible={showResultModal}
        onCancel={() => setShowResultModal(false)}
        enrollmentId={enrollmentId}
        setEnrollmentId={setEnrollmentId}
        campaignType={'CUSTOM'}
    />
  </>
  )
};
export default connect(mapStateToProps)(withRouter(CustomPhishingUsersResults));
