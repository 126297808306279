import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Checkbox, Form, message, Modal, Tooltip} from 'antd';
import {changeUserTableFilter} from "store/actions/saveUserTableFilter";
import {Select, CustomValuesSelect} from 'components/Form';
import {paginatorQuery} from "components/Table/methods";
import Spinner from "components/Spinner";
import apiClient from 'utils/apiClient';
import {connect} from 'react-redux';
import FilterBySelector from './FilterBySelector';
import UserFilterCheckBoxes from './UserFilterCheckBoxes';
import {OrganizationType, UsersFilterModalInterface} from '../types';
import './styles.scss';

const formLayout = {
    labelCol: {span: 6},
    wrapperCol: {span: 18},
};

const mapDispatchToProps = (dispatch: any) => ({
    changeUserTableFilter: (userTableFilter: any) => dispatch(changeUserTableFilter(userTableFilter)),
});


const mapStateToProps = (state: any) => {
    return {
        userTableFilter: state.userTableFilter,
        session: state.session
    }
};

const UsersFilterModal: React.FC<UsersFilterModalInterface> = ({
                                             showFilterModal,
                                             activeOrganizationId,
                                             activeOrganizationType,
                                             activeOrganizationUuid,
                                             userRole,
                                             setShowFilterModal,
                                             setActiveOrganizationId,
                                             setActiveOrganizationType,
                                             setActiveOrganizationUuid,
                                             setIsFilterLoading,
                                             setUsers,
                                             currentPageSize,
                                             setCurrentPage,
                                             setTotalRecords,
                                             usersFilter,
                                             setUsersFilter,
                                             changeUserTableFilter,
                                             userTableFilter,
                                             setRowSelection,
                                             setRowSelectedUsers,
                                             setSelectedRowsLength,
                                             setSelectAllUsers,
                                             searchText,
                                             setOrganizationResponse,
                                             organizationResponse,
                                             setCustomFilterResponse,
                                             setOrganizationalUnitResponse,
                                             updateFilterBar,
                                             setUpdateFilterBar,
                                             form,
                                             reloadUsers,
                                             setReloadUsers,
                                             setIsUsersLoading,
                                             customerId,
                                             filterNewUsers,
                                             setFilterNewUsers,
                                             session
                                         }) => {
    const [filterBy, setFilterBy] = useState<OrganizationType>('CUSTOMER');
    const [modalFormItemVisible, setModalFormItemVisible] = useState(false);
    const [selectedCampaignId, setSelectedCampaignId] = useState<number>(0);
    const [filterSetter, setFilterSetter] = useState(false);
    const [campaignUrl, setCampaignUrl] = useState('');
    const [isCustomFiltersLoading, setIsCustomFiltersLoading] = useState(false);
    const [organizationalUnitUrl, setOrganizationalUnitUrl] = useState('');

    const intl = useIntl();
    const isResellerAdmin = userRole === 'RESELLER_ADMIN';

    useEffect(() => {
        form.setFieldsValue({
            filterBy: 'CUSTOMER',
            accountStatus: ['ACTIVE'],
            active: [true, false],
            progress: ['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED'],
            userTypes: ['CUSTOMER_ADMIN', 'STUDENT']
        });

        if (activeOrganizationType === 'ORGANIZATION') {
            form.setFieldsValue({
            });
        } else {
            form.setFieldsValue({
            });
        }

        if (userTableFilter.filter ) {
            setActiveOrganizationId(userTableFilter.organizationId);
            setActiveOrganizationType(userTableFilter.organizationType);

            setFilterSetter(true);
        }

        if (userRole === 'CUSTOMER_ADMIN' || userRole === 'SUPERVISION') {
            setModalFormItemVisible(true);
        }

        if (customerId) {
            setModalFormItemVisible(true);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (activeOrganizationId !== 0) {
            let url: string = '';
            if (activeOrganizationId !== 0) {
                if (activeOrganizationType === 'CUSTOMER') {
                    url = '/api/v1/customer/organizational-unit-types';
                    if (userRole === 'SUPER_ADMIN' || userRole === 'RESELLER_ADMIN' || userRole === 'DISTRIBUTOR_ADMIN'  ) {
                        url = url + '?customerId=' + activeOrganizationId;
                    }

                    if (session.special_rights && session.special_rights.includes('can_manage_customers') && customerId) {
                            url = url + '?customerId=' + customerId;
                    }

                } else {
                    url = `/api/v1/organizations/${activeOrganizationId}/organizational-unit-types`;
                }
            }
            setOrganizationalUnitUrl(url);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeOrganizationId]);

    useEffect(() => {
        if ((userRole === 'SUPERVISION' || userRole === 'CUSTOMER_ADMIN') && activeOrganizationUuid) {
            setCampaignUrl(`/api/v1/courses/customer/${activeOrganizationUuid}/campaigns`);
            filterUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeOrganizationUuid]);

    useEffect(() => {
        if (activeOrganizationId) {
            filterUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    useEffect(() => {
        if (customerId && activeOrganizationId !== 0 && activeOrganizationType === 'CUSTOMER') {
            filterUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId, activeOrganizationId]);

    useEffect(() => {
        if (reloadUsers) {
            filterUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadUsers]);

    useEffect(() => {
        if (filterSetter) {
            filterUsers();
            setFilterSetter(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeOrganizationType, activeOrganizationId]);

    useEffect(() => {
        if (filterNewUsers) {
            setFilterNewUsers(false);
            filterUsers({ newUsers: true });
        }
    },[filterNewUsers])

    useEffect(() => {
        if (updateFilterBar === true) {
            filterUsers();
        } else if (updateFilterBar === 'clear') {
            setCurrentPage(1);
            setTotalRecords(0);
            setUsers([]);
            setRowSelectedUsers([]);

            setRowSelection({
                filter: {},
                selectedAllUsers: false,
                selectedUsers: [],
                deSelectedUsers: []
            });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateFilterBar]);

    const filterUsers = async (values: any = null) => {
        const existingFields = ['filterBy', 'customer', 'organization', 'accountStatus', 'active', 'userTypes',
            'campaignId', 'progress', 'organizationalUnitId', 'newUsers'];

        setIsFilterLoading(true);

        let parsedValues: any = {};

        if (!values && userTableFilter.filter && !updateFilterBar) {
            parsedValues.filter = userTableFilter.filter;
        } else if (updateFilterBar) {
            setUpdateFilterBar(false);
            parsedValues = usersFilter;
        } else if (values) {
            if (values.accountStatus) {
                let options: any = {};
                parsedValues = {
                    filter: {
                        gatewayOptions: {
                            active: values.active
                        },
                        options: {}
                    }
                };

                if (values.organizationalUnitId && values.organizationalUnitId.length) {
                    options.organizationalUnitId = 'in:' + values.organizationalUnitId.toString();
                }

                if (values.accountStatus) {
                    options.accountStatus = 'in:' + values.accountStatus.toString();
                }

                parsedValues.filter.options = options;
            }

            if (values.campaignId) {
                parsedValues.filter.campaign = {
                    campaignId: values.campaignId,
                    progress: values.progress
                }
            }

            let customFields: any = {};
            let customFiltersExist = false;

            Object.entries(values).map((el: any) => {
                if (!existingFields.includes(el[0])) {
                    let customFilterId = el[0].split('_');
                    customFields[customFilterId[customFilterId.length - 1]] = el[1];
                    customFiltersExist = true;
                }
                return customFields;
            });

            if (customFiltersExist) {
                parsedValues.filter.customFields = customFields;
            }
        }

        if (!parsedValues.length && !parsedValues.filter) {
            switch (activeOrganizationType) {
                case 'CUSTOMER':
                    parsedValues = {
                        filter: {
                            userTypes: ['STUDENT', 'CUSTOMER_ADMIN']
                        }
                    };
                    break;
                case 'ORGANIZATION':
                    parsedValues = {
                        filter: {
                            userTypes: ['ADMIN']
                        }
                    };
                    break;
            }
        } else if (values && values.userTypes) {
            parsedValues.filter.userTypes = values.userTypes
        }

        let requestUrl;

        if (userRole === 'CUSTOMER_ADMIN') {
            requestUrl = '/api/v1/auth/customer/users'
        } else {
            requestUrl = activeOrganizationType === 'CUSTOMER'
                ? '/api/v1/auth/customer/users'
                : `/api/v1/auth/organizations/${activeOrganizationId}/users`;
        }

        if (searchText) {
            parsedValues.q = searchText;
        } else if (searchText.length === 0 && parsedValues.q) {
            delete parsedValues.q
        }

        const query = paginatorQuery(1, currentPageSize);

        requestUrl = requestUrl + '?' + query;

        if (userRole === 'SUPER_ADMIN' || userRole === 'RESELLER_ADMIN' || userRole === 'DISTRIBUTOR_ADMIN'  ) {
            requestUrl = requestUrl + '&customerId=' + activeOrganizationId
        }

        if (session.special_rights && session.special_rights.includes('can_manage_customers') && customerId) {
                requestUrl = requestUrl + '&customerId=' + customerId;
        }

        if (values && values.newUsers !== undefined) {
            parsedValues.filter.newUsers = !!values.newUsers;
        } else {
            parsedValues.filter.newUsers = false;
        }

        try {
            setShowFilterModal(false);
            let usersResponse = await apiClient.request(requestUrl, parsedValues, 'POST');

            setUsers(usersResponse.users);

            if (usersResponse) {
                setUsersFilter(parsedValues);
            }

            if (!customerId) {
                changeUserTableFilter({
                    filter: parsedValues.filter,
                    organizationId: activeOrganizationId,
                    organizationType: activeOrganizationType
                });
            }
            setCurrentPage(1);

            setTotalRecords(usersResponse.pagination.total);

            setRowSelection({
                filter: parsedValues.filter,
                selectedAllUsers: false,
                selectedUsers: [],
                deSelectedUsers: []
            });
            setRowSelectedUsers([]);
            setSelectedRowsLength(0);
            setSelectAllUsers(false);

        } catch (e) {
            console.error(e);
            message.error(intl.formatMessage({id: "error.data_load"}));
        }
        setModalFormItemVisible(true);

        setIsFilterLoading(false);
        setIsUsersLoading(false);
        setReloadUsers(false);
    };

    return (
        <>
            <Modal
                className="user-list-modal"
                visible={showFilterModal}
                title={intl.formatMessage({id: 'users.user_filter'})}
                onOk={() => {form.submit();}}
                onCancel={() => {setShowFilterModal(false);}}
                okText={intl.formatMessage({id: 'general.filter'})}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
                forceRender={true}
                width={680}
            >
                <Form form={form} onFinish={filterUsers}>
                    {!!!customerId &&
                        <>
                            {userRole !== 'CUSTOMER_ADMIN' && userRole !== 'SUPERVISION' ?
                                <FilterBySelector
                                    setFilterBy={setFilterBy}
                                    setActiveOrganizationType={setActiveOrganizationType}
                                    setActiveOrganizationId={setActiveOrganizationId}
                                    setActiveOrganizationUuid={setActiveOrganizationUuid}
                                    form={form}
                                    filterBy={filterBy}
                                    setOrganizationResponse={setOrganizationResponse}
                                    isResellerAdmin={isResellerAdmin}
                                    activeOrganizationId={activeOrganizationId}
                                    setCampaignUrl={setCampaignUrl}
                                />
                                : null
                            }
                        </>
                    }
                    {modalFormItemVisible &&
                        <>
                            {!!!customerId &&
                                <UserFilterCheckBoxes modalFormItemVisible={modalFormItemVisible} filterBy={filterBy} />
                            }
                            {activeOrganizationType === 'CUSTOMER' && !!!customerId?
                                <Select
                                    name="campaignId"
                                    label={intl.formatMessage({id: 'campaign.email_campaign'})}
                                    url={campaignUrl ? campaignUrl : null}
                                    disabled={activeOrganizationId === 0}
                                    allowClear
                                    customLayout={formLayout}
                                    onChange={(el: number) => setSelectedCampaignId(el)}
                                    integerKey={true}
                                    isResponseArray={true}
                                    valueKey='value'
                                    labelKey='label'
                                    dataKey='campaigns'
                                    mapDataEntries={(el: any) => ({
                                        value: el.id,
                                        label: el.name,
                                    })}
                                />
                                : null
                            }
                            {activeOrganizationType !== 'ORGANIZATION' && selectedCampaignId ?
                                <Form.Item
                                    {...{
                                        labelCol: {span: 6},
                                        wrapperCol: {span: 18}
                                    }}
                                    name="progress"
                                    label={
                                        <span>
                                            {intl.formatMessage({id: 'general.progress'})}
                                            <Tooltip title={intl.formatMessage({id: 'general.progress.hint'})}>
                                                <i className='fal fa-question-circle header-item'/>
                                            </Tooltip>
                                        </span>
                                    }
                                >
                                    <Checkbox.Group>
                                        <Checkbox value="NOT_STARTED" style={{lineHeight: '32px'}}>
                                            <FormattedMessage id='general.not_started'/>
                                        </Checkbox>
                                        <Checkbox value="IN_PROGRESS" style={{lineHeight: '32px'}}>
                                            <FormattedMessage id='general.in_progress'/>
                                        </Checkbox>
                                        <Checkbox value="COMPLETED" style={{lineHeight: '32px'}}>
                                            <FormattedMessage id='general.completed'/>
                                        </Checkbox>
                                    </Checkbox.Group>
                                </Form.Item>
                                : null
                            }
                            <Select
                                mode="multiple"
                                allowClear
                                disabled={activeOrganizationId === 0}
                                showSearch
                                name='organizationalUnitId'
                                dataKey='organizationalUnits'
                                maxTagCount={1}
                                showArrow
                                label={intl.formatMessage({id: "general.unit"})}
                                saveLoadedData={true}
                                setLoadedData={setOrganizationalUnitResponse}
                                url={organizationalUnitUrl}
                                sortOptions={function(a: any, b: any) {
                                    let textA = a[1].toUpperCase();
                                    let textB = b[1].toUpperCase();
                                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                }}
                                classifierIsObject={true}
                                integerKey={true}
                                customLayout={formLayout}
                                filterOption={(input: string, option: any) => {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }}
                            />
                            {customerId &&
                                <UserFilterCheckBoxes modalFormItemVisible={modalFormItemVisible} filterBy={filterBy} />
                            }
                            {!!!customerId &&
                                <>
                                    {activeOrganizationType !== 'ORGANIZATION' && activeOrganizationId !== 0 ?
                                        <Spinner spinning={isCustomFiltersLoading}>
                                          <CustomValuesSelect
                                              customerId={activeOrganizationId}
                                              customLayout={formLayout}
                                              allowClear
                                              setLoading={setIsCustomFiltersLoading}
                                              saveLoadedData={true}
                                              setLoadedData={setCustomFilterResponse}
                                              role={userRole}
                                              filterOption={(input: string, option: any) => {
                                                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                              }}
                                          />
                                        </Spinner>
                                        : null
                                    }
                                </>
                            }
                        </>
                    }
                </Form>
            </Modal>
        </>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersFilterModal);