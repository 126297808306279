import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, InputNumber, Input, Select, Tooltip, Space } from 'antd';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import FormattedMessage from 'react-intl/lib/components/message';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import './styles.scss';

interface ActivityRatingInterface {
    activityFormData: any;
    formDate: Function;
    session?: any;
    getAssignmentDeadline: Function;
    form: any;
    getStatusTag: Function;
}
const mapStateToProps = (state: any) => ({
    session: state.session,
});

const ActivityRating: React.FC<ActivityRatingInterface & RouteComponentProps> = ({
    activityFormData,
    formDate,
    session, getAssignmentDeadline, form, getStatusTag }) => {
    const adminRoles = ['RESELLER_ADMIN', 'SUPER_ADMIN', 'DISTRIBUTOR_ADMIN', 'CUSTOMER_ADMIN', 'SUPERVISION'];

    const assignment = activityFormData.materials.assignments;
    const assignmentSettings = JSON.parse(activityFormData.materials.assignments.settings)
    const attempt = activityFormData.activityAttempt;



    const { TextArea } = Input;
    const { Option } = Select;
    const intl = useIntl();

    useEffect(() => {
        if (activityFormData && attempt.assignment_properties) {
            let assignmentEvaluation = JSON.parse(attempt.assignment_properties);


            if (assignmentEvaluation[assignment.id]) {
                assignmentEvaluation = assignmentEvaluation[assignment.id].evaluation;

                form.setFieldsValue({
                    rating: assignmentEvaluation?.rating,
                    examination: assignmentEvaluation?.examination,
                    comment: assignmentEvaluation?.comment,
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attempt]);

    const getRatingDate = () => {
        if (attempt.assignment_properties) {
            const evaluationData = JSON.parse(attempt.assignment_properties)

            if (evaluationData[assignment.id] && evaluationData.evaluation) {
                return evaluationData[assignment.id].evaluation.date;
            }

            return '';
        }
    }

    const getComment = () => {
        if (attempt.assignment_properties) {
            const attemptProperties = JSON.parse(attempt.assignment_properties);

            if (attemptProperties[assignment.id]) {
                return attemptProperties[assignment.id].evaluation?.comment;
            }
        }

        return '';
    }

    /**
     * 
     * @param value 
     * @returns 
     */
    const getExamination = (value: 'value' | 'message' = 'message') => {
        if (attempt.assignment_properties) {
            const attemptEvaluation = JSON.parse(attempt.assignment_properties);
            if (value === 'value') {
                return attemptEvaluation[assignment.id].evaluation?.examination;
            } else if (value === 'message') {
                return <FormattedMessage id={'courses.topic_' + attemptEvaluation[assignment.id].evaluation?.examination} />
            }
        }

        return '';
    }

    /**
     * 
     * @param value 
     * @returns 
     */
    const getRange = (value: 'value' | 'message' = 'message') => {
        if (attempt.assignment_properties) {
            const attemptEvaluation = JSON.parse(attempt.assignment_properties);
            if (attemptEvaluation[assignment.id]) {
                if (value === 'value') {
                    return attemptEvaluation[assignment.id].evaluation?.status;
                } else if (value === 'message') {
                    return <div>{attemptEvaluation[assignment.id].evaluation?.rating}%</div>
                }
            }
        }

        return '';
    }

    const disableRatingFields = () => {
        if (getStatusTag('VALUE') === 'VALUED' || getStatusTag('VALUE') === 'MISSED_DEADLINE') {
            return true;
        }

        return false;
    }

    const getSubmitDate = () => {
        const attemptSubmittedData = JSON.parse(attempt.assignment_properties);

        if (attemptSubmittedData[assignment.id] && attemptSubmittedData[assignment.id]?.submission?.date) {
            return formDate(attemptSubmittedData[assignment.id].submission.date);
        }

        return false;
    }

    return (
        <div className='rating-form'>
            <div className='evaluation-settings'>
                <FormattedMessage id='courses.topic_submit_deadline' />
                <div className='rating-settings'>
                    {getAssignmentDeadline()}

                </div>
            </div>
            {attempt && attempt.assignment_properties && attempt.assignment_properties[assignment.id] && getSubmitDate() &&
                <div className='evaluation-settings'>
                    <FormattedMessage id='courses.topic_submit_date' />
                    <div className='rating-settings'>
                        {getSubmitDate()}
                    </div>
                </div>
            }
            {getRatingDate() && assignmentSettings && assignmentSettings.evaluation_type !== 'NO_EVALUATION' &&
                <div className='evaluation-settings'>
                    <FormattedMessage id='courses.topic_evaluation_date' />
                    <div className='rating-settings'>
                        {attempt && attempt.assignment_properties && formDate(getRatingDate())}
                    </div>
                </div>
            }
            {!(getStatusTag('VALUE') === 'NOT_SUBMITTED' || getStatusTag('VALUE') === 'MISSED_DEADLINE') &&
                <>
                    {adminRoles.includes(session.active_user_type) && assignmentSettings && assignmentSettings.evaluation_type === 'RANGE' &&
                        <div className={'evaluation-settings'}>
                            <Space>
                                <FormattedMessage id='courses.topic_evaluation' />
                                <Tooltip title={intl.formatMessage({ id: 'courses.topic_evaluation_hint' })}>
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </Space>
                            <Form.Item name='rating'>
                                <InputNumber disabled={disableRatingFields()} min={1} max={100} />
                            </Form.Item>
                        </div>
                    }
                    {!adminRoles.includes(session.active_user_type) && assignmentSettings && assignmentSettings.evaluation_type === 'RANGE' && getStatusTag('VALUE') === 'VALUED' &&
                        <div className={(getRange('value') === 'COMPLETED' ? 'passed-color ' : 'failed-color ') + ' evaluation-settings'}>
                            <Space>
                                <FormattedMessage id='courses.topic_evaluation' />
                                <Tooltip title={intl.formatMessage({ id: 'courses.topic_evaluation_hint' })}>
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </Space>
                            <div>
                                {getRange()}
                            </div>
                        </div>
                    }
                    {adminRoles.includes(session.active_user_type) && assignmentSettings && assignmentSettings.evaluation_type === 'EXAMINATION' &&
                        <div className='evaluation-settings'>
                            <Space>
                                <FormattedMessage id='courses.topic_evaluation' />
                                <Tooltip title={intl.formatMessage({ id: 'courses.topic_evaluation_hint' })}>
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </Space>
                            <Form.Item name='examination'>
                                <Select disabled={disableRatingFields()}>
                                    <Option value="passed"><FormattedMessage id='courses.topic_passed' /></Option>
                                    <Option value="failed"><FormattedMessage id='courses.topic_failed' /></Option>
                                </Select>
                            </Form.Item>
                        </div>
                    }
                    {!adminRoles.includes(session.active_user_type) && assignmentSettings && assignmentSettings.evaluation_type === 'EXAMINATION' && getStatusTag('VALUE') === 'VALUED' &&
                        <div className={(getExamination('value') === 'passed' ? 'passed-color ' : 'failed-color ') + 'evaluation-settings'}>
                            <Space>
                                <FormattedMessage id='courses.topic_evaluation' />
                                <Tooltip title={intl.formatMessage({ id: 'courses.topic_evaluation_hint' })}>
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </Space>
                            <div>
                                {getExamination()}
                            </div>
                        </div>
                    }
                </>
            }
            {!adminRoles.includes(session.active_user_type) && getComment() &&
                <div className='comment-height evaluation-settings'>
                    <FormattedMessage id='courses.topic_comment' />
                    <div>
                        {getComment()}
                    </div>
                </div>
            }
            {adminRoles.includes(session.active_user_type) && assignmentSettings &&
                <div className='comment-height-admin evaluation-settings'>
                    <FormattedMessage id='courses.topic_comment' />
                    <Form.Item name='comment' >
                        <TextArea maxLength={1800} />
                    </Form.Item>
                </div>
            }
        </div >
    )
}
export default connect(mapStateToProps)(withRouter(ActivityRating));