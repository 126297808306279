import React, {useState} from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { match, NavLink, useRouteMatch  } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';
import { Form, Button, Layout, Card, message, Alert, Spin, Checkbox } from 'antd';
import { changeLocale } from 'store/actions/locale';
import { switchToAdministrationEnvironment } from 'store/actions/environment';
import { Input } from 'components/Form';
import { changeIsEmailSent } from 'store/actions/resetPassword';
import { setSession } from 'store/actions/session';
import apiClient from 'utils/apiClient';
import jwt from "utils/jwt";
import config from 'config/config';
import LoginInstructionButton from "components/VideoInstructions/LoginInstructionButton";
import FlexRow from "components/FlexRow";
import AppearanceStyler from "components/NoAuthHelper/AppearanceStyler";
import { PhoneNumberSelect } from 'components/GlobalComponents';
import '../styles.scss';

const { Header, Footer, Content } = Layout;

const mapStateToProps = (state: any) => {
  return {
    locale: state.locale.locale,
    resetPasword: state.resetPasword.isEmailSent,
    languageDictionary : state.languageDictionary,
    title: state.appearanceSettings.title,
    logoPath: state.appearanceSettings.logoPath,
    appearanceSettingsLoading: state.appearanceSettings.loading,
    globalTheme: state.publicAppearanceReducer
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  changeLocale: (locale: string) => dispatch(changeLocale(locale)),
  changeIsEmailSent: (isEmailSent: boolean) => dispatch(changeIsEmailSent(isEmailSent)),
  setSession: (session: any) => dispatch(setSession(session)),
  switchToAdministrationEnvironment: () => dispatch(switchToAdministrationEnvironment())
})

const LoginForm: React.FC = ({ locale, changeLocale, resetPasword, setSession, switchToAdministrationEnvironment, languageDictionary, globalTheme, changeIsEmailSent}: any) => {
  const [inProgress, setInProgress] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [inputTextLength, setInputTextLength] = useState(0);
  const [appearanceIsLoading, setAppearanceIsLoading] = useState(!!!globalTheme);
  const [phoneLogin, setPhoneLogin] = useState(false);
  const intl = useIntl();
  const [form] = Form.useForm();

  const uuid:match<{uuid:string}> | null = useRouteMatch("/login/:uuid?");
  
  const submitForm = async (values: any) => {
    try {
      setInProgress(true);

      let login = values.email;

      if (values.phoneNumber) {
        login = values.phoneNumber.replace(/\s/g, '')
      }

      const countryCode = values.code;

      await jwt.login(login, values.password, uuid, countryCode);

      let authMeResponse = await apiClient.request(config.api.routes.auth.me, {}, 'GET'); // TODO: Izvērtēt, ko ar šo darīt (Vajag REDUX saglabāt)

      if (authMeResponse.data.active_user_type !== 'STUDENT') {
        switchToAdministrationEnvironment();
      }

      if (languageDictionary[authMeResponse.data.language]) {
        changeLocale(authMeResponse.data.language)
      }

      await setSession(authMeResponse.data);
      window.location.assign('/');
    } catch (e) {
      let errorMessage = "unsuccessful_log_in_message";
      let error: any = e;
      
      console.error(error);

      form.setFieldsValue({password: ''});

      if (error.message === 'not_found_active_user_account') {
        errorMessage = 'login.' + error.message
      }
      if (error.message === 'customer_has_no_platform_access') {
        errorMessage = 'users.' + error.message
      }
      if (error.message === 'exceeded_max_allowed_students_count') {
        errorMessage = 'error.' + error.message
      }
      if (error.message === 'user_is_not_activated') {
        errorMessage = 'error.' + error.message
      }

      message.error(intl.formatMessage({id: errorMessage}));

      setInProgress(false);
    }
  };

  const suffix = (
      showPassword ? <i style={{width:20}} onClick={() => setShowPassword(false)} className="far fa-eye-slash"></i> : <i style={{width:20}} onClick={() => setShowPassword(true)} className = "far fa-eye" > </i>
  );

  return (
    <Spinner spinning={appearanceIsLoading} opaque>
      <Layout style={{ minHeight: '100vh' }}>
        <AppearanceStyler setAppearanceIsLoading={setAppearanceIsLoading}/>
        <Header style={{ backgroundColor: globalTheme && globalTheme.theme.themeBackground ? globalTheme.theme.themeBackground : '#463368' }} />
        <Content className="login-wrapper" style={{ backgroundColor: globalTheme && globalTheme.theme.themeBackground ? globalTheme.theme.themeBackground : '#463368'}}>
              <Card className="login-card">
              <Spin spinning={appearanceIsLoading} size="small">
                {globalTheme && globalTheme.logo 
                  ? <img className="login-card-logo" src={globalTheme.logo ? URL.createObjectURL(globalTheme.logo) : undefined} alt={globalTheme.title} />
                  : <img className="login-card-logo" src="/cs-logo.svg" alt={'CloudStudy'} />
                }
              </Spin>
                {!resetPasword ?
                <>
                  <p className="please-login-text">
                    <FormattedMessage id="login.please_log_in_to_continue" />
                  </p>
                  <Form className="login-form" onFinish={submitForm} form={form}>
                    {phoneLogin ?

                    <PhoneNumberSelect defaultCode={config.api.defaultCode} form={form} wrapperClassName='login-form-phone-input' name='phoneNumber' placeholder={intl.formatMessage({id: "users.form.phone_number"})} prefix={<i className="fa-solid fa-mobile-screen" style={{ color: 'rgba(0,0,0,.25)' }} />} />
                    :
                    <Input
                      name="email"
                      autocomplete="on"
                      customRules={[{ required: true, message: intl.formatMessage({id: "login.email.validation"}) }]}
                      prefix={<i className=" fal fa-user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder={intl.formatMessage({id: "login.email"})}
                      customLayout={true}
                    />
                    }
                    <Input
                      style={{height: 50}}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={()=> setInputTextLength(form.getFieldValue("password").length)}
                      customRules={[{ required: true, message: intl.formatMessage({id: "login.password.validation"}) }]}
                      prefix={<i className=" fal fa-lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder={intl.formatMessage({id: "login.password"})}
                      customLayout={true}
                      suffix={inputTextLength > 0 && suffix}
                    />
                    <div className="login-options-wrapper">
                      <div>
                        {!uuid?.params.uuid &&
                          <Checkbox onChange={(el: any) => setPhoneLogin(el.target.checked)} >
                            <FormattedMessage id='login.use_phone_to_login' />
                          </Checkbox>
                        }
                      </div>
                      <NavLink to="/forgot-password" className="forgot-password-text">
                          <FormattedMessage id="login.forgot_password" />
                      </NavLink>
                    </div>
                    <Form.Item>
                      <Button type="primary" htmlType="submit" className="login-form-button" loading={inProgress}>
                        <FormattedMessage id="login.log_in" />
                      </Button>
                    </Form.Item>
                  </Form>
                  <FlexRow
                    right={
                      <LoginInstructionButton position='LOGIN' visible={true} />
                    }
                  />
                </>
                :
                <>
                <Alert style={{marginBottom: 10, marginTop: 10}} message={intl.formatMessage({ id: "login.if_you_have_an_account" })} type="info" showIcon />
                <Button type="primary" className="login-form-button" onClick={()=> changeIsEmailSent(false)}>
                  <FormattedMessage id="general.back" />
                </Button>
              </>
                }
              </Card>
              <div>
                {
                  Object.entries(languageDictionary).map((entries:any) => {
                    if (entries[0] !== 'loading' && entries[0] !== 'failed') {
                      return (
                        <Button className={"locale-switch" + (locale === entries[0] ? " active" : "")} onClick={() => changeLocale(entries[0])}>{entries[0]}</Button>
                        )
                      }
                      return null;
                    })
                }
              </div>
        </Content>
        <Footer style={{ backgroundColor: globalTheme && globalTheme.theme.themeBackground ? globalTheme.theme.themeBackground : '#463368', textAlign: 'center' }}>
          <ul className="footer-list">
            <li>© CloudStudy Ltd</li>
            <li >
              <NavLink to='terms-and-conditions' style={{color:'#FFFFFF'}}>
                <FormattedMessage id="login.terms_and_conditions" />
              </NavLink>
            </li>
            <li><FormattedMessage id="login.cookies" /></li>
          </ul>
        </Footer>
      </Layout>
     </Spinner>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
