import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button, Alert, Table, Tooltip, Popover } from 'antd';
import { Switch } from 'components/Form';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import FlexRow from 'components/FlexRow';
import { SortableContainer as SortContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { CampaignActivitiesInterface } from '../types';
import Spinner from 'components/Spinner';
import { withRouter, RouteComponentProps } from 'react-router';
import CampaignActivitiesModal from './CampaignActivitiesModal';
import CustomActivityModal from './CustomActivityModal';
import helper from 'utils/generalHelpers';
import { changeCampaignActivityAction } from 'store/actions/customCampaignActivityAction';
import { connect } from 'react-redux';

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    changeCampaignActivityAction: (data: any) => dispatch(changeCampaignActivityAction(data)),
});

const CampaignActivities: React.FC<CampaignActivitiesInterface & RouteComponentProps> = ({
    campaignId,
    selectedLanguage,
    owner,
    loadLanguages,
    setPhishingCampaign,
    courses,
    setCourses,
    next,
    previous,
    validation,
    newCampaignStatus,
    launchButton,
    currentStep,
    sendRequest,
    launching,
    submitTab,
    status,
    campaignType,
    campaign,
    history,
    changeCampaignActivityAction,
    session

}) => {
    const [forceSpecificLearningSequence, setForceSpecificLearningSequence] = useState(false);
    const [campaignActivities, setCampaignActivities] = useState<any>([]);
    const [minutes, setMinutes] = useState(0);
    const [loading, setIsLoading] = useState(false);
    const [showResourceModal, setShowResourceModal] = useState(false);
    const [createCustomActivityModal, setCreateCustomActivityModal] = useState(false)
    const [customCourse, setCustomCourse] = useState<any>([]);
    const [loadCourses, setLoadCourses] = useState(false);
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);

    const intl = useIntl();

    useEffect(() => {
        if (launching && currentStep === 1) {
            sendRequest(submitForm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [launching]);

    useEffect(() => {
        if (submitTab === 'activities') {
            submitForm();

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitTab]);

    useEffect(() => {
        if (campaignActivities && campaignActivities.length > 0) {
            validation(true);
        } else if (campaignActivities && campaignActivities.length === 0) {
            validation(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignActivities]);

    const onSortEnd = (oldIndex: any) => {

        if (oldIndex.oldIndex !== oldIndex.newIndex) {
            const newData = arrayMove([].concat(campaignActivities), oldIndex.oldIndex, oldIndex.newIndex).filter(el => !!el);
            let i = 0;
            let updatedLearningActivitiesList: any = [];
            Object.entries(newData).map((value: any) => {
                value[1]['index'] = i;
                updatedLearningActivitiesList[i] = value[1];
                i = i + 1
                return updatedLearningActivitiesList;
            });
            setCampaignActivities(updatedLearningActivitiesList)
        }
    };

    const SortableItem = SortableElement((props: any) => <tr {...props} />);
    const SortableContainer = SortContainer((props: any) => <tbody {...props} />);
    const DragHandle = SortableHandle(() => (
        <i className='fal fa-arrows header-item' style={{ cursor: 'pointer' }} />
    ));

    const DragableBodyRow = (...restProps: any) => {
        return (
            <SortableItem index={restProps[0]['data-row-key']} {...restProps[0]} />
        )
    }

    const getIcon = (activity: any) => {
        const material = helper.pluck(activity.learning_materials, 'type');

        var unique = material.filter(helper.onlyUnique);

        switch (unique[0]) {
            case 'SCORM_LEARNING':
            case 'SCORM_TEST':
                return [<i className="fa-solid fa-book" />, 'courses.interactive_study'];
            case 'VIDEO_MATERIAL':
                return [<i className="fa-solid fa-book" />, 'courses.material_type_video_material'];
            case 'PDF_MATERIAL':
                return [<i className="fa-solid fa-book" />, 'courses.material_type_pdf_material'];
            case 'ASSIGNMENT':
                return [<i className="fa-solid fa-file-signature" />, 'courses.individual_work'];
            case 'FILE':
                return [<i className="fa-solid fa-file-lines" />, 'courses.material'];
            default:
                return [<i className="fa-solid fa-square-exclamation" />, 'general.error']
        }
    }


    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            show: forceSpecificLearningSequence,
            render: (_text: string, record: any) => record.index + 1
        },
        {
            title: '',
            dataIndex: 'icon',
            key: 'icon',
            render: (_text: string, record: any) => {
                const icon = getIcon(record);

                return (
                    <Tooltip title={intl.formatMessage({ id: `${icon[1]}` })}>
                        <span className='activity-icon' >{icon[0]}</span>
                    </Tooltip>
                )
            }
        },
        {
            title: intl.formatMessage({ id: 'general.activity' }),
            dataIndex: 'name',
            key: 'name',
            render: (_text: string, record: any) => {
                if (record.title) {
                    const title = JSON.parse(record.title);
                    return title[selectedLanguage] ? title[selectedLanguage] : title[Object.keys(title)[0]];
                }
            }
        },
        {
            title: intl.formatMessage({ id: 'courses.course_title' }),
            dataIndex: 'course_name',
            key: 'course_name',
            render: (_text: string, record: any) => {
                if (record.courseNameTranslations) {
                    return record.courseNameTranslations[selectedLanguage] ? record.courseNameTranslations[selectedLanguage] : record.courseName;
                } else if (record.course_name_translations) {
                    return record.course_name_translations[selectedLanguage] ? record.course_name_translations[selectedLanguage] : record.course_name;
                } else return record.courseName ? record.courseName : record.course_name
            }
        },
        {
            title: intl.formatMessage({ id: 'campaign.available_languages' }),
            dataIndex: 'availableLanguages',
            key: 'availableLanguages',
            render: (_text: string, record: any) => {
                if (!record.available_languages) {
                    return (null);
                }
                return (
                    Object.entries(record.available_languages).map((value: any) => {
                        return (
                            <div className="language-link">
                                <p>{value[1]}</p>
                            </div>
                        )
                    })
                )
            }
        },
        {
            title: intl.formatMessage({ id: 'general.minutes' }),
            dataIndex: 'minutes',
            key: 'minutes',
            render: (_text: string, record: any) => record.minutes
        },
        {
            title: intl.formatMessage({ id: 'general.mandatory' }),
            dataIndex: 'activities ',
            key: 'activities ',
            render: (_text: string, record: any) => {
                if (record.type !== 'FILE') {
                    return (<Switch disabled={status !== 'DRAFT'} onChange={(e: boolean) => record.mandatory = e} defaultChecked={record.mandatory} />)
                } else return '-/-'
            }
        },
        {
            title: intl.formatMessage({ id: 'campaign.force_pre_testing' }),
            dataIndex: 'status',
            key: 'status',
            render: (_text: string, record: any) => {
                if (record.hasTest || record.has_test) {
                    return (<Switch disabled={status !== 'DRAFT'} onChange={(e: boolean) => record.forcePretesting = e} defaultChecked={record.forcePretesting} />)
                } else return '-/-'
            }
        },
        {
            title: intl.formatMessage({ id: 'general.pass_rate' }),
            dataIndex: 'passRate',
            key: 'passRate',
            render: (_text: string, record: any) => {
                if (record.has_test) {
                    return (
                        record.materialPassRate ? record.materialPassRate + '%' : record.material_pass_rate + '%'
                    )
                } else return '-/-';
            }
        },
        {
            key: 'actions',
            render: (_text: string, record: any, index: number) => {
                const customCoursesIds = helper.pluck(customCourse, 'id');

                const handleCustomActivityEdit = () => {
                    changeCampaignActivityAction({ campaignId: campaignId, type: newCampaignStatus });
                    history.push(`/courses/${record.course_id}/material/${record.id}`);
                }

                const content = (
                    <>
                        <div onClick={() => handleCustomActivityEdit()} className="popover-item">
                            <FormattedMessage id="general.edit" />
                        </div>
                    </>
                );

                return (
                    <Popover
                        visible={index === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onVisibleChange={(visible) => handleVisibleChange(visible, index)}
                    >
                        {customCoursesIds.includes(record.course_id) &&
                            <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
                                <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} />
                            </div>
                        }
                    </Popover>
                )
            },
        },
        {
            title: '',
            dataIndex: '',
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
    ];

    const handleVisibleChange = (visible: boolean, rowIndex: number) => {
        if (visible) {
            setVisibleTooltip(rowIndex);
        } else {
            setVisibleTooltip(null);
        }
    };

    const DraggableContainer = (props: any) => {
        return (
            <SortableContainer
                useDragHandle
                helperClass="row-dragging"
                onSortEnd={onSortEnd}
                {...props}
            />
        )
    };

    const submitForm = async (direction: string = '') => {
        if (status !== 'DRAFT') {
            return;
        }
        setIsLoading(true);
        let includedCourses: number[] = [];
        let activities: any = {};

        Object.values(campaignActivities).map((activity: any) => {
            includedCourses.push(activity.courseId ? activity.courseId : activity.course_id);
            activities[activity.id] = {
                mandatory: activity.mandatory,
                force_pretesting: activity.forcePretesting,
                custom_sequence_priority: forceSpecificLearningSequence ? activity.index : null
            }
            return activities;
        });

        const parsedValues = {
            activities,
            courses: includedCourses,
            forceSequence: forceSpecificLearningSequence
        };

        setCourses(includedCourses);
        if (activities !== {} && includedCourses.length > 0) {
            validation(true);
        }

        await apiClient.request(`/api/v1/courses/campaigns/${campaignId}/update-activities`, parsedValues, 'POST');

        if (direction === 'next') {
            next(campaignId);
        } else if (direction === 'previous') {
            previous();
        } else if (direction === 'save') {
            history.push('/courses/manage-campaigns')
        }

        setIsLoading(false);
    };

    const handleCustomCourseModal = () => {
        changeCampaignActivityAction({ campaignId: campaignId, type: newCampaignStatus });

        if (customCourse.length) {
            history.push(`/courses/${customCourse[0].id}/edit`);
        } else {
            setCreateCustomActivityModal(true)
        }
    }

    return (
        <>
            <Spinner spinning={loading}>
                <Form>
                    <div className='campaign-header-text'>
                        <h1 className="text-20" style={{ marginBottom: 24, fontSize: 20 }} >{intl.formatMessage({ id: 'campaign.learning_activities_info' })}</h1>
                        <h1 className="text-20" style={{ margin: 0 }} >{intl.formatMessage({ id: 'campaign.number_of_activiteis' }, { activites: campaignActivities.length })} </h1>
                        <h1 className="text-20" style={{ marginBottom: 16, marginTop: 5 }} >{intl.formatMessage({ id: 'campaign.total_minutes' }, { minutes: minutes })}</h1>
                    </div>
                    {status === 'DRAFT' &&
                        <Alert closable message={intl.formatMessage({ id: "campaign.you_will_be_able_to_add_remove_activities" })} type="info" showIcon style={{ marginBottom: 24 }} />
                    }
                    {status === 'DRAFT' &&
                        <FlexRow
                            left={
                                <>
                                    <Button loading={loadCourses} type="primary" onClick={() => setShowResourceModal(true)} disabled={false} style={{ float: 'left' }}>
                                        <i className='fal fa-plus header-item' style={{ marginRight: 4, fontSize: 16, float: 'left' }} />
                                        <FormattedMessage id="campaign.add_activity_from_catalog" />
                                    </Button>
                                    {session.permissions.includes('create_courses') &&
                                        <Button type="primary" style={{ marginLeft: 8, float: 'left' }} onClick={() => handleCustomCourseModal()} disabled={false}>
                                            <i className='fal fa-plus header-item' style={{ marginRight: 4, fontSize: 16, float: 'left' }} />
                                            <FormattedMessage id="courses.new_activity" />
                                        </Button>
                                    }
                                </>
                            }
                            right={
                                <>
                                    <label className="force-sequence-label">{intl.formatMessage({ id: "campaign.force_specific_learning_sequence" })}</label>
                                    <Switch
                                        name="forceSequence"
                                        onChange={(event: boolean) => setForceSpecificLearningSequence(event)}
                                    />
                                </>
                            }
                        />
                    }
                    <Table
                        locale={{ emptyText: intl.formatMessage({ id: "general.found_no_data" }) }}
                        style={{ marginTop: 16, clear: 'both', marginBottom: 20 }}
                        columns={
                            forceSpecificLearningSequence
                                ? columns
                                : columns.filter(ea => ea.dataIndex !== "index" && ea.dataIndex !== "")
                        }
                        dataSource={campaignActivities}
                        size="middle"
                        scroll={{ x: 800 }}
                        rowKey="index"
                        components={{
                            body: {
                                wrapper: DraggableContainer,
                                row: DragableBodyRow,
                            },
                        }}
                    />
                </Form>
            </Spinner>
            <DefaultLayout.PageFooterWithRow
                left={
                    <>
                        {newCampaignStatus &&
                            <>
                                <Button loading={loading} onClick={() => submitForm('previous')}>
                                    <FormattedMessage id="campaign.previous" />
                                </Button>
                                <Button loading={loading} onClick={() => { submitForm('next'); }}>
                                    <FormattedMessage id='general.next' />
                                </Button>
                            </>
                        }
                    </>
                }
                right={
                    <>
                        {!newCampaignStatus &&
                            <Button onClick={() => { history.push('/courses/manage-campaigns'); }}>
                                <FormattedMessage id='general.back' />
                            </Button>
                        }
                        <Button loading={loading} onClick={() => { submitForm('save'); }} className='save-button'>
                            <i className='fal fa-save header-item' />
                            <FormattedMessage id={status === 'DRAFT' ? 'campaign.save_as_draft' : 'general.save'} />
                        </Button>
                        {!newCampaignStatus && launchButton(submitForm)}
                    </>
                }
            />
            <CampaignActivitiesModal
                visible={showResourceModal}
                onCancel={() => setShowResourceModal(false)}
                owner={owner}
                setCampaignActivities={setCampaignActivities}
                setMinutes={setMinutes}
                campaignId={campaignId}
                campaignCourses={courses}
                setCustomCourse={setCustomCourse}
                setPhishingCampaign={setPhishingCampaign}
                setLoadCourses={setLoadCourses}
                campaignType={campaignType}
                campaign={campaign}
            />
            <CustomActivityModal
                visible={createCustomActivityModal}
                onCancel={() => setCreateCustomActivityModal(false)}
                campaignId={campaignId}
                campaign={campaign}
                customCourse={customCourse}
                submit={submitForm}
            />
        </>
    )
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CampaignActivities));
