import React, {useState, useEffect} from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import { Input, Select} from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';

const mapStateToProps = (state: any) => {
  return {
    locale: state.locale,
    phishingSimulationTemplateView: state.phishingSimulationTemplateView.templateLanguage
  }
};

const PhishingSimulationGophishTemplates: React.FC<any> = (
{
  setGophishTemplateName,
  setGophishTemplateId,
  setGophishLandingPageName,
  setGophishLandingPageId,
  setGophishSendingProfileName,
  setGophishSendingProfileId,
  simulationId,
  templates,
  form,
  locale,
  phishingSimulationTemplateView,
  match
}:any
  ) => {
    const [phishingTemplates, setPhishingTemplates] = useState();
    const [phishingLandingPages, setPhishingLandingPages] = useState();
    const [selectLanguageListName, setSelectLanguageListName] = useState({});
    const [sendingProfiles, setSendingProfiles] = useState<any>();
    const [selectedSimulationLanguage, setSelectedSimulationLanguage] = useState();
    const [newLanguageTab, setNewLanguageTab] = useState(false);

    const intl = useIntl();
    const pageSelectedLanguage = match.params.language;

    useEffect(() => {
      if (pageSelectedLanguage) {
        setSelectedSimulationLanguage(pageSelectedLanguage);
      } else if (pageSelectedLanguage === 'NEW_LANGUAGE') {
        setNewLanguageTab(true);
      }

      const loadPhishingTemplates = async () => {
          let templates = await coursesApiClient.request(`/api/v1/courses/gophish/templates`, {}, 'GET');
          let gophishTemplates:any = [];
          templates.gophishTemplates.map((el:any)=> gophishTemplates.push([el.name, el.id]));
          setPhishingTemplates(gophishTemplates);
      };

      const loadPhishingLandingPages = async () => {
          let pages = await coursesApiClient.request(`/api/v1/courses/gophish/pages`, {}, 'GET');
          let gophishPages:any = [];
          pages.gophishPages.map((el:any)=> gophishPages.push([el.name, el.id]));
          setPhishingLandingPages(gophishPages);
      };

      const loadSendingProfiles = async () => {
        let response = await coursesApiClient.request('/api/v1/courses/gophish/sending-profiles', [], 'GET');
        let gophishSendingProfilesArray:any = [];
        response.gophishSendingProfiles.map((el:any)=> gophishSendingProfilesArray.push([el.name, el.id]));
        setSendingProfiles(gophishSendingProfilesArray);
      };

      const loadLanguages = async () => {
        let languages = await apiClient.request(`/api/v1/language`, {}, 'GET');
        let languageListNameForSelect:any = {};
        Object.values(languages.languages).map((value:any) => {
          return languageListNameForSelect[value['code']] = value['name']
        });
        setSelectLanguageListName(languageListNameForSelect);
      };

      loadSendingProfiles();
      loadPhishingTemplates();
      loadPhishingLandingPages();
      loadLanguages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    useEffect(() => {
        if (pageSelectedLanguage && templates) {
            Object.values(templates).map((el: any) => {
                if (pageSelectedLanguage === el.language) {
                    setGophishTemplateId(el.gophish_template_id);
                    setGophishLandingPageId(el.gophish_landing_page_id);
                    setGophishTemplateName(el.template_name);
                    setGophishLandingPageName(el.landing_page_name);
                    setGophishSendingProfileName(el.gophish_sending_profile);
                    setGophishSendingProfileId(el.gophish_sending_profile_id);
                    setSelectedSimulationLanguage(pageSelectedLanguage);
                    form.setFieldsValue({
                        language: selectedSimulationLanguage,
                        templateNameInChosenLanguage: el.simulation_template_name,
                        gophishTemplates: el.template_name,
                        gophishPages: el.landing_page_name,
                        gophishSendingProfile: el.gophish_sending_profile,
                        listeningUrl: el.url
                    });
                }
                return null;
            });
        } else if (!pageSelectedLanguage && templates) {
            setFieldValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageSelectedLanguage, templates]);

    const setFieldValues = () => {
        if (templates && !newLanguageTab) {
            Object.values(templates).map((el:any)=> {
                if (selectedSimulationLanguage === el.language) {
                    setGophishTemplateId(el.gophish_template_id);
                    setGophishLandingPageId(el.gophish_landing_page_id);
                    setGophishTemplateName(el.template_name);
                    setGophishLandingPageName(el.landing_page_name);
                    setGophishSendingProfileName(el.gophish_sending_profile);
                    setGophishSendingProfileId(el.gophish_sending_profile_id);
                    form.setFieldsValue({
                        language: selectedSimulationLanguage,
                        templateNameInChosenLanguage: el.simulation_template_name,
                        gophishTemplates: el.template_name,
                        gophishPages: el.landing_page_name,
                        gophishSendingProfile: el.gophish_sending_profile,
                        listeningUrl: el.url
                    });
                } else if (locale.locale === el.language) {
                    setGophishTemplateId(el.gophish_template_id);
                    setGophishLandingPageId(el.gophish_landing_page_id);
                    setGophishTemplateName(el.template_name);
                    setGophishLandingPageName(el.landing_page_name);
                    setGophishSendingProfileName(el.gophish_sending_profile);
                    setGophishSendingProfileId(el.gophish_sending_profile_id);
                    form.setFieldsValue({
                        language: el.language,
                        templateNameInChosenLanguage: el.simulation_template_name,
                        gophishTemplates: el.template_name,
                        gophishPages: el.landing_page_name,
                        gophishSendingProfile: el.gophish_sending_profile,
                        listeningUrl: el.url
                    });
                } else if ('EN' === el.language) {
                    setGophishTemplateId(el.gophish_template_id);
                    setGophishLandingPageId(el.gophish_landing_page_id);
                    setGophishTemplateName(el.template_name);
                    setGophishLandingPageName(el.landing_page_name);
                    setGophishSendingProfileName(el.gophish_sending_profile);
                    setGophishSendingProfileId(el.gophish_sending_profile_id);
                    form.setFieldsValue({
                        language: el.language,
                        templateNameInChosenLanguage: el.simulation_template_name,
                        gophishTemplates: el.template_name,
                        gophishPages: el.landing_page_name,
                        gophishSendingProfile: el.gophish_sending_profile,
                        listeningUrl: el.url
                    });
                }
                return null;
            })
        }
    };

    const getPhishingTemplateByLanguage = (languageCode:any) => {
      if (simulationId) {
        if (templates) {
          Object.values(templates).map((el:any)=> {
            if (languageCode === el.language) {
              form.setFieldsValue({
                templateNameInChosenLanguage: el.simulation_template_name,
                gophishTemplates: el.template_name,
                gophishPages: el.landing_page_name,
                gophishSendingProfile: el.gophish_sending_profile,
                listeningUrl: el.url
              });
            }
            return null;
          })
        }
      }
    };

    const clearFields = () => {
      form.setFieldsValue({
        templateNameInChosenLanguage: [],
        gophishTemplates: [],
        gophishPages: [],
        gophishSendingProfile: [],
        listeningUrl: []
      });
    };

  return (
    <>
      <DefaultLayout.PageLayout>
        <Select
            name='language'
            showArrow
            label={intl.formatMessage({id: "general.language"})}
            manualOptions={selectLanguageListName}
            onChange={(value: any, label:any) => {clearFields(); getPhishingTemplateByLanguage(label.value)}}
            customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
            />
        <Input name="templateNameInChosenLanguage" label={intl.formatMessage({id: 'phishing.template_name_in_chosen_language'})} validation={{required: true}} />
        <Select
            name='gophishTemplates'
            showArrow
            label={intl.formatMessage({id: "phishing.gophish_templates"})}
            manualOptions={phishingTemplates}
            onChange={(value: any, options:any) => {setGophishTemplateName(options.children[0]); setGophishTemplateId(options.children[1]);}}
            customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
        />
        <Select
            name='gophishPages'
            showArrow
            label={intl.formatMessage({id: "phishing.gophish_pages"})}
            manualOptions={phishingLandingPages}
            onChange={(value: any, options:any) => {setGophishLandingPageId(options.children[1]); setGophishLandingPageName(options.children[0]);}}
            customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
        />
        <Select
          name='gophishSendingProfile'
          showArrow
          label={intl.formatMessage({id: 'phishing.sending_profile'})}
          customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
          manualOptions={sendingProfiles}
          onChange={(value:any, options:any)=>{setGophishSendingProfileId(options.children[1]); setGophishSendingProfileName(options.children[0]);}}
        />
        <Input name="listeningUrl" label={intl.formatMessage({id: 'phishing.url'})} validation={{required: true }}/>
      </DefaultLayout.PageLayout>
    </>
  )
};

export default connect(mapStateToProps)(withRouter(PhishingSimulationGophishTemplates));
