import React, { useState, useEffect } from 'react';
import { Tabs, Form, TimePicker, Checkbox, Row } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import moment from 'moment';

import DayPicker from 'components/DayPicker';

import './styles.scss';

const DAILY = 'daily';
const WEEKLY = 'weekly';
const MONTHLY = 'monthly';

const INITIAL_BLANK_VALUES: any = {
  everyDayAt: null,
  everyWeekOn: null,
  everyWeekAt: null,
  everyMonthOn: null,
  everyMonthAt: null
}

const CRONBuilder: React.FC<any> = ({onChange, initialValue}) => {
  const [activeTab, setActiveTab] = useState(DAILY);
  const [errors, setErrors] = useState<Array<string>>([]);
  const intl = useIntl();
  const [CRONBuilderForm] = Form.useForm();

  const DAY_OF_WEEK_OPTIONS = [
    { value: 1, label: intl.formatMessage({id: 'emailing.scenarios.day.1'}) },
    { value: 2, label: intl.formatMessage({id: 'emailing.scenarios.day.2'}) },
    { value: 3, label: intl.formatMessage({id: 'emailing.scenarios.day.3'}) },
    { value: 4, label: intl.formatMessage({id: 'emailing.scenarios.day.4'}) },
    { value: 5, label: intl.formatMessage({id: 'emailing.scenarios.day.5'}) },
    { value: 6, label: intl.formatMessage({id: 'emailing.scenarios.day.6'}) },
    { value: 0, label: intl.formatMessage({id: 'emailing.scenarios.day.0'}) },
  ];

  useEffect(() => {
    setErrors([]);
  }, [activeTab]);

  useEffect(() => {
    const setParsedInitialValue = () => {
      let result = INITIAL_BLANK_VALUES;
      let expressionElements = initialValue.split(' ');
      let timeAt = moment().set('hours', parseInt(expressionElements[1])).set('minutes', parseInt(expressionElements[0]));
  
      if (expressionElements[2] !== '*') {
        result.everyMonthAt = timeAt;
        result.everyMonthOn = expressionElements[2].split(',').map((el: string) => parseInt(el));
        setActiveTab(MONTHLY);
      } else if (expressionElements[4] !== '*') {
        result.everyWeekAt = timeAt;
        result.everyWeekOn = expressionElements[4].split(',').map((el: string) => parseInt(el));
        setActiveTab(WEEKLY);
      } else {
        result.everyDayAt = timeAt;
      }
  
      CRONBuilderForm.setFieldsValue(result);
    };

    if (initialValue) {
      setParsedInitialValue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const BuildCRONExpression = () => {
    let CRONExpression = '';
    let CRONBuilderFormValues = CRONBuilderForm.getFieldsValue();
    const {everyDayAt, everyWeekAt, everyWeekOn, everyMonthAt, everyMonthOn} = CRONBuilderFormValues;

    // 0 = 0 minutes, inputs only allow to select hours
    switch(activeTab) {
      case DAILY:
        if (everyDayAt) {
          CRONExpression = DailyCRONBuilder(
            0,
            moment(everyDayAt).hours()
          );
        } else {
          let invalidFields = [];
          if (!everyDayAt) {
            invalidFields.push('everyDayAt');
          }
          setErrors(invalidFields);
        }
        break;
      case WEEKLY:
        if (everyWeekAt && Array.isArray(everyWeekOn) && everyWeekOn.length > 0) {
          CRONExpression = WeeklyCRONBuilder(
            0,
            moment(everyWeekAt).hours(),
            everyWeekOn
          );
        } else {
          let invalidFields = [];
          if (!Array.isArray(everyWeekOn) || everyWeekOn.length === 0) {
            invalidFields.push('everyWeekOn');
          }
          if (!everyWeekAt) {
            invalidFields.push('everyWeekAt');
          }
          setErrors(invalidFields);
        }
        break;
      case MONTHLY:
        if (everyMonthAt && Array.isArray(everyMonthOn) && everyMonthOn.length > 0) {
          CRONExpression = monthlyCRONBuilder(
            0,
            moment(everyMonthAt).hours(),
            everyMonthOn
          );
        } else {
          let invalidFields = [];
          if (!Array.isArray(everyMonthOn) || everyMonthOn.length === 0) {
            invalidFields.push('everyMonthOn');
          }
          if (!everyMonthAt) {
            invalidFields.push('everyMonthAt');
          }
          setErrors(invalidFields);
        }
        break;
    }

    onChange(CRONExpression || null);
  };

  const DailyCRONBuilder = (minutes: number, hours: number) => {
    setErrors([]);
    return `${minutes} ${hours} * * *`;
  };

  const WeeklyCRONBuilder = (minutes: number, hours: number, weekDays: Array<number>) => {
    setErrors([]);
    return `${minutes} ${hours} * * ${weekDays.join()}`;
  };

  const monthlyCRONBuilder = (minutes: number, hours: number, monthDays: Array<number>) => {
    setErrors([]);
    return `${minutes} ${hours} ${monthDays.join()} * *`;
  };

  return (
    <Form
      form={CRONBuilderForm}
      layout="horizontal"
    >
      <Tabs onChange={(tab) => setActiveTab(tab)} type='card' activeKey={activeTab}>
        <Tabs.TabPane tab={intl.formatMessage({id: 'emailing.scenarios.daily'})} key={DAILY}>
          <Form.Item
            name='everyDayAt'
            label={intl.formatMessage({id: 'emailing.scenarios.every_day_at'})}
            hasFeedback={errors.includes('everyDayAt')}
            validateStatus={errors.includes('everyDayAt') ? 'error' : undefined}
            help={errors.includes('everyDayAt') ? intl.formatMessage({id: 'validation.field_required'}) : undefined}
            rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
            className='scenarios-at'
          >
            <TimePicker
              format='HH:mm'
              showNow={false}
              placeholder={intl.formatMessage({id: 'emailing.scenarios.select_hour'})}
              onChange={() => BuildCRONExpression()}
              minuteStep={60}
            />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab={intl.formatMessage({id: 'emailing.scenarios.weekly'})} key={WEEKLY}>
          <Form.Item
            name='everyWeekOn'
            label={intl.formatMessage({id: 'emailing.scenarios.every_week_on'})}
            hasFeedback={errors.includes('everyWeekOn')}
            validateStatus={errors.includes('everyWeekOn') ? 'error' : undefined}
            help={errors.includes('everyWeekOn') ? intl.formatMessage({id: 'validation.field_required'}) : undefined}
            rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
          >
            <Checkbox.Group onChange={() => BuildCRONExpression()}>
              {DAY_OF_WEEK_OPTIONS.map((el: any) =>
                <Row>
                  <Checkbox value={el.value}>
                    <FormattedMessage id={el.label} />
                  </Checkbox>
                </Row>
              )}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item
            name='everyWeekAt'
            label={intl.formatMessage({id: 'emailing.scenarios.at'})}
            hasFeedback={errors.includes('everyWeekAt')}
            validateStatus={errors.includes('everyWeekAt') ? 'error' : undefined}
            help={errors.includes('everyWeekAt') ? intl.formatMessage({id: 'validation.field_required'}) : undefined}
            rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
            className='scenarios-at'
          >
            <TimePicker
              format='HH:mm'
              showNow={false}
              placeholder={intl.formatMessage({id: 'emailing.scenarios.select_hour'})}
              onChange={() => BuildCRONExpression()}
              minuteStep={60}
            />
          </Form.Item>
        </Tabs.TabPane>
        <Tabs.TabPane tab={intl.formatMessage({id: 'emailing.scenarios.monthly'})} key={MONTHLY}>
          <Form.Item
            name='everyMonthOn'
            label={intl.formatMessage({id: 'emailing.scenarios.every_month_on_day'})}
            hasFeedback={errors.includes('everyMonthOn')}
            validateStatus={errors.includes('everyMonthOn') ? 'error' : undefined}
            help={errors.includes('everyMonthOn') ? intl.formatMessage({id: 'validation.field_required'}) : undefined}
            rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
          >
            <DayPicker form={CRONBuilderForm} buildCronExpression={BuildCRONExpression}/>
          </Form.Item>
          <Form.Item
            name='everyMonthAt'
            label={intl.formatMessage({id: 'emailing.scenarios.at'})}
            hasFeedback={errors.includes('everyMonthAt')}
            validateStatus={errors.includes('everyMonthAt') ? 'error' : undefined}
            help={errors.includes('everyMonthAt') ? intl.formatMessage({id: 'validation.field_required'}) : undefined}
            rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
            className='scenarios-at'
          >
            <TimePicker
              format='HH:mm'
              showNow={false}
              placeholder={intl.formatMessage({id: 'emailing.scenarios.select_hour'})}
              onChange={() => BuildCRONExpression()}
              minuteStep={60}
            />
          </Form.Item>
        </Tabs.TabPane>
      </Tabs>
    </Form>
  )
}

export default CRONBuilder;
