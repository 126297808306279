import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import DefaultLayout from 'components/DefaultLayout';
import { Input, Select} from 'components/Form';
import PhishingSimulationsModal from 'components/FormCampaign/PhishingSimulationsModal';
import PhishingTestEmailModal from 'components/PhishingTestEmailModal';
import { Table, Form, Radio, Row, Col, Tabs, Tooltip, DatePicker, Alert, Button, Select as AntDSelect, message } from 'antd';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { InputNumber } from 'components/Form';
import './styles.scss';
import { connect } from 'react-redux';
import apiClient from 'utils/apiClient';

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 6},
};

const mapStateToProps = (state: any) => {
    return {
      session: state.session
    };
  };


const CustomPhishingCampaignSettings = ({
    customerId,
    campaignType,
    selectedSimulations,
    setSelectedSimulations,
    campaignStatus,
    disabledFields,
    form,
    campaignEndDate,
    disabledMainFields,
    phishingSettings,
    session,
    escalationManager,
    setEscalationManager,

    }:any) => {
    const [selectedTestSimulation, setSelectedTestSimulation] = useState<any>();
    const [sendTestPhishingEmailModal, setSendTestPhishingEmailModal] = useState(false);
    const [showPhishingSimulationModal, setShowPhishingSimulationModal] = useState(false);
    const [sendingHoursFrom, setSendingHoursFrom] = useState();
    const [sendingHoursTo, setSendingHoursTo] = useState();
    const [userPassedSimulationTest, setUserPassedSimulationTest] = useState();
    const [loading, setLoading] = useState(false);
    const [usersLoading, setUsersLoading] = useState(false);
    const [learningManagers, setLearningManagers] = useState();

    const intl = useIntl();
    const { TabPane } = Tabs;

    const DragHandle = SortableHandle(() => (<i className='fal fa-arrows header-item' style={{ cursor: 'pointer'}}/>));
    const SortableItem = SortableElement((props:any) =><tr {...props} index={12} />);
    const SortableContainers = SortableContainer((props:any) => <tbody {...props} />);
    const DragableBodyRow = ( ...restProps:any ) => {
      let index:any;
      if (selectedSimulations) {
        index = selectedSimulations.findIndex((x:any) => x.index === restProps[0]['data-row-key'])
      }
      return <SortableItem  index={index} {...restProps[0]} />
  };

    const onSortEnd = (oldIndex:any, newIndex:any ) => {
      if (oldIndex !== newIndex) {
        const newData = arrayMove([].concat(selectedSimulations), oldIndex.oldIndex, oldIndex.newIndex).filter(el => !!el);
        setSelectedSimulations(newData)
      }
    };

    const DraggableContainer = (props:any) => {
      return (
        <SortableContainers
          useDragHandle
          helperClass="row-dragging"
          onSortEnd={onSortEnd}
          {...props}
        />
      )
    };

    useEffect(() => {
        if (phishingSettings) {
            setUserPassedSimulationTest(phishingSettings.sendAfterPass);
        }
    }, [phishingSettings]);

    useEffect(()=>{
        let array:any = [];
        for (let i = 0; i < 24; i++) {
            array.push(i + ':00');
        }
        setSendingHoursFrom(array);
        setSendingHoursTo(array);
        loadCustomerLearningManagers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    const columnsSimulations = [
        {
            title: intl.formatMessage({id: 'phishing.nr'}),
            render: (text: string, record: any, index:any) => index + 1
        },
        {
            title: intl.formatMessage({id: 'phishing.template_name'}),
            dataIndex: 'name',
            key: 'name',
            className: 'drag-visible',
            render: (text: string, record: any) => record.simulationTemplateName
        },
        {
            title: intl.formatMessage({id: 'phishing.difficulty'}),
            dataIndex: 'difficulty',
            key: 'difficulty',
            render: (text: string, record: any) => intl.formatMessage({id: `phishing.${(record.difficulty).toLowerCase()}`})
        },
        {
            title: campaignType === 'STRICTLY' ? intl.formatMessage({id: 'phishing.date_picker'}) : null,
            render: (text: string, record: any) => {
                if (campaignType === 'STRICTLY') {
                    if (record.datePicker && record.datePicker.template_not_sent) {
                        return (
                            <>
                                <DatePicker
                                    style={{ width: 200 }}
                                    format="YYYY-MM-DD"
                                    disabledDate={(current: any) => disabledDates(current, true)}
                                    placeholder=""
                                    defaultValue={record.datePicker.template_not_sent ? moment(record.datePicker.template_not_sent[0]): undefined}
                                    onChange={(el:any)=>record.datePicker = moment(el)}
                                />
                                { moment(record.datePicker.template_not_sent[0]) < moment() ?
                                <p style={{color:'red'}}>
                                    {intl.formatMessage({id: 'validation.change_date'})}
                                </p>
                                : null
                                }
                            </>
                        );
                    } else {
                        return (
                            <DatePicker
                                style={{ width: 200 }}
                                format="YYYY-MM-DD"
                                disabledDate={(current: any) => disabledDates(current, true)}
                                placeholder=""
                                disabled={campaignStatus === 'DRAFT' ? undefined : moment(record.datePicker) < moment() }
                                defaultValue={record.datePicker ? moment(record.datePicker): undefined}
                                onChange={(el:any)=>record.datePicker = moment(el)}
                            />
                        );
                    }
                }
            }
        },
        {
            width: 50,
            minWidth: 20,
            render: (text: string, record: any) => {
                return (
                    <Button className='send-test-email-button' onClick={()=>{setSelectedTestSimulation(record.id); setSendTestPhishingEmailModal(true)}}>
                        <FormattedMessage id="phishing.send_test_phishing_button" />
                    </Button>
                )
            }
        },
        {
            title: '',
            className: 'drag-visible',
            render: () => {
                if (campaignType === 'DYNAMIC') {
                    return (
                        campaignStatus === 'DRAFT' ?
                        <DragHandle />
                       : null
                        )
                }
            }
        }
    ];

    const loadCustomerLearningManagers = async () => {
        setUsersLoading(true);
        
        try {
            let url = '/api/v1/auth/customer/customer-admins';

            if (session.active_user_type === 'SUPER_ADMIN' || session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN'  ) {
                url = url + '&customerId=' + customerId;
            }
    
            let response = await apiClient.request(url, [], 'GET');
    
            setLearningManagers(response.learningManagers);
    
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setUsersLoading(false);
        }
    };

    const disabledDates = (current: moment.Moment, to: boolean = false) => {
        const validDate = to ? moment() : moment().add('3', 'M');
        if (validDate === null) {
          return current < moment().add(1, 'day');
        }

        return (
          (to ? validDate > current : current > validDate) ||
          validDate.isSame(current, 'day') ||
          current < moment().add(1, 'day')
        );
      };

  return (
    <>
        <DefaultLayout.PageLayout>
            <Tabs defaultActiveKey='settings' className={campaignType === 'STRICTLY' ? 'campaign-settings-on-strictly' : 'campaign-settings-on-dynamic'}>
                <TabPane tab={campaignType === 'DYNAMIC' ? intl.formatMessage({id: 'phishing.main_settings'}) : null } key="settings">
                    <Input  className='custom-campaign-type' disabled value={campaignType} bordered={false} name="phishingCampaignType" label={intl.formatMessage({id: 'phishing.campaign_type'})}/>
                    <Input disabled={disabledMainFields} name="phishingCampaignName" label={intl.formatMessage({id: 'phishing.campaign_name'})} validation={{required: true}} />
                        {campaignType !== 'STRICTLY' ?
                            <Form.Item
                                className='datepicker-position'
                                labelCol={{span: 8 }}
                                wrapperCol={{span: 8 }}
                                label={
                                    <span>
                                        {intl.formatMessage({id: 'phishing.finish_campaign_on'})}
                                        <Tooltip title={intl.formatMessage({id: 'phishing.finish_campaign_on_hint'})}>
                                            <i className='fal fa-question-circle header-item' />
                                        </Tooltip>
                                    </span>
                                }
                                name='deadline'
                                rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
                            >
                                <DatePicker
                                    disabled={disabledMainFields}
                                    style={{ width: '30%' }}
                                    format="YYYY-MM-DD"
                                    disabledDate={(current: any) => disabledDates(current, true)}
                                    placeholder=""
                                />
                            </Form.Item>
                            :
                            null
                        }
                        <Row>
                            <Col span={11} style={{ marginLeft: '-1%' }}>
                                <Select
                                    style={{ width: '88%' }}
                                    customLayout={{
                                        labelCol: { span: 18 },
                                        wrapperCol: { span: 6 }
                                    }}
                                    customRules={[
                                        () => ({
                                            validator() {
                                                if (parseInt(form.getFieldValue('deliverPhishingFrom')) < parseInt(form.getFieldValue('deliverPhishingTo'))) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(intl.formatMessage({id: "validation.from_smaller_than_to"}));
                                            }
                                        })
                                    ]}
                                    disabled={disabledFields}
                                    manualOptions={sendingHoursFrom}
                                    name='deliverPhishingFrom'
                                    label={intl.formatMessage({id: 'phishing.deliver_phishing_time'})}
                                />
                            </Col>
                            <Col span={10}>
                                <Select
                                    style={{ width: '72%' }}
                                    customLayout={{
                                        labelCol: { span: 2 },
                                        wrapperCol: { span: 8 }
                                    }}
                                    customRules={[
                                        () => ({
                                            validator() {
                                                if (parseInt(form.getFieldValue('deliverPhishingFrom')) < parseInt(form.getFieldValue('deliverPhishingTo'))) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(intl.formatMessage({id: "validation.to_bigger_than_from"}));
                                            }
                                        })
                                    ]}
                                    disabled={disabledFields}
                                    manualOptions={sendingHoursTo}
                                    name='deliverPhishingTo'
                                    label={intl.formatMessage({id: 'phishing.deliver_phishing_time_and'})}
                                />
                            </Col>
                        </Row>
                    <InputNumber
                        disabled={disabledFields}
                        name="mailPerMinute"
                        label={intl.formatMessage({id: 'phishing.maximum_emails_per_minute'})}
                        customRules={[{ required: true, pattern:/^[1-9]\d*$/, message: intl.formatMessage({id: "validation.must_be_integer"})}]}
                        min={1}
                        max={60}
                    />
                    <Form.Item
                        {...formItemLayout}
                        name="phishDelivery"
                        label={intl.formatMessage({id: "phishing.phish_delivery"})}
                    >
                        <Radio.Group disabled={disabledFields}>
                            <Radio value={'RANDOMIZED'}><FormattedMessage id='phishing.custom_randomized'/></Radio>
                            <Radio value={'SEQUENCIAL'}><FormattedMessage id='phishing.custom_strictly'/></Radio>
                        </Radio.Group>
                    </Form.Item>
                    <div className='phishing-fields'>
                        <Form.Item
                            {...formItemLayout}
                            name='notifyManager'
                            label={
                                <span>
                                    {intl.formatMessage({ id: 'phishing.notify_manager_on_fail' })}
                                    <Tooltip title={intl.formatMessage({ id: 'phishing.notify_manager_on_fail_hint' })}>
                                        <i className='fal fa-question-circle header-item' />
                                    </Tooltip>
                                </span>
                            }
                        >
                            <Radio.Group disabled={disabledFields} onChange={(event:any) => setEscalationManager(event.target.value)}>
                                <Radio value={true}><FormattedMessage id='phishing.yes' /></Radio>
                                <Radio value={false}><FormattedMessage id='phishing.no' /></Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    <div className='phishing-fields'>
                        {escalationManager &&
                            <Form.Item
                                {...{labelCol: {span: 8}, wrapperCol: {span: 6}}}
                                name='escalationManager'
                                rules={[{ required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}

                                label={intl.formatMessage({id: 'phishing.escalation_manager'})}
                            >
                                <AntDSelect
                                    allowClear
                                    showSearch
                                    notFoundContent='No customer users found'
                                    disabled={disabledFields}
                                    style={{height: '100%', width: '100%', borderRadius: 8}}
                                    loading={usersLoading}
                                    dropdownStyle={{minWidth: '400px'}}
                                >
                                    {learningManagers &&
                                        Object.values(learningManagers).map((el: any) => {
                                            return <AntDSelect.Option key={el.userTypeUuid} value={el.userTypeUuid}>{`${el.name} ${el.surname} (${el.email})`}</AntDSelect.Option>
                                        })
                                    }
                                </AntDSelect>
                            </Form.Item>
                        }
                    </div>
                    <div className='phishing-fields'>
                        <Form.Item
                            {...formItemLayout}
                            name='notifyStudent'
                            label={
                                <span>
                                    {intl.formatMessage({ id: 'phishing.notify_student_on_fail' })}
                                    <Tooltip title={intl.formatMessage({ id: 'phishing.notify_student_on_fail_hint' })}>
                                        <i className='fal fa-question-circle header-item' />
                                    </Tooltip>
                                </span>
                            }
                        >
                            <Radio.Group disabled={disabledFields}>
                                <Radio value={true}><FormattedMessage id='phishing.yes' /></Radio>
                                <Radio value={false}><FormattedMessage id='phishing.no' /></Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                    {campaignStatus === 'DRAFT' || (campaignStatus === "ONGOING" && campaignType === 'STRICTLY') ?
                        <Button className='add-new-template-button' type="primary" onClick={()=>setShowPhishingSimulationModal(true)}>
                            <PlusOutlined /> <FormattedMessage id="phishing.add_new" />
                        </Button>
                    :
                    null
                    }
                    <Table
                        locale={{ emptyText: intl.formatMessage({id: "general.found_no_data"}) }}
                        className="table-body"
                        style={{marginTop: 16, clear: 'both'}}
                        rowKey={'index'}
                        columns={columnsSimulations.filter(ea => ea.dataIndex !== "index" && ea.dataIndex !== "")}
                        dataSource={selectedSimulations}
                        size="middle"
                        scroll={{ x: 800 }}
                        components={{
                            body: {
                            wrapper: DraggableContainer,
                            row: DragableBodyRow,
                            },
                        }}
                    />
                    <Alert className='smartdephish-alert' message={ [intl.formatMessage({id: 'general.info'}), ' ',  form.getFieldValue('mailPerMinute') * 60,  ' ', intl.formatMessage({id: 'phishing.info_on_campaign_end'}),  ' ', campaignEndDate  ]} type="info" showIcon style={{marginBottom: 24}} />
                </TabPane>
                {campaignType === 'DYNAMIC' ?
                    <TabPane tab={intl.formatMessage({id: 'phishing.advanced_settings'})} key="advancedSettings">
                        <div className='phishing-input-required'>
                            <InputNumber
                            disabled={disabledFields}
                            name='startAfterDays'
                            label={
                                <span>
                                {intl.formatMessage({id: 'phishing.begin_phishing_simulation_delivery'})}
                                    <Tooltip title={intl.formatMessage({id: 'phishing.begin_phishing_simulation_hint'})}>
                                        <i className='fal fa-question-circle header-item' />
                                    </Tooltip>
                                </span>
                            }
                            customRules={[{ required: true, pattern:/^[1-9]\d*$/, message: intl.formatMessage({id: "validation.must_be_integer"})}]}
                            min={1}
                            max={30}
                            />
                        </div>
                        <div className='phishing-input-required'>
                            <InputNumber
                            disabled={disabledFields}
                            name='sendAfterFailDays'
                            label={
                                <span>
                                {intl.formatMessage({id: 'phishing.if_user_failed_phishing'})}
                                <Tooltip title={intl.formatMessage({id: 'phishing.user_failed_phishing_hint'})}>
                                    <i className='fal fa-question-circle header-item' />
                                </Tooltip>
                                </span>
                            }
                            customRules={[{ required: true, pattern:/^[1-9]\d*$/, message: intl.formatMessage({id: "validation.must_be_integer"})}]}
                            min={1}
                            max={60}
                            />
                        </div>
                        <div className='phishing-fields'>
                            <Form.Item
                                {...formItemLayout}
                                name="sendAfterPass"
                                label={intl.formatMessage({id: "phishing.if_user_passed_phishing"})}
                            >
                                <Radio.Group disabled={disabledFields} onChange={(el:any)=>setUserPassedSimulationTest(el.target.value)}>
                                    <Radio value={'EVENLY'}>
                                        <FormattedMessage id='phishing.evenly_spread'/>
                                        <span>
                                            <Tooltip title={intl.formatMessage({id: 'phishing.evenly_spread_hint'})}>
                                                <i className='fal fa-question-circle header-item' />
                                            </Tooltip>
                                        </span>
                                    </Radio>
                                        <Radio value={'STRICTLY'}>
                                            <FormattedMessage id='phishing.strictly'/>
                                            <span>
                                                <Tooltip title={intl.formatMessage({id: 'phishing.strictly_hint'})}>
                                                    <i className='fal fa-question-circle header-item' />
                                                </Tooltip>
                                            </span>
                                        </Radio>
                                </Radio.Group>
                            </Form.Item>
                            {userPassedSimulationTest === 'STRICTLY' ?
                            <div className='days-input'>
                                <InputNumber
                                    disabled={disabledFields}
                                    name='sendAfterPassDays'
                                    label={intl.formatMessage({id: 'phishing.days'})}
                                    customRules={[{ required: true, pattern:/^[1-9]\d*$/, message: intl.formatMessage({id: "validation.must_be_integer"})}]}
                                    min={1}
                                    max={60}
                                />
                            </div>
                            : null
                            }
                        </div>
                        <div className='phishing-fields'>
                    </div>
                </TabPane>
                : null
                }
            </Tabs>
        </DefaultLayout.PageLayout>
        <PhishingSimulationsModal
            selectedSimulations={selectedSimulations}
            setSelectedSimulations={setSelectedSimulations}
            visible={showPhishingSimulationModal}
            onCancel={() => setShowPhishingSimulationModal(false)}
            setLoading={setLoading}
            loading={loading}
        />
        <PhishingTestEmailModal
            customerId={customerId}
            selectedTestSimulation={selectedTestSimulation}
            visible={sendTestPhishingEmailModal}
            onCancel={() => setSendTestPhishingEmailModal(false)}
            afterSubmit={() => setSendTestPhishingEmailModal(false)}
        />
    </>
  )
};

export default connect(mapStateToProps)(withRouter(CustomPhishingCampaignSettings));
