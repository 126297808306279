import * as ActionTypes from 'store/actionTypes';

const defaultState = {
    owner: null
  }
  
  const MailTemplateCustomerOptions = (state = defaultState, action: any) => {
    switch(action.type) {
      case ActionTypes.SAVE_MAIL_TEMPLATE_CUSTOMER_OPTIONS:
          let currentState = state;
          currentState.owner = action.payload;
        return currentState;
      default:
        return state;
    }
  }
  

export default MailTemplateCustomerOptions;
