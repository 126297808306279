import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import BarChart from 'components/Charts/BarChart';
import coursesApiClient from 'utils/coursesApiClient';

interface TotalTimeSpentChartProps {
  campaignId: number,
}

const TotalTimeSpentChart: React.FC<TotalTimeSpentChartProps> = ({ campaignId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  const intl = useIntl();

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const response = await coursesApiClient.request(`/api/v1/courses/statistic/campaign/${campaignId}/enrolled-students`);

        const data = response.timeReport;

        const parsedData = {
          labels: [
            intl.formatMessage({ id: 'campaign.estimated_time_to_complete' }),
            intl.formatMessage({ id: 'campaign.actual_logged_time' }),
          ],
          datasets: [
            {
              data: [
                // Convert seconds to hours
                data.estimated / 3600,
                data.actual / 3600,
              ],
              backgroundColor: [
                'rgb(102, 148, 224)',
                'rgb(33, 208, 160)',
              ],
              hoverBackgroundColor: [
                'rgb(82, 128, 204)',
                'rgb(13, 188, 140)',
              ],
            },
          ],
        };

        setData(parsedData);
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, [intl, campaignId]);

  return (
    <BarChart
      data={data}
      title={intl.formatMessage({ id: 'campaign.total_time_spent_in_elearning' })}
      dataLabelAppend={` ${intl.formatMessage({ id: 'general.hours' }).toLowerCase()}`}
      loading={isLoading}
    />
  );
}

export default TotalTimeSpentChart;