import React, { useEffect, useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { Alert, message } from 'antd';
import Spinner from '../Spinner';
import config from 'config/config';
import apiClient from '../../utils/apiClient';
import coursesApiClient from '../../utils/coursesApiClient';
import './styles.scss';

const mapStateToProps = (state: any) => ({ session: state.session });

interface LearningActivityProps extends RouteComponentProps<any> {
  learningActivityId: number;
  activityAttemptId?: number;
  type: 'learn' | 'test';
  isFirstAttempt?: boolean;
  session?: any;
  parentActivityId?: number;
  campaignId?: string;
  language?: string;
  version?: string;
}

type ServerRequestType = {
    learningActivityId: number;
    activityAttemptId?: number;
    type: 'learn' | 'test';
    userTypeUuid: string;
    language?: string;
    version?: string;
}

const LearningActivity: React.FC<LearningActivityProps> = ({
  learningActivityId, activityAttemptId, type,
  isFirstAttempt, session, history, parentActivityId, campaignId, language, version
}) => {
  const [isLearningActivityLoading, setIsLearningActivityLoading] = useState(false);
  const [source, setSource] = useState<string|null>(null);
  const [hasError, setHasError] = useState(false);

  const intl = useIntl();

  useEffect(() => {
    const consumeLicenceAccess = async () => {
      try {
        const values = {
          activityId: learningActivityId,
          userTypeId: session.active_user_type_id,
          customerId: session.organization.organization_id,
          parentActivityId: parentActivityId,
          campaignId: campaignId
        }

        return await apiClient.request('/api/v1/licences/consume', values, 'POST');
      } catch(error) {
        message.error(intl.formatMessage({ id: 'error.data_load' }));
        console.error(error);
      }
    }

    const loadLearningActivity = async () => {
      try {
        setIsLearningActivityLoading(true);
        setHasError(false);

        if (!learningActivityId || !type) {
          history.goBack();
        }

        const parsedValues: ServerRequestType = {
            learningActivityId,
            activityAttemptId,
            type,
            userTypeUuid: session.active_user_type_uuid
        };

        if (language) {
            parsedValues.language = language
        }

        if (version) {
            parsedValues.version= version
        }

        const serveResponse = await coursesApiClient.request(`/api/v1/courses/serve-request`, parsedValues, 'POST');
        const serveRequest = serveResponse.learningActivityServeRequest;

        setSource(`${config.api.serveUrl}/${serveRequest.uuid}`);

        if (isFirstAttempt) {
          consumeLicenceAccess();
        }
      } catch (err: any) {
        if (err.message === 'already_active_scorm') {
          message.error(intl.formatMessage({id: 'error.already_active_activity'}));
        } else {
          setHasError(true);
          console.error(err);
          message.error(intl.formatMessage({id: 'error.data_load'}));
        }
      } finally {
        setIsLearningActivityLoading(false);
      }
    }

    loadLearningActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl, learningActivityId, activityAttemptId, type, isFirstAttempt, session]);

  return (
    <Spinner spinning={isLearningActivityLoading}>
      {hasError
        ? <Alert
            message={intl.formatMessage({id: 'campaign.learning_activity_not_found'})}
            type="error"
            showIcon
          />
        : null
      }
      {source ? <iframe className="scorm-player" title="SCORM_PLAYER" src={source}/> : null}
    </Spinner>
  );
}

export default connect(mapStateToProps)(withRouter(LearningActivity));
