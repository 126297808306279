import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Modal, Col, Row, Table, Popover, Tabs, Input as AntDInput, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import { Select, Input, Switch } from 'components/Form';
import LanguageImportModal from 'components/LanguageImportModal';
import LanguageExportModal from 'components/Form/LanguageExportModal';
import './styles.scss';

const mapStateToProps = (state: any) => {
  return {
    languageDictionary: state.languageDictionary
  }
}

const ManageLanguages: React.FC = ({ languageDictionary }: any) => {
  const [isLanguagesLoading, setIsLanguagesLoading] = useState(true);
  const [isTranslationsLoading, setIsTranslationsLoading] = useState(false);
  const [languageList, setLanguagesList] = useState([]);
  const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const [isAddLanguage, setIsAddLanguage] = useState(false);
  const [selectLanguageList, setSelectLanguageList] = useState({});
  const [translationsStringsDataList, setTranslationsStringsDataList] = useState<any>({});
  const [translationsStringsDataListBeforeFilter, setTranslationsStringsDataListBeforeFilter] = useState<any>({});
  const [translationsStringsDataListModule, setTranslationsStringsDataListModule] = useState<any>({});
  const [firstLanguageSelectedValue, setFirstLanguageSelectedValue] = useState<any>();
  const [secondLanguageSelectedValue, setSecondLanguageSelectedValue] = useState<any>();
  const [changedFirstTranslations, setChangedFirstTranslations] = useState<any>([{}]);
  const [changedSecondTranslations, setChangedSecondTranslations] = useState<any>([{}]);
  const [createLanguage, setCreateLanguage] = useState<any>([]);
  const [selectedLanguage, setSelectedLanguage] = useState<any>([]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState<any>(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const [moduleListEnum, setModuleListEnum] = useState<any>();
  const [publishSwitch, setPublishSwitch] = useState<any>(false);
  const [showLanguageImportModal, setShowLanguageImportModal] = useState(false);
  const [showLanguageExportModal, setShowLanguageExportModal] = useState(false);
  const [languageCodeList, setLanguagesCodeList] = useState();

  const [form] = Form.useForm();
  const [translationForm] = Form.useForm();
  const { TabPane } = Tabs;
  const { Search } = AntDInput;
  const intl = useIntl();
  const includeColumn = ['translation', 'secondTranslation', 'key'];

  useEffect(() => {
    const loadLanguageList = async () => {
      setIsLanguagesLoading(true)
      let languages = await apiClient.request(`/api/v1/language`, {}, 'GET');
      setLanguagesList(languages.languages);
      let languageListForSelect: any = {};
      const languageCodeArray: any = {};
      let englishId;
      Object.values(languages.languages).map((value: any, index: any) => {
        languageCodeArray[value['code']] = value['name'];
        languageListForSelect[value['id']] = value['name']
        if (value['code'] === 'EN') {
          englishId = value['id']
          translationForm.setFieldsValue({ firstLanguage: value['id'] })
          setFirstLanguageSelectedValue(value['name']);
        }
        return translationForm;
      })
      setSelectLanguageList(languageListForSelect);
      setLanguagesCodeList(languageCodeArray);

      if (englishId) {
        let translationList = await apiClient.request(`/api/v1/language/${englishId}/interface-translations`, {}, 'GET');
        setTranslationsStringsDataList(translationList.translations);
        setTranslationsStringsDataListBeforeFilter(translationList.translations)
      }
      setIsLanguagesLoading(false)
    }

    loadLanguageList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey]);

  useEffect(() => {
    const loadModuleList = async () => {
      let moduleListResponse = await apiClient.request('/api/v1/enum/language-module-type', {}, 'GET');
      let moduleList: any = [];
      Object.entries(moduleListResponse).map((value: any, index: any) => {
        moduleList[value[0]] = intl.formatMessage({ id: `system.${value[0]}` })
        return moduleList;
      })
      setModuleListEnum(moduleList);
    }
    if (!languageDictionary.loading) {
      loadModuleList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshKey, languageDictionary]);

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: any) => record.name
    },
    {
      title: intl.formatMessage({ id: 'system.languages.code' }),
      dataIndex: 'code',
      key: 'code',
      render: (text: string, record: any) => record.code
    },
    {
      title: intl.formatMessage({ id: 'system.languages.published' }),
      dataIndex: 'published',
      key: 'published',
      render: (text: string, record: any) => {
        return (<Switch key={`language-${record.id}`} defaultChecked={record.publish} onChange={(e: any) => onSwitchChange(record, e)} />)
      }
    },
    {
      title: intl.formatMessage({ id: 'system.languages.language_family' }),
      dataIndex: 'languageFamily',
      key: 'languageFamily',
      render: (text: string, record: any) => record.language_family
    },
    {
      dataIndex: 'actions',
      key: 'actions',
      render: (text: string, record: any, index: number) => {
        const content = <>
          <div className="popover-item" onClick={() => {setVisibleTooltip(null); editLanguage(record.id); checkAddOrEdit('edit') }} >
            <FormattedMessage id="general.edit" />
          </div>
          <div className="popover-item" onClick={() => {setVisibleTooltip(null); setSelectedLanguage(record.id); setShowDeleteConfirmationModal(true) }}>
            <FormattedMessage id="general.delete" />
          </div>
        </>;

        return (
          <Popover
            visible={index === visibleTooltip}
            content={content}
            trigger="click"
            placement="bottomRight"
            arrowPointAtCenter
            onVisibleChange={(visible) => handleVisibleChange(visible, index)}
          >
            <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
              <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} />
            </div>
          </Popover>
        )
      },
    }
  ];

  const columnsTranslation = [
    {
      title: intl.formatMessage({ id: 'system.languages.string' }),
      dataIndex: 'string',
      key: 'string',
      width: '35%',
      render: (text: string, record: any) => record.key
    },
    {
      title: intl.formatMessage({ id: 'system.languages.module' }),
      dataIndex: 'module',
      key: 'module',
      render: (text: string, record: any) => intl.formatMessage({ id: `system.${record.module}` })
    },
    {
      title: firstLanguageSelectedValue ? intl.formatMessage({ id: `system.languages.${firstLanguageSelectedValue}` }) : intl.formatMessage({ id: 'system.languages.first_language' }),
      dataIndex: 'firstLanguage',
      key: 'firstLanguage',
      render: (text: string, record: any) => {
        return (
          <>
            <AntDInput style={{ borderRadius: '8px' }} key={record.translation} onBlur={(el: any) => { setChangedFirstTranslations([...changedFirstTranslations, { [record.id]: el.target.value }]); record.translation = el.target.value }} defaultValue={record.translation ? record.translation : ''}></AntDInput>
          </>
        )
      }
    },
    {
      title: secondLanguageSelectedValue ? intl.formatMessage({ id: `system.languages.${secondLanguageSelectedValue}` }) : intl.formatMessage({ id: 'system.languages.second_language' }),
      dataIndex: 'secondLanguage',
      key: 'secondLanguage',
      render: (text: string, record: any) => {
        if (record.hasOwnProperty('secondTranslation')) {
          return (
            <>
              <AntDInput style={{ borderRadius: '8px' }} key={record.secondTranslation} onBlur={(el: any) => { setChangedSecondTranslations([...changedSecondTranslations, { [record.secondId]: el.target.value }]); record.secondTranslation = el.target.value }} defaultValue={record.secondTranslation ? record.secondTranslation : ''}></AntDInput>
            </>
          )
        }
      }
    }
  ];

  const handleVisibleChange = (visible: boolean, recordId: number) => {
    if (visible) {
      setVisibleTooltip(recordId);
    } else {
      setVisibleTooltip(null);
    }
  }

  const checkAddOrEdit = (value: string) => {
    if (value === 'add') {
      setIsAddLanguage(true)
    } else {
      setIsAddLanguage(false)
    }
    setShowLanguageModal(true)
  }

  const deleteLanguage = async () => {
    setIsLanguagesLoading(true);
    setShowDeleteConfirmationModal(false);
    await apiClient.request(`/api/v1/language/${selectedLanguage}`, {}, 'DELETE');
    setRefreshKey(oldKey => oldKey + 1);
    setIsLanguagesLoading(false);
  };

  const onSwitchChange = async (values: any, status: boolean) => {
    let parsedValues = {
      ...values,
      publish: status
    }
    await apiClient.request(`/api/v1/language/${values.id}`, parsedValues, 'PUT');
  }

  const filterBySearchString = (searchText: string, data: never[], includeColumn: string[]) => {
    return !searchText ? data : data.filter((data: any) => { return Object.keys(data).some(key => includeColumn.includes(key) && data[key] ? data[key].toString().toLowerCase().includes(searchText.toLocaleLowerCase()) : false); });
  }

  const filterBySearch = async (value: string) => {
    let values = translationForm.getFieldsValue();
    if (values['module'] != null) {
      let data = filterBySearchString(value, translationsStringsDataListModule, includeColumn);
      setTranslationsStringsDataList(data);
    } else {
      let data = filterBySearchString(value, translationsStringsDataListBeforeFilter, includeColumn);
      setTranslationsStringsDataList(data);
    }
  }

  const filterBySearchStringModule = (searchText: string, data: never[]) => {
    const filtered = data.filter(function (value: any) {
      return value !== undefined;
    });

    let includeColumnModule = ['module']
    return (!searchText ? filtered : filtered.filter((filtered: any) => {
      return (
        Object.keys(filtered).some(key => includeColumnModule.includes(key) && filtered[key] ? filtered[key].toString().toLowerCase().includes(searchText.toLocaleLowerCase()) : false)
      )
    }))
  }

  const filterBySearchModule = async (value: string) => {
    let data = filterBySearchStringModule(value, translationsStringsDataListBeforeFilter);
    setTranslationsStringsDataList(data);
    setTranslationsStringsDataListModule(data);
  }

  const getLanguageStrings = async (value: any, whichSelect: any) => {
    setIsTranslationsLoading(true)

    let translationList = await apiClient.request(`/api/v1/language/${value}/interface-translations`, {}, 'GET');

    const filtered = translationList.translations.filter(function (value: any) {
      return value !== undefined;
    });

    let translationStringData: any = [];
    Object.values(filtered).map((value: any, index: any) => {
      const type = translationsStringsDataListBeforeFilter.find((el: any) => el.interfaceKeysId === value['interfaceKeysId']);
      //TODO::if (type == null) message.error(intl.formatMessage({id: "error.missing_key_or_translation_key"}));
      translationStringData[index] = type;
      if (whichSelect === 'first') {
        translationStringData[index]['translation'] = value['translation']
      } else if (type !== null) {
        translationStringData[index]['secondTranslation'] = value['translation'] ? value['translation'] : ''
        translationStringData[index]['secondId'] = value['id']
      }
      return translationStringData;
    })
    setTranslationsStringsDataList(translationStringData);
    setTranslationsStringsDataListBeforeFilter(translationStringData);

    setIsTranslationsLoading(false)
  }

  const submitForm = async (values: any) => {
    let parsedValues
    if (createLanguage.length) {
      parsedValues = {
        ...values,
        name: createLanguage,
        publish: publishSwitch
      }
    } else {
      parsedValues = {
        ...values,
        publish: publishSwitch
      }
    }

    if (isAddLanguage) {
      await apiClient.request(`/api/v1/language`, parsedValues, 'POST');
    } else {
      await apiClient.request(`/api/v1/language/${selectedLanguage}`, parsedValues, 'PUT');
    }
    setPublishSwitch(false);
    setShowLanguageModal(false);
    setIsAddLanguage(false);
    setRefreshKey(oldKey => oldKey + 1);
    setLanguagesList([]);
  }

  const submitTranslationForm = async () => {
    setIsTranslationsLoading(true)
    if (changedFirstTranslations) {
      await apiClient.request(`/api/v1/language/interface-translations`, changedFirstTranslations, 'POST');
    }
    if (changedSecondTranslations) {
      await apiClient.request(`/api/v1/language/interface-translations`, changedSecondTranslations, 'POST');
    }
    setChangedFirstTranslations([]);
    setChangedSecondTranslations([]);
    setIsTranslationsLoading(false)
  }

  const editLanguage = async (id: any) => {
    let getLanguage = await apiClient.request(`/api/v1/language/${id}`, {}, 'GET');
    form.setFieldsValue(getLanguage.language)
    setPublishSwitch(getLanguage.language.publish)
    setSelectedLanguage(getLanguage.language.id)
    setShowLanguageModal(true);
  }

  const showTotal = (total: any, range: any) => {
    return `${range[0]}-${range[1]} of ${total}`
  };

  return (
    <>
      <DefaultLayout.PageLayout>
        <DefaultLayout.PageHeader title={intl.formatMessage({ id: "system.system" })} breadcrumb={[{ name: intl.formatMessage({ id: 'general.edit_languages' }), path: '/system/manage-languages' },]} />
        <DefaultLayout.PageContent>
          <Tabs type="card">
            <TabPane tab={intl.formatMessage({ id: "general.languages" })} key="1" disabled={isLanguagesLoading}>
              <Button
                type="primary"
                onClick={() => checkAddOrEdit('add')}
              >
                <PlusOutlined /> <FormattedMessage id="general.add" />
              </Button>
              <Table
                locale={{ emptyText: intl.formatMessage({ id: "general.found_no_data" }) }}
                loading={isLanguagesLoading}
                style={{ marginTop: 16 }}
                columns={columns}
                size="middle"
                scroll={{ x: 800 }}
                dataSource={languageList}
                pagination={{
                  showTotal: showTotal,
                  pageSizeOptions: ['10', '20'],
                  showSizeChanger: true,
                  locale: { items_per_page: "/page" },
                  showQuickJumper: true
                }}
              />
            </TabPane>
            <TabPane tab={intl.formatMessage({ id: "system.languages.translation_strings" })} key="2" disabled={isLanguagesLoading}>
              <Form form={translationForm} onFinish={submitTranslationForm}>
                <Row>
                  <Col>
                    <Select
                      name={'firstLanguage'}
                      label={intl.formatMessage({ id: "system.languages.first_language" })}
                      customLayout
                      manualOptions={selectLanguageList}
                      onChange={(val: any, option: any) => { setFirstLanguageSelectedValue(option.children); getLanguageStrings(val, 'first'); setChangedFirstTranslations([]); }}
                      className={'language-select'}
                      integerKey={true}
                    />
                  </Col>
                  <Col>
                    <Select
                      name={'secondLanguage'}
                      label={intl.formatMessage({ id: "system.languages.second_language" })}
                      customLayout
                      manualOptions={selectLanguageList}
                      onChange={(val: any, option: any) => { setSecondLanguageSelectedValue(option.children); getLanguageStrings(val, 'second'); setChangedSecondTranslations([]); }}
                      className={'language-select'}
                      integerKey={true}
                    />
                  </Col>
                  <Col>
                    <Select
                      name='module'
                      label={intl.formatMessage({ id: "system.module" })}
                      manualOptions={moduleListEnum}
                      customLayout
                      className={'module-select'}
                      onChange={(value: any) => { filterBySearchModule(value); translationForm.setFieldsValue({ search: null }) }}
                      allowClear
                    />
                  </Col>
                  <Col>
                    <Form.Item name='search'>
                      <Search
                        style={{ width: 150, borderRadius: 8 }}
                        placeholder={intl.formatMessage({ id: "general.search" })}
                        onSearch={value => filterBySearch(value)}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name='export'>
                      <Button style={{ marginLeft: 15 }} icon={<i className="fal fa-file-import" />} onClick={() => setShowLanguageExportModal(true)}>
                        <span><FormattedMessage id="system.language.export" /></span>
                      </Button>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name='import'>
                      <Button style={{ marginLeft: 15 }} icon={<i className="fal fa-file-export" />} onClick={() => setShowLanguageImportModal(true)}>
                        <span><FormattedMessage id="system.language.import" /></span>
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
                <Table
                  locale={{ emptyText: intl.formatMessage({ id: "general.found_no_data" }) }}
                  loading={isTranslationsLoading}
                  style={{ marginTop: 16 }}
                  columns={columnsTranslation}
                  size="middle"
                  scroll={{ x: 800 }}
                  dataSource={translationsStringsDataList}
                  pagination={{
                    showTotal: showTotal,
                    pageSizeOptions: ['10', '20'],
                    showSizeChanger: true,
                    locale: { items_per_page: "/page" },
                    showQuickJumper: true
                  }}
                />
              </Form>
            </TabPane>
          </Tabs>
        </DefaultLayout.PageContent>
        <DefaultLayout.PageFooter className='justify-content-end'>
          <div className="form-buttons">
            <NavLink to='/users'>
              <Button style={{ marginRight: 8 }}>
                <FormattedMessage id="general.cancel" />
              </Button>
            </NavLink>
            <Button type="primary" htmlType="submit" onClick={() => translationForm.submit()}>
              <FormattedMessage id="general.save" />
            </Button>
          </div>
        </DefaultLayout.PageFooter>
        <Modal
          visible={showLanguageModal}
          onCancel={() => {
            setShowLanguageModal(false);
            setPublishSwitch(false);
          }}
          onOk={() => form.submit()}
          title={isAddLanguage ? intl.formatMessage({ id: "system.languages.add_language" }) : intl.formatMessage({ id: "general.edit_language" })}
          cancelText={intl.formatMessage({ id: 'general.cancel' })}
          okText={isAddLanguage ? intl.formatMessage({ id: 'general.add' }) : intl.formatMessage({ id: 'general.save' })}
        >
          <Form form={form} onFinish={submitForm}>
            <Select
              allowSearch
              name='name'
              label={intl.formatMessage({ id: "general.name" })}
              onChange={(val: any, option: any) => { form.setFieldsValue({ code: val }); setCreateLanguage(option.children) }}
              url={`/api/v1/enum/language-types`}
            />
            <Input disabled={true} label={intl.formatMessage({ id: "system.languages.code" })} name={'code'} />
            <Select
              name='languageFamily'
              label={intl.formatMessage({ id: "system.languages.language_family" })}
              url={`/api/v1/enum/language-family-types`}
            />

            <Switch
              isFormItem
              hasDefaultLayout
              label= {intl.formatMessage({ id: "system.languages.published" })}
              checked={publishSwitch}
              onChange={(value: boolean) => setPublishSwitch(value)}
            />

          </Form>
        </Modal>
        <Modal
          title={intl.formatMessage({ id: 'system.languages.delete_language' })}
          visible={showDeleteConfirmationModal}
          onOk={() => deleteLanguage()}
          onCancel={() => setShowDeleteConfirmationModal(false)}
          okText={intl.formatMessage({ id: 'general.yes' })}
          cancelText={intl.formatMessage({ id: 'general.no' })}
        >
          {intl.formatMessage({ id: 'general.warning' })}!
        <br></br>
          <br></br>
          {intl.formatMessage({ id: 'system.languages.are_you_sure_you_want_to_delete_language' })}?
      </Modal>
      </DefaultLayout.PageLayout>
      <LanguageImportModal
        visible={showLanguageImportModal}
        onCancel={() => setShowLanguageImportModal(false)}
        languageList={languageCodeList}
      />
      <LanguageExportModal
        visible={showLanguageExportModal}
        onCancel={() => setShowLanguageExportModal(false)}
        languageList={languageCodeList}
      />
    </>
  )
}
export default connect(mapStateToProps)(withRouter(ManageLanguages));
