import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Form, Select as AntDSelect, Spin, Tooltip } from 'antd';
import apiClient from 'utils/apiClient';
import { constructValidationRules } from './validations';
import debounce from 'lodash/debounce';

interface FilterValues {
    input: string;
    campaignId?: number;
}

const RequestSelect: React.FC<any> = ({
    url,
    campaignId,
    valueKey,
    labelKey,
    mapDataEntries,
    name,
    label,
    validation = {},
    customRules,
    customLayout,
    disabled = false,
    help,
    customObjLabel,
    visible = true,
    className,
    hint,
    removeParam,
    translationModule,
    ...props
}) => {
    const [options, setOptions] = useState<any>([]);
    const [isOptionsLoading, setIsOptionsLoading] = useState(false);

    const intl = useIntl();

    // Fix for google autofill poping up in select input
    // https://github.com/ant-design/ant-design/issues/7659
    useEffect(() => {
        const fixAutocomplete = () => {
            document.querySelectorAll(".ant-select-selector input").forEach((e) => {
                e.setAttribute("autocomplete", "noAutocomplete");
                //you can put any value but NOT "off" or "false" because they DO NOT work
            })
        };

        fixAutocomplete();
    }, []);

    let labelCol: any = { span: 8 };
    let wrapperCol: any = { span: 6 };

    if (customLayout) {
        if (typeof customLayout === 'object') {
            labelCol = customLayout.labelCol || undefined;
            wrapperCol = customLayout.wrapperCol || undefined;
        } else {
            labelCol = undefined;
            wrapperCol = undefined;
        }
    }

    const loadOptions = async (input: string) => {
        if (input.length < 3) {
            return;
        }
        let filterValues: FilterValues = {
            input: input
        };

        if (campaignId) {
            filterValues.campaignId = campaignId
        }

        setIsOptionsLoading(true);

        let optionsResponse = await apiClient.request(url, filterValues, 'POST');
        setOptions(optionsResponse);
        setIsOptionsLoading(false);
    };

    const loadOptionsTimeOut = debounce(loadOptions, 800);

    useEffect(() => {
        const removeFromOptions = () => {
            let newOptions = options.filter((el: any) => el.userTypeUuid !== removeParam);
            setOptions(newOptions);
        };

        if (removeParam) {
            removeFromOptions();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [removeParam]);

    return (
        <Form.Item
            className={className}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
            name={name}
            label={
                hint
                    ? <span>
                        {intl.formatMessage({ id: label })}
                        <Tooltip title={intl.formatMessage({ id: hint })}>
                            <i className='fal fa-question-circle header-item' />
                        </Tooltip>
                    </span>
                    : label
            }
            rules={customRules || constructValidationRules(validation, intl)}
            help={help}
            style={{ display: visible ? '' : 'none' }}
        >
            <AntDSelect
                disabled={disabled}
                loading={isOptionsLoading}
                notFoundContent={isOptionsLoading ? <Spin size="small" /> : null}
                filterOption={false}
                maxTagCount={1}
                onSearch={loadOptionsTimeOut}
                dropdownMatchSelectWidth={400}
                {...props}
            >
                {
                    options.map((option: any) => {
                        let label = `${option.name} ${option.surname}`
                        return (
                            <AntDSelect.Option value={option[valueKey]} label={label}>
                                {option.name} {option.surname} ({option.email})
                            </AntDSelect.Option>
                        );
                    })
                }
            </AntDSelect>
        </Form.Item>
    )
};

export default RequestSelect;
