import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import { Spin, Form, Transfer, message, DatePicker, Divider, Checkbox, Button } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';

import DefaultLayout from 'components/DefaultLayout';
import { Input, Select, Title } from 'components/Form';

import config from 'config/config';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';

import './styles.scss';

// Custom hook to get previous state
const usePrevious = (value: any) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

const mapStateToProps = (state: any) => {
  return {
      locale: state.locale.locale
  };
}

const ServiceForm: React.FC = ({history, match, locale}: any) => {
  const [systemServiceType, setSystemServiceType] = useState<any>(null);
  const [serviceLoading, setServiceLoading] = useState<boolean>(true);
  const [submitInProgress, setSubmitInProgress] = useState<boolean>(false);
  const [coursesLoading, setCoursesLoading] = useState<boolean>(true);
  const [coursesList, setCoursesList] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [transferData, setTransferData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [validFromDate, setValidFromDate] = useState<moment.Moment|null>(null);
  const [validToDate, setValidToDate] = useState<moment.Moment|null>(null);
  const [indefinite, setIndefinite] = useState<boolean>(false);
  const [editing, setEditing] = useState(false);
  const [serviceTypesList, setServiceTypesList] = useState<any>({});
  const [serviceTypesLoading, setServiceTypesLoading] = useState(false);
  // There has to be a better way to do this (validate transfer input)
  const [activitiesValid, setActivitiesValid] = useState<boolean>(false);
  const [activitiesHasErrors, setActivitiesHasErrors] = useState<boolean>(false);
  const [courseUuid, setCourseUuid] = useState();

  const prevSelectedCourse = usePrevious(selectedCourse);
  const intl = useIntl();
  const [form] = Form.useForm();
  const serviceId = match.params.id;

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 6 }
  };

  useEffect(() => {
    if (!serviceId) {
      setServiceLoading(false);
    }
  }, [serviceId]);

  useEffect(() => {
    const loadService = async () => {
      try {
        const response = await apiClient.request(`/api/v1/services/${serviceId}`, {}, 'GET');
        const service = response.service;
        const formContent = JSON.parse(service.formContent);

        await setCourseUuid(formContent.course.uuid);
        setSystemServiceType(service.type);

        form.setFieldsValue({
          name: service.name,
          typeReadOnly: serviceTypesList[service.type],
        });

        switch (service.type) {
          case 'E_LEARNING_CONTENT':
            setSelectedCourse(formContent.course.uuid);

            setTargetKeys(formContent.course.activitiesIds);

            const validFrom = service.validFrom ? moment(service.validFrom) : null;
            setValidFromDate(validFrom);

            const indefinite = !(!!service.hasEndDate);
            setIndefinite(indefinite);

            let validTo = null;

            if (!indefinite) {
              validTo = moment(service.validTo);
              setValidToDate(validTo);
            }

            form.setFieldsValue({
              validFrom,
              validTo,
              indefinite
            });
            break;
          default:
            break;
        }
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({id: 'error.data_load'}));
      } finally {
        setServiceLoading(false);
      }
    };

    if (serviceId && Object.keys(serviceTypesList).length > 0) {
      loadService();
      setEditing(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceTypesList, coursesList]);

  useEffect(() => {
    const loadServiceTypes = async () => {
      try {
        setServiceTypesLoading(false);

        const serviceTypes = await apiClient.request(config.api.routes.enums.serviceTypes, {}, 'GET');

        setServiceTypesList(serviceTypes);
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({id: 'error.data_load'}));
      } finally {
        setServiceTypesLoading(false);
      }
    }

    loadServiceTypes();
  }, [intl, form, serviceId]);

  useEffect(() => {
    const loadCourses = async () => {
      try {
        setCoursesLoading(true);

        const courseList = await coursesApiClient.request('/api/v1/courses/courses/active', {}, 'GET');
        const courseListObject: any = {};

        Object.values(courseList).map((value: any, _next: any) => {
          return Object.values(value).map((data: any,  _next: any) => {
            return courseListObject[data['uuid']] = data['name'];
          });
        });

        if (courseUuid) {
          form.setFieldsValue({
            courseReadOnly: courseListObject[courseUuid]
          });
        }

        setCoursesList(courseListObject);
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({id: 'error.data_load'}));
      } finally {
        setCoursesLoading(false);
      }
    }

    if (systemServiceType === 'E_LEARNING_CONTENT' ) {
      loadCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemServiceType, courseUuid]);

  useEffect(() => {
    const loadLearningActivities = async () => {
      try {
        const response = await coursesApiClient.request(`/api/v1/courses/courses/${selectedCourse}/main-activities`);

        const activityData: any = [];

        response.activities.forEach((activity: any) => {

          const title = JSON.parse(activity.title);

          activityData.push({
            key: activity.id,
            name: title[locale] ? title[locale] : title[Object.keys(title)[0]],
          });
        });

        setTransferData(activityData);
        setSelectedKeys([]);

        if (prevSelectedCourse) {
          setTargetKeys([]);
        }
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({id: 'error.data_load'}));
      }
    }

    if (selectedCourse && selectedCourse !== prevSelectedCourse) {
      loadLearningActivities();
    } else if (!selectedCourse) {
      setTransferData([]);
      setTargetKeys([]);
      setSelectedKeys([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCourse, prevSelectedCourse, intl]);

  useEffect(() => {
    const hasActivities = !!targetKeys.length;

    setActivitiesValid(hasActivities);

    if (hasActivities) {
      setActivitiesHasErrors(false);
    }
  }, [targetKeys]);

  const submitForm = async (values: any) => {
    try {
      if (!activitiesValid) {
        setActivitiesHasErrors(true);
        return;
      }

      setActivitiesHasErrors(false);
      setSubmitInProgress(true);

      const parsedValues: any = {
        name: values.name,
        type: systemServiceType,
        formContent: {
          course: {
            uuid: selectedCourse,
            activitiesIds: [
              ...targetKeys,
            ],
          },
        },
        hasEndDate: !indefinite,
      };

      validFromDate && (parsedValues.validFrom = moment(validFromDate).format('YYYY-MM-DD'));

      if (!indefinite) {
        validToDate && (parsedValues.validTo = moment(validToDate).format('YYYY-MM-DD'));
      }

      if (moment(validToDate) <= moment()) {
        return message.error(intl.formatMessage({ id: 'error.learning_activity_is_licenced' }), 5);
      }

      if (match.params.id) {
        await apiClient.request(`/api/v1/services/${match.params.id}`, parsedValues, 'PUT');
      } else {
        await apiClient.request('/api/v1/services', parsedValues, 'POST');
      }

      message.success(intl.formatMessage({ id: `services.service_successfully_${match.params.id ? 'updated' : 'created'}` }));
      history.push('/licensing/manage-services');
    } catch (error: any) {
      const messageString = intl.formatMessage({ id: `error.${error.message.string}` })
        .replace(/{date}/g, moment(error.message.endDate).format('DD/MM/YYYY'));

      message.error(messageString, 20);
      console.error(error);
    } finally {
      setSubmitInProgress(false);
    }
  }

  const disabledDates = (current: moment.Moment, to: boolean = false) => {
    const validDate = to ? validFromDate : validToDate;

    if (validDate === null) {
      return current < moment().subtract(1, 'day');
    }

    return (
      (to ? validDate > current : current > validDate) ||
      validDate.isSame(current, 'day') ||
      current < moment().subtract(1, 'day')
    );
  };

  const handleCheckboxChange = (event: any) => {
    const checked = event.target.checked;

    setIndefinite(checked);
    form.setFieldsValue({ indefinite: event.target.checked });

    if (checked) {
      setValidToDate(null);
      form.setFieldsValue({ validTo: null });
    }
  }

  const handleSelectChange = (value: any) => {
    form.setFieldsValue({value});
    setSelectedCourse(value);
  }

  const renderInputs = () => {
    switch (systemServiceType) {
      case 'E_LEARNING_CONTENT':
        return (
          <>
            <Divider />
            {editing
              ? <Spin spinning={coursesLoading} size="small">
                  <Input
                    name="courseReadOnly"
                    label={intl.formatMessage({id: 'licensing.course'})}
                    validation={{required: true}}
                    readOnly
                  />
                </Spin>
              : <Select
                  name="course"
                  label={intl.formatMessage({id: 'licensing.select_course'})}
                  valueKey="value"
                  labelKey="label"
                  manualOptions={coursesList}
                  loading={coursesLoading}
                  onChange={(value: any) => handleSelectChange(value)}
                  validation={{required: true}}
                />
            }
            <Divider />
            <Title>{intl.formatMessage({id: 'licensing.add_learning_activities'})}</Title>
            <Form.Item
              wrapperCol={{ span: 12, offset: 5 }}
              className={activitiesHasErrors ? 'ant-form-item-with-help ant-form-item-has-error' : ''}
            >
              <Transfer
                locale={{notFoundContent: intl.formatMessage({id: "general.found_no_data"})}}
                dataSource={transferData}
                targetKeys={targetKeys}
                selectedKeys={selectedKeys}
                onSelectChange={(value: any) => { setSelectedKeys(value) }}
                onChange={(value: any) => { setTargetKeys(value) }}
                render={(item: any) => item.name}
                selectAllLabels={[intl.formatMessage({id: 'campaign.available'}, {amount: transferData.length > 0 ? transferData.length - targetKeys.length: 0}),intl.formatMessage({id: 'campaign.added'}, {amount: targetKeys.length})]}
                oneWay
                showSelectAll={false}
                disabled={!(!!selectedCourse)}
              />
              {activitiesHasErrors ? (
                <div className="ant-form-item-explain">
                  <div>{intl.formatMessage({id: 'select_at_least_1_activity'})}</div>
                </div>
              ) : null}
            </Form.Item>
            <Divider />
            <Title>{intl.formatMessage({id: 'licensing.service_terms'})}</Title>
            <Form.Item
              name="validFrom"
              {...formItemLayout}
              label={<span>{intl.formatMessage({id: 'licensing.valid_from'})}</span>}
            >
              <DatePicker
                format="DD/MM/YYYY"
                disabledDate={(current: any) => disabledDates(current)}
                placeholder=""
                onChange={(value: any) => setValidFromDate(value)}
              />
            </Form.Item>
            <Form.Item
              name="validTo"
              {...formItemLayout}
              label={<span>{intl.formatMessage({id: 'licensing.valid_to'})}</span>}
              rules={[{ required: !indefinite, message: intl.formatMessage({id: 'validation.field_required'}) }]}
            >
              <DatePicker
                format="DD/MM/YYYY"
                disabledDate={(current: any) => disabledDates(current, true)}
                disabled={indefinite}
                placeholder=""
                onChange={(value: any) => setValidToDate(value)}
              />
            </Form.Item>
            <Form.Item
              name="indefinite"
              label={<span>{intl.formatMessage({id: 'general.indefinite'})}</span>}
              {...formItemLayout}
            >
              <Checkbox
                onChange={(event: any) => handleCheckboxChange(event)}
                checked={indefinite}
              />
            </Form.Item>
          </>
        );
      case 'FEATURE':
        return null;
      case 'PROFESSIONAL_SERVICE':
        return null;
      default:
        return null;
    }
  }

  return (
    <DefaultLayout.PageLayout withStickyFooter>
      <DefaultLayout.PageHeader
        breadcrumb={[{name: intl.formatMessage({id: 'licensing.manage_services'}), path: '/licensing/manage-services'}]}
        title={intl.formatMessage({id: match.params.id ? 'licensing.edit_service' : 'licensing.add_new_service'})}
      />
      <Spin spinning={submitInProgress || serviceLoading} wrapperClassName={serviceLoading ? "spinner-with-white-background" : ""}>
        <DefaultLayout.PageContent withTopPadding >
          <Form onFinish={submitForm} form={form}>
            <Input
              name="name"
              label={intl.formatMessage({id: 'licensing.service_name'})}
              validation={{required: true}}
              autoFocus
            />
            {editing
              ? <Input
                  name="typeReadOnly"
                  label={intl.formatMessage({id: 'licensing.service_category'})}
                  validation={{required: true}}
                  readOnly
                />
              : <Select
                  name="type"
                  label={intl.formatMessage({id: 'licensing.service_category'})}
                  validation={{required: true}}
                  manualOptions={serviceTypesList}
                  loading={serviceTypesLoading}
                  onChange={(value: any) => setSystemServiceType(value)}
                />
            }
            {renderInputs()}
          </Form>
        </DefaultLayout.PageContent>
        <DefaultLayout.PageFooter className={'justify-content-end'}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <NavLink to="/licensing/manage-services">
              <Button style={{ marginRight: '8px' }}>
                <FormattedMessage id="general.cancel" />
              </Button>
            </NavLink>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitInProgress}
              onClick={() => {form.submit()}}
              style={{ marginRight: '8px' }}
            >
              <FormattedMessage id="general.submit" />
            </Button>
          </div>
        </DefaultLayout.PageFooter>
      </Spin>
    </DefaultLayout.PageLayout>
  );
}

export default connect(mapStateToProps)(withRouter(ServiceForm));
