import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Radio} from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import { Input, Select, Switch} from 'components/Form';

const mapStateToProps = (state: any) => {
  return {
    session: state.session,
  }
}

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 6 },
  };

const PhishingSimulationOptions: React.FC<any> = ({
  phishingSimulationTemplate,
  setSimulationTemplateName,
  setSelectedIndustryTypes,
  industryTypes
}:any) => {
    const [smartDephish, setSmartDephish] = useState(false);
    const [cunstomPhishing, setCunstomPhishing] = useState(false);

    const intl = useIntl();
    useEffect(()=>{
      if(phishingSimulationTemplate) {
        setSmartDephish(phishingSimulationTemplate.allow_smart_dephish === 1 ? true: false);
        setCunstomPhishing(phishingSimulationTemplate.custom_phishing_campaigns === 1 ? true: false);
      }

    },[phishingSimulationTemplate])

  return (
    <>
      <DefaultLayout.PageLayout>
            <Input name="simulationTemplateUniversalName" onInput={(el:any)=> setSimulationTemplateName(el.target.value)} label={intl.formatMessage({id: 'phishing.simulation_template_universal_name'})} validation={{required: true}} />
            <Form.Item {...formItemLayout} name="allowSmartDephish" label={intl.formatMessage({id: "phishing.allow_smart_dephish"})} >
              <Switch checked={smartDephish} onChange={() => setSmartDephish(!smartDephish)}  />
            </Form.Item>
            <Form.Item {...formItemLayout} name="customPhishingCampaigns" label={intl.formatMessage({id: "phishing.custom_phishing_campaigns"})} >
              <Switch checked={cunstomPhishing} onChange={() => setCunstomPhishing(!cunstomPhishing)}  />
            </Form.Item>
            <Form.Item
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 6 }}
              name="difficulty"
              label={intl.formatMessage({id: "phishing.difficulty"})}
            >
              <Radio.Group >
                <Radio value={'LOW'}><FormattedMessage id='phishing.low'/></Radio>
                <Radio value={'MEDIUM'}><FormattedMessage id='phishing.medium'/></Radio>
                <Radio value={'HIGH'}><FormattedMessage id='phishing.high'/></Radio>
              </Radio.Group>
            </Form.Item>
            <Select
              name='industryTypes'
              mode="multiple"
              allowClear
              showArrow
              showSearch
              label={intl.formatMessage({id: "phishing.industry_types"})}
              manualOptions={industryTypes}
              onChange={(value: any, options:any) => setSelectedIndustryTypes(value)}
              customObjLabel={(el: string) => intl.formatMessage({id: `organizations.${el}`})}
              customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
              filterOption={(input: string, option: any) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            />
      </DefaultLayout.PageLayout>

    </>
  )
}

export default connect(mapStateToProps)(withRouter(PhishingSimulationOptions));
