import React from 'react';
import { Form, Switch as AntDSwitch } from 'antd';
import {SwitchProps} from 'antd/lib/switch';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

interface ISwitch extends SwitchProps{
  name?:string | string[],
  label?:string | React.ReactElement,
  styles?: React.CSSProperties,
  customLayout?: {labelCol?:{span:number},wrapperCol?:{span:number}} , 
  hasDefaultLayout?: boolean,
  className?:string
  isFormItem?: boolean;
}

const Input: React.FC<ISwitch> = ({name, label, styles, customLayout, hasDefaultLayout, className, isFormItem, ...props}) => {
  
  const switchElement = <AntDSwitch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} {...props} /> 

  if(!isFormItem){
      return switchElement
  }
  
  let formItemLayout = customLayout || {}
  
  if(hasDefaultLayout){

    formItemLayout = {
      labelCol:{ span: 8 },
      wrapperCol: { span: 8 }
    }

  }

  return (
    <Form.Item
      {...formItemLayout}
      name={name}
      label={label}
      valuePropName='checked'
      style={styles}
      className={className}
    >
      {switchElement}
    </Form.Item>
    )
}

export default Input;
