import React, {useEffect, useState} from 'react';
import { useIntl } from 'react-intl';
import { Tabs } from 'antd';
import { ToolOutlined } from '@ant-design/icons';
import AppearanceSettingsTab from './AppearanceSettingsTab';
import SelectResellerOrCustomer from './SelectResellerOrCustomer';
import EmailSettingsTab from './EmailSettingsTab';
import SMSSettingsTab from './SMSSettingsTab';
import { EnvironmentType } from '../types';
import {connect} from "react-redux";

const { TabPane } = Tabs;

// Remove this when all tabs are finished
const TabUnderConstruction = () => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vh', fontSize: 100 }}>
    <ToolOutlined />
  </div>
);

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    };
};

interface CustomizationFormProps {
  type: EnvironmentType;
  session: any;
}

const CustomizationForm: React.FC<CustomizationFormProps> = ({ type, session }) => {
  const [appearanceSettingsTabKey, setAppearanceSettingsTabKey] = useState(0);
  const [selectedResellerId, setSelectedResellerId] = useState<number>(0);
  const [selectedCustomerId, setSelectedCustomerId] = useState<number>(0);
  const [displayFields, setDisplayFields] = useState(false);

  const intl = useIntl();

  useEffect(() => {
      if (session.active_user_type === 'CUSTOMER_ADMIN') {
          if (session.access.appearance.allow) {
              setSelectedCustomerId(session.organization.organization_id);
          }
          setDisplayFields(false);
      } else {
          setDisplayFields(true);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleTabClick = (key: string) => {
    switch (key) {
      case 'site_appearance':
        setAppearanceSettingsTabKey(appearanceSettingsTabKey + 1);
        break;
    }
  };

  return (
    <>
        {displayFields &&
          <>
              {(type === 'RESELLER' || type === 'CUSTOMER') && (
                  <SelectResellerOrCustomer
                      type={type}
                      setSelectedResellerId={setSelectedResellerId}
                      setSelectedCustomerId={setSelectedCustomerId}
                  />
              )}
          </>
        }
      <Tabs
        size="small"
        style={{ margin: '0 16px' }}
        onTabClick={handleTabClick}
      >
        <TabPane tab={intl.formatMessage({ id: 'system.environment.site_appearance' })} key="site_appearance">
          <AppearanceSettingsTab
            key={appearanceSettingsTabKey}
            type={type}
            selectedResellerId={selectedResellerId}
            selectedCustomerId={selectedCustomerId}
          />
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: 'system.environment.domain_settings' })} key="domain_settings">
          {/* Remove this when this tab is added */}
          <TabUnderConstruction />
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: 'system.environment.email_settings' })} key="email_settings">
          {/* Remove this when this tab is added */}
          <EmailSettingsTab
            key={appearanceSettingsTabKey}
            type={type}
            selectedResellerId={selectedResellerId}
            selectedCustomerId={selectedCustomerId}
          />
        </TabPane>
        <TabPane tab={intl.formatMessage({ id: 'system.environment.sms_settings' })} key="sms_settings">
          <SMSSettingsTab
            key={appearanceSettingsTabKey}
            type={type}
            selectedResellerId={selectedResellerId}
            selectedCustomerId={selectedCustomerId}
          />
        </TabPane>
      </Tabs>
    </>
  );
}

export default connect(mapStateToProps)(CustomizationForm);
