import React from 'react';
import { useIntl } from 'react-intl';
import { Form, DatePicker, Checkbox } from 'antd';

const AppearanceService = ({
  form, formItemLayout, disabledDates,
  setValidFromDate, setValidToDate,
  indefinite, setIndefinite
}: any) => {
  const intl = useIntl();

  const handleCheckboxChange = (event: any) => {
    const checked = event.target.checked;

    setIndefinite(checked);
    form.setFieldsValue({ indefinite: event.target.checked });

    if (checked) {
      setValidToDate(null);
      form.setFieldsValue({ validTo: null });
    }
  }

	return (
		<>
      <Form.Item
        name="validFrom"
        label={intl.formatMessage({id: 'licensing.valid_from'})}
        rules={[{ required: true, message: intl.formatMessage({id: 'validation.field_required'}) }]}
        {...formItemLayout}
      >
        <DatePicker
          format="DD/MM/YYYY"
          disabledDate={(current: any) => disabledDates(current)}
          placeholder=""
          onChange={(value: any) => setValidFromDate(value)}
        />
      </Form.Item>
      <Form.Item
        name="validTo"
        label={intl.formatMessage({id: 'licensing.valid_to'})}
        rules={[{ required: !indefinite, message: intl.formatMessage({id: 'validation.field_required'}) }]}
        {...formItemLayout}
      >
        <DatePicker
          format="DD/MM/YYYY"
          disabledDate={(current: any) => disabledDates(current, true)}
          placeholder=""
          onChange={(value: any) => setValidToDate(value)}
          disabled={indefinite}
        />
      </Form.Item>
      <Form.Item
        name="indefinite"
        label={intl.formatMessage({id: 'general.indefinite'})}
        {...formItemLayout}
      >
        <Checkbox
          onChange={(event: any) => handleCheckboxChange(event)}
          checked={indefinite}
        />
      </Form.Item>
    </>
	);
}

export default AppearanceService;