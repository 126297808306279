import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { changeUserTableFilter } from 'store/actions/saveUserTableFilter';
import { NavLink, withRouter } from 'react-router-dom';
import { Alert, Tag, Popover, message, Button } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { UserFilterInterface, OrganizationType, UsersType, RowSelectionUsersInterface } from 'components/UsersComponents/types';
import DefaultLayout from "components/DefaultLayout";
import { Tooltip } from "antd";
import apiClient from "utils/apiClient";
import Table from 'components/Table';
import Jwt from "utils/jwt";
import UsersListFooter from "components/UsersComponents/UsersListFooter";
import UsersListToolbar from "components/UsersComponents/UsersListToolbar";
import './styles.scss';
import Switch from 'components/Form/Switch';
import Spinner from "components/Spinner";

const mapStateToProps = (state: any) => {
    return {
        userTableFilter: state.userTableFilter,
        session: state.session
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    changeUserTableFilter: (userTableFilter: any) => dispatch(changeUserTableFilter(userTableFilter)),
});

const UserList: React.FC = ({ match, changeUserTableFilter, userTableFilter, session, history }: any) => {
    const [canAddLearningManagers, setCanAddLearningManagers] = useState<boolean>(false);
    const [activeOrganizationId, setActiveOrganizationId] = useState<number>(0);
    const [activeOrganizationType, setActiveOrganizationType] = useState<OrganizationType>();
    const [activeOrganizationUuid, setActiveOrganizationUuid] = useState<string>('');
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [selectedRowsLength, setSelectedRowsLength] = useState<number>(0);
    const [users, setUsers] = useState<UsersType[]>([]);
    const [rowSelectedUsers, setRowSelectedUsers] = useState([]);
    const [resourceUrl, setResourceUrl] = useState<string>('');
    const pageSizeOptions = [30, 50];
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [currentPageSize, setCurrentPageSize] = useState<number>(pageSizeOptions[0]);
    const [selectAllUsers, setSelectAllUsers] = useState(false);
    const [usersFilter, setUsersFilter] = useState<UserFilterInterface>({});
    const [isUsersLoading, setIsUsersLoading] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [reloadUsers, setReloadUsers] = useState(false);
    const [customer, setCustomer] = useState<any>();
    const [isCustomerLoading, setIsCustomerLoading] = useState(false);
    const [newUsers, setNewUsers] = useState(false);
    const [filterNewUsers, setFilterNewUsers] = useState(false);
    const [rowSelection, setRowSelection] = useState<RowSelectionUsersInterface>({
        filter: {},
        selectedAllUsers: false,
        selectedUsers: [],
        deSelectedUsers: [],
        searchText: ''
    });

    const intl = useIntl();
    const userRole = session.active_user_type;
    const customerId = match.params.customerId ? parseInt(match.params.customerId) : false;
    const backPath = match.params.backPath;

    useEffect(() => {
        if (!customerId) {
            if (userRole !== 'SUPER_ADMIN') {
                setActiveOrganizationId(parseInt(session.organization.organization_id));
                setActiveOrganizationType(session.organization.organization_type);
                setActiveOrganizationUuid(session.organization.organization_uuid);
            } else {
                setShowFilter(true);
            }

            if (session.special_rights && session.special_rights.includes('can_manage_learning_managers')) {
                setCanAddLearningManagers(true);
            }

            if (userRole === 'SUPER_ADMIN') {
                setCanAddLearningManagers(true);
            }
        }

        const loadCustomer = async () => {
            setIsCustomerLoading(true);
            let url = '/api/v1/customer/default-values';

            if (session.active_user_type === 'SUPER_ADMIN' || session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN') {
                url = url + '?customerId=' + customerId;
            }

            if (session.special_rights && session.special_rights.includes('can_manage_customers') && customerId) {
                url = url + '?customerId=' + customerId;
            }

            try {
                let response = await apiClient.request(url, [], 'GET');

                setCustomer(response.customer);
                setActiveOrganizationType('CUSTOMER');
                // @ts-ignore ignores typescript next line
                setActiveOrganizationId(customerId);
                setActiveOrganizationUuid(response.customer.uuid);

            } catch (err) {
                console.error(err);
                message.error(intl.formatMessage({ id: "error.data_load" }));
            }
            setIsCustomerLoading(false);
        };

        if (customerId) {
            loadCustomer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl, match.path]);

    useEffect(() => {
        if (activeOrganizationId) {
            checkForNewUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeOrganizationId]);

    useEffect(() => {
        if (userRole === 'CUSTOMER_ADMIN' || userRole === 'SUPERVISION') {
            setResourceUrl('/api/v1/auth/customer/users');
        } else {
            if (activeOrganizationType === 'CUSTOMER') {
                setResourceUrl('/api/v1/auth/customer/users');
            } else {
                setResourceUrl(`/api/v1/auth/organizations/${activeOrganizationId}/users`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeOrganizationId, activeOrganizationType]);

    const checkForNewUsers = async () => {
        try {
            let response = await apiClient.request(`/api/v1/users/${activeOrganizationId}/check-new-users`, {}, 'GET');

            setNewUsers(response.users);
        } catch (error) {
            console.error(error);
        }
    }

    const checkIfUserAvailable = async (record: any) => {
        let parsedValues = {
            userUuid: record.userUuid,
            customerId: activeOrganizationId
        };

        try {
            setVisibleTooltip(null);

            let response = await apiClient.request('/api/v1/manager/user', parsedValues, 'POST');

            if (response.allow) {
                history.push(`/customer/${activeOrganizationId}/user/${record.userUuid}/view/users`);
            } else {
                message.error(intl.formatMessage({ id: 'error.not_allowed' }));
            }
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: "error.data_load" }));
        }
    };

    const switchSuperVisionRole = async (record: any) => {
        let url;
        let activationMessage

        if (record.isActiveSuperVision) {
            url = `/api/v1/users/user/${record.userUuid}/deactivate/super-vision`
            if (userRole !== 'CUSTOMER_ADMIN') {
                url = url + '?customerId=' + activeOrganizationId;
            }
            activationMessage = 'role_super_vision_disabled';
        } else if (!record.isActiveSuperVision) {
            url = `/api/v1/users/user/${record.userUuid}/activate/super-vision`
            if (userRole !== 'CUSTOMER_ADMIN') {
                url = url + '?customerId=' + activeOrganizationId;
            }
            activationMessage = 'role_super_vision_enabled';
        }

        try {
            await apiClient.request(url, [], 'POST');
            message.success(intl.formatMessage({ id: 'users.' + activationMessage }));
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: 'error.cant_change_role' }));
        } finally {
            setIsUsersLoading(true);
            setUsers([]);
            setReloadUsers(true);
        }
    };

    const columns = [
        {
            title: intl.formatMessage({ id: 'general.name' }),
            dataIndex: 'name',
            key: 'name',
            render: (_text: string, record: any) => {
                if (userRole !== 'SUPERVISION') {
                    if (record.roles === 'RESELLER_ADMIN' || record.roles === 'DISTRIBUTOR_ADMIN') {
                        let to = `/organizations/${record.organizationId}/admins/${record.userUuid}/edit`;

                        if (backPath === 'org') {
                            to = to + '/org'
                        } else {
                            to = to + '/users'
                        }

                        return (
                            <NavLink to={to}>
                                {record.name}
                            </NavLink>
                        )
                    } else {
                        let to = `/customer/${activeOrganizationId}/user/${record.userUuid}/view`;

                        if (backPath === 'org') {
                            to = to + '/org'
                        } else {
                            to = to + '/users'
                        }

                        if (userRole === 'RESELLER_ADMIN' || userRole === 'DISTRIBUTOR_ADMIN' || userRole === 'SUPER_ADMIN') {
                            return (
                                <NavLink to={to}>
                                    {record.name}
                                </NavLink>
                            )
                        } else {
                            return (
                                <NavLink to={to}>
                                    {record.name}
                                </NavLink>
                            )
                        }
                    }
                } else {
                    return (
                        <div className='user-name-style' onClick={() => checkIfUserAvailable(record)}>
                            {record.name}
                        </div>
                    )
                }
            }
        },
        {
            title: intl.formatMessage({ id: 'general.surname' }),
            dataIndex: 'surname',
            key: 'surname',
            render: (text: string, record: any) => record.surname
        },
        {
            title: intl.formatMessage({ id: 'general.email' }),
            dataIndex: 'email',
            key: 'email',
            render: (text: string, record: any) => record.email ? record.email : record.phone_number
        },
        {
            title: intl.formatMessage({ id: 'users.organizational_unit' }),
            dataIndex: 'organizationalUnit',
            key: 'organizationalUnit',
            render: (text: string, record: any) => record.organizationalUnitName
        },
        {
            title: intl.formatMessage({ id: 'users.user_status' }),
            dataIndex: 'accountStatus',
            render: (accountStatus: string, _record: any, key: number) => (
                <>
                    <Tag color={accountStatus === 'ACTIVE' ? 'green' : 'red'} key={key}>
                        {intl.formatMessage({ id: `user.status.${accountStatus}` })}
                    </Tag>
                    {_record.deletionInitiatedAt != null ?
                        <Tag color={'red'}>
                            {intl.formatMessage({ id: `user.status.DELETION` })}
                        </Tag> : ''}
                </>
            )
        },
        {
            title:
                <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                    {intl.formatMessage({ id: 'users.registration_status' })}
                    <Tooltip title={intl.formatMessage({ id: 'users.registration_status_explanation' })}>
                        <i className='fal fa-question-circle header-item' />
                    </Tooltip>
                </div>,
            dataIndex: 'active',
            align: 'center' as 'center',
            render: (active: boolean) => (
                active
                    ? <CheckOutlined style={{ color: 'green' }} />
                    : <CloseOutlined style={{ color: 'red' }} />
            )
        },
        {
            title: intl.formatMessage({ id: 'users.user_type' }),
            dataIndex: 'roles',
            render: (roles: string) => {
                const getRoleColor = (role: string): string => {
                    switch (role) {
                        case 'STUDENT':
                            return 'purple';
                        case 'CUSTOMER_ADMIN':
                            return 'blue';
                        case 'ADMIN':
                        case 'RESELLER_ADMIN':
                        case 'DISTRIBUTOR_ADMIN':
                            return 'gold';
                        default:
                            return '';
                    }
                };

                const userRoles = roles.split(',');

                return (
                    userRoles.map((role: string) => {
                        if (role !== 'SUPERVISION') {
                            return (
                                <Tag color={getRoleColor(role)} key={role} style={{ marginTop: 10 }}>
                                    {intl.formatMessage({ id: `user.type.${role}` })}
                                </Tag>
                            )
                        }

                        return null;
                    })
                )
            }
        },
        {
            key: 'superVisionSwitch',
            title:
                <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                    {intl.formatMessage({ id: 'users.supervision' })}
                    <Tooltip title={intl.formatMessage({ id: 'users.supervision_hint' })}>
                        <i className='fal fa-question-circle header-item' />
                    </Tooltip>
                </div>,
            align: 'center' as 'center',
            render: (record: any) => {
                if (record.isDirectManager) {
                    return (
                        <Switch
                            name='supervision'
                            defaultChecked={record.isActiveSuperVision === 1}
                            onClick={() => switchSuperVisionRole(record)}
                        />
                    )
                }
            }
        },
        {
            key: 'actions',
            render: (_text: string, record: any, index: number) => {
                let switchParameters = getUserSwitchParameters(record);

                const email = record.email;
                const phoneNumber = record.phone_number
                const userId = switchParameters.userId;
                let userTypeId: number;

                if (activeOrganizationType === 'ORGANIZATION') {
                    userTypeId = record.userTypeId;
                } else {
                    userTypeId = record.studentUserTypeId ? record.studentUserTypeId : record.customerAdminUserTypeId;
                }

                let editRoute = `/customer/${activeOrganizationId}/user/${record.userUuid}/view`;

                if (backPath === 'org') {
                    editRoute = editRoute + '/org'
                } else {
                    editRoute = editRoute + '/users'
                }

                let orgType = '';
                let orgId = '';

                if (record.roles === 'RESELLER_ADMIN' || record.roles === 'DISTRIBUTOR_ADMIN') {
                    editRoute = `/organizations/${record.organizationId}/admins/${record.userUuid}/edit`;

                    if (record.roles === 'RESELLER_ADMIN') {
                        orgType = 'RESELLER'
                    } else if (record.roles === 'DISTRIBUTOR_ADMIN') {
                        orgType = 'DISTRIBUTOR'
                    }
                    orgId = record.organizationId
                } else {
                    orgType = 'CUSTOMER';
                    orgId = record.customerId
                }

                const content = (
                    <>
                        <NavLink to={editRoute}>
                            <div className="popover-item">
                                <FormattedMessage id="general.edit" />
                            </div>
                        </NavLink>
                        <div className="popover-item" onClick={() => {
                            sendEmail(email ? email : phoneNumber, userTypeId, orgType, orgId, !!email)
                        }}>
                            <FormattedMessage id="login.reset_password" />
                        </div>
                        {isUserSwitchAllowed()
                            ? <div className="popover-item" onClick={() => {
                                switchToUser(userId, userTypeId)
                            }}>
                                <FormattedMessage id="users.switch_user" />
                            </div>
                            : null
                        }
                    </>
                );

                if (userRole !== 'SUPERVISION') {
                    return (
                        <Popover
                            visible={index === visibleTooltip}
                            content={content}
                            trigger="click"
                            placement="bottomRight"
                            arrowPointAtCenter
                            onVisibleChange={(visible) => handleVisibleChange(visible, index)}
                        >
                            <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
                                <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} />
                            </div>
                        </Popover>
                    )
                }
            },
        }
    ];

    const switchToUser = async (userId: number, userTypeId: number) => {
        let errorMessageCode = 'switch_to_user_fail';

        try {
            setVisibleTooltip(null);

            let response = await Jwt.switchUser(userId, userTypeId);

            if (response === true) {
                window.location.assign('/')
            } else {
                message.error(intl.formatMessage({ id: errorMessageCode }));
            }
        } catch (err: any) {
            if (err.message === 'user_switch_not_allowed') {
                errorMessageCode = err.message
            } else if (err.message === 'user_is_not_activated') {
                errorMessageCode = err.message
            }

            message.error(intl.formatMessage({ id: "error." + errorMessageCode }));
        }
    };

    const handleVisibleChange = (visible: boolean, rowIndex: number) => {
        if (visible) {
            setVisibleTooltip(rowIndex);
        } else {
            setVisibleTooltip(null);
        }
    };

    const isUserSwitchAllowed = () => {
        return (session.active_user_type === 'SUPER_ADMIN' || (session.permissions && session.permissions.includes('users.switch_users')))
    };

    const getUserSwitchParameters = (user: any) => {
        let params = {
            userId: user.userId,
            userTypeId: null
        };

        if (userRole === 'CUSTOMER_ADMIN') {
            params.userTypeId = user.studentUserTypeId
        } else if (userRole === 'SUPER_ADMIN') {
            if (user.roles === 'RESELLER_ADMIN' || user.roles === 'DISTRIBUTOR_ADMIN') {
                params.userTypeId = user.userTypeId
            } else if (user.roles === 'CUSTOMER_ADMIN' || user.roles === 'STUDENT' || user.roles.split(',').includes('CUSTOMER_ADMIN')) {
                params.userTypeId = (user.customerAdminUserTypeId != null) ? user.customerAdminUserTypeId : user.studentUserTypeId
            }
        } else if (user.roles === 'RESELLER_ADMIN' || user.roles === 'DISTRIBUTOR_ADMIN') {
            params.userTypeId = user.userTypeId
        }

        return params
    };

    const getRecords = async (resourceUrl: string, pageQuery: string) => {
        let data = {};
        let query = (pageQuery !== '') ? '?' + pageQuery : '';

        let url = resourceUrl + query;

        if (usersFilter) {
            data = usersFilter
        }

        if (userRole === 'SUPER_ADMIN' || userRole === 'RESELLER_ADMIN' || userRole === 'DISTRIBUTOR_ADMIN') {
            url = url + '&customerId=' + activeOrganizationId
        }

        if (session.special_rights && session.special_rights.includes('can_manage_customers') && customerId) {
            url = url + '&customerId=' + customerId;
        }

        let response = await apiClient.request(url, data, 'POST');

        return {
            data: (response.users) ? response.users : {},
            pagination: (response.pagination) ? response.pagination : {}
        }
    };

    const sendEmail = async (userContact: string, userTypeId: number, orgType: string, /* data type??? --->*/orgId: string, isEmail: boolean) => {
        try {
            let parsedValues = {
                userContact: userContact,
                isEmail: isEmail,
                ownerType: orgType,
                ownerReferenceId: orgId,
                userTypeId: userTypeId
            };
            setVisibleTooltip(null); // hide tooltips
            await apiClient.request('/api/v1/password-reset', parsedValues, 'POST');
            message.success(intl.formatMessage({ id: `users.email_sent` }));

        } catch (error) {
            console.error(error);
        }
    };

    const getBreadcrumbs = () => {

        if (session.special_rights && session.special_rights.includes('can_manage_customers')) {
            return [
                {
                    name: intl.formatMessage({ id: 'general.my_organizations' }),
                    path: backPath === 'org' ? '/user/organizations' : '/users',
                }
            ]
        }
        //

        if (customerId && customer) {
            return [
                {
                    name: intl.formatMessage({ id: 'general.customers' }),
                    path: '/customers',
                }
            ]
        }
    };

    return (
        <Spinner spinning={isCustomerLoading}>
            <DefaultLayout.PageLayout>
                {customerId ?
                    <>
                        {customer &&
                            <DefaultLayout.PageHeader
                                breadcrumb={getBreadcrumbs()}
                                title={customerId ? customer.name : intl.formatMessage({ id: 'general.users' })}
                            />
                        }
                    </>
                    :
                    <DefaultLayout.PageHeader
                        title={intl.formatMessage({ id: 'general.users' })}
                    />
                }
                <div style={{ backgroundColor: 'white', padding: 16, borderRadius: 8, marginBottom: 80 }}>
                    <UsersListToolbar
                        canAddLearningManagers={canAddLearningManagers}
                        activeOrganizationId={activeOrganizationId}
                        activeOrganizationType={activeOrganizationType}
                        activeOrganizationUuid={activeOrganizationUuid}
                        showFilter={showFilter}
                        userRole={userRole}
                        setActiveOrganizationId={setActiveOrganizationId}
                        setActiveOrganizationType={setActiveOrganizationType}
                        setActiveOrganizationUuid={setActiveOrganizationUuid}
                        setUsers={setUsers}
                        currentPageSize={currentPageSize}
                        setCurrentPage={setCurrentPage}
                        setTotalRecords={setTotalRecords}
                        usersFilter={usersFilter}
                        setUsersFilter={setUsersFilter}
                        setSelectedRowsLength={setSelectedRowsLength}
                        selectedRowsLength={selectedRowsLength}
                        users={users}
                        totalRecords={totalRecords}
                        setRowSelectedUsers={setRowSelectedUsers}
                        selectAllUsers={selectAllUsers}
                        setSelectAllUsers={setSelectAllUsers}
                        setRowSelection={setRowSelection}
                        rowSelection={rowSelection}
                        reloadUsers={reloadUsers}
                        setReloadUsers={setReloadUsers}
                        setIsUsersLoading={setIsUsersLoading}
                        customerId={customerId}
                        filterNewUsers={filterNewUsers}
                        setFilterNewUsers={setFilterNewUsers}
                    />
                    <Spinner spinning={isUsersLoading}>
                        {newUsers ?
                            <Alert style={{ marginTop: 5 }} closable message={
                                <div className='flex-between'>
                                    <FormattedMessage id='general.new_users_added' values={{ users: newUsers }} />
                                    <Button onClick={() => setFilterNewUsers(true)} >
                                        <FormattedMessage id='general.filter_new_users' />
                                    </Button>

                                </div>
                            } type='info' ></Alert>
                            :
                            null
                        }
                        {users.length ?
                            <Table
                                resourceUrl={resourceUrl}
                                setTotalRecords={setTotalRecords}
                                totalRecords={totalRecords}
                                loadedData={users}
                                setLoadedData={setUsers}
                                setCurrentPageSize={setCurrentPageSize}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                setLoading={setIsUsersLoading}
                                loading={isUsersLoading}
                                columns={userRole === 'SUPERVISION' ?
                                    columns.filter(ea => ea.key !== 'superVisionSwitch')
                                    :
                                    columns
                                }
                                pageSizeOptions={pageSizeOptions}
                                getRecords={getRecords}
                                hasSelection={true}
                                selectAll={selectAllUsers}
                                setSelectAll={setSelectAllUsers}
                                setSelectedData={setRowSelectedUsers}
                                selectedRowsLength={selectedRowsLength}
                                setSelectedRowsLength={setSelectedRowsLength}
                                selectedData={rowSelectedUsers}
                                customRowSelection={rowSelection}
                                setCustomRowSelection={setRowSelection}
                                size='small'
                            />
                            : <Alert
                                message={intl.formatMessage({ id: 'user.please_define_users_filter' })}
                                type="info"
                                style={{ marginTop: 20 }}
                                showIcon
                            />
                        }
                    </Spinner>
                </div>
                {userRole !== 'SUPERVISION' &&
                    <DefaultLayout.PageFooterWithRow
                        left={
                            <UsersListFooter
                                selectedRowsLength={selectedRowsLength}
                                rowSelectedUsers={rowSelectedUsers}
                                activeOrganizationType={activeOrganizationType}
                                activeOrganizationId={activeOrganizationId}
                                activeOrganizationUuid={activeOrganizationUuid}
                                rowSelection={rowSelection}
                                setReloadUsers={setReloadUsers}
                            />
                        }
                    />
                }
            </DefaultLayout.PageLayout>
        </Spinner>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserList));