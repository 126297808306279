import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { message } from 'antd';
import DoughnutChart from 'components/Charts/DoughnutChart';
import coursesApiClient from 'utils/coursesApiClient';

interface TestScoreOverviewChartProps {
  campaignId: number,
}

const TestScoreOverviewChart: React.FC<TestScoreOverviewChartProps> = ({ campaignId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    data: {},
    corner: {},
  });

  const intl = useIntl();

  useEffect(() => {
    const loadData = async () => {
      try {
        setIsLoading(true);

        const response = await coursesApiClient.request(`/api/v1/courses/statistic/campaign/${campaignId}/tests-score-general`);

        const data = response;

        const parsedData = {
          data: {
            labels: [
              intl.formatMessage({ id: 'campaign.average_score' }),
              intl.formatMessage({ id: 'campaign.median_score' }),
            ],
            datasets: [
              {
                label: 'average',
                data: [
                  data.averageScore,
                  100 - data.averageScore,
                ],
                backgroundColor: [
                  'rgb(102, 148, 224)',
                  'rgb(232, 232, 232)',
                ],
                hoverBackgroundColor: [
                  'rgb(82, 128, 204)',
                  'rgb(232, 232, 232)',
                ],
              },
              {
                weight: 1,
                label: '',
              },
              {
                weight: 1,
                label: 'median',
                data: [
                  data.medianScore,
                  100 - data.medianScore,
                ],
                backgroundColor: [
                  'rgb(34, 208, 160)',
                  'rgb(232, 232, 232)',
                ],
                hoverBackgroundColor: [
                  'rgb(14, 188, 140)',
                  'rgb(232, 232, 232)',
                ],
              },
            ],
          },
          corner: {
            text: intl.formatMessage({ id: 'campaign.users_tested' }),
            number: data.usersTested,
          },
        };

        setData(parsedData);
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
        setIsLoading(false);
      }
    }

    loadData();
  }, [intl, campaignId]);

  return (
    <DoughnutChart
      data={data.data}
      title={intl.formatMessage({ id: 'campaign.test_score_overview' })}
      corner={data.corner}
      cutoutPercentage={55}
      loading={isLoading}
    />
  );
}

export default TestScoreOverviewChart;