import React, { useState, useEffect, SetStateAction } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormInstance } from 'antd';
import { LdapConnectionForm } from './ConnectionTypes';
import { Select } from 'components/Form';
import config from 'config/config';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import Spinner from 'components/Spinner';
import './styles.scss';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 8 },
};

const connectionTypes = {
    'LDAP': 'LDAP',
};

interface ConnectionFormInterface {
    connection: any;
    isLoading: boolean;
    form: FormInstance;

    connectionType: string;
    setConnectionType: React.Dispatch<SetStateAction<string>>
}

const ConnectionForm: React.FC<ConnectionFormInterface> = ({ connection, isLoading, form, connectionType, setConnectionType }) => {

    const [copy, setCopy] = useState(false),
        intl = useIntl();

    useEffect(() => {
        if (connection) {
            setConnectionType(connection.connection_settings.type);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection])

    const connectionForms: any = {
        LDAP: <LdapConnectionForm form={form} connection={connection} />
    }

    const copyButton = () => {
        setCopy(true);
        let text = config.api.frontendUrl + '/login/' + connection.uuid;
        navigator.clipboard.writeText(text);
        buttonLoadingTimer();
    }

    const buttonLoadingTimer = () => {
        setTimeout(function () { setCopy(false) }, 2000);
    }

    return (
        <Spinner spinning={isLoading}>
            {connection?.settings &&
                <>
                    <div>
                        <h1 className='text-18'><FormattedMessage id='system.connection' /></h1>
                    </div>
                    <div className='connection-settings-container'>
                        {config.api.frontendUrl + '/login/' + connection.uuid}
                        {copy ?
                            <CheckOutlined className='copy-button' style={{ color: 'green' }} onClick={() => copyButton()} />
                            :
                            <CopyOutlined className='copy-button' onClick={() => copyButton()} />
                        }
                    </div>
                </>
            }
            <div>
                <h1 className='text-18'><FormattedMessage id='system.connection_setup' /></h1>
            </div>
            <hr className='form-group-separator' />
            <Select
                customLayout={formItemLayout}
                name={['connection', 'type']}
                onChange={(el: any) => setConnectionType(el)}
                label={intl.formatMessage({ id: 'system.connection_type' })}
                manualOptions={connectionTypes}
            />
            {connectionType &&
                connectionForms[connectionType]
            }
        </Spinner>
    )
};

export default ConnectionForm;
