import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, DatePicker, Input, Checkbox, InputNumber, Radio, Button, FormInstance } from 'antd';
import { Switch, TimeZoneInput } from 'components/Form';
import CRONBuilder from 'components/CRONBuilder';
import LanguageSelect from 'components/LanguageSelect';
import moment from 'moment';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 8 },
};

interface UserSyncFormInterface {
    connection: any;
    organization: any;
    form: FormInstance;
    connectionType: string;
    setCRONExpression: any;
}

const UserSyncForm: React.FC<UserSyncFormInterface> = ({
    connection,
    organization,
    form,
    connectionType,
    setCRONExpression
}) => {
    const [connectionAuth, setConnectionAuth] = useState(false),
        [enableAutomaticSync, setEnableAutomaticSync] = useState(false),
        [initialCRONExpression, setInitialCRONExpression] = useState<string | null>(null),
        [organizationalUnitMapping, setOrganizationalUnitMapping] = useState(false),

        intl = useIntl();

    useEffect(() => {
        if (connection) {
            setFormValues();
        } else {
            setDefaultFormValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connection])

    const setDefaultFormValues = () => {
        switch (connectionType) {
            case 'LDAP':
                form.setFieldsValue({
                    mapping: {
                        emailKey: 'mail',
                        nameKey: 'givenName',
                        surnameKey: 'sn',
                        organizationalUnitKey: '',
                        positionKey: '',
                        accountStatus: 'BLOCKED'
                    }
                });

                break;
            default:
                break;
        }
    }

    const setFormValues = () => {
        const settings = connection.settings;

        const mapping = connection.settings.mapping;

        setOrganizationalUnitMapping(mapping.orgUnitMapping);
        setConnectionAuth(connection.auth);
        setInitialCRONExpression(connection.cron_expression);
        setEnableAutomaticSync(settings.userSync.automaticSync);

        form.setFieldsValue({
            userSync: {
                automaticSync: settings.userSync.automaticSync,
                userActions: settings.userSync.userActions,
                period: [moment(connection.validFrom), moment(connection.validTo)],
                groups: settings.userSync.groups,
            },

            mapping: {
                nameKey: mapping.nameKey,
                arrayKey: mapping.arrayKey,
                emailKey: mapping.emailKey,
                timeZone: mapping.timeZone,
                surnameKey: mapping.surnameKey,
                positionKey: mapping.positionKey,
                attributeKey: mapping.attributeKey,
                accountStatus: mapping.accountStatus,
                orgUnitMapping: mapping.orgUnitMapping,
                phishingAllowed: mapping.phishingAllowed,
                primaryLanguage: mapping.primaryLanguage,
            }
        });
    }

    return (
        <>
            <div>
                <h1 className='text-18'><FormattedMessage id='general.user_sync' /></h1>
            </div>
            <hr className='form-group-separator' />

            <Switch customLayout={formItemLayout} isFormItem onChange={(el: any) => setEnableAutomaticSync(el)} name={['userSync', 'automaticSync']} label={intl.formatMessage({ id: 'system.enable_user_sync' })} />

            {enableAutomaticSync ?
                <>
                    <Form.Item name={['userSync', 'userActions']} {...formItemLayout} label={intl.formatMessage({ id: 'general.user_data' })}>
                        <Checkbox.Group>
                            <Checkbox value="CREATE" ><FormattedMessage id='general.create' /></Checkbox>
                            <Checkbox value="UPDATE" ><FormattedMessage id='general.update' /></Checkbox>
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item
                        label={intl.formatMessage({ id: 'emailing.scenarios.recurring' })}
                        {...formItemLayout}
                    >
                        <CRONBuilder
                            onChange={(value: string | null) => setCRONExpression(value)}
                            initialValue={initialCRONExpression}
                        />
                    </Form.Item>
                    <Form.Item
                        name={['userSync', 'period']}
                        label={intl.formatMessage({ id: 'emailing.scenarios.period' })}
                        rules={[{ required: true, message: intl.formatMessage({ id: "validation.field_required" }) }]}
                        {...formItemLayout}
                    >
                        <DatePicker.RangePicker
                            format="YYYY-MM-DD"
                            disabledDate={(current: any) => current && current < moment().subtract(1, 'day').endOf('day')}
                        />
                    </Form.Item>
                    {!connectionAuth &&
                        <>
                            <Form.Item
                                {...formItemLayout}
                                name={['auth', 'username']}
                                label={intl.formatMessage({ id: 'system.username' })}
                                rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                            >
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item
                                {...formItemLayout}
                                name={['auth', 'password']}
                                label={intl.formatMessage({ id: 'login.password' })}
                                rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                            >
                                <Input autoComplete="off" type='password' />
                            </Form.Item>
                        </>
                    }
                </>
                :
                null
            }
            <div>
                <h1 className='text-18'><FormattedMessage id='system.role_mapping' /></h1>
            </div>
            <hr className='form-group-separator' />
            <div className='groups-wrapper'>
                <Form.List name={['userSync', 'groups']}>
                    {(fields, { add, remove }, { errors }) => (
                        <>
                            {fields.map((field, index) => (
                                <Form.Item
                                    {...(formItemLayout)}
                                    label={index === 0 ? <><FormattedMessage id='system.groups' />: </> : ' '}
                                    key={field.key}
                                >
                                    <Form.Item {...field} noStyle>
                                        <Input placeholder={intl.formatMessage({ id: 'system.group' })} />
                                    </Form.Item>
                                    {fields.length > 1 ? (
                                        <MinusCircleOutlined
                                            className="dynamic-delete-button"
                                            onClick={() => remove(field.name)}
                                        />
                                    ) : null}
                                </Form.Item>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                >
                                    <FormattedMessage id='system.add_group' />
                                </Button>
                                <Form.ErrorList errors={errors} />
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </div>

            <Form.Item
                {...formItemLayout}
                name={['userSync', 'studentRole']}
                label={intl.formatMessage({ id: 'system.student_role_string' })}
            >
                <Input />
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                name={['userSync', 'customerAdminRole']}
                label={intl.formatMessage({ id: 'system.customer_admin_role_string' })}
            >
                <Input />
            </Form.Item>
            <div>
                <h1 className='text-18'><FormattedMessage id='system.data_mapping' /></h1>
            </div>
            <hr className='form-group-separator' />
            <Form.Item
                {...formItemLayout}
                name={['mapping', 'emailKey']}
                label={intl.formatMessage({ id: 'general.email' })}
                rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                name={['mapping', 'nameKey']}
                label={intl.formatMessage({ id: 'general.name' })}
                rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                name={['mapping', 'surnameKey']}
                label={intl.formatMessage({ id: 'general.surname' })}
                rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
            >
                <Input />
            </Form.Item>
            <Switch customLayout={formItemLayout} isFormItem onChange={(el: any) => setOrganizationalUnitMapping(el)} name={['mapping', 'orgUnitMapping']} label={intl.formatMessage({ id: 'system.parse_org_unit' })} />
            {organizationalUnitMapping ?
                <>
                    <Form.Item
                        {...formItemLayout}
                        name={['mapping', 'attributeKey']}
                        label={intl.formatMessage({ id: 'system.attribute_key' })}
                        rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                    >
                        <Input style={{ textTransform: 'uppercase' }} />
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        name={['mapping', 'arrayKey']}
                        label={intl.formatMessage({ id: 'system.array_key' })}
                        rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                    >
                        <InputNumber min={0} max={20} />
                    </Form.Item>
                </>
                :
                <Form.Item
                    {...formItemLayout}
                    name={['mapping', 'organizationalUnitKey']}
                    label={intl.formatMessage({ id: 'general.unit' })}
                    rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
                >
                    <Input />
                </Form.Item>
            }
            <Form.Item
                {...formItemLayout}
                name={['mapping', 'exclude']}
                label={intl.formatMessage({ id: 'system.exclude_org_units' })}
            >
                <Input />
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                name={['mapping', 'positionKey']}
                label={intl.formatMessage({ id: 'general.position' })}
            >
                <Input />
            </Form.Item>
            <div>
                <h1 className='text-18'><FormattedMessage id='system.default_values' /></h1>
            </div>
            <hr className='form-group-separator' />
            <LanguageSelect
                customLayout={formItemLayout}
                name={['mapping', 'primaryLanguage']}
                label={intl.formatMessage({ id: 'general.language' })}
            />
            <Form.Item
                {...formItemLayout}
                name={['mapping', 'timeZone']}
                label={intl.formatMessage({ id: 'users.form.time_zone' })}
                rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
            >
                <TimeZoneInput />
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                name={['mapping', 'accountStatus']}
                label={intl.formatMessage({ id: 'users.form.account_status' })}
            >
                <Radio.Group>
                    <Radio className='radio-button' value='ACTIVE'>
                        {intl.formatMessage({ id: 'users.form.ACTIVE' })}
                    </Radio>
                    <Radio className='radio-button' value='BLOCKED'>
                        {intl.formatMessage({ id: 'users.form.BLOCKED' })}
                    </Radio>
                </Radio.Group>
            </Form.Item>
            <Switch customLayout={formItemLayout} isFormItem name={['mapping', 'phishingAllowed']} label={intl.formatMessage({ id: 'users.form.phishing_allowed' })} />
        </>
    )
};

export default UserSyncForm;
