import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Modal, Form, Radio} from 'antd';
import apiClient from 'utils/apiClient';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import config from 'config/config';
import {Select} from 'components/Form';

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
};

const ContentFilterModal = ({history, visible, onCancel, contentCategories, filterContent}: any) => {
    const [selectLanguageList, setSelectLanguageList] = useState();
    const [organizations, setOrganizations] = useState();
    const [organizationsLoading, setOrganizationsLoading] = useState(false);
    const [customers, setCustomers] = useState();
    const [customersLoading, setCustomersLoading] = useState(false);
    const [filterGroup, setFilterGroup] = useState('customer');

    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            filterBy: 'customer'
        });

        const loadLanguages = async () => {
            let languages = await apiClient.request(`/api/v1/language`, {}, 'GET');
            let languageListForSelect: any = {};

            Object.values(languages.languages).map((value: any, index: any) => {
                return languageListForSelect[value['code']] = value['name'];
            });

            setSelectLanguageList(languageListForSelect);
        };

        const loadOrganizations = async () => {
            setOrganizationsLoading(true);
            let organizationsResponse = await apiClient.request(config.api.routes.backend.organizationsOptions, {}, 'GET');
            let organizationsArray: any = {};

            Object.values(organizationsResponse.organizations).map((el: any) => {
                return organizationsArray[el.uuid] = el.name;
            });

            setOrganizations(organizationsArray);
            setOrganizationsLoading(false);
        };

        const loadCustomers = async () => {
            setCustomersLoading(true);
            let customersResponse = await apiClient.request(config.api.routes.backend.organizationsCustomers, {}, 'GET');
            let customersArray: any = {};

            Object.values(customersResponse.customers).map((el: any) => {
                return customersArray[el.uuid] = el.name;
            });

            setCustomers(customersArray);
            setCustomersLoading(false);
        };

        loadOrganizations();
        loadCustomers();
        loadLanguages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    return (
        <>
            <Modal
                title={intl.formatMessage({id: 'licensing.content.filter'})}
                visible={visible}
                onCancel={onCancel}
                onOk={() => form.submit()}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
                okText={intl.formatMessage({id: 'general.filter'})}
            >
                <Form form={form} onFinish={filterContent} {...formItemLayout}>
                    <Form.Item
                        name="filterBy"
                        label={intl.formatMessage({id: 'general.filter_by'})}
                    >
                        <Radio.Group
                            onChange={(el: any) => {
                                setFilterGroup(el.target.value);
                                form.setFieldsValue({
                                    organizations: [],
                                    customers: [],
                                    category: [],
                                    language: []
                                });
                            }}
                        >
                            <Radio value="customer"><FormattedMessage id='general.customer'/></Radio>
                            <Radio value="organization"><FormattedMessage id='general.reseller'/></Radio>
                        </Radio.Group>
                    </Form.Item>
                    {filterGroup === 'organization' &&
                        <Select
                            name='organization'
                            valueKey='id'
                            label={intl.formatMessage({id: 'licensing.content.supported_organizations'})}
                            manualOptions={organizations}
                            loading={organizationsLoading}
                            customLayout={formItemLayout}
                            showSearch
                            filterOption={(input: string, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                        />
                    }
                    {filterGroup === 'customer' &&
                        <Select
                            name='customers'
                            valueKey='id'
                            label={intl.formatMessage({id: 'licensing.content.supported_customers'})}
                            manualOptions={customers}
                            loading={customersLoading}
                            customLayout={formItemLayout}
                            showSearch
                            filterOption={(input: string, option: any) => {
                              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                          }}
                        />
                    }
                    <Select
                        name="category"
                        label={intl.formatMessage({id: 'licensing.service_category'})}
                        manualOptions={contentCategories}
                        customLayout={formItemLayout}
                        classifierIsObject
                        labelKey='name'
                        valueKey='uuid'
                    />
                    <Select
                        name="language"
                        showArrow
                        label={intl.formatMessage({id: 'emailing.supported_languages'})}
                        manualOptions={selectLanguageList}
                        customLayout={formItemLayout}
                        classifierIsObject
                        labelKey='name'
                        valueKey='code'
                        mode='tags'
                    />
                </Form>
            </Modal>
        </>
    )
};

export default connect(mapStateToProps)(withRouter(ContentFilterModal));
