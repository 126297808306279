import React, { useState } from 'react';
import { Dropdown, Modal, Menu, message, Card, Input } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import coursesApiClient from 'utils/coursesApiClient';
import { FormattedMessage, useIntl } from 'react-intl';
import { MaterialType } from './types';
import FileDownload from 'js-file-download';
import ScormPreviewModal from './ScormPreviewModal';
import debounce from 'lodash/debounce';
import generalHelper from 'utils/generalHelpers';
import './styles.scss';

interface MaterialCardInterface {
    material: any;
    activityId: number;
    reload: Function;
    loading: React.Dispatch<React.SetStateAction<boolean>>;
    setShowMaterialModal: React.Dispatch<React.SetStateAction<boolean>>;
    setType: React.Dispatch<React.SetStateAction<MaterialType>>;
    setMaterial: React.Dispatch<React.SetStateAction<any>>;
}

const MaterialCard: React.FC<MaterialCardInterface> = ({ material, activityId, reload, loading, setShowMaterialModal, setType, setMaterial }) => {
    const [showScormPreViewModal, setShowScormPreViewModal] = useState(false);

    const { confirm } = Modal;

    const intl = useIntl();

    const warnDeletion = async () => {
        confirm({
            title: intl.formatMessage({ id: 'general.attention' }),
            content: intl.formatMessage({ id: 'courses.deletion_resource' }),
            icon: <ExclamationCircleOutlined />,
            okText: intl.formatMessage({ id: 'general.yes' }),
            cancelText: intl.formatMessage({ id: 'general.no' }),

            onOk() {
                deleteResource();
            }
        });
    }

    const deleteResource = async () => {
        loading(true);
        await coursesApiClient.request(`/api/v1/courses/material/${material.id}/delete`, {}, 'DELETE');
        reload(activityId);
    }

    const content = (
        <Menu>
            {['SCORM_TEST', 'SCORM_LEARNING', 'PDF_MATERIAL', 'ASSIGNMENT', 'VIDEO_MATERIAL'].includes(material.type) ?
                <>
                    <Menu.Item key="edit" onClick={() => { setShowMaterialModal(true); setType(material.type); setMaterial(material); }}>
                        <FormattedMessage id="general.edit" />
                    </Menu.Item>
                    <Menu.Divider />
                </>
                : null
            }
            {['SCORM_TEST', 'SCORM_LEARNING', 'PDF_MATERIAL', 'VIDEO_MATERIAL'].includes(material.type) ?
                <>
                    <Menu.Item key="preview" onClick={() => setShowScormPreViewModal(true)}>
                        <FormattedMessage id="general.preview" />
                    </Menu.Item>
                    <Menu.Divider />
                </>
                : null
            }
            {['FILE'].includes(material.type) ?
                <>
                    <Menu.Item key="download" onClick={() => downloadFile()}>
                        <FormattedMessage id="general.download" />
                    </Menu.Item>
                    <Menu.Divider />
                </>
                : null
            }
            < Menu.Item key="delete" onClick={() => warnDeletion()}>
                <FormattedMessage id="general.delete" />
            </Menu.Item>
        </Menu >
    );

    const downloadFile = async () => {
        try {
            const fileSettings = JSON.parse(material.fileSettings);
            let response = await coursesApiClient.request(`api/v1/courses/material/storage/${fileSettings.path}`, {}, 'GET', true, true);
            FileDownload(response, fileSettings.title);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }
    }

    const updateSettings = async (settings: any, id: number) => {
        try {
            await coursesApiClient.request(`/api/v1/courses/material/update/${id}`, settings, 'PUT');
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }
    }

    const storeOptions = debounce(function (field: string, value: string, id: number) {
        updateSettings({ settings: { [field]: value }, type: 'FILE' }, id);
    }, 800);

    const panelContent = (type: string) => {
        let parsedFileSettings
        let settings

        switch (type) {
            case 'SCORM_LEARNING':
            case 'VIDEO_MATERIAL':
            case 'PDF_MATERIAL':
                parsedFileSettings = material.fileSettings ? JSON.parse(material.fileSettings) : {};
                settings = material.settings ? JSON.parse(material.settings) : {};

                return (
                    <>
                        <div className='card-title-wrapper'>
                            <div className='card-title'>
                                <i className="fa-solid fa-book"></i> <FormattedMessage id='courses.learning_file' />
                            </div>
                            <Dropdown overlay={content} trigger={['click']}>
                                <div className='material-settings'>
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                </div>
                            </Dropdown>
                        </div>
                        <div className='material-details' >
                            <div className='material-label'>
                                <FormattedMessage id='general.title' />
                            </div>
                            <div className='material-title'>
                                {parsedFileSettings.title ? parsedFileSettings.title : <FormattedMessage id='general.activity' />}
                            </div>
                        </div>
                        <div className='material-details' >
                            <div className='material-label'>
                                <FormattedMessage id='general.type' />
                            </div>
                            <div>
                                {material.type === 'SCORM_LEARNING' ? 'SCORM' : material.type === 'VIDEO_MATERIAL' ? 'VIDEO' :'PDF'}
                            </div>
                        </div>
                        {material.type === 'PDF_MATERIAL' &&
                            < div className='material-details' >
                                <div className='material-label'>
                                    <FormattedMessage id='courses.slide_length' />
                                </div>
                                <div>
                                    {settings?.seconds_on_slide ? settings.seconds_on_slide : '-'}
                                </div>

                            </div>
                        }
                        {material.type === 'PDF_MATERIAL' &&
                            < div className='material-details' >
                                <div className='material-label'>
                                    <FormattedMessage id='courses.completion_coefficient' />
                                </div>
                                <div>
                                    {settings?.pass_rate && parsedFileSettings?.pages ? `${settings.pass_rate}/${parsedFileSettings.pages}` : '-'}
                                </div>
                            </div>
                        }
                    </>
                )
            case 'SCORM_TEST':
                parsedFileSettings = material.fileSettings ? JSON.parse(material.fileSettings) : {};
                settings = material.settings ? JSON.parse(material.settings) : {};

                return (
                    <>
                        <div className='card-title-wrapper'>
                            <div className='card-title'>
                                <i className="fa-solid fa-check-double"></i> <FormattedMessage id='courses.test_material' />
                            </div>
                            <Dropdown overlay={content} trigger={['click']}>
                                <div className='material-settings'>
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                </div>
                            </Dropdown>
                        </div>
                        <div className='material-details' >
                            <div className='material-label'>
                                <FormattedMessage id='general.title' />
                            </div>
                            <div className='material-title'>
                                {parsedFileSettings.title ? parsedFileSettings.title : <FormattedMessage id='general.activity' />}
                            </div>
                        </div>
                        <div className='material-details' >
                            <div className='material-label'>
                                <FormattedMessage id='general.type' />
                            </div>
                            <div>
                                SCORM
                            </div>
                        </div>
                        < div className='material-details' >
                            <div className='material-label'>
                                <FormattedMessage id='courses.min_test_result' />
                            </div>
                            <div>
                                {settings?.pass_rate ? `${settings.pass_rate}%` : '-'}
                            </div>
                        </div>
                    </>
                )
            case 'FILE':
                parsedFileSettings = material.fileSettings ? JSON.parse(material.fileSettings) : {};
                settings = material.settings ? JSON.parse(material.settings) : {};

                return (
                    <>
                        <div className='card-title-wrapper'>
                            <div className='card-title'>
                                <i className="fa-solid fa-file-lines" /><FormattedMessage id='courses.material' />
                            </div>
                            <Dropdown overlay={content} trigger={['click']}>
                                <div className='material-settings'>
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                </div>
                            </Dropdown>
                        </div>

                        <div className='material-details' >
                            <div className='material-label'>
                                <FormattedMessage id='general.title' />
                            </div>
                            <div className='material-title'>
                                {parsedFileSettings.title ? parsedFileSettings.title : <FormattedMessage id='general.file' />}
                            </div>
                        </div>

                        <div className='material-details' >
                            <div className='material-label'>
                                <FormattedMessage id='general.description' />
                            </div>
                            <div className='material-description-wrapper'>
                                <Input maxLength={64} defaultValue={settings.description ? settings.description : ''} onBlur={(event: any) => reload ? storeOptions('description', event.target.value, material.id) : null} placeholder={intl.formatMessage({ id: 'courses.file_description' })} />
                            </div>
                        </div>

                        <div className='material-details' >
                            <div className='material-label'>
                                <FormattedMessage id='general.type' />
                            </div>
                            <div>
                                {parsedFileSettings?.title ? generalHelper.getFileType(parsedFileSettings.title) : '-'}
                            </div>
                        </div>

                        <div className='material-details' >
                            <div className='material-label'>
                                <FormattedMessage id='general.file_size' />
                            </div>
                            <div>
                                {parsedFileSettings?.file_size ? generalHelper.getFileSize(parsedFileSettings.file_size) : '-'}
                            </div>
                        </div>
                    </>
                )
            case 'ASSIGNMENT':
                parsedFileSettings = material.fileSettings ? JSON.parse(material.fileSettings) : {};
                settings = material.settings ? JSON.parse(material.settings) : {};
                return (
                    <>
                        <div className='card-title-wrapper'>
                            <div className='card-title'>
                                <i className="fa-solid fa-file-signature"></i><FormattedMessage id='courses.individual_work' />
                            </div>
                            <Dropdown overlay={content} trigger={['click']}>
                                <div className='material-settings'>
                                    <i className="fa-solid fa-ellipsis-vertical"></i>
                                </div>
                            </Dropdown>
                        </div>

                        <div className='material-details' >
                            <div className='material-label'>
                                <FormattedMessage id='general.title' />
                            </div>
                            <div className='material-title'>
                                {settings.title ? settings.title : <FormattedMessage id='general.activity' />}
                            </div>
                        </div>

                        <div className='material-details' >
                            <div className='material-label'>
                                <FormattedMessage id='courses.work_materials' />
                            </div>
                            <div>
                                {parsedFileSettings.length}
                            </div>
                        </div>

                        <div className='material-details' >
                            <div className='material-label'>
                                <FormattedMessage id='courses.work_submission' />
                            </div>
                            <div>
                                {!!settings.file_upload ? <FormattedMessage id='general.yes' /> : <FormattedMessage id='general.no' />}
                            </div>
                        </div>
                        < div className='material-details' >
                            <div className='material-label'>
                                <FormattedMessage id='courses.evaluation_type' />
                            </div>
                            <div>
                                {settings.evaluation_type === 'EXAMINATION' && <FormattedMessage id='courses.examination' />}
                                {settings.evaluation_type === 'RANGE' && <FormattedMessage id='courses.rating_range' />}
                                {settings.evaluation_type === 'NO_EVALUATION' && <FormattedMessage id='courses.no_evaluation' />}
                            </div>
                        </div>
                    </>
                )
            default:
                break;
        }
    }

    return (
        <>
            <Card className='material-card-wrapper'>
                {panelContent(material.type)}
            </Card>
            <ScormPreviewModal
                materialType={material.type}
                visible={showScormPreViewModal}
                onCancel={() => setShowScormPreViewModal(false)}
                activityId={activityId}
            />
        </>
    );
}

export default MaterialCard;
