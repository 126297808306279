import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

import 'components/FilterBar/styles.scss';

const LicenceFilterBar = ({filteredCustomerName, clearFilter}: any) => {
  const intl = useIntl();

  return (
    <div className="filter-by-label">
      <FormattedMessage id="general.filtered_by" />:
      <div className="custom-filter-tags">
        <div>{`${intl.formatMessage({id: 'general.customer'})}: ${filteredCustomerName}`}</div>
      </div>
      <button
        className="clear-all-button"
        onClick={clearFilter}
        style={{ whiteSpace: 'nowrap' }}
      >
        <FormattedMessage id="users.clear_all" />
      </button>
    </div>
  );
}

export default LicenceFilterBar;