import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DefaultLayout from 'components/DefaultLayout';
import { connect } from 'react-redux';
import { Alert, Button, Popover, Table, Tooltip, message, Form } from 'antd';
import Switch from 'components/Form/Switch'
import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import { CampaignNotificationsInterface } from '../types';
import SendEmailModal from 'components/SendEmailModal';
import apiClient from 'utils/apiClient';
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale,
        userRole: state.session.active_user_type
    }
};

const CampaignNotifications: React.FC<CampaignNotificationsInterface & RouteComponentProps> = ({
    campaignId,
    owner,
    next,
    previous,
    campaignStatus,
    setCertificateTemplate,
    locale,
    launchButton,
    newCampaignStatus,
    currentStep,
    sendRequest,
    launching,
    submitTab,
    history,
    campaign,
    userRole
}) => {
    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [showScenariosModal, setShowScenariosModal] = useState(false);
    const [isNotificationsLoading, setIsNotificationsLoading] = useState(true);
    const [notificationList, setNotificationList] = useState([]);

    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
        if (launching && currentStep === 3) {
            sendRequest(submitForm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [launching]);

    useEffect(() => {
        if (submitTab === 'notifications') {
            submitForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitTab]);

    const submitForm = async (direction: string = '') => {
        if (direction === 'next') {
            next(campaignId);
        } else if (direction === 'previous') {
            previous();
        } else if (direction === 'save') {
            history.push('/courses/manage-campaigns');
        }
    };



    useEffect(() => {
        if (campaignId) {
            loadNotifications()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignId]);

    const loadNotifications = async () => {
        try {
            setIsNotificationsLoading(true);

            const response = await apiClient.request(`/api/v1/mail-scenarios/get-by-learning-campaign/${campaignId}`, {}, 'GET');
            setNotificationList(response.mailScenarios);

            Object.values(response.mailScenarios).map((record: any) => {
                if (record.learningCampaignNotificationType === 'ASSIGNMENT_SUBMITTED_NOTIFICATION' && record.status === 'ACTIVE') {
                }
                return null;
            })

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setIsNotificationsLoading(false);
        }
    };

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        setVisibleTooltip(visible ? recordId : null);
    };

    const ccToManager = async (cc: any, values: any) => {
        const parsedValues = ({
            cc: cc
        });

        await apiClient.request(`/api/v1/mail-scenarios/${values.id}/set-cc`, parsedValues, 'POST');
    };

    const defaultStatus = async (status: any, values: any) => {
        if (values.learningCampaignNotificationType === 'COURSE_COMPLETION_WITH_ATTACHMENT') {
            setCertificateTemplate(status);
        }

        const parsedValues = (
            status
                ? {
                    ...values,
                    status: 'ACTIVE'
                }
                : {
                    ...values,
                    status: 'INACTIVE'
                }
        );

        await apiClient.request(`/api/v1/mail-scenarios/${values.id}/set-status`, parsedValues, 'POST');
    };

    const columns = [
        {
            title: intl.formatMessage({ id: 'campaign.notification.title' }),
            dataIndex: 'templateName',
            render: (templateName: any) => {
                let language: string = locale ? locale : '';
                return templateName && templateName[language] ? templateName[language] : templateName[Object.keys(templateName)[0]]
            },
        },
        {
            title:
                <>
                    <FormattedMessage id={intl.formatMessage({ id: 'campaign.notification.status' })} />
                    <Tooltip title={intl.formatMessage({ id: 'campaign.notification.status.hint' })}>
                        <QuestionCircleOutlined />
                    </Tooltip>
                </>,
            dataIndex: 'defaultStatus',
            render: (_unused: string, record: any) => {
                if (record.learningCampaignNotificationType === 'COURSE_COMPLETION_WITH_ATTACHMENT') {
                    switch (record.status) {
                        case 'ACTIVE':
                            setCertificateTemplate(true);
                            break;
                        case 'INACTIVE':
                            setCertificateTemplate(false);
                            break;
                    }
                }

                const showManagerSelectField = (show: boolean) => {
                    if (record.learningCampaignNotificationType === 'ASSIGNMENT_SUBMITTED_NOTIFICATION') {
                    }
                }

                return (
                    <Switch
                        onChange={(e: any) => { defaultStatus(e, record); showManagerSelectField(e) }}
                        defaultChecked={record.status === 'ACTIVE'}
                    />
                );
            },
        },
        {
            title: intl.formatMessage({ id: 'campaign.cc_to_manager' }),
            key: 'cc',
            render: (cc: boolean, record: any) => {
                return (
                    <Switch
                        onChange={(e) => ccToManager(e, record)}
                        defaultChecked={record.cc}
                    />
                );
            },
        },
        {
            key: 'actions',
            render: (record: any) => {
                const content = (
                    <>
                        <NavLink
                            to={{
                                pathname: `/e-mailing/manage-templates/customer/${owner?.id}/${record.templateUuid}/edit`,
                                state: {
                                    campaign: true, backPath: window.location.pathname
                                },
                            }}
                        >
                            <div className="popover-item">
                                <FormattedMessage id='campaign.edit_email_template' />
                            </div>
                        </NavLink>
                        <NavLink to={`/e-mailing/${record.id}/history/`}>
                            <div className='popover-item'>
                                <FormattedMessage id='emailing.scenarios.view_sending_history' />
                            </div>
                        </NavLink>
                    </>
                );
                return (
                    <Popover
                        visible={record.id === visibleTooltip}
                        content={content}
                        trigger='click'
                        placement='bottomRight'
                        arrowPointAtCenter
                        onVisibleChange={(visible) => handleVisibleChange(visible, record.id)}
                    >
                        <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
                            <i className='fal fa-ellipsis-v' style={{ fontSize: '16px' }} />
                        </div>
                    </Popover>
                );
            },
        },
    ];

    return (
        <>
            <div className='campaign-header-text'>
                <h1 className='text-30' >{intl.formatMessage({ id: 'campaign.please_set_up_email_notifications_for_your_campaign' })}</h1>
            </div>
            <Alert
                closable
                message={intl.formatMessage({ id: 'campaign.you_may_use_standart_email_notifications' })}
                type='info'
                showIcon
                style={{ marginBottom: 24 }}
            />
            <Button
                type='primary'
                icon={<PlusOutlined />}
                onClick={() => setShowScenariosModal(true)}
            >
                <span>
                    <FormattedMessage id='campaign.add_new_email' />
                </span>
            </Button>
            <Table
                locale={{ emptyText: intl.formatMessage({ id: 'general.found_no_data' }) }}
                rowKey={(record) => record.id}
                style={{ marginTop: 15 }}
                columns={columns}
                dataSource={notificationList}
                size='middle'
                scroll={{ x: 800 }}
                loading={isNotificationsLoading}
            />
            <DefaultLayout.PageFooterWithRow
                left={
                    <>
                        {newCampaignStatus &&
                            <>
                                <Button onClick={() => submitForm('previous')}>
                                    <FormattedMessage id='campaign.previous' />
                                </Button>
                                <Button onClick={() => submitForm('next')}>
                                    <FormattedMessage id='general.next' />
                                </Button>
                            </>
                        }
                    </>
                }
                right={
                    <>
                        {!newCampaignStatus &&
                            <Button onClick={() => { history.push('/courses/manage-campaigns'); }}>
                                <FormattedMessage id='general.back' />
                            </Button>
                        }
                        <Button onClick={() => { submitForm('save'); }} className='save-button'>
                            <i className='fal fa-save header-item' />
                            <FormattedMessage id={campaignStatus === 'DRAFT' ? 'campaign.save_as_draft' : 'general.save'} />
                        </Button>
                        {!newCampaignStatus &&
                            launchButton()
                        }
                    </>
                }
            />
            <SendEmailModal
                ownerId={owner?.id}
                ownerType='CUSTOMER'
                users={[]}
                visible={showScenariosModal}
                onCancel={() => setShowScenariosModal(false)}
                afterSubmit={() => { setShowScenariosModal(false); loadNotifications(); }}
                cameFromCampaign={true}
                campaignId={campaignId}
                campaignStatus={campaignStatus}
            />
        </>
    )
};
export default connect(mapStateToProps)(withRouter(CampaignNotifications));
