import * as ActionTypes from 'store/actionTypes';

const defaultState = {}

const ScormData = (state = defaultState, action: any) => {
  switch(action.type) {
    case ActionTypes.SCORM_DATA:
      var ScormData = action.payload;
      return ({...state, ...ScormData});

    default:
      return state;
  }
}

export default ScormData;
