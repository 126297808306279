import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Button, message, Modal, Form} from 'antd';
import {FormattedMessage, useIntl} from 'react-intl';
import {} from '../types';
import apiClient from "utils/apiClient";

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    };
};
const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 6},
};

const EnableDisableButton: React.FC<any> = ({session, customerUser, form, isActiveLearningManagerRole, setIsActiveLearningManagerRole, isActiveElearningAccount, setIsActiveElearningAccount, customerId}) => {
    const [showDeactivationConfirmationModal, setShowDeactivationConfirmationModal] = useState(false);
    const [submitButtonInProgress, setSubmitButtonInProgress] = useState(false);

    const intl = useIntl();

    const activateLearningManager = async () => {

        try {
            if (session.active_user_type === 'CUSTOMER_ADMIN') {
                if (!session.special_rights.includes('can_manage_learning_managers')) {
                    throw new Error("action_is_not_permitted");
                }
            }

            setSubmitButtonInProgress(true);

            let url = `/api/v1/customer/${customerUser.userUuid}/activate-learning-manager`;

            if (session.active_user_type === 'SUPER_ADMIN' || session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN'  ) {
                url = url + '?customerId=' + customerId;
            }

            await apiClient.request(url, [], 'POST');
            message.success(intl.formatMessage({id: "users.form.learning_manager_activated"}));
            setIsActiveLearningManagerRole(true);
        } catch (err: any) {
            let errorMessage = "error.server_error";

            console.error(err);

            if (err.message === 'action_is_not_permitted') {
                errorMessage = 'validation.' + err.message
            }

            message.error(intl.formatMessage({id: errorMessage}));
        } finally {
            setSubmitButtonInProgress(false);
        }
    };

    const deactivateLearningManager = async () => {
        try {
            if (session.active_user_type === 'CUSTOMER_ADMIN') {
                if (!session.special_rights.includes('can_manage_learning_managers')) {
                    throw new Error('action_is_not_permitted');
                }
            }

            let url = `/api/v1/customer/${customerUser.userUuid}/deactivate-learning-manager`;

            if (session.active_user_type === 'SUPER_ADMIN' || session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN'  ) {
                url = url + '?customerId=' + customerId;
            }

            setSubmitButtonInProgress(true);

            let response = await apiClient.request(url, [], 'POST');

            if (response.success) {
                setIsActiveLearningManagerRole(false);
                message.success(intl.formatMessage({id: 'users.form.learning_manager_deactivated'}));
                if (!isActiveElearningAccount) {
                    setIsActiveElearningAccount(true);
                    message.warning(intl.formatMessage({id: 'users.form.this_student_is_converted_into_student_account_type'}));
                }
            } else {
                message.error(intl.formatMessage({id: 'error.you_may_not_deactivate_your_account'}));
            }

            setShowDeactivationConfirmationModal(false);

        } catch (err: any) {
            let errorMessage = 'error.server_error';

            console.error(err);

            if (err.message === 'action_is_not_permitted') {
                errorMessage = err.message
            }

            message.error(intl.formatMessage({id: errorMessage}));
        } finally {
            setSubmitButtonInProgress(false);
        }
    };

    return (
        <>
            <Form.Item
                {...formItemLayout}
                className="learning_manager_role"
                label={intl.formatMessage({id: "users.form.learning_manager_role"})}
            >
                <div>
                    {
                        isActiveLearningManagerRole ?
                            <Button type="primary"
                                    onClick={() => setShowDeactivationConfirmationModal(true)}
                                    loading={submitButtonInProgress} style={{
                                height: 32,
                                padding: 8,
                                lineHeight: 1,
                                backgroundColor: '#F5222D',
                                borderRadius: 8,
                                marginRight: 8,
                                border: 0
                            }}>
                                <FormattedMessage id="general.deactivate"/>
                            </Button>
                            :
                            <Button type="primary"
                                    onClick={() => activateLearningManager()} loading={submitButtonInProgress}
                                    style={{
                                        height: 32,
                                        padding: 8,
                                        lineHeight: 1,
                                        backgroundColor: '#52C419',
                                        borderRadius: 8,
                                        marginRight: 8,
                                        border: 0
                                    }}>
                                <FormattedMessage id="general.activate"/>
                            </Button>
                    }
                </div>
            </Form.Item>
            <Modal
                title={intl.formatMessage({id: 'users.form.deactivate_learning_manager'})}
                visible={showDeactivationConfirmationModal}
                onOk={() => deactivateLearningManager()}
                onCancel={() => setShowDeactivationConfirmationModal(false)}
                okText={intl.formatMessage({id: 'users.form.deactivate_learning_manager'})}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
                confirmLoading={submitButtonInProgress}
            >
                <FormattedMessage id="confirmation.are_you_sure"/>
            </Modal>
        </>
    );
};

export default connect(mapStateToProps)(EnableDisableButton);
