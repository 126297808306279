import { Dispatch } from 'redux';
import { AppearanceSettingsData } from 'interfaces/redux';
import * as ActionTypes from 'store/actionTypes';

export const changePublicAppearance = (data: AppearanceSettingsData) => ((dispatch: Dispatch) => {


    dispatch({
        type: ActionTypes.SAVE_PUBLIC_APPEARANCE,
        payload: data,
    });
});
