interface ValidationRules {
  required ?: boolean
}

const constructValidationRules = ({required}: ValidationRules, intl: any) => {
  let validationRules = [];

  if (required) {
    validationRules.push({
      required: true,
      message: intl.formatMessage({id: "validation.field_required"}),
      validateTrigger: 'onSubmit'
    });
  }

  return validationRules;
}

export { constructValidationRules };
