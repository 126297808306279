import React from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import './styles.scss';

interface FilePropertiesInterface {
    fileProperties: {
        title: string;
        size?: string;
        titleLabel: string;
        sizeLabel?: string;
    },
    layout?: {
        labelCol: {
            span: number
        }
        wrapperCol: {
            span: number
        }
    },
    showTitle?: boolean;
    showSize?: boolean;
}

const FileProperties: React.FC<FilePropertiesInterface> = ({ fileProperties, layout, showTitle = true, showSize = true }) => {
    const intl = useIntl();

    return (
        <>
            {showTitle &&
                < Form.Item {...layout} label={intl.formatMessage({ id: fileProperties.titleLabel })}>
                    <div className='file-properties-container'>
                        <div className='file-title'>
                            {fileProperties.title}
                        </div>
                    </div>
                </Form.Item>
            }
            {
                showSize &&
                <Form.Item {...layout} label={intl.formatMessage({ id: fileProperties.sizeLabel })}>
                    <div className='file-properties-container'>
                        <div className='file-title'>
                            {fileProperties.size}
                        </div>
                    </div>
                </Form.Item>
            }
        </>
    )
}
export default FileProperties;
