import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Table, Modal, Tooltip } from 'antd';
import moment from 'moment';
import coursesApiClient from 'utils/coursesApiClient';
import { withRouter } from 'react-router-dom';
import Spinner from 'components/Spinner';
import './styles.scss';

const PhishingCampaignResultModal = ({visible, onCancel, enrollmentId, setEnrollmentId, campaignType=null}: any) => {
    const [userPhishingResults, setUserPhishingResults] = useState();
    const [isSiminning, setIsSiminning] = useState(false);
    const intl = useIntl();

    useEffect(()=> {
        const loadUserPhishingResults = async () => {
            setIsSiminning(true);
            try {
                let responese = await coursesApiClient.request(`/api/v1/courses/phishing-campaigns/user-results/${enrollmentId}`, [], 'GET');
                setUserPhishingResults(responese.userResults);
            } catch (error) {
                message.error(intl.formatMessage({id: "error.server_error"}));
            } finally {
                setIsSiminning(false);
            }
            setEnrollmentId(null);
          }

          const loadUserCustomPhishingResults = async () => {
            setIsSiminning(true);
            try {
                let responese = await coursesApiClient.request(`/api/v1/courses/custom-phishing/user-results/${enrollmentId}`, [], 'GET');
                setUserPhishingResults(responese.userResults);
            } catch (error) {
                message.error(intl.formatMessage({id: "error.server_error"}));
            } finally {
                setIsSiminning(false);
            }
            setEnrollmentId(null);
          }

          if(enrollmentId && campaignType === 'STANDARD') {
            loadUserPhishingResults();
          } else if (enrollmentId && campaignType === 'CUSTOM') {
            loadUserCustomPhishingResults();
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enrollmentId])

    const columns = [
        {
            title: intl.formatMessage({id: 'phishing.phishing_template'}),
            dataIndex: 'template',
            key: 'template',
            render: (text: string, record: any) => record.template
        },
        {
          title: intl.formatMessage({id: 'phishing.email'}),
          dataIndex: 'email',
          key: 'email',
        render: (text: string, record: any) => record.email
        },
        {
          title: intl.formatMessage({id: 'phishing.phish_delivered_on'}),
          dataIndex: 'send_date',
          key: 'send_date',
          render: (text: string, record: any) => {
            let date = record.send_date.substring(0, 10)
            let time = record.send_date.substring(11, 16)

            return moment(date + ' ' + time).format('DD.MM.YYYY HH:mm')
          }
        },
        {
          title:(   
            <span>
              {intl.formatMessage({id: 'phishing.event'})}
              <Tooltip title={intl.formatMessage({id: 'phishing.event_hint'})}>
                  <i className='fal fa-question-circle header-item' />
              </Tooltip>
            </span>),
          dataIndex: 'status',
          key: 'status',

          render: (text: string, record: any) => {
            let date = record.send_date.substring(0, 10)
            let time = record.send_date.substring(11, 16)

            if (moment(date + ' ' + time).isBefore(moment()) && record.status === 'Scheduled') {
              return <FormattedMessage id='general.error' />
            } 
            return record.status
          }
        },
        {
            title: intl.formatMessage({id: 'phishing.user_action_date'}),
            dataIndex: 'modified_date',
            key: 'modified_date',
            render: (text: string, record: any) => {
                if (record.modified_date === record.send_date || record.modified_date === '') {
                    return '-'
                } else {
                    let date = record.modified_date.substring(0, 10)
                    let time = record.modified_date.substring(11, 16)
        
                    return moment(date + ' ' + time).format('DD.MM.YYYY HH:mm')
                }
            }
        },
        {
            title: intl.formatMessage({id: 'phishing.reported'}),
            dataIndex: 'reported',
            key: 'reported',
            render: (text: string, record: any) => {
                return record.reported ?  intl.formatMessage({id: 'general.yes'}):  intl.formatMessage({id: 'general.no'})
            }
        }
      ];

  return (
    <Modal
        className="results-modal"
        title={intl.formatMessage({id: 'phishing.results_modal'})}
        visible={visible}
        onCancel={onCancel}
        cancelText={intl.formatMessage({id: 'general.close'})}
        footer={null}
    >
        <Spinner spinning={isSiminning}>
            <Table
                locale={{ emptyText: intl.formatMessage({id: "general.found_no_data"}) }}
                style={{marginTop: 16}}
                columns={columns}
                dataSource={userPhishingResults}
                size="middle"
                scroll={{ x: 800 }}
            />
        </Spinner>
    </Modal>
  )
}
export default (withRouter(PhishingCampaignResultModal));