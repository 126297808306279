import * as ActionTypes from 'store/actionTypes';

const defaultState = {}

const CustomerUserFilter = (state = defaultState, action: any) => {
  switch(action.type) {
    case ActionTypes.SAVE_CUSTOMER_USER_FILTER:
      var customerUserFilter = action.payload;
      return ({...state, ...customerUserFilter});

    default:
      return state;
  }
}

export default CustomerUserFilter;
