import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { changeScormData } from 'store/actions/scromData';
import coursesApiClient from 'utils/coursesApiClient';
import apiClient from 'utils/apiClient';
import styles from '../styles.module.scss';

const mapStateToProps = (state: any) => ({ session: state.session, locale: state.locale, activityList: state.campaignActivityList });

const mapDispatchToProps = (dispatch: any) => ({
  changeScormData: (formData: any) => dispatch(changeScormData(formData))
});

const Buttons: React.FC<any> = ({ match, activity, campaignData, session, changeScormData, activityList, history, preview, isMaterials = false, setResourceData }) => {
  const [currentTestSequencePriority, setCurrentTestSequencePriority] = useState<any>('0');
  const [currentLearningSequencePriority, setCurrentLearningSequencePriority] = useState<any>('0');
  const [isScormValidating, setIsScormValidating] = useState(false);
  const adminRoles = ['RESELLER_ADMIN', 'SUPER_ADMIN', 'DISTRIBUTOR_ADMIN', 'CUSTOMER_ADMIN', 'SUPERVISION'];


  const campaignId = match.params.id;
  const enrollmentId = match.params.enrollmentId;
  const intl = useIntl();

  const getTestMessageId = (): string => {
    switch (activity.testStatus) {
      case ('NOT_STARTED'):
        if (activity.hasOwnProperty("customSequencePriority")) {
          if (currentTestSequencePriority !== activity.customSequencePriority) {
            return '';
          }
        }
        return 'student.start_test';
      case ('IN_PROGRESS'):
        return 'student.continue_test';
      case ('FAILED'):
        return 'student.repeat_test';
      case ('PASSED'):
        if (activity.hasOwnProperty("customSequencePriority")) {
          setCurrentTestSequencePriority(activity.customSequencePriority + 1);
        }
    }

    if (activity.hasOwnProperty("customSequencePriority")) {
      if (currentTestSequencePriority !== activity.customSequencePriority) {
        return '';
      }
    }

    if (!activity.testStatus) {
      activity.testStatus = 'NOT_STARTED';
    }
    return 'student.start_test';
  }

  const getTestButtonStatus = (): boolean => {
    if (activityList.resertificationIndex && campaignData.includePretestAsOfSecondRecertification) {
      activity.forcePretesting = 1;
    }

    if (adminRoles.includes(session.active_user_type)) {
      return false;
    }

    if (
      (activity.learningStatus !== 'COMPLETED' && activity.forcePretesting && activity.testStatus === 'FAILED')
      || !activity.hasTest
      || (!activity.forcePretesting && activity.testStatus === 'NOT_STARTED' && activity.learningStatus !== 'COMPLETED' && activity.hasLearningFile)
      || (activity.testStatus === 'FAILED' && activity.learningStatus !== 'COMPLETED')
      || getTestMessageId().length === 0
      || activity.testStatus === 'PASSED'
    ) return false;
    return true
  }

  const getLearningMessageId = (): string => {
    switch (activity.learningStatus) {
      case ('NOT_STARTED'):
        if (activity.hasOwnProperty("customSequencePriority")) {
          if (currentLearningSequencePriority !== activity.customSequencePriority || currentTestSequencePriority !== currentLearningSequencePriority) {
            return '';
          }
        }
        return 'student.start_learning';
      case ('IN_PROGRESS'):
        return 'student.continue_learning';
      case ('COMPLETED'):
        if (activity.hasOwnProperty("customSequencePriority")) {
          setCurrentLearningSequencePriority(activity.customSequencePriority + 1);
        }
        return 'student.repeat_learning';
      case ('EXEMPTED'):
        return 'student.repeat_learning'
    }

    if (activity.hasOwnProperty("customSequencePriority")) {
      if (currentLearningSequencePriority !== activity.customSequencePriority || currentTestSequencePriority !== currentLearningSequencePriority) {
        return '';
      }
    }
    activity.learningStatus = 'NOT_STARTED';
    return 'student.start_learning';
  }
  const getLearningButtonStatus = (): boolean => {

    if (activityList.resertificationIndex && campaignData.includePretestAsOfSecondRecertification) {
      activity.forcePretesting = 1;
    }

    if (adminRoles.includes(session.active_user_type)) {
      return false;
    }

    if (!!activity.hasLearningFile) {
      if (
        (activity.forcePretesting && activity.testStatus === 'NOT_STARTED' && !!activity.hasTest)
        || (activity.learningStatus === 'COMPLETED' && !campaignData.accessToCompletedActivityMaterials)
        || getLearningMessageId().length === 0
      ) return false;

    } else if (!!activity.hasLearningFile) {

    }


    return true;
  }

  const beginActivity = (parantLearningActivityId: number, activityAttemptId: number, isFirstAttempt: boolean = false, type: string, availableLanguages: any, learningActivityId: number, materialType: string) => {

    validateActivity(parantLearningActivityId).then(({ hasAccess, learningManagerEmails = null }) => {
      if (hasAccess) {
        changeScormData({
          learningActivityId,
          activityAttemptId,
          isFirstAttempt,
          enrollmentId,
          campaignId,
          type,
          materialType,
          parentActivityId: parantLearningActivityId
        });
        setTimeout(function () {
          window.open('/student/show-activity')
          setIsScormValidating(false);
        }, 100);

      } else {
        const emailLength = learningManagerEmails.length;

        const emails = learningManagerEmails.map((email: string, index: number) => {
          return (
            <>
              <a href={`mailto:${email}`}>{email}</a>
              {emailLength !== index + 1 ? ', ' : null}
            </>
          );
        });

        message.error(intl.formatMessage({ id: 'error.no_access_to_activity' }, { emails }), 10);
        setIsScormValidating(false);
      }
    });
  }

  const changeStatus = () => {
    coursesApiClient.request(`/api/v1/courses/student/campaigns/attempt-start/${enrollmentId}`, [], 'POST');
  }

  const validateScorm = async (parantLearningActivityId: number, activityAttemptId: number, isFirstAttempt: boolean = false, type: string, availableLanguages: any, learningActivityId: number, materialType: string) => {
    setIsScormValidating(true);
    let response = await apiClient.request(`/api/v1/courses/serve-request/${session.active_user_type_uuid}/validate-scorm`, [], 'GET');

    if (!response.access) {
      setIsScormValidating(false);
      return message.error(intl.formatMessage({ id: 'error.already_active_activity' }));
    }

    if (materialType === 'PDF_MATERIAL' || materialType === 'VIDEO_MATERIAL') {
      consumeLicenceAccess();
    }

    beginActivity(parantLearningActivityId, activityAttemptId, isFirstAttempt, type, availableLanguages, learningActivityId, materialType);

    if (isFirstAttempt) {
      changeStatus();
    }
  };

  const validateActivity = async (activityId: number) => {
    try {
      const values = {
        activityId,
        userTypeId: session.active_user_type_id,
        customerId: session.organization.organization_id,
        campaignId: campaignId
      }

      return await apiClient.request('/api/v1/licences/validate', values, 'POST');
    } catch (error) {
      message.error(intl.formatMessage({ id: 'error.data_load' }));
      console.error(error);
    }
  }

  const consumeLicenceAccess = async () => {
    try {
      const values = {
        activityId: activity.id,
        userTypeId: session.active_user_type_id,
        customerId: session.organization.organization_id,
        parentActivityId: activity.parentActivityId,
        campaignId: campaignId
      }

      return await apiClient.request('/api/v1/licences/consume', values, 'POST');
    } catch (error) {
      message.error(intl.formatMessage({ id: 'error.data_load' }));
      console.error(error);
    }
  }

  return (
    <div className={styles.buttonContainer}>
      {activity.hasAssignment && !isMaterials ?
        <Button
          loading={isScormValidating}
          className={`ant-btn-student ${styles.button} ${styles.buttonTest}`}
          onClick={() => { adminRoles.includes(session.active_user_type) ? setResourceData({ activityId: activity.id, campaignId: campaignData.campaignId, enrollmentId: campaignData.enrollmentId }) : history.push(`/student/${enrollmentId}/activity-list/${campaignId}/resources/${activity.id}`) }}
        >
          <FormattedMessage id='courses.view_activities' />
        </Button>
        :
        <>
          {
            getTestButtonStatus() ?
              <Button
                loading={isScormValidating}
                className={`ant-btn-student ${styles.button} ${styles.buttonLearning}`}
                onClick={() => validateScorm(
                  activity.parentActivityId ? activity.parentActivityId : activity.id,
                  activity.activityAttemptId,
                  activity.testStatus === 'NOT_STARTED',
                  'test',
                  activity.availableLanguages, activity.id,
                  'SCORM_TEST'
                )}
              >
                <FormattedMessage id={getTestMessageId()} />
              </Button>
              : null
          }
          {
            getLearningButtonStatus() ?
              <Button
                loading={isScormValidating}
                className={`ant-btn-student ${styles.button} ${styles.buttonTest}`}
                type="primary"
                onClick={() => {
                  validateScorm(activity.parentActivityId ? activity.parentActivityId : activity.id,
                    activity.activityAttemptId,
                    activity.learningStatus === 'NOT_STARTED',
                    'learn',
                    activity.availableLanguages,
                    activity.id,
                    activity.lastLearningActivity
                  );
                }}
              >
                <FormattedMessage id={getLearningMessageId()} />
              </Button>
              : <div className={`ant-btn-student ${styles.button} ${styles.buttonTest}`}></div>
          }
        </>
      }
    </div>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)((withRouter(Buttons)));
