import React from 'react';
import { Modal } from 'antd';
import CampaignCertificate from 'components/CampaignCertificate';
import { PreviewCertificateModalInterface } from '../types';

const PreviewCertificateModal: React.FC<PreviewCertificateModalInterface> = ({
    visible,
    onCancel,
    certificatePreviewData
}) => {
    return (
        <Modal
            className="campaign-certificate-modal"
            visible={visible}
            onCancel={onCancel}
            footer={null}
            width={920}
            bodyStyle={{ height: '770px' }}
        >
            <CampaignCertificate data={certificatePreviewData.data} showPreviewModel={visible} previewLink={certificatePreviewData.previewLink} certificateName={certificatePreviewData.certificateName} />
        </Modal>
    )
}
export default PreviewCertificateModal;
