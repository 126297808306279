import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message, Table, Form, Radio, Alert, Tabs, Button, Tooltip, Space, Select } from 'antd';
import moment from 'moment';

import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import { SortableContainer as SortContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { DatePicker, InputNumber, Switch } from 'components/Form';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import arrayMove from 'array-move';
import coursesApiClient from 'utils/coursesApiClient';
import { PhishingSettingsTabsInterface } from '../types';
import { PlusOutlined } from '@ant-design/icons';
import PhishingSimulationsModal from 'components/FormCampaign/PhishingSimulationsModal';
import PhishingTestEmailModal from 'components/PhishingTestEmailModal';
import Spinner from 'components/Spinner';

const mapStateToProps = (state: any) => {
    return {
        userRole: state.session.active_user_type
    }
  };

const PhishingSettingsTabs: React.FC<PhishingSettingsTabsInterface & RouteComponentProps> = ({
    campaignId,
    owner,
    status,
    campaign,
    phishingSettings,
    phishingCampaign,
    setPhishingSettings,
    previous,
    validation,
    launchButton,
    newCampaignStatus,
    currentStep,
    sendRequest,
    launching,
    submitTab,
    history,
    smartDephishSwitch,
    setSmartDephishSwitch,
    userRole
}) => {
    const [selectedSimulations, setSelectedSimulations] = useState<any>();
    const [selectedTestSimulation, setSelectedTestSimulation] = useState<any>();
    const [sendTestPhishingEmailModal, setSendTestPhishingEmailModal] = useState(false);
    const [showPhishingSimulationModal, setShowPhishingSimulationModal] = useState(false);
    const [userPassedSimulationTest, setUserPassedSimulationTest] = useState();
    const [phishingDeliveryAfterCampaignEnded, setPhishingDeliveryAfterCampaignEnded] = useState();
    const [phishingEnabledSwitch, setPhishingEnabledSwitch] = useState<boolean>(true);
    const [phishingSimulations, setPhishingSimulations] = useState();
    const [loading, setLoading] = useState(false);
    const [campaignSimulations, setCampaignSimulations] = useState<any[]>([]);
    const [direction, setDirection] = useState('');
    const [disabled, setDisabled] = useState(false);

    const [escalationManager, setEscalationManager] = useState(false);
    const [usersLoading, setUsersLoading] = useState(false);
    const [learningManagers, setLearningManagers] = useState();

    const intl = useIntl();
    const [form] = Form.useForm();

    const { TabPane } = Tabs;

    useEffect(() => {
        if (launching && currentStep === 5) {
            sendRequest(submitForm);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [launching]);

    useEffect(() => {
        if (status !== 'DRAFT') {
            setPhishingEnabledSwitch(phishingSettings.status === 'ACTIVE');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phishingSettings]);

    useEffect(() => {
        if (status !== 'DRAFT') {
            setDisabled(true);
        }

    }, [status]);

    useEffect(() => {
        if (submitTab === 'phishing') {
            submitForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitTab]);

    useEffect(() => {
        if (phishingSettings) {
            setFormValues();
        } else {
            setFormDefaultValues();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [phishingSettings]);

    useEffect(() => {
        let simulations: any[] = [];

        if (phishingSimulations && campaignSimulations) {
            Object.values(phishingSimulations).map((simulation: any) => {
                if (campaignSimulations.includes(simulation.id)) {
                    simulations.push(simulation);
                }
                return simulations;
            })
        }
        setSelectedSimulations(simulations);

    }, [phishingSimulations, campaignSimulations]);

    useEffect(() => {

        if (smartDephishSwitch && selectedSimulations && selectedSimulations.length > 0) {
            validation(true);
        } else if (smartDephishSwitch && selectedSimulations && selectedSimulations.length === 0) {
            validation(false);
        } else if (!smartDephishSwitch) {
            validation(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSimulations, smartDephishSwitch]);

    useEffect(() => {
        if (owner?.id) {
            loadCustomerLearningManagers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [owner]);

    const setFormValues = () => {
        setUserPassedSimulationTest(phishingSettings.sendAfterPass);

        form.setFieldsValue({
            startAfterDays: phishingSettings.startAfterDays,
            sendAfterFailDays: phishingSettings.sendAfterFailDays,
            sendAfterPass: phishingSettings.sendAfterPass,
            sendAfterPassDays: phishingSettings.sendAfterPassDays,
            allowDeliveryAfterCampaignHasEnded: !!phishingSettings.allowDeliveryAfterCampaignHasEnded,
            maxEmailsPerMinute: phishingSettings.maxEmailsPerMinute,
            notifyManager: !!phishingSettings.notifyManager,
            escalationManager: phishingSettings.escalationManager,
            notifyStudent: !!phishingSettings.notifyStudent
        });
        setEscalationManager(!!phishingSettings.notifyManager);
        setCampaignSimulations(phishingSettings.gophishTemplatesPagesIds);
    };

    const setFormDefaultValues = () => {
        form.setFieldsValue({
            startAfterDays: 7,
            sendAfterFailDays: 7,
            sendAfterPass: 'EVENLY',
            sendAfterPassDays: null,
            allowDeliveryAfterCampaignHasEnded: false,
            maxEmailsPerMinute: 1,
            notifyManager: true,
            notifyStudent: true
        });
        setEscalationManager(true);

    };

    const DragHandle = SortableHandle(() => (<i className='fal fa-arrows header-item' style={{ cursor: 'pointer' }} />));
    const SortableItem = SortableElement((props: any) => <tr {...props} />);
    const SortableContainers = SortContainer((props: any) => <tbody {...props} />);
    const DragableBodyRow = (...restProps: any) => {
        let index: any;
        if (selectedSimulations) {
            index = selectedSimulations.findIndex((x: any) => x.index === restProps[0]['data-row-key'])
        }
        return <SortableItem index={index} {...restProps[0]} />
    };

    const onSortEnd = (oldIndex: any, newIndex: any) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(selectedSimulations), oldIndex.oldIndex, oldIndex.newIndex).filter(el => !!el);
            setSelectedSimulations(newData)
        }
    };

    const DraggableContainer = (props: any) => {
        return (
            <SortableContainers
                useDragHandle
                helperClass='row-dragging'
                onSortEnd={onSortEnd}
                {...props}
            />
        )
    };

    const columns = [
        {
            title: intl.formatMessage({ id: 'phishing.nr' }),
            render: (_text: string, _record: any, index: any) => index + 1
        },
        {
            title: intl.formatMessage({ id: 'phishing.template_name' }),
            dataIndex: 'name',
            key: 'name',
            className: 'drag-visible',
            render: (_text: string, record: any) => record.simulationTemplateName
        },
        {
            title: intl.formatMessage({ id: 'phishing.difficulty' }),
            dataIndex: 'difficulty',
            key: 'difficulty',
            render: (_text: string, record: any) => intl.formatMessage({ id: `phishing.${(record.difficulty).toLowerCase()}` })
        },
        {
            width: 50,
            minWidth: 20,
            render: (_text: string, record: any) => {
                return (
                    <Button className='send-test-email-button' onClick={() => { setSelectedTestSimulation(record.id); setSendTestPhishingEmailModal(true) }}>
                        <FormattedMessage id='phishing.send_test_phishing_button' />
                    </Button>
                )
            }
        },
        {
            title: '',
            className: 'drag-visible',
            render: () => {
                return (
                    status === 'DRAFT' ? <DragHandle /> : null)
            }
        }
    ];

    const smartDePhishSwitch = async (phish: boolean) => {
        try {
            await coursesApiClient.request(`/api/v1/courses/campaigns/${campaignId}/${phish ? 'activate' : 'deactivate'}`, [], 'PUT');
            message.success(intl.formatMessage({ id: 'phishing.' + (phish ? 'smart_de_phish_activated' : 'smart_de_phish_deactivated') }));
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: 'error.server_error' }));
        }
    };

    const phishingEnabled = async (phish: boolean) => {
        try {
            await coursesApiClient.request(`/api/v1/courses/phishing-campaign/${phish ? 'start' : 'stop'}/${phishingSettings.id}/phishing`, [], 'PUT');
            message.success(intl.formatMessage({ id: "phishing." + (phish ? 'phishing_enabled' : 'phishing_deactivated') }));
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: "error.server_error" }));
        }
    };

    const disabledDates = (current: moment.Moment, to: boolean = false) => {
        const validDate = to ? moment() : moment(moment(campaign?.deadline).format()).add('60', 'days');

        if (validDate === null) {
            return current < moment().subtract(1, 'day');
        }

        return (
            (to ? validDate > current : current > validDate) ||
            validDate.isSame(current, 'day') ||
            current < moment().subtract(1, 'day')
        );
    };

    const loadCustomerLearningManagers = async () => {
        setUsersLoading(true);

        try {
            let url = '/api/v1/auth/customer/customer-admins';

            if (userRole === 'SUPER_ADMIN' || userRole === 'RESELLER_ADMIN' || userRole === 'DISTRIBUTOR_ADMIN') {
                url = url + '&customerId=' + owner?.id;
            }

            let response = await apiClient.request(url, [], 'GET');

            setLearningManagers(response.learningManagers);

        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        } finally {
            setUsersLoading(false);
        }
    };

    const submitForm = async (values: any = null) => {
        if (!values) {
            values = form.getFieldsValue();
        }
        setLoading(true);
        const phishingSimulations = Object.values(selectedSimulations).map((simulation: any) => simulation.id);

        if (smartDephishSwitch) {
            const parsedValues = {
                allowDeliveryAfterCampaignHasEnded: values.allowDeliveryAfterCampaignHasEnded,
                campaignId: campaignId,
                gophishTemplatesPagesIds: phishingSimulations,
                maxDeliveryDaysAfterCampaignEnded: values.maxDeliveryDaysAfterCampaignEnded ? values.maxDeliveryDaysAfterCampaignEnded.format('YYYY-MM-DD') : null,
                maxEmailsPerMinute: values.maxEmailsPerMinute,
                sendAfterFailDays: values.sendAfterFailDays,
                sendAfterPassDays: values.sendAfterPassDays,
                sendAfterPass: values.sendAfterPass,
                startAfterDays: values.startAfterDays,
                notifyManager: values.notifyManager,
                escalationManager: values.escalationManager,
                notifyStudent: values.notifyStudent
            };

            let storeResponse;
            if (!phishingSettings && phishingCampaign) {
                storeResponse = await apiClient.request(`/api/v1/courses/phishing-campaign`, parsedValues, 'POST');
            } else if (phishingSettings?.id && phishingCampaign) {
                storeResponse = await apiClient.request(`/api/v1/courses/phishing-campaign/${phishingSettings?.id}`, parsedValues, 'PUT');
            }

            setPhishingSettings(storeResponse.phishingCampaign);
        }

        if (direction === 'previous') {
            previous();
        } else if (direction === 'save') {
            history.push('/courses/manage-campaigns');
        }

        setLoading(false);
    };

    return (
        <Spinner spinning={loading}>
            <Form form={form} onFinish={submitForm}>
                <Tabs>
                    <TabPane tab={intl.formatMessage({ id: 'phishing.main_settings' })} key='main-settings'>
                        <Alert className='smartdephish-alert' message={intl.formatMessage({ id: 'phishing.phishing_enabled_completed_activities' })} type='info' showIcon style={{ marginBottom: 24 }} />
                        <Space style={{ color: 'black', marginBottom: 20 }}>
                            <FormattedMessage id='phishing.smart_dephish' />
                            {status === 'DRAFT' ?
                                <Switch checked={smartDephishSwitch} name='smartDePhishSwitch' onChange={(phish: boolean) => { setSmartDephishSwitch(phish); smartDePhishSwitch(phish) }} />
                                :
                                <Switch checked={phishingEnabledSwitch} name='phishing' onChange={(phish: boolean) => { setPhishingEnabledSwitch(phish); phishingEnabled(phish) }} />
                            }
                        </Space>
                        <div>
                        </div>
                        {!disabled &&
                            <Button className='add-new-template-button' type='primary' onClick={() => setShowPhishingSimulationModal(true)}>
                                <PlusOutlined /> <FormattedMessage id='phishing.add_new' />
                            </Button>
                        }
                        <Table
                            locale={{ emptyText: intl.formatMessage({ id: 'general.found_no_data' }) }}
                            style={{ marginTop: 16, marginBottom: 20 }}
                            rowKey={'index'}
                            columns={
                                columns.filter(ea => ea.dataIndex !== 'index' && ea.dataIndex !== '')
                            }
                            dataSource={selectedSimulations}
                            size='middle'
                            scroll={{ x: 800 }}
                            components={{
                                body: {
                                    wrapper: DraggableContainer,
                                    row: DragableBodyRow,
                                },
                            }}
                        />
                    </TabPane>
                    <TabPane tab={intl.formatMessage({ id: 'phishing.advanced_settings' })} key='advanced-settings' forceRender>
                        <div className='phishing-input-required'>
                            <InputNumber
                                disabled={disabled}
                                name='startAfterDays'
                                label={
                                    <span>
                                        {intl.formatMessage({ id: 'phishing.begin_phishing_simulation_delivery' })}
                                        <Tooltip title={intl.formatMessage({ id: 'phishing.begin_phishing_simulation_hint' })}>
                                            <i className='fal fa-question-circle header-item' />
                                        </Tooltip>
                                    </span>
                                }
                                customRules={[{ required: true, pattern: /^[1-9]\d*$/, message: intl.formatMessage({ id: 'validation.must_be_integer' }) }]}
                                min={1}
                                max={30}
                            />
                        </div>
                        <div className='phishing-input-required'>
                            <InputNumber
                                disabled={disabled}
                                name='sendAfterFailDays'
                                label={
                                    <span>
                                        {intl.formatMessage({ id: 'phishing.if_user_failed_phishing' })}
                                        <Tooltip title={intl.formatMessage({ id: 'phishing.user_failed_phishing_hint' })}>
                                            <i className='fal fa-question-circle header-item' />
                                        </Tooltip>
                                    </span>
                                }
                                customRules={[{ required: true, pattern: /^[1-9]\d*$/, message: intl.formatMessage({ id: 'validation.must_be_integer' }) }]}
                                min={1}
                                max={60}
                            />
                        </div>
                        <div className='phishing-fields'>
                            <Form.Item
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 6 }}
                                name='sendAfterPass'
                                label={intl.formatMessage({ id: 'phishing.if_user_passed_phishing' })}
                            >
                                <Radio.Group
                                    disabled={disabled}
                                    onChange={(el: any) => setUserPassedSimulationTest(el.target.value)}
                                >
                                    <Radio value={'EVENLY'}>
                                        <FormattedMessage id='phishing.evenly_spread' />
                                        <span>
                                            <Tooltip title={intl.formatMessage({ id: 'phishing.evenly_spread_hint' })}>
                                                <i className='fal fa-question-circle header-item' />
                                            </Tooltip>
                                        </span>
                                    </Radio>
                                    <Radio value={'STRICTLY'}>
                                        <FormattedMessage id='phishing.strictly' />
                                        <span>
                                            <Tooltip title={intl.formatMessage({ id: 'phishing.strictly_hint' })}>
                                                <i className='fal fa-question-circle header-item' />
                                            </Tooltip>
                                        </span>
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                            {userPassedSimulationTest === 'STRICTLY' &&
                                <div className='days-input'>
                                    <InputNumber
                                        disabled={disabled}
                                        name='sendAfterPassDays'
                                        label={intl.formatMessage({ id: 'phishing.days' })}
                                        customRules={[{ required: true, pattern: /^[1-9]\d*$/, message: intl.formatMessage({ id: 'validation.must_be_integer' }) }]}
                                        min={1}
                                        max={60}
                                    />
                                </div>
                            }
                        </div>
                        <div className='phishing-fields'>
                            <Form.Item
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 6 }}
                                name='allowDeliveryAfterCampaignHasEnded'
                                label={
                                    <span>
                                        {intl.formatMessage({ id: 'phishing.allow_delivery_after_campaign_ended' })}
                                        <Tooltip title={intl.formatMessage({ id: 'phishing.delivery_after_ended_hint' })}>
                                            <i className='fal fa-question-circle header-item' />
                                        </Tooltip>
                                    </span>
                                }
                            >
                                <Radio.Group
                                    disabled={disabled}
                                    onChange={(el: any) => {
                                        setPhishingDeliveryAfterCampaignEnded(el.target.value);
                                        form.setFieldsValue({ maxDeliveryDaysAfterCampaignEnded: moment(moment(campaign?.deadline).format()).add('60', 'days') })
                                    }}>
                                    <Radio value={true}><FormattedMessage id='phishing.yes' /></Radio>
                                    <Radio value={false}><FormattedMessage id='phishing.no' /></Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                        {phishingDeliveryAfterCampaignEnded &&
                            <div className='datepicker-display-form-items'>
                                <div className='datepicker-display-form'>
                                    <DatePicker
                                        disabled={disabled}
                                        disabledDate={(current: any) => disabledDates(current)}
                                        name='maxDeliveryDaysAfterCampaignEnded'
                                        label={
                                            <span>
                                                {intl.formatMessage({ id: 'phishing.phishing_delivery_after_campaign_ended' })}
                                                <Tooltip title={intl.formatMessage({ id: 'phishing.after_campaign_ended_hint' })}>
                                                    <i className='fal fa-question-circle header-item' />
                                                </Tooltip>
                                            </span>
                                        }
                                        format={'DD.MM.YYYY'}
                                    />
                                </div>
                            </div>
                        }
                        <div className='phishing-input-required'>
                            <InputNumber
                                disabled={disabled}
                                name='maxEmailsPerMinute'
                                label={intl.formatMessage({ id: 'phishing.maximum_emails_per_minute' })}
                                customRules={[{ required: true, pattern: /^[1-9]\d*$/, message: intl.formatMessage({ id: 'validation.must_be_integer' }) }]}
                                min={1}
                                max={60}
                            />
                        </div>
                        <div className='phishing-fields'>
                            <Form.Item
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 6 }}
                                name='notifyManager'
                                label={
                                    <span>
                                        {intl.formatMessage({ id: 'phishing.notify_manager_on_fail' })}
                                        <Tooltip title={intl.formatMessage({ id: 'phishing.notify_manager_on_fail_hint' })}>
                                            <i className='fal fa-question-circle header-item' />
                                        </Tooltip>
                                    </span>
                                }
                            >
                                <Radio.Group disabled={status === 'FINISHED'} onChange={(event: any) => setEscalationManager(event.target.value)}>
                                    <Radio value={true}><FormattedMessage id='phishing.yes' /></Radio>
                                    <Radio value={false}><FormattedMessage id='phishing.no' /></Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                        {escalationManager &&
                            <Form.Item
                                {...{ labelCol: { span: 8 }, wrapperCol: { span: 6 } }}
                                name='escalationManager'
                                rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}

                                label={intl.formatMessage({ id: 'phishing.escalation_manager' })}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    notFoundContent='No customer users found'
                                    disabled={status === 'FINISHED'}
                                    style={{ height: '100%', width: '100%', borderRadius: 8 }}
                                    loading={usersLoading}
                                    dropdownStyle={{ minWidth: '400px' }}
                                >
                                    {learningManagers &&
                                        Object.values(learningManagers).map((el: any) => {
                                            return <Select.Option key={el.userTypeUuid} value={el.userTypeUuid}>{`${el.name} ${el.surname} (${el.email})`}</Select.Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        }
                        <div className='phishing-fields'>
                            <Form.Item
                                labelCol={{ span: 8 }}
                                wrapperCol={{ span: 6 }}
                                name='notifyStudent'
                                label={
                                    <span>
                                        {intl.formatMessage({ id: 'phishing.notify_student_on_fail' })}
                                        <Tooltip title={intl.formatMessage({ id: 'phishing.notify_student_on_fail_hint' })}>
                                            <i className='fal fa-question-circle header-item' />
                                        </Tooltip>
                                    </span>
                                }
                            >
                                <Radio.Group disabled={status === 'FINISHED'}>
                                    <Radio value={true}><FormattedMessage id='phishing.yes' /></Radio>
                                    <Radio value={false}><FormattedMessage id='phishing.no' /></Radio>
                                </Radio.Group>
                            </Form.Item>
                        </div>
                    </TabPane>
                </Tabs>
            </Form>
            <PhishingSimulationsModal
                selectedSimulations={selectedSimulations}
                setSelectedSimulations={setSelectedSimulations}
                visible={showPhishingSimulationModal}
                onCancel={() => setShowPhishingSimulationModal(false)}
                setPhishingSimulations={setPhishingSimulations}
                setLoading={setLoading}
                loading={loading}
            />

            <PhishingTestEmailModal
                customerId={owner?.id}
                selectedTestSimulation={selectedTestSimulation}
                visible={sendTestPhishingEmailModal}
                onCancel={() => setSendTestPhishingEmailModal(false)}
                afterSubmit={() => setSendTestPhishingEmailModal(false)}
            />

            <DefaultLayout.PageFooterWithRow
                left={
                    <>
                        {newCampaignStatus &&
                            <>
                                <Button loading={loading} onClick={() => { form.submit(); setDirection('previous'); }}>
                                    <FormattedMessage id='campaign.previous' />
                                </Button>
                            </>
                        }
                    </>
                }
                right={
                    <>
                        {!newCampaignStatus &&
                            <Button onClick={() => { history.push('/courses/manage-campaigns'); }}>
                                <FormattedMessage id='general.back' />
                            </Button>
                        }
                        <Button loading={loading} onClick={() => { form.submit(); setDirection('save'); }} className='save-button'>
                            <i className='fal fa-save header-item' />
                            <FormattedMessage id={status === 'DRAFT' ? 'campaign.save_as_draft' : 'general.save'} />
                        </Button>
                        {!newCampaignStatus &&
                            launchButton(submitForm)
                        }
                    </>
                }
            />
        </Spinner>
    )
};

export default connect(mapStateToProps)(withRouter(PhishingSettingsTabs));
