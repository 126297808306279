import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {message, Modal} from 'antd';
import apiClient from 'utils/apiClient';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import config from 'config/config';
import {Select} from 'components/Form';

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
};

const ContentCreateModal = ({history, visible, onCancel}: any) => {
    const [serviceTypesList, setServiceTypesList] = useState<any>({});
    const [serviceTypesLoading, setServiceTypesLoading] = useState(false);
    const [systemServiceType, setSystemServiceType] = useState<any>(null);

    const intl = useIntl();

    useEffect(() => {
        const loadServiceTypes = async () => {
            try {
                setServiceTypesLoading(false);

                const serviceTypes = await apiClient.request(config.api.routes.enums.licenceServiceTypes, {}, 'GET');

                let types: any = {};
                Object.keys(serviceTypes).map((el: string) => {
                    if (el === 'E_LEARNING_CONTENT' || el === 'PLATFORM_ACCESS' || el === 'PHISHING_SERVICE' || el ==='APPEARANCE_SERVICE') {
                        types[el] = intl.formatMessage({id: `licensing.content.${el}`});
                    }
                    return types;
                })

                setServiceTypesList(types);
            } catch (error) {
                console.error(error);
                message.error(intl.formatMessage({id: 'error.data_load'}));
            } finally {
                return setServiceTypesLoading(false);
            }
        };

        loadServiceTypes();
    }, [intl]);

    return (
        <>
            <Modal
                title={intl.formatMessage({id: 'licensing.content.create_content'})}
                visible={visible}
                onCancel={onCancel}
                onOk={() => history.push(`/content/add/${systemServiceType}`)}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
                okText={intl.formatMessage({id: 'general.create'})}
            >
                <Select
                    customLayout={formItemLayout}
                    name="type"
                    label={intl.formatMessage({id: 'licensing.service_category'})}
                    validation={{required: true}}
                    manualOptions={serviceTypesList}
                    loading={serviceTypesLoading}
                    onChange={(value: any) => setSystemServiceType(value)}
                />
            </Modal>
        </>
    )
};

export default connect(mapStateToProps)(withRouter(ContentCreateModal));
