import React, { useState, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Form, Button, Alert } from 'antd';
import { Input} from 'components/Form';
import apiClient from 'utils/apiClient';
import { EnvironmentType } from '../../types';
import Spinner from 'components/Spinner';
import DefaultLayout from 'components/DefaultLayout';

interface SMSSettingsTabProps {
    type: EnvironmentType;
    selectedResellerId: number;
    selectedCustomerId: number;
  }

const SMSSettingsTab: React.FC<SMSSettingsTabProps> = ({ type, selectedResellerId, selectedCustomerId }) => {
  const [emailSettings, setEmailSettings] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const intl = useIntl();
  const [form] = Form.useForm();

  useEffect(() => {
    const loadEmailSettings = async () => {
      setLoading(true)
      try {
        let emailSettingsResponse = await apiClient.request(`api/v1/customize-environment/email-settings/${type}/${selectedCustomerId ? selectedCustomerId : selectedResellerId}`, {}, 'GET');  
        setEmailSettings(emailSettingsResponse.emailSettings);
        form.setFieldsValue(emailSettingsResponse.emailSettings);
      } catch {
        setLoading(false);
      }

     setLoading(false);
    }

    loadEmailSettings()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedResellerId, selectedCustomerId]);

  const submitForm = async (values:any) => {

    setIsSubmitting(true)
    let parsedValues = {
      ...values,
      ownerType: type,
      ownerId: selectedCustomerId ? selectedCustomerId : selectedResellerId
    }
    
    if (emailSettings.id) {
      await apiClient.request(`api/v1/customize-environment/email-settings/${emailSettings.id}`, parsedValues, 'PUT');
    } else {
      await apiClient.request(`api/v1/customize-environment/email-settings`, parsedValues, 'POST');
    }

    setIsSubmitting(false);
  }

  return (
    <>
      <Spinner spinning={loading || isSubmitting}>
        {
          selectedCustomerId || selectedResellerId || type === 'GLOBAL' ? 
            <>
              <Form form={form} onFinish={submitForm}>
                <Input name="smsSender" label={intl.formatMessage({id: 'system.environment.sms_sender'})} validation={{required: true}} />
              </Form>
              <DefaultLayout.PageFooterWithRow
                right={
                  <Button
                    type="primary"
                    loading={isSubmitting}
                    onClick={form.submit}
                  >
                    <span><FormattedMessage id="general.save" /></span>
                  </Button>
                }
              />
            </>
          :
            <Alert
              message={intl.formatMessage({ id: `system.environment.please_select_a_${type === 'RESELLER' ? 'reseller' : 'customer'}` })}
              type="info"
              showIcon
            />
        }
      </Spinner>

    </>
  );
}

export default SMSSettingsTab;