import React, {useState} from "react";
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import Spinner from 'components/Spinner';
import { Form, Button, Layout, Row, Col, Card, message, Spin } from 'antd';
import AppearanceStyler from "components/NoAuthHelper/AppearanceStyler";
import { changeLocale } from 'store/actions/locale';
import { Input } from 'components/Form';
import apiClient from 'utils/apiClient';
import '../styles.scss';

const { Header, Footer, Content } = Layout;

const mapStateToProps = (state: any) => {
  return {
    locale: state.locale.locale,
    languageDictionary : state.languageDictionary,
    title: state.appearanceSettings.title,
    logoPath: state.appearanceSettings.logoPath,
    appearanceSettingsLoading: state.appearanceSettings.loading,
    globalTheme: state.publicAppearanceReducer
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  changeLocale: (locale: string) => dispatch(changeLocale(locale))
})

const ResetConfirmPasswordForm: React.FC = ({ locale, changeLocale, history, languageDictionary, globalTheme}: any) => {
  const intl = useIntl();
  const token = window.location.pathname.split("/").pop();
  const [inProgress, setInProgress] = useState(false);
  const [appearanceIsLoading, setAppearanceIsLoading] = useState(!!!globalTheme);

  const [inputTextLength, setInputTextLength] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [inputConfirmTextLength, setInputConfirmTextLength] = useState(0);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const submitForm = async (values: any) => {
    setInProgress(true);
    try {
      let parsedValues = {
        ...values,
        password: values['password'],
        password_confirmation: values['password_confirmation'],
      }

      await apiClient.request(`/api/v1/reset-password/${token}`, parsedValues, 'PUT');
      setInProgress(false);
      message.success(intl.formatMessage({id: "login.password_successfully_changed"}));
      history.push('/organizations');
    } catch (error) {
      message.error(intl.formatMessage({id: "login.token_is_not_valid"}));
      console.error(error);
      setInProgress(false);
    }
  }

  const suffix = (field: 'password' | 'confirm' ) => {

    if (field === 'password') {
      return (
        showPassword ?
        <i style={{width:20}} onClick={() => setShowPassword(false)} className="far fa-eye-slash"></i>
        :
        <i style={{width:20}} onClick={() => setShowPassword(true)} className = "far fa-eye"></i>
      )
    } else {
      return (
        showConfirmPassword ?
        <i style={{width:20}} onClick={() => setShowConfirmPassword(false)} className="far fa-eye-slash"></i>
        :
        <i style={{width:20}} onClick={() => setShowConfirmPassword(true)} className = "far fa-eye"></i>
      )
    }
  };

  return (
    <Spinner spinning={appearanceIsLoading} opaque>
      <Layout style={{ minHeight: '100vh' }}>
        <AppearanceStyler setAppearanceIsLoading={setAppearanceIsLoading}/>
        <Header style={{ backgroundColor: globalTheme && globalTheme.theme.themeBackground ? globalTheme.theme.themeBackground : '#463368' }} />
        <Content style={{ backgroundColor: globalTheme && globalTheme.theme.themeBackground ? globalTheme.theme.themeBackground : '#463368', display: 'flex', flexDirection: 'column' }}>
            <div className="password-reset-wrapper">
              <Card className="login-card">
                <Spin spinning={appearanceIsLoading} size="small">
                  {globalTheme && globalTheme.logo 
                    ? <img className="cs-login-logo" src={globalTheme.logo ? URL.createObjectURL(globalTheme.logo) : undefined} alt={globalTheme.title} />
                    : <img className="cs-login-logo" src="/cs-logo.svg" alt={'CloudStudy'} />
                  }
                </Spin>
                <p className="please-login-text">
                  <FormattedMessage id="login.forgot_your_password" />
                </p>
                <p className="reset-password-text">
                  <FormattedMessage id="login.well_help_you_reset" />
                </p>

                <Form className="login-form" onFinish={submitForm}>
                  <Input
                    name="password"
                    type={showPassword ? "text" : "password"}
                    prefix={<i className=" fal fa-lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={intl.formatMessage({id: "login.password"})}
                    customLayout={true}
                    onChange={(event: any) => setInputTextLength(event.target.value.length)}
                    customRules={[
                      { required: true, message: intl.formatMessage({id: "validation.field_required"}) },
                      { pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, message: intl.formatMessage({id: "validation.password_pattern"}) }
                    ]}
                      suffix={inputTextLength > 0 && suffix('password')}
                  />
                  <Input
                    name="password_confirmation"
                    type={showConfirmPassword ? "text" : "password"}
                    prefix={<i className=" fal fa-lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={intl.formatMessage({id: "login.confirm_password"})}
                    customLayout={true}
                    onChange={(event: any) => setInputConfirmTextLength(event.target.value.length)}
                    customRules={[
                      { required: true, message: intl.formatMessage({id: "validation.field_required"}) },
                      ({ getFieldValue }: any) => ({
                        validator(rule: any, value: any) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(intl.formatMessage({id: "validation.repeat_password"}));
                        },
                      })
                    ]}
                    suffix={inputConfirmTextLength > 0 && suffix('confirm')}
                  />
                  <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button" loading={inProgress} style={{marginTop: '5px', marginBottom: '10px'}}>
                      <FormattedMessage id="login.reset_password" />
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
          </div>
          <Row justify="center" align="top" style={{flex: 1}}>
            {
              Object.entries(languageDictionary).map((entries:any) => {
                if (entries[0] !== 'loading' && entries[0] !== 'failed') {
                  return (
                    <Button className={"locale-switch" + (locale === entries[0] ? " active" : "")} onClick={() => changeLocale(entries[0])}>{entries[0]}</Button>
                  )
                }
                return null;
              })
            }
          </Row>
        </Content>
        <Footer style={{ backgroundColor: globalTheme && globalTheme.theme.themeBackground ? globalTheme.theme.themeBackground : '#463368', textAlign: 'center' }}>
          <ul className="footer-list">
            <li>© CloudStudy Ltd</li>
            <li><FormattedMessage id="login.terms" /></li>
            <li><FormattedMessage id="login.terms_and_conditions" /></li>
            <li><FormattedMessage id="login.cookies" /></li>
          </ul>
        </Footer>
      </Layout>;
    </Spinner>
  )

}

export default connect(mapStateToProps, mapDispatchToProps)(ResetConfirmPasswordForm);
