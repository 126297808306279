import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { message, Modal, Form, Table, Tag } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import coursesApiClient from 'utils/coursesApiClient';
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
        locale: state.locale.locale
    }
};

const EnrollUsersIntoCampaignModal: React.FC<any> = ({
    rowSelection,
    visible,
    organizationType,
    onCancel,
    uuid,
    id
}) => {
    const [campaigns, setCampaigns] = useState<any>();
    const [selectedCampaigns, setSelectedCampaigns] = useState<any>();
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();
    const intl = useIntl();

    useEffect(() => {
        if (visible && organizationType === 'CUSTOMER' && uuid) {
            loadCampaigns();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])

    const loadCampaigns = async () => {
        try {
            let response = await coursesApiClient.request(`/api/v1/courses/customer/${uuid}/filter-campaigns`, { status: 'in:ONGOING,DRAFT', includedCourses: [] }, 'POST');
            setCampaigns(response.campaigns);
        } catch (error) {
            message.error(intl.formatMessage({ id: 'error.data_load' }));
            console.error(error);
        }
    }

    const submitForm = async (values: any) => {
        setLoading(true);

        try {
            let parsedValues = {
                campaignsId: selectedCampaigns,
                users: rowSelection
            }
            await coursesApiClient.request(`/api/v1/customers/${id}/enroll-students`, parsedValues, 'POST');
            onCancel();
        } catch (error) {
            message.error(intl.formatMessage({ id: 'error.data_load' }));
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    const columns = [
        {
            width: 65,
            render: (record: any) => {
                return (
                    <div className='content-column'>
                        {
                            record.campaignThumbnail ? <i className={`fal ${record.campaignThumbnail}`} /> : <i className="fal fa-book"></i>
                        }
                    </div>
                );
            }
        },
        {
            title: intl.formatMessage({ id: 'general.title' }),
            dataIndex: 'name',
            key: 'name',
            width: '15%',
            render: (_text: string, record: any) => {
                return (record.name)
            }
        },
        {
            title: intl.formatMessage({ id: 'general.begin_date' }),
            dataIndex: 'beginDate',
            key: 'beginDate',
            sorter: (firstEntry: any, secondEntry: any) => moment(firstEntry.beginDate).unix() - moment(secondEntry.beginDate).unix(),
            render: (_text: string, record: any) => record.beginDate ? (moment(record.beginDate).format('DD.MM.YYYY')) : '-'
        },
        {
            title: intl.formatMessage({ id: 'general.end_date' }),
            dataIndex: 'deadline',
            key: 'deadline',
            sorter: (firstEntry: any, secondEntry: any) => moment(firstEntry.deadline).unix() - moment(secondEntry.deadline).unix(),
            render: (_text: string, record: any) => record.deadline ? moment(record.deadline).format('DD.MM.YYYY') : '-'
        },
        {
            title: intl.formatMessage({ id: 'campaign.users_enrolled' }),
            dataIndex: 'studentsCount ',
            render: (_text: string, record: any) => {
                return record.studentsCount;
            }
        },
        {
            title: intl.formatMessage({ id: 'general.status' }),
            dataIndex: 'status',
            key: 'status',
            render: (_text: string, record: any, tag: any) => {
                let color;
                if (record.status) {

                    if (record.status === 'DRAFT') color = 'gold';
                    if (record.status === 'ONGOING') color = 'blue';
                    return (
                        <Tag color={color} key={tag} className={record.status}>
                            {intl.formatMessage({ id: `campaign.status.${record.status}` })}
                        </Tag>
                    );
                }
            }
        }
    ];

    return (
        <Form form={form} onFinish={submitForm}>
            <Modal
                className='scenarios-modal'
                visible={visible}
                onCancel={onCancel}
                onOk={() => { form.submit(); }}
                confirmLoading={loading}
                okText={intl.formatMessage({ id: 'campaign.enroll' })}
                title={intl.formatMessage({ id: 'campaign.user_enrollment_into_campaign' })}
                width={'75%'}
            >
                <Table
                    columns={columns}
                    dataSource={campaigns}
                    rowSelection={{ onChange: setSelectedCampaigns }}
                    rowKey={(record) => record.id}
                    size="small"
                />
            </Modal>
        </Form>
    )
};

export default connect(mapStateToProps)(withRouter(EnrollUsersIntoCampaignModal));
