import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {message, Form, Tabs, Button, Modal} from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import CustomPhishingCampaignSettings from 'components/Phishing/CustomPhishingCampaignSettings';
import { changePhishingCampaign } from 'store/actions/phishingCampaignActions';
import UsersForPhishing from 'components/Phishing/UsersForPhishing';
import CustomPhishingCampaignResulsts from 'components/Phishing/CustomPhishingCampaignResulsts';
import CustomPhishingUsersResults from 'components/Phishing/CustomPhishingUsersResults';
import Spinner from 'components/Spinner';
import moment from 'moment';
import generalHelper from 'utils/generalHelpers';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
      campaignType: state.phishingCampaignReducer,
      session: state.session
    };
  };

  const mapDispatchToProps = (dispatch: any) => ({
    changePhishingCampaign: (fromPhishingView: any) => dispatch(changePhishingCampaign(fromPhishingView)),
  });

const CustomPhishingCampaignForm: React.FC = ({history, session, match, campaignType, changePhishingCampaign}: any) => {
    const [phishingCampaignType, setPhishingCampaignType] = useState<string>();
    const [selectedSimulations, setSelectedSimulations] = useState<any>();
    const [customerUserList, setCustomerUserList] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState<any>([]);
    const [customPhishishingCampaign, setCustomPhishishingCampaign] = useState();
    const [newCampaignId, setNewCampaignId] = useState();
    const [campaignStatus, setCampaignStatus] = useState('');
    const [activeTab, setActiveTab] = useState<string>('0');
    const [isSettingsLoading, setIsSettingsLoading] = useState(false);
    const [isUsersLoading, setIsUsersLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [disabledFields, setDisabledFields] = useState(false);
    const [customerId, setCustomerId] = useState(false);
    const [customerUuid, setCustomerUuid] = useState(false);
    const [disabledMainFields, setDisabledMainFields] = useState(false);
    const [campaignEmailingType, setCampaignEmailingType] = useState();
    const [phishingSettings, setPhishingSettings] = useState<any>();
    const [campaignCreateLoader, setCampaignCreateLoader] = useState(false);
    const [totalUsers, setTotalUsers] = useState(0);
    const [campaignTitle, setCampaignTitle] = useState();

    const [selectAllFiltered, setSelectAllFiltered] = useState(false);
    const [selectedRowsLength, setSelectedRowsLength] = useState<number>(0);
    const [rowSelection, setRowSelection] = useState<any>({
        filter: {},
        selectedAllUsers: false,
        selectedUsers: [],
        deSelectedUsers: [],
    });
    const pageSizeOptions = [30, 50];
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [users, setUsers] = useState<any>([]);
    const [currentPageSize, setCurrentPageSize] = useState<number>(pageSizeOptions[0]);



    const [usersToAdd, setUsersToAdd] = useState({filter:{}, distinctUsers:[], removedUsers:[]});

    const [usersPhishingResults, setUsersPhishingResults] = useState<any>();
    const [isPhishTemplateLoading, setIsPhishTemplateLoading] = useState(false);
    const [tableSpinner, setTableSpinner] = useState(false);
    const [campaignEndDate, setCampaignEndDate] = useState<any>();

    const [escalationManager, setEscalationManager] = useState(false);

    const intl = useIntl();
    const [form] = Form.useForm();
    const [usersForm] = Form.useForm();
    const campaignId = match.params.id;
    const { TabPane } = Tabs;
    const { confirm } = Modal;

    useEffect(() => {

        if (session.active_user_type === 'CUSTOMER_ADMIN') {
            if (!session.access.phishing.allow) {
                history.push('/403');
            }
        }

        if (session.active_user_type === 'SUPER_ADMIN' && campaignType.type === null) {
            message.error(intl.formatMessage({id: "validation.customer_not_selected"}));
            history.push('/phishing/manage-campaigns');
        } else {
            let customerId;
            let customerUuid;
            if (campaignType.customerId) {
                customerId = campaignType.customerId;
                customerUuid = campaignType.customerUuid;
            } else {
                customerId = session.organization.organization_id;
                customerUuid = session.organization.organization_uuid;
            }

            setCustomerId(customerId);
            setCustomerUuid(customerUuid);
        }

        if (session.active_user_type === 'SUPERVISION') {
            setActiveTab('2');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    useEffect(()=>{
        const setFieldValues = () => {
            setIsLoading(true);
            setIsSettingsLoading(true);
            let campaignTypeTemp;
            if (campaignType.type) {
                campaignTypeTemp = campaignType.type;
                setPhishingCampaignType(campaignType.type);
                form.setFieldsValue({
                    phishingCampaignType: campaignTypeTemp
                });
            } else {
                campaignTypeTemp = 'DYNAMIC';
                setPhishingCampaignType('DYNAMIC');
                form.setFieldsValue({
                    phishingCampaignType: 'DYNAMIC'
                });
            }

            if (phishingCampaignType) {
                changePhishingCampaign({type :null})
            }

            if (campaignTypeTemp === 'STRICTLY') {
                form.setFieldsValue({
                    phishDelivery:'RANDOMIZED',
                    deliverPhishingFrom: '9',
                    deliverPhishingTo: '18',
                    mailPerMinute: 1
                })
            } else if (campaignTypeTemp === 'DYNAMIC') {
                form.setFieldsValue({
                    phishDelivery:'RANDOMIZED',
                    deliverPhishingFrom: '9',
                    deliverPhishingTo: '18',
                    mailPerMinute: 1,
                    startAfterDays: 7,
                    sendAfterFailDays: 7,
                    sendAfterPass: 'EVENLY'
                })
            }

            setEscalationManager(false);

            form.setFieldsValue({
                notifyManager: false,
                notifyStudent: false
            })

            setCampaignStatus('DRAFT');
            setIsLoading(false);

            setIsSettingsLoading(false);
        };

        const loadPhishingCampaign = async () => {
            setIsLoading(true);
            setIsSettingsLoading(true);
            let phishingCampaign;

            try {
                phishingCampaign = await coursesApiClient.request(`/api/v1/courses/custom-phishing/phishing-campaign/${campaignId}`, [] , 'GET');
            } catch (error: any) {
                if (error && error.status === 403) {
                    history.push('/403');
                    return;
                }
                console.error(error);

            }
            let campaign = phishingCampaign.customPhishingCampaign;
            setCustomPhishishingCampaign(campaign);
            setCampaignTitle(campaign.name);

            setPhishingCampaignType(campaign.phishingCampaignType);
            setCampaignStatus(campaign.status);

            if (campaign.status === 'ONGOING' && campaign.phishing) {
                setDisabledFields(true);
            }

            if (campaign.status === 'ONGOING' && campaign.phishingCampaignType === 'STRICTLY') {
                setActiveTab('2');
                setDisabledMainFields(true);
            }

            if (campaign.status === 'FINISHED') {
                setDisabledMainFields(true);
            }

            form.setFieldsValue({
                phishDelivery: campaign.phishDelivery,
                deliverPhishingFrom: campaign.deliveryFrom,
                deliverPhishingTo: campaign.deliveryTo,
                mailPerMinute: campaign.mailPerMinute,
                startAfterDays: campaign.startAfterDays,
                sendAfterFailDays: campaign.sendAfterFailDays,
                sendAfterPass: campaign.sendAfterPass,
                phishingCampaignName: campaign.name,
                deadline: moment(campaign.deadline),
                phishingCampaignType: campaign.phishingCampaignType,
                notifyManager: !!campaign.notifyManager,
                notifyStudent: !!campaign.notifyStudent,
                escalationManager: campaign.escalationManager
            });
            setEscalationManager(!!campaign.notifyManager);

            if (campaign.phishingCampaignType === 'DYNAMIC') {
                setCampaignEmailingType(campaign.sendAfterPass);

                setPhishingSettings({
                    startAfterDays: campaign.startAfterDays,
                    sendAfterFailDays: campaign.sendAfterFailDays,
                    sendAfterPass: campaign.sendAfterPass,
                    sendAfterPassDays: campaign.sendAfterPassDays
                });

                form.setFieldsValue({
                    startAfterDays: campaign.startAfterDays,
                    sendAfterFailDays: campaign.sendAfterFailDays,
                    sendAfterPass: campaign.sendAfterPass,
                    sendAfterPassDays: campaign.sendAfterPassDays
                })
            }
            setIsSettingsLoading(false);
            setIsLoading(false);
        };

        const loadSelectedPhishingSimulations = async () => {
            setIsSettingsLoading(true);
            let response;
            try {
                response = await coursesApiClient.request(`/api/v1/courses/custom-phishing/simulations/${campaignId}`, [], 'GET');
            } catch (error: any) {
                if (error && error.status === 403) {
                    return;
                }
                console.error(error);
            }

            let i = 0;
            let simulations:any = [];
            Object.entries(response.simulations).map((el:any)=> {
              simulations.push(el[1]);
              if (el[1].datePicker && campaignStatus !=='DRAFT') {
                if (moment(el[1].datePicker) < moment()) {
                    el[1].disabled = true;
                } else {
                    el[1].disabled = false;
                }
            }
              return el[1].index = (i = i + 1)
            });

            setSelectedSimulations(simulations);
            setIsSettingsLoading(false);
          };

        if (campaignId && customerId) {
            loadPhishingCampaign();
            loadSelectedPhishingSimulations();
        } else {
            setFieldValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[customerId]);

    useEffect(()=>{
        if (campaignId && customerId && campaignStatus !== 'DRAFT' && campaignStatus !== '') {
            loadUsers()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [campaignStatus, campaignId] );

    const loadUsers = async () => {
        setIsPhishTemplateLoading(true);
        setTableSpinner(true);
        let usersResults;
        try {
            usersResults = await apiClient.request(`/api/v1/auth/customer/${customerId}/custom-phishing/users-results/${campaignId}`, {}, 'GET');
        } catch (error: any) {
            if (error && error.status_code === 403) {
                return;
            }
            message.error(intl.formatMessage({id: "error.data_load"}));
            if (error && error.status_code === 403) {
                return;
            }
        }
        setUsersPhishingResults(usersResults.userData);
        setTableSpinner(false);
        setIsPhishTemplateLoading(false);
    };

    const next = async () => {
        let values = form.getFieldsValue();

        if (moment(values.deliverPhishingFrom) > moment(values.deliverPhishingTo)) {
            return message.error(intl.formatMessage({id: "validation.invalid_time"}));
        }

        if (phishingCampaignType === 'DYNAMIC' && !values.deadline) {
            return message.error(intl.formatMessage({id: "validation.pick_campaign_deadline"}));
        }

        if (!values.phishingCampaignName) {
            return message.error(intl.formatMessage({id: "validation.input_cmapaign_name"}));
        }

        if (!selectedSimulations ) {
            return message.error(intl.formatMessage({id: "validation.selet_phishing_templates"}));
        }

        if (phishingCampaignType === 'STRICTLY') {
            if (selectedSimulations && selectedSimulations[0].datePicker ) {
                if (!newCampaignId && !campaignId) {
                    submitForm();
                }
                setActiveTab((parseInt(activeTab) + 1).toString());
            } else {
                message.error(intl.formatMessage({id: "validation.pick_template_dates"}))
            }
        } else {
            if (!newCampaignId && !campaignId) {
                submitForm();
            }
            setActiveTab((parseInt(activeTab) + 1).toString());
        }
    };

    const prev = () => {
        setActiveTab((parseInt(activeTab) - 1).toString());
    };

    const submitForm = async () => {
        let values = form.getFieldsValue();

        if (moment(values.deliverPhishingFrom) > moment(values.deliverPhishingTo)) {
            return message.error(intl.formatMessage({id: "validation.invalid_time"}));
        }

        if (!values.phishingCampaignName) {
            return message.error(intl.formatMessage({id: "validation.input_cmapaign_name"}));
        }

        let phishingSimulations:any = [];
        Object.values(selectedSimulations).map((el:any)=> {
            return phishingSimulations.push(el.id)
        });

        let parsedValues: any = {
            name: values.phishingCampaignName,
            phishingCampaignType: phishingCampaignType,
            status: campaignStatus,
            deadline:  moment(values.deadline).format('YYYY-MM-DD'),
            deliveryFrom: values.deliverPhishingFrom,
            deliveryTo:  values.deliverPhishingTo,
            mailPerMinute: values.mailPerMinute,
            phishDelivery: values.phishDelivery,
            gophishTemplatesPagesIds: phishingSimulations.join(),
            customerUuid: customerUuid,
            notifyManager: values.notifyManager,
            notifyStudent: values.notifyStudent,
            escalationManager: values.escalationManager,
        };

        if (phishingCampaignType === 'DYNAMIC') {
            parsedValues['sendAfterPass'] = values.sendAfterPass ? values.sendAfterPass : 'EVENLY';
            parsedValues['sendAfterFailDays'] = values.sendAfterFailDays ? values.sendAfterFailDays : 7;
            parsedValues['startAfterDays'] = values.startAfterDays ? values.startAfterDays : 7;
            if (values.sendAfterPass === 'STRICTLY') {
                parsedValues['sendAfterPassDays'] = values.sendAfterPassDays;
            }
        }

        if (phishingCampaignType === 'STRICTLY') {
            let phishingScheduler:any = {};
            Object.values(selectedSimulations).map((el:any)=> {
                if (el.datePicker) {
                    let date = moment(el.datePicker).format('DDMMYYYY');
                    if (phishingScheduler[date] !== undefined) {
                        phishingScheduler[date] = phishingScheduler[date] + ',' + el.id;
                    } else {
                        phishingScheduler[date] = el.id;
                    }
                }
                return phishingScheduler;
            });
            parsedValues['phishingScheduler'] = phishingScheduler
        }

        if (phishingCampaignType === 'STRICTLY' &&  Object.keys(parsedValues['phishingScheduler']).length === 0) {
            return message.error(intl.formatMessage({id: "validation.date_not_selected"}));
        }

        if (campaignId) {
            setCampaignCreateLoader(true);
            let phishingCampaign = await coursesApiClient.request(`/api/v1/courses/custom-phishing/phishing-campaign/${campaignId}`, parsedValues, 'PUT');
            message.success(intl.formatMessage({id: "phishing.campaign_saved"}));
            history.push(`/customer/${customerId}/phishing/campaign/${phishingCampaign.customPhishingCampaign.id}/edit`);

            setCampaignCreateLoader(false);
        } else {
            setCampaignCreateLoader(true);
            let phishingCampaign = await coursesApiClient.request('/api/v1/courses/custom-phishing/phishing-campaign', parsedValues, 'POST');
            setNewCampaignId(phishingCampaign.customPhishingCampaign.id);
            setCampaignStatus(phishingCampaign.customPhishingCampaign.status);
            message.success(intl.formatMessage({id: "phishing.campaign_saved"}));
            history.push(`/customer/${customerId}/phishing/campaign/${phishingCampaign.customPhishingCampaign.id}/edit`);

            setCampaignCreateLoader(false);
            return phishingCampaign.customPhishingCampaign.id;
        }
    };

    const submitUsersForm = async () => {

        if (totalUsers > session.access.phishing.max_users) {
            message.warn(intl.formatMessage({id: 'phishing.to_many_users'}));
            return;
        }

        if (totalUsers > 0) {

            let parsedValues = {
                usersToAdd,
                campaignId: campaignId
            };

            setCampaignCreateLoader(true);
            await coursesApiClient.request(`/api/v1/customers/${customerId}/add-phishing-enrollments`, parsedValues, 'POST');
            setCampaignCreateLoader(false);
        }
    };

    const launchCampaign = async () => {
        let launchId;
        let id = await submitForm();
            await submitUsersForm();

        if (campaignId) {
            launchId = campaignId;
        } else if (newCampaignId) {
            launchId = newCampaignId;
        } else if (id) {
            launchId = id;
        }

        try {
            setCampaignCreateLoader(true);
            await apiClient.request(`/api/v1/courses/custom-phishing/phishing-campaign/${ launchId }/launch`, {}, 'POST');
            message.success(intl.formatMessage({id: "campaign.campaign_succesfully_launched"}));
            setCampaignCreateLoader(false);
        } catch (error) {
          console.error(error);
          message.error(intl.formatMessage({id: "launch_error"}));
        } finally {
          history.push('/phishing/manage-campaigns');
        }
      };

      useEffect(()=>{
          if (selectedSimulations) {
            let values = form.getFieldsValue();
            let addDays;
            let sendingHours = parseInt(values.deliverPhishingTo) - parseInt(values.deliverPhishingFrom);

            if (totalUsers) {
                if (phishingCampaignType === 'DYNAMIC') {
                    if (campaignEmailingType === 'EVENLY') {
                        let deadline = moment(values.deadline).add(values.startAfterDays, 'days');
                        addDays = deadline.diff(moment(), 'days');

                        if (totalUsers/(sendingHours * 60 * values.mailPerMinute) > 1 ) {
                            addDays = addDays + (sendingHours * 60 * values.mailPerMinute)/totalUsers;
                        }

                        setCampaignEndDate(moment().add(addDays, 'days').format('DD.MM.YYYY'));

                    } else if (campaignEmailingType === 'STRICTLY') {
                        addDays = values.startAfterDays + values.sendAfterPassDays * selectedSimulations.length;
                        if (totalUsers/(sendingHours * 60 * values.mailPerMinute) > 1 ) {
                            addDays = addDays + (sendingHours * 60 * values.mailPerMinute)/totalUsers;
                        }
                        setCampaignEndDate(moment().add(addDays, 'days').format('DD.MM.YYYY'));
                    }
                }
                if (phishingCampaignType === 'STRICTLY') {
                    let dates:any = [];
                    Object.values(selectedSimulations).map((el:any)=>{
                        return dates.push(new Date(moment(el.datePicker).format('YYYY-MM-DD')));
                    });

                    if (totalUsers/(sendingHours * 60 * values.mailPerMinute) > 1 ) {
                        addDays = addDays + (sendingHours * 60 * values.mailPerMinute)/totalUsers;
                    }

                    let latest = new Date(Math.max.apply(null,dates));
                    setCampaignEndDate(moment(latest).format('DD.MM.YYYY'));
                }
            }
          }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[selectedSimulations, totalUsers]);

    const getPhishingResults = async() => {
        Modal.info({
            title: intl.formatMessage({id: 'general.info'}),
            content: intl.formatMessage({id: 'phishing.phishing_results_message'}),
        });
        await coursesApiClient.request(`/api/v1/courses/custom-phishing/get-results/${campaignId}`, [], 'GET');
    };

    const tabSideButton = () => {
        if (campaignStatus === 'DRAFT') {
            return;
        }

        if (session.active_user_type === 'SUPERVISION') {
            return;
        }

        return (
            <Button type='primary' onClick={()=> getPhishingResults()}>
                <FormattedMessage id="phishing.get_results" />
            </Button>
        )
    };

    const loadEnrolledUsers = async () => {
        try {
            let response = await apiClient.request(`/api/v1/auth/customers/${customerId}/phishing-enrollments?page[number]=1&page[size]=${currentPageSize}`, {campaignId: campaignId}, 'POST');
            setTotalRecords(response.pagination.total);
            setTotalUsers(response.pagination.total);
            setCurrentPage(response.pagination.current_page);
            setUsers(response.users);
        } catch (error) {
          console.error(error);
          message.error(intl.formatMessage({id: "launch_error"}));
        } 
    };

    const deleteUsersFromCampaign = async () => {
        try {
            let arr = generalHelper.pluck(selectedUsers, 'studentUserTypeUuid');
            await coursesApiClient.request(`/api/v1/courses/custom-phishing/${campaignId}/phishing-students/remove`, {users:arr}, 'POST');
            loadEnrolledUsers();
        } catch (error) {
          console.error(error);
          message.error(intl.formatMessage({id: "launch_error"}));
        } 
    }

    const warnDeletion = async () => {

        if (!selectedUsers.length) {
            Modal.warning({
                title: intl.formatMessage({id: 'general.attention'}),
                content: intl.formatMessage({id: 'users.no_users_selected'})
            });
        } else {
            confirm({
                title: intl.formatMessage({ id: 'general.attention' }),
                content: intl.formatMessage({ id: 'campaign.mass_delete' }),
                icon: <ExclamationCircleOutlined />,
                okText: intl.formatMessage({ id: 'general.yes' }),
                cancelText: intl.formatMessage({ id: 'general.no' }),
    
                onOk() {
                    deleteUsersFromCampaign();
                }
            });
        }
    }

  return (
    <>
      <DefaultLayout.PageLayout>
        <DefaultLayout.PageHeader
          breadcrumb={
            [{name: intl.formatMessage({id: 'phishing.phishing'}), path: '/phishing/manage-campaigns'}]
          }
          title={intl.formatMessage({id: 'phishing.phishing_campaign'})}
        />
        <Spinner spinning={isLoading}>
          <div style={{backgroundColor: 'white', padding: 16, borderRadius: 8}}>
        <Tabs activeKey={activeTab} onChange={(e:any) => {setActiveTab(e); }} tabBarExtraContent={tabSideButton()}
              onTabClick={(e:any)=> {
                  let values = form.getFieldsValue();

                  if (e === '1' && !campaignId) {
                      if (
                          !(moment(values.deliverPhishingFrom) > moment(values.deliverPhishingTo)) &&
                          !(phishingCampaignType === 'DYNAMIC' && !values.deadline) &&
                          !(!values.phishingCampaignName) &&
                          !(!selectedSimulations)
                      ) {
                          submitForm();
                      }
                  }
              }}
        >
            {campaignStatus !== 'DRAFT' &&
                <TabPane tab={campaignStatus !== 'DRAFT' && intl.formatMessage({ id: 'phishing.results' }) } key='2'>
                    <CustomPhishingCampaignResulsts
                        phishingCampaign={customPhishishingCampaign}
                    />
                </TabPane>
            }
            {session.active_user_type !== 'SUPERVISION' &&
                <TabPane tab={intl.formatMessage({ id: 'phishing.campaign_settings' })} key='0'>
                    <Spinner spinning={isSettingsLoading}>
                        <Form form={form} onFinish={submitForm}>
                            <CustomPhishingCampaignSettings
                                campaignType={phishingCampaignType}
                                customerId={customerId}
                                selectedSimulations={selectedSimulations}
                                setSelectedSimulations={setSelectedSimulations}
                                campaignStatus={campaignStatus}
                                disabledFields={disabledFields}
                                disabledMainFields={disabledMainFields}
                                form={form}
                                campaignEndDate={campaignEndDate}
                                setCampaignEndDate={setCampaignEndDate}
                                phishingSettings={phishingSettings}
                                escalationManager={escalationManager}
                                setEscalationManager={setEscalationManager}
                            />
                        </Form>
                    </Spinner>
                </TabPane>
            }
            {session.active_user_type !== 'SUPERVISION' &&
                <TabPane tab={intl.formatMessage({ id: 'phishing.users' })} key='1' forceRender={true} >
                    <Spinner spinning={isUsersLoading}>
                        <Form form={usersForm} onFinish={submitUsersForm}>
                            <UsersForPhishing
                                customerUserList={customerUserList}
                                setCustomerUserList={setCustomerUserList}
                                campaignId={campaignId}
                                setIsUsersLoading={setIsUsersLoading}
                                disabledFields={disabledMainFields}
                                campaignStatus={campaignStatus}
                                setUsersToAdd={setUsersToAdd}
                                usersToAdd={usersToAdd}
                                customerId={customerId}
                                setTotalUsers={setTotalUsers}
                                campaignTitle={campaignTitle}

                                selectAll={selectAllFiltered}
                                setSelectAll={setSelectAllFiltered}
                                selectedRowsLength={selectedRowsLength}
                                setSelectedRowsLength={setSelectedRowsLength}
                                selectedData={selectedUsers}
                                setSelectedData={setSelectedUsers}
                                customRowSelection={rowSelection}
                                setCustomRowSelection={setRowSelection}

                                totalRecords={totalRecords}
                                setTotalRecords={setTotalRecords}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                users={users}
                                setUsers={setUsers}

                                pageSizeOptions={pageSizeOptions}
                                currentPageSize={currentPageSize}
                                setCurrentPageSize={setCurrentPageSize}
                                loadEnrolledUsers={loadEnrolledUsers}
                            />
                        </Form>
                    </Spinner>
                </TabPane>
            }
            <TabPane tab={campaignStatus !== 'DRAFT' && intl.formatMessage({ id: 'phishing.users_results' }) } key='3'>
                <CustomPhishingUsersResults
                    usersPhishingResults={usersPhishingResults}
                    isPhishTemplateLoading={isPhishTemplateLoading}
                    tableSpinner={tableSpinner}
                />
            </TabPane>
        </Tabs>
        </div>
        </Spinner>
          {session.active_user_type !== 'SUPERVISION' &&
              <DefaultLayout.PageFooter className='campaign-footer'>
                  {activeTab === '1' &&
                    <Button onClick={()=> warnDeletion()} style={{ margin: '0 8px', float: 'left', marginTop: '12px' }}>
                        <i style={{ paddingRight: 5, color: '#F5222D'}} className="fal fa-trash"/>
                        <FormattedMessage id='campaign.mass_delete' />
                    </Button>
                  }
                  {campaignStatus === 'DRAFT' &&
                  <>
                      {parseInt(activeTab) !== 0 &&  (
                          <Button onClick={() => prev()}  style={{ margin: '0 8px', float: 'left', marginTop: '12px' }}>
                              <FormattedMessage id="campaign.previous" />
                          </Button>
                      )}
                      {parseInt(activeTab) < 1 && (
                          <Button onClick={() => next()} style={{float: 'left', marginTop: '12px' }}>
                              <FormattedMessage id="general.next" />
                          </Button>
                      )}
                      {campaignStatus === 'DRAFT' &&
                          <Button
                              style={{margin: '0 8px', float: 'right', marginTop: '12px'}}
                              type="primary"
                              loading={campaignCreateLoader}
                              onClick={launchCampaign}
                              disabled={(!(selectedSimulations && selectedSimulations.length > 0 && totalUsers > 0 && totalUsers <= session.access.phishing.max_users))}
                          >
                              <FormattedMessage id="campaign.launch_campaign"/>
                          </Button>
                      }
                  </>
                  }
                  { campaignStatus === 'DRAFT' ?
                      <Button loading={campaignCreateLoader} disabled={selectedSimulations ? null : selectedSimulations} onClick={() => { submitForm(); submitUsersForm()}} className='save-button' style={{ margin: '0 8px', float: 'right', marginTop: '12px' }}>
                          <FormattedMessage id="campaign.save_as_draft" />
                      </Button>
                      :
                      <Button disabled={selectedSimulations ? null : selectedSimulations} onClick={() => { submitForm(); submitUsersForm();}} className='save-button' style={{ margin: '0 8px', float: 'right', marginTop: '12px' }}>
                          <FormattedMessage id="general.save" />
                      </Button>
                  }
              </DefaultLayout.PageFooter>
          }
      </DefaultLayout.PageLayout>
    </>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomPhishingCampaignForm));
