import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button, Input, Row, Col } from 'antd';
import ConnectionTestModal from './ConnectionTestModal';

const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 8 },
};

const LdapConnectionForm: React.FC<any> = ({ form, connection }) => {
    const [showConnectionTestModal, setShowConnectionTestModal] = useState(false);

    const intl = useIntl();

    useEffect(() => {

        if (connection?.connection_settings) {
            form.setFieldsValue({
                connection: {
                    type: connection?.connection_settings.type,
                    servers: connection?.connection_settings.servers,
                    base_dn: connection?.connection_settings.base_dn,
                    port: connection?.connection_settings.port
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <Form.Item
                {...formItemLayout}
                name={['connection', 'servers']}
                label={intl.formatMessage({ id: 'system.ldap.servers' })}
                rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                name={['connection', 'base_dn']}
                label={intl.formatMessage({ id: 'system.ldap.base_dn' })}
                rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                name={['connection', 'port']}
                label={intl.formatMessage({ id: 'system.port' })}
                rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}
            >
                <Input />
            </Form.Item>
            <Row>
                <Col offset={formItemLayout.labelCol.span}>
                    <Button onClick={() => setShowConnectionTestModal(true)}>
                        <FormattedMessage id='system.test_connection' />
                    </Button>
                </Col>
            </Row>
            <ConnectionTestModal
                form={form}
                visible={showConnectionTestModal}
                onCancel={() => setShowConnectionTestModal(false)}
            />
        </>
    )
};

export default LdapConnectionForm;
