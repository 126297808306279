import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { Button, message, Modal } from 'antd';
import FileDownload from 'js-file-download';
import { SubmittedFilesModalInterface } from '../types';
import coursesApiClient from 'utils/coursesApiClient';
import FormattedMessage from 'react-intl/lib/components/message';
import './styles.scss';
import { FileTextOutlined } from '@ant-design/icons';

const mapStateToProps = (state: any) => {
    return {
        activeUserRole: state.session.active_user_type
    }
}

const SubmittedFilesModal: React.FC<SubmittedFilesModalInterface> = ({
    visible,
    onCancel,
    id

}) => {
    const [assignments, setAssignments] = useState([]);
    const intl = useIntl();

    useEffect(() => {
        if (id !== 0) {
            loadSubmittedFiles();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const loadSubmittedFiles = async () => {

        try {
            const response = await coursesApiClient.request(`/api/v1/courses/student/${id}/submitted-files`, {}, 'GET');
            setAssignments(response.assignments);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }
    }

    /**
     * 
     * @param name 
     * @param path 
     */
    const downloadFile = async (name: string, path: string) => {
        try {
            let response = await coursesApiClient.request(`api/v1/courses/storage/${path}`, {}, 'GET', true, true);
            FileDownload(response, name);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: 'error.data_load' }));
        }
    }

    return (
        <Modal
            className="user-list-modal"
            visible={visible}
            title={intl.formatMessage({ id: 'users.submitted_files' })}
            onCancel={onCancel}
            footer={
                <Button onClick={() => onCancel()}>
                    <FormattedMessage id='general.cancel' />
                </Button>
            }
        >
            {Object.entries(assignments).map((assignment: any) => {
                return (
                    <div>
                        <div className='assignment-title'>
                            {assignment[0]}
                        </div>
                        {
                            Object.values(assignment[1]).map((file: any) => {
                                return (
                                    <div className='assignment-file' onClick={() => downloadFile(file.name, file.path)}>
                                        <FileTextOutlined style={{ color: '#7633B9' }} /> {file.name}
                                    </div>
                                )
                            })
                        }
                    </div>
                )
            })
            }
        </Modal>
    )
}

export default connect(mapStateToProps)(SubmittedFilesModal);
