import * as ActionTypes from 'store/actionTypes';

const defaultState = {}

const CampaignData = (state = defaultState, action: any) => {
  switch(action.type) {
    case ActionTypes.SAVE_CAMPAIGN_FORM:
      var CampaignFormData = action.payload;
      return ({...state, ...CampaignFormData});
    case ActionTypes.CLEAR_CAMPAIGN_DATA:
      return (defaultState);
    default:
      return state;
  }
}

export default CampaignData;