import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import { message} from 'antd';
import {changeLocale} from 'store/actions/locale';
import globalStyles from "utils/globalStyle";
import {changePublicAppearance} from "store/actions/publicAppearanceActions";
import ThemeSwitcher from 'react-css-vars';
import Helmet from 'react-helmet';
import config from 'config/config'
import { ThemeType } from 'pages/Customers/NoAuthCustomerForm/types';

const mapStateToProps = (state: any) => {
    return {
        globalTheme: state.publicAppearanceReducer
    }
};

const mapDispatchToProps = (dispatch: any) => ({
    changeLocale: (locale: string) => dispatch(changeLocale(locale)),
    changePublicAppearance: (publicAppearanceReducer: any) => dispatch(changePublicAppearance(publicAppearanceReducer)),
});

const AppearanceStyler: React.FC<any> = ({setAppearanceIsLoading, globalTheme, changePublicAppearance}) => {
    const [favicon, setFavicon] = useState();
    const [faviconPath, setFaviconPath] = useState();
    const [title, setTitle] = useState('CloudStudy');
    const [theme, setTheme] = useState<ThemeType>();

    const intl = useIntl();

    useEffect(() => {
        loadStyles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadStyles = async () => {
      let options;
      try {

        if (globalTheme.title) {
          options = globalTheme;
        } else {
          options = await globalStyles.getGlobalStyles();
        }


        if (!options.logo && options.logoPath) {
          let logo = await globalStyles.loadLogo(options.logoPath);
          options.logo = logo;
        }
    
        if (options.favicon) {
          setFavicon(options.favicon);
          setFaviconPath(options.faviconPath);
        } else if (options.faviconPath) {
          let favicon = await globalStyles.loadFavicon(options.faviconPath);
          setFavicon(favicon);
          options.favicon = favicon;
          setFaviconPath(options.faviconPath);
        }
        if (options) {
            setTitle(options.title);
            setTheme(options.theme);
            changePublicAppearance(options);
        }

      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      } finally {
        setAppearanceIsLoading(false);
      }
    };

    return (
        <>
            {theme &&
                <ThemeSwitcher theme={{themeColorGlobal: theme.themeColor}} />
            }
            <Helmet>
                {faviconPath === null
                    ? <link rel="icon" type="image/png" href="/favicon.ico" sizes="16x16" />
                    : <link rel="icon" type="image/png" href={favicon ? URL.createObjectURL(favicon) : undefined} sizes="16x16" />
                }
                <title>{config.api.appAppearance.title}</title>
            </Helmet>
        </>
    )
};


export default connect(mapStateToProps, mapDispatchToProps)(AppearanceStyler);

