import React from 'react';
import { Button, Space } from 'antd';
import FormattedMessage from 'react-intl/lib/components/message';
import { MaterialType } from './types';
import './styles.scss';

interface MaterialButtonsInterface {
    setShowMaterialModal:React.Dispatch<React.SetStateAction<boolean>>;
    setType: React.Dispatch<React.SetStateAction<MaterialType>>;
    disable: {
        learn: boolean,
        test: boolean,
        assignment: boolean,
        handouts: boolean
    }
}

const MaterialButtons: React.FC<MaterialButtonsInterface> = ({setShowMaterialModal, setType, disable}) => {
    const materialType = {
        'SCORM_LEARNING': {
            type: 'SCORM_LEARNING',
            disabled: disable.learn,
            text: 'courses.learning_file',
            icon: <i className="fa-solid fa-book"></i>,
        },
        'SCORM_TEST': {
            type: 'SCORM_TEST',
            disabled: disable.test,
            text: 'courses.scorm_test_file',
            icon: <i className="fa-solid fa-check-double"></i>,
        },
        'ASSIGNMENT': {
            type: 'ASSIGNMENT',
            disabled: disable.assignment,
            text: 'courses.individual_work',
            icon: <i className="fa-solid fa-file-signature"></i>,
        },
        'FILE': {
            type: 'FILE',
            disabled: disable.handouts,
            text: 'courses.material',
            icon: <i className="fa-solid fa-file-lines"></i>,
            buttonType: 'outlined'
        }
    };

    return (
        <>
            <Space size={10}>
                {
                    Object.values(materialType).map((material: any) => {
                        return (
                            <Button type={material?.buttonType ? 'default' : 'primary'} className={'material-type-button ' + (material?.buttonType && 'button-outlined')} key={material.type} disabled={material.disabled} onClick={() => { setType(material.type); setShowMaterialModal(true); }}>
                                {material.icon} <FormattedMessage id={material.text} />
                            </Button>
                        )
                    })
                }
            </Space>
        </>
    );
}

export default MaterialButtons;
