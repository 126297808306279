import * as ActionTypes from 'store/actionTypes';

const defaultState = {
    type: null
}

const phishingCampaignReducer = (state = defaultState, action: any) => {
  switch(action.type) {
    case ActionTypes.SAVE_PHISHING_CAMPAIGN_TYPE:
        let type = state;
        type = action.payload;
      return type;
    default:
      return state;
  }
}

export default phishingCampaignReducer;