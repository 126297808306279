import * as ActionTypes from 'store/actionTypes';

const defaultState = {
  collapsed: false
}

const SidebarCollapsed = (state = defaultState, action: any) => {
  switch(action.type) {
    case ActionTypes.CHANGE_SIDEBAR_COLLAPSED:
      var collapsed = action.payload;
      return {...state, collapsed: collapsed};

    default:
      return state;
  }
}

export default SidebarCollapsed;
