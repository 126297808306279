import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Select as AntDSelect, Row, Col, message, Radio, Checkbox, Modal, Button } from 'antd';
import DefaultLayout from 'components/DefaultLayout';
import LanguageSelect from 'components/LanguageSelect';
import Spinner from 'components/Spinner';
import { Input, Select, Switch, TimeZoneInput } from 'components/Form';
import { changeUserTableFilter } from 'store/actions/saveUserTableFilter';
import apiClient from 'utils/apiClient';
import config from 'config/config';
import jwt from 'utils/jwt';
import './styles.scss';

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 6 },
};

const mapStateToProps = (state: any) => {
  return {
    session: state.session,
    userTableFilter: state.userTableFilter
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  changeUserTableFilter: (userTableFilter: any) => dispatch(changeUserTableFilter(userTableFilter)),
});


const AdminForm: React.FC = ({history, match, session, changeUserTableFilter, userTableFilter}: any) => {
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [organization, setOrganization] = useState<any>({});
  const [isOrganizationLoading, setIsOrganizationLoading] = useState(false);
  const [accountStatuses, setAccountStatuses] = useState<any>({});
  const [isAdminLoading, setIsAdminLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState(null);
  const [selectedOrganizationalUnitId, setSelectedOrganizationalUnitId] = useState(null);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [isOrganizationUsersLoading, setIsOrganizationUsersLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  const [isPhishingAllowed, setIsPhishingAllowed] = useState(true);
  const [customerLabel, setCustomerLabel ] = useState('');
  const [organizationUnitLabel, setOrganizationUnitLabel ] = useState('');
  const [organizationalUnits, setOrganizationalUnits] = useState([{}]);
  const [addOrganizationalUnit, setAddOrganizationalUnit] = useState<any>([]);
  const [disableOrganizationSelect, setDisableOrganizationSelect] = useState(true);
  const [organizationLabelForFilter, setOrganizationLabelForFilter] = useState();
  const [languageCode, setLanguageCode] = useState('EN');

  const intl = useIntl();
  const [form] = Form.useForm();
  const organizationId = match.params.organizationId;
  const adminUuid = match.params.uuid;

  useEffect(() => {
    const loadOrganization = async () => {
      try {
        setIsOrganizationLoading(true);
        let organizationResponse = await apiClient.request(`/api/v1/organizations/${organizationId}`, {}, 'GET');
        setOrganization(organizationResponse.organization);
        setCustomerLabel(intl.formatMessage({id: "other.customer_users"}, {customer: `${organizationResponse.organization.name}`}))
      } catch (err) {
        console.error(err);
        message.error(intl.formatMessage({id: "error.data_load"}));
      } finally {
        setIsOrganizationLoading(false);
      }
    }

    const loadAdmin = async () => {
      try {
        setIsAdminLoading(true);
        let adminResponse = await apiClient.request(`/api/v1/auth/admins/${organizationId}/${adminUuid}`, {}, 'GET');
        let admin = adminResponse[0];
        setUserId(admin.userId);
        form.setFieldsValue({
          name: admin.name,
          surname: admin.surname,
          email: admin.email,
          position: admin.position,
          organizationalUnitId: admin.organizationalUnitId,
          managerReferenceId: admin.managerReferenceId,
          primaryLanguage: admin.primaryLanguage,
          location: admin.location,
          accountStatus: admin.accountStatus,
          phishingAllowed: !!admin.phishingAllowed,
          timeZone: admin.timeZone,
          canAddNewResellers: !!admin.canAddNewResellers,
          canAddNewCustomers: !!admin.canAddNewCustomers,
          canManageAgreements: !!admin.canManageAgreements,
          canManageCourses: !!admin.canManageCourses,
          canManageAdmins: !!admin.canManageAdmins,
          canEditEmailTemplates: !!admin.canEditEmailTemplates,
          canEditLanguages: !!admin.canEditLanguages,
          canManagePhishing: !!admin.canManagePhishing,
          canManageLicences: !!admin.canManageLicences,
          canManageServices: !!admin.canManageServices,
          canManageCustomerConnection: !!admin.canManageCustomerConnection

          
        });

        setSelectedOrganizationalUnitId(admin.organizationalUnitId);
      } catch (err) {
        message.error(intl.formatMessage({id: "error.data_load"}));
      } finally {
        setIsAdminLoading(false);
      }
    }

    const loadClassifiers = async () => {
      let accountStatusesResponse = await apiClient.request('/api/v1/enum/student-account-status-types', {}, 'GET');
      setAccountStatuses(accountStatusesResponse || {});

      if (organizationId) {
        setIsOrganizationUsersLoading(true);
        let organizationUsers = await apiClient.request(`/api/v1/auth/organizations/${organizationId}/users`, {}, 'GET');
        setOrganizationUsers(organizationUsers.users);
        setIsOrganizationUsersLoading(false);
      }
    }

    loadClassifiers();
    loadOrganizationalUnits();

    form.setFieldsValue({
      accountStatus: 'ACTIVE'
    });

    if (organizationId) {
      loadOrganization();
    }

    if (adminUuid) {
      loadAdmin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationId, intl])

  const loadOrganizationalUnits = async (id:any = null) => {
    let organizationalUnits = await apiClient.request(`/api/v1/organizations/${organizationId || id}/organizational-unit-types`, {}, 'GET');
    if (organizationalUnits) {
      setOrganizationalUnits(organizationalUnits.organizationalUnits);
    }
  }

  useEffect(() => {
    const loadOrganizationUsers = async () => {
      setIsOrganizationUsersLoading(true);
      let organizationUsers = await apiClient.request(`/api/v1/auth/organizations/${selectedOrganizationId}/users`, {}, 'GET');
      setOrganizationUsers(organizationUsers.users);
      setIsOrganizationUsersLoading(false);
    }

    if (selectedOrganizationId) {
      loadOrganizationUsers();
    }
  }, [selectedOrganizationId]);

  const submitForm = async (values: any) => {
    let newOrganizationalUnit = addOrganizationalUnit[addOrganizationalUnit.length -2];
    let organizationalUnitId = selectedOrganizationalUnitId;
    let parsedValues:any;

    parsedValues = {
      ...values,
      primaryLanguage:languageCode,
      organizationId: organizationId || values.organizationId,
      phishingAllowed: isPhishingAllowed,
      canManageLearningManagers: !!values.canManageLearningManagers,
      canAddNewResellers: !!values.canAddNewResellers,
      canAddNewCustomers: !!values.canAddNewCustomers,
      canManageAgreements: !!values.canManageAgreements,
      canManageCourses: !!values.canManageCourses,
      canManageAdmins: !!values.canManageAdmins,
      canEditEmailTemplates: !!values.canEditEmailTemplates,
      canEditLanguages: !!values.canEditLanguages,
      canManagePhishing: !!values.canManagePhishing,
      canManageLicences: !!values.canManageLicences,
      canManageServices: !!values.canManageServices,
      canManageCustomerConnection: !!values.canManageCustomerConnection,
      managerOfUnit: false,
      sendWelcomeMail: sendEmail
    }

    if (organizationalUnits.length === 0) {
      parsedValues = {
        ...parsedValues,
        organizationalUnitId,
        newOrganizationalUnit
      }
    }

    try {
      Object.entries(organizationalUnits).map((el:any)=> {
        if (el[1] === newOrganizationalUnit) {
          parsedValues = {
            ...parsedValues,
            organizationalUnitId
          }

        } else {
          parsedValues = {
            ...parsedValues,
            newOrganizationalUnit,
            organizationalUnitId
          }
        }
        return parsedValues;
      })

      if (userId) {
        parsedValues.userId = userId;
      }

      setSubmitInProgress(true);
      let submitResponse
      if (adminUuid) {
        submitResponse = await apiClient.request(`/api/v1/auth/admins/${organizationId}/${adminUuid}/update`, parsedValues, 'PUT');
      } else {
        submitResponse = await apiClient.request('/api/v1/auth/admins/create', parsedValues, 'POST');
      }
      if (!Object.keys(userTableFilter).length) {
        changeUserTableFilter({filter:{
          filter:{
            options:{
              accountStatus: "in:ACTIVE",
            },
            userTypes: ["ADMIN"]
          },
          accountStatus: ["ACTIVE"],
          organizationalUnitId:[],
          filterBy: "reseller",
          owner: organizationId || values.organizationId,
          userTypes: ["ADMIN"],
          active: [1, 2]
        },
          filterLabels:{
            name: organizationLabelForFilter || organization.name
          }
        })
      }

      if(submitResponse.status_code > 299) return "submit error";

      setSubmitInProgress(false);
      message.success(adminUuid ? 'Admin updated successfully' : 'Admin successfully created');
      if (values.email === session.email) {
        jwt.switchUserRole(session.active_user_type_id);
      }
      history.push(`/organizations/${organizationId}/admins`);
    } catch (error: any) {
      console.error(error);
      message.error(intl.formatMessage({id: "general.submit_error"}));    // TODO: Izmantot notification
      if (error.message === 'admin_email_already_exists') {
        Modal.error({
          title: 'Error',
          content: `There already is one user within ${organization.name} account with such email!`,
        });
      } else {
        message.error(intl.formatMessage({id: "general.submit_error"}));    // TODO: Izmantot notification
      }
      setSubmitInProgress(false);
    }
  }

  return <DefaultLayout.PageLayout withStickyFooter>
    <DefaultLayout.PageHeader
      loading={isOrganizationLoading}
      breadcrumb={organizationId
        ? [
            {name: intl.formatMessage({id: 'general.resellers'}), path: '/organizations'},
            {name: (organization.name + ' ' + intl.formatMessage({id: 'users.form.administrators'})), path: session.active_user_type === "CUSTOMER_ADMIN" ? `/users` : `/organizations/${organizationId}/admins`}
          ]
        : [{name: intl.formatMessage({id: 'general.users'}), path: '/users'}]
      }
      title={intl.formatMessage({id: adminUuid ? 'users.form.edit_administrator' : 'users.form.add_administrator'})}
    />
    <Spinner spinning={submitInProgress || isAdminLoading} opaque={isAdminLoading}>
    <DefaultLayout.PageContent withTopPadding>
        <Form form={form} onFinish={submitForm}>
          {
            organizationId
              ? null
              : <Select
                  showSearch
                  name='organizationId'
                  label={intl.formatMessage({id: "general.reseller"})}
                  url={config.api.routes.backend.organizationsOptions}
                  integerKey={true}
                  customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
                  isResponseArray={true}
                  dataKey='organizations'
                  mapDataEntries={(el: any) => ({value: el.id, label: el.name})}
                  valueKey='value'
                  labelKey='label'
                  onChange={(value: any, label: any) => {loadOrganizationalUnits(value); setOrganizationLabelForFilter(label.children); setDisableOrganizationSelect(false); setSelectedOrganizationId(value); form.setFieldsValue({organizationId: value}); setCustomerLabel(`Other ${label.children} users`)}}
                  filterOption={(input: string, option: any) => {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  }}
                />
          }
          <Input name="name" label={intl.formatMessage({id: "general.name"})} validation={{required: true}}/>
          <Input name="surname" label={intl.formatMessage({id: "general.surname"})} validation={{required: true}}/>
          <Input name="email" label={intl.formatMessage({id: "general.email"})} validation={{required: true, email: true}}/>
          <Input name="position" label={intl.formatMessage({id: "general.position"})} validation={{required: true}}/>
          <Select
              allowClear
              showSearch
              disabled={!organizationId ? disableOrganizationSelect : false }
              name='organizationalUnitId'
              label={intl.formatMessage({id: "general.unit"})}
              onSearch={(element: any) => {setAddOrganizationalUnit((el:any) =>[...addOrganizationalUnit, element]); form.setFieldsValue({organizationalUnitId: addOrganizationalUnit[addOrganizationalUnit.length -1]});}}
              dataKey='organizationalUnits'
              manualOptions={organizationalUnits}
              integerKey={true}
              onChange={(value: any, label: any) => {setSelectedOrganizationalUnitId(value); setAddOrganizationalUnit([]); form.setFieldsValue({organizationalUnitId: value}); form.validateFields(); setOrganizationUnitLabel(intl.formatMessage({id: "other.customer_users"}, {customer: `${label.children}`}))}}
              filterOption={(input: string, option: any) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
              customRules={[
                { required: true, message: intl.formatMessage({id: "validation.field_required"}) },
              ]}
              dropdownStyle={{ minWidth: "400px" }}
            />
          <Form.Item
            {...formItemLayout}
            name="managerReferenceId"
            label={intl.formatMessage({id: "users.form.users_direct_manager"})}
          >
            <AntDSelect
              allowClear
              showSearch
              disabled={!organizationId && !selectedOrganizationId}
              notFoundContent='No customer users found'
              style={{ height: '100%', width: '100%', borderRadius: 8}}
              loading={isOrganizationUsersLoading}
              filterOption={(input: string, option: any) => {
                if (option.value) {
                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                } else {
                    return false;
                }
              }}>
              {organizationUsers.filter((el: any) => el.organizationalUnitId === selectedOrganizationalUnitId && el.accountStatus === 'ACTIVE' && el.userId !== userId).length > 0
                ? <AntDSelect.OptGroup label={organizationUnitLabel}>
                  {
                    organizationUsers.filter((el: any) => el.organizationalUnitId === selectedOrganizationalUnitId && el.accountStatus === 'ACTIVE' && el.userId !== userId).map((el: any) => {
                      return <AntDSelect.Option key={el.userTypeId} value={parseInt(el.userTypeId)}>{`${el.name} ${el.surname}`}</AntDSelect.Option>
                    })
                  }
                </AntDSelect.OptGroup>
                : null
              }
              {organizationUsers.filter((el: any) => el.organizationalUnitId !== selectedOrganizationalUnitId && el.accountStatus === 'ACTIVE' && el.userId !== userId).length > 0
                ? <AntDSelect.OptGroup label={customerLabel}>
                  {
                    organizationUsers.filter((el: any) => el.organizationalUnitId !== selectedOrganizationalUnitId && el.accountStatus === 'ACTIVE' && el.userId !== userId).map((el: any) =>
                      <AntDSelect.Option key={el.userTypeId} value={parseInt(el.userTypeId)}>{`${el.name} ${el.surname}`}</AntDSelect.Option>
                    )
                  }
                </AntDSelect.OptGroup>
                : null
              }
            </AntDSelect>
          </Form.Item>
          <LanguageSelect
                name='primaryLanguage'
                label={intl.formatMessage({id: "general.language"})}
                setLanguageCode={setLanguageCode}
              />
          <Form.Item
            {...formItemLayout}
            name="timeZone"
            label={intl.formatMessage({id: "users.form.time_zone"})}
            rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
          >
            <TimeZoneInput />
          </Form.Item>
          <Form.Item
            {...formItemLayout}
            name="accountStatus"
            label={intl.formatMessage({id: "users.form.account_status"})}
          >
            <Radio.Group >
              {Object.keys(accountStatuses).map(key =>
                <Radio className="radio-button" key={key} value={key}>{intl.formatMessage({id: `users.form.${key}`})}</Radio>
              )}
            </Radio.Group>
          </Form.Item>

          <Switch
            isFormItem
            hasDefaultLayout
            checked={isPhishingAllowed}
            onChange={() => setIsPhishingAllowed(!isPhishingAllowed)}
            name="phishingAllowed"
            label={intl.formatMessage({id: "users.form.phishing_allowed"})}
          />

          <Switch isFormItem hasDefaultLayout name="isActiveElearningAccount" label={intl.formatMessage({id: "users.form.active_e_learning_account"})} />

          <Form.Item
              {...formItemLayout}
              name="remember"
              label={intl.formatMessage({id: "general.send_email"})}

            >
              <Checkbox checked={sendEmail} onChange={() => setSendEmail(!sendEmail)}/>
            </Form.Item>
          <hr className="form-group-seperator" />
          <Row>
            <Col span={14}>
              <h1 className="form-group-header">{intl.formatMessage({id: "organization.special_rights"})}</h1>
            </Col>
          </Row>
          <Switch isFormItem hasDefaultLayout name="canAddNewResellers" label={intl.formatMessage({id: "organization.add_new_resellers"})} />
          <Switch isFormItem hasDefaultLayout name="canAddNewCustomers" label={intl.formatMessage({id: "organization.add_new_customers"})} />
          <Switch isFormItem hasDefaultLayout name="canManageAgreements" label={intl.formatMessage({id: "users.form.manage_agreements"})} />
          <Switch isFormItem hasDefaultLayout name="canManageCourses" label={intl.formatMessage({id: "organization.add_remove_courses"})} />
          <Switch isFormItem hasDefaultLayout name="canManageAdmins" label={intl.formatMessage({id: "organization.add_remove_admins"})} />
          <Switch isFormItem hasDefaultLayout name="canEditEmailTemplates" label={intl.formatMessage({id: "organization.edit_email_templates"})} />
          <Switch isFormItem hasDefaultLayout name="canEditLanguages" label={intl.formatMessage({id: "general.edit_languages"})} />
          <Switch isFormItem hasDefaultLayout name="canManagePhishing" label={intl.formatMessage({id: "organization.manage_phishing"})} />
          <Switch isFormItem hasDefaultLayout name="canManageLicences" label={intl.formatMessage({id: "licensing.manage_licences"})} />
          <Switch isFormItem hasDefaultLayout name="canManageServices" label={intl.formatMessage({id: "licensing.manage_services"})} />
          <Switch isFormItem hasDefaultLayout name="canManageCustomerConnection" label={intl.formatMessage({id: "system.can_manage_customer_connection"})} />
        </Form>
      </DefaultLayout.PageContent>
      <DefaultLayout.PageFooter className='justify-content-end'>
        <div className="form-buttons">
          <NavLink to={'/users'}>
            <Button style={{marginRight: 8}}>
              <FormattedMessage id="general.back" />
            </Button>
          </NavLink>
          <Button type="primary" htmlType="submit" loading={submitInProgress} onClick={() => form.submit()}>
            <FormattedMessage id="general.submit" />
          </Button>
        </div>
      </DefaultLayout.PageFooter>
    </Spinner>
  </DefaultLayout.PageLayout>
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminForm));
