import React, {useState} from 'react';
import {Popover} from 'antd';
import {FormattedMessage} from 'react-intl';
import '../styles.scss';

const OrganizationSwitch: React.FC<any> = ({customers, organizations, onClick, selectedOrganization}) => {
  const [isOpen, setIsOpen] = useState(false);

  let selectedCustomerObject = customers && selectedOrganization ? (customers.find((el: any) => `c${el.value}` === selectedOrganization) || {}) : {};
  let selectedOrganizationObject = customers && selectedOrganization ? (organizations.find((el: any) => `o${el.value}` === selectedOrganization) || {}) : {};

  const PopoverContent = () => {
    if (customers && customers.length === 0 && organizations && organizations.length === 0) {
      return <div className='no-results-item'><FormattedMessage id='general.no_items' /></div>
    }

    return (
      <>
        {customers && customers.length > 0
          ? <p className='select-item-seperator'><FormattedMessage id='general.customers'/></p>
          : null
        }
        {customers.map((el: any) =>
          <div key={`c${el.value}`} className='select-item' onClick={() => {onClick(`c${el.value}`, 'customers'); setIsOpen(false)}}>{el.label}</div>)
        }
        {organizations && organizations.length > 0
          ? <p className='select-item-seperator'><FormattedMessage id='general.resellers'/></p>
          : null
        }
        {organizations.map((el: any) =>
          <div key={`o${el.value}`} className='select-item' onClick={() => {onClick(`o${el.value}`, 'organizations'); setIsOpen(false)}}>{el.label}</div>)
        }
      </>
    )
  };

  return <div className='session-switch-item'>
    <Popover
      placement='bottomLeft'
      content={<PopoverContent />}
      overlayClassName='session-switch-popover'
      arrowContent={false}
      visible={isOpen}
      trigger='click'
      onVisibleChange={() => setIsOpen(!isOpen)}
    >
      <div className='role-switch'>
        <i className='fal fa-building with-accent' />
        {
          <span className='display-organization-name'>
            {selectedOrganizationObject.label || selectedCustomerObject.label || '-'}
          </span>
        }
        <i className='fal fa-chevron-down' style={{fontSize: 10}} />
      </div>
    </Popover>
  </div>
}

export default OrganizationSwitch;
