import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Descriptions } from 'antd';

interface UserInfoProps {
    user: {
        name: string;
        surname: string;
        email: string;
    };
}

const UserInfo: React.FC<UserInfoProps> = ({ user }) => {
    const label = (labelId: string) => (
        <span style={{ fontWeight: 'bold' }}>
      <FormattedMessage id={labelId} />
    </span>
    );

    return (
        <Descriptions column={1}>
            <Descriptions.Item label={label('general.name')}>
                {user.name}
            </Descriptions.Item>
            <Descriptions.Item label={label('general.last_name')}>
                {user.surname}
            </Descriptions.Item>
            <Descriptions.Item label={label('general.email')}>
                {user.email}
            </Descriptions.Item>
        </Descriptions>
    );
};

export default UserInfo;
