import {
  APPEARANCE_SETTINGS_GET_IN_PROGRESS,
  APPEARANCE_SETTINGS_GET_SUCCESS,
  APPEARANCE_SETTINGS_GET_FAILED,
  AppearanceSettingsActionTypes,
} from 'store/actionTypes';

const defaultState = {
  title: '',
  logoPath: null,
  faviconPath: null,
  theme: {
    themeColor: '#19a9Ce',
    navTextColor: '#b7b1bf',
    navActiveTextColor: '#ffffff',
    navBackgroundColor: '#2f224b',
    navSubmenuBackgroundColor: '#1f182f',
    navActiveBackgroundColor: '#7633b9',
  },
};

const appearanceSettingsReducer = (state = defaultState, action: AppearanceSettingsActionTypes) => {
  switch (action.type) {
    case APPEARANCE_SETTINGS_GET_IN_PROGRESS:
      return { loading: true, failed: false };
    case APPEARANCE_SETTINGS_GET_SUCCESS:
      return { ...action.payload, loading: false, failed: false };
    case APPEARANCE_SETTINGS_GET_FAILED:
      return { loading: false, failed: true };
    default:
      return state;
  }
}

export default appearanceSettingsReducer;
