import * as ActionTypes from 'store/actionTypes';

const defaultState = {
  name: null,
  surname: null,
  email: null,
  active: false,
  user_types: {},
  organization: null,
  active_user_type: null,
  permissions: [],
  active_user_type_id: null,
  loading: true
}

const Session = (state = defaultState, action: any) => {
  switch(action.type) {
    case ActionTypes.SET_SESSION:
      let session = action.payload;
      return {...session, loading: false};
    case ActionTypes.CLEAR_SESSION:
      return {...defaultState};
    case ActionTypes.TOGGLE_SESSION_LOADING:
      return {...state, loading: !state.loading}
    case ActionTypes.SET_SESSION_SELECTED_ORGANIZATION:
        let currentState = state;
        currentState.organization = action.payload;

      return currentState;
    default:
      return state;
  }
}

export default Session;
