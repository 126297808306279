import React, { useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Input, Popover } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import Day from './Day';

import './styles.scss';

interface DayPickerProps {
  form: any; // FormInstance
  buildCronExpression: VoidFunction;
  numOfDays?: number;
  numOfRows?: number;
}

const DayPicker: React.FC<DayPickerProps> = ({ form, buildCronExpression, numOfDays = 31, numOfRows = 5 }) => {
  const [value, setValue] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const intl = useIntl();

  const days = ((length: number = numOfDays, rows: number = numOfRows) => {
    const _days = [];
    let value = 1;
    
    for (let i = 0; i < rows; i++) {
      const row = [];

      for (let j = 0; j < Math.ceil(length / rows); j++) {
        if (value <= length) {
          row.push(value);
          value++;
        }
      }

      _days.push(row);
    }
    
    return _days;
  })();

  const popoverContent = () => {
    return (
      <div className="day-picker-panel">
        <div className="day-picker-header">
          <FormattedMessage id="general.select_days"/>
        </div>
        <div className="day-picker-body">
          <table className="day-picker-content">
            <tbody>
              {days.map((row) => (
                <tr>
                  {row.map((day) => (
                    <Day
                      form={form}
                      buildCronExpression={buildCronExpression}
                      pickerValue={value}
                      setPickerValue={setValue}
                      dayValue={day}
                    />
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const reset = (e: any) => {
    if (e.type === 'click') {
      setValue([]);
      form.setFieldsValue({ everyMonthOn: [] });
      buildCronExpression();
      setIsOpen(false);
    }
  }

  return (
    <Popover
      placement="bottom"
      content={popoverContent} 
      trigger="click"
      visible={isOpen}
      arrowPointAtCenter
      onVisibleChange={setIsOpen}
    >
      <Input
        name="days"
        value={value.join()}
        placeholder={intl.formatMessage({id: 'general.select_days'})}
        suffix={<CalendarOutlined />}
        allowClear
        onChange={reset}
      />
    </Popover>
  );
}

export default DayPicker;
