import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert, Button, message, Modal, Popover, Radio, Tag, Tooltip, Form } from 'antd';
import moment from 'moment';
import { changeCampaignData } from 'store/actions/campaignData';
import { changePhishingCampaignView } from 'store/actions/phishingCampaignView';
import { changePhishingCampaign } from 'store/actions/phishingCampaignActions';
import DefaultLayout from 'components/DefaultLayout';
import PhishingCampaignFilterModal from "components/Phishing/PhishingCampaignFilterModal";
import InstructionButton from "components/VideoInstructions/InstructionButton";
import { Select, Switch } from 'components/Form';
import Spinner from 'components/Spinner';
import coursesApiClient from 'utils/coursesApiClient';
import config from 'config/config';
import FlexRow from 'components/FlexRow';
import Table from 'components/Table';
import './styles.scss';

type FilterValues = {
    customerUuid: string;
    hideFinished: boolean;
    status?: 'FINISHED' | 'ONGOING' | 'DRAFT';
}

const mapStateToProps = (state: any) => {
  return {
    session: state.session,
    campaignData: state.campaignData
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  changeCampaignData: (formData: any) => dispatch(changeCampaignData(formData)),
  changePhishingCampaignView: (fromPhishingView: any) => dispatch(changePhishingCampaignView(fromPhishingView)),
  changePhishingCampaign: (fromPhishingView: any) => dispatch(changePhishingCampaign(fromPhishingView)),
});

const ManagePhishingCampaigns: React.FC = ({history, session, changeCampaignData, changePhishingCampaignView, changePhishingCampaign}: any) => {
  const [isCampaignsLoading, setIsCampaignsLoading] = useState(false);
  const [phishingCampaigns, setPhishingCampaigns] = useState<any>();
  const [visibleTooltip, setVisibleTooltip] = useState<number|null>(null);
  const [showPhishingCampaignModal, setShowPhishingCampaignModal] = useState(false);
  const [selectedCampaignType, setSelectedCampaignType] = useState();
  const [newCampaignCustomer, setNewCampaignCustomer] = useState();
  const [isPhishingChanging, setIsPhishingChanging] = useState(false);
  const [displayUnfinishedCampaigns, setDisplayUnfinishedCampaigns] = useState(true);
  const [customerUuid, setCustomerUuid] = useState<string| undefined>();
  const [customerId, setCustomerId] = useState<number| undefined>();
  const [filter, setFilter] = useState<any>();
  const pageSizeOptions = [10, 20];
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPageSize, setCurrentPageSize] = useState<number>(pageSizeOptions[0]);

  const [showCampaignFilterModal, setShowCampaignFilterModal] = useState(false);

  const resourceUrl = `/api/v1/courses/customer-phishing-campaigns/`;

  const intl = useIntl();
  const [modalForm] = Form.useForm();
  const isLearningManager = session.active_user_type === 'CUSTOMER_ADMIN';

  useEffect(() => {
    const loadCampaigns = async () => {
        setIsCampaignsLoading(true);
        let pageQuery = 'page[number]=' + 1 + '&page[size]=' + currentPageSize;

        let parsedValues: FilterValues = {
            customerUuid: session.organization.organization_uuid,
            hideFinished: true
        };

        let response = await getRecords(resourceUrl, pageQuery, parsedValues, session.organization.organization_uuid, session.organization.organization_id, true);

        setPhishingCampaigns(response.data);
        setTotalRecords(response.pagination.total);
        setIsCampaignsLoading(false);
    };

    modalForm.setFieldsValue({
      campaignType:'STRICTLY'
    });

    if (session.active_user_type === 'CUSTOMER_ADMIN') {
      loadCampaigns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  const handleVisibleChange = (visible: boolean, rowIndex: number) => {
    setVisibleTooltip(visible ? rowIndex : null);
  };

  const columns = [
    {
        width: 65,
        render: (record: any) => {
            return (
                <div className='content-column'>
                    {
                        record.campaignThumbnail ? <i className={`fal ${record.campaignThumbnail}`} /> : <i className="fal fa-book"></i>
                    }
                </div>
            );
        }
    },
    {
      title: intl.formatMessage({id: 'phishing.campaign_name'}),
      dataIndex: 'campaignName',
      render: (campaignName: string, record: any) => {
        switch (record.campaignType) {
          case 'smart_dephish':
            return (
              <NavLink to={`/campaign/${record.campaignId}/phishing`}>
                {campaignName}
              </NavLink>
            );
          case 'custom_campaign':

            if (session.active_user_type !== 'CUSTOMER_ADMIN') {
              return (
                <div className='user-name-style' onClick={()=>{
                  changePhishingCampaign({type: 'custom_campaign', customerId: customerId , customerUuid: customerUuid }); history.push(`/customer/${customerId}/phishing/campaign/${record.phishingCampaignId}/edit`)}}>
                  {campaignName}
                </div>
              );
            } else {
              return (
                  <NavLink to={`/customer/${customerId}/phishing/campaign/${record.phishingCampaignId}/edit`} >
                      {campaignName}
                  </NavLink>
              );
            }
        }
      },
    },
    {
      title: intl.formatMessage({id: 'phishing.launch_date'}),
      sorter: true,
      dataIndex: 'begin_date',
      render: (launchDate: string, record: any) => record.launchDate ? moment(record.launchDate).format('DD.MM.YYYY') : '-',
    },
    {
      title: intl.formatMessage({id: 'phishing.completed_date'}),
      dataIndex: 'deadline',
      sorter: true,
      render: (completionDate: string, record: any) => record.completionDate ? moment(record.completionDate).format('DD.MM.YYYY') : '-',
    },
    {
      title: intl.formatMessage({id: 'general.type'}),
      dataIndex: 'campaignType',
      key: 'campaignType',
      render: (campaignType: number) =>  intl.formatMessage({id: `phishing.${campaignType}`}),
    },
    {
      title: intl.formatMessage({id: 'phishing.target_users'}),
      dataIndex: 'targetUsers',
      render: (targetUsers: number) => targetUsers || '-',
    },
    {
      title:(
        <span>
          {intl.formatMessage({id: 'phishing.phish_e_mails_delivered'})}
          <Tooltip title={intl.formatMessage({id: 'phishing.results_hint'})}>
              <i className='fal fa-question-circle header-item' />
          </Tooltip>
        </span>),
      dataIndex: 'result',
      key: 'result',
      render: (text: string, record: any) => {
        return(
          <div>
            { record.phishEmailsDelivered !== null ?
            <div>
              <span>{record.phishesFailed}/</span>
                <span>{record.phishEmailsDelivered} </span>
                { record.failRate !== null ?
                  <>
                    <span>( {record.failRate.toString()} %)</span>
                  </>
                : null
                }
            </div>
              : '-'
            }
          </div>
        )
      }
    },
    {
      title:
      <div style={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
        {intl.formatMessage({id: 'phishing.turn_on_off'})}
        <Tooltip title={intl.formatMessage({id: 'phishing.turn_on_off_hint'})}>
          <i className='fal fa-question-circle header-item' />
        </Tooltip>
      </div>,
      dataIndex: 'status',
      align: 'center',
      render: (text: string, record: any) => {
          let disabled;
          if (record.campaignType === 'smart_dephish') {
              if (record.status === 'DRAFT') {
                  disabled = true;
              } else if (record.status === 'FINISHED') {
                  disabled = true;
              }

              const changeSwitch = () => {
                  setIsPhishingChanging(true);
                  if (record.phishingStatus === 'ACTIVE') {
                      deactivatePhishing(record)
                      // Typo in database, don't fix unless you also fix the database :)
                  } else if (record.phishingStatus === 'STOPED') {
                      activatePhishing(record)
                  }
              };

              return (
                  <Switch
                      className='status-switch'
                      checked={record.phishingStatus !== 'STOPED'}
                      disabled={disabled}
                      onChange={() => changeSwitch()}
                  />
              );

        } else if (record.campaignType === 'custom_campaign') {
          if (record.status === 'DRAFT') {
            disabled = true;
          } else if (record.status === 'FINISHED') {
            disabled = true;
          } else if (record.status === 'ONGOING') {
            disabled = false;
          }

              const changeSwitch = () => {
                  setIsPhishingChanging(true);
                  if (record.phishingEnabled === 1) {
                      deactivateCustomPhishing(record)
                  } else if (record.phishingEnabled === 0) {
                      activateCustomPhishing(record)
                  }
              };

              return (
                  <Switch
                      className='status-switch'
                      checked={record.phishingEnabled === 1}
                      disabled={disabled}
                      onChange={() => changeSwitch()}
                  />
              );
          }
      },
    },
    {
      title: intl.formatMessage({id: 'general.status'}),
      dataIndex: 'phishingStatus',
      render: (phishingStatus: string, record: any, tag:any) => {
        let color;
        let status;

        if (record.campaignType === 'smart_dephish') {
          switch (phishingStatus) {
            case 'STOPED':
              color = 'red';
              status = phishingStatus;
              break;
            case 'ACTIVE':
              if (record.status === 'ONGOING') color = 'green';
              if (record.status === 'DRAFT') color = 'gold';
              if (record.status === 'FINISHED') color = 'blue';
              status = record.status;
              break;
          }
          return (
            <div className="phishing-campaign-tags">
              <Tag color={color} key={tag} className={`phishing.${record.status}`}>
                {intl.formatMessage({id: `gophish.status.${status}`})}
              </Tag>
            </div>
          );
        } else if (record.campaignType === 'custom_campaign') {
          switch (record.status) {
            case 'DRAFT':
              color = 'red';
              status = 'STOPED';
              break;
            case 'ONGOING':
              if (record.phishingEnabled) {
                color = 'green';
                status = record.status;
              }

              if (!record.phishingEnabled) {
                color = 'red';
                status = 'STOPED';
              }
              break;
            case 'FINISHED':
              color = 'blue';
              status = record.status;
              break;
          }

          return (
            <div className="phishing-campaign-tags">
              <Tag color={color} key={tag} className={`phishing.${record.status}`}>
                {intl.formatMessage({id: `gophish.status.${status}`})}
              </Tag>
            </div>
          );

        }
      },
    },
    {
      key: 'actions',
      render: (text: string, record: any, index: any) => {
        if (record.campaignType === 'Smart dephish') {
          const content = <>
          <div className="popover-item" onClick={()=>{changePhishingCampaignView({fromPhishingView:true}); history.push(`/courses/campaign/${record.campaignId}/edit`)}}>
            <FormattedMessage id="general.view"/>
          </div>
        </>;
          return (
            <Popover
              visible={index === visibleTooltip}
              content={content}
              trigger="click"
              placement="bottomRight"
              arrowPointAtCenter
              onVisibleChange={(visible) => handleVisibleChange(visible, index)}
            >
              <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
              </div>
            </Popover>
          );
        } else if (record.campaignType === 'Custom campaign') {

          const content = <>
          { session.active_user_type !== 'CUSTOMER_ADMIN' ?
            <div className="popover-item" onClick={()=>{changePhishingCampaign({type: 'custom_campaign', customerId: customerId, customerUuid: customerUuid }); history.push(` /customer/${customerId}/campaign/${record.phishingCampaignId}/edit`)}}>
              <FormattedMessage id="general.view"/>
            </div>
            :

            <div className="popover-item" onClick={()=>{history.push(`/customer/${customerId}/phishing/campaign/${record.phishingCampaignId}/edit`)}}>
              <FormattedMessage id="general.view"/>
            </div>
          }
        </>;
          return (
            <Popover
              visible={index === visibleTooltip}
              content={content}
              trigger="click"
              placement="bottomRight"
              arrowPointAtCenter
              onVisibleChange={(visible) => handleVisibleChange(visible, index)}
            >
              <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
              </div>
            </Popover>
          );
        }
      },
    },
  ];

  const deactivatePhishing = async (record: any) => {
    const newCampaign = await coursesApiClient.request(`/api/v1/courses/phishing-campaign/stop/${record.phishingCampaignId}/${customerUuid || session.organization.organization_uuid}`, {}, 'PUT');

    message.success(intl.formatMessage({id: 'phishing.phishing_deactivated'}));

      let key:any;
      Object.entries(phishingCampaigns).map((el:any) => {
          if (el[1].phishingCampaignId === record.phishingCampaignId && record.campaignId) {
              key = el[0];
          }
          return key;
      });

      let phishingCampaign = phishingCampaigns.filter((el:any) => el.phishingCampaignId !== 0);
      phishingCampaign[key] = newCampaign.phishingCampaign;

      setPhishingCampaigns(phishingCampaign);
      setIsPhishingChanging(false);
  };

  const activatePhishing = async (record: any) => {
    const newCampaign = await coursesApiClient.request(`/api/v1/courses/phishing-campaign/start/${record.phishingCampaignId}/${customerUuid || session.organization.organization_uuid}`, {}, 'PUT');

    message.success(intl.formatMessage({id: 'phishing.phishing_enabled'}));

      let key:any;
      Object.entries(phishingCampaigns).map((el:any) => {
          if (el[1].phishingCampaignId === record.phishingCampaignId && record.campaignId) {
              key = el[0];
          }
          return key;
      });

      let phishingCampaign = phishingCampaigns.filter((el:any) => el.phishingCampaignId !== 0);
      phishingCampaign[key] = newCampaign.phishingCampaign;

      setPhishingCampaigns(phishingCampaign);
      setIsPhishingChanging(false);
  };

  const deactivateCustomPhishing = async (record: any) => {

    const newCampaign = await coursesApiClient.request(`/api/v1/courses/custom-phishing/stop/${record.phishingCampaignId}/${customerUuid || session.organization.organization_uuid}`, {}, 'PUT');

    message.success(intl.formatMessage({id: 'phishing.phishing_deactivated'}));

    let key:any;
    Object.entries(phishingCampaigns).map((el:any) => {
        if (el[1].phishingCampaignId === record.phishingCampaignId && !record.campaignId) {
            key = el[0];
        }
        return key;
    });

    let phishingCampaign = phishingCampaigns.filter((el:any) => el.phishingCampaignId !== 0);
    phishingCampaign[key] = newCampaign.phishingCampaign;

    setPhishingCampaigns(phishingCampaign);
    setIsPhishingChanging(false);
  };

  const activateCustomPhishing = async (record: any) => {

    const newCampaign = await coursesApiClient.request(`/api/v1/courses/custom-phishing/start/${record.phishingCampaignId}/${customerUuid || session.organization.organization_uuid}`, {}, 'PUT');

    message.success(intl.formatMessage({id: 'phishing.phishing_enabled'}));
    let key:any;
    Object.entries(phishingCampaigns).map((el:any) => {
        if (el[1].phishingCampaignId === record.phishingCampaignId && !record.campaignId) {
            key = el[0];
        }
        return key;
    });
    let phishingCampaign = phishingCampaigns.filter((el:any) => el.phishingCampaignId !== 0);
    phishingCampaign[key] = newCampaign.phishingCampaign;
    setPhishingCampaigns(phishingCampaign);
    setIsPhishingChanging(false);
  };

  const createCampaign = () => {
    let campaignType;
    if (newCampaignCustomer) {
      changePhishingCampaign({type:campaignType, customerId: customerId, customerUuid:customerUuid});
    }

    if (!selectedCampaignType) {
      campaignType = 'STRICTLY';
    } else {
      campaignType = selectedCampaignType;
    }

    changePhishingCampaign({type:campaignType});
    setShowPhishingCampaignModal(false);
    history.push(`/customer/${customerId}/phishing/campaign`);
  }

  const getRecords = async (resourceUrl: string, pageQuery: string, passedFilter?: FilterValues, filterCustomerUuid?: string, filterCustomerId?: number, hideFinished?: boolean) => {
    let query = (pageQuery !== '') ? '?' + pageQuery : '';

    let uuid: string | undefined;
    let filterValues: FilterValues;

    if (passedFilter) {
        filterValues = passedFilter;
    } else {
        filterValues = filter;
    }

    if (filterCustomerUuid) {
        setCustomerUuid(filterCustomerUuid);
        uuid = filterCustomerUuid;
        setCustomerId(filterCustomerId);
    } else {
        uuid = customerUuid;
    }

    if (hideFinished !== undefined) {
        filterValues.hideFinished = hideFinished;
    } else {
        filterValues.hideFinished = displayUnfinishedCampaigns;
    }

    let response = await coursesApiClient.request(resourceUrl + uuid + query, filterValues, 'POST');
    setFilter(filterValues);

    let campaignArray:any = [];

    Object.values(response.phishingCampaigns.phishing).map((el:any)=> {
      return campaignArray.push(el);
    });

    return  {
      data: (response.phishingCampaigns) ? campaignArray : {},
      pagination: (response.phishingCampaigns.pagination) ? response.phishingCampaigns.pagination : {}
    }
  };

  const handleVSwitchChange = async (hideFinished: boolean) => {
      setIsCampaignsLoading(true);

      let pageQuery = 'page[number]=' + 1 + '&page[size]=' + currentPageSize;

      let response = await getRecords(resourceUrl, pageQuery, undefined, undefined, undefined, hideFinished);

      setPhishingCampaigns(response.data);
      setTotalRecords(response.pagination.total);
      setIsCampaignsLoading(false);
  };

  const handleTableSortFilter = async (sorter: any, tablePage: number) => {
    if (sorter && Object.keys(sorter).length === 0 && sorter.constructor === Object) {
        return;
    }
    let pageQuery = 'page[number]=' + 1 + '&page[size]=' + currentPageSize;

    let query = '&column=' + sorter.field + '&order=' + sorter.order;

    if (currentPage === tablePage && sorter.order) {

        let response: any = await getRecords(resourceUrl, pageQuery + query);

        setPhishingCampaigns(response.data);
        setTotalRecords(response.pagination.total);
        setIsCampaignsLoading(false);

    } else {
      let response = await getRecords(resourceUrl, pageQuery);

      setPhishingCampaigns(response.data);
      setTotalRecords(response.pagination.total);
      setIsCampaignsLoading(false);
    }
};


  return (
    <>
      <DefaultLayout.PageLayout>
        <DefaultLayout.PageHeader title={intl.formatMessage({id: 'phishing.phishing_campaign'})} />
        <DefaultLayout.PageContent>
          <FlexRow
            left={
              <>
                  {session.active_user_type !== 'SUPERVISION' &&
                    <>
                        <Button type="primary" onClick={() => setShowCampaignFilterModal(true)}>
                            <FormattedMessage id={session.active_user_type === 'SUPERVISION' || session.active_user_type === 'CUSTOMER_ADMIN' ? 'general.filter' : 'phishing.filter_campaign'}/>
                        </Button>
                        {isLearningManager && session.access.phishing.allow &&
                            <Button type="primary" onClick={() => setShowPhishingCampaignModal(true)}>
                                <FormattedMessage id="phishing.create_campaign"/>
                            </Button>
                        }
                    </>
                  }
              </>
            }
            right={
              <>
                <FormattedMessage id='campaigns.dont_show_finished'/>
                <Switch
                    disabled={!!!customerUuid}
                    name="showUnfinishedCampaigns"
                    onChange={(el: boolean) => { handleVSwitchChange(el); setDisplayUnfinishedCampaigns(el)}}
                    defaultChecked={displayUnfinishedCampaigns}
                />
                <InstructionButton position='PHISHING' />
              </>
            }
          />

          <Spinner spinning={isCampaignsLoading || isPhishingChanging} opaqueH={isPhishingChanging}>
            {phishingCampaigns
              ?
              <Table
                  locale={{
                      emptyText: intl.formatMessage({id: "general.found_no_data"}),
                      filterConfirm: intl.formatMessage({id: "general.filter"}),
                      filterReset: intl.formatMessage({id: "general.clear"}),
                      triggerDesc: intl.formatMessage({id: "general.trigger_desc"}),
                      triggerAsc: intl.formatMessage({id: "general.trigger_asc"}),
                      cancelSort: intl.formatMessage({id: "general.cancel_sort"}),
                  }}
                resourceUrl = {resourceUrl}
                totalRecords = {totalRecords}
                loadedData = {phishingCampaigns}
                setLoadedData = {setPhishingCampaigns}
                setTotalRecords = {setTotalRecords}
                setCurrentPageSize = {setCurrentPageSize}
                currentPage = {currentPage}
                setCurrentPage = {setCurrentPage}
                setLoading = {setIsCampaignsLoading}
                loading = {isCampaignsLoading}
                columns = {session.active_user_type === 'SUPERVISION' ? columns.filter(element => element.dataIndex !== 'status') : columns}
                pageSizeOptions = {pageSizeOptions}
                getRecords = {getRecords}
                hasSelection = {false}
                onChange={(pagination:any, filters: any, sorter: any) => handleTableSortFilter(sorter, pagination.current)}
            />
              : <Alert
                  message={intl.formatMessage({id: "phishing.select_customer"})}
                  type="info"
                  style={{ margin: 0, marginTop: 15 }}
                  showIcon
                />
            }
          </Spinner>
        </DefaultLayout.PageContent>
      </DefaultLayout.PageLayout>
      <PhishingCampaignFilterModal
          visible={showCampaignFilterModal}
          onCancel={() => setShowCampaignFilterModal(false)}
          getRecords={getRecords}
          resourceUrl={resourceUrl}
          currentPageSize={currentPageSize}
          setPhishingCampaigns={setPhishingCampaigns}
          setTotalRecords={setTotalRecords}
          setLoading={setIsCampaignsLoading}

      />
      <Modal
        className="filter-phishing"
        visible={showPhishingCampaignModal}
        onCancel={() => setShowPhishingCampaignModal(false)}
        title={intl.formatMessage({id: 'phishing.campaign'})}
        okText={intl.formatMessage({id: 'general.create'})}
        cancelText={intl.formatMessage({id: 'general.cancel'})}
        onOk={() => createCampaign()}
      >
        <Form form={modalForm}>
          {
            session.active_user_type === 'SUPER_ADMIN' ?
            <Select
            showSearch
            name='customer'
            label={intl.formatMessage({id: "general.select_customer"})}
            url={config.api.routes.backend.organizationsCustomers}
            integerKey={true}
            customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
            onChange={(value: any) => {
              setNewCampaignCustomer(value);
            }}
            isResponseArray={true}
            dataKey='customers'
            mapDataEntries={(el: any) => ({value: el.uuid + ',' + el.id, label: el.name})}
            valueKey='value'
            labelKey='label'
            customLayout={true}
            filterOption={(input: string, option: any) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          />
          : null
          }
          <Form.Item
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 6 }}
            style={{marginRight:'-25%'}}
            name="campaignType"
            label={intl.formatMessage({id: "phishing.choose_scenario"})}
          >
            <Radio.Group onChange={(el:any)=>setSelectedCampaignType(el.target.value)}>
              <Radio value={'DYNAMIC'}><FormattedMessage id='phishing.dynamic_scenario'/></Radio>
              <Radio value={'STRICTLY'}><FormattedMessage id='phishing.strictly_scenario'/></Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ManagePhishingCampaigns));
