import React from 'react';
import { defaults, Bar } from 'react-chartjs-2';
import { Spin } from 'antd';
import '../styles.scss';

// EXAMPLE DATA
// const data = {
//   labels: ['Team 1', 'Team 2', 'Team 3', 'Team 4', 'Team 5', 'Team 6'],
//   datasets: [
//     {
//       label: 'Not started',
//       backgroundColor: 'rgb(247, 216, 0)',
//       hoverBackgroundColor: 'rgb(227, 196, 0)',
//       data: [100, 20, 60, 25, 35, 30]
//     },
//     {
//       label: 'In progress',
//       backgroundColor: 'rgb(126, 162, 223)',
//       hoverBackgroundColor: 'rgb(106, 142, 203)',
//       data: [20, 10, 25, 5, 30, 15]
//     },
//     {
//       label: 'Complete',
//       backgroundColor: 'rgb(33, 208, 160)',
//       hoverBackgroundColor: 'rgb(13, 188, 140)',
//       data: [10, 110, 15, 40, 50, 45]
//     }
//   ]
// }

interface StackedBarChartProps {
  data: any
  title?: string,
  loading?: boolean,
}

const StackedBarChart: React.FC<StackedBarChartProps> = ({ data, title, loading = false }) => {
  defaults.global.defaultFontFamily = 'Gilroy';
  
  // Could be cleaner
  const getMaxValueOfColumn = (): number => {
    const sumArray: any = [];

    data.datasets?.forEach((dataset: any) => {
      dataset.data.forEach((value: number, index: number) => {
        sumArray[index] = sumArray[index] ? sumArray[index] + value : value;
      });
    });

    return Math.max(...sumArray);
  }

  const options = {
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      xAxes: [{
        stacked: true,
        ticks: {
          fontColor: 'black',
          autoSkip: false,
          maxRotation: 60,
          minRotation: 0,
          callback: (label: string, labelIndex:number, labels: string[]) => {
            // set different  label length, depending on label count and position, for proper display 
            let maxLength = 30;
            
            if(labels.length > 6){
              maxLength = labelIndex > 1 ? 30 : 18;
            }
            
            if(labels.length > 12){
              maxLength = labelIndex > 1 ? 23 : 12;
            }

            return label.substring(0,maxLength) + (label.length > maxLength ? '...': '');
          },
        },
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          suggestedMax: (getMaxValueOfColumn() || 1) * 1.25,
          beginAtZero: true,
          precision: 0,
        },
      }],
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  };

  const renderLegend = (data: any) => {
    return (
      <div className="chart-legend">
        {data.datasets?.map((dataset: any) => {
          return (
            <div className="chart-legend-item">
              <div
                className="chart-legend-badge"
                style={{ backgroundColor: dataset.backgroundColor }}
              />
              <div className="chart-legend-text">
                <p className="chart-legend-label">{dataset.label}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <>
      {title
        ? <p className="chart-title">{title}</p>
        : null}
      <Spin spinning={loading}>
        <Bar
          data={data}
          options={options}
        />
        {renderLegend(data)}
      </Spin>
    </>
  );
}

export default StackedBarChart;
