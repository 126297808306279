import React, { useEffect, useState } from 'react';
import { Route, Redirect, withRouter  } from 'react-router-dom';
import jwt from 'utils/jwt';
import apiClient from 'utils/apiClient';
import Spinner from 'components/Spinner';

const ShortRoute: React.FC<any> = (props) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getToken();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getToken = async () => {
        setLoading(true)
        try {
            let response = await apiClient.request('/api/v1/get-url/' + props.computedMatch.params.code, {}, 'GET');
            props.history.push(response.url);
        } catch (error) {
            props.history.push('/');
        } finally{
            setLoading(false)
        }
    };

    return (
        <Spinner spinning={loading} opaqueH>
            {!!jwt.get() ? <Redirect to="/forgot-password" /> : <Route {...props} />}
        </Spinner>
    )
}

export default withRouter(ShortRoute);
