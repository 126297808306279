interface ValidationRules {
    required ?: boolean,
    email ?: boolean,
    integer ?: boolean
  }
  
  const constructValidationRules = ({required, email, integer}: ValidationRules, intl: any) => {
    let validationRules = [];
  
    if (required) {
      validationRules.push({
        required: true,
        message: intl.formatMessage({id: "validation.field_required"}),
        validateTrigger: 'onSubmit'
      });
    }
  
    if (integer) {
      validationRules.push({
        pattern: /^\d+$/,
        message: intl.formatMessage({id: "validation.must_be_integer"}),
        validateTrigger: 'onSubmit'
      })
    }
  
    return validationRules;
  }
  
  export { constructValidationRules };
  