import { Dispatch } from 'redux';
import {
  APPEARANCE_SETTINGS_GET_IN_PROGRESS,
  APPEARANCE_SETTINGS_GET_SUCCESS,
  APPEARANCE_SETTINGS_GET_FAILED,
} from 'store/actionTypes';
import { AppearanceSettingsData } from 'interfaces/redux';
import apiClient from 'utils/apiClient';
import config from 'config/config';

export const loadAppearanceSettings = (data: AppearanceSettingsData) => (async (dispatch: Dispatch) => {
  dispatch({ type: APPEARANCE_SETTINGS_GET_IN_PROGRESS });

  try {
    let current;
    if (!sessionStorage.getItem('current')) {
      const response = await apiClient.request(config.api.routes.backend.appearanceSettings.current, data, 'POST');

      sessionStorage.setItem('current', JSON.stringify(response));
      current = response;
    } else {
      let currentData: string | null = sessionStorage.getItem('current')
      current = currentData && JSON.parse(currentData);
    }

    dispatch({
      type: APPEARANCE_SETTINGS_GET_SUCCESS,
      payload: current,
    });
  } catch (error) {
    dispatch({
      type: APPEARANCE_SETTINGS_GET_FAILED,
    });
  }
});
