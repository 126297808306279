import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Checkbox, Form, message, Modal } from 'antd';
import { Select, CustomValuesSelect } from 'components/Form';
import { paginatorQuery } from "components/Table/methods";
import Spinner from "components/Spinner";
import apiClient from 'utils/apiClient';
import { connect } from 'react-redux';
import './styles.scss';

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const mapStateToProps = (state: any) => {
    return {
        userTableFilter: state.userTableFilter,
        session: state.session,
        userRole: state.session.active_user_type
    }
};

const ScenarioUserFilter: React.FC<any> = ({
    userRole,
    activeOrganizationId,
    activeOrganizationType,
    setUsers,
    visible,
    onCancel,
    currentPageSize,
    setCurrentPage,
    setTotalRecords,
    setUserFilter,
    userFilter
}) => {
    const [isCustomFiltersLoading, setIsCustomFiltersLoading] = useState(false);
    const [organizationalUnitUrl, setOrganizationalUnitUrl] = useState<string>();

    const intl = useIntl();
    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue({
            filterBy: 'CUSTOMER',
            accountStatus: ['ACTIVE'],
            active: [true, false],
            progress: ['NOT_STARTED', 'IN_PROGRESS', 'COMPLETED'],
            userTypes: ['CUSTOMER_ADMIN', 'STUDENT']
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (activeOrganizationId !== 0 && activeOrganizationType) {
            let url: string = '';
            if (activeOrganizationType === 'CUSTOMER') {
                url = '/api/v1/customer/organizational-unit-types';
                if (userRole === 'SUPER_ADMIN' || userRole === 'RESELLER_ADMIN' || userRole === 'DISTRIBUTOR_ADMIN') {
                    url = url + '?customerId=' + activeOrganizationId;
                }
            } else {
                url = `/api/v1/organizations/${activeOrganizationId}/organizational-unit-types`;
            }
            setOrganizationalUnitUrl(url);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeOrganizationId, activeOrganizationType]);

    const filterUsers = async (values: any = null) => {
        const existingFields = ['userTypes', 'organizationalUnitId'];

        let parsedValues: any = {};

        if (values) {
            let options: any = {};
            parsedValues = {
                filter: {
                    options: {}
                }
            };

            if (values.organizationalUnitId && values.organizationalUnitId.length) {
                options.organizationalUnitId = 'in:' + values.organizationalUnitId.toString();
            }

            options.accountStatus = 'in:ACTIVE'

            parsedValues.filter.options = options;

            let customFields: any = {};
            let customFiltersExist = false;

            Object.entries(values).map((value: any) => {
                if (!existingFields.includes(value[0]) && value[1] && value[1].length) {
                    let customFilterId = value[0].split('_');
                    customFields[customFilterId[customFilterId.length - 1]] = value[1];
                    customFiltersExist = true;
                }
                return customFields;
            });

            if (customFiltersExist) {
                parsedValues.filter.customFields = customFields;
            }

            parsedValues.filter.userTypes = values.userTypes

        } else {
            parsedValues = userFilter
        }

        let requestUrl;

        if (userRole === 'CUSTOMER_ADMIN') {
            requestUrl = '/api/v1/auth/customer/users'
        } else {
            requestUrl = activeOrganizationType === 'CUSTOMER'
                ? '/api/v1/auth/customer/users'
                : `/api/v1/auth/organizations/${activeOrganizationId}/users`;
        }

        const query = paginatorQuery(1, currentPageSize);

        requestUrl = requestUrl + '?' + query;

        if (userRole === 'SUPER_ADMIN' || userRole === 'RESELLER_ADMIN' || userRole === 'DISTRIBUTOR_ADMIN') {
            requestUrl = requestUrl + '&customerId=' + activeOrganizationId
        }
        try {
            setUserFilter(parsedValues.filter);
            onCancel();
            let usersResponse = await apiClient.request(requestUrl, parsedValues, 'POST');

            setUsers(usersResponse.users);

            setCurrentPage(1);
            setTotalRecords(usersResponse.pagination.total);

        } catch (e) {
            console.error(e);
            message.error(intl.formatMessage({ id: "error.data_load" }));
        }
    };

    return (
        <Modal
            className="user-list-modal"
            visible={visible}
            onCancel={onCancel}
            title={intl.formatMessage({ id: 'users.user_filter' })}
            onOk={() => form.submit()}
            okText={intl.formatMessage({ id: 'general.select' })}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
        >
            <Form form={form} onFinish={filterUsers}>
                <Form.Item {...{ labelCol: { span: 6 }, wrapperCol: { span: 18 } }}
                    name="userTypes" label={intl.formatMessage({ id: 'users.user_type' })}>
                    <Checkbox.Group >
                        {activeOrganizationType === 'ORGANIZATION' ?
                            <Checkbox value="ADMIN" style={{ lineHeight: '32px' }}><FormattedMessage id='user.type.ADMIN' /></Checkbox>
                            :
                            <>
                                <Checkbox value="CUSTOMER_ADMIN" style={{ lineHeight: '32px' }}><FormattedMessage id='user.type.CUSTOMER_ADMIN' /></Checkbox>
                                <Checkbox value="STUDENT" style={{ lineHeight: '32px' }}><FormattedMessage id='user.type.STUDENT' /></Checkbox>
                            </>
                        }
                    </Checkbox.Group>
                </Form.Item>
                {organizationalUnitUrl &&
                    <Select
                        mode="multiple"
                        allowClear
                        showSearch
                        name='organizationalUnitId'
                        dataKey='organizationalUnits'
                        maxTagCount={1}
                        showArrow
                        label={intl.formatMessage({ id: "general.unit" })}
                        saveLoadedData={false}
                        url={organizationalUnitUrl}
                        sortOptions={function (a: any, b: any) {
                            let textA = a[1].toUpperCase();
                            let textB = b[1].toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        }}
                        classifierIsObject={true}
                        integerKey={true}
                        customLayout={formItemLayout}
                        dropdownStyle={{ minWidth: "385px" }}
                        filterOption={(input: string, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                    />
                }
                {activeOrganizationId !== 0 &&
                    <>
                        {activeOrganizationType !== 'ORGANIZATION' && activeOrganizationId !== 0 ?
                            <Spinner spinning={isCustomFiltersLoading}>
                                <CustomValuesSelect
                                    customerId={activeOrganizationId}
                                    customLayout={formItemLayout}
                                    allowClear
                                    setLoading={setIsCustomFiltersLoading}
                                    saveLoadedData={false}
                                    role={userRole}
                                    filterOption={(input: string, option: any) => {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }}
                                />
                            </Spinner>
                            : null
                        }
                    </>
                }
            </Form>
        </Modal>
    )
};

export default connect(mapStateToProps)(ScenarioUserFilter);
