const htmlTemplateParse = (html: any) => {
    const VARIABLE_NODE_CLASS_NAME = 'email_template.variable';

    let htmlTemplateForSubmit = html;
    let allUsedVariables = htmlTemplateForSubmit.match(/data-variable="\w*"/g) || [];

    if (htmlTemplateForSubmit.includes('href="{{password_reset_url}}"')) {
        htmlTemplateForSubmit = htmlTemplateForSubmit.replace('href="{{password_reset_url}}"', 'href="[{password_reset_url}]"');
    }

    allUsedVariables.forEach((el: any) => {
        el = el.replace('data-variable="', '');
        el = el.replace('"', '');

        htmlTemplateForSubmit = replaceAll(htmlTemplateForSubmit, `{_{${el}}_}`);

        htmlTemplateForSubmit = htmlTemplateForSubmit.replace(`<span class="${VARIABLE_NODE_CLASS_NAME}" data-variable="${el}">`, '');
        htmlTemplateForSubmit = htmlTemplateForSubmit.replace(`<span data-variable="${el}" class="${VARIABLE_NODE_CLASS_NAME}">`, '');
        htmlTemplateForSubmit = htmlTemplateForSubmit.replace(`</span>`, '');
    });
    htmlTemplateForSubmit = htmlTemplateForSubmit.replace(/{_{/g, '{{');
    htmlTemplateForSubmit = htmlTemplateForSubmit.replace(/}_}/g, '}}');

    htmlTemplateForSubmit = htmlTemplateForSubmit.replace(/\[{/g, '{{');
    htmlTemplateForSubmit = htmlTemplateForSubmit.replace(/}]/g, '}}');

    return htmlTemplateForSubmit;
};

function replaceAll(str: string, replace: string) {
    return str.replace(/{{[^}]*}}/, replace);
}

const exportedObject = {
    htmlTemplateParse
};

export default exportedObject;