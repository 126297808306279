import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { message, Modal, Input, Form } from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import { Select } from 'components/Form';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import apiClient from 'utils/apiClient';
import './styles.scss';

interface CustomActivityModalInterface {
    visible: boolean;
    onCancel: any;
    campaign: any;
    campaignId: number;
    history?: any;
    customCourse: any;
    submit: Function;
}

const formItemLayout = {
    labelCol: { span: 9 },
    wrapperCol: { span: 14 }
  };

const CustomActivityModal: React.FC<CustomActivityModalInterface & RouteComponentProps> = ({ visible, onCancel, campaignId, campaign, history, customCourse, submit }) => {
    const [languageList, setLanguageList] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();
    const intl = useIntl();

    useEffect(() => {
        const loadLanguages = async () => {
            let languages = await apiClient.request(`/api/v1/language`, {}, 'GET');
            let languageListForSelect: any = {};
            let languageListNameForSelect: any = {};
            Object.values(languages.languages).map((value: any) => {
                if (value['publish']) {
                    languageListForSelect[value['code']] = value['code']
                    languageListNameForSelect[value['code']] = value['name']
                }
                return languageListNameForSelect;
            });
            setLanguageList(languageListNameForSelect);
        };

        loadLanguages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    /**
     * 
     * @param values 
     */
    const submitForm = async (values: any) => {
        setIsLoading(true);
        try {
            let courseValues = {
                name: campaign.name,
                firstLanguage: values.language,
                active: 1,
                campaignCourse: campaignId
            }
            submit();

            let courseResponse = await coursesApiClient.request(`/api/v1/courses/courses`, courseValues, 'POST');

            const course = courseResponse.course;

            const parsedValues = {
                name: values.name,
                language: values.language,
                status: 'ONLINE',
                courseId: course.id
            }
            const response = await coursesApiClient.request(`/api/v1/courses/material/store`, parsedValues, 'POST');

            history.push(`/courses/${course.id}/material/${response.activity.id}`);
        } catch (error) {
            console.error(error);
            message.error(intl.formatMessage({ id: "error.server_error" }));
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={intl.formatMessage({ id: 'courses.add_new_topic' })}
            okText={intl.formatMessage({ id: 'general.submit' })}
            cancelText={intl.formatMessage({ id: 'general.back' })}
            onOk={form.submit}
            confirmLoading={isLoading}
        >
            <Form form={form} onFinish={submitForm}>
                <Form.Item labelCol={formItemLayout.labelCol} wrapperCol={formItemLayout.wrapperCol} name='name' label={intl.formatMessage({ id: 'courses.activity_name' })} rules={[{ required: true, message: intl.formatMessage({ id: 'validation.field_required' }) }]}>
                    <Input />
                </Form.Item>
                <Select
                    customLayout={formItemLayout}
                    name='language'
                    label={intl.formatMessage({ id: "general.language" })}
                    manualOptions={languageList}
                    customRules={[{ required: true, message: intl.formatMessage({ id: "validation.field_required" }) }]}
                />
            </Form>
        </Modal>
    );
}

export default withRouter(CustomActivityModal);
