import React, { ReactText, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Dropdown, Menu, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { MenuInfo } from 'rc-menu/lib/interface';
import FileDownload from 'js-file-download';
import coursesApiClient from 'utils/coursesApiClient';
import {connect} from "react-redux";

interface PhishingDownloadProps {
  campaignId: number;
  locale?: string;
}

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    }
};

const PhishingDownload: React.FC<PhishingDownloadProps> = ({ campaignId, locale }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const intl = useIntl();

  const downloadStatistics = async (key: ReactText) => {
    try {
      setIsDownloading(true);

      const urls: { [key: string]: string } = {
        'phishing_overall_results': `/api/v1/courses/campaigns/${campaignId}/phishing-export`,
      };

      const response = await coursesApiClient.request(urls[key], {language: locale}, 'GET', true, true);

      if (response.fileName) {
        FileDownload(response, response.fileName);
      } else {
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      }
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({ id: 'error.data_load' }));
    } finally {
      setIsDownloading(false);
    }
  };

  const handleMenuClick = (e: MenuInfo) => downloadStatistics(e.key);

  const dropdownMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="phishing_overall_results">
        <FormattedMessage id="phishing.phishing_overall_results" />
      </Menu.Item>

    </Menu>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8 }}>
      <Dropdown overlay={dropdownMenu} trigger={['click']}>
        <Button type="primary" loading={isDownloading}>
          <i className="fal fa-file-download" />
          <span><FormattedMessage id="phishing.export_phishing_statistics" /></span>
          <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default connect(mapStateToProps)(PhishingDownload);
