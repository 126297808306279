import React from 'react';
import { useIntl } from 'react-intl';
import { CurrentImageState } from '../../types';
import { FileInput } from 'components/Form';
import { Button, Form, message, Modal, Popover, Spin } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons';
import { AppearanceSettingsData } from 'interfaces/redux';
import apiClient from 'utils/apiClient';
import config from 'config/config';

const { confirm } = Modal;

interface FileInputWithPreviewProps {
  currentSettingsAreSameAsSaved: () => boolean;
  getAppearanceSettingsData: () => ({ resellerId: number }|{ customerId: number } | {});
  loadAppearanceSettings: (data: AppearanceSettingsData) => void;
  name: 'logo'|'favicon';
  label: string;
  previewState: CurrentImageState;
  setPreviewState: React.Dispatch<React.SetStateAction<CurrentImageState>>;
  initialState: CurrentImageState;
  loadSettings: () => Promise<void>;
}

const FileInputWithPreview: React.FC<FileInputWithPreviewProps> = ({
  currentSettingsAreSameAsSaved,
  getAppearanceSettingsData,
  loadAppearanceSettings,
  name,
  label,
  previewState,
  setPreviewState,
  initialState,
  loadSettings,
}) => {
  const intl = useIntl();

  const popoverContent = (
    <Spin spinning={previewState.isLoading}>
      <div style={{ padding: 16 }}>
        <img
          src={previewState.file ? URL.createObjectURL(previewState.file) : undefined}
          alt={`${previewState.environmentName} ${name}`}
          style={{ display: 'block', margin: '0 auto' }}
        />
      </div>
    </Spin>
  );

  const showDeleteConfirm = (id: number, type: 'logo'|'favicon') => {
    const deleteImage = async () => {
      try {
        const url = config.api.routes.backend.appearanceSettings.delete
          .replace('{id}', id.toString())
          .replace('{type}', type);

        await apiClient.request(url, {}, 'DELETE');

        setPreviewState(initialState);

        message.success(`${intl.formatMessage({ id: `system.environment.${name}_deleted` })}!`);

        if (currentSettingsAreSameAsSaved()) {
          loadAppearanceSettings(getAppearanceSettingsData());
        }

        await loadSettings();
      } catch (error) {
        console.error(error);
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      }
    };

    confirm({
      title: intl.formatMessage({ id: `system.environment.${name}_deletion_confirmation` }),
      icon: <ExclamationCircleOutlined />,
      content: intl.formatMessage({ id: `system.environment.${name}_deletion_description` }),
      okText: intl.formatMessage({ id: 'general.yes' }),
      cancelText: intl.formatMessage({ id: 'general.no' }),
      onOk: deleteImage,
    });
  };

  return (
    <>
      <FileInput name={name} label={label} accept="image/*" />
      {(previewState.isSet || previewState.isLoading) && (
        <Form.Item label={intl.formatMessage({ id: `system.environment.current_${name}` })}>
          <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Popover
              placement="top"
              title={
                <div style={{ textAlign: 'center' }}>
                  {previewState.fileName}
                </div>
              }
              content={popoverContent}
            >
              <Button style={{ display: 'flex', justifyContent: 'center' , alignItems: 'center', width: 'calc(100% - 38px)' }}>
                  <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {previewState.fileName}
                  </span>
                <EyeOutlined style={{ lineHeight: 0 }} />
              </Button>
            </Popover>
            <Button
              onClick={() => showDeleteConfirm(previewState.settingsId!, name)}
              style={{ marginLeft: 6 }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        </Form.Item>
      )}
    </>
  );
};

export default FileInputWithPreview;