import Cookies from 'js-cookie';
import api from './apiClient';
import config from 'config/config';
import { Modal } from 'antd';
import { match } from 'react-router-dom';


const set = (token: any, expires: any) => {
  const expirationDate = new Date(new Date().getTime() + expires * 1000);
  Cookies.set('jwt', token, {expires: expirationDate});
};

const get = () => {
  return Cookies.get('jwt');
};

const getHeader = () => {
  return 'Bearer ' + get();
};

const isTokenActive = () => {
  return get() ? true : false
};

const remove = () => {
  Cookies.remove('jwt');
};

const refresh = async () => {
  const response = await api.request(config.api.routes.auth.refresh, {}, 'POST', false);
  const token = response.token;
  const expires = 86400;
  set(token, expires);
};

const login = async (login: string, password: string, match: match<{uuid: string}> | null, countryCode: string | null) => {
  const response = await api.request(config.api.routes.auth.login, {login: login, password: password, connection: match?.params.uuid, countryCode: countryCode}, 'POST');

  if (response.hasOwnProperty('error') && response.code !== 200) {
    throw new Error(response.error)
  }

  const token = response.token;
  const expires = 86400;
  set(token, expires);
};

const logout = async () => {
  await api.request(config.api.routes.auth.logout, {}, 'POST');
  sessionStorage.removeItem('current');
  remove();
  window.location.reload();
};

const switchUserRole = async (userTypeId: any) => {
  let switchUserRoleResponse = await api.request(config.api.routes.auth.switchUserRole, {}, 'POST', true, false, {userTypeId});

  if (switchUserRoleResponse.token) {
    remove();

    set(switchUserRoleResponse.token, 86400);

    if (window.location.pathname === '/403') {
      window.location.assign('/')
    } else {
      window.location.reload();
    }
  } else {
    let errorMessage = 'switch_to_user_role_failed'

    if (switchUserRoleResponse.error === 'customer_has_no_platform_access') {
      errorMessage = 'users.' + switchUserRoleResponse.error
    }
    if (switchUserRoleResponse.error === 'exceeded_max_allowed_students_count') {
      errorMessage = 'error.' + switchUserRoleResponse.error
    }

    Modal.error({
      title: 'Error',
      content: errorMessage,
      onOk:() => window.location.reload()
    });
  }
}

const switchUser = async (userId: any, userTypeId: any) => {
    let switchUserResponse = await api.request('/api/v1/auth/users/' + userId + '/switch-to-user/' + userTypeId, {}, 'POST', true, false, {});

    if (switchUserResponse.token) {
      remove();

      set(switchUserResponse.token, 86400);

      return true
    } else {
      console.error(switchUserResponse.error);

      return false
    }
}

const exportedObject = {
  set, get, getHeader, isTokenActive, remove, refresh, login, logout, switchUserRole, switchUser
};

export default exportedObject;
