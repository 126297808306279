import React, {useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {Form, InputNumber, message, Modal, Transfer} from 'antd';
import {Select} from 'components/Form';
import apiClient from 'utils/apiClient';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import config from 'config/config';

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    }
};

const customerFormItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12}
};

const DemoContentRequestModal = ({visible, onCancel, serviceType, afterSubmit, contentUuid, transferData, serviceId, session, displayButton, contentType, setLoader}: any) => {
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [targetKeys, setTargetKeys] = useState([]);
    const [customers, setCustomers] = useState();
    const [customersLoading, setCustomersLoading] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);

    const intl = useIntl();
    const [form] = Form.useForm();
    const userRole = session.active_user_type;

    useEffect(() => {
        const loadCustomers = async () => {
            setCustomersLoading(true);
            let customersResponse = await apiClient.request(config.api.routes.backend.organizationsCustomers, {}, 'GET');
            let customersArray: any = {};

            Object.values(customersResponse.customers).map((el: any) => {
                return customersArray[el.id] = el.name;
            });

            setCustomers(customersArray);
            setCustomersLoading(false);
        };

        if (userRole === 'DISTRIBUTOR_ADMIN' ||
            userRole === 'RESELLER_ADMIN' ||
            userRole === 'SUPER_ADMIN'
        ) {
            loadCustomers();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    const renderInputs = () => {
        switch (serviceType) {
            case 'E_LEARNING_CONTENT':
                if (transferData) {
                    return (
                        <>
                            <Transfer
                                locale={{notFoundContent: intl.formatMessage({id: "general.found_no_data"})}}
                                dataSource={transferData}
                                targetKeys={targetKeys}
                                selectedKeys={selectedKeys}
                                onSelectChange={(value: any) => {
                                    setSelectedKeys(value)
                                }}
                                onChange={(value: any) => {
                                    setTargetKeys(value)
                                }}
                                render={item => item.name}
                                selectAllLabels={[intl.formatMessage({id: 'campaign.available'}, {amount: transferData.length - targetKeys.length}), intl.formatMessage({id: 'campaign.added'}, {amount: targetKeys.length})]}
                                oneWay
                                showSelectAll={false}
                                disabled={!(!!serviceId)}
                            />
                            {userRole === 'DISTRIBUTOR_ADMIN' || userRole === 'RESELLER_ADMIN' || userRole === 'SUPER_ADMIN' ?
                                <div style={{marginTop: 32}}>
                                    <Select
                                        name='customers'
                                        label={intl.formatMessage({id: 'general.customer'})}
                                        manualOptions={customers}
                                        loading={customersLoading}
                                        onChange={(value: any, options: any) => setSelectedCustomers(options.value)}
                                        customLayout={customerFormItemLayout}
                                        showSearch
                                        filterOption={(input: string, option: any) => {
                                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }}
                                    />
                                </div>
                                :
                                null
                            }
                            <Form.Item
                                name="maxAccess"
                                label={intl.formatMessage({id: 'licensing.max_access'})}
                                rules={[{
                                    required: true,
                                    message: intl.formatMessage({id: 'validation.field_required'})
                                }]}
                                style={{marginTop: 32}}
                                {...customerFormItemLayout}
                            >
                                <InputNumber disabled defaultValue={2} min={2} max={2} style={{width: '100%'}}/>
                            </Form.Item>
                        </>
                    );
                }
                break;
            case 'PLATFORM_ACCESS':
            case 'PHISHING_SERVICE':
                return (
                    <>
                        {userRole === 'DISTRIBUTOR_ADMIN' || userRole === 'RESELLER_ADMIN' || userRole === 'SUPER_ADMIN' ?
                            <div style={{marginTop: 32}}>
                                <Select
                                    name='customers'
                                    label={intl.formatMessage({id: 'general.customer'})}
                                    manualOptions={customers}
                                    loading={customersLoading}
                                    onChange={(value: any, options: any) => setSelectedCustomers(options.value)}
                                    customLayout={customerFormItemLayout}
                                    showSearch
                                    filterOption={(input: string, option: any) => {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }}
                                />
                            </div>
                            :
                            null
                        }
                        <Form.Item
                            name="maxAccess"
                            label={intl.formatMessage({id: 'licensing.max_users'})}
                            rules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                            {...customerFormItemLayout}
                        >
                            <InputNumber disabled defaultValue={2} min={2} max={2} style={{width: '100%'}}/>
                        </Form.Item>
                    </>
                );
            case 'APPEARANCE_SERVICE':
                return <FormattedMessage id='content.request_appearance_licence' />;
        }
    };

    const submit = async () => {
        if (serviceType === 'E_LEARNING_CONTENT' && targetKeys.length === 0) {
            return (
                message.error(intl.formatMessage({id: "error.content_not_submitted"}))
            )
        }

        let organizationType: any;

        switch (session.active_user_type) {
            case 'DISTRIBUTOR_ADMIN':
                organizationType = 'distributor';
                break;
            case 'RESELLER_ADMIN':
                organizationType = 'reseller';

                break;
            case 'CUSTOMER_ADMIN':
                organizationType = 'customer';
                break;
        }

        let parsedValues: any = {
            customerId: selectedCustomers ? selectedCustomers : session.organization.organization_id,
            organizationType: organizationType,
            contentType: contentType
        };

        if (serviceType === 'E_LEARNING_CONTENT') {
            parsedValues.learningActivities = targetKeys;
            parsedValues.serviceId = serviceId;
        }

        let response = undefined;

        try {
            setLoader(true);
            afterSubmit();
            response = await apiClient.request(`/api/v1/content/${contentUuid}/request-demo`, parsedValues, 'POST');
            setLoader(false);

            if (response) {
                message.success(intl.formatMessage({id: "subscriptions.content.request_demo"}));
                if (userRole === 'CUSTOMER_ADMIN') {
                    displayButton(false);
                }
            }

        } catch (error: any) {
            setLoader(false);
            if (error.message === 'demo_already_used') {
                message.warning(intl.formatMessage({id: "licensing.content.demo_already_used"}));
            } else {
                console.error(error);
                message.error(intl.formatMessage({id: "error.server_error"}));
            }
            afterSubmit();
        }
    };

    return (
        <>
            <Modal
                title={intl.formatMessage({id: 'licensing.content.add_content'})}
                visible={visible}
                onCancel={onCancel}
                onOk={() => {
                    submit();
                }}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
                okText={intl.formatMessage({id: 'general.submit'})}
            >
                <Form form={form} onFinish={submit}>
                    {renderInputs()}
                </Form>
            </Modal>
        </>
    )
}
export default connect(mapStateToProps)(withRouter(DemoContentRequestModal));
