import React from 'react';
import {Form, Radio} from 'antd';
import {FormattedMessage, useIntl} from 'react-intl';
import {FilterBySelectorInterface} from '../types';
import CustomerSelect from "../../Form/CustomerSelect";
import config from "config/config";

const FilterBySelector: React.FC<FilterBySelectorInterface> = ({
                                             setFilterBy,
                                             setActiveOrganizationType,
                                             setActiveOrganizationId,
                                             setActiveOrganizationUuid,
                                             form,
                                             filterBy,
                                             setOrganizationResponse,
                                             isResellerAdmin,
                                             activeOrganizationId,
                                             setCampaignUrl
                                         }) => {
    const intl = useIntl();

    return (
            <>
                <Form.Item
                    name="filterBy"
                    label={intl.formatMessage({id: 'general.filter_by'})}
                >
                    <Radio.Group
                        onChange={(el: any) => {
                            setFilterBy(el.target.value);
                            if (el.target.value === 'CUSTOMER') {
                                setActiveOrganizationType('CUSTOMER');
                                setActiveOrganizationId(0);
                                form.setFieldsValue({
                                    userTypes: ['CUSTOMER_ADMIN', 'STUDENT']
                                });

                            } else {
                                setActiveOrganizationType('ORGANIZATION');
                                setActiveOrganizationId(0);
                                form.setFieldsValue({
                                    userTypes: ['ADMIN']
                                });
                            }
                            form.setFieldsValue({
                                customer: [],
                                organization: [],
                                organizationalUnitId: [],
                            });

                        }}
                    >
                        <Radio value="CUSTOMER"><FormattedMessage id='general.customer'/></Radio>
                        <Radio value="ORGANIZATION"><FormattedMessage id='general.reseller'/></Radio>
                    </Radio.Group>
                </Form.Item>
                {
                    filterBy === 'CUSTOMER'
                        ? <CustomerSelect
                            saveLoadedData={true}
                            setLoadedData={setOrganizationResponse}
                            showSearch
                            name='customer'
                            label={intl.formatMessage({id: "general.customer"})}
                            url={config.api.routes.backend.organizationsCustomers}
                            customRules={[{
                                required: true,
                                message: intl.formatMessage({id: "validation.field_required"})
                            }]}
                            integerKey={true}
                            onChange={(value: number, options:any) => {
                                setActiveOrganizationId(value);
                                setActiveOrganizationType('CUSTOMER');
                                setActiveOrganizationUuid(options.uuid);
                                form.setFieldsValue({
                                    organizationalUnitId: [],
                                    reseller: [],
                                });
                            }}
                            onSelect={(value: any, options: any) => {
                                setCampaignUrl(`/api/v1/courses/customer/${options.uuid}/campaigns`)
                            }}
                            isResponseArray={true}
                            dataKey='customers'
                            mapDataEntries={(el: any) => ({
                                value: el.id,
                                label: el.name,
                                uuid: el.uuid,

                            })}
                            valueKey='value'
                            labelKey='label'
                            customLayout={true}
                            filterOption={(input: string, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                        />
                        : null
                }
                {

                    filterBy === 'ORGANIZATION'
                        ? <CustomerSelect
                            saveLoadedData={true}
                            setLoadedData={setOrganizationResponse}
                            showSearch
                            name='organization'
                            label={intl.formatMessage({id: "general.reseller"})}
                            customRules={[{
                                required: true,
                                message: intl.formatMessage({id: "validation.field_required"})
                            }]}
                            url={config.api.routes.backend.organizationsOptions}
                            integerKey={true}
                            onChange={(value: number) => {
                                setActiveOrganizationId(value);
                                setActiveOrganizationType('ORGANIZATION');
                                form.setFieldsValue({
                                    organizationalUnitId: [],
                                    customer: []
                                });

                            }}
                            isResponseArray={true}
                            dataKey='organizations'
                            mapDataEntries={(el: any) => ({
                                value: el.id,
                                label: el.name,
                                customerType: el.type,
                                uuid: el.uuid,

                            })}
                            valueKey='value'
                            labelKey='label'
                            customLayout={true}
                            filterOption={(input: string, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                        />
                        : null
                }
            </>
    );
};

export default FilterBySelector;
