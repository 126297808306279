import React, {useState, useEffect} from 'react';
import { useIntl } from 'react-intl';
import { Alert, Card, Col, message, Row } from 'antd';
import coursesApiClient from 'utils/coursesApiClient';
import Spinner from 'components/Spinner';
import DoughnutChart from 'components/Charts/DoughnutChart';
import StackedBarChart from 'components/Charts/StackedBarChart';
import CustomPhishingDownload from 'components/Phishing/CustomPhishingDownload';
import {connect} from "react-redux";

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    }
};

const CustomPhishingCampaignResulsts = ({phishingCampaign, session}: any) => {
  const [organizationalUnits, setOrganizationalUnits] = useState();
  const [emailSent, setEmailSent] = useState();
  const [emailOpened, setEmailOpened] = useState();
  const [linkOpened, setLinkOpened] = useState();
  const [dataSubmitted, setDataSubmitted] = useState();
  const [emailReported, setEmailReported] = useState();
  const [loadPhishingByUnit, setLoadPhishingByUnit] = useState(true);
  const [doughnutChart, setDoughnutChart] = useState<any>();

  const intl = useIntl();

    useEffect(() => {
      const loadPhishingResultsByOrganizationalUnit = async () => {
        let results = await coursesApiClient.request(`/api/v1/courses/custom-phishing/results/${phishingCampaign.id}`, [], 'GET');
        try {
          let organizationalUnitsArray:any =  [];
          let emailSentArray:any =  [];
          let emailOpenedArray:any =  [];
          let linkOpenedArray:any =  [];
          let dataSubmittedArray:any =  [];
          let emailReportedArray:any = [];

          Object.values(results.summary).map((el:any)=> {
            organizationalUnitsArray.push(el.organizational_unit);
            emailSentArray.push(el.sent - el.opened);
            emailOpenedArray.push(el.opened - el.clicked);
            linkOpenedArray.push(el.clicked - el.submitted_data);
            emailReportedArray.push(el.email_reported);
            dataSubmittedArray.push(el.submitted_data);

            return [organizationalUnitsArray, emailSentArray, emailOpenedArray, linkOpenedArray, emailReportedArray, dataSubmittedArray];
          });

          setOrganizationalUnits(organizationalUnitsArray);
          setEmailReported(emailReportedArray);
          setEmailSent(emailSentArray);
          setEmailOpened(emailOpenedArray);
          setLinkOpened(linkOpenedArray);
          setDataSubmitted(dataSubmittedArray);
        } catch (error) {
          message.error(intl.formatMessage({id: "error.server_error"}));
        } finally {
          setLoadPhishingByUnit(false);
        }
      };

      const loadDoughnutData = async () => {
        let phishingCampaignResponse = await coursesApiClient.request(`/api/v1/courses/custom-phishing/phishing-campaign/${phishingCampaign.id}`, [], 'GET');

        let phishingResulsts = phishingCampaignResponse.customPhishingCampaign.summary;

        if (!phishingResulsts) {
          setDoughnutChart(null);
          return null;
        }

        const data = {
          labels: [
              intl.formatMessage({id: 'phishing.success'}),
              intl.formatMessage({id: 'phishing.failed'}),
          ],
          datasets: [{
              data: [phishingResulsts.sent - phishingResulsts.clicked, phishingResulsts.clicked],
              backgroundColor: [
                  'RGB(33, 208, 160)',
                  'RGB(244, 98, 100)'
                ],
                hoverBackgroundColor: [
                  'RGB(13, 188, 140)',
                  'RGB(224 ,68, 80)',
                ]
          }]
        };
        setDoughnutChart(data);
      };

      loadPhishingResultsByOrganizationalUnit();
      loadDoughnutData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

  const data2 = {
    labels: organizationalUnits,
    datasets: [
      {
        label: intl.formatMessage({id: 'phishing.email_sent'}),
        backgroundColor: '#63CDA3',
        hoverBackgroundColor: '#63CDA3',
        data: emailSent
      },
      {
        label: intl.formatMessage({id: 'phishing.email_opened'}),
        backgroundColor: '#85A1DA',
        hoverBackgroundColor: '#85A1DA',
        data: emailOpened
      },
      {
        label: intl.formatMessage({id: 'phishing.link_opened'}),
        backgroundColor: '#F2D943',
        hoverBackgroundColor: '#F2D943',
        data: linkOpened
      },
      {
        label: intl.formatMessage({id: 'phishing.data_submitted'}),
        backgroundColor: '#E36B69',
        hoverBackgroundColor: '#E36B69',
        data: dataSubmitted
      },
      {
        label: intl.formatMessage({id: 'phishing.email_reported'}),
        backgroundColor: '#94f7cf',
        hoverBackgroundColor: '#94f7cf',
        data: emailReported
      }
    ]
  };

  return (
    <>
    {session.active_user_type !== 'SUPERVISION' &&
      <CustomPhishingDownload campaignId={phishingCampaign.id}/>
    }
    { doughnutChart ?
      <Row style={{marginLeft: -8, marginRight: -8, marginTop: 16}} gutter={[16, 16]}>
      <Col xs={24} md={12} span={8}>
        <Card bordered={true} className="dashboard-card">
          <Spinner spinning={loadPhishingByUnit}>
            {doughnutChart ?
                <DoughnutChart
                  title={intl.formatMessage({id: 'phishing.fail_rate'})}
                  data={doughnutChart}
                  cutoutPercentage={0}
                />
              : null
            }
          </Spinner>
        </Card>
      </Col>
      <Col xs={24} md={12} span={8}>
          <Card bordered={true} className="dashboard-card">
            <Spinner spinning={loadPhishingByUnit}>
              <div className='phishing-bar-chart'>
                {dataSubmitted ?
                  <StackedBarChart
                    title={intl.formatMessage({id: 'phishing.results_in_cross_section'})}
                    data={data2}
                  />
                : null
                }
              </div>
            </Spinner>
          </Card>
      </Col>
    </Row>
    :
    <Alert className='smartdephish-alert' message={intl.formatMessage({id: "general.found_no_data"})} type="info" showIcon style={{marginBottom: 24}} />
    }

    </>
    )
};
export default connect(mapStateToProps)(CustomPhishingCampaignResulsts);
