import React, { useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tooltip, Popover, message, Progress, Tag } from 'antd';

import apiClient from 'utils/apiClient';
import Table from 'components/Table';
import coursesApiClient from 'utils/coursesApiClient';
import { StudentTableInterface } from '../types';
import SubmittedFilesModal from './SubmittedFilesModal';
import StudentActivityQuickView from './StudentActivityQuickView';
import { changeStudentViewData } from 'store/actions/saveStudentViewDataActions';
import moment from 'moment';

const mapStateToProps = (state: any) => {
    return {
        activeUserRole: state.session.active_user_type
    }
}

const mapDispatchToProps = (dispatch: any) => ({
    changeStudentViewData: (formData: any) => dispatch(changeStudentViewData(formData))
});

const StudentTable = ({
    status,
    campaignId,
    campaign,
    owner,
    customFilterValues,
    pageSizeOptions,
    setCurrentPageSize,
    history,
    getRecords,
    students,
    setStudents,
    resourceUrl,
    totalRecords,
    setTotalRecords,
    setExcludedUsers,
    reloadTable,
    rowSelection,
    setRowSelection,
    activeUserRole,
    changeStudentViewData
}: StudentTableInterface) => {

    const intl = useIntl();

    const [visibleTooltip, setVisibleTooltip] = useState<number | null>(null);
    const [selectedUsers, setSelectedUsers] = useState<any>([]);

    const [selectAllFiltered, setSelectAllFiltered] = useState(false);

    const [isUsersLoading, setIsUsersLoading] = useState(false);
    const [selectedRowsLength, setSelectedRowsLength] = useState<number>(0);

    const [currentPage, setCurrentPage] = useState<number>(1);

    const [showSubmittedFiles, setShowSubmittedFiles] = useState(false);
    const [enrollmentId, setEnrollmentId] = useState(0);
    const [quickSelectUser, setQuickSelectUser] = useState(null);

    /**
     * 
     * @param record 
     */
    const checkIfUserAvailable = async (record: any) => {
        let parsedValues = {
            userUuid: record.userUuid,
            customerId: owner?.id,
            campaignId: campaignId,
            enrollmentId: record.enrollmentId
        };

        try {
            setVisibleTooltip(null);

            let response = await apiClient.request('/api/v1/manager/user', parsedValues, 'POST');

            if (response.allow) {
                history.push(`/customer/${owner?.id}/user/${record.userUuid}/view`);
            } else {
                message.error(intl.formatMessage({ id: 'error.not_allowed' }));
            }
        } catch (err) {
            console.error(err);
            message.error(intl.formatMessage({ id: "error.data_load" }));
        }
    };

    /**
     * 
     * @param record 
     */
    const onDelete = async (record: any) => {
        if (record.enrollmentId) {
            await coursesApiClient.request(`/api/v1/courses/campaigns/enrollment/${record.enrollmentId}/remove`, {}, 'POST');
        }

        if (!record.enrollmentId) {
            setExcludedUsers((excludedUsers: any) => [...excludedUsers, record.uuid]);
        }

        reloadTable();
    };

    const popoverContent = (
        <>
            <div>
                <FormattedMessage id="campaign.student_actions_delete_hint" />
            </div>
            <div>
                <FormattedMessage id="campaign.student_actions_cancel_enrollment_hint" />
            </div>
        </>
    );

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    }

    /**
     * 
     * @param visible 
     * @param record 
     */
    const handleVisibleQuickViewChange = (visible: boolean, record: any) => {
        if (visible) {
            setQuickSelectUser(record);
        } else {
            setQuickSelectUser(null);
        }
    }

    /**
     * 
     * @param record 
     */
    const setStudentData = (record: any) => {
        changeStudentViewData({
            campaignId: campaignId,
            enrollmentId: record.enrollmentId,
            userUuid: record.userUuid,
            customerId: owner?.id,
            campaignTitle: campaign?.name
        });
    };

    const columnsStudent = [
        {
            title: intl.formatMessage({ id: 'general.name' }),
            dataIndex: 'name',
            render: (_name: string, record: any) => {
                if (activeUserRole !== 'SUPERVISION') {
                    const url = `/customer/${owner?.id}/user/${record.userUuid}/view/campaign,${campaignId},students`;
                    return (
                        <div className='user-name-style' onClick={() => {
                            setStudentData(record); history.push({
                                pathname: url,
                                state: {
                                    fromCampaign: true,
                                    campaignTitle: campaign?.name,
                                    campaignUrl: window.location.pathname,
                                    campaignId: campaignId,
                                    enrollmentId: record.enrollmentId
                                }
                            })
                        }}>
                            {record.name}
                        </div>
                    )
                } else {
                    return (
                        <div className='user-name-style' onClick={() => checkIfUserAvailable(record)}>
                            {record.name}
                        </div>
                    )
                }
            }
        },
        {
            title: intl.formatMessage({ id: 'general.last_name' }),
            dataIndex: 'surname',
        },
        {
            title: intl.formatMessage({ id: 'general.email' }),
            dataIndex: 'email',
        },
        {
            title: intl.formatMessage({ id: 'users.organizational_unit' }),
            dataIndex: 'organizationalUnitName',
        },
        {
            title: intl.formatMessage({ id: 'campaign.learning_progress' }),
            dataIndex: 'learningProgress',
            key: 'learningProgress',
            render: (_text: string, record: any) => {
                if (record.result !== undefined) {

                    const content = <>
                        <StudentActivityQuickView record={record} campaignId={campaignId} />
                    </>
                    return (
                        <Popover
                            visible={record.id === quickSelectUser}
                            content={content}
                            trigger="click"
                            placement="top"
                            arrowPointAtCenter
                            onVisibleChange={(visible) => handleVisibleQuickViewChange(visible, record.id)}
                        >
                            <div className={quickSelectUser === record.id ? 'quick-progress-view' : 'quick-progress-view-hover'}>
                                <Tooltip title={intl.formatMessage({ id: 'campaign.procent_completed' }, { amount: record.result })}>
                                    <Progress percent={record.result} showInfo={false} strokeColor='#52C419' trailColor='#F9AD15' />
                                </Tooltip>
                            </div>
                        </Popover>
                    );
                }
            }
        },
        {
            title: intl.formatMessage({ id: 'general.status' }),
            dataIndex: 'status',
            key: 'status',
            render: (_text: string, record: any) => {
                const getParams = (): any => {
                    if (!record.isEnrollmentCanceled) {
                        switch (record.campaignAttemptStatus) {
                            case ('NOT_STARTED'):
                                if (status === 'FINISHED') {
                                    return { color: 'red', message: 'student.unfinished', className: 'student-campaign-tag-unfinished' };
                                } else {
                                    return { color: 'gold', message: 'student.not_started', className: 'student-campaign-tag-not-started' };
                                }
                            case ('IN_PROGRESS'):
                                if (record.hasAssignments && record.submittedFiles) {
                                    return { color: 'geekblue', message: 'student.submitted_assignment', className: 'student-campaign-tag-process' };
                                } else if (!!campaign?.signatureJournal && record.result === 100 && !record.signature) {
                                    return { color: 'geekblue', message: 'student.to_sign', className: 'student-campaign-tag-process' };
                                } else {
                                    if (status === 'FINISHED') {
                                        return { color: 'red', message: 'student.unfinished', className: 'student-campaign-tag-unfinished' };
                                    } else {
                                        return { color: 'blue', message: 'student.in_progress', className: 'student-campaign-tag-process' };
                                    }
                                }
                            case ('COMPLETED'):
                                return { color: 'green', message: 'student.completed', className: 'student-campaign-tag-finished' };
                        }

                        return { notFound: true };
                    } else return { color: 'volcano', message: 'student.canceled', className: 'student-campaign-tag-canceled' };

                }
                if (getParams().notFound) return null;
                return (
                    <Tag color={getParams().color} key={record.id} className={getParams().className}>
                        {intl.formatMessage({ id: getParams().message })}
                    </Tag>
                );
            }
        },
        {
            title: intl.formatMessage({ id: 'campaign_form.students.IndividualCampaignDeadline' }),
            dataIndex: 'enrollmentDeadline',
            render: (text: string) => {
                if (!text) {
                    return '';
                }

                return moment(text).format('YYYY-MM-DD');
            }
        },
        {
            title: intl.formatMessage({ id: 'campaign.date_of_last_activity' }),
            dataIndex: 'dateOfLastActivity',
            key: 'dateOfLastActivity',
            render: (_text: string, record: any) => record.last_login
        },
        {
            title: intl.formatMessage({ id: 'campaign.filter_signs' }),
            dataIndex: 'userCustomFields',
            key: 'userCustomFields',
            render: (_text: string, record: any) => {
                if (customFilterValues && record.userCustomFields && Object.keys(record.userCustomFields).length > 0) {
                    let filterValues: any = [];
                    Object.entries(record.userCustomFields).map((value: any) => {
                        let filter = customFilterValues.filter((el: any) => el.slug === value[0]);
                        if (filter.length) {
                            let values = filter[0].values;
                            filterValues.push(values[value[1]]);
                            return filterValues;
                        }
                    });
                    return (
                        filterValues.map((el: string) => {
                            return (
                                <Tag color={'gray'} className='ant-tag-gray'>
                                    {el}
                                </Tag>
                            )
                        })
                    )
                }
            }
        },
        {
            title: (
                <div>
                    {activeUserRole !== 'SUPERVISION' &&
                        <>
                            <FormattedMessage id="general.actions" />
                            <Tooltip title={popoverContent}>
                                <i className="fal fa-question-circle" style={{ marginLeft: '3px' }} />
                            </Tooltip>
                        </>
                    }
                </div>
            ),
            dataIndex: 'popoverActions',
            key: 'popoverActions',
            render: (_text: string, record: any) => {
                const content = <>
                    <div style={{ color: '#4BADD2', cursor: 'pointer' }} onClick={() => {
                        setStudentData(record); history.push({
                            pathname: `/customer/${owner?.id}/user/${record.userUuid}/view/users`,
                            state: {
                                fromCampaign: true,
                                campaignTitle: campaign?.name,
                                campaignUrl: window.location.pathname,
                                campaignId: campaignId,
                                enrollmentId: record.enrollmentId
                            }
                        })
                    }}>
                        <div className="popover-item">
                            <FormattedMessage id="general.view" />
                        </div>
                    </div>
                    <div onClick={(e) => { onDelete(record); }} className="popover-item">
                        <FormattedMessage id="campaign.delete" />
                    </div>
                </>

                if (activeUserRole !== 'SUPERVISION') {
                    return (
                        <Popover
                            visible={record.id === visibleTooltip}
                            content={content}
                            trigger="click"
                            placement="left"
                            arrowPointAtCenter
                            onVisibleChange={(visible) => handleVisibleChange(visible, record.id)}
                        >
                            <div style={{ width: '100%', cursor: 'pointer', textAlign: 'center' }}>
                                <i className="fal fa-ellipsis-v" style={{ fontSize: '16px' }} />
                            </div>
                        </Popover>
                    )
                }
            },
        }
    ];

    const closeSubmittedFilesModal = () => {
        setShowSubmittedFiles(false);
        setEnrollmentId(0);
    }

    return (
        <>
            {campaign &&
                <Table
                    style={{ marginTop: 16, marginBottom: 20 }}
                    size='small'
                    resourceUrl={resourceUrl}
                    totalRecords={totalRecords}
                    loadedData={students}
                    setLoadedData={setStudents}
                    setTotalRecords={setTotalRecords}
                    setCurrentPageSize={setCurrentPageSize}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    setLoading={setIsUsersLoading}
                    loading={isUsersLoading}
                    columns={
                        status === 'DRAFT'
                            ? columnsStudent.filter(ea => ea.dataIndex !== "learningProgress" && ea.dataIndex !== "dateOfLastActivity" && ea.dataIndex !== "popoverActions")
                            : columnsStudent.filter(ea => ea.dataIndex !== "actions")
                    }
                    pageSizeOptions={pageSizeOptions}
                    getRecords={getRecords}

                    hasSelection={true}
                    selectAll={selectAllFiltered}
                    setSelectAll={setSelectAllFiltered}
                    selectedRowsLength={selectedRowsLength}
                    setSelectedRowsLength={setSelectedRowsLength}
                    selectedData={selectedUsers}
                    setSelectedData={setSelectedUsers}
                    customRowSelection={rowSelection}
                    setCustomRowSelection={setRowSelection}
                />
            }

            <SubmittedFilesModal
                visible={showSubmittedFiles}
                onCancel={() => closeSubmittedFilesModal()}
                id={enrollmentId}
            />
        </>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentTable);
