import React, { useEffect } from 'react';
import { Form, Input, Select } from 'antd';
import Flag from '../CountryFlags';
import './styles.scss';

const PhoneNumberSelect: React.FC<any> = ({ layout, label, name, hasDefaultLayout, defaultCode, wrapperClassName, form, ...props }) => {

    const countryCodes = {
        "1": "us",
        "7": "ru",
        "48": "pl",
        "49": "de",
        "370": "lt",
        "371": "lv",
        "372": "ee",
        "381": "rs",
        "385": "hr",
        "387": "ba",
        "998": "uz"
    }
    let formItemLayout = layout || {}
    const { Option } = Select;

    if (hasDefaultLayout) {
        formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 }
        }
    }

    useEffect(() => {
        if (defaultCode) {
            form.setFieldsValue({
                code: defaultCode
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[defaultCode])

    return (
        <Form.Item {...formItemLayout} className={wrapperClassName} label={label && label}>
            <Input.Group compact>
                <Form.Item className='phone-select-wrapper' name='code' initialValue={defaultCode} >
                    <Select showArrow={false} dropdownMatchSelectWidth={90} defaultValue={defaultCode}>
                        {Object.entries(countryCodes).map((code: any) =>
                            <Option key={code[0]} value={code[0]}><Flag languageCode={code[1].toUpperCase()} /> +{code[0]}</Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item name={name} className='phone-input-wrapper'>
                    <Input  style={{width: '100%'}} className='county-code-select' {...props} />
                </Form.Item>
            </Input.Group>
        </Form.Item>
    )
};

export default PhoneNumberSelect;
