import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Form, message, Radio, Select as AntDSelect, Checkbox} from 'antd';
import LanguageSelect from 'components/LanguageSelect';
import {Input, Switch, TimeZoneInput, Select} from 'components/Form';
import EnableDisableButton from './EnableDisableButton';
import { PhoneNumberSelect } from 'components/GlobalComponents';
import apiClient from 'utils/apiClient';
import {UserFormDisplayInterface} from '../types';
import Spinner from '../../Spinner';
import debounce from 'lodash/debounce';
import config from "config/config";
import './styles.scss';

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 6},
};

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    };
};

const UserFormDisplay: React.FC<UserFormDisplayInterface> = ({
                                                                 displayStudentFields,
                                                                 customerUser,
                                                                 disabledFields,
                                                                 customerParams,
                                                                 history,
                                                                 match,
                                                                 session,
                                                                 location,
                                                                 form,
                                                                 submitForm,
                                                                 setAddOrganizationalUnit,
                                                                 addOrganizationalUnit,
                                                                 setNewCustomValue,
                                                                 newCustomValue,
                                                                 userUuid,
                                                                 role,
                                                                 customerId,
                                                                 organizationalUnits,
                                                                 setOrganizationalUnits
                                                             }) => {

    const [accountStatuses, setAccountStatuses] = useState<any>({});
    const [isUserLoading, setIsUserLoading] = useState(true);
    const [customerUsers, setCustomerUsers] = useState([]);
    const [isCustomerUsersLoading, setIsCustomerUsersLoading] = useState(true);
    const [customFilterValues, setCustomFilterValues] = useState<any>();
    const [organizationUnitLabel, setOrganizationUnitLabel] = useState('');
    const [isActiveLearningManagerRole, setIsActiveLearningManagerRole] = useState(false);
    const [isActiveElearningAccount, setIsActiveElearningAccount] = useState(false);
    const [sendEmail, setSendEmail] = useState(true);
    const [directManagerLabel, setDirectManagerLabel] = useState('');
    const [enableSmsSending, setEnableSmsSending] = useState(false);
    const [registerUserWithoutEmil ,setRegisterUserWithoutEmil] = useState(false);;

    const intl = useIntl();

    useEffect(() => {
        const loadOrganizationalUnits = async () => {
            let url = '/api/v1/customer/organizational-unit-types';

            if (session.active_user_type === 'SUPER_ADMIN' || session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN'  ) {
                url = url + '?customerId=' + customerId;
            }

            if (session.special_rights && session.special_rights.includes('can_manage_customers')) {
                url = url + '?customerId=' + customerId;
            }

            try {
                let organizationalUnits = await apiClient.request(url, [], 'GET');

                let sortedOptions: any = Object.entries(organizationalUnits.organizationalUnits).sort(function(a: any, b: any) {
                    let textA = a[1].toUpperCase();
                    let textB = b[1].toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                setOrganizationalUnits(sortedOptions)
            } catch (err) {
                console.error(err);
                message.error(intl.formatMessage({id: 'error.data_load'}));
            }
        };

        const loadCustomFields = async () => {
            let url = '/api/v1/customer/custom-fields';

            if (session.active_user_type === 'SUPER_ADMIN' || session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN'  ) {
                url = url + '?customerId=' + customerId;
            }

            if (session.special_rights && session.special_rights.includes('can_manage_customers')) {
                url = url + '?customerId=' + customerId;
            }

            try {
                setIsUserLoading(true);
                let customFields = await apiClient.request(url, [], 'GET');
                setCustomFilterValues(customFields.customFields);
            } catch (err) {
                console.error(err);
                message.error(intl.formatMessage({id: 'error.data_load'}));
            } finally {
                setIsUserLoading(false);
            }
        };

        loadCustomFields();
        loadOrganizationalUnits();
        loadCustomerUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadCustomerUsers = async (initial: boolean = true, q: string | undefined = undefined) => {
        setIsCustomerUsersLoading(true);

        let url = '/api/v1/auth/customer/direct-managers';

        url = url + '?initial=' + initial;

        if (session.active_user_type === 'SUPER_ADMIN' || session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN'  ) {
            url = url + '&customerId=' + customerId;
        }

        if (session.special_rights && session.special_rights.includes('can_manage_customers')) {
            url = url + '&customerId=' + customerId;
        }

        if (userUuid) {
            url = url + '&user=' + userUuid;
        }

        if (q) {
            url = url + '&q=' + q;
        }

        let customerUsers = await apiClient.request(url, [], 'GET');

        if (initial) {
            setDirectManagerLabel(organizationUnitLabel)
        }

        setCustomerUsers(customerUsers.users);
        setIsCustomerUsersLoading(false);
    };

    useEffect(() => {
        const loadClassifiers = async () => {
            let url = '';

            if (role === 'students') {
                url = '/api/v1/enum/student-account-status-types';
            } else if (role === 'learning-managers') {
                url = '/api/v1/enum/customer-admin-account-status-types';
            } else {
                url = '/api/v1/enum/customer-admin-account-status-types';
            }

            try {
                let accountStatusesResponse = await apiClient.request(url, {}, 'GET');
                setAccountStatuses(accountStatusesResponse || {});
            } catch (err) {
                console.error(err);
            }
        };

        form.setFieldsValue({
            accountStatus: 'ACTIVE',
        });

        loadClassifiers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (customerUser.name) {
            setIsActiveLearningManagerRole(customerUser.isActiveLearningManagerRole=== 1);
            setIsActiveElearningAccount(customerUser.isActiveElearningAccount=== 1);

            if (customerUser.userCustomFields) {
                Object.entries(customerUser.userCustomFields).map((el: any) => {
                    form.setFieldsValue({
                        [el[0]]: el[1]
                    });

                    return null;
                });
            }

            let phoneNumber

            if (customerUser.phone_number) {
                phoneNumber = customerUser.phone_number.split(' ');
            }

            form.setFieldsValue({
                name: customerUser.name,
                surname: customerUser.surname,
                email: customerUser.email,
                position: customerUser.position,
                organizationalUnitId: customerUser.organizationalUnitId.toString(),
                managerReferenceId: customerUser.managerReferenceId,
                primaryLanguage: customerUser.primaryLanguage,
                location: customerUser.location,
                timeZone: customerUser.timeZone,
                phishingAllowed: customerUser.phishingAllowed === 1,
                accountStatus: customerUser.accountStatus,
                isActiveElearningAccount: customerUser.isActiveElearningAccount === 1,
                canManageLearningManagers: !!customerUser.canManageLearningManagers,
                canManageCustomers: !!customerUser.canManageCustomers,
                canAddOwnContent: !!customerUser.canAddOwnContent,
                phoneNumber: phoneNumber ? phoneNumber[1] : '',
                code: phoneNumber ? phoneNumber[0] : config.api.defaultCode,
                sendEmail: false,
                smsSending: !!customerUser.smsSending
            });

            setEnableSmsSending(!!customerUser.smsSending);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerUser]);

    useEffect(() => {
        if (role === 'learning-managers') {
            form.setFieldsValue({
                isActiveElearningAccount: true,
                canManageLearningManagers: false,
                canManageCustomers: false,
                phishingAllowed: false,
                sendEmail: true
            });
        } else if (role === 'students') {
            form.setFieldsValue({
                isActiveElearningAccount: false,
                canManageLearningManagers: false,
                canManageCustomers: false,
                phishingAllowed: true,
                sendEmail: true
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role]);

    const loadOptionsTimeOut = debounce(function (text: string) {
        const searchText = text.replace(/\s+/g, '');
        if (searchText.length >= 3) {
            loadCustomerUsers(false, searchText);
        }
    }, 800);

    return (
        <>
            <Spinner spinning={false} opaque={isUserLoading}>
                <Form form={form} onFinish={submitForm}>
                    <Input disabled={disabledFields} name='name' label={intl.formatMessage({id: 'general.name'})} validation={{required: true}}/>
                    <Input disabled={disabledFields} name='surname' label={intl.formatMessage({id: 'general.last_name'})} validation={{required: true}}/>
                    {!registerUserWithoutEmil &&
                        <Input disabled={disabledFields} name='email' label={intl.formatMessage({id: 'general.email'})} validation={{required: !registerUserWithoutEmil, email: true}}/>
                    }
                    <Input disabled={disabledFields} name='position' label={intl.formatMessage({id: 'general.position'})}/>
                    <PhoneNumberSelect 
                        defaultCode={config.api.defaultCode} 
                        wrapperClassName='phone-number-wrapper'
                        form={form} layout={formItemLayout} 
                        name='phoneNumber' 
                        label={intl.formatMessage({id: 'users.form.phone_number'})} 
                    />
                    <Select
                        allowClear
                        showSearch
                        showArrow
                        name='organizationalUnitId'
                        label={intl.formatMessage({id: 'general.unit'})}
                        disabled={session.active_user_type === 'SUPERVISION' && disabledFields}
                        onSearch={(element: any) => {
                            setAddOrganizationalUnit(() => [...addOrganizationalUnit, element]);
                            form.setFieldsValue({organizationalUnitId: addOrganizationalUnit[addOrganizationalUnit.length - 1]});
                        }}
                        dataKey='organizationalUnits'
                        manualOptions={organizationalUnits}
                        isResponseArray={true}
                        valueKey={'0'}
                        labelKey={'1'}
                        integerKey={true}
                        onChange={(value: any, label: any) => {
                            setAddOrganizationalUnit([]);
                            form.setFieldsValue({organizationalUnitId: value});
                            setOrganizationUnitLabel(label !== undefined ? label.children : '')
                        }}
                        filterOption={(input: string, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                        customRules={[
                            {required: true, message: intl.formatMessage({id: 'validation.field_required'})},
                        ]}
                        dropdownStyle={{minWidth: '400px'}}
                    />
                    <Form.Item
                        {...formItemLayout}
                        name='managerReferenceId'
                        label={intl.formatMessage({id: 'users.form.users_direct_manager'})}
                    >
                        <AntDSelect
                            allowClear
                            showSearch
                            notFoundContent='No customer users found'
                            disabled={session.active_user_type === 'SUPERVISION' && disabledFields}
                            style={{height: '100%', width: '100%', borderRadius: 8}}
                            loading={isCustomerUsersLoading}
                            onSearch={loadOptionsTimeOut}
                            dropdownStyle={{minWidth: '400px'}}
                        >
                            <AntDSelect.OptGroup label={intl.formatMessage({id: 'other.customer_users'}, {customer: `${directManagerLabel}`})}>
                                {customerUsers &&
                                    Object.values(customerUsers).map((el: any) => {
                                        return <AntDSelect.Option key={el.userTypeId} value={parseInt(el.userTypeId)}>{`${el.name} ${el.surname} (${el.email})`}</AntDSelect.Option>
                                    })
                                }
                            </AntDSelect.OptGroup>
                        </AntDSelect>
                    </Form.Item>
                    <LanguageSelect
                        name='primaryLanguage'
                        label={intl.formatMessage({id: 'general.language'})}
                        disabled={disabledFields}
                    />
                    <Form.Item
                        {...formItemLayout}
                        name='timeZone'
                        label={intl.formatMessage({id: 'users.form.time_zone'})}
                        rules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
                    >
                        <TimeZoneInput disabled={disabledFields}/>
                    </Form.Item>
                    <Switch onChange={() => setEnableSmsSending(!enableSmsSending)} isFormItem hasDefaultLayout name='smsSending' label={intl.formatMessage({ id: 'users.form.enable_sms_sending' })} />
                    {!userUuid &&
                        <Switch onChange={() => setRegisterUserWithoutEmil(!registerUserWithoutEmil)} isFormItem hasDefaultLayout label={intl.formatMessage({ id: 'users.form.register_user_no_email' })} />
                    }
                    {session.active_user_type !== 'SUPERVISION' &&
                        <>
                            {isActiveLearningManagerRole || role === 'learning-managers' ?
                                <Switch isFormItem hasDefaultLayout name='isActiveElearningAccount' label={intl.formatMessage({id: 'users.form.active_e_learning_account'})}/>
                                :
                                null
                            }
                            <Form.Item {...formItemLayout} name='accountStatus' label={intl.formatMessage({id: 'users.form.account_status'})}>
                                <Radio.Group>
                                    {Object.keys(accountStatuses).map(key =>
                                        <Radio disabled={disabledFields}
                                               className='radio-button'
                                               key={key}
                                               value={key}
                                        >
                                            {intl.formatMessage({id: `users.form.${key}`})}
                                        </Radio>
                                    )}
                                </Radio.Group>
                            </Form.Item>
                            <Switch isFormItem hasDefaultLayout name='phishingAllowed' label={intl.formatMessage({id: 'users.form.phishing_allowed'})} disabled={disabledFields}/>
                            {!userUuid &&
                                <Form.Item {...formItemLayout} name='sendEmail' label={intl.formatMessage({id: 'general.send_email'})}>
                                    <Checkbox checked={sendEmail} onChange={() => { setSendEmail(!sendEmail); form.setFieldsValue({ sendEmail: !sendEmail }); }} />
                                </Form.Item>
                            }
                            {userUuid &&
                                <EnableDisableButton
                                    customerUser={customerUser}
                                    from={form}
                                    isActiveLearningManagerRole={isActiveLearningManagerRole}
                                    setIsActiveLearningManagerRole={setIsActiveLearningManagerRole}
                                    isActiveElearningAccount={isActiveElearningAccount}
                                    setIsActiveElearningAccount={setIsActiveElearningAccount}
                                    customerId={customerId}
                                />
                            }
                    </>
                    }
                    {customFilterValues && customFilterValues.length > 0 &&
                        <>
                            <hr className='form-group-separator' style={{marginBottom: 24}}/>
                            <>
                                {customFilterValues && customFilterValues.map((value: any) => {
                                    return (
                                        <Select
                                            allowClear
                                            showSearch
                                            disabled={disabledFields}
                                            name={value['slug']}
                                            label={value['name']}
                                            valueKey='value'
                                            labelKey='label'
                                            onSearch={(element: any) => {
                                                setNewCustomValue(() => [...newCustomValue, element]);
                                                form.setFieldsValue(element);
                                                form.setFieldsValue({[value['slug']]: newCustomValue[newCustomValue.length - 1]});
                                            }}
                                            manualOptions={value['values']}
                                            customRules={[
                                                {
                                                    required: value['is_required'],
                                                    message: intl.formatMessage({id: 'validation.field_required'})
                                                },
                                            ]}
                                            filterOption={(input: string, option: any) => {
                                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                            }}
                                        />
                                    )
                                })}
                            </>
                        </>
                    }
                    {session.active_user_type !== 'SUPERVISION' &&
                    <>
                        {isActiveLearningManagerRole || role === 'learning-managers' ?
                            <>
                                <hr className='form-group-separator'/>
                                <h1 className='form-group-header'>{intl.formatMessage({id: 'organization.special_rights'})}</h1>
                                <Switch isFormItem hasDefaultLayout name='canManageLearningManagers' label={intl.formatMessage({id: 'users.form.add_remove_learning_manager'})}/>
                                <Switch isFormItem hasDefaultLayout name='canManageCustomers' label={intl.formatMessage({id: 'organizations.can_manage_customers'})}/>
                                {customerParams && customerParams.canAddOwnContent &&
                                    <Switch isFormItem hasDefaultLayout name='canAddOwnContent' label={intl.formatMessage({id: 'users.form.can_add_own_content'})}/>
                                }
                            </>
                            :
                            null
                        }
                    </>
                    }
                </Form>
            </Spinner>
        </>
    );
};

export default connect(mapStateToProps)(withRouter(UserFormDisplay));
