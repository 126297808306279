import React from 'react';
import { defaults, Bar } from 'react-chartjs-2';
import '../styles.scss';
import { Spin } from 'antd';

// EXAMPLE DATA
// const data = {
//   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
//   datasets: [
//     {
//       type:'line',
//       label: 'Sales',
//       data: [51, 65, 40, 49, 60, 37, 40],
//       backgroundColor: 'rgb(33, 208, 160)',
//       borderColor: 'rgb(33, 208, 160)',
//       hoverBorderColor: 'rgb(13, 188, 140)',
//       pointBackgroundColor: 'rgb(13, 188, 140)',
//       yAxisID: 'y-axis-percentage',
//     },
//     {
//       type: 'bar',
//       label: 'Visitor',
//       data: [200, 185, 590, 621, 250, 400, 95],
//       backgroundColor: 'rgb(102, 148, 224)',
//       hoverBackgroundColor: 'rgb(82, 128, 204)',
//       yAxisID: 'y-axis-absolute',
//     }
//   ]
// };

interface BarLineChartProps {
  data: any,
  title?: string,
  loading?: boolean,
}

const BarLineChart: React.FC<BarLineChartProps> = ({ data, title, loading = false }) => {
  defaults.global.defaultFontFamily = 'Gilroy';

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      mode: 'label',
      callbacks: {
        label: (tooltip: any, data: any) => {
          const label = data.datasets[tooltip.datasetIndex].label;
          const value = tooltip.yLabel;

          // if it is bar (percentage) tooltip
          if (tooltip.datasetIndex === 1) {
            return `${label}: ${value}%`;
          }

          return `${label}: ${value}`;
        },
      },
    },
    elements: {
      line: {
        fill: false,
        tension: 0,
      },
      point: {
        radius: 1,
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          barPercentage: 0.4,
          categoryPercentage: 0.8,
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: 'black',
            callback: (tick: any) => tick.toString().substring(0, 3),
          },
        },
      ],
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-absolute',
          gridLines: {
            drawBorder: false,
            zeroLineColor: 'rgba(0, 0, 0, .15)',
            zeroLineWidth: 1,
          },
          ticks: {
            fontColor: 'black',
            padding: 10,
            precision: 0,
            maxTicksLimit: 5,
            suggestedMax: (Math.max(...((data.datasets && data.datasets[0]) ? data.datasets[0].data : [])) || 1) * 1.25,
          }
        },
        {
          type: 'linear',
          display: true,
          position: 'right',
          id: 'y-axis-percentage',
          gridLines: {
            display: false,
          },
          ticks: {
            fontColor: 'black',
            callback: (tick: any) => `${tick.toString()}%`,
            beginAtZero: true,
            suggestedMax: 100,
          }
        },
      ],
    },
    plugins: {
      datalabels: {
        display: false,
      },
    },
  }

  const renderLegend = (data: any) => {
    return (
      <div className="chart-legend">
        {data.datasets?.map((dataset: any) => {
          return (
            <div className="chart-legend-item">
              <div
                className="chart-legend-badge"
                style={{ backgroundColor: dataset.backgroundColor }}
              />
              <div className="chart-legend-text">
                <p className="chart-legend-label">{dataset.label}</p>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <>
      {title && <p className="chart-title">{title}</p>}
      <Spin spinning={loading}>
        <Bar
          data={data}
          options={options}
        />
      </Spin>
      {renderLegend(data)}
    </>
  );
}

export default BarLineChart;
