import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {message, Tag, Popover, Modal} from 'antd';
import {paginatorQuery} from "components/Table/methods";
import {connect} from 'react-redux';
import apiClient from "utils/apiClient";
import moment from 'moment';
import {NavLink} from "react-router-dom";
import DefaultLayout from "components/DefaultLayout";
import Table from "components/Table";

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    }
};

const UserOrganizations: React.FC = ({session, history}:any) => {
    const [isLoading, setLoading] = useState(false);
    const [userOrganizations, setUserOrganizations] = useState([]);
    const [visibleTooltip, setVisibleTooltip] = useState<number|null>(null);

    // pagination
    const pageSizeOptions = [10, 20];
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [currentPageSize, setCurrentPageSize] = useState<number>(pageSizeOptions[0]);

    const { confirm } = Modal;
    const resourceUrl = 'api/v1/user/' + session.active_user_type_uuid + '/organizations' ;
    const intl = useIntl();

    useEffect(() => {

        if (!session.special_rights.includes('can_manage_customers')) {
            history.push('/');
        }

        const loadData = async () => {
            try {
                setLoading(true);

                let query = paginatorQuery(1, currentPageSize);
                let customersResponse = await getRecords(resourceUrl, query);

                setUserOrganizations(customersResponse.data);
                setTotalRecords(customersResponse.pagination.total);
                setCurrentPageSize(pageSizeOptions[0])
            } catch (err) {
                console.error(err);
                message.error(intl.formatMessage({id: "error.data_load"}));
            } finally {
                setLoading(false);
            }
        };

        loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    const getRecords = async (resourceUrl: string, pageQuery: string) => {
        let data = {};
        let query = (pageQuery !== '') ? '?' + pageQuery : '';

        let response = await apiClient.request(resourceUrl + query + '&withoutDeleted=true', data, 'GET');

        return  {
            data: (response.customers) ? response.customers : {},
            pagination: (response.pagination) ? response.pagination : {}
        }
    };

    const deleteCustomer = async (id: string) => {
        try {
            setLoading(true);
            setVisibleTooltip(null);

            let validationResponse = await apiClient.request(`/api/v1/user/${session.active_user_type_uuid}/organization/${id}/validate-delete`, [], 'POST');

            if (validationResponse.hasOwnProperty('result')) {
                if (validationResponse.result.activeLicences > 0) {
                    Modal.warning({
                        title: intl.formatMessage({id: "general.attention"}),
                        content: intl.formatMessage({id: "organization.deletion.valid_licences_contact_support"})
                    });
                } else if (validationResponse.result.actualUsers > 0) {
                    confirm({
                        title: intl.formatMessage({id: "general.attention"}),
                        content: intl.formatMessage({id: "organization.deletion.still_has_undeleted_users"}),
                        icon: <ExclamationCircleOutlined />,
                        okText: intl.formatMessage({id: 'general.yes'}),
                        cancelText: intl.formatMessage({id: 'general.no'}),

                        onOk() {
                            queueDeletion(id);
                        }
                    });
                }
            }
        } catch (err: any) {
            if (err.hasOwnProperty('message') && err.message === 'customer_already_added_to_deletion_queue') {
                message.error(intl.formatMessage({id: "error.customer_already_added_to_deletion_queue"}));
            } else {
                message.error(intl.formatMessage({id: "error.delete_item"}));
            }

            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const queueDeletion = async (id: string) => {
        try {
            setLoading(true);

            await apiClient.request(`/api/v1/user/${session.active_user_type_uuid}/organization/${id}/queue-deletion`, [], 'DELETE');
        } catch (err) {
            message.error(intl.formatMessage({id: "error.delete_item"}));
            console.error(err);
        } finally {
            setLoading(false);
            message.success(intl.formatMessage({id: "organization.deletion.added_to_deletion_queue"}));
        }
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'general.customer'}),
            dataIndex: 'name',
            render: (_text: string, record: any) => (
                <NavLink to={`/customers/${record.customer.id}/edit`}>
                    {record.customer.name}
                </NavLink>
            )
        },
        {
            title: intl.formatMessage({id: 'general.start_date'}),
            key: 'start_date',
            render: (value: string, record: any) =>
                record.agreement ? moment(record.agreement.beginDate).format('DD.MM.YYYY') : '-'
        },
        {
            title: intl.formatMessage({id: 'general.end_date'}),
            key: 'end_date',
            render: (value: string, record: any) => {
                let color;
                if (record.agreement && record.agreement.endDate){
                    if (moment(record.agreement.endDate) < moment()) color='date-color';
                    return (
                        <div className={color}>
                            {moment(record.agreement.endDate).format('DD.MM.YYYY')}
                        </div>)
                } else {
                    return'-'
                }
            }
        },
        {
            title: intl.formatMessage({id: 'organization.agreement_status'}),
            key: 'agreement_status',
            render: (_text: string, record: any, key: number) => (
                record.agreement
                    ? <Tag color={record.agreement.status === 'ACTIVE' ? 'green' : 'red'} key={key}>
                        {intl.formatMessage({id: `agreement.status.${record.agreement.status}`})}
                    </Tag>
                    : '-'
            )
        },
        {
            title: intl.formatMessage({id: 'organization.active_students'}),
            key: 'active_students',
            render: (_text: string, record: any) => (
                <NavLink to={`/customers/${record.customer.id}/users/org`}>
                    {record.activeStudentCount}
                </NavLink>
            )
        },
        {
            key: 'actions',
            render: (text: string, record: any) => {
                const customerId = record.customer.id;

                const content = <>
                    <NavLink to={`/customers/${customerId}/edit`}>
                        <div className="popover-item">
                            <FormattedMessage id="organization.edit_customer"/>
                        </div>
                    </NavLink>
                    <div className="popover-item" onClick={() => {deleteCustomer(customerId)}}>
                        <FormattedMessage id="organization.delete_customer"/>
                    </div>
                </>;

                return (
                    <Popover
                        visible={customerId === visibleTooltip}
                        content={content}
                        trigger="click"
                        placement="bottomRight"
                        arrowPointAtCenter
                        onVisibleChange={(visible) => handleVisibleChange(visible, customerId)}
                    >
                        <div style={{width: '100%', cursor: 'pointer', textAlign: 'center'}}>
                            <i className="fal fa-ellipsis-v" style={{fontSize: '16px'}} />
                        </div>
                    </Popover>
                )
            },
        },
    ];

    const handleVisibleChange = (visible: boolean, recordId: number) => {
        if (visible) {
            setVisibleTooltip(recordId);
        } else {
            setVisibleTooltip(null);
        }
    };

    return (
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({id: 'general.my_organizations'})} />
            <DefaultLayout.PageContent>
                <Table
                    resourceUrl = {resourceUrl}
                    totalRecords = {totalRecords}
                    loadedData = {userOrganizations}
                    setLoadedData = {setUserOrganizations}
                    setTotalRecords = {setTotalRecords}
                    setCurrentPageSize = {setCurrentPageSize}
                    currentPage={currentPage}
                    setCurrentPage = {setCurrentPage}
                    setLoading = {setLoading}
                    loading = {isLoading}
                    columns = {columns}
                    pageSizeOptions = {pageSizeOptions}
                    getRecords = {getRecords}
                />
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    )
};

export default connect(mapStateToProps)(UserOrganizations);

