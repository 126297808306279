import * as ActionTypes from 'store/actionTypes';

type changeStudentViewDataType = {
    enrollmentId: number | undefined;
    campaignId: number | undefined;
    customerId: number | undefined;
    userUuid: string | undefined;
    campaignTitle: string | undefined;
}

export const changeStudentViewData = ({ enrollmentId, campaignId, customerId, userUuid, campaignTitle }: changeStudentViewDataType) => ({
    type: ActionTypes.SAVE_STUDENT_VIEW_DATA,

    payload: {
        enrollmentId: enrollmentId,
        campaignId: campaignId,
        customerId: customerId,
        userUuid: userUuid,
        campaignTitle: campaignTitle
    }
});

export const clearStudentViewData = () => ({
    type: ActionTypes.CLEAR_STUDENT_VIEW_DATA,
    payload: {
        enrollmentId: null,
        campaignId: null,
        customerId: null,
        userUuid: null,
        campaignTitle: null
    }
});

