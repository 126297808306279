import React, {useState, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {Form, message, Select as AntDSelect} from 'antd';
import apiClient from 'utils/apiClient';



const CustomValuesSelect: React.FC<any> = ({
                                               customerId,
                                               customLayout,
                                               className,
                                               setLoading,
                                               saveLoadedData,
                                               setLoadedData,
                                               role,
                                               ...props
                                           }) => {
    const [options, setOptions] = useState<any>([]);
    const [isOptionsLoading, setIsOptionsLoading] = useState(false);

    const intl = useIntl();

    useEffect(() => {
        const loadCustomFields = async () => {
            try {
                setIsOptionsLoading(true);
                setLoading(true);
                let url = '';
                if (role !== 'CUSTOMER_ADMIN' && role !== 'SUPERVISION' ) {
                    url = `/api/v1/customer/custom-fields?customerId=${customerId}`
                } else {
                    url = '/api/v1/customer/custom-fields'
                }
                let response = await apiClient.request(url, [], 'GET');
                setLoading(false);
                setOptions(response.customFields);
                if (saveLoadedData) {
                    setLoadedData(response.customFields);
                }
                setIsOptionsLoading(false);
            } catch (e) {
                console.error(e);
                message.error(intl.formatMessage({id: "error.data_load"}));
                setIsOptionsLoading(false);
            }
        };

        if (customerId) {
            loadCustomFields();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId]);

    let labelCol: any = {span: 8};
    let wrapperCol: any = {span: 6};

    if (customLayout) {
        if (typeof customLayout === 'object') {
            labelCol = customLayout.labelCol || undefined;
            wrapperCol = customLayout.wrapperCol || undefined;
        } else {
            labelCol = undefined;
            wrapperCol = undefined;
        }
    }

    return (
        <>
            {options ?
                options.map((value: any) => {
                    return (
                        <Form.Item
                            className={className}
                            labelCol={labelCol}
                            wrapperCol={wrapperCol}
                            name={`customFilter_${value['slug']}_${value['id']}`}
                            label={
                                value['name']
                            }
                        >
                            <AntDSelect
                                loading={isOptionsLoading}
                                className={`default-select ${props.className || ''}`}
                                {...props}
                                mode="multiple"
                                maxTagCount={2}
                            >
                                {
                                    Object.entries(value.values).map((key: any) => {

                                        return (
                                            <AntDSelect.Option
                                                value={key[1]}
                                            >
                                                {key[1]}
                                            </AntDSelect.Option>
                                        );
                                    })
                                }
                            </AntDSelect>
                        </Form.Item>
                    );
                })
                : null
            }
        </>
    )
};

export default CustomValuesSelect;
