import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Form, Select as AntDSelect, InputNumber, Input as AntDInput, Radio, Button, Alert, Tabs } from 'antd';
import { Input, Select, RemoveConfirmSelect } from 'components/Form';
import Spinner from 'components/Spinner';
import { Editor } from '@tinymce/tinymce-react';
import config from 'config/config';
import apiClient from 'utils/apiClient';
import { connect } from 'react-redux';
import './styles.scss';

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 6 },
};

const textAreaItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

const VARIABLE_NODE_CLASS_NAME = 'email_template.variable';

const mapStateToProps = (state: any) => {
  return {
    locale: state,
    session: state.session
  }
};

const EmailTemplateForm: React.FC<any> = ({
  form,
  intl,
  emailCategories,
  category,
  setCategory,
  setSelectedMailable,
  selectedMailable,
  setHtmlTemplate,
  htmlTemplate,
  languageChange,
  templateId,
  ownerVariables,
  mailables,
  mailable,
  loading,
  deleteLangugae,
  supportedLanguages,
  setSupportedLanguages,
  disableMailable,
  disableCategory,
  disableCampaignNotification,
  templateType,
  session,
  managingOrganization
}) => {
  const [learningNotificationType, setLearningNotificationType] = useState<any>();
  const [notification, setNotification] = useState<any>();
  const [richTextEditor, setRichTextEditor] = useState<any>();
  const [variables, setVariables] = useState([]);
  const [languageList, setLanguageList] = useState({});
  const [inputType, setInputType] = useState('htmlTemplate');

  const { TabPane } = Tabs;
  const { TextArea } = AntDInput;

  useEffect(() => {
    const loadLearningCampaignNotificationType = async () => {
      let response = await apiClient.request(config.api.routes.enums.learningCampaignNotificationTypes, [], 'GET');
      let translatedValues: any = [];
      Object.entries(response).map((el: any) => {
        return translatedValues.push(el);
      });
      setLearningNotificationType(translatedValues);
    };

    const loadLanguages = async () => {
      let languages = await apiClient.request(config.api.routes.backend.languages, {}, 'GET');
      let languageListForSelect: any = {};
      Object.values(languages.languages).map((value: any) => {
        return languageListForSelect[value['code']] = value['name'];
      });
      setLanguageList(languageListForSelect);
    };

    loadLanguages();
    loadLearningCampaignNotificationType();
  }, [intl]);

  useEffect(() => {

    const loadVariables = async () => {
      if (!templateId && templateType !== 'STANDARD') {
        let variablesResponse = await apiClient.request('/api/v1/mail-templates/custom/UserDefinedTemplateMail/variables', {}, 'GET');
        setVariables(variablesResponse);
      } else if (ownerVariables) {
        setVariables(ownerVariables);
      }
    };
    loadVariables();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateId, ownerVariables]);

  useEffect(() => {
    if (notification) {
      let notificationType: any = notification;
      notificationType = notificationType.replace(new RegExp(/_/, 'g'), ' ');
      notificationType = notificationType.replace(/\w\S*/g, (m: any) => m.charAt(0).toUpperCase() + m.substr(1).toLowerCase());
      notificationType = notificationType.replace(new RegExp(/\s/, 'g'), '');
      form.setFieldsValue({
        mailable: notificationType
      });
      setSelectedMailable(notificationType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification]);

  const addVariableToSmsText = (variable: string) => {

    let smsTemplate = form.getFieldValue('smsTemplate');

    smsTemplate += ` |*${variable}*| `

    form.setFieldsValue({
      smsTemplate: smsTemplate
    });
  }

  return (
    <>
      <Spinner spinning={loading} >
        <Input name="name" label={intl.formatMessage({ id: "emailing.templates.template_name" })} validation={{ required: true }} />
        <Select
          disabled={disableCategory}
          name='categoryId'
          label={intl.formatMessage({ id: "general.category" })}
          manualOptions={emailCategories}
          integerKey={true}
          customRules={[{ required: true, message: intl.formatMessage({ id: "validation.field_required" }) }]}
          customObjLabel={(label: string) => intl.formatMessage({ id: `email_templates.categories.${label}` })}
          onChange={(id: number) => { setCategory(emailCategories[id]) }}
        />

        {category &&
          <>
            {category !== 'course_status' && mailable !== 'UserDefinedTemplateMail' &&
              <Form.Item
                {...formItemLayout}
                name="mailable"
                label={intl.formatMessage({ id: "emailing.templates.mailable" })}
                rules={[category !== 'course_status' ? { required: true, message: intl.formatMessage({ id: "validation.field_required" }) } : { required: false }]}
              >
                <AntDSelect disabled={disableMailable} onChange={(value: any) => setSelectedMailable(value)}>
                  {
                    mailables.map((el: any) => <AntDSelect.Option value={el}><FormattedMessage id={`email_templates.mailables.${el}`} /></AntDSelect.Option>)
                  }
                </AntDSelect>
              </Form.Item>
            }
            {category === 'course_status' &&
              <Form.Item
                {...formItemLayout}

                name="learningCampaignNotificationType"
                label={intl.formatMessage({ id: "emailing.template.learning_campaign_notification_types" })}
                rules={[category === 'course_status' ? { required: true, message: intl.formatMessage({ id: "validation.field_required" }) } : { required: false }]}
              >
                <AntDSelect disabled={disableCampaignNotification} onChange={(e: any) => { setNotification(e); }}>
                  {learningNotificationType &&
                    learningNotificationType.map((el: any) =>
                      <AntDSelect.Option value={el[0]}>
                        <FormattedMessage id={`email_templates.notifications.${el[0]}`} />
                      </AntDSelect.Option>
                    )
                  }
                </AntDSelect>
              </Form.Item>
            }
          </>
        }

        {selectedMailable === 'ReminderToCompleteTheCourse' && category === 'course_status' &&
          <Form.Item
            {...formItemLayout}
            name="daysBefore"
            label={intl.formatMessage({ id: "emailing.days_before" })}
            rules={[selectedMailable === 'ReminderToCompleteTheCourse' && category === 'course_status' ? { required: true, message: intl.formatMessage({ id: "validation.field_required" }) } : { required: false }]}
          >
            <InputNumber />
          </Form.Item>
        }
        {selectedMailable === 'ReminderToCompleteTheCourse' &&
          <Form.Item
            {...formItemLayout}
            name="every"
            label={intl.formatMessage({ id: "emailing.template.every_x_days" })}
            rules={[selectedMailable === 'ReminderToCompleteTheCourse' ? { required: true, message: intl.formatMessage({ id: "validation.field_required" }) } : { required: false }]}
          >
            <InputNumber />
          </Form.Item>
        }

        <Input name="from" label={intl.formatMessage({ id: "general.from" })} disabled={!session.access.appearance.allow} />
        <Input name="subject" label={intl.formatMessage({ id: "emailing.templates.subject" })} validation={{ required: true }} />
        <RemoveConfirmSelect
          name='supportedLanguages'
          label={intl.formatMessage({ id: "emailing.supported_languages" })}
          valueKey='value'
          labelKey='label'
          manualOptions={languageList}
          customRules={[{ required: true, message: intl.formatMessage({ id: "validation.field_required_for_language" }) }]}
          selectedOptions={supportedLanguages}
          setSelectedOptions={setSupportedLanguages}
          removeFunction={deleteLangugae}
          confirmModalOK={intl.formatMessage({ id: "general.delete" })}
          confirmModalTitle={intl.formatMessage({ id: "emailing.delete_this_template_language" })}
          confirmModalCancel={intl.formatMessage({ id: "general.cancel" })}
        />
        {supportedLanguages && supportedLanguages.length > 1 &&
          <Radio.Group className='radio-buttons'{...formItemLayout}>
            {
              Object.values(supportedLanguages).map((el: any) => {
                return (
                  <>
                    {el &&
                      <Radio.Button style={{ textTransform: 'uppercase' }} value={el} onClick={() => { languageChange(el); }}>
                        {el}
                      </Radio.Button>
                    }
                  </>
                )
              })
            }
          </Radio.Group>
        }
        <Form.Item
          {...formItemLayout}
          label={intl.formatMessage({ id: "general.variables" })}
        >
          {variables && variables.length > 0
            ? variables.map((variable: string) => {
              return (
                <Button size="small" style={{ marginRight: 8, marginBottom: 4, padding: 4 }}
                  onClick={() => {
                    if (inputType === 'htmlTemplate') {
                      switch (variable) {
                        case 'managing_organization_support_email':
                        case 'managing_organization_support_phone':
                          richTextEditor.insertContent(`<span data-variable="${variable}" class="${VARIABLE_NODE_CLASS_NAME}">{{${intl.formatMessage({ id: "email_template.variable." + variable }, { organization: managingOrganization ? managingOrganization : intl.formatMessage({ id: 'email_template.managing_organization' }) })}}}</span> `);
                          return true;

                        default:
                          richTextEditor.insertContent(`<span data-variable="${variable}" class="${VARIABLE_NODE_CLASS_NAME}">{{${intl.formatMessage({ id: "email_template.variable." + variable })}}}</span> `);
                          return true;
                      }
                    } else if (inputType === 'smsTemplate') {
                      addVariableToSmsText(variable)
                    }

                  }}
                >
                  {variable === 'managing_organization_support_email' || variable === 'managing_organization_support_phone' ?
                    managingOrganization ?
                      variable === 'managing_organization_support_email' ?
                        <FormattedMessage id='email_template.variable.managing_organization_support_email' values={{ organization: managingOrganization }} />
                        :
                        <FormattedMessage id='email_template.variable.managing_organization_support_phone' values={{ organization: managingOrganization }} />
                      :
                      <FormattedMessage id={`email_template.variable.${variable}`} />
                    :
                    <FormattedMessage id={`email_template.variable.${variable}`} />
                  }
                </Button>
              )
            })
            : <p style={{ color: 'red', marginBottom: 0 }}><FormattedMessage id={"emailing.templates.choose_mailable"} /></p>
          }
        </Form.Item>
        <Form.Item
          {...textAreaItemLayout}
          label='‎'
          className='form-alert'
        >
          <Alert message={intl.formatMessage({ id: 'emailing.variable_info_message' })} type="info" />
        </Form.Item>
        <Tabs className='tab-row' onChange={(tabKey: string) => setInputType(tabKey)}>
          <TabPane tab={intl.formatMessage({ id: "emailing.templates.html_template" })} key="htmlTemplate">
            <Form.Item {...textAreaItemLayout} name='htmlTemplate' label={intl.formatMessage({ id: "emailing.templates.html_template" })}>
              <Editor
                apiKey={config.api.tinymceApiKey}
                value={htmlTemplate}
                onEditorChange={(el: any) => setHtmlTemplate(el)}
                initialValue={'‎'} // initial value = Empty character. Needs to switch between language after loading template
                init={{
                  mode: 'exact',
                  setup: function (editor: any) {
                    editor.on('PreInit', function () {
                    });
                    editor.on('init', function () {
                      editor.setContent(htmlTemplate);
                      setRichTextEditor(editor);
                    });
                  },
                  height: 500,
                  relative_urls : true,
                  remove_script_host : true,
                  menubar: false,
                  plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                  ],
                  toolbar:
                    'undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code | help'
                }}
                onKeyDown={(event, editor) => {
                  if (event.code === 'Backspace') {
                    let currentNode: any = editor.selection.getNode();

                    if (currentNode.className === VARIABLE_NODE_CLASS_NAME && currentNode.innerText[currentNode.innerText.length - 1] === '}' && currentNode.innerText[currentNode.innerText.length - 2] === '}') {
                      currentNode.remove();
                    }
                  }
                  if (event.code === 'Delete') {
                    let currentNode = editor.selection.getNode();
                    if (currentNode.className === VARIABLE_NODE_CLASS_NAME) {
                      currentNode.remove();
                    }
                  }
                }}
              />
            </Form.Item>
          </TabPane>
          <TabPane tab={intl.formatMessage({ id: "emailing.templates.sms_template" })} key="smsTemplate">
            <Form.Item className='sms-text-area' {...textAreaItemLayout} name='smsTemplate' label={intl.formatMessage({ id: "emailing.templates.html_template" })}>
              <TextArea showCount maxLength={320} style={{ height: 320 }} />
            </Form.Item>
          </TabPane>
        </Tabs>
      </Spinner>
    </>
  )
};

export default connect(mapStateToProps)(withRouter(EmailTemplateForm));
