import React from 'react';
import { Spin } from 'antd';
import './styles.scss';

const Spinner: React.FC<any> = ({opaque, opaqueH, ...props}) => {
  let className = 'spinner-wrapper';

  if (opaque) {
    className += ' opaque';
  }

  if (opaqueH) {
      className += ' opaqueH';
  }

  return (
    <Spin wrapperClassName={className} {...props} />
  )
}

export default Spinner;
