import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Modal, Form, Radio, message } from 'antd';
import FormattedMessage from 'react-intl/lib/components/message';
import { CustomerSelect } from 'components/Form';
import config from 'config/config';

interface OrganizationModalInterface {
    visible: boolean;
    onCancel: any;
    setOrganization: any;
}

const OrganizationModal = ({
    visible,
    onCancel,
    setOrganization

}: OrganizationModalInterface) => {
    const [filterBy, setFilterBy] = useState('CUSTOMER');
    const [selectedOrganization, setSelectedOrganization] = useState<any>();

    const [form] = Form.useForm();
    const intl = useIntl();

    useEffect(() => {
        form.setFieldsValue({
            filterBy: 'CUSTOMER'
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl])

    const submitForm = () => {
        if (selectedOrganization) {
            setOrganization({
                type: filterBy,
                id: selectedOrganization.value,
                uuid: selectedOrganization.uuid
            });
            onCancel();
        } else {
            message.error(intl.formatMessage({ id: 'validation.select_organization' }));
        }
    }

    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            title={intl.formatMessage({ id: 'general.get_customer' })}
            okText={intl.formatMessage({ id: 'general.filter' })}
            cancelText={intl.formatMessage({ id: 'general.cancel' })}
            onOk={() => submitForm()}
        >
            <Form form={form}>
                <Form.Item
                    name="filterBy"
                    label={intl.formatMessage({ id: 'general.filter_by' })}
                >
                    <Radio.Group onChange={(event: any) => {
                        setFilterBy(event.target.value);

                        form.setFieldsValue({
                            filterBy: event.target.value
                        });

                    }}>
                        <Radio value="CUSTOMER"><FormattedMessage id='general.customer' /></Radio>
                        <Radio value="ORGANIZATION"><FormattedMessage id='general.reseller' /></Radio>
                    </Radio.Group>
                </Form.Item>
                {
                    filterBy === 'CUSTOMER'
                        ? <CustomerSelect
                            saveLoadedData={false}
                            showSearch
                            name='customer'
                            label={intl.formatMessage({ id: "general.customer" })}
                            url={config.api.routes.backend.organizationsCustomers}
                            customRules={[{
                                required: true,
                                message: intl.formatMessage({ id: "validation.field_required" })
                            }]}
                            integerKey={true}
                            onChange={(_value: number, options: any) => {
                                setSelectedOrganization(options)

                            }}
                            isResponseArray={true}
                            dataKey='customers'
                            mapDataEntries={(el: any) => ({
                                value: el.id,
                                label: el.name,
                                uuid: el.uuid,

                            })}
                            valueKey='value'
                            labelKey='label'
                            customLayout={true}
                            filterOption={(input: string, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                        />
                        : null
                }
                {
                    filterBy === 'ORGANIZATION'
                        ? <CustomerSelect
                            saveLoadedData={false}
                            showSearch
                            name='organization'
                            label={intl.formatMessage({ id: "general.reseller" })}
                            customRules={[{
                                required: true,
                                message: intl.formatMessage({ id: "validation.field_required" })
                            }]}
                            url={config.api.routes.backend.organizationsOptions}
                            integerKey={true}
                            onChange={(_value: number, options: any) => {
                                setSelectedOrganization(options)

                            }}
                            isResponseArray={true}
                            dataKey='organizations'
                            mapDataEntries={(el: any) => ({
                                value: el.id,
                                label: el.name,
                                customerType: el.type,
                                uuid: el.uuid,

                            })}
                            valueKey='value'
                            labelKey='label'
                            customLayout={true}
                            filterOption={(input: string, option: any) => {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                        />
                        : null
                }
            </Form>
        </Modal>
    )
}

export default OrganizationModal;
