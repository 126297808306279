import React, { ReactText, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Dropdown, Menu, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { MenuInfo } from 'rc-menu/lib/interface';
import FileDownload from 'js-file-download';
import coursesApiClient from 'utils/coursesApiClient';
import {connect} from "react-redux";

interface StatisticsDownloadProps {
  campaignId: number;
  locale?: string;
}

const mapStateToProps = (state: any) => {
    return {
        locale: state.locale.locale
    }
};

const StatisticsDownload: React.FC<StatisticsDownloadProps> = ({ campaignId, locale }) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const intl = useIntl();

  const downloadStatistics = async (key: ReactText) => {
    try {
      setIsDownloading(true);

      const urls: { [key: string]: string } = {
        'overall_student_progress': `/api/v1/courses/statistic/campaign/${campaignId}/export-campaign-details`,
        'overall_campaign_data': `/api/v1/courses/statistic/campaign/${campaignId}/export-campaign-overall-data`,
        'student_overall_data': `/api/v1/courses/statistic/campaign/${campaignId}/export-result-overall-data`,
        'student_signatures': `/api/v1/courses/statistic/campaign/${campaignId}/export-signature`,
      };

      const response = await coursesApiClient.request(urls[key], {language: locale}, 'GET', true, true);

      if (response.fileName) {
        FileDownload(response, response.fileName);
      } else {
        message.error(intl.formatMessage({ id: 'error.data_load' }));
      }
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({ id: 'error.data_load' }));
    } finally {
      setIsDownloading(false);
    }
  };

  const handleMenuClick = (e: MenuInfo) => downloadStatistics(e.key);

  const dropdownMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="overall_student_progress">
        <FormattedMessage id="campaign.overall_student_progress" />
      </Menu.Item>
      <Menu.Item key="overall_campaign_data">
        <FormattedMessage id="campaign.overall_campaign_data" />
      </Menu.Item>
        <Menu.Item key="student_overall_data">
            <FormattedMessage id="campaign.overall_student_response_data" />
        </Menu.Item>
        <Menu.Item key="student_signatures">
            <FormattedMessage id="campaign.student_signature" />
        </Menu.Item>
    </Menu>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 8 }}>
      <Dropdown overlay={dropdownMenu} trigger={['click']}>
        <Button type="primary" loading={isDownloading}>
          <i className="fal fa-file-download" />
          <span><FormattedMessage id="campaign.export_statistics" /></span>
          <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default connect(mapStateToProps)(StatisticsDownload);
