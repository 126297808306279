import React from 'react';
import { useIntl } from 'react-intl';
import { Alert } from 'antd';
import DefaultLayout from 'components/DefaultLayout';

const NotAllowed: React.FC<any> = () => {
  const intl = useIntl();

  return (
    <DefaultLayout.PageLayout withoutPageHeader>
      <DefaultLayout.PageContent>
        <Alert
          message={intl.formatMessage({id: 'validation.user_has_no_permissions_to_access_page'})}
          description={intl.formatMessage({id: 'validation.user_has_no_permissions_to_access_page.please_message_support'})}
          type="error"
          showIcon
        />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default NotAllowed;
