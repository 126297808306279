import React from 'react';
import { useIntl } from 'react-intl';
import { Modal, Form, message } from 'antd';
import { Select } from 'components/Form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { changeLicenceFilter } from 'store/actions/saveLicenceFilter';

import apiClient from 'utils/apiClient';

const mapDispatchToProps = (dispatch: any) => ({
  changeLicenceFilter: (formData: any) => dispatch(changeLicenceFilter(formData))
});

const LicenceCreateModal = ({
  history, showAddNewLicence, setShowAddNewLicence,
  customers, customersLoading, form,
  customer, changeLicenceFilter
}: any) => {
  const intl = useIntl();

  const resetForm = () => form.setFieldsValue({ customer: customer || null });


  const submitForm = async (values: any) => {
    try {
      const response: any = await apiClient.request(`/api/v1/licences`, { customerId: values.customer }, 'POST');
      changeLicenceFilter(values.customer);
      history.push(`/licensing/manage-licences/${response.id}/edit`);
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({id: 'error.data_load'}));
    }
  }

  return (
    <Modal
      visible={showAddNewLicence}
      title={intl.formatMessage({id: 'licensing.add_new_licence'})}
      okText={intl.formatMessage({id: 'general.submit'})}
      cancelText={intl.formatMessage({id: 'general.back'})}
      onOk={form.submit}
      onCancel={() => {
        setShowAddNewLicence(false);
        resetForm();
      }
      }
    >
      <Form form={form} onFinish={submitForm}>
        {customers ? <Select
          showSearch
          customLayout
          name="customer"
          label={intl.formatMessage({id: 'general.customer'})}
          customRules={[{ required: true, message: intl.formatMessage({id: 'validation.field_required'}) }]}
          manualOptions={customers}
          filterOption={(input: string, option: any) => {
            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
          loading={customersLoading}
        />: null}
      </Form>
    </Modal>
  );
}

export default connect(null, mapDispatchToProps)(withRouter(LicenceCreateModal));
