import React, { ReactElement } from 'react';
import styles from '../../styles.module.scss';

interface ListRowProps {
  title: string;
  content: ReactElement|string|null;
}

const ListItem: React.FC<ListRowProps> = ({ title, content }) => {
  return (
    <>
      <div className={styles.listItemTitle}>
        {title}
      </div>
      <div className={styles.listItemContent}>
        {content}
      </div>
    </>
  );
};

export default ListItem;