import React, { useCallback, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Steps, Tabs, Button, message, Form, Tag, Tooltip } from 'antd';
import { withRouter } from 'react-router-dom';
import FlexRow from 'components/FlexRow';
import Spinner from 'components/Spinner';
import { CampaignSettings, CampaignActivities, CampaignStudents, CampaignNotifications, CampaignCertificates, CampaignPhishing } from 'components/CampaignForm';
import { CampaignInterface, CampaignResponseType, CampaignStatusType, OwnerType } from 'components/CampaignForm/types';
import DefaultLayout from 'components/DefaultLayout';
import coursesApiClient from 'utils/coursesApiClient';
import apiClient from 'utils/apiClient';
import CampaignOverview from 'components/FormCampaign/CampaignOverview';
import './styles.scss';

const mapStateToProps = (state: any) => {
  return {
    session: state.session,
    locale: state.locale.locale
  }
};

const Campaign: React.FC<CampaignInterface> = ({ match, history, locale, session }) => {
  const [campaignStatus, setCampaignStatus] = useState<CampaignStatusType>('DRAFT');
  const [currentStep, setCurrentStep] = React.useState(0);
  const [campaignLanguages, setCampaignLanguages] = useState([]);
  const [activeTab, setActiveTab] = useState<string>('');
  const [selectedLanguage, setSelectedLanguage] = useState<string>(locale ? locale : '');
  const [campaignLanguagesTranslations, setCampaignLanguagesTranslations] = useState<string[]>([]);
  const [phishingCampaign, setPhishingCampaign] = useState(false);
  const [campaignOwnerData, setCampaignOwnerData] = useState<OwnerType>();
  const [phishingCampaignSettings, setPhishingCampaignSettings] = useState();

  const [campaignId, setCampaignId] = useState<number>(parseInt(match.params.id));
  const [campaign, setCampaign] = useState<CampaignResponseType | undefined>();
  const [certificateTemplate, setCertificateTemplate] = useState(false);
  const [certificateGeneration, setCertificateGeneration] = useState(true);

  const [submitTab, setSubmitTab] = useState('');
  const [launch, setLaunch] = useState(false);
  const [campaignIsLaunching, setCampaignIsLaunching] = useState<boolean>(false);
  const [isDataLoading, setIsDataLoading] = useState(false);


  // launch front validations
  const [campaignValidation, setCampaignValidation] = useState(false);
  const [activitiesValidation, setActivitiesValidation] = useState(false);
  const [studentsValidation, setStudentsValidation] = useState(false);
  const [certificateValidation, setCertificateValidation] = useState(false);
  const [phishingValidation, setPhishingValidation] = useState(false);
  const [campaignType, setCampaignType] = useState('TIMED_EVENT');
  const [smartDephishSwitch, setSmartDephishSwitch] = useState<boolean>(true);


  const [campaignTitle, setCampaignTitle] = useState<string>('');
  const [campaignCourses, setCampaignCourses] = useState<string[] | null>([]);

  const step = match.params.step;

  const newCampaignStatus = history.location.pathname.includes('/campaign/create/');

  const { Step } = Steps;
  const { TabPane } = Tabs;

  const intl = useIntl();
  const stepsArray = ['settings', 'activities', 'students', 'notifications', 'certificates', 'phishing'];


  const next = (campaignId: number) => {
    setCurrentStep(currentStep + 1);
    history.push(`/campaign/create/${campaignId}/${stepsArray[currentStep + 1]}`);
  };

  const previous = () => {
    setCurrentStep(currentStep - 1);
    if (campaignId) {
      history.push(`/campaign/create/${campaignId}/${stepsArray[currentStep - 1]}`);
    }
  };

  const handleCampaignType = (type: string) => {
    setCampaignType(type);
    if (type === 'TIMED_EVENT') {
      setPhishingCampaign(true);
    } else if (type === 'CERTIFICATION') {
      setPhishingCampaign(false);
    }
  };

  const sendRequest = useCallback(async (onClick: any) => {
    try {
      if (onClick) {
        return await onClick();
      } else {
        return;
      }
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({ id: 'error.data_load' }));
      return undefined;
    } finally {
      launchCampaign();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignId]);

  const launchButton = (onClick: any = null) => {
    if (campaignStatus === 'DRAFT' && campaignId) {
      return (
        <Button
          loading={campaignIsLaunching}
          type='primary'
          onClick={() => { (newCampaignStatus ? setLaunch(true) : sendRequest(onClick)) }}
          disabled={!campaignValidation || !activitiesValidation || !studentsValidation || !certificateValidation || (phishingCampaign ? !phishingValidation : false)}>
          <FormattedMessage id='campaign.launch_campaign' />
        </Button>
      )
    } else {
      return null;
    }
  };

  const loadCourseLanguages = async (campaignCourses: string[]) => {
    let response;

    if (campaignCourses.length) {
      response = await coursesApiClient.request(`/api/v1/courses/campaigns/${campaignCourses}/available-languages`, {}, 'GET');
      let languageArray: any = Object.values(response.languages);
      setCampaignLanguages(languageArray);
      translateLanguageCode(languageArray);
    }
  };

  const launchCampaign = async () => {
    setCampaignIsLaunching(true);
    try {
      await coursesApiClient.request(`/api/v1/courses/campaigns/${campaignId}/launch`, {}, 'POST');
      message.success(intl.formatMessage({ id: "campaign.campaign_succesfully_launched" }));
      history.push('/courses/manage-campaigns');
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({ id: "launch_error" }));
    }
    setCampaignIsLaunching(false);
  };

  const views = {
    overview: <CampaignOverview campaignId={campaignId} role={session.active_user_type} />,
    settings: <CampaignSettings
      status={campaignStatus}
      campaignId={campaignId}
      campaignTitle={campaignTitle}
      setCampaignTitle={setCampaignTitle}
      campaignLanguages={campaignLanguages}
      setSelectedLanguage={setSelectedLanguage}
      selectedLanguage={selectedLanguage}
      languageTranslations={campaignLanguagesTranslations}
      setCampaignType={handleCampaignType}
      courses={campaignCourses}
      next={next}
      owner={campaignOwnerData}
      setCampaign={setCampaign}
      setCampaignId={setCampaignId}
      campaign={campaign}
      validation={setCampaignValidation}
      newCampaignStatus={newCampaignStatus}
      launchButton={launchButton}
      phishingCampaign={phishingCampaign}
      currentStep={currentStep}
      sendRequest={sendRequest}
      launching={launch}
      submitTab={submitTab}
    />,
    activities: <CampaignActivities
      setPhishingCampaign={setPhishingCampaign}
      campaignType={campaignType}
      selectedLanguage={selectedLanguage}
      loadLanguages={loadCourseLanguages}
      owner={campaignOwnerData}
      campaign={campaign}
      courses={campaignCourses}
      setCourses={setCampaignCourses}
      next={next}
      previous={previous}
      validation={setActivitiesValidation}
      newCampaignStatus={newCampaignStatus}
      status={campaignStatus}
      launchButton={launchButton}
      campaignId={campaignId}
      currentStep={currentStep}
      sendRequest={sendRequest}
      launching={launch}
      submitTab={submitTab}
    />,
    students: <CampaignStudents
      owner={campaignOwnerData}
      newCampaignStatus={newCampaignStatus}
      campaign={campaign}
      courses={campaignCourses}
      status={campaignStatus}
      next={next}
      previous={previous}
      validation={setStudentsValidation}
      launchButton={launchButton}
      campaignId={campaignId}
      currentStep={currentStep}
      sendRequest={sendRequest}
      launching={launch}
      submitTab={submitTab}
    />,
    notifications: <CampaignNotifications
      owner={campaignOwnerData}
      campaignStatus={campaignStatus}
      campaign={campaign}
      setCertificateTemplate={setCertificateTemplate}
      newCampaignStatus={newCampaignStatus}
      next={next}
      previous={previous}
      launchButton={launchButton}
      campaignId={campaignId}
      currentStep={currentStep}
      sendRequest={sendRequest}
      launching={launch}
      submitTab={submitTab}
    />,
    certificates: <CampaignCertificates
      owner={campaignOwnerData}
      status={campaignStatus}
      campaign={campaign}
      phishing={phishingCampaign}
      newCampaignStatus={newCampaignStatus}
      campaignLanguages={campaignLanguages}
      selectedLanguage={selectedLanguage}
      certificateTemplate={certificateTemplate}
      next={next}
      previous={previous}
      validation={setCertificateValidation}
      certificateGeneration={certificateGeneration}
      setCertificateGeneration={setCertificateGeneration}
      launchButton={launchButton}
      campaignId={campaignId}
      currentStep={currentStep}
      sendRequest={sendRequest}
      launching={launch}
      submitTab={submitTab}
    />,
    phishing: <CampaignPhishing
      owner={campaignOwnerData}
      campaign={campaign}
      previous={previous}
      status={campaignStatus}
      phishingSettings={phishingCampaignSettings}
      phishingCampaign={phishingCampaign}
      newCampaignStatus={newCampaignStatus}
      setPhishingSettings={setPhishingCampaignSettings}
      validation={setPhishingValidation}
      launchButton={launchButton}
      campaignId={campaignId}
      currentStep={currentStep}
      sendRequest={sendRequest}
      launching={launch}
      submitTab={submitTab}
      smartDephishSwitch={smartDephishSwitch}
      setSmartDephishSwitch={setSmartDephishSwitch}
    />
  };

  const tabs = [
    {
      title: <FormattedMessage id='campaign.overview' />,
      key: 'overview',
      content: views.overview
    },
    {
      title: <FormattedMessage id='campaign.campaign_settings' />,
      key: 'settings',
      content: views.settings,
      render: true
    },
    {
      title: <FormattedMessage id='campaign.learning_activities' />,
      key: 'activities',
      content: views.activities
    },
    {
      title: <FormattedMessage id='campaign.students' />,
      key: 'students',
      content: views.students
    },
    {
      title: <FormattedMessage id='campaign.notifications' />,
      key: 'notifications',
      content: views.notifications
    },
    {
      title: campaign ? campaign.status !== 'DRAFT' && !certificateGeneration ?
        <Tooltip title={intl.formatMessage({ id: 'campaign.certificates_disabled_for_campaign' })}>
          <span>{intl.formatMessage({ id: 'campaign.certificates' })}</span>
        </Tooltip> : <FormattedMessage id='campaign.certificates' /> : null,
      key: 'certificates',
      content: views.certificates
    },
    {
      title: <FormattedMessage id='phishing.phishing' />,
      key: 'phishing',
      content: views.phishing
    }
  ];

  const handleStep = (step: string | undefined) => {
    if (campaignId && step) {
      switch (step) {
        case 'settings':
          setCurrentStep(0);
          break;
        case 'activities':
          setCurrentStep(1);
          break;
        case 'students':
          setCurrentStep(2);
          break;
        case 'notifications':
          setCurrentStep(3);
          break;
        case 'certificates':
          setCurrentStep(4);
          break;
        case 'phishing':
          setCurrentStep(5);
          break;
        default:
          setCurrentStep(0);
          break;
      }
    } else {
      setCurrentStep(0);
    }
  };

  const handleCampaignId = async () => {
    let campaign = await loadCampaign(campaignId);
    loadCustomer(campaign.customerUuid);
  };

  useEffect(() =>  {
    let parsedCampaignCourses: string[] = [];
    if (campaignId) {

      if (newCampaignStatus) {
        handleStep(step);
      } else {
        handleTab(step);
      }
      handleCampaignId()

    } else if (history.location.state) {
      parsedCampaignCourses = history.location.state.courses.split('&');
      setCampaignTitle(history.location.state.title);
      setCampaignCourses(history.location.state.courses.split('&'));
      setPhishingCampaign(history.location.state.phish);
    } else {
      parsedCampaignCourses = [];
      setCampaignTitle('');
      setCampaignCourses(null);
      setPhishingCampaign(false);
    }

    if (parsedCampaignCourses.length > 0) {
      loadCourseLanguages(parsedCampaignCourses);
    }

    if (session.active_user_type === 'CUSTOMER_ADMIN' || session.active_user_type === 'SUPERVISION') {
      const owner: OwnerType = {
        id: session.organization.organization_id,
        uuid: session.organization.organization_uuid
      };

      setCampaignOwnerData(owner);

    } else if (
      session.active_user_type === 'RESELLER_ADMIN' ||
      session.active_user_type === 'DISTRIBUTOR_ADMIN' ||
      session.active_user_type === 'SUPER_ADMIN'
    ) {
      if (history.location?.state?.owner) {
        setCampaignOwnerData(history.location.state.owner);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  const loadCustomer = async (uuid: string) => {
    if (session.active_user_type === 'CUSTOMER_ADMIN' || session.active_user_type === 'SUPERVISION') {
      return;
    }
    let response = await apiClient.request(`/api/v1/customers-uuid/${uuid}`, {}, 'GET');

    const customer = response.customer;

    const owner: OwnerType = {
      id: customer.id,
      uuid: customer.uuid
    };
    setCampaignOwnerData(owner);
  };

  const steps = [
    {
      title: <FormattedMessage id='campaign.campaign_settings' />,
      key: 'settings',
      content: views.settings,
      status: currentStep === 0 ? 'process' : campaignValidation ? 'finish' : 'error'
    },
    {
      title: <FormattedMessage id='campaign.learning_activities' />,
      key: 'activities',
      content: views.activities,
      status: currentStep === 1 ? 'process' : currentStep <= 1 ? 'wait' : activitiesValidation ? 'finish' : 'error'
    },
    {
      title: <FormattedMessage id='campaign.students' />,
      key: 'students',
      content: views.students,
      status: currentStep === 2 ? 'process' : currentStep <= 2 ? 'wait' : studentsValidation ? 'finish' : 'error'
    },
    {
      title: <FormattedMessage id='campaign.notifications' />,
      key: 'notifications',
      content: views.notifications
    },
    {
      title: <FormattedMessage id='campaign.certificates' />,
      key: 'certificates',
      content: views.certificates,
      status: currentStep === 4 ? 'process' : currentStep <= 4 ? 'wait' : certificateValidation ? 'finish' : 'error'
    },
    {
      title: <FormattedMessage id='phishing.phishing' />,
      key: 'phishing',
      content: views.phishing,
      status: currentStep === 5 ? 'process' : currentStep <= 5 ? 'wait' : phishingValidation ? 'finish' : 'error'
    }
  ];

  const loadCampaign = async (campaignId: number) => {
    setIsDataLoading(true);
    let response;
    try {
      response = await coursesApiClient.request(`/api/v1/courses/campaigns/${campaignId}`, {}, 'GET');
    } catch (error: any) {
      if (error.message === 'dont_have_permission_to_access') {
        history.push('/403');
        return;
      }
    } finally {
      setIsDataLoading(false);
    }

    const campaign = response.campaign;

    if (newCampaignStatus && campaign.status === 'ONGOING') {
      history.push(`/campaign/${campaignId}/overview`);
      setActiveTab('overview');
    }

    setCampaign(response.campaign);
    setCampaignTitle(campaign.name);
    setCampaignCourses(campaign.courses);
    setCampaignStatus(campaign.status);
    setCertificateGeneration(!!campaign.generateCertificates);
    setPhishingCampaignSettings(campaign.phishingCampaign);
    setSmartDephishSwitch(!!campaign.phishingEnabled);

    if (campaign.courses.length > 0 && session.active_user_type !== 'SUPERVISION') {
      loadCourseLanguages(campaign.courses);
    }
    setPhishingCampaign(campaign.phishingEnabled);

    if (step === 'phishing' && !campaign.phishingEnabled) {
      setCurrentStep(4);
      history.push(`/campaign/create/${campaignId}/certificates`);
    }

    const readyToLaunch = campaign.readyToLaunch;

    setCampaignValidation(readyToLaunch.settings);
    setActivitiesValidation(readyToLaunch.activities);
    setStudentsValidation(readyToLaunch.students);
    setCertificateValidation(readyToLaunch.certificate);
    if (campaign.phishingEnabled) {
      setPhishingValidation(readyToLaunch.phishing);
    }

    return response.campaign;
  };

  const translateLanguageCode = (languages: []) => {
    let lng: any = {};

    languages.map((el: string) => {
      return lng[el] = <FormattedMessage id={'system.languages.' + el.toLowerCase()} />
    });
    setCampaignLanguagesTranslations(lng);
  };

  const handleTab = (tabKey: string) => {
    history.push(`/campaign/${campaignId}/${tabKey}`);
    setActiveTab(tabKey);
  };

  const renderTab = (tabKey: string) => {
    const tab = tabs.find((tabsArray: { title: any, key: string, content: any }) => tabsArray.key === tabKey);
    return tab?.content;
  };

  return (
    <DefaultLayout.PageLayout>
      <FlexRow
        left={
          <DefaultLayout.PageHeader title={campaignTitle}
            breadcrumb={[{
              name: intl.formatMessage({ id: 'courses.courses' }),
              path: '/courses/manage-content'
            }, {
              name: intl.formatMessage({ id: 'campaign.manage_e_learning' }),
              path: '/courses/manage-campaigns'
            }]} />
        }
        right={
          <>
            {campaign &&
              <Form.Item label={intl.formatMessage({ id: `general.status` })} style={{ float: 'right', marginTop: 54, marginBottom: 24, fontWeight: 600 }}>
                <Tag color={campaignStatus === 'DRAFT' ? 'gold' : campaignStatus === 'ONGOING' ? 'blue' : 'green'} className={campaignStatus}>
                  {intl.formatMessage({ id: `campaign.status.` + campaignStatus })}
                </Tag>
              </Form.Item>
            }
          </>
        }
      />
      <DefaultLayout.PageContent>
        <Spinner spinning={isDataLoading}>
          {campaignId && !newCampaignStatus ?
            <Tabs activeKey={activeTab} onChange={(tab: string) => { setSubmitTab(activeTab); handleTab(tab); }}>
              {tabs.map((item: any) => {
                if (session.active_user_type === 'SUPERVISION') {
                  if (item.key === 'overview' || item.key === 'students') {
                    return (
                      <TabPane tab={item.title} key={item.key} >
                        {renderTab(item.key)}
                      </TabPane>)
                  }
                } else {
                  if (item.key === 'phishing' && phishingCampaign) {
                    return (
                      <TabPane tab={item.title} key={item.key} >
                        {renderTab(item.key)}
                      </TabPane>)
                  }

                  if (item.key === 'overview' && campaignStatus !== 'DRAFT') {
                    return (
                      <TabPane tab={item.title} key={item.key} >
                        {renderTab(item.key)}
                      </TabPane>)
                  }
                  if (item.key !== 'overview' && item.key !== 'phishing') {
                    let forceRenderArray = ['settings', 'activities'];
                    let render = false;
                    if (forceRenderArray.includes(item.key)) {
                      render = true;
                    }

                    let disabledArray = ['certificates'];
                    let disable = false;
                    if (disabledArray.includes(item.key) && campaignStatus !== 'DRAFT' && !certificateGeneration) {
                      disable = true;
                    }

                    return (
                      <TabPane disabled={disable} forceRender={render} tab={item.title} key={item.key} >
                        {renderTab(item.key)}
                      </TabPane>)
                  }
                }
                return null;
              })}
            </Tabs>
            :
            <>
              <span className='steps-positions'>
                <Steps current={currentStep}>
                  {steps.map((item: any) => {
                    if (item.key !== 'phishing') {
                      return <Step status={item.status} key={item.title} title={item.title} />
                    }

                    if (item.key === 'phishing' && phishingCampaign) {
                      return <Step status={item.status} key={item.title} title={item.title} />
                    }
                    return null;

                  })}
                </Steps>
                <span>
                  {launchButton()}
                </span>
              </span>
              <hr className='form-group-separator' />
              <div >{steps[currentStep].content}</div>
            </>
          }
        </Spinner>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
};

export default connect(mapStateToProps)(withRouter(Campaign));
