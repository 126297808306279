import React, {useState, useEffect} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';
import {Form, Modal, message, Button, Alert} from 'antd';
import FlexRow from 'components/FlexRow';
import {Select, RequestSelect, CustomValuesSelect} from 'components/Form';
import coursesApiClient from 'utils/coursesApiClient';
import apiClient from 'utils/apiClient';
import Table from 'components/Table';
import {connect} from "react-redux";
import Spinner from "components/Spinner";
import './styles.scss';

const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 14}
};

const mapStateToProps = (state: any) => {
    return {
        session: state.session
    };
};

const UsersForPhishing: React.FC<any> = ({
                                             campaignId,
                                             disabledFields,
                                             campaignStatus,
                                             setUsersToAdd,
                                             usersToAdd,
                                             customerId,
                                             setTotalUsers,
                                             campaignTitle,
                                             session,
                                             selectAll,
                                            setSelectAll,
                                            selectedRowsLength,
                                            setSelectedRowsLength,
                                            selectedData,
                                            setSelectedData,
                                            customRowSelection,
                                            setCustomRowSelection,

                                            totalRecords,
                                            setTotalRecords,
                                            currentPage,
                                            setCurrentPage,
                                            users,
                                            setUsers,
                                            pageSizeOptions,
                                            currentPageSize,
                                            setCurrentPageSize,
                                            loadEnrolledUsers

                                         }: any) => {
    const [filterModalShow, setFilterModalShow] = useState(false);
    const [isCustomerUsersLoading, setIsCustomerUsersLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState([]);
    const [searchedUsers, setSearchedUsers] = useState([]);
    const [displayWarning, setDisplayWarning] = useState(false);
    const [organizationalUnits, setOrganizationalUnits] = useState([]);
    const [isCustomFiltersLoading, setIsCustomFiltersLoading] = useState(false);


    //pagination

    const intl = useIntl();
    const [filterForm] = Form.useForm();
    const [userForm] = Form.useForm();
    const resourceUrl = '/api/v1/auth/customers/' + customerId + '/phishing-enrollments';

    useEffect(() => {
        const loadOrganizationalUnits = async () => {
            let url = '/api/v1/customer/organizational-unit-types';

            if (session.active_user_type === 'SUPER_ADMIN' || session.active_user_type === 'RESELLER_ADMIN' || session.active_user_type === 'DISTRIBUTOR_ADMIN'  ) {
                url = url + '?customerId=' + customerId;
            }

            try {
                let organizationalUnits = await apiClient.request(url, [], 'GET');

                let sortedOptions: any = Object.entries(organizationalUnits.organizationalUnits).sort(function(a: any, b: any) {
                    let textA = a[1].toUpperCase();
                    let textB = b[1].toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                });
                setOrganizationalUnits(sortedOptions)
            } catch (err) {
                console.error(err);
                message.error(intl.formatMessage({id: 'error.data_load'}));
            }
        };

        if (customerId) {
            loadOrganizationalUnits();
        }

        if (campaignId && customerId) {
            loadEnrolledUsers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerId]);

    const filterUsers = async (values: any, parsedFilter: boolean = false) => {
        const existingFields = ['organizationalUnitId'];

        let parsedValues: any = {};

        if (parsedFilter) {
            parsedValues = values;
        } else {
            parsedValues = {
                filter: {
                    userTypes: ["STUDENT"],
                    options: {
                        accountStatus: 'in:ACTIVE',
                        customerId: `in:${customerId}`
                    }
                }
            };

            if (values.organizationalUnitId) {
                parsedValues.filter.options['organizationalUnitId'] = `in:${values.organizationalUnitId}`;
            }

            let customFields: any = {};
            let customFiltersExist = false;

            Object.entries(values).map((el: any) => {
                if (!existingFields.includes(el[0])) {
                    let customFilterId = el[0].split('_');
                    customFields[customFilterId[customFilterId.length - 1]] = el[1];
                    customFiltersExist = true;
                }
                return customFields;
            });

            if (customFiltersExist) {
                parsedValues.filter.customFields = customFields;
            }
        }

        setFilterModalShow(false);
        setIsCustomerUsersLoading(true);
        let response = await getRecords(resourceUrl, 'page[number]=1&page[size]=' + currentPageSize, parsedValues);

        setUsersToAdd({
            filter: parsedValues.filter,
            distinctUsers: usersToAdd.distinctUsers,
            removedUsers: usersToAdd.removedUsers
        });

        let totalUsersCount = response.pagination.total + usersToAdd.distinctUsers.length;

        if (totalUsersCount > session.access.phishing.max_users) {
            setDisplayWarning(true);
        } else {
            setDisplayWarning(false);
        }

        setTotalUsers(totalUsersCount);
        setTotalRecords(totalUsersCount);
        setCurrentPage(response.pagination.current_page);
        setUsers(response.data);
        setIsCustomerUsersLoading(false);
    };

    const columns = [
        {
            title: intl.formatMessage({id: 'general.name'}),
            dataIndex: 'name',
            render: (_name: string, record: any) => {
                const url = `/customers/${customerId}/users/${record.userUuid}/edit`;
                return (
                    <NavLink
                        to={{
                            pathname: url,
                            state: {
                                fromCampaign: true,
                                campaignTitle: campaignTitle,
                                campaignUrl: window.location.pathname,
                            },
                        }}
                    >
                        {record.name}
                    </NavLink>
                )
            }
        },
        {
            title: intl.formatMessage({id: 'general.surname'}),
            dataIndex: 'surname',
        },
        {
            title: intl.formatMessage({id: 'general.email'}),
            dataIndex: 'email',
        },
        {
            title: intl.formatMessage({id: 'phishing.organizational_unit'}),
            dataIndex: 'organizationalUnitName',
        },
        {
            render: (_text: string, record: any) => {
                if (campaignStatus === 'DRAFT') {
                    return (
                        <Button className='send-test-email-button' onClick={() => {
                            onDelete(record)
                        }}>
                            <FormattedMessage id="phishing.remove_user"/>
                        </Button>
                    )
                }
            }
        },
    ];

    const onDelete = async (record: any) => {
        if (record.studentUserTypeUuid) {
            coursesApiClient.request(`/api/v1/courses/custom-phishing/${campaignId}/phishing-students/${ record.studentUserTypeUuid }/remove`, {}, 'POST');
        }

        let removedUsers = usersToAdd.removedUsers;

        if (record.uuid) {
            removedUsers.push(record.uuid);
        } else {
            removedUsers.push(record.studentUserTypeUuid);
        }

        setUsersToAdd({
            filter: usersToAdd.filter,
            distinctUsers: usersToAdd.distinctUsers,
            removedUsers: removedUsers
        });

        const data: any = users.filter((item: any) => item.userUuid !== record.userUuid);

        let totalUsersCount = totalRecords - 1;

        setTotalUsers(totalUsersCount);
        setTotalRecords(totalUsersCount);
        if (totalUsersCount <= session.access.phishing.max_users) {
            setDisplayWarning(false);
        }

        setUsers(data);
    };

    // add distinct users
    const addUser = async () => {
        if (selectedUser === undefined || selectedUser.length === 0) {
            return;
        }

        let optionsResponse = await apiClient.request(`/api/v1/auth/customers/${customerId}/get-students`, selectedUser, 'POST');

        let addedUsersUuids = usersToAdd.distinctUsers;

        addedUsersUuids = addedUsersUuids.concat(selectedUser);

        optionsResponse.forEach((element: any) => {
            setUsers((users: any) => [...users, element]);
        });

        setUsersToAdd({
            filter: usersToAdd.filter,
            distinctUsers: addedUsersUuids,
            removedUsers: usersToAdd.removedUsers
        });

        let totalUsersCount = totalRecords - usersToAdd.removedUsers.length + addedUsersUuids.length;

        if (totalUsersCount > session.access.phishing.max_users) {
            setDisplayWarning(true);
        }
        setTotalUsers(totalUsersCount);

        setTotalRecords(totalUsersCount);

        setSearchedUsers(selectedUser);
        userForm.setFieldsValue({
            addUser: undefined,
        });
        setSearchedUsers([]);
    };

    const getRecords = async (resourceUrl: string, pageQuery: string, filter: any = {}) => {
        let query = (pageQuery !== '') ? '?' + pageQuery : '';

        let parsedValues: any = {
            campaignId: campaignId,
        };

        if (Object.keys(filter).length !== 0 && filter.constructor === Object) {
            parsedValues.usersToAdd = filter;
        } else if (Object.keys(usersToAdd.filter).length !== 0 && usersToAdd.filter.constructor === Object) {
            parsedValues.usersToAdd = usersToAdd;
        }

        if (usersToAdd.distinctUsers.length) {
            parsedValues.usersToAdd.distinctUsers = usersToAdd.distinctUsers;
        }

        let response = await apiClient.request(resourceUrl + query, parsedValues, 'POST');

        return {
            data: (response.users) ? response.users : {},
            pagination: (response.pagination) ? response.pagination : {}
        }
    };

    return (
        <>
            <FlexRow
                left={
                    <>
                        <Button disabled={disabledFields} type="primary" onClick={() => setFilterModalShow(true)}>
                            <i className="fal fa-plus header-item"/>
                            <span><FormattedMessage id="campaign.add_user_groups"/></span>
                        </Button>
                        <Form form={userForm} className="add-user">
                            <RequestSelect
                                disabled={disabledFields}
                                url={`/api/v1/auth/customers/${customerId}/select-users`}
                                campaignId={campaignId}
                                className="add-user-select"
                                placeholder="Search"
                                name="addUser"
                                label={intl.formatMessage({id: 'users.add_user'})}
                                style={{width: 210, marginBottom: 0}}
                                onChange={(e: any) => setSelectedUser(e)}
                                mode="multiple"
                                valueKey='userTypeUuid'
                                removeParam={searchedUsers}
                                customLayout
                                showSearch
                            />
                        </Form>

                        <Button onClick={addUser} disabled={disabledFields}>
                            <FormattedMessage id="general.add"/>
                        </Button>
                    </>
                }
            />
            {displayWarning &&
                <Alert className='max-user-validation' message={intl.formatMessage({id: 'phishing.max_users_validation'}, {maxUsers: session.access.phishing.max_users}  ) } type="warning" showIcon />
            }
            <Table
                resourceUrl={resourceUrl}
                totalRecords={totalRecords}
                loadedData={users}
                setLoadedData={setUsers}
                setTotalRecords={setTotalRecords}
                setCurrentPageSize={setCurrentPageSize}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setLoading={setIsCustomerUsersLoading}
                loading={isCustomerUsersLoading}
                columns={columns}
                pageSizeOptions={pageSizeOptions}
                getRecords={getRecords}
                hasSelection={true}
                selectAll={selectAll}
                setSelectAll={setSelectAll}
                selectedRowsLength={selectedRowsLength}
                setSelectedRowsLength={setSelectedRowsLength}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                customRowSelection={customRowSelection}
                setCustomRowSelection={setCustomRowSelection}
                size='small'
            />
            <Modal
                className="user-list-modal"
                visible={filterModalShow}
                title={intl.formatMessage({id: 'users.user_filter'})}
                onOk={() => {
                    filterForm.submit();
                }}
                onCancel={() => {
                    setFilterModalShow(false);
                }}
                okText={intl.formatMessage({id: 'general.filter'})}
                cancelText={intl.formatMessage({id: 'general.cancel'})}
            >
                <Form form={filterForm} onFinish={filterUsers}>
                    <Select
                        mode="multiple"
                        allowClear
                        showSearch
                        showArrow
                        name='organizationalUnitId'
                        dataKey='organizationalUnits'
                        label={intl.formatMessage({id: "general.unit"})}
                        isResponseArray={true}
                        valueKey={'0'}
                        labelKey={'1'}
                        manualOptions={organizationalUnits}
                        integerKey={true}
                        customLayout={formItemLayout}
                        filterOption={(input: string, option: any) => {
                            return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }}
                        dropdownStyle={{minWidth: "385px"}}
                    />
                        <Spinner spinning={isCustomFiltersLoading}>
                              <CustomValuesSelect
                                  customerId={customerId}
                                  customLayout={{
                                      labelCol: {span: 10},
                                      wrapperCol: {span: 12}
                                  }}
                                  allowClear
                                  setLoading={setIsCustomFiltersLoading}
                                  saveLoadedData={false}
                                  role={session.active_user_type}
                                  filterOption={(input: string, option: any) => {
                                      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                  }}
                              />
                        </Spinner>
                </Form>
            </Modal>
        </>
    )
};

export default connect(mapStateToProps)(withRouter(UsersForPhishing));
