import React from 'react';
import { Progress } from 'antd';
import {connect} from "react-redux";
import styles from '../styles.module.scss';

interface BadgeProps {
  campaign: any;
  appearance: any;
}

const mapStateToProps = (state: any) => {
    return {
        appearance: state.appearanceSettings.theme,
    };
};

const Badge: React.FC<BadgeProps> = ({ campaign, appearance }) => (
  <div className={styles.badgeContainer}>
    <div className={styles.badge}>
      <div className={styles.badgeInner}>
        <i className={`${styles.badgeIcon} fal ${campaign.campaignThumbnail}`} />
      </div>
        {appearance &&
            <Progress
                showInfo={false}
                percent={parseInt(campaign.result)}
                type="circle"
                width={100}
                strokeColor={appearance.navBackgroundColor}
            />
        }
    </div>
  </div>
);

export default connect(mapStateToProps)(Badge);
