
const htmlTemplateFrontendParser = (html: any, customer: any, intl: any) => {
    const VARIABLE_NODE_CLASS_NAME = 'email_template.variable';

    let managingOrganizationName = customer ? customer['managingOrganizationName'] : intl.formatMessage({ id: 'emailing.reseller' });

    let htmlTemplateWithTranslatedVariables = html;

    if (htmlTemplateWithTranslatedVariables.includes('href="{{password_reset_url}}"')) {
        htmlTemplateWithTranslatedVariables = htmlTemplateWithTranslatedVariables.replace('href="{{password_reset_url}}"', 'href="[{password_reset_url}]"');
    }

    let allUsedVariables = htmlTemplateWithTranslatedVariables.match(/{{\w*}}/g) || [];

    allUsedVariables.forEach((el: string) => {
        el = el.replace('{{', '');
        el = el.replace('}}', '');

        switch (el) {
            case ('managing_organization_support_email'):
                htmlTemplateWithTranslatedVariables = htmlTemplateWithTranslatedVariables.replace(
                    /{{[^}]*}}/,
                    `<span data-variable="${el}" class="${VARIABLE_NODE_CLASS_NAME}">{_{${intl.formatMessage({ id: "email_template.variable.managing_organization_support_email" }, { organization: managingOrganizationName })}}_}</span>`
                );
                break;
            case ('managing_organization_support_phone'):
                htmlTemplateWithTranslatedVariables = htmlTemplateWithTranslatedVariables.replace(
                    /{{[^}]*}}/,
                    `<span data-variable="${el}" class="${VARIABLE_NODE_CLASS_NAME}">{_{${intl.formatMessage({ id: "email_template.variable.managing_organization_support_phone" }, { organization: managingOrganizationName })}}_}</span>`
                );
                break;
            default:
                htmlTemplateWithTranslatedVariables = htmlTemplateWithTranslatedVariables.replace(
                    /{{[^}]*}}/,
                    `<span data-variable="${el}" class="${VARIABLE_NODE_CLASS_NAME}">{_{${intl.formatMessage({ id: "email_template.variable." + el })}}_}</span>`
                );
                break;
        }
    });
    htmlTemplateWithTranslatedVariables = htmlTemplateWithTranslatedVariables.replace(/{_{/g, '{{');
    htmlTemplateWithTranslatedVariables = htmlTemplateWithTranslatedVariables.replace(/}_}/g, '}}');

    return htmlTemplateWithTranslatedVariables;
};

const exportedObject = {
    htmlTemplateFrontendParser
};

export default exportedObject;

