import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { message, Tag, Modal, Input, Form, DatePicker, Radio, Button, Checkbox } from 'antd';
import config from 'config/config';
import DefaultLayout from 'components/DefaultLayout';
import apiClient from 'utils/apiClient';
import coursesApiClient from 'utils/coursesApiClient';
import { connect } from 'react-redux';
import moment from 'moment';
import Table from 'components/Table';
import { Select, CustomerSelect } from 'components/Form';
import { EyeOutlined } from '@ant-design/icons';
import './styles.scss';

const mapStateToProps = (state: any) => {
  return {
    session: state.session,
    locale: state.locale.locale
  };
}

const EmailHistoryList: React.FC<any> = ({props, session, locale}:any) => {
  const [historyRecords, setHistoryRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setISModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [filterBy, setFilterBy] = useState('customer');
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [isLearningManager, setIsLearningManager] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState<any>();
  const [selectedCustomer, setSelectedCustomer] = useState<any>();
  const [ownerId, setOwnerId] = useState();
  const [ownerType, setOwnerType] = useState<any>();
  const [selectedFromLabel, setSelectedFromLabel] = useState<any>();
  const [selectedToLabel, setSelectedToLabel] = useState<any>();
  const [emailCategories, setEmailCategories] = useState<any>();
  const [campaignList, setCampaignList] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState();
  const [filter, setFilter] = useState<any>();

  const pageSizeOptions = [10, 20];
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPageSize, setCurrentPageSize] = useState<number>(pageSizeOptions[0]);

  const intl = useIntl();
  const { Search } = Input;
  const [form] = Form.useForm();
  const includeColumn = ['fullName', 'to'];
  const resourceUrl = '/api/v1/filtered-mail-history/' + locale;

  useEffect(() => {
    if (session.active_user_type === "CUSTOMER_ADMIN") {
      setSelectedCustomer(session.organization.organization_id);
      setIsLearningManager(true)
      setOwnerId(session.organization.organization_id)
      setOwnerType('CUSTOMER')
      form.setFieldsValue({
        type: ['sms', 'e-mail']
      });
    } else {
      form.setFieldsValue({
        filterBy: 'customer',
        type: ['sms', 'e-mail']
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intl]);

  useEffect(()=>{
    const loadEmailCategories = async () => {
      let categoriesResponse = await apiClient.request(`/api/v1/mail-templates/mail-categories`, {}, 'GET');
      if (session.active_user_type !== 'SUPER_ADMIN') {
          Object.entries(categoriesResponse).map((el:any)=> {
            if (el[1] === 'system_events') {
              delete categoriesResponse[el[0]]
            }
            return null;
          })
      }
      setEmailCategories(categoriesResponse);
    }
    loadEmailCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(()=>{
    const loadCampaignList = async () => {
      let customer;
      if (ownerId) {
        try {
          customer = await apiClient.request(`/api/v1/customers/${ownerId}`, {}, 'GET');
        } catch (e) {}

        let campaignList = await coursesApiClient.request(`/api/v1/courses/customer/${customer ? customer.customer.uuid : session.organization.organization_uuid}/campaigns`, {}, 'GET');

        let campaignListValues:any = [];
        Object.values(campaignList.campaigns).map((value:any, index:any) => {
          return campaignListValues[value['id']] = value['name'];
        })
        setCampaignList(campaignListValues)
      }
    }
    loadCampaignList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[ownerId])

  const  filterBySearchString = (searchText: string, users: never[], includeColumn: string[]) => {
    return !searchText ? users : users.filter((user: any) => { return Object.keys(user).some(key => includeColumn.includes(key) ? user[key].toString().toLowerCase().includes(searchText.toLocaleLowerCase()) : false); });
  }

  const filterHistory = async (values: any) => {
    const startDate = values.startDate.format('YYYY-MM-DD 00:00');
    const endDate = values.endDate.format('YYYY-MM-DD 23:59');

    let parsedValues = {
        startDate: startDate,
        endDate: endDate,
        id: ownerId,
        ownerType: ownerType,
        from: selectedFromLabel,
        to: selectedToLabel,
        category: values.category,
        campaignId: selectedCampaign,
        type: values.type
    }

    try {
      setIsLoading(true);
      setShowFilterModal(false);

      const pageSize = currentPageSize ? currentPageSize : pageSizeOptions[0];
      let response = await apiClient.request('/api/v1/filtered-mail-history/' + locale + '?page[number]=1&page[size]=' + pageSize, parsedValues, 'POST');

      setFilter(parsedValues);
      setHistoryRecords(response.mailHistory.history);
      setTotalRecords(response.mailHistory.paginate.total);
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({ id: 'error.data_load' }));
    } finally {
      setIsLoading(false);
    }
  }

  const  filterBySearch = async (value: string) => {
    if (value.length === 0 ) {
      setHistoryRecords([]);
    } else {
      let data = filterBySearchString(value, historyRecords, includeColumn);
      setHistoryRecords(data);
    }
  }

  const columns = [
    {
      title: intl.formatMessage({id: 'emailing.history.email_scenarios'}),
      render: (record: any) => {
        let description = record.description.split(':');
        return (
          <>
            <Tag>
              {description[0]}
            </Tag>
            {description[1]}
          </>
        );
      }
    },
    {
      title: intl.formatMessage({id: 'emailing.history.receiver_name'}),
      dataIndex: 'fullName',
    },
    {
      title: intl.formatMessage({id: 'emailing.history.senders_address'}),
      dataIndex: 'from',
      render: (text: string, record: any) => {
        return (
          <div className='history-sender'>
            {record.from}
            <>
              {record.type === 'sms' ? 
                <Tag>
                  <FormattedMessage id='users.export.table.smsSending' />
                </Tag>
              : null
              }
            </>
          </div>
        )
      }

    },
    {
      title: intl.formatMessage({id: 'emailing.history.receiver_address'}),
      dataIndex: 'to',
      render: (text: string, record: any) => {
        return record.type === 'e-mail' ? record.userEmail : record.phoneNumber;
      }
    },
    {
      title: intl.formatMessage({id: 'general.date'}),
      dataIndex: 'date',
      key: 'date',
      render: (text: string, record: any) => moment(record.executionTime).format('HH:mm:ss DD.MM.YYYY')
    },
    {
      title: intl.formatMessage({id: 'general.body'}),
      dataIndex: 'body',
      key: 'body',
      render: (text: string, record: any) => {
        return <p
          style={{color: '#19A9CE', cursor: 'pointer'}}
          onClick={() => {
            setModalTitle(intl.formatMessage({id: "emailing.history.email_body"}));
            setModalContent(record.body);
            setISModalVisible(true);
          }}
        >
          <FormattedMessage id={'general.show'}/> <EyeOutlined />
        </p>
      }
    },
    {
      title: intl.formatMessage({id: 'general.status'}),
      dataIndex: 'status',
      render: (text: string, record: any, tag: any) => {
        let color;
        if (record.status) {
          if (record.status === 'SENT') color = 'green';
          if (record.status === 'SENDING') color = 'gold';
          if (record.status === 'FAILED') color = 'red';
          return (
            <>
              <Tag color={color} key={tag}>
                  {intl.formatMessage({id: `email_history.status.${record.status}`})}
              </Tag>
              {
                record.status === 'FAILED'
                  ? <p
                      style={{color: '#19A9CE', cursor: 'pointer'}}
                      onClick={() => {
                        setModalTitle(intl.formatMessage({id: "general.error"}));
                        setModalContent(record.errors);
                        setISModalVisible(true);
                      }}
                    >
                      <FormattedMessage id={'general.show'}/> <EyeOutlined />
                    </p>
                  : null
              }
            </>
          )
        } else {
          return '-';
        }
      }
    },
  ];

    const getRecords = async (resourceUrl: string, pageQuery: string) => {
        let query = (pageQuery !== '') ? '?' + pageQuery : '';

        let response = await apiClient.request(resourceUrl + query, filter, 'POST');

        return  {
            data: (response.mailHistory.history) ? response.mailHistory.history : {},
            pagination: (response.mailHistory.paginate) ? response.mailHistory.paginate : {}
        }
    };

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageHeader title={intl.formatMessage({id: 'emailing.history.e_mailing_history'})} />
      <DefaultLayout.PageContent>
        <Button type="primary" onClick={() => setShowFilterModal(true)}><FormattedMessage id="general.filter"/></Button>
        <Search
          className="table-search-field"
          placeholder={intl.formatMessage({id: "general.search"})}
          onChange={(value:any) => filterBySearch(value)}
         />
          <Table
              resourceUrl = {resourceUrl}
              totalRecords = {totalRecords}
              loadedData = {historyRecords}
              setLoadedData = {setHistoryRecords}
              setTotalRecords = {setTotalRecords}
              setCurrentPageSize = {setCurrentPageSize}
              currentPage = {currentPage}
              setCurrentPage = {setCurrentPage}
              setLoading = {setIsLoading}
              loading = {isLoading}
              columns = {columns}
              pageSizeOptions = {pageSizeOptions}
              getRecords = {getRecords}
              hasSelection = {false}
          />
      </DefaultLayout.PageContent>
      <Modal
        title={modalTitle}
        visible={isModalVisible}
        onOk={() => setISModalVisible(false)}
        onCancel={() => setISModalVisible(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div dangerouslySetInnerHTML={{ __html: modalContent }} />
      </Modal>
      <Modal
        className="history-list-modal"
        visible={showFilterModal}
        title={intl.formatMessage({id: 'emailing.history.email_history_filter'})}
        onOk={()=>form.submit()}
        onCancel={() => setShowFilterModal(false)}
        okText={intl.formatMessage({id: 'general.filter'})}
        cancelText={intl.formatMessage({id: 'general.cancel'})}
      >
        <Form form={form} onFinish={filterHistory}>
          <Form.Item
            name="filterBy"
            label="Filter by"
            style={isLearningManager ? { display: 'none'} : {display: ''}}
          >
            <Radio.Group
              onChange={(el: any) => {
                setFilterBy(el.target.value);
                setSelectedCustomer(undefined);
                setSelectedOrganization(undefined);
                setSelectedCampaign(undefined);
                setCampaignList([])
                if(el.target.value === 'customer'){
                  form.setFieldsValue({
                    filterBy: 'customer',
                    userTypes: ['CUSTOMER_ADMIN', 'STUDENT'],
                  });
                }else{
                  form.setFieldsValue({
                    filterBy: 'reseller',
                    userTypes: ['ADMIN'],
                  });
                }
                form.setFieldsValue({
                  customer: [],
                  reseller: [],
                  organizationalUnitId: []
                });
              }}
            >
              <Radio value="customer"><FormattedMessage id='general.customer'/></Radio>
              <Radio value="reseller"><FormattedMessage id='general.reseller'/></Radio>
            </Radio.Group>
          </Form.Item>
          <div style={isLearningManager ? { display: 'none'} : {display: ''}}>
            {
              filterBy === 'customer'
                ? <CustomerSelect
                    showSearch
                    name='customer'
                    className='scenario-email-required'
                    label={intl.formatMessage({id: "general.customer"})}
                    url={config.api.routes.backend.organizationsCustomers}
                    integerKey={true}
                    customRules={[isLearningManager ? {required: false}: { required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
                    onChange={(value: any) => {
                      setSelectedCustomer(value)
                      setOwnerId(value)
                      setOwnerType('CUSTOMER')
                    }}
                    isResponseArray={true}
                    dataKey='customers'
                    mapDataEntries={(el: any) => ({value: el.id, label: el.name})}
                    valueKey='value'
                    labelKey='label'
                    customLayout={true}
                    filterOption={(input: string, option: any) => {
                      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                />
                : null
            }
            {
              filterBy === 'reseller'
                ? <CustomerSelect
                    showSearch
                    className='scenario-email-required'
                    name='organization'
                    label={intl.formatMessage({id: "general.reseller"})}
                    url={config.api.routes.backend.organizationsOptions}
                    integerKey={true}
                    customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"})}]}
                    onChange={(value: any, option:any) => {
                      setSelectedOrganization(value)
                      setOwnerId(value)
                      setOwnerType(option.customerType)
                    }}
                    isResponseArray={true}
                    dataKey='organizations'
                    mapDataEntries={(el: any) => ({value: el.id, customerType: el.type, label: el.name})}
                    valueKey='value'
                    labelKey='label'
                    customLayout={true}
                    filterOption={(input: string, option: any) => {
                      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }}
                />
                : null
            }
          </div>
          <Form.Item name="startDate" className='scenario-email-required' label={intl.formatMessage({id: "general.start_date"})} rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}>
          <DatePicker />
        </Form.Item>
        <Form.Item name="endDate" className='scenario-email-required' label={intl.formatMessage({id: "general.end_date"})} rules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]} >
          <DatePicker />
        </Form.Item>
          <Select
            className = 'scenario-email-required'
            name='category'
            label={intl.formatMessage({id: "emailing.scenarios.e_mail_category"})}
            customLayout={true}
            manualOptions={emailCategories}
            integerKey={true}
            customObjLabel={(el: string) => intl.formatMessage({id: `email_templates.categories.${el}`})}
            withSystemName={true}
          />
          {
            filterBy === 'customer' ?
            <Select
              name="campaignList"
              label={intl.formatMessage({id: 'campaign.email_campaign'})}
              onChange={(value: any) => {setSelectedCampaign(value);}}
              manualOptions= {campaignList}
              customLayout={true}
              allowClear
            />
          : null
          }
          <Select
            showSearch
            allowClear
            name='from'
            label={intl.formatMessage({id: "general.from"})}
            integerKey={true}
            disabled={!selectedCustomer && !selectedOrganization}
            dataKey='mailHistoryEmails'
            url={`/api/v1/mail-history/${ownerType}/${ownerId}/from-emails`}
            onChange={(el:any, option:any)=> {option ? setSelectedFromLabel(option.children) : setSelectedFromLabel(null)}}
            valueKey='value'
            labelKey='label'
            customLayout={true}
            distinct={true}
            filterOption={(input: string, option: any) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;}}
          />
          <Select
            showSearch
            allowClear
            name='to'
            label={intl.formatMessage({id: "general.to"})}
            integerKey={true}
            customRules={[{required: false}]}
            url={`/api/v1/mail-history/${ownerType}/${ownerId}/to-emails`}
            dataKey='mailHistoryToEmails'
            onChange={(el:any, option:any)=> {option ? setSelectedToLabel(option.children) : setSelectedToLabel(null)}}
            valueKey='value'
            labelKey='label'
            customLayout={true}
            distinct={true}
            filterOption={(input: string, option: any) => {
              return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          />

          <Form.Item
            name="type"
            label={intl.formatMessage({ id: 'general.type' })}
          >
            <Checkbox.Group>
              <Checkbox value="sms" style={{ lineHeight: '32px' }}>
                <FormattedMessage id='users.export.table.smsSending' /> 
              </Checkbox>
              <Checkbox value="e-mail" style={{ lineHeight: '32px' }}>
                <FormattedMessage id='general.email' />
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>                
        </Form>
      </Modal>
    </DefaultLayout.PageLayout>
  );
}

export default connect(mapStateToProps)(withRouter(EmailHistoryList));
