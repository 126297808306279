import * as ActionTypes from 'store/actionTypes';

export const changeScormData = ({
  learningActivityId, type, activityAttemptId,
  enrollmentId, campaignId, isFirstAttempt, parentActivityId, materialType
}: any) => ({
  type: ActionTypes.SCORM_DATA,
  payload: {
    learningActivityId: learningActivityId,
    type: type,
    activityAttemptId: activityAttemptId,
    enrollmentId: enrollmentId,
    campaignId: campaignId,
    isFirstAttempt: isFirstAttempt ,
    parentActivityId: parentActivityId,
    materialType: materialType
  }
});
