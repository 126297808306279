import React, {useEffect, useState} from 'react'
import DefaultLayout from 'components/DefaultLayout';
import {FormattedMessage, useIntl} from 'react-intl';
import {Form, message, Radio} from 'antd';
import apiClient from 'utils/apiClient';
import config from "config/config";
import Select from "components/Form/Select";
import { Editor } from '@tinymce/tinymce-react';
import FlexRow from "../../../components/FlexRow";
import {NavLink} from "react-router-dom";
import {connect} from "react-redux";
import Button from "components/Button";
import './styles.scss';

const mapStateToProps = (state: any) => {
    return {
        session: state.session,
    }
};

const TermsAndConditionsEdit: React.FC = ({history, session}: any) => {
    const [terms, setTerms] = useState();
    const [languageList, setLanguageList] = useState<any>({});
    const [policyKey, setPolicyKey] = useState();

    const [form] = Form.useForm();
    const intl = useIntl();

    useEffect(() => {

        if (session.active_user_type === 'CUSTOMER_ADMIN' ||
            session.active_user_type === 'STUDENT' ||
            session.active_user_type === 'RESELLER_ADMIN'
        ) {
            history.push('/403');
        }

        form.setFieldsValue({
            key: 'terms-and-conditions'
        });

        const loadLanguages = async () => {
            let languages = await apiClient.request(`/api/v1/language`, {}, 'GET');
            let languageListForSelect: any = {};

            Object.values(languages.languages).map((value: any) => {
               return languageListForSelect[value['code']] = value['name'];
            });

            setLanguageList(languageListForSelect);
        };

        const loadTerms = async () => {
            let response = await apiClient.request('/api/v1/terms-and-conditions/' + intl.locale, [], 'GET');
            setTerms(response.terms.text);
        };

        loadLanguages();
        loadTerms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl]);

    const submitForm = async (values: any) => {
        let parsedValues = {
            key: values.key,
            language: values.language,
            text: values.text.level.content
        };

        try {

            if (policyKey) {
                await apiClient.request('api/v1/terms-and-policy/update/' + policyKey, parsedValues, 'PUT');
            } else {
                await apiClient.request('api/v1/terms-and-policy/store', parsedValues, 'POST');
            }

            if (values.key === 'terms-and-conditions') {
                history.push('terms-and-conditions')
            } else {
                history.push('privacy-policy')
            }
        } catch (error) {
            message.error(intl.formatMessage({id: "general.submit_error"}));
        }
    };

    const languageChange = async (language: string) => {
        let response = await apiClient.request('/api/v1/terms-and-policy/' + form.getFieldValue('key') + '/' + language, [], 'GET');

        if (response.csPolicy) {
            setPolicyKey(response.csPolicy.id);

            form.setFieldsValue({
                text: response.csPolicy.text
            });
        }
    };

    return <>
        <DefaultLayout.PageLayout>
            <DefaultLayout.PageHeader title={intl.formatMessage({id: 'system.terms_and_conditions'})}/>
            <DefaultLayout.PageContent>
                <Form
                    form={form}
                    onFinish={submitForm}
                >
                    <Form.Item
                        className='terms-form'
                        label=' '
                        name='key'
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 6 }}
                    >
                        <Radio.Group>
                            <Radio value="terms-and-conditions"><FormattedMessage id='system.terms_and_conditions'/></Radio>
                            <Radio value="privacy-policy"><FormattedMessage id='system.privacy_policy'/></Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Select
                        name='language'
                        label={intl.formatMessage({id: "general.language"})}
                        manualOptions={languageList}
                        onChange={(el: string) => languageChange(el)}
                    />
                        <Form.Item name={'text'}>
                            <Editor
                                apiKey={config.api.tinymceApiKey}
                                value={terms}
                                onEditorChange={(el: any) => setTerms(el)}
                                initialValue={'‎'} // initial value = Empty character. Needs to switch between language after loading template
                                init={{
                                    branding: false,
                                    mode: 'exact',
                                    relative_urls : true,
                                    remove_script_host : true,
                                    height: 600,
                                    menubar: false,
                                    plugins: [
                                        'advlist autolink lists link image charmap print preview anchor',
                                        'searchreplace visualblocks code fullscreen',
                                        'insertdatetime media table paste code help wordcount'
                                    ],
                                    toolbar:
                                        'undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | code | help'
                                }}
                            />
                        </Form.Item>
                </Form>
                <FlexRow right={
                    <div className='form-buttons'>
                        <NavLink to='terms-and-conditions'>
                            <Button type='outline' style={{marginRight: 8}}>
                                <FormattedMessage id='general.back'/>
                            </Button>
                        </NavLink>
                        <Button
                            type='primary'
                            onClick={() => form.submit()}
                        >
                            <FormattedMessage id='general.submit'/>
                        </Button>
                    </div>
                }/>
            </DefaultLayout.PageContent>
        </DefaultLayout.PageLayout>
    </>
};

export default connect(mapStateToProps)(TermsAndConditionsEdit);




