import React, {useState, useEffect} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import { Form, message, Row, Col, Modal, Button } from 'antd';
import moment from "moment";
import DefaultLayout from 'components/DefaultLayout';
import Spinner from 'components/Spinner';
import { Input, Switch, Checkbox, Select, DatePicker } from 'components/Form';
import apiClient from 'utils/apiClient';
import config from 'config/config';
import './styles.scss';

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 6 },
};

const OrganizationAgreementForm: React.FC = ({history, match}: any) => {
  const [actionOnAgreementEnd, setActionOnAgreementEnd] = useState<any>(false);
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const [agreementLoading, setAgreementLoading] = useState(true);
  const [agreement, setAgreement] = useState<any>({});
  const [organization, setOrganization] = useState<any>({});
  const [hasEndDate, setHasEndDate] = useState(true);
  const [showDeactivationConfirmationModal, setShowDeactivationConfirmationModal] = useState(false);

  const intl = useIntl();
  const [form] = Form.useForm();
  const organizationId = match.params.organizationId;

  useEffect(() => {
    const loadAgreement = async () => {
      try {
        setAgreementLoading(true);
        let organizationResponse = await apiClient.request(`/api/v1/organizations/${organizationId}`);
        setOrganization(organizationResponse.organization);
        setAgreement(organizationResponse.agreement || {});
      } catch (err) {
        console.error(err);
        message.error(intl.formatMessage({id: "error.data_load"}));
      } finally {
        setAgreementLoading(false);
      }
    };

    const loadClassifiers = async () => {
      try {
        let actionOnAgreementEndResponse = await apiClient.request(config.api.routes.enums.actionOnAgreementEnd, {}, 'GET');
        let actionOnAgreementEnd:any = [];
        Object.entries(actionOnAgreementEndResponse).map((value:any, index:any) => {
          return actionOnAgreementEnd[value[0]] = intl.formatMessage({id: `organization.${value[0]}`})
        })
        setActionOnAgreementEnd(actionOnAgreementEnd);
      } catch (err) {
        console.error(err);
        // TODO: Pielikt paziņojumu par to, ka datus nav izdevies ielasīt
      }
    }

    loadAgreement();
    loadClassifiers();
  }, [organizationId, intl]);

  useEffect(() => {
    if (agreement.id) {
      form.setFieldsValue({
        beginDate: moment(agreement.beginDate),
        endDate: agreement.endDate ? moment(agreement.endDate) : moment(agreement.beginDate).add(1, 'days'),
        referenceId: agreement.referenceId,
        canAddNewResellers: !!agreement.canAddNewResellers,
        canAddNewCustomers: !!agreement.canAddNewCustomers,
        canManageCourses: !!agreement.canManageCourses,
        canManageAdmins: !!agreement.canManageAdmins,
        canManageEmailTemplates: !!agreement.canManageEmailTemplates,
        canManageLanguages: !!agreement.canManageLanguages,
        canManagePhishing: !!agreement.canManagePhishing,
        onAgreementEnd: agreement.onAgreementEnd,
        hasEndDate: agreement.hasEndDate
      });
      setHasEndDate(agreement.hasEndDate);
    } else {
      // Default form values
      form.setFieldsValue({
        beginDate: moment(),
        endDate: moment().add('1', 'day'),
        canAddNewResellers: false,
        canAddNewCustomers: false,
        canManageCourses: false,
        canManageAdmins: false,
        canManageEmailTemplates: false,
        canManageLanguages: false,
        canManagePhishing: false,
        onAgreementEnd: 'BLOCK_USER_ACCOUNT_ACCESS'
      });
    }
  }, [agreement, form]);

  const submitForm = async (values: any) => {
    try {
      let parsedValues = {
        ...values,
        beginDate: values['beginDate'].format("YYYY-MM-DD"),
        endDate: values['endDate'] ? values['endDate'].format("YYYY-MM-DD") : null,
        status: agreement.status || 'ACTIVE' ,
        organizationId: organizationId,
        hasEndDate: hasEndDate
      }

      setSubmitInProgress(true);
      if (agreement.id) {
        const updateAgreementResponse = await apiClient.request(`/api/v1/agreements/${agreement.id}`, parsedValues, 'PUT');
        setAgreement(updateAgreementResponse.agreement || {});
        setSubmitInProgress(false);
        history.push('/organizations');
      } else {
        await apiClient.request('/api/v1/agreements', parsedValues, 'POST');
        setSubmitInProgress(false);
        history.push(`/organizations/${match.params.organizationId}/admins`);
      }
      message.success(intl.formatMessage({id: "organization.agreement_successfully_updated"}));
    } catch (error) {
      console.error(error);
      message.error(intl.formatMessage({id: "general.submit_error"}));    // TODO: Izmantot notification
      setSubmitInProgress(false);
    }
  }

  const activateAgreement = async () => {
    try {
      setSubmitInProgress(true);
      await apiClient.request(`/api/v1/agreements/${agreement.id}/reactivate`, [], 'POST');
      let organizationResponse = await apiClient.request(`/api/v1/organizations/${organizationId}`);
      setAgreement(organizationResponse.agreement || {});
      message.success(intl.formatMessage({id: "organization.agreement_activated"}));
    } catch (err) {
      console.error(err);
      message.error(intl.formatMessage({id: "error.server_error"}));
    } finally {
      setSubmitInProgress(false);
    }
  }

  const deactivateAgreement = async () => {
    try {
      setSubmitInProgress(true);
      await apiClient.request(`/api/v1/agreements/${agreement.id}/cancel`, [], 'POST');
      let organizationResponse = await apiClient.request(`/api/v1/organizations/${organizationId}`);
      setAgreement(organizationResponse.agreement || {});
      setShowDeactivationConfirmationModal(false);
      message.success(intl.formatMessage({id: "organization.agreement_deactivated"}));
    } catch (err) {
      console.error(err);
      message.error(intl.formatMessage({id: "error.server_error"}));
    } finally {
      setSubmitInProgress(false);
    }
  }

  return <DefaultLayout.PageLayout withStickyFooter>
    <DefaultLayout.PageHeader
      loading={agreementLoading}
      breadcrumb={[{name: intl.formatMessage({id: 'general.resellers'}), path: '/organizations'}]}
      title={
        agreement.id
          ? intl.formatMessage({id: 'form.edit_agreement_title'}, {name: organization.name})
          : intl.formatMessage({id: 'form.add_agreement_title'}, {name: organization.name})
      }
    />
    <Spinner spinning={submitInProgress || agreementLoading} opaque={agreementLoading}>
      <DefaultLayout.PageContent withTopPadding>
        <Form form={form} onFinish={submitForm}>
          <DatePicker
            name='beginDate'
            validateTrigger='onChange'
            label={intl.formatMessage({id: "organization.agreement_begin_date"})}
            customRules={[{required: true, message: intl.formatMessage({id: 'validation.field_required'})}]}
          />
          {
            hasEndDate ?
              <DatePicker
                name="endDate"
                className={moment() > moment(agreement.endDate) ? 'expired-end-date' : ''}
                validateTrigger="onChange"
                label={intl.formatMessage({id: "organization.agreement_end_date"})}
                defaultValue={agreement.beginDate ? moment(agreement.beginDate) : moment()}
                customRules={[
                  { required: true, message: intl.formatMessage({id: "validation.field_required"}) },
                  ({ getFieldValue }: any) => ({
                    validator(rule: any, value: any) {
                      if (moment(getFieldValue('beginDate')).isBefore(value, 'day')) {
                        return Promise.resolve();
                      }

                      return Promise.reject(intl.formatMessage({id: "validation.date_must_be_after_begin_date"}));
                    }
                  })
                ]}
              /> : null
          }
          <Checkbox
            name="hasEndDate"
            label={intl.formatMessage({id: "general.indefinite"})}
            checked={!hasEndDate}
            onChange={() => setHasEndDate(!hasEndDate)}
          />
          {
            agreement.id ?
              <Form.Item
                className="agreement-status"
                {...formItemLayout}
                label={intl.formatMessage({id: "Agreement status:"})}
              >
                <div>
                  {
                    agreement.status === 'INACTIVE' ? 
                    <label className="inactive-status"><FormattedMessage id="agreement.status.INACTIVE"/></label> 
                    :
                    <label className="active-status"><FormattedMessage id="agreement.status.ACTIVE"/></label>
                  }                
                  {
                    agreement.status === 'ACTIVE' ?
                      <Button type="primary" onClick={() => setShowDeactivationConfirmationModal(true)} loading={submitInProgress} style={{height: 32, padding: 8, lineHeight: 1, backgroundColor: '#F5222D', borderRadius: 8, marginRight: 8, border: 0}}>
                        <FormattedMessage id="form.actions.deactivate_agreement" />
                      </Button>
                      :
                      <Button type="primary" onClick={() => activateAgreement()} loading={submitInProgress} style={{height: 32, padding: 8, lineHeight: 1, backgroundColor: '#52C419', borderRadius: 8, marginRight: 8, border: 0}}>
                        <FormattedMessage id="form.actions.activate_agreement" />
                      </Button>
                  }
                </div>
              </Form.Item> : null   
          }
          <Input name="referenceId" label={intl.formatMessage({id: "organization.reference_id"})} validation={{required: true}} />

          <hr className="form-group-seperator" />
          <Row>
            <Col span={14}>
              <h1 className="form-group-header">{intl.formatMessage({id: "organization.special_rights"})}</h1>
            </Col>
          </Row>

          <Switch isFormItem name="canAddNewResellers" label={intl.formatMessage({id: "organization.add_new_resellers"})} />
          <Switch isFormItem name="canAddNewCustomers" label={intl.formatMessage({id: "organization.add_new_customers"})} />
          <Switch isFormItem name="canManageCourses" label={intl.formatMessage({id: "organization.add_remove_courses"})} />
          <Switch isFormItem name="canManageAdmins" label={intl.formatMessage({id: "organization.add_remove_admins"})} />
          <Switch isFormItem name="canManageEmailTemplates" label={intl.formatMessage({id: "organization.edit_email_templates"})} />
          <Switch isFormItem name="canManageLanguages" label={intl.formatMessage({id: "general.edit_languages"})} />
          <Switch isFormItem name="canManagePhishing" label={intl.formatMessage({id: "organization.manage_phishing"})} />
          <Select
            name='onAgreementEnd'
            label={intl.formatMessage({id: "organization.action_on_agreement_end"})}
            customRules={[{ required: true, message: intl.formatMessage({id: "validation.field_required"}) }]}
            manualOptions={actionOnAgreementEnd}
          />
        </Form>
      </DefaultLayout.PageContent>
      <DefaultLayout.PageFooter className='justify-content-end'>
        <div className="form-buttons">
          <NavLink to="/organizations">
            <Button>
              <FormattedMessage id="general.back" />
            </Button>
          </NavLink>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginLeft: 10 }}
            loading={submitInProgress}
            onClick={() => form.submit()}
          >
            <FormattedMessage id="general.submit" />
          </Button>
        </div>
      </DefaultLayout.PageFooter>
      <Modal
        title={intl.formatMessage({id: 'form.actions.deactivate_agreement'})}
        visible={showDeactivationConfirmationModal}
        onOk={() => deactivateAgreement()}
        onCancel={() => setShowDeactivationConfirmationModal(false)}
        okText={intl.formatMessage({id: 'form.actions.deactivate_agreement'})}
        cancelText={intl.formatMessage({id: 'general.cancel'})}
      >
        <FormattedMessage id="confirmation.are_you_sure" />
      </Modal>
    </Spinner>
  </DefaultLayout.PageLayout>
}

export default withRouter(OrganizationAgreementForm);
